/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery, UseQueryOptions } from 'react-query';

import { fetchCanDelete } from './user.apis';

import { CanDeleteResponse } from './user.model';

import { CustomAxiosError } from 'core/axios';

export function useCanDeleteUser(
  options:
    | UseQueryOptions<
        CanDeleteResponse,
        CustomAxiosError<unknown>,
        CanDeleteResponse
      >
    | undefined = {}
) {
  return useQuery<CanDeleteResponse, CustomAxiosError>(
    '/me/can_delete',
    () => fetchCanDelete(),
    {
      retry: 1,
      ...options,
    }
  );
}
