import React from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { EnterpriseSingleColumnPageTemplate } from '../../designSystem/templates';

import useTranslatedTexts from './useTranslatedTexts';

import { CLASSTING_OIDC_URL } from 'shared/constants';

import { useClearStoredDataAsync } from 'shared/root';
import { AnchorButton } from 'designSystem/molecules';
import { sessionStorageService } from 'core/storage';
import { useIsClasstingMobileApp } from 'shared/hooks';

/**
 * 현재는 RN에서만 동작하는 url입니다.
 * RN 해당 url을 캐치하면 RN에서 로그아웃 로직을 수행하고 앱 랜딩 페이지로 네비게이션합니다.
 */
const END_SESSION_SUCCESS_URL = `${CLASSTING_OIDC_URL}/session/end/success`;

const DeleteSuccess = (): JSX.Element => {
  useClearStoredDataAsync(100);
  const texts = useTranslatedTexts();
  const isClasstingMobileApp = useIsClasstingMobileApp();

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const state = query.get('state');
  const returnTo = state ? JSON.parse(state).returnTo : null;

  const redirectUrl = isClasstingMobileApp ? END_SESSION_SUCCESS_URL : returnTo;
  return (
    <EnterpriseSingleColumnPageTemplate
      title={texts.CTATitle}
      desc={texts.CTADesc}
    >
      <AnchorButton
        testId="delete-success-page__confirm-button"
        size="lg"
        theme="white"
        appearance="outlined"
        href={redirectUrl}
      >
        {texts.confirmButton}
      </AnchorButton>
    </EnterpriseSingleColumnPageTemplate>
  );
};

const EnterpriseDeleteSuccessPage = (): JSX.Element => {
  const isDeleteRequestSuccess = sessionStorageService.getItem(
    'isDeleteRequestSuccess'
  );

  if (isDeleteRequestSuccess) {
    return <DeleteSuccess />;
  }

  return (
    <Redirect
      to={{
        pathname: '/enterprise/account/delete/error',
        state: {
          reason: '비정상적인 접근',
        },
      }}
    />
  );
};

export default EnterpriseDeleteSuccessPage;
