/* istanbul ignore file */

import * as Sentry from '@sentry/react';
import { Event } from '@sentry/types';

import { NetworkError } from '../../core/axios/modifyAxiosError';

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;

const networkErrorEvent = (
  event: Event,
  originalException: NetworkError
): Event | null => {
  const draftNetworkErrorEvent = event;
  if (originalException.contexts?.error) {
    const errorContext = originalException.contexts.error;
    const method = errorContext.method as string;
    const host = errorContext.host as string;
    const status = errorContext.status as number;

    if (status && 400 <= status && status < 500) {
      return null;
    }

    draftNetworkErrorEvent.fingerprint = [
      '{{ default }}',
      '{{ type }}',
      method,
      host,
    ];
  }

  if (draftNetworkErrorEvent?.exception?.values?.[0]?.type) {
    draftNetworkErrorEvent.exception.values[0].type = 'NetworkError';
  }
  return draftNetworkErrorEvent;
};

export const initSentry = (): void => {
  Sentry.init({
    dsn,
    environment,
    beforeSend(event, hint) {
      if (hint?.originalException instanceof NetworkError) {
        return networkErrorEvent(event, hint.originalException);
      }
      return event;
    },
  });
};
