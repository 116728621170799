/* istanbul ignore file */

import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { configureSafeHref } from 'ui-box';
import 'locales/i18nConfig';
import 'locales/countryCodeConfig';

import { ApolloProvider } from '@apollo/client';

import AuthProvider from './components/AuthProvider';

import RootRouter from 'router/RootRouter';
import { colors } from 'designSystem/ions';
import { ModalProvider, SimpleModal } from 'designSystem/organisms';
import apolloClient from 'core/apollo/client';
import { GoogleTagManager } from 'shared/analytics';

configureSafeHref({
  enabled: true,
});

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK === 'true'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(): JSX.Element {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <ApolloProvider client={apolloClient}>
            <AuthProvider>
              <BrowserRouter>
                <GoogleTagManager />
                <RootRouter />
              </BrowserRouter>
              <Toaster
                position="bottom-center"
                toastOptions={{
                  style: {
                    fontSize: '1rem',
                    fontWeight: 'normal',
                    color: colors.gray[900],
                    lineHeight: 1.5,
                  },
                  error: {
                    iconTheme: {
                      primary: colors.status.error,
                      secondary: colors.white,
                    },
                  },
                }}
              />
              <SimpleModal />
            </AuthProvider>
          </ApolloProvider>
        </ModalProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
