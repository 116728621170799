import React, { useCallback, useMemo, useState, useRef } from 'react';
import dayjs from 'dayjs';

import useTranslatedTexts from '../useTranslatedTexts';

import {
  AGE_LIMIT,
  EMPTY_BIRTHYEAR_FALLBACK,
  EMPTY_SCHOOL_INFO,
  FALLBACK_COUNTRY_CODE,
  FALLBACK_LANGUAGE,
  LEGACY_EMPTY_BIRTHYEAR_FALLBACK,
} from '../constants';

import { useUpdateAccountProfileImageCommand } from '../hooks';

import {
  PersonalInfoForm,
  PersonalInfoFormSchema,
  errorTypes,
  personalInfoFormSchema,
} from './PersonalInfoForm';
import { EditableProfileImage } from './EditableProfileImage';
import { ChangeRoleDialog } from './ChangeRoleDialog';

import { useModalContext, SectionPanel } from 'designSystem/organisms';
import { createTrackEventProps } from 'shared/analytics/utils';

import {
  Account,
  useMyAccount,
  Gender,
  useUpdateAccount,
  MySchoolInfo,
} from 'shared/data/account';

import { RouteLeavingGuard } from 'router/RouteLeavingGuard';

import { Box, useIsInSmScreen } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';

import { useFormWithResolver } from 'shared/hooks';
import { UpdateAccountErrorResponse } from 'shared/data/user';

import { getWesternAge, isEmptyObject } from 'shared/utils';

const PersonalInfoPanel = ({ account }: { account: Account }): JSX.Element => {
  const { isLoading: isRefetching, refetch } = useMyAccount({ enabled: false });
  const texts = useTranslatedTexts();
  const { openModal } = useModalContext();
  const isActiveRouteLeaveGuard = useRef(false);
  const isSmScreen = useIsInSmScreen();
  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false);
  const getPersonalInfoFormDefaultValue = useCallback((account: Account) => {
    let birthdayYear: number | undefined;
    let birthdayMonth: number | undefined;
    let birthdayDate: number | undefined;

    if (!account.birthDate) {
      birthdayYear = Number(EMPTY_BIRTHYEAR_FALLBACK);
      birthdayDate = -1;
      birthdayMonth = -1;
    } else {
      const date = dayjs(
        account.birthDate.replace(
          LEGACY_EMPTY_BIRTHYEAR_FALLBACK,
          EMPTY_BIRTHYEAR_FALLBACK
        )
      );

      birthdayYear = date.year();
      birthdayMonth = date.month();
      birthdayDate = date.date();
    }

    let accountSchoolInfo: MySchoolInfo = EMPTY_SCHOOL_INFO;

    if (account.schoolInfo !== null && account.schoolInfo?.school) {
      accountSchoolInfo = account.schoolInfo;
    }

    return {
      name: account.name,
      description: account.description ?? '',
      birthdayYear,
      birthdayMonth,
      birthdayDate,
      gender: account.gender,
      countryCode: account.countryCode || FALLBACK_COUNTRY_CODE,
      language: account.language || FALLBACK_LANGUAGE,
      schoolInfo: accountSchoolInfo,
    };
  }, []);

  const personalInfoFormDefaultValues = useMemo(
    () => getPersonalInfoFormDefaultValue(account),
    [account, getPersonalInfoFormDefaultValue]
  );

  const personalInfoForm = useFormWithResolver<PersonalInfoFormSchema>({
    schema: personalInfoFormSchema,
    defaultValues: personalInfoFormDefaultValues,
  });

  const {
    formState: personalInfoFormState,
    setError: personalInfoFormSetError,
    handleSubmit: personalInfoFormHandleSubmit,
    reset: personalInfoFormReset,
  } = personalInfoForm;
  const {
    errors: personalInfoFormErrors,
    dirtyFields: personalInfoFormDirtyFields,
  } = personalInfoFormState;

  const personalInfoFormHasErrors = !isEmptyObject(personalInfoFormErrors);
  const personalInfoFormHasChanges = !!Object.keys(personalInfoFormDirtyFields)
    .length;

  const personalInfoFormMutation = useUpdateAccount({
    onSuccess() {
      /* istanbul ignore next */
      refetch().then(({ data }) => {
        if (data) {
          personalInfoFormReset(getPersonalInfoFormDefaultValue(data), {
            keepValues: true,
          });
        }
      });

      if (!isActiveRouteLeaveGuard.current) {
        openModal({
          status: 'success',
          message: texts.message.successChange,
        });
      }
    },
    onError(error) {
      /* istanbul ignore else */
      if (error.response) {
        const data = error.response.data as UpdateAccountErrorResponse<
          keyof PersonalInfoFormSchema
        >;

        data.message.forEach(({ property }) => {
          /* istanbul ignore else */
          if (data.statusCode === 422) {
            personalInfoFormSetError(property, {
              type: errorTypes.containsBrandName,
            });
          } else {
            personalInfoFormSetError(property, {
              type: errorTypes.unknown,
            });
          }
        });
      }
    },
  });

  /* istanbul ignore next */
  const handleSubmitPersonalInfoForm = personalInfoFormHandleSubmit(
    useCallback(
      ({
        name,
        description,
        birthdayYear,
        birthdayDate,
        birthdayMonth,
        gender,
        language,
        countryCode,
      }: PersonalInfoFormSchema) => {
        const isBirthdateDirty =
          personalInfoFormDirtyFields.birthdayMonth ||
          personalInfoFormDirtyFields.birthdayDate;

        const hasBirthdayInfo =
          birthdayYear !== undefined &&
          birthdayMonth !== undefined &&
          birthdayMonth !== -1 &&
          birthdayDate !== undefined &&
          birthdayDate !== -1;

        const data = {
          ...(personalInfoFormDirtyFields.name ? { name } : null),
          ...(personalInfoFormDirtyFields.description ? { description } : null),
          ...(personalInfoFormDirtyFields.gender
            ? { gender: gender as Gender }
            : null),
          ...(isBirthdateDirty && hasBirthdayInfo
            ? {
                birthDate: dayjs()
                  .year(birthdayYear)
                  .month(birthdayMonth)
                  .date(birthdayDate)
                  .format('YYYY-MM-DD'),
              }
            : null),
          ...(personalInfoFormDirtyFields.language ? { language } : null),
          ...(personalInfoFormDirtyFields.countryCode ? { countryCode } : null),
        };
        personalInfoFormMutation.mutate({ userId: account.id, data });
      },
      [account.id, personalInfoFormDirtyFields, personalInfoFormMutation]
    )
  );

  const {
    uploadHandler: handleUploadProfileImage,
    deleteHandler: handleDeleteProfileImage,
  } = useUpdateAccountProfileImageCommand({
    account,
  });

  const handleChangeProfileImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      /* istanbul ignore else */
      if (event.target.files) {
        handleUploadProfileImage(event.target.files[0]);
      }
    },
    [handleUploadProfileImage]
  );

  const closeChangeRoleDialog = () => {
    setIsChangeRoleDialogOpen(false);
  };

  const handleClickRequestRoleChangeButton = () => {
    setIsChangeRoleDialogOpen(true);
  };

  return (
    <SectionPanel.Wrapper testId="personal-info-panel" className="w-full pt-8">
      <SectionPanel.Header
        title={texts.title.userInfo}
        showTitleInMobile={false}
      />
      <SectionPanel.Body>
        <Box className="w-full">
          <Box className="text-body-2 sm:text-body-1 text-gray-900 font-medium">
            {texts.label.profile}
          </Box>
          <Box className="mt-5 sm:mt-6">
            <EditableProfileImage
              src={account.profileImage || ''}
              deleteText={texts.button.delete}
              onChange={handleChangeProfileImage}
              onDelete={handleDeleteProfileImage}
              editEvent={{
                name: 'ChangeProfileImageStarted',
                on: 'click',
                properties: {
                  userId: account.id,
                  role: account.role,
                },
              }}
              deleteEvent={{
                name: 'DeleteProfileImageStarted',
                on: 'click',
                properties: {
                  userId: account.id,
                  role: account.role,
                },
              }}
            />
          </Box>
        </Box>
        <PersonalInfoForm
          account={account}
          formProps={personalInfoForm}
          onSubmit={handleSubmitPersonalInfoForm}
          onClickChangeRole={handleClickRequestRoleChangeButton}
        />
        <Button
          testId="submit-form-button"
          className="bottom-0 mt-10"
          appearance="contained"
          disabled={
            personalInfoFormMutation.isLoading ||
            isRefetching ||
            !personalInfoFormHasChanges ||
            personalInfoFormHasErrors
          }
          size="lg"
          fullWidth
          rounded
          onClick={handleSubmitPersonalInfoForm}
          {...createTrackEventProps({
            name: 'PersonalInfoSaveClicked',
            on: 'click',
            properties: {
              userId: account?.id,
              role: account?.role,
            },
          })}
        >
          {texts.button.saveChange}
        </Button>
      </SectionPanel.Body>
      <ChangeRoleDialog
        account={account}
        open={isChangeRoleDialogOpen}
        openModal={openModal}
        closeDialog={closeChangeRoleDialog}
      />
      <RouteLeavingGuard when={personalInfoFormHasChanges} />
    </SectionPanel.Wrapper>
  );
};

export default PersonalInfoPanel;
