import React from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';
import LinkChildPageContent from './LinkChildPageContent';

import {
  SectionPanel,
  GlobalAppBar,
  CenterTitleNavigationHeader,
} from 'designSystem/organisms';
import { SideMenuPageTemplate } from 'designSystem/templates';
import { useLinkChildPageQuery } from 'generated/graphql';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks';

export const LinkChildPage = ({
  account,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const isAllowed = account.role === 'parent' || account.role === 'teacher';
  const texts = useTranslatedTexts();
  const isClasstingMobileApp = useIsClasstingMobileApp();

  const { data } = useLinkChildPageQuery();
  const guardianId = data?.me?.id || '';

  const renderContent = () => {
    if (!isAllowed) {
      return <Redirect to="/not-found" />;
    }

    return <LinkChildPageContent guardianId={guardianId} />;
  };

  return (
    <SideMenuPageTemplate
      account={account}
      showFooter={!isClasstingMobileApp}
      headerChild={
        isClasstingMobileApp ? (
          <CenterTitleNavigationHeader title={texts.title.linkChild} />
        ) : (
          <GlobalAppBar account={account} />
        )
      }
    >
      <SectionPanel.Wrapper testId="link-child-panel" className="w-full pt-8">
        <SectionPanel.Body>{renderContent()}</SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SideMenuPageTemplate>
  );
};

export default withAccount(LinkChildPage);
