import React, { useCallback } from 'react';
import classnames from 'classnames';

import { Box } from 'designSystem/ions';

export type ListItemValue = {
  id: string;
  label: string;
};

export type ListItemProps = {
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (id: string) => void;
} & ListItemValue;

const defaultStyle = 'text-gray-700 font-regular bg-transparent';
const selectedStyle = 'text-gray-900 font-medium bg-gray-100';
const disabledStyle = 'text-gray-400';

const ListItem = ({
  id,
  label,
  selected,
  disabled,
  className,
  onClick,
}: ListItemProps): JSX.Element => {
  const menuStyle = classnames(
    'flex w-full py-3 px-6 text-body-3 focus:outline-none focus-visible:ring',
    {
      [selectedStyle]: selected,
      [defaultStyle]: !selected,
      [disabledStyle]: disabled,
    },
    className
  );

  const handlerItemClick = useCallback(() => {
    typeof onClick === 'function' && onClick(id);
  }, [id, onClick]);

  return (
    <Box
      is="li"
      role="menuitem"
      area-disabled={disabled}
      className="flex list-none"
    >
      <Box
        is="button"
        data-testid="list-item"
        className={menuStyle}
        disabled={disabled}
        onClick={handlerItemClick}
      >
        {label}
      </Box>
    </Box>
  );
};

export default React.memo(ListItem);
