/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function KakaoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={16} height={16} rx={4} fill="#FEE500" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.001 4.4C5.791 4.4 4 5.792 4 7.505c0 1.067.694 2.008 1.747 2.568l-.444 1.63c-.04.144.124.26.25.175L7.5 10.586c.164.016.331.025.5.025 2.21 0 4.001-1.392 4.001-3.106.002-1.713-1.79-3.105-3.999-3.105z"
        fill="#000"
        fillOpacity={0.9}
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(KakaoIcon),
};

export default Asset;
