import React from 'react';

import { Box, useIsInSmScreen } from 'designSystem/ions';
import { EnterpriseSideMenu } from 'designSystem/organisms/EnterpriseSideMenu';
import { Account } from 'shared/data/account';

interface EnterpriseSideMenuLayoutProps {
  account?: Account;
  children: React.ReactNode;
}

const EnterpriseSideMenuLayout = ({
  account,
  children,
}: EnterpriseSideMenuLayoutProps): JSX.Element => {
  const isSmScreen = useIsInSmScreen();
  const className = `
  flex flex-col mb-22
  `;

  return (
    <Box is="div" className="container">
      <Box is="div" className="row justify-center">
        <Box is="main" role="main" className={className}>
          <Box is="div" className="flex gap-x-6">
            {!isSmScreen && <EnterpriseSideMenu account={account} />}
            <Box is="div" className="w-full">
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EnterpriseSideMenuLayout;
