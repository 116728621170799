/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const notLinked = t('account.item.linked_account.null');
  const title = {
    userInfo: t('account.my_info.title'),
    accountInfo: t('account.account_info.title'),
  };

  const label = {
    none: t('account.message.none'),
    id: t('account.label.id'),
    username: t('account.label.username'),
    password: t('account.label.password'),
    email: t('account.label.email'),
    linked: t('account.label.linked_account'),
    mobile: t('account.label.mobile'),
    name: t('account.label.name'),
    cannotUpdatePassword: t('account.message.no_password'),
  };

  const button = {
    add: t('account.button.add'),
    change: t('account.button.change'),
    changePassword: t('account.button.change_password'),
    deleteAccount: t('account.delete_account.agree.button.delete_account'),
    copy: t('account.button.copy'),
  };

  const message = {
    copyConfirm: t('account.message.id_copied'),
    idTooltipDescription: t('account.account_info.id_tooltip_description'),
  };

  return {
    notLinked,
    title,
    label,
    button,
    message,
  };
};

export default useTranslatedTexts;
