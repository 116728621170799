import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const checkList = [
    { text: t('account.delete_account.agree.message.1') },
    { text: t('account.delete_account.agree.message.3') },
    { text: t('account.delete_account.agree.message.4') },
  ];

  const checkLabel = t('account.delete_account.agree.check.all');
  const CTATitle = t('account.delete_account.agree.title');
  const CTADesc = t('account.delete_account.agree.description');
  const accountInfoTitle = t('account.delete_account.agree.account_info_title');
  const noticeTitle = t('account.delete_account.agree.notice_title');
  const noticeAgreeTitle = t('account.delete_account.agree.notice_agree_title');
  const cancelButton = t('account.button.cancel');
  const deleteButton = t('account.delete_account.agree.button.delete_account');

  return {
    checkList,
    checkLabel,
    CTATitle,
    CTADesc,
    cancelButton,
    deleteButton,
    accountInfoTitle,
    noticeTitle,
    noticeAgreeTitle,
  };
};

export default useTranslatedTexts;
