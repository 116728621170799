/* istanbul ignore file */
import * as yup from 'yup';
import emojiRegex from 'emoji-regex';
import dayjs from 'dayjs';

const emoji = emojiRegex();

export const errorTypes = {
  unknown: 'unknown',
  containsBrandName: 'containsBrandName',
  specialCharacter: 'specialCharacter',
  invalidBirthdate: 'invalidBirthdate',
  missingValue: 'missingValue',
} as const;

export type PersonalInfoFormSchema = {
  name: string;
  description?: string;
  birthdayYear?: number;
  birthdayMonth?: number;
  birthdayDate?: number;
  gender?: string | null; // Gender | null
  language: string;
  countryCode: string;
};

const schema: yup.SchemaOf<PersonalInfoFormSchema> = yup.object({
  name: yup
    .string()
    .min(1)
    .max(30)
    .test(
      errorTypes.specialCharacter,
      'special character is not allowed',
      (value) => {
        if (value) {
          return !(
            /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value) ||
            emoji.test(value)
          );
        }
        return false;
      }
    )
    .required(),
  description: yup.string().optional().max(200),
  birthdayYear: yup.number().optional().min(0),
  birthdayMonth: yup
    .number()
    .optional()
    .test({
      name: errorTypes.missingValue,
      message: 'missing month',
      exclusive: false,
      test: (month, ctx) => {
        if (
          ctx.parent.birthdayDate === undefined ||
          ctx.parent.birthdayDate === -1
        ) {
          return true;
        }

        return month !== undefined && month !== -1;
      },
    })
    .test({
      name: errorTypes.invalidBirthdate,
      message: 'invalid date',
      exclusive: false,
      test: (month, ctx) => {
        if (!ctx.parent.birthdayDate || ctx.parent.birthdayDate === -1) {
          return true;
        }

        if (month === undefined || month < 0 || month > 11) {
          return false;
        }
        const correctedDate = dayjs()
          .year(ctx.parent.birthdayYear)
          .month(month)
          .date(ctx.parent.birthdayDate)
          .date();

        return correctedDate === ctx.parent.birthdayDate;
      },
    }),

  birthdayDate: yup
    .number()
    .optional()
    .test({
      name: errorTypes.missingValue,
      message: 'missing date',
      exclusive: false,
      test: (date, ctx) => {
        if (
          ctx.parent.birthdayMonth === undefined ||
          ctx.parent.birthdayMonth === -1
        ) {
          return true;
        }

        return date !== undefined && date !== -1;
      },
    })
    .test({
      name: errorTypes.invalidBirthdate,
      message: 'invalid date',
      exclusive: false,
      test: (date, ctx) => {
        if (
          ctx.parent.birthdayMonth === undefined ||
          ctx.parent.birthdayMonth === -1
        ) {
          return true;
        }

        if (!date || date < 1 || date > 31) {
          return false;
        }

        const correctedDate = dayjs()
          .year(ctx.parent.birthdayYear)
          .month(ctx.parent.birthdayMonth)
          .date(date)
          .date();

        return correctedDate === date;
      },
    }),
  gender: yup
    .string()
    .nullable()
    .optional()
    .test((data) => {
      if (!data) {
        return true;
      }
      if (data === 'male' || data === 'female') {
        return true;
      }
      return false;
    }),
  language: yup.string().required(),
  countryCode: yup.string().required(),
});

export const personalInfoFormSchema = schema;
