import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';

import { useAuth } from 'react-oidc-context';

import { sessionStorageService } from 'core/storage';

import {
  getCredential,
  setCredential,
  transformCredential,
} from 'core/credential/credential.service';
import {
  checkChallenging,
  checkChallengingPath,
} from 'core/challenge/challenge.service';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { WebViewBridge } from 'shared/webViewBridge';

const AuthRoute = <T extends RouteProps = RouteProps>({
  component: Component,
  children,
  ...rest
}: T): JSX.Element | null => {
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const [initialized, setInitializedState] = useState(!isClasstingMobileApp);
  const [hasAuthError, setAuthError] = useState(false);
  const auth = useAuth();
  const history = useHistory();
  const [user, setUser] = useState(auth.user);
  const [isAuthenticated, setAuthenticated] = useState(auth.isAuthenticated);

  useEffect(() => {
    if (!isClasstingMobileApp) return;

    WebViewBridge.getAuthorizationToken().then(
      ({ token_type, access_token, id_token, expires_in }) => {
        const credential = transformCredential({
          token_type,
          access_token,
          id_token,
          expires_in,
          session_state: '',
          scope: '',
        });
        setCredential(credential);
        setInitializedState(true);
        setUser(getCredential());
        setAuthenticated(true);
      }
    );
  }, [isClasstingMobileApp]);

  useEffect(() => {
    if (!auth.error || hasAuthError) return;

    setAuthError(true);
    history.replace('/account/error');
  }, [auth.error, history, hasAuthError]);

  let from = '/';

  if (rest.location) {
    from = rest.location.pathname + rest.location.search + rest.location.hash;
  }

  useEffect(() => {
    if (isClasstingMobileApp || auth.isLoading || auth.error || hasAuthError) {
      return;
    } else if (auth.isAuthenticated) {
      setUser(auth.user);
      setAuthenticated(true);

      return;
    } else {
      sessionStorageService.setItem('redirect', from);

      auth.signinRedirect();
    }
  }, [isClasstingMobileApp, auth, hasAuthError, from]);

  return initialized ? (
    <Route
      {...rest}
      render={(props) => {
        const accountId = user?.profile?.sub ?? null;
        if (
          !isClasstingMobileApp &&
          isAuthenticated &&
          checkChallengingPath(props.location.pathname) &&
          !checkChallenging(accountId)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/account/challenge-redirect',
                state: {
                  from: props.location.pathname,
                },
              }}
            />
          );
        }

        if (isAuthenticated && Component) {
          return <Component {...props} />;
        }
        if (isAuthenticated && children) {
          return children;
        }
      }}
    />
  ) : null;
};

export default AuthRoute;
