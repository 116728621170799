import jwt from 'jsonwebtoken';

import { IdTokenClaims, User } from 'oidc-client-ts';

import {
  CredentialParamObject,
  KEY_CREDENTIAL,
  CredentialUser,
} from './credential.model';

import { memoryStorageService } from 'core/storage';

import type { StorageService } from 'core/storage/storage.service';

class CredentialService {
  private storageService: StorageService;

  public constructor(storageService: StorageService) {
    this.storageService = storageService;
  }

  transformCredential = (
    credentialParamObject: CredentialParamObject
  ): CredentialUser => {
    const { expires_in, ...rest } = credentialParamObject;
    const expires_in_number = parseInt(credentialParamObject.expires_in, 10);
    const profile = (jwt.decode(rest?.id_token) as IdTokenClaims) ?? null;

    return {
      ...rest,
      expires_at: expires_in_number,
      profile,
    };
  };

  setCredential = (credential: CredentialUser): void => {
    this.storageService.setItem(KEY_CREDENTIAL, credential);
  };

  getCredential = (): User | null => {
    const deserializedCredential = this.storageService.getItem(KEY_CREDENTIAL, {
      raw: true,
    }) as string;

    if (!deserializedCredential) {
      return null;
    }

    return User.fromStorageString(deserializedCredential);
  };

  getCredentialAccountId = (): string | null => {
    const credential = getCredential();
    return credential ? credential.profile?.sub : null;
  };

  clearCredential = (): void => {
    this.storageService.removeItem(KEY_CREDENTIAL);
  };
}

const credentialService = new CredentialService(memoryStorageService);

export const transformCredential = credentialService.transformCredential;
export const setCredential = credentialService.setCredential;
export const getCredential = credentialService.getCredential;
export const getCredentialAccountId = credentialService.getCredentialAccountId;
export const clearCredential = credentialService.clearCredential;
