import React from 'react';

import { useLocation } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import { RedirectAppPage } from 'pages/RedirectAppPage';

import { TransparentPanel } from 'designSystem/layouts';
import { Helmet } from 'designSystem/ions';
import { BASE_URL } from 'shared/constants';
import { SingleColumnPageTemplate } from 'designSystem/templates';

const GuardianConnectPage = (): JSX.Element => {
  const texts = useTranslatedTexts();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const id = params.get('id') ?? '';

  const linkGuardianUrl = `/account/link-guardian?guardian_id=${id}`;

  return (
    <SingleColumnPageTemplate
      showFooter={false}
      hasBodyTopSpacing={false}
      hasHeaderShadow={false}
    >
      <TransparentPanel.Wrapper>
        <TransparentPanel.Header />
        <TransparentPanel.Body headerLess>
          <Helmet
            title={texts.title}
            desc={texts.description}
            thumbnail={`${BASE_URL}/account/classting-logo.png`}
          />
          <RedirectAppPage redirectWebUrl={linkGuardianUrl}></RedirectAppPage>
        </TransparentPanel.Body>
      </TransparentPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default GuardianConnectPage;
