import React from 'react';

type ClasstingCircleLogoProps = React.SVGProps<SVGSVGElement> & {
  theme?: 'primary' | 'white' | 'gray';
};

const size = {
  width: '150px',
  height: '40px',
};

function ClasstingCircleLogo({
  theme = 'primary',
  ...props
}: ClasstingCircleLogoProps): JSX.Element {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>classting Cicle Logo</title>
        <circle cx="48" cy="48" r="48" fill="#00C896" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.8719 42.7131C52.2458 42.7131 51.644 42.8185 51.1109 42.9931C50.2906 43.2619 47.6076 44.3989 47.5098 47.7651C47.5076 47.8457 47.5065 47.9225 47.5065 47.9998C47.5065 48.0771 47.5076 48.1544 47.5098 48.2312C47.6076 51.6007 50.2906 52.7383 51.1103 53.007C52.8584 53.579 55.3327 53.4098 57.038 51.2195C57.0445 51.2114 57.051 51.2033 57.057 51.1952L59.5842 47.9998L57.057 44.8049C57.051 44.7968 57.0445 44.7882 57.0375 44.7806C55.8534 43.2592 54.2978 42.7131 52.8719 42.7131ZM48.9405 19.5992C48.8053 19.5992 48.6701 19.5998 48.5355 19.6014C33.8402 19.8047 22.1248 30.7463 20.6855 45.026C19.0499 61.249 31.5304 75.7574 47.825 76.3803C56.9531 76.7285 65.5222 72.7805 71.179 65.6722C71.8825 64.7877 71.8782 63.543 71.1688 62.6461L65.2286 55.1361C65.0037 54.8512 64.5716 54.8512 64.3462 55.1356L63.4686 56.245C59.921 60.7915 54.076 62.5644 48.5717 60.7629C43.1349 58.9835 39.516 54.1586 39.3516 48.4712C39.3468 48.3171 39.3446 48.1587 39.3446 47.9997C39.3446 47.8408 39.3468 47.6829 39.3516 47.525C39.5165 41.8409 43.1355 37.0159 48.5717 35.2366C54.0755 33.4351 59.92 35.2085 63.4681 39.7534L64.3462 40.8639C64.5711 41.1483 65.0031 41.1483 65.2286 40.8639L71.1682 33.3534C71.8792 32.4543 71.8706 31.1929 71.146 30.2862C65.7082 23.4791 57.6339 19.5992 48.9405 19.5992ZM48.9395 84.5625C39.1695 84.5625 29.9873 80.7605 23.082 73.8566C16.1778 66.9517 12.3755 57.7694 12.375 48.0005C12.375 38.3057 16.1064 29.1818 22.8819 22.3087C29.6586 15.4346 38.7294 11.5753 48.4226 11.4412C54.1784 11.3612 59.7033 12.582 64.8443 15.0675C69.7365 17.4329 74.1217 20.9343 77.5232 25.1927C80.6317 29.0839 80.6506 34.522 77.5703 38.4165L69.9901 48L77.5703 57.5834C80.6447 61.4703 80.6431 66.8868 77.5665 70.7537C70.5828 79.5299 60.1488 84.5625 48.9395 84.5625Z"
          fill="white"
        />
      </svg>
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(ClasstingCircleLogo),
};
export default Asset;
