import React, { ReactElement } from 'react';
import classNames from 'classnames';

import defaultProfile from './images/defaultProfile@3x.png';

import { Box, BoxProps } from 'designSystem/ions';

import type { Without } from 'types/utilities';

type ProfileImageSize = 'sm' | 'md' | 'xs';

type Props = Without<BoxProps<'img'>, 'is'> & {
  size?: ProfileImageSize;
};

const ProfileImage = (
  props: Props,
  ref: React.Ref<HTMLImageElement>
): ReactElement => {
  const { src, size = 'sm', ...rest } = props;
  const profileImageStyle = classNames('rounded-full object-cover', {
    'min-w-9 w-9 h-9': size === 'xs',
    'min-w-12 w-12 h-12': size === 'sm',
    'min-w-16 w-16 h-16': size === 'md',
  });

  if (src) {
    return (
      <Box
        is="img"
        ref={ref}
        src={src}
        data-testid="profile-image"
        className={profileImageStyle}
        {...rest}
      />
    );
  }
  return (
    <Box
      is="img"
      ref={ref}
      src={defaultProfile}
      data-testid="profile-image--default"
      className={profileImageStyle}
      {...rest}
    />
  );
};

export default React.memo(React.forwardRef(ProfileImage));
