import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import useTranslatedTexts from '../useTranslatedTexts';
import { SimpleModal, useModalContext } from '../../../designSystem/organisms';

import { emailSchema, emailAuthenticationSchema } from './schema';

import { Box, useIsInMobile } from 'designSystem/ions';
import {
  useRequestAccountEmailUpdate,
  useUpdateAccountEmail,
  Account,
} from 'shared/data/account';
import { useFormRegister, Field } from 'shared/hooks';
import { TextField } from 'designSystem/molecules';
import { Button } from 'designSystem/atoms';
import { isEmptyObject } from 'shared/utils';
import { createTrackEventProps } from 'shared/analytics/utils';

type ChangeEmailFormProps = {
  account: Account;
};

const EnterpriseChangeEmailForm = ({
  account,
}: ChangeEmailFormProps): JSX.Element => {
  const [verifiedEmail, setVerifiedEmail] = useState<string | null>(null);
  const { openModal, closeModal } = useModalContext();
  const history = useHistory();
  const isMobile = useIsInMobile();
  const texts = useTranslatedTexts();
  const { fields, errors, setError, handleSubmit } = useFormRegister(
    ['email'],
    emailSchema
  );
  const {
    fields: authFields,
    errors: authErrors,
    setError: authSetError,
    handleSubmit: handleAuthSubmit,
  } = useFormRegister(['authentication'], emailAuthenticationSchema);
  const emailField = fields.get('email') as Field;
  const authField = authFields.get('authentication') as Field;

  const disableSubmitButton =
    !(authField.value && isEmptyObject(authErrors)) &&
    !(emailField.value && isEmptyObject(errors));

  const updateRequestMutation = useRequestAccountEmailUpdate({
    onSuccess: () => {
      setVerifiedEmail(emailField.value);
    },
    onError: (error) => {
      if (error.response?.status === 412) {
        // 이미 사용 중인 이메일
        setError('email', {
          type: 'alreadyOccupied',
          message: 'email already in use',
        });
      } else if (error.response?.status === 409) {
        // 이전과 동일한 이메일
        setError('email', {
          type: 'sameAsBefore',
          message: 'email same as before',
        });
      } else {
        //  기타 네트워크 에러
        openModal({
          status: 'error',
          message: texts.message.temporaryError,
        });
      }
    },
  });

  const updateMutation = useUpdateAccountEmail({
    onSuccess: () => {
      openModal({
        status: 'success',
        message: texts.message.successChange,
        onClickButton: () => {
          history.push('/enterprise/account/info');
        },
      });
    },
    onError: (error) => {
      if (error.response?.status === 406) {
        // 핀코드 틀림
        authSetError('authentication', {
          type: 'incorrect',
          message: 'incorrect pin code',
        });
      } else if (error.response?.status === 408) {
        // 기간 만료
        authSetError('authentication', {
          type: 'expired',
          message: 'expired pin code',
        });
      } else {
        //  기타 네트워크 에러
        openModal({
          status: 'error',
          message: texts.message.temporaryError,
        });
      }
    },
  });

  const submitEmail = (data: { email: string }) => {
    updateRequestMutation.mutate({
      userId: account.id,
      email: data.email,
      isEnterprise: true,
    });
  };

  const submitAuthCode = (data: { authentication: string }) => {
    updateMutation.mutate({ userId: account.id, code: data.authentication });
  };

  const handleClickModalClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closeModal();
  };

  const handleClickResendButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    /* istanbul ignore else */
    if (verifiedEmail) {
      submitEmail({ email: verifiedEmail });
      closeModal();
    }
  };

  const handleClickFailedToReceiveEmail = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    openModal({
      status: 'default',
      title: texts.title.failedToRecieveEmail,
      message: texts.message.failedToReceiveEmail,
      buttonGroup: (
        <SimpleModal.ButtonGroup>
          <Button
            testId="modal-close-button"
            theme="white"
            appearance={isMobile ? 'text' : 'outlined'}
            onClick={handleClickModalClose}
          >
            {texts.button.close}
          </Button>
          <Button
            testId="modal-retry-button"
            appearance={isMobile ? 'text' : 'outlined'}
            onClick={handleClickResendButton}
            {...createTrackEventProps({
              name: 'EmailCodeResendClicked',
              on: 'click',
              properties: {
                userId: account?.id,
                role: account?.role,
              },
            })}
          >
            {texts.button.resendEmailCode}
          </Button>
        </SimpleModal.ButtonGroup>
      ),
    });
  };

  return (
    <form
      data-testid={verifiedEmail ? 'authentication-form' : 'email-form'}
      onSubmit={
        verifiedEmail
          ? handleAuthSubmit(submitAuthCode)
          : handleSubmit(submitEmail)
      }
    >
      <Box>
        <TextField
          ref={emailField.ref}
          name={emailField.name}
          onChange={emailField.onChange}
          onBlur={emailField.onBlur}
          isError={!isEmptyObject(errors)}
          disabled={!!verifiedEmail}
          placeholder={texts.placeholder.email}
          type="text"
          label={texts.label.newEmail}
          helperTexts={[
            {
              status: 'error',
              visible: errors.email?.type === 'email',
              message: texts.message.invalidEmail,
            },
            {
              status: 'error',
              visible: errors.email?.type === 'alreadyOccupied',
              message: texts.message.alreadyOccupiedEmail,
            },
            {
              status: 'error',
              visible: errors.email?.type === 'sameAsBefore',
              message: texts.message.alreadyUsingEmail,
            },
            {
              status: 'default',
              visible: !!verifiedEmail,
              message: texts.message.sendEmailCode,
              icon: 'check',
            },
          ]}
        />
      </Box>
      {verifiedEmail && (
        <Box className="mt-6">
          <TextField
            ref={authField.ref}
            name={authField.name}
            onChange={authField.onChange}
            onBlur={authField.onBlur}
            type="text"
            label={texts.label.emailCode}
            placeholder={texts.placeholder.emailCode}
            isError={!isEmptyObject(authErrors)}
            helperTexts={[
              {
                status: 'error',
                visible: authErrors.authentication?.type === 'incorrect',
                message: texts.message.notMatchedEmailCode,
              },
              {
                status: 'error',
                visible: authErrors.authentication?.type === 'expired',
                message: texts.message.expiredEmailCode,
              },
            ]}
          />
        </Box>
      )}
      <Box className="mt-6">
        <Button
          type="submit"
          className="w-full sm:h-14"
          disabled={disableSubmitButton}
          appearance="contained"
          size={isMobile ? 'md' : 'lg'}
        >
          {verifiedEmail ? texts.button.change : texts.button.verifyEmail}
        </Button>
      </Box>
      {verifiedEmail && (
        <Box className="flex justify-center mt-6">
          <Box
            is="button"
            type="button"
            className="text-caption text-gray-600"
            onClick={handleClickFailedToReceiveEmail}
          >
            {texts.button.failedToReceiveEmail}
          </Box>
        </Box>
      )}
    </form>
  );
};

export default EnterpriseChangeEmailForm;
