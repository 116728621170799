import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/features/global-this';
import 'regenerator-runtime/runtime';

// eslint-disable-next-line import/no-unresolved
import 'assets/styles/app.css';
import 'assets/styles/custom.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { initSentry } from './shared/sentry';

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
