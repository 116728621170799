import { useLocation } from 'react-router-dom';

import React, { useEffect } from 'react';

import { CLASSTING_OIDC_URL } from 'shared/constants';
import { FullScreenLoader } from 'designSystem/templates';

function EnterpriseSignOutCallbackPage(): React.ReactElement {
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const state = query.get('state');
    const returnTo = state ? JSON.parse(state).returnTo : null;

    const idToken = query.get('id_token_hint');

    if (!idToken && returnTo) {
      window.location.replace(returnTo);
      return;
    }

    if (idToken) {
      const postLogoutRedirectUri = `${window.location.origin}/enterprise/account/post-logout`;
      const signOutUrl = `${CLASSTING_OIDC_URL}/oidc/session/end${search}&post_logout_redirect_uri=${postLogoutRedirectUri}
      `;

      window.location.replace(signOutUrl);
      return;
    }
  }, [search]);

  return <FullScreenLoader />;
}

export default EnterpriseSignOutCallbackPage;
