import React, { useMemo } from 'react';

import { CheckIcon } from 'designSystem/ions/Icons/solid';
import { Box, getThemeObject, Theme } from 'designSystem/ions';

export interface CheckListItemProps {
  text: string;
  iconColor: string;
}

export interface CheckListProps {
  theme?: Theme;
  data: Pick<CheckListItemProps, 'text'>[];
}

const CheckListItemView = (props: CheckListItemProps): JSX.Element => {
  const { text, iconColor } = props;
  const testId = `icon_${text}`;
  const className = `w-4 h-4 ${iconColor}`;
  return (
    <Box is="li" className="flex flex-row w-full item-start mb-4 last:mb-0">
      <Box
        is="div"
        className="
      flex flex-col flex-initial
      items-start py-2px mr-3"
      >
        <CheckIcon.View data-testid={testId} className={className} />
      </Box>
      <Box is="div" className="flex flex-col items-start w-full">
        <Box is="p" className="w-full text-body-3 font-regular text-gray-900">
          {text}
        </Box>
      </Box>
    </Box>
  );
};

export const CheckListItem = React.memo(CheckListItemView);

const CheckList = (props: CheckListProps): JSX.Element => {
  const { data, theme = 'primary' } = props;
  const checkIconColor = useMemo(() => getThemeObject(theme).color.normal, [
    theme,
  ]);

  if (data.length > 0) {
    const listItems = data.map((item, index) => {
      const key = `checkList-${index}`;
      return (
        <CheckListItem key={key} text={item.text} iconColor={checkIconColor} />
      );
    });
    return (
      <Box is="ul" className="flex flex-col w-full">
        {listItems}
      </Box>
    );
  }
  return <Box is="ul" className="flex flex-col w-full"></Box>;
};

export default React.memo(CheckList);
