import React, { useRef, useEffect } from 'react';

import { Box } from 'designSystem/ions';

interface ToastProps {
  message: string;
  duration?: number;
  startAdornment?: React.ReactElement;
  onDismiss?: () => void;
}

const toastStyle = `
fixed flex flex-row justify-center items-center w-max
p-2 bg-gray-800 rounded-lg bottom-8 left-1/2
text-white transform -translate-x-1/2
`;

export const Toast = ({
  message,
  duration = 3000,
  startAdornment,
  onDismiss,
}: ToastProps): JSX.Element => {
  const timerRef = useRef<number>();

  useEffect(() => {
    timerRef.current = window.setTimeout(() => onDismiss?.(), duration);
    return () => clearTimeout(timerRef.current);
  }, [duration, onDismiss]);

  return (
    <Box is="div" className={toastStyle} data-testid="toast">
      {startAdornment ? <Box className="mr-2">{startAdornment}</Box> : null}
      {message}
    </Box>
  );
};
