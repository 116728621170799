import React from 'react';

import { Box } from 'designSystem/ions';

const makeAnimationDelay = (number: number) => {
  return {
    WebkitAnimationDelay: `${number * 0.12}s`,
    msAnimationDelay: `${number * 0.12}s`,
    MozAnimationDelay: `${number * 0.12}s`,
    animationDelay: `${number * 0.12}s`,
  };
};

const Spinner = (): JSX.Element => {
  return (
    <Box is="span" className="inline-flex self-center justify-self-center">
      <Box
        is="span"
        className="rounded-full w-3 h-3 m-1 bg-primary-normal inline-block animate-spinner"
        style={makeAnimationDelay(1)}
      ></Box>
      <Box
        is="span"
        className="rounded-full w-3 h-3 m-1 bg-primary-normal inline-block animate-spinner"
        style={makeAnimationDelay(2)}
      ></Box>
      <Box
        is="span"
        className="rounded-full w-3 h-3 m-1 bg-primary-normal inline-block animate-spinner"
        style={makeAnimationDelay(3)}
      ></Box>
    </Box>
  );
};

export default React.memo(Spinner);
