import React from 'react';

import { SignInMethod } from 'generated/graphql';
import {
  AppleIcon,
  EmailIcon,
  GoogleIcon,
  UserIcon,
  KakaoIcon,
  MobileIcon,
  NaverIcon,
  WhalespaceIcon,
  AzureIcon,
} from 'designSystem/ions/Icons/outlined';

const IconMap = {
  [SignInMethod.Apple]: AppleIcon,
  [SignInMethod.Email]: EmailIcon,
  [SignInMethod.Google]: GoogleIcon,
  [SignInMethod.Id]: UserIcon,
  [SignInMethod.Kakao]: KakaoIcon,
  [SignInMethod.Mobile]: MobileIcon,
  [SignInMethod.Naver]: NaverIcon,
  [SignInMethod.Username]: UserIcon,
  [SignInMethod.Whalespace]: WhalespaceIcon,
  [SignInMethod.Azure]: AzureIcon,
};

interface SignInMethodIconProps {
  method: SignInMethod;
  testId?: string;
}

const SignInMethodIcon = ({
  method,
  testId,
}: SignInMethodIconProps): JSX.Element => {
  const Icon = IconMap[method];
  return <Icon.View data-testid={testId} width="1rem" height="1rem" />;
};

export default SignInMethodIcon;
