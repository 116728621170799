import { useTranslation } from 'react-i18next';

import { EnterpriseDto } from 'generated/graphql';

type useTranslatedTextsReturn = {
  CTATitle: string;
  CTADesc: string;
  confirmButton: string;
};

const useTranslatedTexts = (): useTranslatedTextsReturn => {
  const { t } = useTranslation();
  const CTATitle = t('account.delete_account.done.title');
  const CTADesc = t('account.delete_account.enterprise_done.description');
  const confirmButton = t('account.button.confirm');
  return {
    CTATitle,
    CTADesc,
    confirmButton,
  };
};

export default useTranslatedTexts;
