import React, { useMemo } from 'react';
import type { Ref } from 'react';
import classnames from 'classnames';

import { Box, BoxProps } from 'designSystem/ions';
import { ButtonLikeComponentProps } from 'designSystem/atoms';
import { WithChildrenProp, Without } from 'types/utilities';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

type BoxButtonProps = Without<BoxProps<'button'>, 'is'>;
type RenewChildCodeButtonProps = WithChildrenProp<
  BoxButtonProps & ButtonLikeComponentProps
> & {
  event: TagManagerEventDefinition;
};

const CONTAINER_STYLE = `
flex justify-center items-center h-11 px-4 bg-gray-100 rounded-lg
focus:outline-none focus-visible:ring
disabled:cursor-not-allowed disabled:shadow-none
`;
const TEXT_STYLE = 'text-body-3 font-medium';

const RenewChildCodeButton = (
  {
    children,
    type,
    disabled,
    className,
    testId,
    onClick,
    event,
    ...restProps
  }: RenewChildCodeButtonProps,
  ref: Ref<HTMLButtonElement>
): JSX.Element => {
  const buttonStyle = useMemo(() => classnames(CONTAINER_STYLE, className), [
    className,
  ]);

  return (
    <Box
      is="button"
      type={type}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyle}
      style={{ opacity: disabled ? 0.54 : 1 }}
      ref={ref}
      {...createTrackEventProps(event)}
      {...restProps}
    >
      <Box is="span" className={TEXT_STYLE}>
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(React.forwardRef(RenewChildCodeButton));
