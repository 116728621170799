import React from 'react';

import { useHistory } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import {
  GlobalSection,
  TransparentPanel,
  SingleColumnLayout,
} from 'designSystem/layouts';
import { Button } from 'designSystem/atoms';
import { BlockedBino } from 'assets/classtingFriends';
import {
  CenterTitleNavigationHeader,
  GlobalFooter,
} from 'designSystem/organisms';
import { CallToAction, AnchorButton } from 'designSystem/molecules';
import { Box } from 'designSystem/ions';
import { useHistoryBackHandler } from 'shared/hooks/history';
import { useIsClasstingMobileApp } from 'shared/hooks';

const BlockedBinoAsset = () => (
  <BlockedBino.View
    className="
  w-3/4 sm:w-full
  mx-auto
  "
  />
);

const pageTitle = '오류';

const NotFoundErrorPage = (): JSX.Element => {
  const history = useHistory();
  const historyHandler = useHistoryBackHandler();
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const texts = useTranslatedTexts();
  const handleBackButtonClick = () => {
    historyHandler.go(-1);
  };

  return (
    <GlobalSection.Wrapper>
      <GlobalSection.Header title="클래스팅">
        <CenterTitleNavigationHeader
          title={pageTitle}
          showTitleInMobile={false}
        />
      </GlobalSection.Header>
      <GlobalSection.Body>
        <SingleColumnLayout>
          <TransparentPanel.Wrapper>
            <TransparentPanel.Header />
            <TransparentPanel.Body headerLess>
              <CallToAction
                withAsset="rightAsset"
                title={texts.CTATitle}
                desc={texts.CTADesc}
                asset={BlockedBinoAsset}
                width={BlockedBino.size.width}
              >
                <AnchorButton
                  testId="not-found-error-page__home-button"
                  size="md"
                  appearance="outlined"
                  className="mr-3"
                  href="https://classting.com"
                >
                  {texts.homeButton}
                </AnchorButton>
                <Button
                  testId="not-found-error-page__back-button"
                  size="md"
                  appearance="contained"
                  theme="primary"
                  onClick={handleBackButtonClick}
                >
                  {texts.backButton}
                </Button>
              </CallToAction>
              <Box
                is="p"
                data-testid="not-found-error-page-location-text"
                className="hidden text-center text-body-3 font-regular text-gray-700"
              >
                {history.location.pathname + history.location.hash}
              </Box>
            </TransparentPanel.Body>
          </TransparentPanel.Wrapper>
        </SingleColumnLayout>
      </GlobalSection.Body>
      {!isClasstingMobileApp && (
        <GlobalSection.Footer>
          <GlobalFooter />
        </GlobalSection.Footer>
      )}
    </GlobalSection.Wrapper>
  );
};

export default React.memo(NotFoundErrorPage);
