import React from 'react';

import { RouteProps } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import { BinoSorry } from 'assets/classtingFriends';
import { CenterTitleNavigationHeader } from 'designSystem/organisms';
import { Box } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import { CallToAction, AnchorButton } from 'designSystem/molecules';
import { SingleColumnPageTemplate } from 'designSystem/templates';
import { TransparentPanel } from 'designSystem/layouts';
import { useHistoryBackHandler } from 'shared/hooks/history/useHistoryBackHandler';

interface ErrorPageLocationState {
  reason?: string;
}

const BinoSorryAsset = () => (
  <BinoSorry.View
    className="
  w-2/3 sm:w-full
  mt-4 sm:-mt-8 md:-mt-10
  py-0 sm:py-10px md:py-0
  px-0 sm:px-5px md:px-0
  -mb-2 md:mb-0 mx-auto
  "
  />
);
const ErrorPage = (props: RouteProps): JSX.Element => {
  const historyHandler = useHistoryBackHandler();
  const state = props?.location?.state as ErrorPageLocationState;
  const texts = useTranslatedTexts();
  const handleBackButtonClick = () => {
    historyHandler.go(-1);
  };

  return (
    <SingleColumnPageTemplate
      headerChild={
        <CenterTitleNavigationHeader title="오류" showTitleInMobile={false} />
      }
      hasHeaderShadow={false}
    >
      <TransparentPanel.Wrapper>
        <TransparentPanel.Header />
        <TransparentPanel.Body headerLess>
          <CallToAction
            withAsset="rightAsset"
            title={texts.CTATitle}
            desc={texts.CTADesc}
            asset={BinoSorryAsset}
            width={BinoSorry.size.width}
          >
            <AnchorButton
              testId="error-page__home-button"
              size="md"
              appearance="outlined"
              className="mr-3"
              href="https://classting.com"
            >
              {texts.homeButton}
            </AnchorButton>
            <Button
              testId="error-page__back-button"
              size="md"
              appearance="contained"
              theme="primary"
              onClick={handleBackButtonClick}
            >
              {texts.backButton}
            </Button>
          </CallToAction>
          <Box
            is="p"
            data-testid="error-reason-text"
            className="hidden text-center text-body-3 font-regular text-gray-700"
          >
            {state?.reason ? `${state?.reason}` : ''}
          </Box>
        </TransparentPanel.Body>
      </TransparentPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default React.memo(ErrorPage);
