import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'react-oidc-context';

import { sessionStorageService } from 'core/storage';
import { FullScreenLoader } from 'designSystem/templates';

const AuthCallbackPage = (): JSX.Element | null => {
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      const redirectPath = sessionStorageService.getItem<string>('redirect', {
        raw: true,
      });

      sessionStorageService.removeItem('redirect');

      history.replace(redirectPath ?? '');
    }
  }, [auth, history]);

  return <FullScreenLoader />;
};

export default AuthCallbackPage;
