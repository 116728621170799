import * as yup from 'yup';
import {
  useForm,
  DeepMap,
  FieldValues,
  FieldError,
  ChangeHandler,
  UseFormSetError,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type Field = {
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  ref: React.Ref<HTMLInputElement>;
  name: string;
  value: string;
};

export type FieldErrors = DeepMap<FieldValues, FieldError>;

const useFormRegister = (
  fieldNames: string[],
  scheme: yup.AnyObjectSchema,
  defaultValues: Record<string, unknown> = {}
): {
  fields: Map<string, Field>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  setError: UseFormSetError<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
} => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(scheme),
    defaultValues,
  });

  const values = watch();
  const fields = new Map();

  fieldNames.forEach((name) => {
    fields.set(name, {
      ...register(`${name}` as const),
      value: values[name],
    });
  });

  return {
    fields,
    setValue,
    errors,
    setError,
    handleSubmit,
  };
};

export default useFormRegister;
