interface WebViewMessageObject {
  category: BridgeMessageCategory;
  type: BridgeMessageType;
  callback?: string;
  payload?: Record<string, unknown>;
}
type BridgeMessageCategory = 'auth' | 'navigation' | 'interaction' | 'webView';
type BridgeMessageType =
  | 'social_login'
  | 'app_auth_migration'
  | 'get_token'
  | 'open'
  | 'close'
  | 'share'
  | 'change_config';

export class BridgeMessageBuilder {
  private messageObject = {} as WebViewMessageObject;

  constructor(category: BridgeMessageCategory, type: BridgeMessageType) {
    this.messageObject.category = category;
    this.messageObject.type = type;
  }

  setCallback(callback: string): BridgeMessageBuilder {
    this.messageObject.callback = callback;
    return this;
  }

  setPayload(payload: Record<string, unknown>): BridgeMessageBuilder {
    this.messageObject.payload = payload;
    return this;
  }

  build(): string {
    return JSON.stringify(this.messageObject);
  }
}
