import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const Check = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      data-testid="check-icon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M15.036 4.764a.9.9 0 010 1.272l-7.2 7.2a.9.9 0 01-1.272 0l-3.6-3.6a.9.9 0 011.272-1.272L7.2 11.327l6.564-6.563a.9.9 0 011.272 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const Asset = {
  size,
  View: React.memo(Check),
};

export default Asset;
