import React from 'react';

import classNames from 'classnames';

import { Box, useIsInMobile } from 'designSystem/ions';
import { LinkIcon } from 'designSystem/ions/Icons/outlined';
import { SimpleAccountDto } from 'generated/graphql';
import { Button, ProfileImage } from 'designSystem/atoms';
import { createTrackEventProps } from 'shared/analytics/utils';
import useTranslatedTexts from 'pages/LinkGuardianPage/useTranslatedTexts';

type AgreeToLinkProps = {
  guardianAccount: SimpleAccountDto;
  accountId: string;
  onClickNext: () => void;
  onClickCancel: () => void;
};

const AgreeToLink = ({
  guardianAccount,
  accountId,
  onClickCancel,
  onClickNext,
}: AgreeToLinkProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const isInMobile = useIsInMobile();

  return (
    <Box
      data-testid="agree-to-link-content"
      className="flex flex-col items-center mt-8 xs:mt-12"
    >
      <LinkIcon.View className="w-8 h-8 text-ctGreen-500" />
      <Box
        data-testid="title"
        is="h3"
        className="text-20 font-bold text-gray-900 mt-4"
      >
        {texts.title.confirmLink}
      </Box>
      <Box
        data-testid="guardian-profile"
        className="flex flex-row items-center justify-center w-full gap-2 mt-10  py-6 border border-solid border-gray-200 rounded-xl"
      >
        <ProfileImage
          className="max-w-9 w-9 h-9 rounded-full"
          src={guardianAccount.avatarImage || ''}
        />
        <Box className="text-gray-800 font-medium">{guardianAccount.name}</Box>
      </Box>
      <Box
        data-testid="description"
        className={
          'flex text-center whitespace-pre-line text-14 text-gray-700 mt-6'
        }
      >
        <Box>{texts.description.confirmLink}</Box>
      </Box>
      <Box
        className={classNames('flex gap-3', {
          'fixed z-50 bottom-4 w-full px-4 flex-col': isInMobile,
          'flex-row-reverse mt-12 self-end flex-wrap break-all': !isInMobile,
        })}
      >
        <Button
          testId="next-button"
          theme="primary"
          appearance="contained"
          onClick={onClickNext}
          {...createTrackEventProps({
            name: 'LinkGuardianStarted',
            on: 'click',
            properties: {
              userId: accountId,
              parentId: guardianAccount.id,
            },
          })}
        >
          {texts.button.next}
        </Button>
        <Button
          testId="cancel-button"
          theme="white"
          appearance="text"
          onClick={onClickCancel}
          {...createTrackEventProps({
            name: 'LinkGuardianCancelled',
            on: 'click',
            properties: {
              userId: accountId,
              parentId: guardianAccount.id,
            },
          })}
        >
          {texts.button.cancel}
        </Button>
      </Box>
    </Box>
  );
};

export default AgreeToLink;
