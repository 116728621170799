import { SchoolGrade } from 'generated/graphql';
import { CLASSTING_OIDC_URL } from 'shared/constants';

export const DEFAULT_PROFILE_IMAGE = `${CLASSTING_OIDC_URL}/account/avatars/default.png`;
export const AGE_LIMIT = 14;
export const LEGACY_EMPTY_BIRTHYEAR_FALLBACK = '0000';
export const EMPTY_BIRTHYEAR_FALLBACK = '8888';
export const FALLBACK_LANGUAGE = 'ko';
export const FALLBACK_COUNTRY_CODE = 'KR';
export const EMPTY_SCHOOL_INFO = {
  school: {
    id: '',
    name: '',
    admissionAge: -1,
    regularCourseYear: -1,
    zipcode: '',
  },
  grade: SchoolGrade.None,
  isErrorVisible: false,
};
