import React from 'react';

import useTranslatedTexts from '../../useTranslatedTexts';

import LinkedGuardianListItem from './LinkedGuardianListItem';

import { Box } from 'designSystem/ions';

import { RelationshipType, GuardianAccountDto } from 'generated/graphql';

interface LinkedGuardianListProps {
  guardianAccounts: {
    relationType: RelationshipType;
    guardianAccount: GuardianAccountDto;
  }[];
}

const LinkedGuardianList = ({
  guardianAccounts,
}: LinkedGuardianListProps): JSX.Element => {
  const texts = useTranslatedTexts();

  return (
    <Box
      data-testid="linked-guardian-list"
      className="flex flex-col w-full gap-4"
    >
      {guardianAccounts.length ? (
        guardianAccounts.map(({ relationType, guardianAccount }) => {
          return (
            <LinkedGuardianListItem
              key={guardianAccount.id}
              name={guardianAccount.name}
              profileImage={guardianAccount.avatarImage || ''}
              relation={texts.relation[relationType] || ''}
            />
          );
        })
      ) : (
        <Box
          data-testid="child-code-page-content-empty-guardian"
          className="pt-6 pb-4 leading-normal text-center text-14 text-gray-700 whitespace-pre-line"
        >
          {texts.message.emptyGuardian}
        </Box>
      )}
    </Box>
  );
};

export default LinkedGuardianList;
