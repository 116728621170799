import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useRequestMobileChangeCommand, useChangeMobileCommand } from './hooks';
import useTranslatedTexts from './useTranlatedTexts';

import { Box, useIsInSmScreen } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import {
  CenterTitleNavigationHeader,
  SectionPanel,
  TimerTextField,
  DropdownTextField,
  useModalContext,
} from 'designSystem/organisms';
import { SingleColumnPageTemplate } from 'designSystem/templates';

import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { countryCode } from 'shared/constants';

import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

const countryCodeItems = countryCode.map(({ dialCode, name }, index) => {
  return {
    key: dialCode + index,
    value: dialCode,
    label: name,
  };
});

/* istanbul ignore next */
const isEnterKey = (event: React.KeyboardEvent) => {
  /* keyCode for ie 11 compatibility  */
  return event.key === 'Enter' || event.keyCode === 13;
};

const MobileCodeExpiredMessage = ({
  onResend,
  event,
}: {
  onResend: () => void;
  event: TagManagerEventDefinition;
}) => {
  const texts = useTranslatedTexts();

  const resendedButtonTrackEventProps = event && createTrackEventProps(event);

  return (
    <Box
      data-testid="expired-message"
      className="flex flex-row items-center mt-5 sm:mt-6"
    >
      <Box
        is="p"
        className="mr-1 text-caption sm:text-body-3 text-gray-700 font-regular cursor-default"
      >
        {texts.message.expiredMobileCode}
      </Box>
      <Box
        is="button"
        className="text-caption sm:text-body-3 text-ctGreen-500 font-medium focus:outline-none focus-visible:ring"
        onClick={onResend}
        {...resendedButtonTrackEventProps}
      >
        {texts.button.resendMobileCode}
      </Box>
    </Box>
  );
};

type ChangeMobileNumberPageProps = {
  account: Account;
};

const ChangeMobileNumberPage = ({
  account,
}: ChangeMobileNumberPageProps): JSX.Element => {
  const [expired, setExpireState] = useState(false);
  const { openModal } = useModalContext();
  const history = useHistory();
  const texts = useTranslatedTexts();
  const isSmScreen = useIsInSmScreen();
  const buttonSize = isSmScreen ? 'md' : 'lg';

  const {
    inputProps: mobileNumberInputProps,
    buttonProps: requestMobileCodeButtonProps,
    mutation: { isSuccess: isMobileCodeRequested, data: requestCodeData },
    expireTimeout,
    handler: sendMobileCode,
    resendHandler: resendMobileCode,
  } = useRequestMobileChangeCommand({
    account,
    history,
  });

  const {
    inputProps: mobileCodeInputProps,
    buttonProps: requestMobileChangeButtonProps,
    handler: changeMobileNumber,
  } = useChangeMobileCommand({
    account,
    history,
  });

  const handleSendMobileCode = () => {
    /* istanbul ignore else */
    if (!isMobileCodeRequested) {
      setExpireState(false);
      sendMobileCode();
    }
  };

  /* istanbul ignore next */
  const handleResendMobileCode = () => {
    setExpireState(false);
    resendMobileCode();
  };

  const handleChangeMobile = () => {
    /* istanbul ignore else */
    if (requestCodeData) {
      changeMobileNumber(requestCodeData.id, openModal);
    }
  };

  /* istanbul ignore next */
  const handleKeyPressMobileNumberInput = (event: React.KeyboardEvent) => {
    if (
      !isMobileCodeRequested &&
      !requestMobileCodeButtonProps.disabled &&
      isEnterKey(event)
    ) {
      handleSendMobileCode();
    }
  };

  /* istanbul ignore next */
  const handleKeyPressMobileCodeInput = (event: React.KeyboardEvent) => {
    if (
      !expired &&
      !requestMobileChangeButtonProps.disabled &&
      isEnterKey(event)
    ) {
      handleChangeMobile();
    }
  };

  const handleMobileCodeExpired = useCallback(() => setExpireState(true), []);

  return (
    <SingleColumnPageTemplate
      showFooter={false}
      headerChild={<CenterTitleNavigationHeader title={texts.title} />}
      hasHeaderShadow={false}
    >
      <SectionPanel.Wrapper className="w-full">
        <SectionPanel.Header
          showBackButton={true}
          title={texts.title}
          description={texts.description}
          backTo="/account/info"
        />
        <SectionPanel.Body>
          <SectionPanel.Item className="mt-5 sm:mt-6">
            <DropdownTextField
              testId="mobile-number-input"
              type="text"
              name="mobile-number-input"
              label={texts.label.newMobile}
              placeholder={texts.placeholder.mobile}
              disabled={isMobileCodeRequested}
              dropdownItems={countryCodeItems}
              onKeyPress={handleKeyPressMobileNumberInput}
              {...mobileNumberInputProps}
            />
          </SectionPanel.Item>
          {isMobileCodeRequested && (
            <>
              <SectionPanel.Item className="mt-5 sm:mt-6">
                <TimerTextField
                  testId="mobile-code-input"
                  type="text"
                  name="mobile-code-input"
                  initialTime={expireTimeout}
                  label={texts.label.mobileCode}
                  placeholder={texts.placeholder.code}
                  onKeyPress={handleKeyPressMobileCodeInput}
                  onFinish={handleMobileCodeExpired}
                  {...mobileCodeInputProps}
                />
              </SectionPanel.Item>
              {expired && (
                <MobileCodeExpiredMessage
                  onResend={handleResendMobileCode}
                  event={{
                    name: 'MobileCodeResended',
                    on: 'click',
                    properties: {
                      userId: account.id,
                      role: account.role,
                    },
                  }}
                />
              )}
            </>
          )}
          <SectionPanel.Item className="mt-5 sm:mt-6">
            {isMobileCodeRequested ? (
              <Button
                testId="change-mobile-button"
                size={buttonSize}
                appearance="contained"
                className="w-full"
                onClick={handleChangeMobile}
                disabled={requestMobileChangeButtonProps.disabled || expired}
              >
                {texts.button.changeMobile}
              </Button>
            ) : (
              <Button
                testId="verify-mobile-button"
                size={buttonSize}
                appearance="contained"
                className="w-full"
                onClick={handleSendMobileCode}
                {...requestMobileCodeButtonProps}
              >
                {texts.button.verifyMobile}
              </Button>
            )}
          </SectionPanel.Item>
        </SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default withAccount(ChangeMobileNumberPage);
