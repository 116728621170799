import InfoPages from './InfoPages';
import DeleteConfirmPage from './DeleteConfirmPage';
import DeleteSuccessPage from './DeleteSuccessPage';
import ChangePasswordPage from './ChangePasswordPage';
import ChangeEmailPage from './ChangeEmailPage';
import ChangeMobilePage from './ChangeMobilePage';
import ErrorPages from './ErrorPages';
import AuthPages from './AuthPages';
import LinkChildByUrlPage from './LinkChildByUrlPage';
import LinkGuardianPage from './LinkGuardianPage';
import GuardianConnectPage from './GuardianConnectPage';
import MarketingAgreementNoticePage from './MarketingAgreementNoticePage';
import EnterpriseInfoPages from './EnterpriseInfoPages';
import EnterpriseSignOutCallbackPage from './EnterpriseSignOutCallbackPage';
import EnterpriseDeleteConfirmPage from './EnterpriseDeleteConfirmPage';
import EnterpriseDeleteSuccessPage from './EnterpriseDeleteSuccessPage';
import EnterpriseChangeEmailPage from './EnterpriseChangeEmailPage';
import EnterpriseChangeMobilePage from './EnterpriseChangeMobilePage';

const pages = {
  DeleteConfirmPage,
  DeleteSuccessPage,
  ChangePasswordPage,
  ChangeEmailPage,
  ChangeMobilePage,
  LinkChildByUrlPage,
  LinkGuardianPage,
  GuardianConnectPage,
  MarketingAgreementNoticePage,
  ...InfoPages,
  ...AuthPages,
  ...ErrorPages,
  EnterpriseInfoPages,
  EnterpriseSignOutCallbackPage,
  EnterpriseDeleteConfirmPage,
  EnterpriseDeleteSuccessPage,
  EnterpriseChangeEmailPage,
  EnterpriseChangeMobilePage,
};

export default pages;
