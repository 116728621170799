import React, { useEffect, useRef, useState } from 'react';
import range from 'lodash/range';

import { ChildSchoolInfo } from '../ChildInfoPanel/ChildInfoForm/schema';
import useTranslatedTexts from '../useTranslatedTexts';

import { SearchSchoolDialog } from './SearchSchoolDialog';

import { Button, Dropdown, Label } from 'designSystem/atoms';
import { TextField } from 'designSystem/molecules';
import { RemoveIcon } from 'designSystem/ions/Icons/outlined';
import { School, SchoolGrade } from 'generated/graphql';
import { Box } from 'designSystem/ions';

import { useGradeTranslations } from 'shared/hooks/translation/useGradeTranslation';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';
import { MySchoolInfo } from 'shared/data/account';
import { K12_NUMBER_TO_GRADE } from 'shared/constants/grades';

type SchoolInfoInputProps = {
  index: number;
  schoolInfo: ChildSchoolInfo | MySchoolInfo;
  removeButtonVisible: boolean;
  onChangeSchool: (school: School, index: number) => void;
  onChangeGrade: (selectedGrade: SchoolGrade, index: number) => void;
  onClickDelete?: (index: number) => void;
  event?: TagManagerEventDefinition;
  closeEvent?: TagManagerEventDefinition;
};

type DropdownItem = { key: React.Key; label: string; value: string | number };

const getMatchingDropdownItem = (
  dropdownItems: DropdownItem[],
  value: string | number
): DropdownItem => {
  const matchingItem = dropdownItems.find((item) => item.value === value);

  if (!matchingItem) {
    return {
      key: '',
      label: '',
      value: '',
    };
  }
  return matchingItem;
};

const SchoolInfoInput = ({
  index,
  schoolInfo,
  removeButtonVisible,
  onChangeSchool,
  onChangeGrade,
  onClickDelete,
  event,
  closeEvent,
}: SchoolInfoInputProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const { translateGrade } = useGradeTranslations();
  const textInputRef = useRef<HTMLInputElement>(null);

  const grade = schoolInfo.grade.toUpperCase();

  const [
    isSearchSchoolDialogVisible,
    setIsSearchSchoolDialogVisible,
  ] = React.useState(false);

  useEffect(() => {
    if (textInputRef !== null) textInputRef.current?.blur();
  }, [isSearchSchoolDialogVisible]);

  const createGradeDropdownItems = (
    admission_age?: number,
    regular_course_year?: number
  ) => {
    if (admission_age === undefined || regular_course_year === undefined) {
      return [];
    }

    return range(
      admission_age - 6,
      admission_age - 6 + regular_course_year
    ).map((course_year) => {
      const grade = K12_NUMBER_TO_GRADE[course_year];

      const translate = translateGrade(grade);

      return {
        key: translate,
        value: grade,
        label: translate,
      };
    });
  };

  const gradeDropdownItems = createGradeDropdownItems(
    schoolInfo.school.admissionAge,
    schoolInfo.school.regularCourseYear
  );

  const selectedGradeLabel =
    grade !== undefined &&
    schoolInfo.school.admissionAge !== undefined &&
    schoolInfo.school.regularCourseYear !== undefined
      ? getMatchingDropdownItem(gradeDropdownItems, grade).label
      : undefined;

  const isGradeDropdownDisabled =
    schoolInfo.school.admissionAge === undefined ||
    schoolInfo.school.regularCourseYear === undefined ||
    (schoolInfo.school.admissionAge as number) +
      (schoolInfo.school.regularCourseYear as number) <=
      0;

  const isGradeErrorVisible =
    schoolInfo.grade === 'NONE' &&
    schoolInfo.school.regularCourseYear !== undefined &&
    schoolInfo.school.regularCourseYear > 0 &&
    schoolInfo.isErrorVisible !== undefined &&
    schoolInfo.isErrorVisible;

  const handleClickDelete = () => {
    if (onClickDelete && index !== undefined) {
      onClickDelete(index);
    }
  };

  const handleChangeGrade = (grade: SchoolGrade) => {
    onChangeGrade(grade, index);
  };

  const handleClickSchoolNameInput = () => {
    setIsSearchSchoolDialogVisible(true);
  };

  const closeDialog = () => {
    setIsSearchSchoolDialogVisible(false);
  };

  const handleSelectSchool = (school: School, index: number) => {
    onChangeSchool(school, index);
  };

  const schoolInputTrackEventProps = event && createTrackEventProps(event);

  return (
    <Box data-testid="school-info-input" is="div" className="flex">
      <Box is="div" className="flex-1 mr-3">
        <TextField
          testId="school-name-input"
          type="text"
          value={schoolInfo.school.name}
          label={texts.label.institution}
          placeholder={texts.placeholder.institutionName}
          onClick={handleClickSchoolNameInput}
          helperTexts={[
            {
              status: 'error',
              visible: isGradeErrorVisible,
              message: texts.message.empty_grade,
            },
          ]}
          readOnly
          {...schoolInputTrackEventProps}
          ref={textInputRef}
        />
      </Box>
      <Box>
        <Box className="mb-1">
          <Label>{texts.label.grade}</Label>
        </Box>
        <Box className="flex flex-row items-center">
          <Dropdown
            testId="school-grade-dropdown"
            defaultValue={selectedGradeLabel}
            onValueChange={handleChangeGrade}
            className="w-32"
            disabled={isGradeDropdownDisabled}
          >
            <Dropdown.Trigger
              placeholder={texts.placeholder.select}
              align="left"
              className="w-full"
              hasError={isGradeErrorVisible}
            >
              {selectedGradeLabel}
            </Dropdown.Trigger>
            <Dropdown.Menu className="w-full max-h-44">
              {gradeDropdownItems.map(({ key, label, value }) => (
                <Dropdown.MenuItem align="left" key={key} value={value}>
                  {label}
                </Dropdown.MenuItem>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {removeButtonVisible && (
            <Button
              testId="remove-button"
              appearance="text"
              onClick={handleClickDelete}
              {...createTrackEventProps({
                name: 'RemoveChildInfoClicked',
                on: 'click',
              })}
            >
              <RemoveIcon.View {...RemoveIcon.size} />
            </Button>
          )}
        </Box>
      </Box>
      <SearchSchoolDialog
        index={index}
        open={isSearchSchoolDialogVisible}
        closeDialog={closeDialog}
        onSelectSchool={handleSelectSchool}
        event={closeEvent}
      />
    </Box>
  );
};

export default SchoolInfoInput;
