/* istanbul ignore file */

import React from 'react';

const size = {
  width: '24px',
  height: '24px',
};

const ChevronLeft = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.828 12L15.778 16.95L14.364 18.364L8 12L14.364 5.63599L15.778 7.04999L10.828 12Z"
        fill="#757575"
      />
    </svg>
  );
};

const Asset = {
  size,
  View: ChevronLeft,
};

export default Asset;
