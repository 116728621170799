import { QueryClient } from 'react-query';

import { clearCredential } from 'core/credential/credential.service';
import {
  localStorageService,
  sessionStorageService,
  memoryStorageService,
} from 'core/storage';

export function clearStoredData({
  queryClient,
}: {
  queryClient: QueryClient;
}): void {
  clearCredential();
  localStorageService.clear();
  sessionStorageService.clear();
  memoryStorageService.clear();
  queryClient.clear();
  document.cookie = '';
}
