import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';

import { RiArrowRightSLine } from 'react-icons/ri';

import {
  Box,
  Text,
  Switch,
  Typography,
  Divider,
} from '@classtinginc/design-system';

import toast from 'react-hot-toast';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { CheckboxCircle } from '../../../designSystem/ions/Icons/fill';

import { Button } from '../../../designSystem/atoms';

import useTranslatedTexts from './useTranslatedTexts';

import { SideMenuPageTemplate } from 'designSystem/templates';
import { withAccount } from 'shared/root';
import { Account, useUpdateAccount } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks';
import {
  SectionPanel,
  SimpleModal,
  useModalContext,
  GlobalAppBar,
  CenterTitleNavigationHeader,
} from 'designSystem/organisms';

export const MarketingAgreementPage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const texts = useTranslatedTexts();
  const { i18n } = useTranslation();
  const { openModal, closeModal } = useModalContext();

  const allowLanguage: { [key: string]: string } = {
    ko: 'ko',
    en: 'en',
    'ja-JP': 'ja',
    'zh-TW': 'zh-tw',
    'vi-VN': 'vi',
  };
  const language = allowLanguage[i18n.language] ?? 'en';
  const originalPoliciesLink = `https://policies.classting.com/${language}/events`;
  const policiesLink = isClasstingMobileApp
    ? `classting://webview?url=${encodeURIComponent(
        originalPoliciesLink
      )}&transition_type=sheet&appbar=1`
    : originalPoliciesLink;

  const nowDate = dayjs();

  const agreedMessage = texts.message.setAgreed(
    nowDate.format('YYYY'),
    nowDate.format('MM'),
    nowDate.format('DD')
  );

  const disagreedMessage = texts.message.setDisagreed(
    nowDate.format('YYYY'),
    nowDate.format('MM'),
    nowDate.format('DD')
  );

  const updateAccountMutation = useUpdateAccount();
  const [agreedToggles, setAgreedToggles] = useState({
    isPushAgreed: account.marketingAgreement?.isPushAgreed ?? false,
    isSMSAgreed: account.marketingAgreement?.isSMSAgreed ?? false,
    isEmailAgreed: account.marketingAgreement?.isEmailAgreed ?? false,
  });

  const refs = useRef(false);

  const { isPushAgreed, isSMSAgreed, isEmailAgreed } = agreedToggles;

  const isAgreed = useMemo(
    () =>
      agreedToggles.isEmailAgreed ||
      agreedToggles.isPushAgreed ||
      agreedToggles.isSMSAgreed,
    [agreedToggles]
  );

  const handleClickModalClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    closeModal();
  };

  const handleErrorModalOpen = () => {
    openModal({
      status: 'default',
      message: texts.message.temporaryError,
      buttonGroup: (
        <SimpleModal.ButtonGroup>
          <Button
            testId="error-modal-close-button"
            theme="primary"
            appearance="contained"
            onClick={handleClickModalClose}
          >
            {texts.button.confirm}
          </Button>
        </SimpleModal.ButtonGroup>
      ),
    });
  };

  const onCheckedChange = (name: string, checked: boolean) => {
    refs.current = true;

    const agreeds = {
      ...agreedToggles,
      [name]: checked,
    };

    updateAccountMutation.mutate(
      {
        userId: account.id,
        data: {
          marketingAgreement: {
            isPushAgreed: agreeds.isPushAgreed,
            isEmailAgreed: agreeds.isEmailAgreed,
            isSMSAgreed: agreeds.isSMSAgreed,
          },
        },
      },
      {
        onSuccess: () => {
          setAgreedToggles({
            ...agreeds,
          });
        },
        onError: () => {
          handleErrorModalOpen();
        },
      }
    );
  };

  const currentToastId = useRef('');
  const showAgreedToast = useCallback(
    (message: string) => {
      if (currentToastId.current !== '') {
        toast.remove(currentToastId.current);
      }

      const toastId = toast(
        <Box
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box as="div">
            <CheckboxCircle.View {...CheckboxCircle.size} />
          </Box>
          <Typography
            as="div"
            css={{
              marginLeft: '$03',
            }}
            size="sub-body"
            color="dark"
          >
            {message}
          </Typography>
        </Box>,
        {
          position: isClasstingMobileApp ? 'bottom-center' : 'top-center',
          style: {
            maxWidth: 'inherit',
            margin: isClasstingMobileApp ? '0rem' : '4.375rem',
            padding: '7px 0px',
          },
        }
      );

      currentToastId.current = toastId;
    },
    [isClasstingMobileApp]
  );

  useEffect(() => {
    if (!refs.current) return;

    showAgreedToast(isAgreed ? agreedMessage : disagreedMessage);
  }, [isAgreed, agreedMessage, disagreedMessage, showAgreedToast]);

  return (
    <SideMenuPageTemplate
      account={account}
      headerChild={
        isClasstingMobileApp ? (
          <CenterTitleNavigationHeader title={texts.title} />
        ) : (
          <GlobalAppBar account={account} />
        )
      }
    >
      <SectionPanel.Wrapper className="w-full pt-8">
        <SectionPanel.Body>
          <Box
            css={{
              width: '100%',
            }}
          >
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography as="div" size="body" color="dark">
                  {texts.label.marketingAgreement}
                </Typography>
              </Box>
              <Box
                as="a"
                href={policiesLink}
                target={isClasstingMobileApp ? '_self' : '_blank'}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                data-testid="policies-link"
              >
                <Typography as="div" size="body" color="subDark">
                  {texts.label.showTerms}
                </Typography>
                <Text size="140" color="medium">
                  <RiArrowRightSLine />
                </Text>
              </Box>
            </Box>
            <Divider
              type="full-bleed"
              css={{ marginBottom: '$06', marginTop: '$06' }}
            ></Divider>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography as="div" size="body" color="dark">
                {texts.label.emailAgreed}
              </Typography>
              <Switch
                checked={isEmailAgreed}
                onCheckedChange={(checked: boolean) =>
                  onCheckedChange('isEmailAgreed', checked)
                }
                data-testid="is-email-agreed-switch"
              />
            </Box>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '$06',
              }}
            >
              <Typography as="div" size="body" color="dark">
                {texts.label.smsAgreed}
              </Typography>
              <Switch
                checked={isSMSAgreed}
                onCheckedChange={(checked: boolean) =>
                  onCheckedChange('isSMSAgreed', checked)
                }
                data-testid="is-sms-agreed-switch"
              />
            </Box>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '$06',
              }}
            >
              <Typography as="div" size="body" color="dark">
                {texts.label.pushAgreed}
              </Typography>
              <Switch
                checked={isPushAgreed}
                onCheckedChange={(checked: boolean) =>
                  onCheckedChange('isPushAgreed', checked)
                }
                data-testid="is-push-agreed-switch"
              />
            </Box>
          </Box>
        </SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SideMenuPageTemplate>
  );
};

export default withAccount(MarketingAgreementPage);
