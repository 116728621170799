import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';

import { getCredential } from 'core/credential/credential.service';

import { API_URL } from 'shared/constants';

const authLink = new ApolloLink((operation, forward) => {
  const credential = getCredential();

  if (credential) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${credential.access_token}`,
      },
    });
  }

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: `${API_URL}/graphql/`,
});

const cache = new InMemoryCache({});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
