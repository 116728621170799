/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

import React, { memo } from 'react';

import { Box } from 'designSystem/ions';
import { useHistoryBackHandler } from 'shared/hooks/history';
import { WebViewBridge } from 'shared/webViewBridge';
import { XIcon, ChevronLeft } from 'designSystem/ions/Icons/solid';

interface CenterTitleNavigationHeaderProps {
  title?: string;
  showTitleInMobile?: boolean;
  showBackButtonInMobile?: boolean;
  backButtonBehavior?: 'back' | 'close';
}

const CenterTitleNavigationHeader = (
  props: CenterTitleNavigationHeaderProps
): JSX.Element => {
  const { title = '', backButtonBehavior = 'back' } = props;
  const historyHandler = useHistoryBackHandler();

  const handleBackButtonClick = () => {
    if (backButtonBehavior === 'close') {
      WebViewBridge.requestNavigationClose();
    } else {
      historyHandler.go(-1);
    }
  };

  const titleClassName = `
  text-body-1 font-medium text-center text-gray-900
  flex visible
  `;

  const backButtonClassName = `
  flex-col items-start justify-center h-full
  px-15px
  focus:outline-none focus-visible:ring
  flex visible
  `;

  return (
    <>
      <Box
        is="div"
        className="flex items-center absolute min-h-14 sm:min-h-15 z-20"
      >
        <Box
          is="button"
          data-testid="center-title-navigation-header__back-button"
          className={backButtonClassName}
          tabIndex={0}
          aria-label="Go back"
          onClick={handleBackButtonClick}
        >
          {backButtonBehavior === 'back' ? (
            <ChevronLeft.View className="-ml-px w-6 h-6" />
          ) : (
            <XIcon.View className="-ml-px w-6 h-6 text-gray-600" />
          )}
        </Box>
      </Box>
      <Box is="div" className="container min-h-14 sm:min-h-15">
        <Box
          is="div"
          className="relative row h-full py-1 flex"
          data-testid="center-title-navigation-header"
        >
          <Box is="div" className="absolute w-full h-full pb-2 z-10">
            <Box is="div" className="flex h-full justify-center items-center">
              <Box
                is="p"
                data-testid="center-title-navigation-header__title"
                className={titleClassName}
              >
                {title}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(CenterTitleNavigationHeader);
