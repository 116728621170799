import React from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import StudentCodePageContent from './StudentCodePageContent';

import {
  SectionPanel,
  GlobalAppBar,
  CenterTitleNavigationHeader,
} from 'designSystem/organisms';
import { SideMenuPageTemplate } from 'designSystem/templates';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks';

export const StudentCodePage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const isAllowed = account.role === 'teacher';
  const texts = useTranslatedTexts();
  const isClasstingMobileApp = useIsClasstingMobileApp();

  const renderContent = () => {
    if (!isAllowed) {
      return <Redirect to="/not-found" />;
    }

    return <StudentCodePageContent />;
  };

  return (
    <SideMenuPageTemplate
      account={account}
      headerChild={
        isClasstingMobileApp ? (
          <CenterTitleNavigationHeader title={texts.title.studentCode} />
        ) : (
          <GlobalAppBar account={account} />
        )
      }
    >
      <SectionPanel.Wrapper className="w-full sm:px-5.5 sm:pt-2.5">
        {isClasstingMobileApp && (
          <SectionPanel.Header
            showBackButton={isClasstingMobileApp}
            showTitleInMobile={isClasstingMobileApp}
            showBackButtonOnMobile={isClasstingMobileApp}
          />
        )}

        <SectionPanel.Body>{renderContent()}</SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SideMenuPageTemplate>
  );
};

export default withAccount(StudentCodePage);
