import React from 'react';
import type { Ref } from 'react';

import { noop } from 'lodash';

import { Box, BoxProps } from 'designSystem/ions';

import { Without } from 'types/utilities';

type CheckBoxProps = {
  id: string;
  checked: boolean;
  color: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};

type BoxInputProps = Without<BoxProps<'input'>, 'is'>;

type Props = BoxInputProps & CheckBoxProps;

const CheckBox = (
  { id, checked, onClick, color, ...rest }: Props,
  ref: Ref<HTMLInputElement>
): JSX.Element => {
  const className = `form-checkbox w-4 h-4 border rounded cursor-pointer border-gray-400 ${color}`;
  return (
    <Box
      is="input"
      ref={ref}
      id={id}
      aria-label={id}
      type="checkbox"
      checked={checked}
      onClick={onClick}
      onChange={noop}
      className={className}
      {...rest}
    />
  );
};

export default React.memo(React.forwardRef(CheckBox));
