/* istanbul ignore file */

import React from 'react';

const size = {
  width: '32px',
  height: '32px',
};

function UserWithHeartIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.7882 20.8788L24.0228 21.1148L24.2602 20.8788C24.5387 20.6003 24.8695 20.3793 25.2334 20.2285C25.5974 20.0777 25.9875 20.0001 26.3815 20.0001C26.7755 20.0001 27.1656 20.0777 27.5296 20.2285C27.8935 20.3793 28.2243 20.6003 28.5028 20.8788C28.7814 21.1574 29.0024 21.4881 29.1532 21.8521C29.3039 22.2161 29.3815 22.6062 29.3815 23.0002C29.3815 23.3941 29.3039 23.7842 29.1532 24.1482C29.0024 24.5122 28.7814 24.8429 28.5028 25.1215L24.0228 29.6002L19.5455 25.1215C18.9829 24.5589 18.6668 23.7958 18.6668 23.0002C18.6668 22.2045 18.9829 21.4414 19.5455 20.8788C20.1081 20.3162 20.8712 20.0001 21.6668 20.0001C22.4625 20.0001 23.2255 20.3162 23.7882 20.8788ZM16.0002 18.6668V21.3335C13.8784 21.3335 11.8436 22.1763 10.3433 23.6766C8.84302 25.1769 8.00016 27.2118 8.00016 29.3335H5.3335C5.33353 26.5623 6.41208 23.8998 8.34079 21.9099C10.2695 19.92 12.8969 18.7588 15.6668 18.6722L16.0002 18.6668ZM16.0002 1.3335C20.4202 1.3335 24.0002 4.9135 24.0002 9.3335C24.0009 11.4035 23.199 13.3932 21.763 14.8842C20.327 16.3751 18.3688 17.2512 16.3002 17.3282L16.0002 17.3335C11.5802 17.3335 8.00016 13.7535 8.00016 9.3335C7.99942 7.26346 8.80137 5.27377 10.2374 3.78281C11.6734 2.29186 13.6316 1.41579 15.7002 1.33883L16.0002 1.3335ZM16.0002 4.00016C13.0535 4.00016 10.6668 6.38683 10.6668 9.3335C10.6668 12.2802 13.0535 14.6668 16.0002 14.6668C18.9468 14.6668 21.3335 12.2802 21.3335 9.3335C21.3335 6.38683 18.9468 4.00016 16.0002 4.00016Z"
        fill="#00C896"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(UserWithHeartIcon),
};

export default Asset;
