import { useTranslation } from 'react-i18next';

import { SchoolGrade } from 'generated/graphql';

const GRADE_TRANSLATION_HOLDER_KEY = 'account.item.grade';

interface GradeTranslationsHookReturnType {
  translateGrade(grade: string): string;
}

export const useGradeTranslations = (): GradeTranslationsHookReturnType => {
  const { t } = useTranslation();

  const translations: Record<string, string> = {
    [SchoolGrade.None]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.NONE`),
    [SchoolGrade.PreKindergarten]: '',
    [SchoolGrade.Kindergarten]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.00`),
    [SchoolGrade.First]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.01`),
    [SchoolGrade.Second]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.02`),
    [SchoolGrade.Third]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.03`),
    [SchoolGrade.Fourth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.04`),
    [SchoolGrade.Fifth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.05`),
    [SchoolGrade.Sixth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.06`),
    [SchoolGrade.Seventh]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.07`),
    [SchoolGrade.Eighth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.08`),
    [SchoolGrade.Ninth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.09`),
    [SchoolGrade.Tenth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.10`),
    [SchoolGrade.Eleventh]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.11`),
    [SchoolGrade.Twelfth]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.12`),
    [SchoolGrade.HigherEducation]: t(`${GRADE_TRANSLATION_HOLDER_KEY}.13`),
    [SchoolGrade.Adult]: '',
  };

  function translateGrade(grade: string) {
    return translations[grade] ?? '';
  }

  return { translateGrade };
};
