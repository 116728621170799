import React, { PropsWithChildren } from 'react';

import { Box, Helmet } from 'designSystem/ions';

const Wrapper = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Box
      is="div"
      className="App flex flex-col h-screen min-w-320px bg-gray-50 overflow-x-hidden sm:overflow-x-auto"
    >
      {children}
    </Box>
  );
};

const Header = ({
  children,
  title,
  keepShadow = false,
  className = '',
}: PropsWithChildren<{
  title: string;
  keepShadow?: boolean;
  className?: string;
}>): JSX.Element => {
  const colorStyle = 'sm:bg-white';
  const headerStyle = `
  flex flex-initial
  w-full
  z-20
  ${keepShadow ? 'shadow-sm' : 'shadow-none sm:shadow-sm'}
  ${colorStyle}
  ${className}`;

  return (
    <Box is="header" className={headerStyle}>
      <Box is="h1" className="sr-only">
        {title}
      </Box>
      <Helmet title={title} />
      <Box is="div" className="flex relative h-full w-full">
        {children}
      </Box>
    </Box>
  );
};

const Body = ({
  children,
  className = '',
  hasTopSpacing = true,
}: PropsWithChildren<{
  className?: string;
  // TODO hasTopSpacing은 CDS 적용시 제거되어야 합니다
  hasTopSpacing?: boolean;
}>): JSX.Element => {
  const topSpacing = hasTopSpacing ? 'mt-0 sm:mt-8 md:mt-12' : '';
  const bodyStyle = `flex flex-1-0 z-10 ${topSpacing} ${className}`;

  return (
    <Box is="div" className={bodyStyle}>
      {children}
    </Box>
  );
};

const Footer = ({
  children,
  className = '',
}: PropsWithChildren<{ className?: string }>): JSX.Element => {
  return (
    <Box is="footer" className={className} role="contentinfo">
      {children}
    </Box>
  );
};

Wrapper.displayName = 'GlobalSection.Wrapper';
Header.displayName = 'GlobalSection.Header';
Body.displayName = 'GlobalSection.Body';
Footer.displayName = 'GlobalSection.Footer';

export default {
  Wrapper: Wrapper,
  Body: Body,
  Header: Header,
  Footer: Footer,
};
