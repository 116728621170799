/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const title = t('account.change_mobile.title');
  const description = t('account.change_mobile.description');

  const label = {
    newMobile: t('account.label.new_mobile'),
    mobileCode: t('account.label.mobile_code'),
  };

  const placeholder = {
    mobile: t('account.placeholder.mobile'),
    code: t('account.placeholder.mobile_code'),
  };

  const message = {
    sendMobileCode: t('account.message.send_mobile_code'),
    invalidMobileNumber: t('account.error.message.invalid_mobile'),
    alreadyOccupiedMobile: t('account.error.message.already_occupied_mobile'),
    alreadyUsingMobile: t('account.error.message.already_using_mobile'),
    notMatchedMobileCode: t('account.error.message.not_matched_mobile_code'),
    expiredMobileCode: t('account.error.message.expired_mobile_code'),
    resendMobileCode: t('account.message.resend_mobile_code'),
    successChange: t('account.message.success_change'),
  };

  const button = {
    verifyMobile: t('account.button.verify_mobile'),
    changeMobile: t('account.change_mobile.button.change'),
    resendMobileCode: t('account.button.resend_mobile_code'),
    confirm: t('account.button.config'),
  };

  return {
    title,
    description,
    placeholder,
    label,
    message,
    button,
  };
};

export default useTranslatedTexts;
