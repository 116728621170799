/* istanbul ignore file */
import countries from 'i18n-iso-countries';
import ko from 'i18n-iso-countries/langs/ko.json';
import en from 'i18n-iso-countries/langs/en.json';
import ja from 'i18n-iso-countries/langs/ja.json';
import es from 'i18n-iso-countries/langs/es.json';
import id from 'i18n-iso-countries/langs/id.json';
import ru from 'i18n-iso-countries/langs/ru.json';
import th from 'i18n-iso-countries/langs/th.json';
import vi from 'i18n-iso-countries/langs/vi.json';
import zh from 'i18n-iso-countries/langs/zh.json';
import km from 'i18n-iso-countries/langs/km.json';
import ar from 'i18n-iso-countries/langs/ar.json';
import mn from 'i18n-iso-countries/langs/mn.json';

countries.registerLocale(ko);
countries.registerLocale(en);
countries.registerLocale(ja);
countries.registerLocale(es);
countries.registerLocale(id);
countries.registerLocale(ru);
countries.registerLocale(th);
countries.registerLocale(vi);
countries.registerLocale(zh);
countries.registerLocale(km);
countries.registerLocale(ar);
countries.registerLocale(mn);
