import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  InsightsJson: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  OrganizationJson: any;
  Void: any;
};

export type Account = {
  __typename?: 'Account';
  countryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type AccountDto = {
  __typename?: 'AccountDto';
  /** 아바타 프로필 이미지 URL */
  avatarImage?: Maybe<Scalars['String']>;
  /** 생일 */
  birthDate?: Maybe<Scalars['String']>;
  /** 국가 코드 */
  countryCode?: Maybe<Scalars['String']>;
  /** 생성일 */
  createdAt?: Maybe<Scalars['String']>;
  /** 소개글 */
  description?: Maybe<Scalars['String']>;
  /** 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 이메일 인증 여부 */
  emailVerified: Scalars['Boolean'];
  /** 소속 엔터프라이즈 정보 */
  enterprise?: Maybe<Enterprise>;
  /** 성별 */
  gender?: Maybe<Gender>;
  grade: SchoolGrade;
  /** 식별자 */
  id: Scalars['String'];
  /** 소셜 로그인 정보 */
  identities: Array<Maybe<SocialIdentity>>;
  /** 광고 제거 상품 구독 여부 */
  isAdFreeSubscribed: Scalars['Boolean'];
  /** 언어 */
  language?: Maybe<Scalars['String']>;
  /** 마케팅 수신 정보 채널별 동의 여부 */
  marketingAgreement?: Maybe<MarketingAgreement>;
  /** 모바일 정보 */
  mobile?: Maybe<Mobile>;
  /** 이름 */
  name?: Maybe<Scalars['String']>;
  personalCertificate?: Maybe<PersonalCertificate>;
  /** 개인정보 이용약관 동의일 */
  privacyTermsAgreedAt?: Maybe<Scalars['String']>;
  /** 프로필 이미지 URL */
  profileImage?: Maybe<Scalars['String']>;
  role: AccountRole;
  schoolInfo?: Maybe<SchoolInfo>;
  /** 소속 학교 정보 */
  schoolInfoDto?: Maybe<SchoolInfoDto>;
  /** 자녀 학교 정보 리스트 */
  schoolInfoDtoForChildren: Array<Maybe<SchoolInfoDto2>>;
  schoolInfoForChildren: Array<Maybe<SchoolInfo2>>;
  /** 회원가입일 */
  signedUpAt?: Maybe<Scalars['String']>;
  /** 학생코드 */
  studentRegistrationCode?: Maybe<Scalars['String']>;
  /** 이용약관 동의일 */
  termsOfUseAgreedAt?: Maybe<Scalars['String']>;
  /** 수정일 */
  updatedAt?: Maybe<Scalars['String']>;
  /** 아이디 */
  username?: Maybe<Scalars['String']>;
};

export enum AccountRole {
  Parent = 'PARENT',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type AccountSchool = {
  __typename?: 'AccountSchool';
  admissionAge: Scalars['Float'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  regularCourseYear: Scalars['Float'];
  zipcode: Scalars['String'];
};

export type AccountServiceAccountCertificate = {
  __typename?: 'AccountServiceAccountCertificate';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ActivityStreamDto = {
  __typename?: 'ActivityStreamDto';
  actor: Scalars['Json'];
  content?: Maybe<Scalars['String']>;
  generator: Scalars['Json'];
  id: Scalars['String'];
  object: Scalars['Json'];
  publishedAt: Scalars['String'];
  recipientId: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  target: Scalars['Json'];
  type: Scalars['String'];
};

export type ActivityStreamsCollectionDto = {
  __typename?: 'ActivityStreamsCollectionDto';
  activityStreams: Array<Maybe<ActivityStreamDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type AddAttendeeToMeetingDtoInput = {
  classroomId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AddDeviceRequestDtoInput = {
  deviceModel: Scalars['String'];
  deviceOs: Scalars['String'];
  deviceType: Scalars['Float'];
  gameVersion: Scalars['String'];
  isRelease?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  sdk: Scalars['String'];
  timezone: Scalars['Float'];
  voipDeviceToken: Scalars['String'];
};

export type AddDeviceResponseDto = {
  __typename?: 'AddDeviceResponseDto';
  playerId: Scalars['String'];
};

export type AddReactionToCommentDtoInput = {
  name: Scalars['String'];
};

export type AddReactionToPostDtoInput = {
  name: Scalars['String'];
};

export type AddReactionToReplyDtoInput = {
  name: Scalars['String'];
};

export type AiTestCollection = {
  __typename?: 'AiTestCollection';
  aiTests?: Maybe<Array<Maybe<AiTestItem>>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type AiTestCreated = {
  __typename?: 'AiTestCreated';
  id?: Maybe<Scalars['Int']>;
};

export type AiTestItem = {
  __typename?: 'AiTestItem';
  assigneesCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  questionsCount?: Maybe<Scalars['Int']>;
  subject?: Maybe<AiTestItemSubject>;
  submissionCount?: Maybe<Scalars['Int']>;
  units?: Maybe<Array<Maybe<AiTestItemSubject>>>;
};

export type AiTestItemSubject = {
  __typename?: 'AiTestItemSubject';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type AiTestPolicy = {
  __typename?: 'AiTestPolicy';
  allowedSubjectIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  availableCount?: Maybe<Scalars['Int']>;
  isSubjectRestricted: Scalars['Boolean'];
  remainingRenewTime: Scalars['Int'];
  renewAt?: Maybe<Scalars['DateTime']>;
};

export type AiTestSubject = {
  __typename?: 'AiTestSubject';
  createdAt?: Maybe<Scalars['DateTime']>;
  hasUnit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AiTestUnit = {
  __typename?: 'AiTestUnit';
  coreConcepts?: Maybe<Array<Maybe<AiTestUnitCoreConceptsItems>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AiTestUnitCoreConceptsItems = {
  __typename?: 'AiTestUnitCoreConceptsItems';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Array<Maybe<AiTestUnitCoreConceptsItemsKeywordsItems>>>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  quizzesCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AiTestUnitCoreConceptsItemsKeywordsItems = {
  __typename?: 'AiTestUnitCoreConceptsItemsKeywordsItems';
  coreConceptId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pagesCount?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AppearanceInfo = {
  __typename?: 'AppearanceInfo';
  appIconUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  signInLogoUrl?: Maybe<Scalars['String']>;
};

export type ArticleDto = {
  __typename?: 'ArticleDto';
  blocks: Scalars['Json'];
  files: Array<FileMetaDto>;
  id: Scalars['String'];
  locale: Scalars['String'];
  publishedAt: Scalars['Date'];
  publisher: PublisherDto;
  revision: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ArticlesCollectionDto = {
  __typename?: 'ArticlesCollectionDto';
  articles: Array<ArticleDto>;
  cursor?: Maybe<Scalars['String']>;
};

export type AssignAssignmentDtoInput = {
  assigneeIds: Array<InputMaybe<Scalars['String']>>;
  channelId: Scalars['String'];
};

export type AssignMemberRoleDtoInput = {
  role: Role;
};

export type AssignmentDto = {
  __typename?: 'AssignmentDto';
  /** 과제를 할당받은 학생 구성원 전체 식별자 목록 */
  assigneeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 과제를 할당받은 전체 학생 수 */
  assigneesCount: Scalars['Float'];
  /** 각 학생이 제출한 과제 제출물 목록 */
  assignmentSubmissions: Array<Maybe<AssignmentSubmissionDto>>;
  /** 과제를 작성한 선생님의 식별자 */
  authorId: Scalars['String'];
  /** 과제가 작성된 공간의 식별자 */
  channelId?: Maybe<Scalars['String']>;
  /** 과제가 작성된 클래스룸의 식별자 */
  classroomId: Scalars['String'];
  /** 과제가 작성된 시각 */
  createdAt: Scalars['String'];
  /** 제출물 제출 마감 시각 */
  dueDateTime?: Maybe<Scalars['String']>;
  /** 최대 점수가 있는지 표시 */
  hasMaxPoints: Scalars['Boolean'];
  /** 과제 식별자 */
  id: Scalars['String'];
  /** 한 명 이상의 학생에게 할당되었는지 표시 */
  isAssigned: Scalars['Boolean'];
  /** 과제에 설정된 최대 점수 */
  maxPoints: Scalars['Float'];
  /** 제출물을 제출한 학생 수 */
  membersSubmittedCount: Scalars['Float'];
  /** 과제 메타 정보 */
  meta?: Maybe<Scalars['Json']>;
  /** 과제의 내용이 작성된 게시글의 식별자 */
  postId: Scalars['String'];
  /** 과제의 제목 */
  title?: Maybe<Scalars['String']>;
  type: AssignmentType;
  /** 과제가 마지막으로 수정된 시각 */
  updatedAt: Scalars['String'];
};

export type AssignmentPermissionDto = {
  __typename?: 'AssignmentPermissionDto';
  canGrade: Scalars['Boolean'];
  canSubmit: Scalars['Boolean'];
};

export type AssignmentSubmissionCommentDto = {
  __typename?: 'AssignmentSubmissionCommentDto';
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  author: MemberDto;
  authorId: Scalars['String'];
  classroomId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  repliesCount: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type AssignmentSubmissionCommentPermissionDto = {
  __typename?: 'AssignmentSubmissionCommentPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
};

export type AssignmentSubmissionCommentsCollectionDto = {
  __typename?: 'AssignmentSubmissionCommentsCollectionDto';
  assignmentSubmissionComments: Array<Maybe<AssignmentSubmissionCommentDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type AssignmentSubmissionDto = {
  __typename?: 'AssignmentSubmissionDto';
  /** 과제를 할당받은 학생 구성원 식별자 */
  assigneeId: Scalars['String'];
  /** 출제된 과제의 식별자 */
  assignmentId: Scalars['String'];
  /** 과제 제출물이 제출된 클래스의 식별자 */
  classroomId: Scalars['String'];
  /** 과제 제출물에 달린 댓글 수 */
  commentsCount?: Maybe<Scalars['Float']>;
  /** 과제 제출물이 생성된 시각 */
  createdAt: Scalars['String'];
  gradingStatus: GradingStatus;
  /** 과제 제출물 식별자 */
  id: Scalars['String'];
  /** 해당 학생이 제출물을 제출했는지 표시 */
  isSubmitted: Scalars['Boolean'];
  lastSubmission?: Maybe<SubmissionDto>;
  learningSubmission?: Maybe<LearningSubmissionDto>;
  /** 과제 제출물 채점 점수 */
  points?: Maybe<Scalars['Float']>;
  /** 과제 제출물에 달린 답글 수 */
  repliesCount?: Maybe<Scalars['Float']>;
  status: AssignmentSubmissionStatus;
  /** 과제에 제출된 전체 제출물 목록 */
  submissions: Array<Maybe<SubmissionDto>>;
  /** 과제 제출물이 마지막으로 수정된 시각 */
  updatedAt: Scalars['String'];
};

export enum AssignmentSubmissionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Prepared = 'PREPARED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export enum AssignmentType {
  Assignment = 'ASSIGNMENT',
  Learning = 'LEARNING'
}

export type AssignmentsSearchCollectionDto = {
  __typename?: 'AssignmentsSearchCollectionDto';
  assignments: Array<Maybe<AssignmentDto>>;
  /** 현재 페이지 */
  page?: Maybe<Scalars['Float']>;
  /** 페이지당 과제의 최대 개수 */
  perPage?: Maybe<Scalars['Float']>;
  /** 검색된 과제의 총 페이지 수 */
  totalPages?: Maybe<Scalars['Float']>;
  /** 검색된 과제의 총 개수 */
  totalResults?: Maybe<Scalars['Float']>;
};

export type AttendanceDto = {
  __typename?: 'AttendanceDto';
  attendedAt?: Maybe<Scalars['String']>;
  attendee?: Maybe<MemberDto>;
  attendeeId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  status: Status;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AttendanceSummaryDto = {
  __typename?: 'AttendanceSummaryDto';
  attendanceRatio: Scalars['Float'];
  date: Scalars['String'];
  numberOfAttendedStudents: Scalars['Float'];
  numberOfStudents: Scalars['Float'];
};

export type ChangeEmailDtoInput = {
  code: Scalars['String'];
};

export type ChangeMobileDtoInput = {
  code: Scalars['String'];
  requestId: Scalars['String'];
};

export type ChangePasswordDtoInput = {
  newPasswordConfirm: Scalars['String'];
  password: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId?: Maybe<Scalars['String']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  editorMode?: Maybe<Scalars['Json']>;
  general: Scalars['Boolean'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inactiveMembersCount: Scalars['Float'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Float']>;
  postPins: Array<Maybe<Scalars['Json']>>;
  purpose?: Maybe<Scalars['String']>;
  type: Type;
  updatedAt: Scalars['String'];
};

export type ChannelDto = {
  __typename?: 'ChannelDto';
  /** 비활성 구성원 수를 제외한 활성 구성원 수 */
  activeMembersCount: Scalars['Float'];
  archived: Scalars['Boolean'];
  autoJoin: Scalars['Boolean'];
  canMemberReactToPost: Scalars['Boolean'];
  canMemberWriteComment: Scalars['Boolean'];
  canMemberWritePost: Scalars['Boolean'];
  channelGroupId?: Maybe<Scalars['String']>;
  channelType: ChannelType;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  /** 게시글 작성 시 사용될 에디터 모드를 결정하는 값. "default" | "presentation"  */
  editorMode: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCommon: Scalars['Boolean'];
  isDeletable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  meeting?: Maybe<Meeting2>;
  meetingId?: Maybe<Scalars['String']>;
  memberIds: Array<Maybe<Scalars['String']>>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  purpose?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type ChannelGroupDto = {
  __typename?: 'ChannelGroupDto';
  channelIds: Array<Maybe<Scalars['String']>>;
  channels: Array<Maybe<ChannelDto>>;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
  updatedAt: Scalars['String'];
};

export type ChannelPostPinDto = {
  __typename?: 'ChannelPostPinDto';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  post?: Maybe<PostDto>;
  postId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum ChannelType {
  Announcement = 'ANNOUNCEMENT',
  Legacy = 'LEGACY',
  Post = 'POST'
}

export type ChatUser = {
  __typename?: 'ChatUser';
  createdAt?: Maybe<Scalars['DateTime']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type ChildAccountDto = {
  __typename?: 'ChildAccountDto';
  avatarImage?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mobile?: Maybe<Mobile2>;
  name: Scalars['String'];
  schoolInfo?: Maybe<SchoolInfo4>;
  signInMethods: Array<Maybe<SignInMethod>>;
  signedUpAt?: Maybe<Scalars['String']>;
};

export type ChildCodeResponseDto = {
  __typename?: 'ChildCodeResponseDto';
  childId: Scalars['String'];
  childName: Scalars['String'];
  code: Scalars['String'];
  createdAt: Scalars['String'];
  expireAt: Scalars['String'];
  id: Scalars['String'];
  verifiedGuardianId: Scalars['String'];
};

export type Class = {
  __typename?: 'Class';
  class?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
};

export type ClassCodeDto = {
  __typename?: 'ClassCodeDto';
  /** 해당 코드의 클래스룸 식별자 */
  classroomId: Scalars['String'];
  /** 클래스코드 */
  code: Scalars['String'];
  enterprise?: Maybe<ClassroomEnterpriseDto>;
  /** 클래스코드 만료일 */
  expiresAt?: Maybe<Scalars['String']>;
};

export type ClassCollection = {
  __typename?: 'ClassCollection';
  classes?: Maybe<Array<Maybe<Class>>>;
};

export type ClassroomBookmarkStatusDto = {
  __typename?: 'ClassroomBookmarkStatusDto';
  /** 북마크 여부 */
  bookmarkStatus: Scalars['Boolean'];
  /** 북마크된 클래스의 식별자 */
  classroomId: Scalars['String'];
  /** 북마크한 구성원의 식별자 */
  userId: Scalars['String'];
};

export type ClassroomDto = {
  __typename?: 'ClassroomDto';
  /** 클래스룸 소개글 */
  about?: Maybe<Scalars['String']>;
  /** 클래스룸 관리자 수 */
  adminsCount: Scalars['Float'];
  /** 클래스룸 보관 여부 */
  archived: Scalars['Boolean'];
  /** 클래스룸 국가 코드 */
  countryCode?: Maybe<Scalars['String']>;
  /** 클래스룸 생성일자 */
  createdAt: Scalars['String'];
  /** 클래스룸 학년 */
  grade: Grade2;
  /** 클래스룸 식별자 */
  id: Scalars['String'];
  /** 구 클래스 여부 */
  isLegacy?: Maybe<Scalars['Boolean']>;
  legacyClassRelation?: Maybe<LegacyClassRelation>;
  /**
   * 클래스룸의 구성원 목록
   *
   * * 유효한 API: 클래스룸 개설 API
   *
   * * 그 외의 API: 항상 빈 배열
   */
  members: Array<Maybe<Member>>;
  /** 클래스룸 전체 멤버 수. *관리자 포함* */
  membersCount: Scalars['Float'];
  /** 클래스룸 이름 */
  name: Scalars['String'];
  /** 클래스룸이 소속된 기관의 식별자 */
  orgId?: Maybe<Scalars['String']>;
  /** 클래스룸 고정된 태그 수 */
  pinnedTagsCount: Scalars['Float'];
  /** 미디어 서비스 내의 클래스룸 프로필 이미지 식별자 */
  profileImageId?: Maybe<Scalars['String']>;
  /** 클래스룸 프로필 이미지 URL */
  profileImageUrl: Scalars['String'];
  /** 클래스룸 생성 관련 작업들의 진행 상태 */
  provisioningStatus: Scalars['String'];
  /** 클래스룸이 소속된 기관의 식별자. *orgId로 네이밍 변경 예정* */
  schoolId?: Maybe<Scalars['String']>;
  /** 클래스룸이 소속된 기관의 이름 */
  schoolName?: Maybe<Scalars['String']>;
  /** 클래스룸 교육년도 */
  schoolYear?: Maybe<Scalars['Int']>;
  /** 클래스룸 학생 수 */
  studentsCount: Scalars['Float'];
  /** 클래스룸 태그 수 */
  tagsCount: Scalars['Float'];
  /** 클래스룸 최근 수정일자 */
  updatedAt: Scalars['String'];
  /**
   * 클래스룸 기능 버전
   *
   * v2: 공통 공간(알림장 공간 및 공지 공간) 기능 제공
   */
  version: Scalars['Float'];
};

export type ClassroomEnterpriseDto = {
  __typename?: 'ClassroomEnterpriseDto';
  /** 사람이 읽을 수 있는 엔터프라이즈 식별자 */
  slug: Scalars['String'];
};

export type ClassroomFavoriteDto = {
  __typename?: 'ClassroomFavoriteDto';
  classroom: ClassroomDto;
  /** 즐겨찾기된 클래스룸의 식별자 */
  classroomId: Scalars['String'];
  /** 클래스룸을 즐겨찾기한 시각 */
  createdAt: Scalars['String'];
  /** 클래스룸 즐겨찾기 식별자 */
  id: Scalars['String'];
  /** 클래스룸을 즐겨찾기한 구성원의 식별자 */
  userId: Scalars['String'];
};

export type ClassroomFavoritesCollectionDto = {
  __typename?: 'ClassroomFavoritesCollectionDto';
  classroomFavorites: Array<Maybe<ClassroomFavoriteDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type ClassroomsCollectionDto = {
  __typename?: 'ClassroomsCollectionDto';
  classrooms: Array<Maybe<ClassroomDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type ClassroomsSearchCollectionDto = {
  __typename?: 'ClassroomsSearchCollectionDto';
  classrooms: Array<Maybe<ClassroomDto>>;
  page?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
  totalPages?: Maybe<Scalars['Float']>;
  totalResults?: Maybe<Scalars['Float']>;
};

export type ClasstalkMeetingDto = {
  __typename?: 'ClasstalkMeetingDto';
  classroomId?: Maybe<Scalars['String']>;
  classroomName?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  endedAt?: Maybe<Scalars['String']>;
  externalServiceId: Scalars['String'];
  id: Scalars['String'];
  isCaller: Scalars['Boolean'];
  isMissed: Scalars['Boolean'];
  opponentId?: Maybe<Scalars['String']>;
  opponentName?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ClasstalkMeetingsCollectionDto = {
  __typename?: 'ClasstalkMeetingsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  meetings: Array<Maybe<ClasstalkMeetingDto>>;
};

export type ClasstalkUserDto = {
  __typename?: 'ClasstalkUserDto';
  callable: Scalars['Boolean'];
  callableCurrently: Scalars['Boolean'];
  countryCode: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  /** 클래스톡 유저의 활성 상태. 클래스팅에서 탈퇴 등의 경우 해당 값이 false로 설정됨 */
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  setting: ClasstalkUserSettingDto;
  updatedAt: Scalars['String'];
  workdays: Array<Maybe<WorkdayDto>>;
};

export type ClasstalkUserSettingDto = {
  __typename?: 'ClasstalkUserSettingDto';
  autoRecordingTermsAgreedAt?: Maybe<Scalars['String']>;
  isAutoRecordingActive: Scalars['Boolean'];
};

export type CommentDto = {
  __typename?: 'CommentDto';
  author: MemberDto;
  authorId: Scalars['String'];
  classroomId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  postId: Scalars['String'];
  reactions: Array<Maybe<CommentReactionDto>>;
  reactionsCount: Scalars['Json'];
  repliesCount: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type CommentPermissionDto = {
  __typename?: 'CommentPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canReact: Scalars['Boolean'];
  canWriteReply: Scalars['Boolean'];
};

export type CommentReactionDto = {
  __typename?: 'CommentReactionDto';
  createdAt: Scalars['String'];
  member?: Maybe<MemberDto>;
  memberId: Scalars['String'];
  name: Scalars['String'];
};

export type CommentReactionsCollectionDto = {
  __typename?: 'CommentReactionsCollectionDto';
  reactions: Array<Maybe<CommentReactionDto>>;
};

export type CommentsCollectionDto = {
  __typename?: 'CommentsCollectionDto';
  comments: Array<Maybe<CommentDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type CompleteAssignmentSubmissionDtoInput = {
  assigneeId: Scalars['String'];
};

export type CompleteOnboardingChecklistActionDtoInput = {
  /**
   *
   *     완료할 체크리스트의 액션,
   *     허용되는 값들:
   *       | 'distribute-ai-test'
   *       | 'visit-product-tour-page'
   *
   */
  action: Scalars['String'];
};

export type CreateAiTestDtoInput = {
  classroomId: Scalars['String'];
  studentIds: Array<InputMaybe<Scalars['String']>>;
  subjectId: Scalars['Int'];
  testConfiguration?: InputMaybe<Array<InputMaybe<CreateAiTestDtoTestConfigurationItemsInput>>>;
};

export type CreateAiTestDtoTestConfigurationItemsInput = {
  coreConceptId?: InputMaybe<Scalars['Int']>;
  questionCount?: InputMaybe<Scalars['Int']>;
};

export type CreateAssignmentDtoInput = {
  postId: Scalars['String'];
  type?: InputMaybe<AssignmentType>;
};

export type CreateAssignmentSubmissionCommentDtoInput = {
  content: Scalars['String'];
};

export type CreateAttendanceDtoInput = {
  timezone?: InputMaybe<Scalars['String']>;
};

export type CreateChannelDtoInput = {
  autoJoin?: InputMaybe<Scalars['Boolean']>;
  canMemberReactToPost?: InputMaybe<Scalars['Boolean']>;
  canMemberWriteComment?: InputMaybe<Scalars['Boolean']>;
  canMemberWritePost?: InputMaybe<Scalars['Boolean']>;
  channelGroupId: Scalars['String'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  purpose?: InputMaybe<Scalars['String']>;
};

export type CreateChannelGroupDtoInput = {
  name: Scalars['String'];
};

export type CreateClassroomDtoInput = {
  about?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade>;
  name: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
  schoolYear?: InputMaybe<Scalars['Int']>;
};

export type CreateCommentDtoInput = {
  content: Scalars['String'];
};

export type CreateFileDtoInput = {
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType?: InputMaybe<Scalars['String']>;
  originalFileName: Scalars['String'];
  totalSize: Scalars['Float'];
};

export type CreateImageDtoInput = {
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  originalFileName: Scalars['String'];
  totalSize: Scalars['Float'];
};

export type CreateMeetingDtoInput = {
  classroomId?: InputMaybe<Scalars['String']>;
};

export type CreatePostDtoInput = {
  isAssignment: Scalars['Boolean'];
};

export type CreateReplyDtoInput = {
  content: Scalars['String'];
};

export type CreateTagDtoInput = {
  name: Scalars['String'];
};

export type CreateVideoDtoInput = {
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  originalFileName: Scalars['String'];
  resourceKey?: InputMaybe<Scalars['String']>;
  totalSize: Scalars['Float'];
};

export type DistributeAiTestDtoInput = {
  classroomId: Scalars['String'];
  resourceScope?: InputMaybe<DistributeAiTestDtoResourceScope>;
  studentIds: Array<InputMaybe<Scalars['String']>>;
  testConfiguration?: InputMaybe<Array<InputMaybe<DistributeAiTestDtoTestConfigurationItemsInput>>>;
};

export enum DistributeAiTestDtoResourceScope {
  CoreConcept = 'CoreConcept',
  Keyword = 'Keyword'
}

export type DistributeAiTestDtoTestConfigurationItemsInput = {
  questionCount?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['Int']>;
};

/** 소속 엔터프라이즈 정보 */
export type Enterprise = {
  __typename?: 'Enterprise';
  createdAt: Scalars['String'];
  homeUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isWhiteLabeled: Scalars['Boolean'];
  logo: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type EnterpriseBranding = {
  __typename?: 'EnterpriseBranding';
  /** 심볼 형태의 엔터프라이즈 로고 이미지 URL */
  appIconUrl?: Maybe<Scalars['String']>;
  /** 심볼과 워드마크가 조합된 형태의 엔터프라이즈 로고 이미지 URL */
  logoUrl?: Maybe<Scalars['String']>;
  /** 엔터프라이즈의 브랜드 이름 */
  name?: Maybe<Scalars['String']>;
  /** 엔터프라이즈 브랜드 대표 색상의 HEX 코드 */
  primaryColor?: Maybe<Scalars['String']>;
  /** 전용 로그인 페이지에 사용되는 엔터프라이즈 로그인 로고 이미지 URL */
  signInLogoUrl?: Maybe<Scalars['String']>;
};

export type EnterpriseContractDto = {
  __typename?: 'EnterpriseContractDto';
  /** 계약 종료 일시 */
  endsAt: Scalars['String'];
  /** 엔터프라이즈 계약 식별자 */
  id: Scalars['String'];
  /** 계약 시작 일시 */
  startsAt: Scalars['String'];
  type: EnterpriseContractType;
};

export enum EnterpriseContractType {
  AiLearning = 'AI_LEARNING',
  Bass = 'BASS',
  ClassroomPostTranslation = 'CLASSROOM_POST_TRANSLATION',
  Mou = 'MOU'
}

export type EnterpriseDto = {
  __typename?: 'EnterpriseDto';
  appearanceInfo: AppearanceInfo;
  branding: EnterpriseBranding;
  contracts: Array<Maybe<EnterpriseContractDto>>;
  /** 엔터프라이즈 생성 일시 */
  createdAt: Scalars['String'];
  /** 엔터프라이즈의 기본 홈 URL */
  homeUrl: Scalars['String'];
  /** 엔터프라이즈 식별자 */
  id: Scalars['String'];
  /** 화이트라벨링 적용 대상 엔터프라이즈 여부 */
  isWhiteLabeled: Scalars['Boolean'];
  /** 엔터프라이즈의 로고 URL */
  logoUrl?: Maybe<Scalars['String']>;
  /** 엔터프라이즈 이름 */
  name: Scalars['String'];
  /** 사람이 읽고 이해하기 쉬운 형태의 엔터프라이즈 식별자 */
  slug: Scalars['String'];
  /** 엔터프라이즈 최근 수정 일시 */
  updatedAt: Scalars['String'];
};

export type EnterpriseMemberDto = {
  __typename?: 'EnterpriseMemberDto';
  /** 구성원 생성 일시 */
  createdAt: Scalars['String'];
  /** 구성원이 소속된 엔터프라이즈 식별자 */
  enterpriseId: Scalars['String'];
  /** 엔터프라이즈 구성원 식별자 */
  id: Scalars['String'];
  role: EnterpriseMemberRole;
  /** 구성원 최근 수정 일시 */
  updatedAt: Scalars['String'];
};

export enum EnterpriseMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type EnterprisePermissionDto = {
  __typename?: 'EnterprisePermissionDto';
  /** 현재 사용자가 최소 한 개의 엔터프라이즈 소속 기관에서 관리 권한을 가졌는지의 여부 */
  canAdministerOrganization: Scalars['Boolean'];
};

export type ExcelExportJobStateDto = {
  __typename?: 'ExcelExportJobStateDto';
  createdAt: Scalars['String'];
  /** 내려받기 작업 상태가 제거될 시각 */
  deleteAt?: Maybe<Scalars['String']>;
  /** 생성된 파일을 다운받을 경로. 내려받기 상태가 완료 시 반환됨 */
  downloadUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** 내려받기 진행 상태를 나타내는 상태값. "processing" | "success" | "failure" */
  jobStatus: Scalars['String'];
  numOfCompletedTasks: Scalars['Float'];
  numOfTotalTasks: Scalars['Float'];
  /** 내려받기 진행도를 나타내는 값. 백분율로 표시 */
  progress: Scalars['Float'];
};

export type ExcuseAttendanceDtoInput = {
  attendedAt: Scalars['String'];
  attendeeId: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['String'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  presignedPost?: Maybe<Scalars['Json']>;
  totalSize: Scalars['Float'];
  updatedAt: Scalars['String'];
  urls: Scalars['Json'];
  userId: Scalars['String'];
};

export type FileMetaDto = {
  __typename?: 'FileMetaDto';
  name: Scalars['String'];
  size: Scalars['Float'];
  src: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export enum Filter {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum Gender2 {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GenerateRecordingDownloadUrlDtoInput = {
  externalServiceId: Scalars['String'];
  meetingId: Scalars['String'];
};

export type GeneratorDto = {
  __typename?: 'GeneratorDto';
  hasUnreadNews: Scalars['Boolean'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export enum Grade {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export enum Grade2 {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export enum Grade3 {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export enum GradingStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export enum Group {
  Guardian = 'GUARDIAN',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export enum Group2 {
  Guardian = 'GUARDIAN',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type GuardianAccountDto = {
  __typename?: 'GuardianAccountDto';
  avatarImage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GuardianRelationshipDto = {
  __typename?: 'GuardianRelationshipDto';
  childAccount?: Maybe<ChildAccountDto>;
  createdAt: Scalars['String'];
  guardianAccount?: Maybe<GuardianAccountDto>;
  id: Scalars['String'];
  relationshipType: RelationshipType;
};

export type Image = {
  __typename?: 'Image';
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['String'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  presignedPost?: Maybe<Scalars['Json']>;
  totalSize: Scalars['Float'];
  updatedAt: Scalars['String'];
  urls: Scalars['Json'];
  userId: Scalars['String'];
};

export type ImageDto = {
  __typename?: 'ImageDto';
  /** 이미지가 첨부된 상위 리소스 식별자 */
  categoryId: Scalars['String'];
  /** 이미지가 첨부된 상위 리소스 */
  categoryType: Scalars['String'];
  /** 이미지의 MIME 타입 */
  contentType: Scalars['String'];
  /** 이미지가 생성된 시각 */
  createdAt: Scalars['String'];
  /** 이미지가 저장된 경로 */
  filePath: Scalars['String'];
  /** 이미지 식별자 */
  id: Scalars['String'];
  /** 이미지의 원본 파일명 */
  originalFileName: Scalars['String'];
  /** 이미지의 크기 */
  totalSize: Scalars['Float'];
  /** 이미지가 최근 수정된 시각 */
  updatedAt: Scalars['String'];
  /** 다양한 크기의 이미지 URL 목록 */
  urls: Scalars['Json'];
  /** 이미지를 업로드한 사용자의 식별자 */
  userId: Scalars['String'];
};

export type InitiateExportAttendancesDtoInput = {
  fileName: Scalars['String'];
  /** comma separated string으로 월 지정. 예시) "1,2" */
  months: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  year: Scalars['Float'];
};

export type InsightsAccountDto = {
  __typename?: 'InsightsAccountDto';
  guardianIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  role: InsightsAccountRole;
};

export enum InsightsAccountRole {
  Child = 'CHILD',
  Guardian = 'GUARDIAN'
}

export type InsightsActivitiesCollectionDto = {
  __typename?: 'InsightsActivitiesCollectionDto';
  activities: Array<Maybe<InsightsActivityDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type InsightsActivityDto = {
  __typename?: 'InsightsActivityDto';
  actor: Scalars['InsightsJson'];
  childId: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  generator: Scalars['InsightsJson'];
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  object: Scalars['InsightsJson'];
  publishedAt?: Maybe<Scalars['String']>;
  stickers?: Maybe<Array<Maybe<InsightsStickerDto>>>;
  summary?: Maybe<Scalars['String']>;
  target: Scalars['InsightsJson'];
  type: Scalars['String'];
};

export enum InsightsBillingPeriodDuration {
  P1M = 'P1M',
  P1Y = 'P1Y'
}

export type InsightsChildDto = {
  __typename?: 'InsightsChildDto';
  avatarImage: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  schoolInfo?: Maybe<InsightsSchoolInfoDto>;
};

export type InsightsCreateCumulativeRecordDtoInput = {
  contents: Scalars['String'];
  recordedAt: Scalars['String'];
};

export type InsightsCreateFreePlanSubscriptionDtoInput = {
  childIds: Array<InputMaybe<Scalars['String']>>;
  endAt: Scalars['String'];
  ordererId: Scalars['String'];
  packageName: Scalars['String'];
  period?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  startAt: Scalars['String'];
};

export type InsightsCreateGoalDtoInput = {
  reward?: InputMaybe<Scalars['String']>;
  targetStampCount: Scalars['Float'];
};

export type InsightsCumulativeRecordDto = {
  __typename?: 'InsightsCumulativeRecordDto';
  adminId: Scalars['String'];
  classroomId: Scalars['String'];
  contents: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  recordedAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type InsightsCumulativeRecordsCollectionDto = {
  __typename?: 'InsightsCumulativeRecordsCollectionDto';
  cumulativeRecords: Array<Maybe<InsightsCumulativeRecordDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type InsightsEstimatedOrderDto = {
  __typename?: 'InsightsEstimatedOrderDto';
  currencyCode: Scalars['String'];
  price: Scalars['Float'];
};

export type InsightsGoalDto = {
  __typename?: 'InsightsGoalDto';
  childId: Scalars['String'];
  createdAt: Scalars['String'];
  endAt?: Maybe<Scalars['String']>;
  guardianId: Scalars['String'];
  id: Scalars['String'];
  reward?: Maybe<Scalars['String']>;
  stamps: Array<Maybe<InsightsStampDto>>;
  startAt?: Maybe<Scalars['String']>;
  status: InsightsGoalStatus;
  targetStampCount: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export enum InsightsGoalStatus {
  Complete = 'COMPLETE',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Suspended = 'SUSPENDED'
}

export type InsightsOrderDto = {
  __typename?: 'InsightsOrderDto';
  billingPeriodDuration?: Maybe<InsightsBillingPeriodDuration>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  ordererId: Scalars['String'];
  paymentMethod: InsightsPaymentMethod;
  price?: Maybe<Scalars['Float']>;
  status: InsightsOrderStatus;
  subscription?: Maybe<InsightsSubscriptionDto>;
  subscriptionId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum InsightsOrderStatus {
  Complete = 'COMPLETE',
  Created = 'CREATED',
  Draft = 'DRAFT'
}

export enum InsightsPaymentMethod {
  AndroidInApp = 'ANDROID_IN_APP',
  None = 'NONE'
}

export type InsightsPostReadDto = {
  __typename?: 'InsightsPostReadDto';
  postCreatedAt: Scalars['String'];
  postId: Scalars['String'];
  postReadRate: Scalars['Float'];
  postTitle?: Maybe<Scalars['String']>;
  postUrl: Scalars['String'];
};

export type InsightsPostReadsCollectionDto = {
  __typename?: 'InsightsPostReadsCollectionDto';
  classroomId: Scalars['String'];
  endDate: Scalars['String'];
  postReadRates: Array<Maybe<InsightsPostReadDto>>;
  startDate: Scalars['String'];
  totalPostCount: Scalars['Float'];
};

export type InsightsPraiseChildActivityDtoInput = {
  stickerCode: InsightsStickerCode;
};

export type InsightsSchoolDto = {
  __typename?: 'InsightsSchoolDto';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum InsightsSchoolGrade {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export type InsightsSchoolInfoDto = {
  __typename?: 'InsightsSchoolInfoDto';
  grade?: Maybe<InsightsSchoolGrade>;
  school?: Maybe<InsightsSchoolDto>;
};

export type InsightsStampDto = {
  __typename?: 'InsightsStampDto';
  activityId: Scalars['String'];
  type: InsightsStampType;
};

export enum InsightsStampType {
  Admire = 'ADMIRE',
  Encourage = 'ENCOURAGE',
  PraiseHighest = 'PRAISE_HIGHEST',
  PraiseNormal = 'PRAISE_NORMAL',
  Thank = 'THANK'
}

export enum InsightsStickerCode {
  Admire = 'ADMIRE',
  Encourage = 'ENCOURAGE',
  PraiseHighest = 'PRAISE_HIGHEST',
  PraiseNormal = 'PRAISE_NORMAL',
  Thank = 'THANK'
}

export type InsightsStickerDto = {
  __typename?: 'InsightsStickerDto';
  code: InsightsStickerCode;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  senderId: Scalars['String'];
};

export type InsightsSubscriptionDto = {
  __typename?: 'InsightsSubscriptionDto';
  childIds: Array<Maybe<Scalars['String']>>;
  createdAt: Scalars['String'];
  endAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  orderId: Scalars['String'];
  plan?: Maybe<InsightsSubscriptionPlan>;
  platformStatus?: Maybe<InsightsSubscriptionPlatformStatus>;
  startAt?: Maybe<Scalars['String']>;
  status: InsightsSubscriptionStatus;
  subscriberId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum InsightsSubscriptionPlan {
  Free = 'FREE',
  Pro = 'PRO'
}

export enum InsightsSubscriptionPlatformStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  InGracePeriod = 'IN_GRACE_PERIOD',
  OnHold = 'ON_HOLD'
}

export type InsightsSubscriptionProductDto = {
  __typename?: 'InsightsSubscriptionProductDto';
  basePlanId: Scalars['String'];
  billingPeriodDuration: InsightsBillingPeriodDuration;
  currencyCode: Scalars['String'];
  fixedPrice: Scalars['Float'];
  id: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  plan: InsightsSubscriptionPlan;
  sellingPrice: Scalars['Float'];
};

export enum InsightsSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED'
}

export type InsightsTodoDto = {
  __typename?: 'InsightsTodoDto';
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  dueAt: Scalars['String'];
  id: Scalars['String'];
  originalUrl: Scalars['String'];
  title: Scalars['String'];
  type: InsightsTodoType;
  updatedAt: Scalars['String'];
};

export enum InsightsTodoType {
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT'
}

export type InsightsTodosCollectionDto = {
  __typename?: 'InsightsTodosCollectionDto';
  todos: Array<Maybe<InsightsTodoDto>>;
};

export type InsightsVerifyOrderDtoInput = {
  packageName: Scalars['String'];
  productId: Scalars['String'];
  purchaseToken: Scalars['String'];
};

export type IntegrateZoomDtoInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type InviteMembersDtoInput = {
  phoneNumbers: Array<InputMaybe<Scalars['String']>>;
};

export type JoinLegacyClassDtoInput = {
  childName?: InputMaybe<Scalars['String']>;
  classcode: Scalars['String'];
  role: Scalars['String'];
};

export type LearningCard = {
  __typename?: 'LearningCard';
  audioCount?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  chapterId?: Maybe<Scalars['Int']>;
  chapterName?: Maybe<Scalars['String']>;
  chapterPosition?: Maybe<Scalars['Int']>;
  copyId?: Maybe<Scalars['Int']>;
  coreConcept?: Maybe<LearningCardCoreConcept>;
  coreConceptCode?: Maybe<Scalars['String']>;
  coreConceptId?: Maybe<Scalars['Int']>;
  coreConceptPosition?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  coverImageLarge?: Maybe<Scalars['String']>;
  coverImageMedium?: Maybe<Scalars['String']>;
  coverImageMini?: Maybe<Scalars['String']>;
  coverImageSmall?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  goal?: Maybe<Scalars['String']>;
  grade?: Maybe<LearningCardGrade>;
  gradeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imageCount?: Maybe<Scalars['Int']>;
  isAutograde?: Maybe<Scalars['Boolean']>;
  isFinalCopy?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  maxPoints?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['Int']>;
  publisherName?: Maybe<Scalars['String']>;
  quizCount?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  searchKeywords?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['Int']>;
  shareLevel?: Maybe<Scalars['Int']>;
  storeCardId?: Maybe<Scalars['Int']>;
  storeCardUpdatedAt?: Maybe<Scalars['DateTime']>;
  subject?: Maybe<LearningCardSubject>;
  subjectId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoCount?: Maybe<Scalars['Int']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type LearningCardAnswer = {
  __typename?: 'LearningCardAnswer';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['Int']>;
  storeOptionId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningCardAudio = {
  __typename?: 'LearningCardAudio';
  audioFile?: Maybe<Scalars['String']>;
  audioUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  durationMillis?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  storeAudioId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningCardCollection = {
  __typename?: 'LearningCardCollection';
  learningCards?: Maybe<Array<Maybe<LearningCard>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LearningCardCoreConcept = {
  __typename?: 'LearningCardCoreConcept';
  chapterPosition?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  domainConceptId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  gradeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  quizzesCount?: Maybe<Scalars['Int']>;
  subjectId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoCoursesCount?: Maybe<Scalars['Int']>;
};

export type LearningCardGrade = {
  __typename?: 'LearningCardGrade';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isActivated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type LearningCardImage = {
  __typename?: 'LearningCardImage';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imageFile?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  largeUrl?: Maybe<Scalars['String']>;
  mediumUrl?: Maybe<Scalars['String']>;
  miniUrl?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  smallUrl?: Maybe<Scalars['String']>;
  storeImageId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export type LearningCardPage = {
  __typename?: 'LearningCardPage';
  answerCriteria?: Maybe<Scalars['Int']>;
  answerViewType?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<Maybe<LearningCardAnswer>>>;
  audioCount?: Maybe<Scalars['Int']>;
  audios?: Maybe<Array<Maybe<LearningCardAudio>>>;
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  cardId?: Maybe<Scalars['Int']>;
  chapterName?: Maybe<Scalars['String']>;
  chapterPosition?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  coreConceptId?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['Int']>;
  gradeId?: Maybe<Scalars['Int']>;
  gradingKeywords?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageCount?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<LearningCardImage>>>;
  isAutograde?: Maybe<Scalars['Boolean']>;
  isCopiedPage?: Maybe<Scalars['Boolean']>;
  isQuiz?: Maybe<Scalars['Boolean']>;
  isStorePage?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  pageType?: Maybe<LearningCardPagePageType>;
  pageTypeId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['Int']>;
  publisherName?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  searchKeywords?: Maybe<Scalars['String']>;
  shareLevel?: Maybe<Scalars['Int']>;
  storePageId?: Maybe<Scalars['Int']>;
  subjectId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoCount?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Maybe<LearningCardVideo>>>;
};

export type LearningCardPagePageType = {
  __typename?: 'LearningCardPagePageType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isAutograde?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum LearningCardPageType {
  Essay = 'ESSAY',
  MultipleChoice = 'MULTIPLE_CHOICE',
  NonQuiz = 'NON_QUIZ',
  TextEntry = 'TEXT_ENTRY'
}

export type LearningCardSubject = {
  __typename?: 'LearningCardSubject';
  alias?: Maybe<Scalars['String']>;
  asset?: Maybe<Scalars['String']>;
  assetBackground?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gradeLowerBound?: Maybe<Scalars['Int']>;
  gradeUpperBound?: Maybe<Scalars['Int']>;
  hasCardLectures?: Maybe<Scalars['Boolean']>;
  hasCourseGroup?: Maybe<Scalars['Boolean']>;
  hasUnit?: Maybe<Scalars['Boolean']>;
  hasVideoLectures?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isSpecialized?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningCardVideo = {
  __typename?: 'LearningCardVideo';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  durationMillis?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['Int']>;
  posterPath?: Maybe<Scalars['String']>;
  posterUrl?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  storeVideoId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoFile?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type LearningCoreConcept = {
  __typename?: 'LearningCoreConcept';
  chapterPosition?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  domainConceptId?: Maybe<Scalars['Int']>;
  domainId?: Maybe<Scalars['Int']>;
  gradeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pivotCoreConceptId?: Maybe<Scalars['Int']>;
  pivotUnitId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  quizzesCount?: Maybe<Scalars['Int']>;
  subjectId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoCoursesCount?: Maybe<Scalars['Int']>;
};

export type LearningGrade = {
  __typename?: 'LearningGrade';
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isActivated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  schoolYear?: Maybe<Scalars['Int']>;
};

export type LearningStoreCardAnswer = {
  __typename?: 'LearningStoreCardAnswer';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['Int']>;
  storeOptionId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningStoreCardAudio = {
  __typename?: 'LearningStoreCardAudio';
  audioFile?: Maybe<Scalars['String']>;
  audioUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  durationMillis?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  storeAudioId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningStoreCardCollection = {
  __typename?: 'LearningStoreCardCollection';
  learningStoreCards?: Maybe<Array<Maybe<LearningCard>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LearningStoreCardImage = {
  __typename?: 'LearningStoreCardImage';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imageFile?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  largeUrl?: Maybe<Scalars['String']>;
  mediumUrl?: Maybe<Scalars['String']>;
  miniUrl?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  smallUrl?: Maybe<Scalars['String']>;
  storeImageId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  width?: Maybe<Scalars['Int']>;
};

export type LearningStoreCardPage = {
  __typename?: 'LearningStoreCardPage';
  answerCriteria?: Maybe<Scalars['Int']>;
  answerViewType?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<Maybe<LearningStoreCardAnswer>>>;
  audioCount?: Maybe<Scalars['Int']>;
  audios?: Maybe<Array<Maybe<LearningStoreCardAudio>>>;
  authorId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  cardId?: Maybe<Scalars['Int']>;
  chapterName?: Maybe<Scalars['String']>;
  chapterPosition?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  copyId?: Maybe<Scalars['Int']>;
  coreConceptId?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['Int']>;
  gradeId?: Maybe<Scalars['Int']>;
  gradingKeywords?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  imageCount?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<LearningStoreCardImage>>>;
  isAutograde?: Maybe<Scalars['Boolean']>;
  isCopiedPage?: Maybe<Scalars['Boolean']>;
  isQuiz?: Maybe<Scalars['Boolean']>;
  isStorePage?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  optionViewType?: Maybe<Scalars['String']>;
  options?: Maybe<LearningStoreCardPageOptions>;
  pageType?: Maybe<LearningStoreCardPagePageType>;
  pageTypeId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  publisherId?: Maybe<Scalars['Int']>;
  publisherName?: Maybe<Scalars['String']>;
  quizType?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  searchKeywords?: Maybe<Scalars['String']>;
  shareLevel?: Maybe<Scalars['Int']>;
  storePageId?: Maybe<Scalars['Int']>;
  subjectId?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoCount?: Maybe<Scalars['Int']>;
  videos?: Maybe<Array<Maybe<LearningStoreCardVideo>>>;
};

export type LearningStoreCardPageOptions = {
  __typename?: 'LearningStoreCardPageOptions';
  content?: Maybe<Scalars['String']>;
  copyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isAnswer?: Maybe<Scalars['Boolean']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['Int']>;
  storeOptionId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type LearningStoreCardPagePageType = {
  __typename?: 'LearningStoreCardPagePageType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isAutograde?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningStoreCardVideo = {
  __typename?: 'LearningStoreCardVideo';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  durationMillis?: Maybe<Scalars['Float']>;
  fileSize?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  pageId?: Maybe<Scalars['Int']>;
  posterPath?: Maybe<Scalars['String']>;
  posterUrl?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  storeVideoId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoFile?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type LearningSubject = {
  __typename?: 'LearningSubject';
  backgroundIconUrl?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  gradeLowerBound?: Maybe<Scalars['Int']>;
  gradeUpperBound?: Maybe<Scalars['Int']>;
  hasCardLectures?: Maybe<Scalars['Boolean']>;
  hasUnit?: Maybe<Scalars['Boolean']>;
  hasVideoLectures?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isSpecialized?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type LearningSubmissionDto = {
  __typename?: 'LearningSubmissionDto';
  answers: Array<Maybe<LearningUserAnswerDto>>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type LearningUnit = {
  __typename?: 'LearningUnit';
  coreConceptId?: Maybe<Scalars['Int']>;
  coreConcepts?: Maybe<Array<Maybe<LearningCoreConcept>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  curriculum?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['Int']>;
  gradeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isGradeless?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  subjectId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LearningUserAnswerDto = {
  __typename?: 'LearningUserAnswerDto';
  correctOptions?: Maybe<Array<Maybe<Scalars['Json']>>>;
  duration: Scalars['Float'];
  essay?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  maxPoints: Scalars['Float'];
  multipleChoiceOption?: Maybe<Scalars['Json']>;
  pageId: Scalars['Float'];
  pageIndex: Scalars['Float'];
  pageType: LearningCardPageType;
  points: Scalars['Float'];
  textEntry?: Maybe<Scalars['String']>;
  userInput: Scalars['String'];
};

export type LegacyBookmarkResult = {
  __typename?: 'LegacyBookmarkResult';
  classId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type LegacyClassMember = {
  __typename?: 'LegacyClassMember';
  child?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum LegacyClassRelation {
  Invited = 'INVITED',
  Joined = 'JOINED',
  Waiting = 'WAITING'
}

export type LinkChildRequestDtoInput = {
  childId: Scalars['String'];
  relationshipType: RelationshipType;
};

export type LinkChildResponseDto = {
  __typename?: 'LinkChildResponseDto';
  childId: Scalars['String'];
  createdAt: Scalars['String'];
  guardianId: Scalars['String'];
  id: Scalars['String'];
  relationshipType: RelationshipType;
};

export type LinkGuardianRequestDtoInput = {
  guardianId: Scalars['String'];
  relationshipType: RelationshipType;
};

/** 마케팅 수신 정보 채널별 동의 여부 */
export type MarketingAgreement = {
  __typename?: 'MarketingAgreement';
  /** 마케팅 수신 정보 동의 여부(email) */
  isEmailAgreed: Scalars['Boolean'];
  /** 마케팅 수신 정보 동의 여부(앱 푸시) */
  isPushAgreed: Scalars['Boolean'];
  /** 마케팅 수신 정보 동의 여부(SMS) */
  isSmsAgreed: Scalars['Boolean'];
};

export type MarketingAgreement2Input = {
  /** 마케팅 수신 정보 동의 여부(email) */
  isEmailAgreed: Scalars['Boolean'];
  /** 마케팅 수신 정보 동의 여부(앱 푸시) */
  isPushAgreed: Scalars['Boolean'];
  /** 마케팅 수신 정보 동의 여부(SMS) */
  isSmsAgreed: Scalars['Boolean'];
};

export type MealFeedDto = {
  __typename?: 'MealFeedDto';
  actor: Scalars['Json'];
  content: Scalars['String'];
  generator: Scalars['Json'];
  id: Scalars['String'];
  object: Scalars['Json'];
  publishedAt: Scalars['String'];
  recipientId: Scalars['String'];
  summary: Scalars['String'];
  target: Scalars['Json'];
  type: Scalars['String'];
};

export type MealFeedsCollectionDto = {
  __typename?: 'MealFeedsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  mealFeeds: Array<Maybe<MealFeedDto>>;
};

export type MealPost = {
  __typename?: 'MealPost';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type MealPostCollection = {
  __typename?: 'MealPostCollection';
  mealPosts?: Maybe<Array<Maybe<MealPost>>>;
};

export type Meeting = {
  __typename?: 'Meeting';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  status: Status2;
  zoomMeeting: Scalars['Json'];
  zoomSignature: Scalars['String'];
};

export type Meeting2 = {
  __typename?: 'Meeting2';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  status: Status2;
  zoomMeeting: Scalars['Json'];
  zoomSignature: Scalars['String'];
};

export type MeetingDto = {
  __typename?: 'MeetingDto';
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  id: Scalars['String'];
  memberId: Scalars['String'];
  status: Status2;
  zoomMeeting: Scalars['Json'];
  zoomSignature: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  active: Scalars['Boolean'];
  blocked: Scalars['Json'];
  canStartMeeting?: Maybe<Scalars['Boolean']>;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  group?: Maybe<Group>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profileImageId?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  role: Role;
  roleId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type MemberAttendancesCollectionDto = {
  __typename?: 'MemberAttendancesCollectionDto';
  attendances: Array<Maybe<AttendanceDto>>;
};

export type MemberDto = {
  __typename?: 'MemberDto';
  /** 구성원 활성화 여부 */
  active: Scalars['Boolean'];
  /** 구성원 차단 유무 */
  blocked: Scalars['Boolean'];
  /** 줌 미팅 시작 권한 */
  canStartMeeting?: Maybe<Scalars['Boolean']>;
  /** 구성원이 속한 클래스룸 식별자 */
  classroomId: Scalars['String'];
  /** 구성원 가입 일자 */
  createdAt: Scalars['String'];
  group?: Maybe<Group>;
  /** 구성원 식별자 */
  id: Scalars['String'];
  /** 구성원 가입 프로비저닝 완료 유무 */
  isProvisioningCompleted: Scalars['Boolean'];
  /** 구성원 이름 */
  name?: Maybe<Scalars['String']>;
  /** 미디어 서비스 내의 구성원 프로필 이미지 식별자 */
  profileImageId?: Maybe<Scalars['String']>;
  /** 구성원 프로필 이미지 URL */
  profileImageUrl?: Maybe<Scalars['String']>;
  role: Role;
  roleId?: Maybe<Scalars['String']>;
  /** 구성원 최근 수정일자 */
  updatedAt: Scalars['String'];
};

export enum MemberGroup {
  Guardian = 'GUARDIAN',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type MemberPushNotificationSettingsDto = {
  __typename?: 'MemberPushNotificationSettingsDto';
  id: Scalars['String'];
  pushNotificationSettings: PushNotificationSettings;
};

export enum MemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type MembersCollectionDto = {
  __typename?: 'MembersCollectionDto';
  /** 다음 페이지 검색을 위한 커서 */
  cursor?: Maybe<Scalars['String']>;
  members: Array<Maybe<MemberDto>>;
};

/** 모바일 정보 */
export type Mobile = {
  __typename?: 'Mobile';
  /** 휴대폰 국가 코드입니다. */
  countryCode: Scalars['String'];
  /** 휴대폰 번호입니다. */
  number: Scalars['String'];
};

export type Mobile2 = {
  __typename?: 'Mobile2';
  /** 휴대폰 국가 코드입니다. */
  countryCode: Scalars['String'];
  /** 휴대폰 번호입니다. */
  number: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   *
   *
   * Equivalent to POST /classtalk-service/meetings/{meetingId}/attendees
   */
  addAttendeeToClasstalkMeeting?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /notification-service/push-notification/devices
   */
  addDevice?: Maybe<AddDeviceResponseDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/posts/{postId}/comments/{commentId}/actions/react
   */
  addReactionToComment?: Maybe<CommentDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/react
   */
  addReactionToPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/posts/{postId}/comments/{commentId}/replies/{replyId}/actions/react
   */
  addReactionToReply?: Maybe<ReplyDto>;
  /**
   *
   *
   * Equivalent to POST /media-service/admin/images
   */
  adminApiCreateImage?: Maybe<Image>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/archive
   */
  archiveChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels-dto/{channelId}/actions/archive
   */
  archiveChannelDto?: Maybe<ChannelDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/actions/archiveClassroom
   */
  archiveClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/actions/assign
   */
  assignAssignment?: Maybe<AssignmentDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/members/{memberId}/actions/assign-role
   */
  assignMemberRole?: Maybe<MemberDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/members/{memberId}/actions/block
   */
  blockMember?: Maybe<MemberDto>;
  /**
   * 클래스룸 즐겨찾기
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/bookmark
   */
  bookmarkClassroom?: Maybe<ClassroomFavoriteDto>;
  /** POST /users/me/favorited_classes/{args.classId} */
  bookmarkLegacyClass?: Maybe<LegacyBookmarkResult>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/legacy-classrooms/{classroomId}/bookmark
   */
  bookmarkLegacyClassroom?: Maybe<ClassroomFavoriteDto>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/change-email
   */
  changeEmail?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/change-mobile
   */
  changeMobile?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to PUT /account-service/accounts/{id}/password
   */
  changePassword?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignment-submission/actions/complete-all
   */
  completeAllAssignmentSubmission?: Maybe<Array<Maybe<AssignmentSubmissionDto>>>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignment-submission/actions/complete
   */
  completeAssignmentSubmission?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/onboarding-checklist/actions/complete-action
   */
  completeOnboardingChecklistAction?: Maybe<OnboardingChecklistDto>;
  /** POST /ai_assignments */
  createAiTest?: Maybe<AiTestCreated>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/assignments
   */
  createAssignment?: Maybe<AssignmentDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/assignment-submissions/{assignmentSubmissionId}/comments
   */
  createAssignmentSubmissionComment?: Maybe<AssignmentSubmissionCommentDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/attendances
   */
  createAttendance?: Maybe<AttendanceDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels
   */
  createChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels-dto
   */
  createChannelDto?: Maybe<ChannelDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channel-groups
   */
  createChannelGroup?: Maybe<ChannelGroupDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channel-groups-dto
   */
  createChannelGroupDto?: Maybe<ChannelGroupDto>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/child-code
   */
  createChildCode?: Maybe<ChildCodeResponseDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms
   */
  createClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to POST /classtalk-service/meetings
   */
  createClasstalkMeeting?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/comments
   */
  createComment?: Maybe<CommentDto>;
  /**
   *
   *
   * Equivalent to POST /insights-service/classrooms/{classroomId}/members/{memberId}/cumulative-record
   */
  createCumulativeRecord?: Maybe<InsightsCumulativeRecordDto>;
  /**
   *
   *
   * Equivalent to POST /media-service/files
   */
  createFile?: Maybe<File>;
  /**
   *
   *
   * Equivalent to POST /insights-service/subscription/free-plan
   */
  createFreePlanSubscription?: Maybe<InsightsOrderDto>;
  /**
   *
   *
   * Equivalent to POST /insights-service/goals
   */
  createGoal?: Maybe<InsightsGoalDto>;
  /**
   *
   *
   * Equivalent to POST /media-service/images
   */
  createImage?: Maybe<Image>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/certificate
   */
  createPersonalCertificate?: Maybe<AccountServiceAccountCertificate>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts
   */
  createPost?: Maybe<PostDto>;
  createPublisherSubscription: PublisherSubscriptionDto;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/comments/{commentId}/replies
   */
  createReply?: Maybe<ReplyDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/tags
   */
  createTag?: Maybe<TagDto>;
  /**
   *
   *
   * Equivalent to POST /media-service/videos
   */
  createVideo?: Maybe<Video>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/members/{memberId}/actions/deactivate
   */
  deactivateClassroomMember?: Maybe<MemberDto>;
  /**
   *
   *
   * Equivalent to DELETE /account-service/accounts/{id}
   */
  deleteAccount?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}
   */
  deleteAssignment?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/assignment-submissions/{assignmentSubmissionId}/comments/{commentId}
   */
  deleteAssignmentSubmissionComment?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/classrooms/{classroomId}/channel-groups/{channelGroupId}/channels/{channelId}
   */
  deleteChannel?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/classrooms/{classroomId}/channel-groups/{channelGroupId}
   */
  deleteChannelGroup?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/classrooms/{classroomId}
   */
  deleteClassroom?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classtalk-service/internal-meetings/{meetingId}
   */
  deleteClasstalkMeeting?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/posts/{postId}/comments/{commentId}
   */
  deleteComment?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /insights-service/classrooms/{classroomId}/cumulative-records/{cumulativeRecordId}
   */
  deleteCumulativeRecord?: Maybe<Scalars['InsightsJson']>;
  /**
   *
   *
   * Equivalent to DELETE /media-service/files/{fileId}
   */
  deleteFile?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /media-service/images/{imageId}
   */
  deleteImage?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/classrooms/{classroomId}/posts/{postId}
   */
  deletePost?: Maybe<Scalars['Json']>;
  deletePublisherSubscription?: Maybe<Scalars['Void']>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/comments/{commentId}/replies/{replyId}
   */
  deleteReply?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /media-service/videos/{videoId}
   */
  deleteVideo?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/actions/disintegrate-zoom
   */
  disintegrateZoom?: Maybe<Scalars['Json']>;
  /** POST /ai_assignments */
  distributeAiTest?: Maybe<AiTestCreated>;
  /**
   *
   *
   * Equivalent to POST /insights-service/orders
   */
  draftOrder?: Maybe<InsightsOrderDto>;
  /**
   *
   *
   * Equivalent to DELETE /classtalk-service/meetings/{meetingId}
   */
  endClasstalkMeeting?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/end-meeting
   */
  endMeeting?: Maybe<ChannelDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/excused-attendances
   */
  excuseAttendance?: Maybe<AttendanceDto>;
  /**
   *
   *
   * Equivalent to POST /classtalk-service/recordings/actions/generate-download-url
   */
  generateRecordingDownloadUrl?: Maybe<RecordingDownloadUrlDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/onboarding-checklist/actions/hide
   */
  hideOnboardingChecklist?: Maybe<OnboardingChecklistDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/attendances/initiate-export
   */
  initiateExportAttendances?: Maybe<ExcelExportJobStateDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/actions/integrate-zoom
   */
  integrateZoom?: Maybe<ZoomIntegrationDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/invitations
   */
  inviteMembers?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/join
   */
  joinChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels-dto/{channelId}/actions/join
   */
  joinChannelDto?: Maybe<ChannelDto>;
  /** POST /classes/{args.classId}/members/{args.userId} */
  joinLegacyClass?: Maybe<LegacyClassMember>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/join-meeting
   */
  joinMeeting?: Maybe<MeetingDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/leave
   */
  leaveChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels-dto/{channelId}/actions/leave
   */
  leaveChannelDto?: Maybe<ChannelDto>;
  /**
   * 현재 사용자의 엔터프라이즈 나가기
   *
   * Equivalent to DELETE /enterprise-service/enterprises/{enterpriseId}/actions/leave
   */
  leaveEnterprise?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /account-service/guardian-relationships
   */
  linkChild?: Maybe<LinkChildResponseDto>;
  /**
   *
   *
   * Equivalent to POST /account-service/guardian-relationships/link-guardian
   */
  linkGuardian?: Maybe<LinkChildResponseDto>;
  /**
   *
   *
   * Equivalent to POST /notification-service/generators/{generatorType}/{generatorId}/actions/mark-as-read
   */
  markNewsAsRead?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /notification-service/notice-feeds/{noticeFeedId}/actions/mark-as-read
   */
  markNoticeFeedAsRead?: Maybe<NoticeFeedDto>;
  /**
   *
   *
   * Equivalent to POST /notification-service/notifications/{notificationId}/actions/mark-as-read
   */
  markNotificationAsRead?: Maybe<NotificationDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/mark-as-read
   */
  markPostAsRead?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to PUT /organization-service/organizations/{organizationId}/members/{memberId}/actions/change-member-account-password
   */
  organizationChangeMemberAccountPassword?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to PUT /organization-service/organizations/{orgId}/members/{memberId}/actions/change-member-org-role
   */
  organizationChangeMemberOrgRole?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/orders/{orderId}/actions/complete
   */
  organizationCompleteOrder?: Maybe<OrganizationOrderDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{organizationId}/members
   */
  organizationCreateMember?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/orders
   */
  organizationCreateOrder?: Maybe<OrganizationOrderDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/classrooms
   */
  organizationCreateOrgClassroom?: Maybe<OrganizationClassroomWithAdminDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/org-units
   */
  organizationCreateOrgUnit?: Maybe<OrganizationOrganizationalUnitDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/plans
   */
  organizationCreatePlan?: Maybe<OrganizationPlanDto>;
  /**
   *
   *
   * Equivalent to DELETE /organization-service/organizations/{orgId}/org-units/{orgUnitId}
   */
  organizationDeleteOrgUnit?: Maybe<Scalars['OrganizationJson']>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/invitations
   */
  organizationInviteMembers?: Maybe<Scalars['OrganizationJson']>;
  /**
   *
   *
   * Equivalent to DELETE /organization-service/organizations/{orgId}/actions/leave
   */
  organizationLeaveOrg?: Maybe<Scalars['OrganizationJson']>;
  /**
   *
   *
   * Equivalent to PUT /organization-service/organizations/{orgId}/actions/mark-as-primary
   */
  organizationMarkOrgAsPrimary?: Maybe<OrganizationPrimaryOrgMembershipDto>;
  /**
   *
   *
   * Equivalent to PUT /organization-service/private/organizations/{orgId}/actions/mark-as-primary
   */
  organizationPrivateMarkOrgAsPrimaryControllerMarkOrgAsPrimary?: Maybe<OrganizationPrimaryOrgMembershipDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/org-code
   */
  organizationRecreateOrgCode?: Maybe<OrganizationOrgCodeDto>;
  /**
   * 클래스에 복수의 구성원을 등록합니다.
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/classrooms/{classroomId}/members
   */
  organizationRegisterClassroomMembers?: Maybe<Array<Maybe<OrganizationClassroomMemberDto>>>;
  /**
   *
   *
   * Equivalent to POST /organization-service/registration
   */
  organizationRegisterForOrg?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to DELETE /organization-service/organizations/{orgId}/members/{memberId}
   */
  organizationRemoveMember?: Maybe<Scalars['OrganizationJson']>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{orgId}/messages
   */
  organizationSendMessage?: Maybe<OrganizationMessageDto>;
  /**
   *
   *
   * Equivalent to POST /organization-service/organizations/{organizationId}/members/{memberId}
   */
  organizationUpdateMember?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to PATCH /organization-service/admin/organizations/{orgId}/message-service
   */
  organizationUpdateMessageService?: Maybe<OrganizationMessageServiceDto>;
  /**
   *
   *
   * Equivalent to PATCH /organization-service/organizations/{orgId}/classrooms/{classroomId}
   */
  organizationUpdateOrgClassroom?: Maybe<OrganizationClassroomWithAdminDto>;
  /**
   *
   *
   * Equivalent to PATCH /organization-service/organizations/{orgId}/policy-consent
   */
  organizationUpdateOrgPolicyConsent?: Maybe<OrganizationOrganizationDto>;
  /**
   *
   *
   * Equivalent to PUT /organization-service/organizations/{orgId}/org-units/{orgUnitId}
   */
  organizationUpdateOrgUnit?: Maybe<OrganizationOrganizationalUnitDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/pin-post
   */
  pinPost?: Maybe<ChannelPostPinDto>;
  /**
   *
   *
   * Equivalent to POST /insights-service/activities/{activityId}/praise
   */
  praiseChildActivity?: Maybe<InsightsActivityDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/privates/classrooms
   */
  privateCreateClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to POST /enterprise-service/privates/enterprises/{enterpriseId}/members
   */
  privateCreateMemberControllerPrivateCreateMember?: Maybe<EnterpriseMemberDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/privates/classrooms/{classroomId}/registration
   */
  privateRegisterForClassroom?: Maybe<MemberDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/privates/classrooms/{classroomId}
   */
  privateUpdateClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/publish
   */
  publishPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/class-code/actions/recreate
   */
  recreateClassroomClassCode?: Maybe<ClassCodeDto>;
  /**
   * 구성원 가입
   *
   * Equivalent to POST /classroom-service/registration
   */
  registerForClassroom?: Maybe<MemberDto>;
  /** POST /schools */
  registerSchool?: Maybe<SchoolRegistration>;
  /**
   *
   *
   * Equivalent to POST /classtalk-service/register
   */
  registerToClasstalk?: Maybe<ClasstalkUserDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignees/{assigneeId}/actions/regrade
   */
  regradeAssignmentSubmission?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/remind
   */
  remindPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to DELETE /notification-service/push-notification/devices/{playerId}
   */
  removeDevice?: Maybe<RemoveDeviceResponseDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/posts/{postId}/comments/{commentId}/actions/unreact
   */
  removeReactionFromComment?: Maybe<CommentDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/unreact
   */
  removeReactionFromPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/posts/{postId}/comments/{commentId}/replies/{replyId}/actions/unreact
   */
  removeReactionFromReply?: Maybe<ReplyDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/report
   */
  reportPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/request-email-change
   */
  requestEmailChange?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/request-mobile-change
   */
  requestMobileChange?: Maybe<PinCodeDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignees/{assigneeId}/actions/save-points
   */
  saveAssignmentSubmissionPoints?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignees/{assigneeId}/actions/save-essay-answer-points-to-learning-assignment
   */
  saveEssayAnswerPointsToLearningAssignment?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/actions/save-answer-to-learning-assignment
   */
  saveMemberAnswerToLearningAssignment?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/save-as-draft
   */
  savePostAsDraft?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/schedule
   */
  schedulePost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to POST /classtalk-service/meetings/actions/start
   */
  startClasstalkMeeting?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/start-meeting
   */
  startMeeting?: Maybe<MeetingDto>;
  /**
   *
   *
   * Equivalent to POST /account-service/student-registration-codes
   */
  studentRegistrationCodesControllerCreateStudentRegistrationCode?: Maybe<StudentRegistrationCodeDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/submit-learning-assignment
   */
  submitLearningSubmission?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/submit
   */
  submitSubmission?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to POST /insights-service/goals/{goalId}/actions/suspended
   */
  suspendGoal?: Maybe<InsightsGoalDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/translate
   */
  translatePost?: Maybe<TranslatedPostDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/unarchive
   */
  unarchiveChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/channels-dto/{channelId}/actions/unarchive
   */
  unarchiveChannelDto?: Maybe<ChannelDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/actions/unarchiveClassroom
   */
  unarchiveClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/members/{memberId}/actions/unblock
   */
  unblockMember?: Maybe<MemberDto>;
  /**
   * 클래스룸 즐겨찾기 해제
   *
   * Equivalent to DELETE /classroom-service/classrooms/{classroomId}/bookmark
   */
  unbookmarkClassroom?: Maybe<Scalars['Json']>;
  /** DELETE /users/me/favorited_classes/{args.classId} */
  unbookmarkLegacyClass?: Maybe<LegacyBookmarkResult>;
  /**
   *
   *
   * Equivalent to DELETE /classroom-service/legacy-classrooms/{classroomId}/bookmark
   */
  unbookmarkLegacyClassroom?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to DELETE /account-service/guardian-relationships/{id}
   */
  unlinkChild?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/channels/{channelId}/actions/unpin-post
   */
  unpinPost?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/posts/{postId}/actions/unschedule
   */
  unschedulePost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to PATCH /account-service/accounts/{id}
   */
  updateAccount?: Maybe<AccountDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}
   */
  updateAssignedAssignment?: Maybe<AssignmentDto>;
  /**
   *
   *
   * Equivalent to PUT /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}
   */
  updateAssignment?: Maybe<AssignmentDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/assignment-submissions/{assignmentSubmissionId}/comments/{commentId}
   */
  updateAssignmentSubmissionComment?: Maybe<AssignmentSubmissionCommentDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/channels/{channelId}
   */
  updateChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/channels-dto/{channelId}
   */
  updateChannelDto?: Maybe<ChannelDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/channel-groups/{channelGroupId}
   */
  updateChannelGroup?: Maybe<ChannelGroupDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/channel-groups-dto/{channelGroupId}
   */
  updateChannelGroupDto?: Maybe<ChannelGroupDto>;
  /**
   *
   *
   * Equivalent to PUT /account-service/accounts/{id}/child-school-info
   */
  updateChildSchoolInfo?: Maybe<AccountDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}
   */
  updateClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/members/{memberId}
   */
  updateClassroomMember?: Maybe<MemberDto>;
  /**
   *
   *
   * Equivalent to PATCH /classtalk-service/classtalk-users/{userId}
   */
  updateClasstalkUser?: Maybe<ClasstalkUserDto>;
  /**
   *
   *
   * Equivalent to PATCH /classtalk-service/classtalk-users/settings/auto-recording
   */
  updateClasstalkUserAutoRecordingSetting?: Maybe<ClasstalkUserDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/posts/{postId}/comments/{commentId}
   */
  updateComment?: Maybe<CommentDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/settings/push-notifications
   */
  updateMemberPushNotificationSettings?: Maybe<MemberPushNotificationSettingsDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/posts/{postId}
   */
  updatePost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/action/update-admin-exclusive-by-admin
   */
  updatePostAdminExclusiveByAdmin?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to POST /classroom-service/classrooms/{classroomId}/posts/{postId}/action/update-channel-by-admin
   */
  updatePostChannelAdmin?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/comments/{commentId}/replies/{replyId}
   */
  updateReply?: Maybe<ReplyDto>;
  /**
   *
   *
   * Equivalent to PATCH /classroom-service/classrooms/{classroomId}/tags/{tag}
   */
  updateTag?: Maybe<TagDto>;
  /**
   *
   *
   * Equivalent to POST /account-service/accounts/{id}/child-code/{code}/verify
   */
  verifyChildCode?: Maybe<ChildCodeResponseDto>;
  /**
   *
   *
   * Equivalent to POST /insights-service/orders/{orderId}/verify
   */
  verifyOrderControllerVerifyOrder?: Maybe<Scalars['InsightsJson']>;
};


export type MutationAddAttendeeToClasstalkMeetingArgs = {
  addAttendeeToMeetingDtoInput: AddAttendeeToMeetingDtoInput;
  meetingId: Scalars['String'];
};


export type MutationAddDeviceArgs = {
  addDeviceRequestDtoInput?: InputMaybe<AddDeviceRequestDtoInput>;
};


export type MutationAddReactionToCommentArgs = {
  addReactionToCommentDtoInput: AddReactionToCommentDtoInput;
  commentId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationAddReactionToPostArgs = {
  addReactionToPostDtoInput: AddReactionToPostDtoInput;
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationAddReactionToReplyArgs = {
  addReactionToReplyDtoInput: AddReactionToReplyDtoInput;
  commentId: Scalars['String'];
  postId: Scalars['String'];
  replyId: Scalars['String'];
};


export type MutationAdminApiCreateImageArgs = {
  createImageDtoInput: CreateImageDtoInput;
};


export type MutationArchiveChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationArchiveChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationArchiveClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationAssignAssignmentArgs = {
  assignAssignmentDtoInput: AssignAssignmentDtoInput;
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationAssignMemberRoleArgs = {
  assignMemberRoleDtoInput: AssignMemberRoleDtoInput;
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationBlockMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationBookmarkClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationBookmarkLegacyClassArgs = {
  classId?: InputMaybe<Scalars['String']>;
};


export type MutationBookmarkLegacyClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  changeEmailDtoInput: ChangeEmailDtoInput;
  id: Scalars['String'];
};


export type MutationChangeMobileArgs = {
  changeMobileDtoInput: ChangeMobileDtoInput;
  id: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  changePasswordDtoInput: ChangePasswordDtoInput;
  id: Scalars['String'];
};


export type MutationCompleteAllAssignmentSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type MutationCompleteAssignmentSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  completeAssignmentSubmissionDtoInput: CompleteAssignmentSubmissionDtoInput;
};


export type MutationCompleteOnboardingChecklistActionArgs = {
  classroomId: Scalars['String'];
  completeOnboardingChecklistActionDtoInput: CompleteOnboardingChecklistActionDtoInput;
};


export type MutationCreateAiTestArgs = {
  input?: InputMaybe<CreateAiTestDtoInput>;
};


export type MutationCreateAssignmentArgs = {
  classroomId: Scalars['String'];
  createAssignmentDtoInput: CreateAssignmentDtoInput;
};


export type MutationCreateAssignmentSubmissionCommentArgs = {
  assignmentSubmissionId: Scalars['String'];
  createAssignmentSubmissionCommentDtoInput: CreateAssignmentSubmissionCommentDtoInput;
};


export type MutationCreateAttendanceArgs = {
  classroomId: Scalars['String'];
  createAttendanceDtoInput?: InputMaybe<CreateAttendanceDtoInput>;
};


export type MutationCreateChannelArgs = {
  classroomId: Scalars['String'];
  createChannelDtoInput: CreateChannelDtoInput;
};


export type MutationCreateChannelDtoArgs = {
  classroomId: Scalars['String'];
  createChannelDtoInput: CreateChannelDtoInput;
};


export type MutationCreateChannelGroupArgs = {
  classroomId: Scalars['String'];
  createChannelGroupDtoInput: CreateChannelGroupDtoInput;
};


export type MutationCreateChannelGroupDtoArgs = {
  classroomId: Scalars['String'];
  createChannelGroupDtoInput: CreateChannelGroupDtoInput;
};


export type MutationCreateChildCodeArgs = {
  id: Scalars['String'];
};


export type MutationCreateClassroomArgs = {
  createClassroomDtoInput: CreateClassroomDtoInput;
};


export type MutationCreateClasstalkMeetingArgs = {
  createMeetingDtoInput?: InputMaybe<CreateMeetingDtoInput>;
};


export type MutationCreateCommentArgs = {
  classroomId: Scalars['String'];
  createCommentDtoInput: CreateCommentDtoInput;
  postId: Scalars['String'];
};


export type MutationCreateCumulativeRecordArgs = {
  classroomId: Scalars['String'];
  createCumulativeRecordDtoInput: InsightsCreateCumulativeRecordDtoInput;
  memberId: Scalars['String'];
};


export type MutationCreateFileArgs = {
  createFileDtoInput: CreateFileDtoInput;
};


export type MutationCreateFreePlanSubscriptionArgs = {
  createFreePlanSubscriptionDtoInput: InsightsCreateFreePlanSubscriptionDtoInput;
};


export type MutationCreateGoalArgs = {
  childId: Scalars['String'];
  createGoalDtoInput: InsightsCreateGoalDtoInput;
};


export type MutationCreateImageArgs = {
  createImageDtoInput: CreateImageDtoInput;
};


export type MutationCreatePersonalCertificateArgs = {
  createPersonalCertificateDtoInput: Scalars['Json'];
  id: Scalars['String'];
};


export type MutationCreatePostArgs = {
  classroomId: Scalars['String'];
  createPostDtoInput?: InputMaybe<CreatePostDtoInput>;
};


export type MutationCreatePublisherSubscriptionArgs = {
  locale: Scalars['String'];
  publisherId: Scalars['String'];
};


export type MutationCreateReplyArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  createReplyDtoInput: CreateReplyDtoInput;
  postId: Scalars['String'];
};


export type MutationCreateTagArgs = {
  classroomId: Scalars['String'];
  createTagDtoInput: CreateTagDtoInput;
};


export type MutationCreateVideoArgs = {
  createVideoDtoInput: CreateVideoDtoInput;
};


export type MutationDeactivateClassroomMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationDeleteAssignmentSubmissionCommentArgs = {
  assignmentSubmissionId: Scalars['String'];
  commentId: Scalars['String'];
};


export type MutationDeleteChannelArgs = {
  channelGroupId: Scalars['String'];
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationDeleteChannelGroupArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationDeleteClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationDeleteClasstalkMeetingArgs = {
  meetingId: Scalars['String'];
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationDeleteCumulativeRecordArgs = {
  classroomId: Scalars['String'];
  cumulativeRecordId: Scalars['String'];
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['String'];
};


export type MutationDeleteImageArgs = {
  imageId: Scalars['String'];
};


export type MutationDeletePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationDeletePublisherSubscriptionArgs = {
  locale: Scalars['String'];
  publisherId: Scalars['String'];
};


export type MutationDeleteReplyArgs = {
  commentId: Scalars['String'];
  replyId: Scalars['String'];
};


export type MutationDeleteVideoArgs = {
  videoId: Scalars['String'];
};


export type MutationDisintegrateZoomArgs = {
  classroomId: Scalars['String'];
};


export type MutationDistributeAiTestArgs = {
  input?: InputMaybe<DistributeAiTestDtoInput>;
};


export type MutationDraftOrderArgs = {
  childIds: Array<InputMaybe<Scalars['String']>>;
  paymentMethod: InsightsPaymentMethod;
};


export type MutationEndClasstalkMeetingArgs = {
  meetingId: Scalars['String'];
};


export type MutationEndMeetingArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationExcuseAttendanceArgs = {
  classroomId: Scalars['String'];
  excuseAttendanceDtoInput: ExcuseAttendanceDtoInput;
};


export type MutationGenerateRecordingDownloadUrlArgs = {
  generateRecordingDownloadUrlDtoInput: GenerateRecordingDownloadUrlDtoInput;
};


export type MutationHideOnboardingChecklistArgs = {
  classroomId: Scalars['String'];
};


export type MutationInitiateExportAttendancesArgs = {
  classroomId: Scalars['String'];
  initiateExportAttendancesDtoInput: InitiateExportAttendancesDtoInput;
};


export type MutationIntegrateZoomArgs = {
  classroomId: Scalars['String'];
  integrateZoomDtoInput: IntegrateZoomDtoInput;
};


export type MutationInviteMembersArgs = {
  classroomId: Scalars['String'];
  inviteMembersDtoInput: InviteMembersDtoInput;
};


export type MutationJoinChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationJoinChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationJoinLegacyClassArgs = {
  classId?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<JoinLegacyClassDtoInput>;
  userId?: InputMaybe<Scalars['String']>;
};


export type MutationJoinMeetingArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationLeaveChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationLeaveChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationLeaveEnterpriseArgs = {
  enterpriseId: Scalars['String'];
};


export type MutationLinkChildArgs = {
  linkChildRequestDtoInput: LinkChildRequestDtoInput;
};


export type MutationLinkGuardianArgs = {
  linkGuardianRequestDtoInput: LinkGuardianRequestDtoInput;
};


export type MutationMarkNewsAsReadArgs = {
  generatorId: Scalars['String'];
  generatorType: Scalars['String'];
};


export type MutationMarkNoticeFeedAsReadArgs = {
  noticeFeedId: Scalars['String'];
};


export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['String'];
};


export type MutationMarkPostAsReadArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationOrganizationChangeMemberAccountPasswordArgs = {
  changeMemberAccountPasswordDtoInput: OrganizationChangeMemberAccountPasswordDtoInput;
  memberId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationOrganizationChangeMemberOrgRoleArgs = {
  changeMemberOrgRoleDtoInput: OrganizationChangeMemberOrgRoleDtoInput;
  memberId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationOrganizationCompleteOrderArgs = {
  completeOrderDtoInput: OrganizationCompleteOrderDtoInput;
  orderId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationOrganizationCreateMemberArgs = {
  createMemberDtoInput: OrganizationCreateMemberDtoInput;
  organizationId: Scalars['String'];
};


export type MutationOrganizationCreateOrderArgs = {
  createOrderDtoInput: OrganizationCreateOrderDtoInput;
};


export type MutationOrganizationCreateOrgClassroomArgs = {
  createOrgClassroomDtoInput: OrganizationCreateOrgClassroomDtoInput;
  orgId: Scalars['String'];
};


export type MutationOrganizationCreateOrgUnitArgs = {
  createOrgUnitDtoInput: OrganizationCreateOrgUnitDtoInput;
  orgId: Scalars['String'];
};


export type MutationOrganizationCreatePlanArgs = {
  createPlanDtoInput: OrganizationCreatePlanDtoInput;
};


export type MutationOrganizationDeleteOrgUnitArgs = {
  orgId: Scalars['String'];
  orgUnitId: Scalars['String'];
};


export type MutationOrganizationInviteMembersArgs = {
  inviteMembersDtoInput: OrganizationInviteMembersDtoInput;
  orgId: Scalars['String'];
};


export type MutationOrganizationLeaveOrgArgs = {
  orgId: Scalars['String'];
};


export type MutationOrganizationMarkOrgAsPrimaryArgs = {
  orgId: Scalars['String'];
};


export type MutationOrganizationPrivateMarkOrgAsPrimaryControllerMarkOrgAsPrimaryArgs = {
  orgId: Scalars['String'];
  privateMarkOrgAsPrimaryDtoInput: OrganizationPrivateMarkOrgAsPrimaryDtoInput;
};


export type MutationOrganizationRecreateOrgCodeArgs = {
  orgId: Scalars['String'];
};


export type MutationOrganizationRegisterClassroomMembersArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['String'];
  registerClassroomMembersDtoInput: OrganizationRegisterClassroomMembersDtoInput;
};


export type MutationOrganizationRegisterForOrgArgs = {
  registerForOrgDtoInput: OrganizationRegisterForOrgDtoInput;
};


export type MutationOrganizationRemoveMemberArgs = {
  memberId: Scalars['String'];
  orgId: Scalars['String'];
};


export type MutationOrganizationSendMessageArgs = {
  orgId: Scalars['String'];
  sendMessageDtoInput: OrganizationSendMessageDtoInput;
};


export type MutationOrganizationUpdateMemberArgs = {
  memberId: Scalars['String'];
  organizationId: Scalars['String'];
  updateMemberDtoInput: OrganizationUpdateMemberDtoInput;
};


export type MutationOrganizationUpdateMessageServiceArgs = {
  orgId: Scalars['String'];
  updateMessageServiceDtoInput: OrganizationUpdateMessageServiceDtoInput;
};


export type MutationOrganizationUpdateOrgClassroomArgs = {
  classroomId: Scalars['String'];
  orgId: Scalars['String'];
  updateOrgClassroomDtoInput: OrganizationUpdateOrgClassroomDtoInput;
};


export type MutationOrganizationUpdateOrgPolicyConsentArgs = {
  orgId: Scalars['String'];
  updateOrgPolicyConsentDtoInput: OrganizationUpdateOrgPolicyConsentDtoInput;
};


export type MutationOrganizationUpdateOrgUnitArgs = {
  orgId: Scalars['String'];
  orgUnitId: Scalars['String'];
  updateOrgUnitDtoInput: OrganizationUpdateOrgUnitDtoInput;
};


export type MutationPinPostArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  pinPostDtoInput: PinPostDtoInput;
};


export type MutationPraiseChildActivityArgs = {
  activityId: Scalars['String'];
  childId: Scalars['String'];
  praiseChildActivityDtoInput: InsightsPraiseChildActivityDtoInput;
};


export type MutationPrivateCreateClassroomArgs = {
  privateCreateClassroomDtoInput: PrivateCreateClassroomDtoInput;
};


export type MutationPrivateCreateMemberControllerPrivateCreateMemberArgs = {
  enterpriseId: Scalars['String'];
  privateCreateEnterpriseMemberDtoInput: PrivateCreateEnterpriseMemberDtoInput;
};


export type MutationPrivateRegisterForClassroomArgs = {
  classroomId: Scalars['String'];
  privateRegisterDtoInput: PrivateRegisterDtoInput;
};


export type MutationPrivateUpdateClassroomArgs = {
  classroomId: Scalars['String'];
  updateClassroomDtoInput: UpdateClassroomDtoInput;
};


export type MutationPublishPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationRecreateClassroomClassCodeArgs = {
  classroomId: Scalars['String'];
};


export type MutationRegisterForClassroomArgs = {
  registerDtoInput: RegisterDtoInput;
};


export type MutationRegisterSchoolArgs = {
  input?: InputMaybe<RegisterSchoolDtoInput>;
};


export type MutationRegisterToClasstalkArgs = {
  registerToClasstalkDtoInput: RegisterToClasstalkDtoInput;
};


export type MutationRegradeAssignmentSubmissionArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  regradeAssignmentSubmissionDtoInput: RegradeAssignmentSubmissionDtoInput;
};


export type MutationRemindPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationRemoveDeviceArgs = {
  playerId: Scalars['String'];
};


export type MutationRemoveReactionFromCommentArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
  removeReactionFromCommentDtoInput: RemoveReactionFromCommentDtoInput;
};


export type MutationRemoveReactionFromPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  removeReactionFromPostDtoInput: RemoveReactionFromPostDtoInput;
};


export type MutationRemoveReactionFromReplyArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
  removeReactionFromReplyDtoInput: RemoveReactionFromReplyDtoInput;
  replyId: Scalars['String'];
};


export type MutationReportPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  reportPostDtoInput: ReportPostDtoInput;
};


export type MutationRequestEmailChangeArgs = {
  id: Scalars['String'];
  requestEmailChangeDtoInput: RequestEmailChangeDtoInput;
};


export type MutationRequestMobileChangeArgs = {
  id: Scalars['String'];
  requestMobileChangeDtoInput: RequestMobileChangeDtoInput;
};


export type MutationSaveAssignmentSubmissionPointsArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  saveAssignmentSubmissionPointsDtoInput: SaveAssignmentSubmissionPointsDtoInput;
};


export type MutationSaveEssayAnswerPointsToLearningAssignmentArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  saveEssayAnswerPointsToLearningAssignmentDtoInput: SaveEssayAnswerPointsToLearningAssignmentDtoInput;
};


export type MutationSaveMemberAnswerToLearningAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  saveMemberAnswerToLearningAssignmentDtoInput: SaveMemberAnswerToLearningAssignmentDtoInput;
};


export type MutationSavePostAsDraftArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  savePostAsDraftDtoInput: SavePostAsDraftDtoInput;
};


export type MutationSchedulePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationStartClasstalkMeetingArgs = {
  startMeetingDtoInput: StartMeetingDtoInput;
};


export type MutationStartMeetingArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationSubmitLearningSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationSubmitSubmissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  submitSubmissionDtoInput: SubmitSubmissionDtoInput;
};


export type MutationSuspendGoalArgs = {
  childId: Scalars['String'];
  goalId: Scalars['String'];
};


export type MutationTranslatePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  translatePostDtoInput: TranslatePostDtoInput;
};


export type MutationUnarchiveChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationUnarchiveChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type MutationUnarchiveClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationUnblockMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationUnbookmarkClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationUnbookmarkLegacyClassArgs = {
  classId?: InputMaybe<Scalars['String']>;
};


export type MutationUnbookmarkLegacyClassroomArgs = {
  classroomId: Scalars['String'];
};


export type MutationUnlinkChildArgs = {
  id: Scalars['String'];
};


export type MutationUnpinPostArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  unpinPostDtoInput: UnpinPostDtoInput;
};


export type MutationUnschedulePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  id: Scalars['String'];
  updateAccountDtoInput: UpdateAccountDtoInput;
};


export type MutationUpdateAssignedAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  updateAssignedAssignmentDtoInput: UpdateAssignedAssignmentDtoInput;
};


export type MutationUpdateAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  updateAssignmentDtoInput: UpdateAssignmentDtoInput;
};


export type MutationUpdateAssignmentSubmissionCommentArgs = {
  assignmentSubmissionId: Scalars['String'];
  commentId: Scalars['String'];
  updateAssignmentSubmissionCommentDtoInput: UpdateAssignmentSubmissionCommentDtoInput;
};


export type MutationUpdateChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  updateChannelDtoInput: UpdateChannelDtoInput;
};


export type MutationUpdateChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  updateChannelDtoInput: UpdateChannelDtoInput;
};


export type MutationUpdateChannelGroupArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
  updateChannelGroupDtoInput: UpdateChannelGroupDtoInput;
};


export type MutationUpdateChannelGroupDtoArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
  updateChannelGroupDtoInput: UpdateChannelGroupDtoInput;
};


export type MutationUpdateChildSchoolInfoArgs = {
  id: Scalars['String'];
  updateChildSchoolInfoDtoInput: UpdateChildSchoolInfoDtoInput;
};


export type MutationUpdateClassroomArgs = {
  classroomId: Scalars['String'];
  updateClassroomDtoInput: UpdateClassroomDtoInput;
};


export type MutationUpdateClassroomMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
  updateMemberDtoInput: UpdateMemberDtoInput;
};


export type MutationUpdateClasstalkUserArgs = {
  updateClasstalkUserDtoInput: UpdateClasstalkUserDtoInput;
  userId: Scalars['String'];
};


export type MutationUpdateClasstalkUserAutoRecordingSettingArgs = {
  updateAutoRecordingSettingDtoInput: UpdateAutoRecordingSettingDtoInput;
};


export type MutationUpdateCommentArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
  updateCommentDtoInput: UpdateCommentDtoInput;
};


export type MutationUpdateMemberPushNotificationSettingsArgs = {
  classroomId: Scalars['String'];
  updateMemberPushNotificationSettingsDtoInput: UpdateMemberPushNotificationSettingsDtoInput;
};


export type MutationUpdatePostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  updatePostDtoInput: UpdatePostDtoInput;
};


export type MutationUpdatePostAdminExclusiveByAdminArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  updatePostAdminExclusiveByAdminDtoInput: UpdatePostAdminExclusiveByAdminDtoInput;
};


export type MutationUpdatePostChannelAdminArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
  updatePostChannelAdminDtoInput: UpdatePostChannelAdminDtoInput;
};


export type MutationUpdateReplyArgs = {
  commentId: Scalars['String'];
  replyId: Scalars['String'];
  updateReplyDtoInput: UpdateReplyDtoInput;
};


export type MutationUpdateTagArgs = {
  classroomId: Scalars['String'];
  tag: Scalars['String'];
  updateTagDtoInput: UpdateTagDtoInput;
};


export type MutationVerifyChildCodeArgs = {
  code: Scalars['String'];
  id: Scalars['String'];
};


export type MutationVerifyOrderControllerVerifyOrderArgs = {
  orderId: Scalars['String'];
  verifyOrderDtoInput: InsightsVerifyOrderDtoInput;
};

export type MyClassroomsCollectionDto = {
  __typename?: 'MyClassroomsCollectionDto';
  classrooms: Array<Maybe<ClassroomDto>>;
};

export type NewsletterSubscriberDto = {
  __typename?: 'NewsletterSubscriberDto';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type NoticeFeedDto = {
  __typename?: 'NoticeFeedDto';
  /** 활동을 수행하는 주체나 참여자 */
  actor: Scalars['Json'];
  /** 활동 정보 */
  content?: Maybe<Scalars['String']>;
  /** 활동을 발행한 주체 */
  generator?: Maybe<Scalars['Json']>;
  /** 고유 아이디 */
  id: Scalars['String'];
  /** 활동을 담는 객체 */
  object?: Maybe<Scalars['Json']>;
  /** 공지 발생일 */
  publishedAt: Scalars['String'];
  /** 공지 읽음 여부 */
  read: Scalars['Boolean'];
  /** 수신자 아이디 */
  recipientId: Scalars['String'];
  /** 활동 요약 정보 */
  summary?: Maybe<Scalars['String']>;
  /** 활동의 대상 ex) John added a movie to his wishlist에서 target은 John wishlist */
  target: Scalars['Json'];
  /** 활동 타입 */
  type: Scalars['String'];
};

export type NoticeFeedsCollectionDto = {
  __typename?: 'NoticeFeedsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  noticeFeeds: Array<Maybe<NoticeFeedDto>>;
};

export type NotificationDto = {
  __typename?: 'NotificationDto';
  /** 활동을 수행하는 주체나 참여자 */
  actor: Scalars['Json'];
  /** 활동 정보 */
  content?: Maybe<Scalars['String']>;
  /** 활동을 발행한 주체 */
  generator?: Maybe<Scalars['Json']>;
  /** 고유 아이디 */
  id: Scalars['String'];
  /** 활동을 담는 객체 */
  object?: Maybe<Scalars['Json']>;
  /** 알림 발생일 */
  publishedAt: Scalars['String'];
  /** 알림 읽음 여부 */
  read: Scalars['Boolean'];
  /** 수신자 아이디 */
  recipientId: Scalars['String'];
  /** 활동 요약 정보 */
  summary?: Maybe<Scalars['String']>;
  /** 활동의 대상 ex) John added a movie to his wishlist에서 target은 John wishlist */
  target: Scalars['Json'];
  /** 활동 타입 */
  type: Scalars['String'];
};

export type NotificationsCollectionDto = {
  __typename?: 'NotificationsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  notifications: Array<Maybe<NotificationDto>>;
};

export enum OAuthProvider {
  Apple = 'APPLE',
  Azure = 'AZURE',
  Google = 'GOOGLE',
  Kakao = 'KAKAO',
  Naver = 'NAVER',
  Whalespace = 'WHALESPACE'
}

export type OnboardingChecklistDto = {
  __typename?: 'OnboardingChecklistDto';
  /**
   *
   *     해당 클래스룸에서 보여질 수 있는 순서가 정의된 스탭들,
   *     허용되는 값들:
   *       | 'invite-member'
   *       | 'publish-post'
   *       | 'distribute-ai-test'
   *       | 'visit-product-tour-page'
   *
   */
  availableActions: Array<Maybe<Scalars['String']>>;
  classroomId: Scalars['String'];
  id: Scalars['String'];
  /** 체크리스트를 노출할지 여부 */
  isActive: Scalars['Boolean'];
  /** 체크리스트의 모든 스텝이 완료되었는지 여부 */
  isComplete: Scalars['Boolean'];
  /**
   *
   *       각 체크리스트 액션의 완료 여부 상태를 저장하고 있는 map
   *       availableActions에 포함된 값들을 키로 사용함
   *
   */
  progress: Scalars['Json'];
};

export enum OrganizationAccountRole {
  Parent = 'PARENT',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type OrganizationChangeMemberAccountPasswordDtoInput = {
  password: Scalars['String'];
};

export type OrganizationChangeMemberOrgRoleDtoInput = {
  orgRole?: InputMaybe<OrganizationOrganizationMemberRole>;
};

export enum OrganizationClassroomGrade {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export type OrganizationClassroomMemberDto = {
  __typename?: 'OrganizationClassroomMemberDto';
  classroomId: Scalars['String'];
  group?: Maybe<OrganizationClassroomMemberGroup>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  role: OrganizationClassroomMemberRole;
};

export enum OrganizationClassroomMemberGroup {
  Guardian = 'GUARDIAN',
  Parent = 'PARENT',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export enum OrganizationClassroomMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type OrganizationClassroomMembersCollectionDto = {
  __typename?: 'OrganizationClassroomMembersCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  members: Array<Maybe<OrganizationClassroomMemberDto>>;
};

export type OrganizationClassroomWithAdminDto = {
  __typename?: 'OrganizationClassroomWithAdminDto';
  about?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  grade: OrganizationClassroomGrade;
  id: Scalars['String'];
  isLegacy?: Maybe<Scalars['Boolean']>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  profileImageUrl: Scalars['String'];
  schoolYear?: Maybe<Scalars['Float']>;
};

export type OrganizationClassroomsWithAdminCollectionDto = {
  __typename?: 'OrganizationClassroomsWithAdminCollectionDto';
  classrooms: Array<Maybe<OrganizationClassroomWithAdminDto>>;
  page?: Maybe<Scalars['Float']>;
  totalPages?: Maybe<Scalars['Float']>;
};

export type OrganizationCompleteOrderDtoInput = {
  paymentId: Scalars['String'];
};

export enum OrganizationContractCustomType {
  None = 'NONE',
  Pen = 'PEN'
}

export type OrganizationContractDto = {
  __typename?: 'OrganizationContractDto';
  createdAt: Scalars['String'];
  customType: OrganizationContractCustomType;
  /** 이용권 종료일시 */
  endsAt: Scalars['String'];
  id: Scalars['String'];
  licenses: Array<Maybe<OrganizationLicenseDto>>;
  orgId: Scalars['String'];
  /** 이용권 이용 가능 개월 수 */
  periodInMonth: Scalars['Int'];
  /** 구매한 학생 이용권 총 수량 */
  quantity: Scalars['Int'];
  /** 이용권 시작일시 */
  startsAt: Scalars['String'];
  updatedAt: Scalars['String'];
  variantCode: OrganizationVariantCode;
};

export enum OrganizationContractStatus {
  Active = 'ACTIVE'
}

export type OrganizationContractsCollectionDto = {
  __typename?: 'OrganizationContractsCollectionDto';
  contracts: Array<Maybe<OrganizationContractDto>>;
  cursor?: Maybe<Scalars['String']>;
};

export type OrganizationCreateMemberDtoInput = {
  accountRole: OrganizationMemberAccountRole;
  email?: InputMaybe<Scalars['String']>;
  /** 학번, 사번 등 구성원을 기관에서 식별하는 번호 또는 문자열 */
  identityNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orgUnitPath: Scalars['String'];
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type OrganizationCreateOrderDtoInput = {
  orgId: Scalars['String'];
  price: Scalars['Float'];
};

export type OrganizationCreateOrgClassroomDtoInput = {
  about?: InputMaybe<Scalars['String']>;
  adminId: Scalars['String'];
  grade: OrganizationClassroomGrade;
  name: Scalars['String'];
  schoolYear: Scalars['Float'];
};

export type OrganizationCreateOrgUnitDtoInput = {
  name: Scalars['String'];
  parentOrgUnitPath: Scalars['String'];
};

export type OrganizationCreatePlanDtoInput = {
  annualBillingPrice: Scalars['Float'];
  description: Scalars['String'];
  featureId: Scalars['String'];
  monthlyBillingPrice: Scalars['Float'];
  name: Scalars['String'];
};

export enum OrganizationGrade {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export type OrganizationInviteMembersDtoInput = {
  phoneNumbers: Array<InputMaybe<Scalars['String']>>;
};

export enum OrganizationLicenseCode {
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type OrganizationLicenseDto = {
  __typename?: 'OrganizationLicenseDto';
  code: OrganizationLicenseCode;
  createdAt: Scalars['String'];
  endsAt: Scalars['String'];
  /** 구성원에게 할당된 수량 */
  grantedCount: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  startsAt: Scalars['String'];
  /** 각 이용권의 구매 수량 */
  totalCount: Scalars['Int'];
  updatedAt: Scalars['String'];
  /** 구체적인 이용권 제한의 조건 */
  variantOption?: Maybe<Scalars['String']>;
};

export enum OrganizationMemberAccountRole {
  Parent = 'PARENT',
  Student = 'STUDENT',
  Teacher = 'TEACHER'
}

export type OrganizationMemberDto = {
  __typename?: 'OrganizationMemberDto';
  accountId: Scalars['String'];
  accountRole: OrganizationMemberAccountRole;
  avatarImageUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  identityNumber?: Maybe<Scalars['String']>;
  lastAccessAt?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orgUnitPath?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationRole: OrganizationOrganizationMemberRole;
  updatedAt: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type OrganizationMemberLicenseDto = {
  __typename?: 'OrganizationMemberLicenseDto';
  accountId: Scalars['String'];
  code: OrganizationLicenseCode;
  contractId: Scalars['String'];
  createdAt: Scalars['String'];
  endsAt: Scalars['String'];
  id: Scalars['String'];
  licenseId: Scalars['String'];
  memberId: Scalars['String'];
  orgId: Scalars['String'];
  startsAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum OrganizationMemberOrderField {
  Name = 'NAME'
}

export type OrganizationMemberPermissionDto = {
  __typename?: 'OrganizationMemberPermissionDto';
  /** 대상이 되는 구성원의 권한 변경 권한 */
  canChangeRole: Scalars['Boolean'];
  /** 대상이 되는 구성원을 내보낼 수 있는 권한 */
  canRemove: Scalars['Boolean'];
  /** 대상이 되는 구성원의 정보 및 비밀번호 변경 권한 */
  canUpdate: Scalars['Boolean'];
};

export type OrganizationMemberSearchCollectionDto = {
  __typename?: 'OrganizationMemberSearchCollectionDto';
  members: Array<Maybe<OrganizationMemberDto>>;
  page?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
  totalPages?: Maybe<Scalars['Float']>;
  totalResults?: Maybe<Scalars['Float']>;
};

export type OrganizationMessageDto = {
  __typename?: 'OrganizationMessageDto';
  content: Scalars['String'];
  createdAt: Scalars['String'];
  failCount: Scalars['Float'];
  id: Scalars['String'];
  orgId: Scalars['String'];
  pendingCount: Scalars['Float'];
  sender?: Maybe<OrganizationMemberDto>;
  senderId: Scalars['String'];
  senderNumber: Scalars['String'];
  successCount: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  totalCount: Scalars['Float'];
  type: OrganizationMessageType;
  updatedAt: Scalars['String'];
};

export type OrganizationMessageRecipientDto = {
  __typename?: 'OrganizationMessageRecipientDto';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  messageId: Scalars['String'];
  mobile: Scalars['String'];
  name: Scalars['String'];
  orgId: Scalars['String'];
  status: OrganizationMessageSentStatusType;
  statusDetail?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type OrganizationMessageRecipientsCollectionDto = {
  __typename?: 'OrganizationMessageRecipientsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  messageRecipients: Array<Maybe<OrganizationMessageRecipientDto>>;
};

export enum OrganizationMessageSentStatusType {
  Fail = 'FAIL',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type OrganizationMessageServiceDto = {
  __typename?: 'OrganizationMessageServiceDto';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  orgId: Scalars['String'];
  points: Scalars['Float'];
  remainingLmsSendLimit: Scalars['Float'];
  remainingSmsSendLimit: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export enum OrganizationMessageType {
  Lms = 'LMS',
  Sms = 'SMS'
}

export type OrganizationMessagesCollectionDto = {
  __typename?: 'OrganizationMessagesCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  messages: Array<Maybe<OrganizationMessageDto>>;
};

export enum OrganizationOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrganizationOrderDto = {
  __typename?: 'OrganizationOrderDto';
  amount: Scalars['Float'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  orgId: Scalars['String'];
  price: Scalars['Float'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum OrganizationOrgCategory {
  NonSchool = 'NON_SCHOOL',
  School = 'SCHOOL'
}

export type OrganizationOrgCodeDto = {
  __typename?: 'OrganizationOrgCodeDto';
  code: Scalars['String'];
  enterprise?: Maybe<OrganizationOrgEnterpriseDto>;
  expiresAt: Scalars['String'];
  orgId: Scalars['String'];
};

export type OrganizationOrgEnterpriseDto = {
  __typename?: 'OrganizationOrgEnterpriseDto';
  slug: Scalars['String'];
};

export type OrganizationOrgPermissionDto = {
  __typename?: 'OrganizationOrgPermissionDto';
  /** 구성원이 기관에서 스스로 나갈 수 있는 권한 */
  canLeave: Scalars['Boolean'];
};

export enum OrganizationOrgRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  SuperAdmin = 'SUPER_ADMIN'
}

export enum OrganizationOrgRoles3 {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  SuperAdmin = 'SUPER_ADMIN'
}

export type OrganizationOrganizationDto = {
  __typename?: 'OrganizationOrganizationDto';
  /** 기관 주소 */
  address?: Maybe<Scalars['String']>;
  /** 입학 연령으로 표기되는 기관의 학교급, 공교육 기관일 경우 값을 가짐 */
  admissionAge?: Maybe<Scalars['Float']>;
  category: OrganizationOrgCategory;
  /** 기관이 위치한 도시명 */
  city?: Maybe<Scalars['String']>;
  /** 기관이 속한 국가 코드 */
  countryCode: Scalars['String'];
  /**
   * 기관이 소속된 엔터프라이즈의 하위 카테고리 중 해당 기관이 속한 카테고리 이름,
   *
   * 엔터프라이즈에 소속된 기관이며 속한 카테고리가 있을 경우 값을 가짐
   */
  enterpriseCategory?: Maybe<Scalars['String']>;
  /** 기관이 소속된 엔터프라이즈 식별자, 엔터프라이즈에 소속된 기관인 경우 값을 가짐 */
  enterpriseId?: Maybe<Scalars['String']>;
  /** 기관의 첫 학년, 공교육 기관일 경우 값을 가짐 */
  firstGrade?: Maybe<Scalars['Float']>;
  /** 기관 식별자 */
  id: Scalars['String'];
  /** 기관 차단 여부 */
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** 기관 승인 여부 */
  isCertified?: Maybe<Scalars['Boolean']>;
  /** 현재 기관이 이용 중인 계약 존재 여부 */
  licensed?: Maybe<Scalars['Boolean']>;
  /** 기관의 학기 시작 월, 공교육 기관일 경우 값을 가짐 */
  monthAcademicYearStartsFrom?: Maybe<Scalars['Float']>;
  /** 기관 이름 */
  name: Scalars['String'];
  /** 기관이 소속된 교육청의 기관 식별자, 공교육 기관일 경우 값을 가짐 */
  officeOfEducation?: Maybe<Scalars['String']>;
  /** 개인정보 위탁 정책 동의 일시 */
  personalInfoConsignmentAt?: Maybe<Scalars['String']>;
  /** 기관의 대표 전화번호 */
  phone?: Maybe<Scalars['String']>;
  /** 기관의 프로필 이미지 URL */
  profileImage: Scalars['String'];
  /** 기관의 전체 학년 수, 공교육 기관일 경우 값을 가짐 */
  regularCourseYear?: Maybe<Scalars['Float']>;
  /** 대표 발신자 번호, 메시지 서비스를 이용하는 기관일 경우 등록 가능 */
  senderNumber?: Maybe<Scalars['String']>;
  /** 기관 검색 시 해당 기관에 적용 가능한 검색 태그 목록 */
  tags: Array<Maybe<Scalars['String']>>;
  /** 기관 웹사이트 URL */
  website?: Maybe<Scalars['String']>;
  /** 기관이 위치한 주소의 우편번호 */
  zipcode: Scalars['String'];
};

export enum OrganizationOrganizationMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  SuperAdmin = 'SUPER_ADMIN'
}

export type OrganizationOrganizationalUnitDto = {
  __typename?: 'OrganizationOrganizationalUnitDto';
  id: Scalars['String'];
  name: Scalars['String'];
  organizationalUnitPath: Scalars['String'];
  parentOrganizationalUnitId?: Maybe<Scalars['String']>;
  parentOrganizationalUnitPath?: Maybe<Scalars['String']>;
};

export type OrganizationOrganizationsSearchCollectionDto = {
  __typename?: 'OrganizationOrganizationsSearchCollectionDto';
  /** 현재 검색 결과 내의 모든 엔터프라이즈 하위 카테고리의 목록, 이름 기준으로 오름차순 정렬 */
  facets: Scalars['OrganizationJson'];
  /** 기관 이름 기준으로 오름차순 정렬, 전문 검색시 관련도 기준 정렬 */
  organizations: Array<Maybe<OrganizationOrganizationDto>>;
  /** 현재 페이지 */
  page?: Maybe<Scalars['Float']>;
  /** 페이지당 기관의 최대 개수 */
  perPage?: Maybe<Scalars['Float']>;
  /** 검색된 기관의 총 페이지 수 */
  totalPages?: Maybe<Scalars['Float']>;
  /** 검색된 기관의 총 개수 */
  totalResults?: Maybe<Scalars['Float']>;
};

export type OrganizationPlanDto = {
  __typename?: 'OrganizationPlanDto';
  annualBillingPrice: Scalars['Float'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  featureId: Scalars['String'];
  id: Scalars['String'];
  monthlyBillingPrice: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type OrganizationPrimaryOrgMembershipDto = {
  __typename?: 'OrganizationPrimaryOrgMembershipDto';
  accountId: Scalars['String'];
  createdAt: Scalars['String'];
  orgId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type OrganizationPrivateMarkOrgAsPrimaryDtoInput = {
  accountId: Scalars['String'];
};

export type OrganizationRegisterClassroomMembersDtoInput = {
  memberIds: Array<InputMaybe<Scalars['String']>>;
};

export type OrganizationRegisterForOrgDtoInput = {
  orgCode: Scalars['String'];
};

export type OrganizationSendMessageDtoInput = {
  content: Scalars['String'];
  recipients: Array<InputMaybe<OrganizationSendMessageRecipientDtoInput>>;
  senderNumber: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type: OrganizationMessageType;
};

export type OrganizationSendMessageRecipientDtoInput = {
  mobile: Scalars['String'];
  name: Scalars['String'];
};

export enum OrganizationStatus2 {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type OrganizationSubscribedFeatureDto = {
  __typename?: 'OrganizationSubscribedFeatureDto';
  code: Scalars['String'];
  tier: Scalars['Float'];
};

export type OrganizationUpdateMemberDtoInput = {
  accountRole?: InputMaybe<OrganizationMemberAccountRole>;
  /** 학번, 사번 등 구성원을 기관에서 식별하는 번호 또는 문자열 */
  identityNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgUnitPath: Scalars['String'];
};

export type OrganizationUpdateMessageServiceDtoInput = {
  pointsToAdd?: InputMaybe<Scalars['Float']>;
};

export type OrganizationUpdateOrgClassroomDtoInput = {
  about?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<OrganizationClassroomGrade>;
  name?: InputMaybe<Scalars['String']>;
  schoolYear?: InputMaybe<Scalars['Int']>;
};

export type OrganizationUpdateOrgPolicyConsentDtoInput = {
  isPersonalInfoConsignmentConsent?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationUpdateOrgUnitDtoInput = {
  name?: InputMaybe<Scalars['String']>;
  parentOrgUnitPath?: InputMaybe<Scalars['String']>;
};

export enum OrganizationVariantCode {
  Network = 'NETWORK',
  Quota = 'QUOTA',
  Subject = 'SUBJECT',
  Unlimited = 'UNLIMITED'
}

export type Passcode = {
  __typename?: 'Passcode';
  code?: Maybe<Scalars['String']>;
  owner?: Maybe<PasscodeOwner>;
};

export type PasscodeOwner = {
  __typename?: 'PasscodeOwner';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type PersonalCertificate = {
  __typename?: 'PersonalCertificate';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PinCodeDto = {
  __typename?: 'PinCodeDto';
  expireAt: Scalars['String'];
  id: Scalars['String'];
  isGlobalSms: Scalars['Boolean'];
};

export type PinPostDtoInput = {
  postId: Scalars['String'];
};

export type PostDto = {
  __typename?: 'PostDto';
  /** 관리자만 읽기 가능 여부 */
  adminExclusive: Scalars['Boolean'];
  /** 게시글이 공지된 시각 */
  announcedAt?: Maybe<Scalars['String']>;
  /** 과제 타입 게시글일때, 연결된 과제의 식별자 */
  assignmentId?: Maybe<Scalars['String']>;
  author: MemberDto;
  /** 게시글 작성자의 식별자 */
  authorId: Scalars['String'];
  /** 게시글이 작성된 공간의 식별자 */
  channelId?: Maybe<Scalars['String']>;
  /** 게시글이 작성된 클래스룸의 식별자 */
  classroomId: Scalars['String'];
  /** 게시글의 댓글 수 */
  commentsCount: Scalars['Float'];
  /** 게시글 본문 */
  content?: Maybe<Scalars['String']>;
  /** 첫 번째로 첨부된 미디어의 이미지 URL */
  coverImageUrl?: Maybe<Scalars['String']>;
  /** 게시글이 생성된 시각 */
  createdAt: Scalars['String'];
  /** 게시글에 첨부된 파일 식별자 목록 */
  fileIds: Array<Maybe<Scalars['String']>>;
  /** 게시글 식별자 */
  id: Scalars['String'];
  /** 게시글에 첨부된 이미지 식별자 목록 */
  imageIds: Array<Maybe<Scalars['String']>>;
  images?: Maybe<Array<Maybe<ImageDto>>>;
  /** 공지사항 여부 */
  isAnnouncement: Scalars['Boolean'];
  /** 게시글 고정 여부 */
  isPinned: Scalars['Boolean'];
  /** 게시글의 마지막 재알림 시각 */
  lastNotifiedAt?: Maybe<Scalars['String']>;
  /** 게시글이 발행된 시각 */
  publishedAt?: Maybe<Scalars['String']>;
  /** 게시글의 전체 반응 목록 */
  reactions: Array<Maybe<PostReactionDto>>;
  /** 게시글의 전체 반응 수 */
  reactionsCount: Scalars['Json'];
  /** 게시글을 읽은 구성원 수 */
  readsCount: Scalars['Float'];
  /** 게시글의 답글 수 */
  repliesCount: Scalars['Float'];
  /** 게시글을 신고한 구성원 식별자 목록 */
  reporterIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** 게시글의 발행이 예정된 시각 */
  scheduledAt?: Maybe<Scalars['String']>;
  /** 게시글의 상태 */
  status: Status3;
  /** 게시글 본문 요약 */
  summary?: Maybe<Scalars['String']>;
  /** 게시글 태그 목록 */
  tags: Array<Maybe<Scalars['String']>>;
  /** 게시글 제목 */
  title?: Maybe<Scalars['String']>;
  type: PostType;
  /** 게시글이 최근 수정된 시각 */
  updatedAt: Scalars['String'];
  /** 게시글에 첨부된 동영상 식별자 목록 */
  videoIds: Array<Maybe<Scalars['String']>>;
  videos?: Maybe<Array<Maybe<VideoDto>>>;
};

export type PostPermissionDto = {
  __typename?: 'PostPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canMarkAsAnnouncement: Scalars['Boolean'];
  canMarkAsRead: Scalars['Boolean'];
  canPin: Scalars['Boolean'];
  canReact: Scalars['Boolean'];
  canReport: Scalars['Boolean'];
  canWriteComment: Scalars['Boolean'];
  postId?: Maybe<Scalars['String']>;
};

export type PostReactionDto = {
  __typename?: 'PostReactionDto';
  /** 반응한 시각 */
  createdAt: Scalars['String'];
  member?: Maybe<MemberDto>;
  /** 반응한 구성원 식별자 */
  memberId: Scalars['String'];
  /** 반응의 종류 */
  name: Scalars['String'];
};

export type PostReactionsCollectionDto = {
  __typename?: 'PostReactionsCollectionDto';
  reactions: Array<Maybe<PostReactionDto>>;
};

export type PostReadStatusDto = {
  __typename?: 'PostReadStatusDto';
  postId: Scalars['String'];
  readStatus: ReadStatus;
};

export enum PostType {
  Announcement = 'ANNOUNCEMENT',
  Assignment = 'ASSIGNMENT',
  Post = 'POST'
}

export type PostsCollectionDto = {
  __typename?: 'PostsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  posts: Array<Maybe<PostDto>>;
};

export type PostsSearchCollectionDto = {
  __typename?: 'PostsSearchCollectionDto';
  page?: Maybe<Scalars['Float']>;
  perPage?: Maybe<Scalars['Float']>;
  posts: Array<Maybe<PostDto>>;
  totalPages?: Maybe<Scalars['Float']>;
  totalResults?: Maybe<Scalars['Float']>;
};

export type PrivateCreateClassroomDtoInput = {
  about?: InputMaybe<Scalars['String']>;
  adminId: Scalars['String'];
  grade?: InputMaybe<Grade>;
  name: Scalars['String'];
  schoolId?: InputMaybe<Scalars['String']>;
  schoolYear?: InputMaybe<Scalars['Int']>;
};

export type PrivateCreateEnterpriseMemberDtoInput = {
  /** 클래스팅 계정의 식별자 */
  accountId: Scalars['String'];
};

export type PrivateRegisterDtoInput = {
  group?: InputMaybe<MemberGroup>;
  name: Scalars['String'];
  profileImageId?: InputMaybe<Scalars['String']>;
  profileImageUrl?: InputMaybe<Scalars['String']>;
  role: MemberRole;
  userId: Scalars['String'];
};

export type PublisherDto = {
  __typename?: 'PublisherDto';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['String'];
  locale: Scalars['String'];
  name: Scalars['String'];
  profileImage: Scalars['String'];
  subscriptionCount: Scalars['Float'];
};

export type PublisherSubscriptionDto = {
  __typename?: 'PublisherSubscriptionDto';
  publisher: PublisherDto;
  subscribedAt: Scalars['Date'];
  subscriber?: Maybe<NewsletterSubscriberDto>;
  user: UserDto;
};

export type PublisherSubscriptionsCollectionDto = {
  __typename?: 'PublisherSubscriptionsCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  publisherSubscriptions: Array<PublisherSubscriptionDto>;
};

export type PublishersCollectionDto = {
  __typename?: 'PublishersCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  publishers: Array<PublisherDto>;
};

export type PushNotificationSettings = {
  __typename?: 'PushNotificationSettings';
  announcementsEnabled?: Maybe<Scalars['Boolean']>;
  commentsAndRepliesEnabled?: Maybe<Scalars['Boolean']>;
  postCommentsAndRepliesEnabled?: Maybe<Scalars['Boolean']>;
  postReactionsEnabled?: Maybe<Scalars['Boolean']>;
  postsEnabled?: Maybe<Scalars['Boolean']>;
  reactionsEnabled?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   *
   *
   * Equivalent to GET /media-service/admin/images/{imageId}
   */
  adminApiGetImage?: Maybe<Image>;
  /**
   *
   *
   * Equivalent to GET /classtalk-service/test
   */
  appControllerGetHello?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignment-submissions/{assignmentSubmissionId}
   */
  assignmentSubmission?: Maybe<AssignmentSubmissionDto>;
  /** GET /users/me */
  currentChatUser?: Maybe<ChatUser>;
  /**
   * 엔터프라이즈 조회
   *
   * Equivalent to GET /enterprise-service/enterprises/{enterpriseId}
   */
  enterprise?: Maybe<EnterpriseDto>;
  /**
   * 현재 사용자의 엔터프라이즈 구성원 정보 조회
   *
   * Equivalent to GET /enterprise-service/enterprises/{enterpriseId}/members/me
   */
  enterpriseMe?: Maybe<EnterpriseMemberDto>;
  /**
   * 엔터프라이즈 구성원 정보 조회
   *
   * Equivalent to GET /enterprise-service/enterprises/{enterpriseId}/members/{memberId}
   */
  enterpriseMember?: Maybe<EnterpriseMemberDto>;
  /**
   * 현재 사용자의 엔터프라이즈 권한 조회
   *
   * Equivalent to GET /enterprise-service/enterprises/{enterpriseId}/permission
   */
  enterprisePermission?: Maybe<EnterprisePermissionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/attendances/export
   */
  exportClassroomAttendances?: Maybe<Scalars['Json']>;
  /**
   *
   *
   * Equivalent to GET /account-service/accounts/{id}
   */
  getAccount?: Maybe<SimpleAccountDto>;
  /** GET /ai_assignment_policy?target_id={args.classroomId} */
  getAiTestPolicy?: Maybe<AiTestPolicy>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}
   */
  getAssignment?: Maybe<AssignmentDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/permission
   */
  getAssignmentPermission?: Maybe<AssignmentPermissionDto>;
  /**
   * 학생의 과제 제출물 목록 조회
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignees/{assigneeId}/assignment-submission
   */
  getAssignmentSubmission?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/assignment-submissions/{assignmentSubmissionId}/comments/{commentId}/permission
   */
  getAssignmentSubmissionCommentPermission?: Maybe<AssignmentSubmissionCommentPermissionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/attendances/summary
   */
  getAttendanceSummary?: Maybe<AttendanceSummaryDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channels/{channelId}
   */
  getChannel?: Maybe<Channel>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channels-dto/{channelId}
   */
  getChannelDto?: Maybe<ChannelDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channel-groups/{channelGroupId}
   */
  getChannelGroup?: Maybe<ChannelGroupDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channel-groups-dto/{channelGroupId}
   */
  getChannelGroupDto?: Maybe<ChannelGroupDto>;
  /**
   * 클래스코드 조회
   *
   * Equivalent to GET /classroom-service/class-codes/{code}
   */
  getClassCode?: Maybe<ClassCodeDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}
   */
  getClassroom?: Maybe<ClassroomDto>;
  /**
   * 클래스 북마크 여부 확인
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/bookmark-status
   */
  getClassroomBookmarkStatus?: Maybe<ClassroomBookmarkStatusDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{id}/class-code
   */
  getClassroomClassCode?: Maybe<ClassCodeDto>;
  /**
   * :memberId에 `me` 할당 시 요청자의 구성원 정보 조회
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/members/{memberId}
   */
  getClassroomMember?: Maybe<MemberDto>;
  /**
   *
   *
   * Equivalent to GET /classtalk-service/users/{userId}
   */
  getClasstalkUser?: Maybe<ClasstalkUserDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/comments/{commentId}
   */
  getComment?: Maybe<CommentDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/posts/{postId}/comments/{commentId}/permission
   */
  getCommentPermission?: Maybe<CommentPermissionDto>;
  /**
   *
   *
   * Equivalent to GET /insights-service/estimated-orders
   */
  getEstimatedOrder?: Maybe<InsightsEstimatedOrderDto>;
  /** GET /users/me/favorited_classes */
  getFavoritedClasses?: Maybe<ClassCollection>;
  /**
   *
   *
   * Equivalent to GET /media-service/files/{fileId}
   */
  getFile?: Maybe<File>;
  /**
   *
   *
   * Equivalent to GET /insights-service/goals/{goalId}
   */
  getGoal?: Maybe<InsightsGoalDto>;
  /**
   *
   *
   * Equivalent to GET /media-service/images/{imageId}
   */
  getImage?: Maybe<Image>;
  /**
   *
   *
   * Equivalent to GET /insights-service/me
   */
  getInsightsAccount?: Maybe<InsightsAccountDto>;
  /** GET /users/me/joined_classes */
  getJoinedClasses?: Maybe<ClassCollection>;
  /**
   *
   *
   * Equivalent to GET /insights-service/latest-goals
   */
  getLatestGoal?: Maybe<InsightsGoalDto>;
  /**
   *
   *
   * Equivalent to GET /insights-service/latest-subscriptions
   */
  getLatestSubscription?: Maybe<InsightsSubscriptionDto>;
  /** GET /cards/{args.learningCardId} */
  getLearningCard?: Maybe<LearningCard>;
  /** GET /store-cards/{args.learningStoreCardId} */
  getLearningStoreCard?: Maybe<LearningCard>;
  /**
   * 내 계정 정보 확인
   *
   * Equivalent to GET /account-service/accounts/me
   */
  getMe?: Maybe<AccountDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/settings/push-notifications
   */
  getMemberPushNotificationSettings?: Maybe<MemberPushNotificationSettingsDto>;
  /**
   * 사용자가 받은 Generator별 새소식 조회
   *
   * Equivalent to GET /notification-service/generators/{generator_id}/news
   */
  getNewsGenerator?: Maybe<GeneratorDto>;
  /**
   *
   *
   * Equivalent to GET /notification-service/notice-feeds/{id}
   */
  getNoticeFeed?: Maybe<NoticeFeedDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/onboarding-checklist
   */
  getOnboardingChecklist?: Maybe<OnboardingChecklistDto>;
  /**
   *
   *
   * Equivalent to GET /insights-service/orders/{orderId}
   */
  getOrder?: Maybe<InsightsOrderDto>;
  /**
   * 게시글 조회
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}
   */
  getPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/permission
   */
  getPostPermission?: Maybe<PostPermissionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/posts/{postId}/read-status
   */
  getPostReadStatus?: Maybe<PostReadStatusDto>;
  getPublisher?: Maybe<PublisherDto>;
  getPublisherArticle?: Maybe<ArticleDto>;
  getPublisherSubscription?: Maybe<PublisherSubscriptionDto>;
  /**
   *
   *
   * Equivalent to GET /classtalk-service/meetings/{meetingId}/recordings/{externalServiceId}
   */
  getRecording?: Maybe<RecordingDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/comments/{commentId}/replies/{replyId}
   */
  getReply?: Maybe<ReplyDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/comments/{commentId}/replies/{replyId}/permission
   */
  getReplyPermission?: Maybe<ReplyPermissionDto>;
  /** GET /posts?fields=id,message,created_at&owner_type=school&owner_id={args.schoolId}&postwall=news&limit={args.limit} */
  getSchoolMealPosts?: Maybe<MealPostCollection>;
  /** GET /subscribing_schools */
  getSubscribingSchools?: Maybe<SchoolCollection>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/tags/{tag}
   */
  getTag?: Maybe<TagDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/translation/permission
   */
  getTranslationPermission?: Maybe<TranslationPermissionDto>;
  /**
   *
   *
   * Equivalent to GET /media-service/url-metadata
   */
  getUrlMetadata?: Maybe<UrlMetadata>;
  /**
   *
   *
   * Equivalent to GET /media-service/videos/{videoId}
   */
  getVideo?: Maybe<Video>;
  /** GET /search?type=passcode&q={args.q} */
  legacyPasscode?: Maybe<Passcode>;
  /**
   *
   *
   * Equivalent to GET /activity-stream-service/activity-streams
   */
  listActivityStreams?: Maybe<ActivityStreamsCollectionDto>;
  /** GET /subject_grades?grade_id={args.grade}&is_activated=true&is_assignable=true */
  listAiTestSubjects?: Maybe<Array<Maybe<AiTestSubject>>>;
  /** GET /units?grade_id={args.grade}&subject_id={args.subjectId} */
  listAiTestUnits?: Maybe<Array<Maybe<AiTestUnit>>>;
  /** GET /ai_assignments?target_id={args.classroomId}&page={args.page}&per_page={args.perPage} */
  listAiTests?: Maybe<AiTestCollection>;
  /**
   *
   *
   * Equivalent to GET /insights-service/classrooms/{classroomId}/all-cumulative-records
   */
  listAllCumulativeRecords?: Maybe<Array<Maybe<InsightsCumulativeRecordDto>>>;
  listArticles: ArticlesCollectionDto;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignees
   */
  listAssignmentAssignees?: Maybe<Array<Maybe<MemberDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/assignment-submissions/{assignmentSubmissionId}/comments
   */
  listAssignmentSubmissionComments?: Maybe<AssignmentSubmissionCommentsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/assignment-submissions
   */
  listAssignmentSubmissions?: Maybe<Array<Maybe<AssignmentSubmissionDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/bookmarks
   */
  listBookmarkedClassrooms?: Maybe<ClassroomFavoritesCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channel-groups
   */
  listChannelGroups?: Maybe<Array<Maybe<ChannelGroupDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channel-groups-dto
   */
  listChannelGroupsDto?: Maybe<Array<Maybe<ChannelGroupDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channels/{channelId}/media-posts
   */
  listChannelMediaPosts?: Maybe<PostsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channels/{channelId}/members
   */
  listChannelMembers?: Maybe<Array<Maybe<MemberDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channels
   */
  listChannels?: Maybe<Array<Maybe<Channel>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/channels-dto
   */
  listChannelsDto?: Maybe<Array<Maybe<ChannelDto>>>;
  /**
   *
   *
   * Equivalent to GET /insights-service/children/{childId}/activities
   */
  listChildActivities?: Maybe<InsightsActivitiesCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /insights-service/childrens
   */
  listChildren?: Maybe<Array<Maybe<InsightsChildDto>>>;
  /**
   * 정렬 순서: 가장 최근에 작성된 과제순
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments
   */
  listClassroomAssignments?: Maybe<AssignmentsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/blocked-members
   */
  listClassroomBlockedMembers?: Maybe<MembersCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/media-posts
   */
  listClassroomMediaPosts?: Maybe<PostsSearchCollectionDto>;
  /**
   * 구성원 이름 순으로 정렬. 비활성 구성원은 제외
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/members
   */
  listClassroomMembers?: Maybe<MembersCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/admin/classrooms/{classroomId}/members
   */
  listClassroomMembersControllerAdminListClassroomMembers?: Maybe<MembersCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/roles
   */
  listClassroomRoles?: Maybe<Array<Maybe<RoleDto>>>;
  /**
   * 정렬 순서: 최근에 개설된 클래스 순
   *
   * Equivalent to GET /classroom-service/classrooms
   */
  listClassrooms?: Maybe<ClassroomsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classtalk-service/meetings
   */
  listClasstalkMeetings?: Maybe<ClasstalkMeetingsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/comments/{commentId}/reactions
   */
  listCommentReactions?: Maybe<CommentReactionsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/common-channels
   */
  listCommonChannels?: Maybe<Array<Maybe<ChannelDto>>>;
  /**
   *
   *
   * Equivalent to GET /insights-service/classrooms/{classroomId}/cumulative-records
   */
  listCumulativeRecords?: Maybe<InsightsCumulativeRecordsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /account-service/guardian-relationships
   */
  listGuardianRelationships?: Maybe<Array<Maybe<GuardianRelationshipDto>>>;
  /**
   *
   *
   * Equivalent to GET /media-service/images
   */
  listImages?: Maybe<Array<Maybe<Image>>>;
  /** GET /cards/{args.learningCardId}/pages */
  listLearningCardPages?: Maybe<Array<Maybe<LearningCardPage>>>;
  /** GET /cards?author_id={args.authorId}&core_concept_id={args.coreConceptId}&grade_id={args.gradeId}&media_type={args.mediaType}&offset={args.offset}&limit={args.limit}&order_by={args.orderBy}&publisher_id={args.publisherId}&school_id={args.schoolId}&searchKeywords={args.searchKeywords}&share_level={args.shareLevel}&subject_id={args.subjectId}&unit_id={args.unitId} */
  listLearningCards?: Maybe<LearningCardCollection>;
  /** GET /cards?core_concept_id={args.coreConceptId}&grade_id={args.gradeId}&media_type={args.mediaType}&offset={args.offset}&limit={args.limit}&order_by={args.orderBy}&publisher_id={args.publisherId}&school_id={args.schoolId}&searchKeywords={args.searchKeywords}&share_level={args.shareLevel}&subject_id={args.subjectId}&unit_id={args.unitId} */
  listLearningCommunityCards?: Maybe<LearningCardCollection>;
  /** GET /grades */
  listLearningGrades?: Maybe<Array<Maybe<LearningGrade>>>;
  /** GET /cards?core_concept_id={args.coreConceptId}&grade_id={args.gradeId}&media_type={args.mediaType}&offset={args.offset}&limit={args.limit}&order_by={args.orderBy}&publisher_id={args.publisherId}&school_id={args.schoolId}&searchKeywords={args.searchKeywords}}&share_level={args.shareLevel}&subject_id={args.subjectId}&unit_id={args.unitId} */
  listLearningSchoolCards?: Maybe<LearningCardCollection>;
  /** GET /store-cards/{args.learningStoreCardId}/pages */
  listLearningStoreCardPages?: Maybe<Array<Maybe<LearningStoreCardPage>>>;
  /** GET /store-cards?core_concept_id={args.coreConceptId}&grade_id={args.gradeId}&media_type={args.mediaType}&offset={args.offset}&limit={args.limit}&order_by={args.orderBy}&publisher_id={args.publisherId}&school_id={args.schoolId}&search_keywords={args.searchKeywords}&share_level={args.shareLevel}&subject_id={args.subjectId}&unit_id={args.unitId} */
  listLearningStoreCards?: Maybe<LearningStoreCardCollection>;
  /** GET /subjects */
  listLearningSubjects?: Maybe<Array<Maybe<LearningSubject>>>;
  /** GET /units?subject_id={args.subjectId}&grade_id={args.gradeId} */
  listLearningUnits?: Maybe<Array<Maybe<LearningUnit>>>;
  /**
   *
   *
   * Equivalent to GET /notification-service/meal-feeds
   */
  listMealFeeds?: Maybe<MealFeedsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /insights-service/members/{memberId}/activities
   */
  listMemberActivities?: Maybe<InsightsActivitiesCollectionDto>;
  /**
   * 나에게 할당된 과제 목록 조회
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/my-assignments
   */
  listMemberAssignments?: Maybe<AssignmentsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/attendances
   */
  listMemberAttendances?: Maybe<MemberAttendancesCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/assignments/{assignmentId}/my-submissions
   */
  listMemberSubmissions?: Maybe<Array<Maybe<SubmissionDto>>>;
  /**
   * 정렬 순서: 즐겨찾기한 순서, 구 클래스는 항상 하단에 위치
   *
   * Equivalent to GET /classroom-service/my-classrooms/bookmarks
   */
  listMyBookmarkedClassrooms?: Maybe<MyClassroomsCollectionDto>;
  /**
   * 정렬 순서: 최근에 개설된 클래스 순
   *
   * Equivalent to GET /classroom-service/my-classrooms
   */
  listMyClassrooms?: Maybe<MyClassroomsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/newsfeed
   */
  listNewsfeedPosts?: Maybe<PostsCollectionDto>;
  /**
   * 사용자가 받은 공지의 피드
   *
   * Equivalent to GET /notification-service/notice-feeds
   */
  listNoticeFeeds?: Maybe<NoticeFeedsCollectionDto>;
  /**
   * 수신한 알림 리스트
   *
   * Equivalent to GET /notification-service/notifications
   */
  listNotifications?: Maybe<NotificationsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/comments
   */
  listPostComments?: Maybe<CommentsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/post-drafts
   */
  listPostDrafts?: Maybe<PostsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/post-pins
   */
  listPostPins?: Maybe<Array<Maybe<ChannelPostPinDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/reactions
   */
  listPostReactions?: Maybe<PostReactionsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/reads
   */
  listPostReads?: Maybe<Array<Maybe<MemberDto>>>;
  /**
   *
   *
   * Equivalent to GET /insights-service/statistics/classrooms/{classroomId}/post-reads-by-post
   */
  listPostReadsByPost?: Maybe<InsightsPostReadsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/post-schedules
   */
  listPostSchedules?: Maybe<PostsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/unreads
   */
  listPostUnreads?: Maybe<Array<Maybe<MemberDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts
   */
  listPosts?: Maybe<PostsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/{postType}/posts
   */
  listPostsByPostType?: Maybe<PostsSearchCollectionDto>;
  listPublisherArticles: ArticlesCollectionDto;
  listPublisherSubscriptions: PublisherSubscriptionsCollectionDto;
  listPublishers: PublishersCollectionDto;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/{postId}/comments/{commentId}/replies
   */
  listReplies?: Maybe<RepliesCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/comments/{commentId}/replies/{replyId}/reactions
   */
  listReplyReactions?: Maybe<ReplyReactionsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/schools/{schoolId}/classrooms
   */
  listSchoolClassrooms?: Maybe<ClassroomsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/admin/schools/{schoolId}/classrooms
   */
  listSchoolClassroomsControllerAdminListSchoolClassrooms?: Maybe<ClassroomsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /insights-service/subscription-products
   */
  listSubscriptionProducts?: Maybe<Array<Maybe<InsightsSubscriptionProductDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/tags
   */
  listTags?: Maybe<Array<Maybe<TagDto>>>;
  /**
   *
   *
   * Equivalent to GET /insights-service/todos
   */
  listTodos?: Maybe<InsightsTodosCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /media-service/videos
   */
  listVideos?: Maybe<Array<Maybe<Video>>>;
  /** GET /account-service/accounts/me */
  me?: Maybe<Account>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{organizationId}/members/me
   */
  organizationCurrentMember?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{organizationId}/members/{memberId}
   */
  organizationMember?: Maybe<OrganizationMemberDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/members/{memberId}/permission
   */
  organizationMemberPermission?: Maybe<OrganizationMemberPermissionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/messages/{messageId}
   */
  organizationMessage?: Maybe<OrganizationMessageDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/messages/{messageId}/recipients
   */
  organizationMessageRecipients?: Maybe<OrganizationMessageRecipientsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/message-service
   */
  organizationMessageService?: Maybe<OrganizationMessageServiceDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/messages
   */
  organizationMessages?: Maybe<OrganizationMessagesCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/my-license
   */
  organizationMyLicense?: Maybe<OrganizationMemberLicenseDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/orders/{orderId}
   */
  organizationOrder?: Maybe<OrganizationOrderDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/classrooms/{classroomId}
   */
  organizationOrgClassroom?: Maybe<OrganizationClassroomWithAdminDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/classrooms/{classroomId}/members
   */
  organizationOrgClassroomMembers?: Maybe<OrganizationClassroomMembersCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/classrooms
   */
  organizationOrgClassrooms?: Maybe<OrganizationClassroomsWithAdminCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/org-code
   */
  organizationOrgCode?: Maybe<OrganizationOrgCodeDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/contracts
   */
  organizationOrgContracts?: Maybe<OrganizationContractsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/members
   */
  organizationOrgMembers?: Maybe<OrganizationMemberSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/permission
   */
  organizationOrgPermission?: Maybe<OrganizationOrgPermissionDto>;
  /**
   * 기관 조회
   *
   * Equivalent to GET /organization-service/organizations/{organizationId}
   */
  organizationOrganization?: Maybe<OrganizationOrganizationDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{organizationId}/org-units
   */
  organizationOrganizationalUnits?: Maybe<Array<Maybe<OrganizationOrganizationalUnitDto>>>;
  /**
   * 현재 사용자의 소속 기관 목록 조회
   *
   * Equivalent to GET /organization-service/organizations
   */
  organizationOrgs?: Maybe<Array<Maybe<OrganizationOrganizationDto>>>;
  /**
   *
   *
   * Equivalent to GET /organization-service/plans
   */
  organizationPlans?: Maybe<Array<Maybe<OrganizationPlanDto>>>;
  /**
   * 현재 사용자의 대표기관 조회
   *
   * Equivalent to GET /organization-service/organizations/primary-org
   */
  organizationPrimaryOrg?: Maybe<OrganizationOrganizationDto>;
  /**
   * 기관 조회
   *
   * Equivalent to GET /organization-service/private/organizations/{organizationId}
   */
  organizationPrivateGetOrganizationControllerPrivateGetOrganization?: Maybe<OrganizationOrganizationDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/private/organizations/{organizationId}/members
   */
  organizationPrivateListOrgMembersControllerPrivateListOrgMembers?: Maybe<OrganizationMemberSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/private/organizations/{organizationId}/org-units
   */
  organizationPrivateListOrgUnitsControllerListOrganizationalUnits?: Maybe<Array<Maybe<OrganizationOrganizationalUnitDto>>>;
  /**
   * 사용자의 소속 기관 목록 조회
   *
   * Equivalent to GET /organization-service/private/organizations
   */
  organizationPrivateListOrgsControllerPrivateListOrgs?: Maybe<Array<Maybe<OrganizationOrganizationDto>>>;
  /**
   *
   *
   * Equivalent to GET /organization-service/private/organizations/search
   */
  organizationPrivateSearchOrganizationsControllerSearchOrganizations?: Maybe<OrganizationOrganizationsSearchCollectionDto>;
  /**
   * 기관 검색
   *
   * Equivalent to GET /organization-service/organizations/search
   */
  organizationSearchOrganizations?: Maybe<OrganizationOrganizationsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /organization-service/organizations/{orgId}/features
   */
  organizationSubscribedFeatures?: Maybe<Array<Maybe<OrganizationSubscribedFeatureDto>>>;
  /**
   *
   *
   * Equivalent to GET /organization-service/org-codes/{orgCode}
   */
  organizationVerifiedOrgCode?: Maybe<OrganizationOrgCodeDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}/assignments/{assignmentId}
   */
  privateGetAssignment?: Maybe<AssignmentDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/assignment-submissions/{assignmentSubmissionId}
   */
  privateGetAssignmentSubmission?: Maybe<AssignmentSubmissionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}
   */
  privateGetClassroom?: Maybe<ClassroomDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}/posts/{postId}/comments/{commentId}
   */
  privateGetComment?: Maybe<CommentDto>;
  /**
   *
   *
   * Equivalent to GET /enterprise-service/privates/enterprises/{enterpriseId}
   */
  privateGetEnterpriseControllerPrivateGetEnterprise?: Maybe<EnterpriseDto>;
  /**
   *
   *
   * Equivalent to GET /enterprise-service/privates/enterprises/{enterpriseId}/members/{memberId}
   */
  privateGetMemberControllerPrivateGetMember?: Maybe<EnterpriseMemberDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}/posts/{postId}
   */
  privateGetPost?: Maybe<PostDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}/channels/{channelId}/members
   */
  privateListChannelMembers?: Maybe<Array<Maybe<MemberDto>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}/members
   */
  privateListClassroomMembers?: Maybe<MembersCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/organizations/{organizationId}/classrooms
   */
  privateListOrganizationClassrooms?: Maybe<ClassroomsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/{classroomId}/posts
   */
  privateListPosts?: Maybe<PostsCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/privates/classrooms/search
   */
  privateSearchClassrooms?: Maybe<ClassroomsSearchCollectionDto>;
  /** GET /schools/{args.schoolId} */
  school?: Maybe<School>;
  /** GET /institute_types/school/categories */
  schoolCategories?: Maybe<Array<Maybe<SchoolCategory>>>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/search
   */
  searchClassrooms?: Maybe<ClassroomsSearchCollectionDto>;
  /**
   *
   *
   * Equivalent to GET /classroom-service/classrooms/{classroomId}/posts/search
   */
  searchPosts?: Maybe<PostsSearchCollectionDto>;
  /** GET /search?type=school&q={args.q}&offset={args.offset}&limit={args.limit} */
  searchSchools?: Maybe<SchoolCollection>;
  /**
   *
   *
   * Equivalent to GET /account-service/student-registration-codes
   */
  studentRegistrationCodesControllerListStudentRegistrationCodes?: Maybe<Array<Maybe<StudentRegistrationCodeDto>>>;
};


export type QueryAdminApiGetImageArgs = {
  imageId: Scalars['String'];
};


export type QueryAssignmentSubmissionArgs = {
  assignmentSubmissionId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryEnterpriseArgs = {
  enterpriseId: Scalars['String'];
};


export type QueryEnterpriseMeArgs = {
  enterpriseId: Scalars['String'];
};


export type QueryEnterpriseMemberArgs = {
  enterpriseId: Scalars['String'];
  memberId: Scalars['String'];
};


export type QueryEnterprisePermissionArgs = {
  enterpriseId: Scalars['String'];
};


export type QueryExportClassroomAttendancesArgs = {
  classroomId: Scalars['String'];
  fileName: Scalars['String'];
  months: Array<InputMaybe<Scalars['Float']>>;
  timezone?: InputMaybe<Scalars['String']>;
  year: Scalars['Float'];
};


export type QueryGetAccountArgs = {
  id: Scalars['String'];
};


export type QueryGetAiTestPolicyArgs = {
  classroomId: Scalars['ID'];
};


export type QueryGetAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetAssignmentPermissionArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetAssignmentSubmissionArgs = {
  assigneeId: Scalars['String'];
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetAssignmentSubmissionCommentPermissionArgs = {
  assignmentSubmissionId: Scalars['String'];
  commentId: Scalars['String'];
};


export type QueryGetAttendanceSummaryArgs = {
  classroomId: Scalars['String'];
  date: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};


export type QueryGetChannelArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetChannelDtoArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetChannelGroupArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetChannelGroupDtoArgs = {
  channelGroupId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryGetClassCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetClassroomArgs = {
  classroomId: Scalars['String'];
};


export type QueryGetClassroomBookmarkStatusArgs = {
  classroomId: Scalars['String'];
};


export type QueryGetClassroomClassCodeArgs = {
  id: Scalars['String'];
};


export type QueryGetClassroomMemberArgs = {
  classroomId: Scalars['String'];
  memberId: Scalars['String'];
};


export type QueryGetClasstalkUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetCommentArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  postId: Scalars['String'];
};


export type QueryGetCommentPermissionArgs = {
  commentId: Scalars['String'];
  postId: Scalars['String'];
};


export type QueryGetEstimatedOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryGetFileArgs = {
  fileId: Scalars['String'];
};


export type QueryGetGoalArgs = {
  childId: Scalars['String'];
  goalId: Scalars['String'];
};


export type QueryGetImageArgs = {
  imageId: Scalars['String'];
};


export type QueryGetLatestGoalArgs = {
  childId: Scalars['String'];
  guardianId: Scalars['String'];
};


export type QueryGetLearningCardArgs = {
  learningCardId?: InputMaybe<Scalars['Float']>;
};


export type QueryGetLearningStoreCardArgs = {
  learningStoreCardId?: InputMaybe<Scalars['Float']>;
};


export type QueryGetMemberPushNotificationSettingsArgs = {
  classroomId: Scalars['String'];
};


export type QueryGetNewsGeneratorArgs = {
  generatorId: Scalars['String'];
  generatorType: Scalars['String'];
};


export type QueryGetNoticeFeedArgs = {
  id: Scalars['String'];
};


export type QueryGetOnboardingChecklistArgs = {
  classroomId: Scalars['String'];
};


export type QueryGetOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryGetPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type QueryGetPostPermissionArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type QueryGetPostReadStatusArgs = {
  postId: Scalars['String'];
};


export type QueryGetPublisherArgs = {
  publisherId: Scalars['String'];
  publisherLocale: Scalars['String'];
};


export type QueryGetPublisherArticleArgs = {
  articleId: Scalars['String'];
  publisherId: Scalars['String'];
  publisherLocale: Scalars['String'];
};


export type QueryGetPublisherSubscriptionArgs = {
  publisherId: Scalars['String'];
};


export type QueryGetRecordingArgs = {
  externalServiceId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type QueryGetReplyArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  replyId: Scalars['String'];
};


export type QueryGetReplyPermissionArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  replyId: Scalars['String'];
};


export type QueryGetSchoolMealPostsArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  schoolId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTagArgs = {
  classroomId: Scalars['String'];
  tag: Scalars['String'];
};


export type QueryGetTranslationPermissionArgs = {
  classroomId: Scalars['String'];
};


export type QueryGetUrlMetadataArgs = {
  url: Scalars['String'];
};


export type QueryGetVideoArgs = {
  videoId: Scalars['String'];
};


export type QueryLegacyPasscodeArgs = {
  q?: InputMaybe<Scalars['String']>;
};


export type QueryListActivityStreamsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  generatorId?: InputMaybe<Scalars['String']>;
  generatorType: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  objectTypes?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  targetTypes?: InputMaybe<Scalars['String']>;
};


export type QueryListAiTestSubjectsArgs = {
  grade?: InputMaybe<Scalars['Float']>;
};


export type QueryListAiTestUnitsArgs = {
  grade: Scalars['Float'];
  subjectId: Scalars['Float'];
};


export type QueryListAiTestsArgs = {
  classroomId: Scalars['ID'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};


export type QueryListAllCumulativeRecordsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListArticlesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  locale: Scalars['String'];
};


export type QueryListAssignmentAssigneesArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListAssignmentSubmissionCommentsArgs = {
  assignmentSubmissionId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListAssignmentSubmissionsArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  submitted?: InputMaybe<Scalars['String']>;
};


export type QueryListBookmarkedClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListChannelGroupsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Scope2>;
};


export type QueryListChannelGroupsDtoArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Scope2>;
};


export type QueryListChannelMediaPostsArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};


export type QueryListChannelMembersArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  groups?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListChannelsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListChannelsDtoArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListChildActivitiesArgs = {
  childId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  generatorId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryListChildrenArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListClassroomAssignmentsArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};


export type QueryListClassroomBlockedMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListClassroomMediaPostsArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};


export type QueryListClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  roles?: InputMaybe<Scalars['String']>;
};


export type QueryListClassroomMembersControllerAdminListClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  roles?: InputMaybe<Scalars['String']>;
};


export type QueryListClassroomRolesArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Filter>;
  limit?: InputMaybe<Scalars['Float']>;
  role?: InputMaybe<Scalars['String']>;
};


export type QueryListClasstalkMeetingsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListCommentReactionsArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};


export type QueryListCommonChannelsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListCumulativeRecordsArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  memberId?: InputMaybe<Scalars['String']>;
};


export type QueryListGuardianRelationshipsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  type: Type;
};


export type QueryListImagesArgs = {
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListLearningCardPagesArgs = {
  learningCardId: Scalars['ID'];
};


export type QueryListLearningCardsArgs = {
  authorId?: InputMaybe<Scalars['String']>;
  coreConceptId?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  mediaType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  publisherId?: InputMaybe<Scalars['Float']>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchKeywords?: InputMaybe<Scalars['String']>;
  shareLevel?: InputMaybe<Scalars['Float']>;
  subjectId?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Float']>;
};


export type QueryListLearningCommunityCardsArgs = {
  coreConceptId?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  mediaType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  publisherId?: InputMaybe<Scalars['Float']>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchKeywords?: InputMaybe<Scalars['String']>;
  shareLevel?: InputMaybe<Scalars['Float']>;
  subjectId?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Float']>;
};


export type QueryListLearningSchoolCardsArgs = {
  coreConceptId?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  mediaType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  publisherId?: InputMaybe<Scalars['Float']>;
  schoolId: Scalars['String'];
  searchKeywords?: InputMaybe<Scalars['String']>;
  shareLevel?: InputMaybe<Scalars['ID']>;
  subjectId?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Float']>;
};


export type QueryListLearningStoreCardPagesArgs = {
  learningStoreCardId: Scalars['ID'];
};


export type QueryListLearningStoreCardsArgs = {
  coreConceptId?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  mediaType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  publisherId?: InputMaybe<Scalars['Float']>;
  schoolId?: InputMaybe<Scalars['String']>;
  searchKeywords?: InputMaybe<Scalars['String']>;
  shareLevel?: InputMaybe<Scalars['Float']>;
  subjectId?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['Float']>;
};


export type QueryListLearningUnitsArgs = {
  gradeId: Scalars['ID'];
  subjectId: Scalars['ID'];
};


export type QueryListMealFeedsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  generatorId?: InputMaybe<Scalars['String']>;
  generatorType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  objectId?: InputMaybe<Scalars['String']>;
  objectType?: InputMaybe<Scalars['String']>;
};


export type QueryListMemberActivitiesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  generatorId: Scalars['String'];
  generatorType: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
  memberId: Scalars['String'];
};


export type QueryListMemberAssignmentsArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
};


export type QueryListMemberAttendancesArgs = {
  classroomId: Scalars['String'];
  endDate: Scalars['String'];
  memberId: Scalars['String'];
  startDate: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};


export type QueryListMemberSubmissionsArgs = {
  assignmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListMyBookmarkedClassroomsArgs = {
  includeLegacyClassrooms?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListMyClassroomsArgs = {
  filter?: InputMaybe<Filter>;
  includeLegacyClassrooms?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  orgId?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolYear?: InputMaybe<Scalars['Int']>;
};


export type QueryListNewsfeedPostsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  postIds?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status4>;
};


export type QueryListNoticeFeedsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  generatorId?: InputMaybe<Scalars['String']>;
  generatorType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  objectId?: InputMaybe<Scalars['String']>;
  objectType?: InputMaybe<Scalars['String']>;
};


export type QueryListNotificationsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  generatorId?: InputMaybe<Scalars['String']>;
  generatorType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListPostCommentsArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  postId: Scalars['String'];
};


export type QueryListPostDraftsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  postType?: InputMaybe<PostType>;
};


export type QueryListPostPinsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListPostReactionsArgs = {
  classroomId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};


export type QueryListPostReadsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  postId: Scalars['String'];
};


export type QueryListPostReadsByPostArgs = {
  classroomId: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryListPostSchedulesArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  postType?: InputMaybe<PostType>;
};


export type QueryListPostUnreadsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  postId: Scalars['String'];
};


export type QueryListPostsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  postIds?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status4>;
};


export type QueryListPostsByPostTypeArgs = {
  classroomId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  postType: Scalars['String'];
};


export type QueryListPublisherArticlesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  publisherId: Scalars['String'];
  publisherLocale: Scalars['String'];
};


export type QueryListPublisherSubscriptionsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  publisherId?: InputMaybe<Scalars['String']>;
};


export type QueryListPublishersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  publisherLocale: Scalars['String'];
};


export type QueryListRepliesArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  postId: Scalars['String'];
};


export type QueryListReplyReactionsArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  replyId: Scalars['String'];
};


export type QueryListSchoolClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  schoolId: Scalars['String'];
};


export type QueryListSchoolClassroomsControllerAdminListSchoolClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  schoolId: Scalars['String'];
};


export type QueryListSubscriptionProductsArgs = {
  currencyCode: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  packageName: Scalars['String'];
  productId: Scalars['String'];
  regionCode: Scalars['String'];
};


export type QueryListTagsArgs = {
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListTodosArgs = {
  childId: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListVideosArgs = {
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationCurrentMemberArgs = {
  organizationId: Scalars['String'];
};


export type QueryOrganizationMemberArgs = {
  memberId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryOrganizationMemberPermissionArgs = {
  memberId: Scalars['String'];
  orgId: Scalars['String'];
};


export type QueryOrganizationMessageArgs = {
  messageId: Scalars['String'];
  orgId: Scalars['String'];
};


export type QueryOrganizationMessageRecipientsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  messageId: Scalars['String'];
  orgId: Scalars['String'];
};


export type QueryOrganizationMessageServiceArgs = {
  orgId: Scalars['String'];
};


export type QueryOrganizationMessagesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  orgId: Scalars['String'];
};


export type QueryOrganizationMyLicenseArgs = {
  orgId: Scalars['String'];
};


export type QueryOrganizationOrderArgs = {
  orderId: Scalars['String'];
  orgId: Scalars['String'];
};


export type QueryOrganizationOrgClassroomArgs = {
  classroomId: Scalars['String'];
  orgId: Scalars['String'];
};


export type QueryOrganizationOrgClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  orgId: Scalars['String'];
  roles?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationOrgClassroomsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<OrganizationGrade>;
  orgId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  schoolYear?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<OrganizationStatus2>;
};


export type QueryOrganizationOrgCodeArgs = {
  orgId: Scalars['String'];
};


export type QueryOrganizationOrgContractsArgs = {
  contractStatus?: InputMaybe<OrganizationContractStatus>;
  orgId: Scalars['String'];
};


export type QueryOrganizationOrgMembersArgs = {
  accountRole?: InputMaybe<OrganizationAccountRole>;
  memberOrderField?: InputMaybe<OrganizationMemberOrderField>;
  orderDirection?: InputMaybe<OrganizationOrderDirection>;
  orgId: Scalars['String'];
  orgRole?: InputMaybe<OrganizationOrgRole>;
  orgUnitId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationOrgPermissionArgs = {
  orgId: Scalars['String'];
};


export type QueryOrganizationOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryOrganizationOrganizationalUnitsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
};


export type QueryOrganizationOrgsArgs = {
  enterpriseId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  orgRoles?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationPlansArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationPrivateGetOrganizationControllerPrivateGetOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryOrganizationPrivateListOrgMembersControllerPrivateListOrgMembersArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  accountRole?: InputMaybe<OrganizationAccountRole>;
  orgRole?: InputMaybe<OrganizationOrgRole>;
  orgRoles?: InputMaybe<OrganizationOrgRoles3>;
  orgUnitId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationPrivateListOrgUnitsControllerListOrganizationalUnitsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
};


export type QueryOrganizationPrivateListOrgsControllerPrivateListOrgsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  enterpriseId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  orgRoles?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationPrivateSearchOrganizationsControllerSearchOrganizationsArgs = {
  enterpriseCategory?: InputMaybe<Scalars['String']>;
  enterpriseId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationSearchOrganizationsArgs = {
  enterpriseCategory?: InputMaybe<Scalars['String']>;
  enterpriseId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationSubscribedFeaturesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['String'];
};


export type QueryOrganizationVerifiedOrgCodeArgs = {
  orgCode: Scalars['String'];
};


export type QueryPrivateGetAssignmentArgs = {
  assignmentId: Scalars['String'];
  classroomId: Scalars['String'];
};


export type QueryPrivateGetAssignmentSubmissionArgs = {
  assignmentSubmissionId: Scalars['String'];
};


export type QueryPrivateGetClassroomArgs = {
  classroomId: Scalars['String'];
};


export type QueryPrivateGetCommentArgs = {
  classroomId: Scalars['String'];
  commentId: Scalars['String'];
  postId: Scalars['String'];
};


export type QueryPrivateGetEnterpriseControllerPrivateGetEnterpriseArgs = {
  enterpriseId: Scalars['String'];
};


export type QueryPrivateGetMemberControllerPrivateGetMemberArgs = {
  enterpriseId: Scalars['String'];
  memberId: Scalars['String'];
};


export type QueryPrivateGetPostArgs = {
  classroomId: Scalars['String'];
  postId: Scalars['String'];
};


export type QueryPrivateListChannelMembersArgs = {
  channelId: Scalars['String'];
  classroomId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryPrivateListClassroomMembersArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  roles?: InputMaybe<Scalars['String']>;
};


export type QueryPrivateListOrganizationClassroomsArgs = {
  active?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade3>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['String'];
  schoolYear?: InputMaybe<Scalars['Float']>;
};


export type QueryPrivateListPostsArgs = {
  classroomId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  publishedAtGt?: InputMaybe<Scalars['String']>;
  publishedAtLt?: InputMaybe<Scalars['String']>;
};


export type QueryPrivateSearchClassroomsArgs = {
  active?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade3>;
  isLegacy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  schoolYear?: InputMaybe<Scalars['Float']>;
};


export type QuerySchoolArgs = {
  schoolId?: InputMaybe<Scalars['String']>;
};


export type QuerySearchClassroomsArgs = {
  active?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade3>;
  isLegacy?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  schoolYear?: InputMaybe<Scalars['Float']>;
};


export type QuerySearchPostsArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  classroomId: Scalars['String'];
  isAnnouncement?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};


export type QuerySearchSchoolsArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  q?: InputMaybe<Scalars['String']>;
};


export type QueryStudentRegistrationCodesControllerListStudentRegistrationCodesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export enum ReadStatus {
  Read = 'READ',
  Unread = 'UNREAD'
}

export type RecordingDownloadUrlDto = {
  __typename?: 'RecordingDownloadUrlDto';
  presignedUrl: Scalars['String'];
};

export type RecordingDto = {
  __typename?: 'RecordingDto';
  createdAt: Scalars['String'];
  externalServiceId: Scalars['String'];
  id: Scalars['String'];
  meetingId: Scalars['String'];
  /**
   *
   *     녹음 파일 상태값
   *       'in-progress' | 'success' | 'failure'
   *
   */
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RegisterDtoInput = {
  /** 가입할 클래스의 클래스코드 */
  classCode: Scalars['String'];
  /** 구성원 클래스 내 역할 */
  group?: InputMaybe<Group2>;
  /** 구성원 이름 */
  name: Scalars['String'];
  /** 미디어 서비스 내의 구성원 프로필 이미지 식별자 */
  profileImageId?: InputMaybe<Scalars['String']>;
  /** 구성원 프로필 이미지 URL */
  profileImageUrl?: InputMaybe<Scalars['String']>;
};

export type RegisterSchoolDtoInput = {
  academicYearStartMonth?: InputMaybe<Scalars['Int']>;
  address: Scalars['String'];
  addressDetail: Scalars['String'];
  admissionAge?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['Int'];
  countryCode: Scalars['String'];
  firstGrade?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  regularCourseYear?: InputMaybe<Scalars['Int']>;
  website: Scalars['String'];
  zipcode: Scalars['String'];
};

export type RegisterToClasstalkDtoInput = {
  callable?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profileImageUrl?: InputMaybe<Scalars['String']>;
  workdays?: InputMaybe<Array<InputMaybe<RegisterWorkdayDtoInput>>>;
};

export type RegisterWorkdayDtoInput = {
  available: Scalars['Boolean'];
  day: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type RegradeAssignmentSubmissionDtoInput = {
  points: Scalars['Float'];
};

export enum RelationshipType {
  Father = 'FATHER',
  GrandFather = 'GRAND_FATHER',
  GrandMother = 'GRAND_MOTHER',
  Guardian = 'GUARDIAN',
  Mother = 'MOTHER'
}

export type RemoveDeviceResponseDto = {
  __typename?: 'RemoveDeviceResponseDto';
  success: Scalars['Boolean'];
};

export type RemoveReactionFromCommentDtoInput = {
  name: Scalars['String'];
};

export type RemoveReactionFromPostDtoInput = {
  name: Scalars['String'];
};

export type RemoveReactionFromReplyDtoInput = {
  name: Scalars['String'];
};

export type RepliesCollectionDto = {
  __typename?: 'RepliesCollectionDto';
  cursor?: Maybe<Scalars['String']>;
  replies: Array<Maybe<ReplyDto>>;
};

export type ReplyDto = {
  __typename?: 'ReplyDto';
  author: MemberDto;
  authorId: Scalars['String'];
  commentId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  postId: Scalars['String'];
  reactions: Array<Maybe<ReplyReactionDto>>;
  reactionsCount: Scalars['Json'];
  updatedAt: Scalars['String'];
};

export type ReplyPermissionDto = {
  __typename?: 'ReplyPermissionDto';
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canReact: Scalars['Boolean'];
};

export type ReplyReactionDto = {
  __typename?: 'ReplyReactionDto';
  createdAt: Scalars['String'];
  member?: Maybe<MemberDto>;
  memberId: Scalars['String'];
  name: Scalars['String'];
};

export type ReplyReactionsCollectionDto = {
  __typename?: 'ReplyReactionsCollectionDto';
  reactions: Array<Maybe<ReplyReactionDto>>;
};

export type ReportPostDtoInput = {
  reason?: InputMaybe<Scalars['String']>;
};

export type RequestEmailChangeDtoInput = {
  email: Scalars['String'];
  isEnterprise: Scalars['Boolean'];
};

export type RequestMobileChangeDtoInput = {
  countryCode: Scalars['String'];
  isEnterprise: Scalars['String'];
  number: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export enum RoleCategory {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Moderator = 'MODERATOR',
  Visitor = 'VISITOR'
}

export type RoleDto = {
  __typename?: 'RoleDto';
  category: RoleCategory;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  /** 역할을 지칭하기 위한 수정 가능한 이름 */
  name: Scalars['String'];
  /** 목록내의 정렬순서를 나타냄 */
  position: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type SaveAssignmentSubmissionPointsDtoInput = {
  points?: InputMaybe<Scalars['Float']>;
};

export type SaveEssayAnswerPointsToLearningAssignmentDtoInput = {
  pageId: Scalars['Float'];
  points?: InputMaybe<Scalars['Float']>;
};

export type SaveMemberAnswerToLearningAssignmentDtoInput = {
  correctOptions?: InputMaybe<Array<InputMaybe<Scalars['Json']>>>;
  duration: Scalars['Float'];
  essay?: InputMaybe<Scalars['String']>;
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  maxPoints?: InputMaybe<Scalars['Float']>;
  multipleChoiceOption?: InputMaybe<Scalars['Json']>;
  pageId: Scalars['Float'];
  pageIndex: Scalars['Float'];
  pageType: LearningCardPageType;
  textEntry?: InputMaybe<Scalars['String']>;
  userInput?: InputMaybe<Scalars['String']>;
};

export type SavePostAsDraftDtoInput = {
  adminExclusive?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isAnnouncement?: InputMaybe<Scalars['Boolean']>;
  scheduledAt?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  videoIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type School = {
  __typename?: 'School';
  admissionAge?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstGrade?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActivated?: Maybe<Scalars['Boolean']>;
  isCertified?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  monthAcademicYearStartsFrom?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  regularCourseYear?: Maybe<Scalars['Int']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type SchoolCategory = {
  __typename?: 'SchoolCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameLabel?: Maybe<Scalars['String']>;
};

export type SchoolCollection = {
  __typename?: 'SchoolCollection';
  schools?: Maybe<Array<Maybe<School>>>;
};

export type SchoolDto = {
  __typename?: 'SchoolDto';
  /** 입학 연령 */
  admissionAge: Scalars['Float'];
  /** 소속 도시 */
  city: Scalars['String'];
  /** 국가 코드 */
  countryCode: Scalars['String'];
  /** 학교 식별자 */
  id: Scalars['String'];
  /** 학교 이름 */
  name: Scalars['String'];
  /** 교육 과정 년도 */
  regularCourseYear: Scalars['Float'];
  /** 우편변호 */
  zipcode: Scalars['String'];
};

export type SchoolDtoInput = {
  /** 입학 연령 */
  admissionAge: Scalars['Float'];
  /** 소속 도시 */
  city: Scalars['String'];
  /** 국가 코드 */
  countryCode: Scalars['String'];
  /** 학교 식별자 */
  id: Scalars['String'];
  /** 학교 이름 */
  name: Scalars['String'];
  /** 교육 과정 년도 */
  regularCourseYear: Scalars['Float'];
  /** 우편변호 */
  zipcode: Scalars['String'];
};

export enum SchoolGrade {
  Adult = 'ADULT',
  Eighth = 'EIGHTH',
  Eleventh = 'ELEVENTH',
  Fifth = 'FIFTH',
  First = 'FIRST',
  Fourth = 'FOURTH',
  HigherEducation = 'HIGHER_EDUCATION',
  Kindergarten = 'KINDERGARTEN',
  Ninth = 'NINTH',
  None = 'NONE',
  PreKindergarten = 'PRE_KINDERGARTEN',
  Second = 'SECOND',
  Seventh = 'SEVENTH',
  Sixth = 'SIXTH',
  Tenth = 'TENTH',
  Third = 'THIRD',
  Twelfth = 'TWELFTH'
}

export type SchoolInfo = {
  __typename?: 'SchoolInfo';
  grade: SchoolGrade;
  school?: Maybe<AccountSchool>;
};

export type SchoolInfo2 = {
  __typename?: 'SchoolInfo2';
  grade: SchoolGrade;
  school?: Maybe<AccountSchool>;
};

export type SchoolInfo3Input = {
  grade: SchoolGrade;
  school?: InputMaybe<SchoolDtoInput>;
};

export type SchoolInfo4 = {
  __typename?: 'SchoolInfo4';
  grade: SchoolGrade;
  school?: Maybe<SchoolDto>;
};

export type SchoolInfoByIdDtoInput = {
  grade: SchoolGrade;
  schoolId: Scalars['String'];
};

export type SchoolInfoByIdInput = {
  grade: SchoolGrade;
  schoolId: Scalars['String'];
};

/** 소속 학교 정보 */
export type SchoolInfoDto = {
  __typename?: 'SchoolInfoDto';
  grade: SchoolGrade;
  school?: Maybe<SchoolDto>;
};

export type SchoolInfoDto2 = {
  __typename?: 'SchoolInfoDto2';
  grade: SchoolGrade;
  school?: Maybe<SchoolDto>;
};

export type SchoolInfoDto2Input = {
  grade: SchoolGrade;
  school?: InputMaybe<SchoolDtoInput>;
};

export type SchoolRegistration = {
  __typename?: 'SchoolRegistration';
  academicYearStartMonth?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  addressDetail?: Maybe<Scalars['String']>;
  admissionAge?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  firstGrade?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regularCourseYear?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export enum Scope2 {
  All = 'ALL',
  Joined = 'JOINED'
}

export enum SignInMethod {
  Apple = 'APPLE',
  Azure = 'AZURE',
  Email = 'EMAIL',
  Google = 'GOOGLE',
  Id = 'ID',
  Kakao = 'KAKAO',
  Mobile = 'MOBILE',
  Naver = 'NAVER',
  Username = 'USERNAME',
  Whalespace = 'WHALESPACE'
}

export type SimpleAccountDto = {
  __typename?: 'SimpleAccountDto';
  avatarImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  role: AccountRole;
  username?: Maybe<Scalars['String']>;
};

export type SocialIdentity = {
  __typename?: 'SocialIdentity';
  createdAt?: Maybe<Scalars['String']>;
  provider: OAuthProvider;
  /** 소셜 로그인 Provider 고유 아이디 */
  uid: Scalars['String'];
};

export type StartMeetingDtoInput = {
  classroomId: Scalars['String'];
  receiverId: Scalars['String'];
};

export enum Status {
  Absent = 'ABSENT',
  Attended = 'ATTENDED',
  Excused = 'EXCUSED',
  Unknown = 'UNKNOWN'
}

export enum Status2 {
  Ended = 'ENDED',
  Started = 'STARTED',
  Waiting = 'WAITING'
}

export enum Status3 {
  Created = 'CREATED',
  Drafted = 'DRAFTED',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

export enum Status4 {
  Drafted = 'DRAFTED',
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED'
}

export type StudentRegistrationCodeDto = {
  __typename?: 'StudentRegistrationCodeDto';
  code: Scalars['String'];
  createdAt: Scalars['String'];
  expireAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  studentAccountId?: Maybe<Scalars['String']>;
  teacherAccountId: Scalars['String'];
  teacherAgreedAt?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type SubmissionDto = {
  __typename?: 'SubmissionDto';
  /** 제출물이 제출된 시각 */
  createdAt: Scalars['String'];
  /** 제출물에 첨부된 파일 식별자 목록 */
  fileIds: Array<Maybe<Scalars['String']>>;
  /** 제출물 식별자 */
  id: Scalars['String'];
  /** 제출물에 첨부된 이미지 식별자 목록 */
  imageIds: Array<Maybe<Scalars['String']>>;
  /** 제출물 내용 */
  text: Scalars['String'];
};

export type SubmitSubmissionDtoInput = {
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text?: InputMaybe<Scalars['String']>;
};

export type TagDto = {
  __typename?: 'TagDto';
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  pinned: Scalars['Boolean'];
  postsCount: Scalars['Float'];
  updatedAt: Scalars['String'];
};

export type TranslatePostDtoInput = {
  targetLanguage: Scalars['String'];
};

export type TranslatedPostDto = {
  __typename?: 'TranslatedPostDto';
  classroomId: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
  postId: Scalars['String'];
  targetLanguage: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type TranslationPermissionDto = {
  __typename?: 'TranslationPermissionDto';
  canTranslatePost: Scalars['Boolean'];
  memberId: Scalars['String'];
};

export enum Type {
  Announcement = 'ANNOUNCEMENT',
  Child = 'CHILD',
  Guardian = 'GUARDIAN',
  Legacy = 'LEGACY',
  Post = 'POST'
}

export type UnpinPostDtoInput = {
  postId: Scalars['String'];
};

export type UpdateAccountDtoInput = {
  birthDate?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender2>;
  grade?: InputMaybe<SchoolGrade>;
  isMarketingTermsAgreed?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  marketingAgreement?: InputMaybe<MarketingAgreement2Input>;
  name?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
  schoolInfo?: InputMaybe<SchoolInfo3Input>;
  schoolInfoById?: InputMaybe<SchoolInfoByIdInput>;
  schoolInfoForChildren?: InputMaybe<Array<InputMaybe<SchoolInfoDto2Input>>>;
};

export type UpdateAssignedAssignmentDtoInput = {
  dueDateTime?: InputMaybe<Scalars['String']>;
  maxPoints?: InputMaybe<Scalars['Float']>;
};

export type UpdateAssignmentDtoInput = {
  channelId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  dueDateTime: Scalars['String'];
  maxPoints: Scalars['Float'];
};

export type UpdateAssignmentSubmissionCommentDtoInput = {
  content: Scalars['String'];
};

export type UpdateAutoRecordingSettingDtoInput = {
  agreeAutoRecordingTerms?: InputMaybe<Scalars['Boolean']>;
  isAutoRecordingActive: Scalars['Boolean'];
};

export type UpdateChannelDtoInput = {
  autoJoin?: InputMaybe<Scalars['Boolean']>;
  canMemberReactToPost?: InputMaybe<Scalars['Boolean']>;
  canMemberWriteComment?: InputMaybe<Scalars['Boolean']>;
  canMemberWritePost?: InputMaybe<Scalars['Boolean']>;
  channelGroupId?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
};

export type UpdateChannelGroupDtoInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateChildSchoolInfoDtoInput = {
  schoolInfoForChildren: Array<InputMaybe<SchoolInfoByIdDtoInput>>;
};

export type UpdateClassroomDtoInput = {
  about?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade3>;
  name?: InputMaybe<Scalars['String']>;
  profileImageId?: InputMaybe<Scalars['String']>;
  profileImageUrl?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['String']>;
  schoolYear?: InputMaybe<Scalars['Int']>;
};

export type UpdateClasstalkUserDtoInput = {
  callable?: InputMaybe<Scalars['Boolean']>;
  workdays?: InputMaybe<Array<InputMaybe<UpdateClasstalkUserWorkdayDtoInput>>>;
};

export type UpdateClasstalkUserWorkdayDtoInput = {
  available: Scalars['Boolean'];
  day: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type UpdateCommentDtoInput = {
  content: Scalars['String'];
};

export type UpdateMemberDtoInput = {
  group?: InputMaybe<Group>;
  name?: InputMaybe<Scalars['String']>;
  profileImageId?: InputMaybe<Scalars['String']>;
  profileImageUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateMemberPushNotificationSettingsDtoInput = {
  announcementsEnabled?: InputMaybe<Scalars['Boolean']>;
  commentsAndRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  postCommentsAndRepliesEnabled?: InputMaybe<Scalars['Boolean']>;
  postReactionsEnabled?: InputMaybe<Scalars['Boolean']>;
  postsEnabled?: InputMaybe<Scalars['Boolean']>;
  reactionsEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePostAdminExclusiveByAdminDtoInput = {
  adminExclusive: Scalars['Boolean'];
};

export type UpdatePostChannelAdminDtoInput = {
  channelId: Scalars['String'];
};

export type UpdatePostDtoInput = {
  adminExclusive?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  fileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isAnnouncement?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  videoIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateReplyDtoInput = {
  content: Scalars['String'];
};

export type UpdateTagDtoInput = {
  pinned?: InputMaybe<Scalars['Boolean']>;
};

export type UrlMetadata = {
  __typename?: 'UrlMetadata';
  author?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  publisher?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

export type UserDto = {
  __typename?: 'UserDto';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Video = {
  __typename?: 'Video';
  categoryId: Scalars['String'];
  categoryType: Scalars['String'];
  contentType: Scalars['String'];
  conversionStatus?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  originalFileName: Scalars['String'];
  posterUrl?: Maybe<Scalars['String']>;
  presignedPost?: Maybe<Scalars['Json']>;
  resourceKey?: Maybe<Scalars['String']>;
  streamUrl?: Maybe<Scalars['String']>;
  totalSize: Scalars['Float'];
  updatedAt: Scalars['String'];
  urls: Scalars['Json'];
  userId: Scalars['String'];
};

export type VideoDto = {
  __typename?: 'VideoDto';
  /** 비디오가 첨부된 상위 리소스 식별자 */
  categoryId: Scalars['String'];
  /** 비디오가 첨부된 상위 리소스 */
  categoryType: Scalars['String'];
  /** 비디오의 MIME 타입 */
  contentType: Scalars['String'];
  /** 비디오의 변환 상태 */
  conversionStatus?: Maybe<Scalars['String']>;
  /** 비디오가 생성된 시각 */
  createdAt: Scalars['String'];
  /** 비디오가 저장된 경로 */
  filePath: Scalars['String'];
  /** 동영상 식별자 */
  id: Scalars['String'];
  /** 비디오의 원본 파일명 */
  originalFileName: Scalars['String'];
  /** 비디오의 썸네일 URL */
  posterUrl?: Maybe<Scalars['String']>;
  /** 비디오의 스트리밍 URL */
  streamUrl?: Maybe<Scalars['String']>;
  /** 비디오의 크기 */
  totalSize: Scalars['Float'];
  /** 비디오가 최근 수정된 시각 */
  updatedAt: Scalars['String'];
  /** 비디오의 다양한 URL 목록 */
  urls: Scalars['Json'];
  /** 동영상을 업로드한 사용자의 식별자 */
  userId: Scalars['String'];
};

export type WorkdayDto = {
  __typename?: 'WorkdayDto';
  available: Scalars['Boolean'];
  day: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type ZoomIntegrationDto = {
  __typename?: 'ZoomIntegrationDto';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  memberId: Scalars['String'];
  refreshToken: Scalars['String'];
  scope: Scalars['String'];
  tokenType: Scalars['String'];
  userId: Scalars['String'];
};

export type EnterpriseAppBarEnterpriseDtoFieldsFragment = { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', logoUrl?: string | null, name?: string | null } };

export type EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment = { __typename?: 'EnterpriseDto', id: string, isWhiteLabeled: boolean, branding: { __typename?: 'EnterpriseBranding', name?: string | null } };

export type EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment = { __typename?: 'EnterpriseDto', id: string, isWhiteLabeled: boolean, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } };

export type EnterpriseChangeEmailPageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type EnterpriseChangeEmailPageQuery = { __typename?: 'Query', enterprise?: { __typename?: 'EnterpriseDto', id: string, isWhiteLabeled: boolean, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type EnterpriseChangeMobilePageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type EnterpriseChangeMobilePageQuery = { __typename?: 'Query', enterprise?: { __typename?: 'EnterpriseDto', id: string, isWhiteLabeled: boolean, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type EnterpriseDeleteConfirmPageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type EnterpriseDeleteConfirmPageQuery = { __typename?: 'Query', enterprise?: { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', logoUrl?: string | null, name?: string | null, appIconUrl?: string | null } } | null };

export type AccountInfoPageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type AccountInfoPageQuery = { __typename?: 'Query', enterprise?: { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type ChildCodePageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type ChildCodePageQuery = { __typename?: 'Query', enterprise?: { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type CreateChildCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CreateChildCodeMutation = { __typename?: 'Mutation', createChildCode?: { __typename?: 'ChildCodeResponseDto', childId: string, code: string, expireAt: string } | null };

export type ChildCodePageContentQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ChildCodePageContentQuery = { __typename?: 'Query', listGuardianRelationships?: Array<{ __typename?: 'GuardianRelationshipDto', relationshipType: RelationshipType, guardianAccount?: { __typename?: 'GuardianAccountDto', id: string, avatarImage?: string | null, name: string } | null } | null> | null };

export type EnterpriseLinkChildPageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type EnterpriseLinkChildPageQuery = { __typename?: 'Query', me?: { __typename?: 'Account', id?: string | null, role?: string | null } | null, enterprise?: { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type SetGuardianRelationshipMutationVariables = Exact<{
  childId: Scalars['String'];
  relationship: RelationshipType;
}>;


export type SetGuardianRelationshipMutation = { __typename?: 'Mutation', linkChild?: { __typename?: 'LinkChildResponseDto', id: string, childId: string, createdAt: string, guardianId: string, relationshipType: RelationshipType } | null };

export type VerifyChildCodeMutationVariables = Exact<{
  code: Scalars['String'];
  guardianId: Scalars['String'];
}>;


export type VerifyChildCodeMutation = { __typename?: 'Mutation', verifyChildCode?: { __typename?: 'ChildCodeResponseDto', childId: string, childName: string, code: string, createdAt: string, expireAt: string, id: string, verifiedGuardianId: string } | null };

export type LinkChildPageContentQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type LinkChildPageContentQuery = { __typename?: 'Query', listGuardianRelationships?: Array<{ __typename?: 'GuardianRelationshipDto', id: string, childAccount?: { __typename?: 'ChildAccountDto', id: string, avatarImage?: string | null, email?: string | null, name: string, signInMethods: Array<SignInMethod | null>, mobile?: { __typename?: 'Mobile2', countryCode: string, number: string } | null, schoolInfo?: { __typename?: 'SchoolInfo4', grade: SchoolGrade, school?: { __typename?: 'SchoolDto', admissionAge: number, id: string, name: string, regularCourseYear: number, zipcode: string } | null } | null } | null } | null> | null };

export type UnlinkChildDialogContentMutationVariables = Exact<{
  relationshipId: Scalars['String'];
}>;


export type UnlinkChildDialogContentMutation = { __typename?: 'Mutation', unlinkChild?: any | null };

export type EnterpriseMarketingAgreementPageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type EnterpriseMarketingAgreementPageQuery = { __typename?: 'Query', me?: { __typename?: 'Account', id?: string | null, role?: string | null } | null, enterprise?: { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type UpdateChildSchoolInfoMutationVariables = Exact<{
  id: Scalars['String'];
  updateChildSchoolInfoDtoInput: UpdateChildSchoolInfoDtoInput;
}>;


export type UpdateChildSchoolInfoMutation = { __typename?: 'Mutation', updateChildSchoolInfo?: { __typename?: 'AccountDto', schoolInfoDtoForChildren: Array<{ __typename?: 'SchoolInfoDto2', grade: SchoolGrade, school?: { __typename?: 'SchoolDto', id: string, admissionAge: number, name: string, regularCourseYear: number, zipcode: string } | null } | null> } | null };

export type MarkOrgAsPrimaryMutationVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type MarkOrgAsPrimaryMutation = { __typename?: 'Mutation', organizationMarkOrgAsPrimary?: { __typename?: 'OrganizationPrimaryOrgMembershipDto', orgId: string } | null };

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['String'];
  updateAccountDtoInput: UpdateAccountDtoInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount?: { __typename?: 'AccountDto', id: string } | null };

export type PersonalInfoPageQueryVariables = Exact<{
  enterpriseId: Scalars['String'];
}>;


export type PersonalInfoPageQuery = { __typename?: 'Query', enterprise?: { __typename?: 'EnterpriseDto', id: string, name: string, isWhiteLabeled: boolean, homeUrl: string, branding: { __typename?: 'EnterpriseBranding', appIconUrl?: string | null, logoUrl?: string | null, name?: string | null } } | null };

export type GradeInputAccountSchoolFieldsFragment = { __typename?: 'AccountSchool', id: string, name: string, regularCourseYear: number, admissionAge: number };

export type GradeInputOrganizationFieldsFragment = { __typename?: 'OrganizationOrganizationDto', id: string, name: string, regularCourseYear?: number | null, admissionAge?: number | null };

export type OrganizationInputAccountFieldsFragment = { __typename?: 'AccountDto', id: string, grade: SchoolGrade, schoolInfo?: { __typename?: 'SchoolInfo', grade: SchoolGrade, school?: { __typename?: 'AccountSchool', id: string, name: string, regularCourseYear: number, admissionAge: number } | null } | null };

export type OrganizationInputOrganizationFieldsFragment = { __typename?: 'OrganizationOrganizationDto', id: string, name: string, regularCourseYear?: number | null, admissionAge?: number | null };

export type OrganizationInputQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationInputQuery = { __typename?: 'Query', organizations?: Array<{ __typename?: 'OrganizationOrganizationDto', id: string, name: string, regularCourseYear?: number | null, admissionAge?: number | null } | null> | null };

export type SearchSchoolsQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
}>;


export type SearchSchoolsQuery = { __typename?: 'Query', searchSchools?: { __typename?: 'SchoolCollection', schools?: Array<{ __typename?: 'School', id?: string | null, name?: string | null, monthAcademicYearStartsFrom?: number | null, profileImage?: string | null, countryCode?: string | null, country?: string | null, city?: string | null, isCertified?: boolean | null, isActivated?: boolean | null, zipcode?: string | null, latitude?: number | null, longitude?: number | null, firstGrade?: number | null, regularCourseYear?: number | null, admissionAge?: number | null } | null> | null } | null };

export type LinkChildPageQueryVariables = Exact<{ [key: string]: never; }>;


export type LinkChildPageQuery = { __typename?: 'Query', me?: { __typename?: 'Account', id?: string | null, role?: string | null } | null };

export type LinkGuardianPageContentQueryVariables = Exact<{
  guardianId: Scalars['String'];
}>;


export type LinkGuardianPageContentQuery = { __typename?: 'Query', getAccount?: { __typename?: 'SimpleAccountDto', avatarImage?: string | null, description?: string | null, id: string, name?: string | null, profileImage?: string | null, role: AccountRole, username?: string | null } | null };

export type LinkGuardianPageSetGuardianRelationshipMutationVariables = Exact<{
  guardianId: Scalars['String'];
  relationship: RelationshipType;
}>;


export type LinkGuardianPageSetGuardianRelationshipMutation = { __typename?: 'Mutation', linkGuardian?: { __typename?: 'LinkChildResponseDto', id: string, childId: string, createdAt: string, guardianId: string, relationshipType: RelationshipType } | null };

export const EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc = gql`
    fragment EnterpriseAppBarEnterpriseDtoFields on EnterpriseDto {
  id
  name
  isWhiteLabeled
  homeUrl
  branding {
    logoUrl
    name
  }
}
    `;
export const EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragmentDoc = gql`
    fragment EnterpriseSideMenuPageTemplateEnterpriseDtoFields on EnterpriseDto {
  id
  isWhiteLabeled
  branding {
    name
  }
}
    `;
export const EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragmentDoc = gql`
    fragment EnterpriseSingleColumnPageTemplateEnterpriseDtoFields on EnterpriseDto {
  id
  isWhiteLabeled
  branding {
    appIconUrl
    logoUrl
    name
  }
}
    `;
export const GradeInputAccountSchoolFieldsFragmentDoc = gql`
    fragment GradeInputAccountSchoolFields on AccountSchool {
  id
  name
  regularCourseYear
  admissionAge
}
    `;
export const OrganizationInputAccountFieldsFragmentDoc = gql`
    fragment OrganizationInputAccountFields on AccountDto {
  id
  grade
  schoolInfo {
    grade
    school {
      id
      name
      ...GradeInputAccountSchoolFields
    }
  }
}
    ${GradeInputAccountSchoolFieldsFragmentDoc}`;
export const GradeInputOrganizationFieldsFragmentDoc = gql`
    fragment GradeInputOrganizationFields on OrganizationOrganizationDto {
  id
  name
  regularCourseYear
  admissionAge
}
    `;
export const OrganizationInputOrganizationFieldsFragmentDoc = gql`
    fragment OrganizationInputOrganizationFields on OrganizationOrganizationDto {
  id
  name
  ...GradeInputOrganizationFields
}
    ${GradeInputOrganizationFieldsFragmentDoc}`;
export const EnterpriseChangeEmailPageDocument = gql`
    query EnterpriseChangeEmailPage($enterpriseId: String!) {
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    ...EnterpriseSingleColumnPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useEnterpriseChangeEmailPageQuery__
 *
 * To run a query within a React component, call `useEnterpriseChangeEmailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseChangeEmailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseChangeEmailPageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useEnterpriseChangeEmailPageQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseChangeEmailPageQuery, EnterpriseChangeEmailPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseChangeEmailPageQuery, EnterpriseChangeEmailPageQueryVariables>(EnterpriseChangeEmailPageDocument, options);
      }
export function useEnterpriseChangeEmailPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseChangeEmailPageQuery, EnterpriseChangeEmailPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseChangeEmailPageQuery, EnterpriseChangeEmailPageQueryVariables>(EnterpriseChangeEmailPageDocument, options);
        }
export type EnterpriseChangeEmailPageQueryHookResult = ReturnType<typeof useEnterpriseChangeEmailPageQuery>;
export type EnterpriseChangeEmailPageLazyQueryHookResult = ReturnType<typeof useEnterpriseChangeEmailPageLazyQuery>;
export type EnterpriseChangeEmailPageQueryResult = Apollo.QueryResult<EnterpriseChangeEmailPageQuery, EnterpriseChangeEmailPageQueryVariables>;
export const EnterpriseChangeMobilePageDocument = gql`
    query EnterpriseChangeMobilePage($enterpriseId: String!) {
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    ...EnterpriseSingleColumnPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useEnterpriseChangeMobilePageQuery__
 *
 * To run a query within a React component, call `useEnterpriseChangeMobilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseChangeMobilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseChangeMobilePageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useEnterpriseChangeMobilePageQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseChangeMobilePageQuery, EnterpriseChangeMobilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseChangeMobilePageQuery, EnterpriseChangeMobilePageQueryVariables>(EnterpriseChangeMobilePageDocument, options);
      }
export function useEnterpriseChangeMobilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseChangeMobilePageQuery, EnterpriseChangeMobilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseChangeMobilePageQuery, EnterpriseChangeMobilePageQueryVariables>(EnterpriseChangeMobilePageDocument, options);
        }
export type EnterpriseChangeMobilePageQueryHookResult = ReturnType<typeof useEnterpriseChangeMobilePageQuery>;
export type EnterpriseChangeMobilePageLazyQueryHookResult = ReturnType<typeof useEnterpriseChangeMobilePageLazyQuery>;
export type EnterpriseChangeMobilePageQueryResult = Apollo.QueryResult<EnterpriseChangeMobilePageQuery, EnterpriseChangeMobilePageQueryVariables>;
export const EnterpriseDeleteConfirmPageDocument = gql`
    query EnterpriseDeleteConfirmPage($enterpriseId: String!) {
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    id
    ...EnterpriseAppBarEnterpriseDtoFields
    ...EnterpriseSingleColumnPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc}
${EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useEnterpriseDeleteConfirmPageQuery__
 *
 * To run a query within a React component, call `useEnterpriseDeleteConfirmPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseDeleteConfirmPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseDeleteConfirmPageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useEnterpriseDeleteConfirmPageQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseDeleteConfirmPageQuery, EnterpriseDeleteConfirmPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseDeleteConfirmPageQuery, EnterpriseDeleteConfirmPageQueryVariables>(EnterpriseDeleteConfirmPageDocument, options);
      }
export function useEnterpriseDeleteConfirmPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseDeleteConfirmPageQuery, EnterpriseDeleteConfirmPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseDeleteConfirmPageQuery, EnterpriseDeleteConfirmPageQueryVariables>(EnterpriseDeleteConfirmPageDocument, options);
        }
export type EnterpriseDeleteConfirmPageQueryHookResult = ReturnType<typeof useEnterpriseDeleteConfirmPageQuery>;
export type EnterpriseDeleteConfirmPageLazyQueryHookResult = ReturnType<typeof useEnterpriseDeleteConfirmPageLazyQuery>;
export type EnterpriseDeleteConfirmPageQueryResult = Apollo.QueryResult<EnterpriseDeleteConfirmPageQuery, EnterpriseDeleteConfirmPageQueryVariables>;
export const AccountInfoPageDocument = gql`
    query AccountInfoPage($enterpriseId: String!) {
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    id
    branding {
      appIconUrl
    }
    ...EnterpriseAppBarEnterpriseDtoFields
    ...EnterpriseSideMenuPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc}
${EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useAccountInfoPageQuery__
 *
 * To run a query within a React component, call `useAccountInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountInfoPageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useAccountInfoPageQuery(baseOptions: Apollo.QueryHookOptions<AccountInfoPageQuery, AccountInfoPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountInfoPageQuery, AccountInfoPageQueryVariables>(AccountInfoPageDocument, options);
      }
export function useAccountInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountInfoPageQuery, AccountInfoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountInfoPageQuery, AccountInfoPageQueryVariables>(AccountInfoPageDocument, options);
        }
export type AccountInfoPageQueryHookResult = ReturnType<typeof useAccountInfoPageQuery>;
export type AccountInfoPageLazyQueryHookResult = ReturnType<typeof useAccountInfoPageLazyQuery>;
export type AccountInfoPageQueryResult = Apollo.QueryResult<AccountInfoPageQuery, AccountInfoPageQueryVariables>;
export const ChildCodePageDocument = gql`
    query ChildCodePage($enterpriseId: String!) {
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    id
    branding {
      appIconUrl
    }
    ...EnterpriseAppBarEnterpriseDtoFields
    ...EnterpriseSideMenuPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc}
${EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useChildCodePageQuery__
 *
 * To run a query within a React component, call `useChildCodePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildCodePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildCodePageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useChildCodePageQuery(baseOptions: Apollo.QueryHookOptions<ChildCodePageQuery, ChildCodePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildCodePageQuery, ChildCodePageQueryVariables>(ChildCodePageDocument, options);
      }
export function useChildCodePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildCodePageQuery, ChildCodePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildCodePageQuery, ChildCodePageQueryVariables>(ChildCodePageDocument, options);
        }
export type ChildCodePageQueryHookResult = ReturnType<typeof useChildCodePageQuery>;
export type ChildCodePageLazyQueryHookResult = ReturnType<typeof useChildCodePageLazyQuery>;
export type ChildCodePageQueryResult = Apollo.QueryResult<ChildCodePageQuery, ChildCodePageQueryVariables>;
export const CreateChildCodeDocument = gql`
    mutation CreateChildCode($id: String!) {
  createChildCode(id: $id) {
    childId
    code
    expireAt
  }
}
    `;
export type CreateChildCodeMutationFn = Apollo.MutationFunction<CreateChildCodeMutation, CreateChildCodeMutationVariables>;

/**
 * __useCreateChildCodeMutation__
 *
 * To run a mutation, you first call `useCreateChildCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChildCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChildCodeMutation, { data, loading, error }] = useCreateChildCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateChildCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateChildCodeMutation, CreateChildCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChildCodeMutation, CreateChildCodeMutationVariables>(CreateChildCodeDocument, options);
      }
export type CreateChildCodeMutationHookResult = ReturnType<typeof useCreateChildCodeMutation>;
export type CreateChildCodeMutationResult = Apollo.MutationResult<CreateChildCodeMutation>;
export type CreateChildCodeMutationOptions = Apollo.BaseMutationOptions<CreateChildCodeMutation, CreateChildCodeMutationVariables>;
export const ChildCodePageContentDocument = gql`
    query ChildCodePageContent($limit: Int) {
  listGuardianRelationships(type: GUARDIAN, limit: $limit) {
    guardianAccount {
      id
      avatarImage
      name
    }
    relationshipType
  }
}
    `;

/**
 * __useChildCodePageContentQuery__
 *
 * To run a query within a React component, call `useChildCodePageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useChildCodePageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChildCodePageContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChildCodePageContentQuery(baseOptions?: Apollo.QueryHookOptions<ChildCodePageContentQuery, ChildCodePageContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChildCodePageContentQuery, ChildCodePageContentQueryVariables>(ChildCodePageContentDocument, options);
      }
export function useChildCodePageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChildCodePageContentQuery, ChildCodePageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChildCodePageContentQuery, ChildCodePageContentQueryVariables>(ChildCodePageContentDocument, options);
        }
export type ChildCodePageContentQueryHookResult = ReturnType<typeof useChildCodePageContentQuery>;
export type ChildCodePageContentLazyQueryHookResult = ReturnType<typeof useChildCodePageContentLazyQuery>;
export type ChildCodePageContentQueryResult = Apollo.QueryResult<ChildCodePageContentQuery, ChildCodePageContentQueryVariables>;
export const EnterpriseLinkChildPageDocument = gql`
    query EnterpriseLinkChildPage($enterpriseId: String!) {
  me {
    id
    role
  }
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    id
    branding {
      appIconUrl
    }
    ...EnterpriseAppBarEnterpriseDtoFields
    ...EnterpriseSideMenuPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc}
${EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useEnterpriseLinkChildPageQuery__
 *
 * To run a query within a React component, call `useEnterpriseLinkChildPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseLinkChildPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseLinkChildPageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useEnterpriseLinkChildPageQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseLinkChildPageQuery, EnterpriseLinkChildPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseLinkChildPageQuery, EnterpriseLinkChildPageQueryVariables>(EnterpriseLinkChildPageDocument, options);
      }
export function useEnterpriseLinkChildPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseLinkChildPageQuery, EnterpriseLinkChildPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseLinkChildPageQuery, EnterpriseLinkChildPageQueryVariables>(EnterpriseLinkChildPageDocument, options);
        }
export type EnterpriseLinkChildPageQueryHookResult = ReturnType<typeof useEnterpriseLinkChildPageQuery>;
export type EnterpriseLinkChildPageLazyQueryHookResult = ReturnType<typeof useEnterpriseLinkChildPageLazyQuery>;
export type EnterpriseLinkChildPageQueryResult = Apollo.QueryResult<EnterpriseLinkChildPageQuery, EnterpriseLinkChildPageQueryVariables>;
export const SetGuardianRelationshipDocument = gql`
    mutation SetGuardianRelationship($childId: String!, $relationship: RelationshipType!) {
  linkChild(
    linkChildRequestDtoInput: {childId: $childId, relationshipType: $relationship}
  ) {
    id
    childId
    createdAt
    guardianId
    relationshipType
  }
}
    `;
export type SetGuardianRelationshipMutationFn = Apollo.MutationFunction<SetGuardianRelationshipMutation, SetGuardianRelationshipMutationVariables>;

/**
 * __useSetGuardianRelationshipMutation__
 *
 * To run a mutation, you first call `useSetGuardianRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGuardianRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGuardianRelationshipMutation, { data, loading, error }] = useSetGuardianRelationshipMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      relationship: // value for 'relationship'
 *   },
 * });
 */
export function useSetGuardianRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<SetGuardianRelationshipMutation, SetGuardianRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGuardianRelationshipMutation, SetGuardianRelationshipMutationVariables>(SetGuardianRelationshipDocument, options);
      }
export type SetGuardianRelationshipMutationHookResult = ReturnType<typeof useSetGuardianRelationshipMutation>;
export type SetGuardianRelationshipMutationResult = Apollo.MutationResult<SetGuardianRelationshipMutation>;
export type SetGuardianRelationshipMutationOptions = Apollo.BaseMutationOptions<SetGuardianRelationshipMutation, SetGuardianRelationshipMutationVariables>;
export const VerifyChildCodeDocument = gql`
    mutation VerifyChildCode($code: String!, $guardianId: String!) {
  verifyChildCode(code: $code, id: $guardianId) {
    childId
    childName
    code
    createdAt
    expireAt
    id
    verifiedGuardianId
  }
}
    `;
export type VerifyChildCodeMutationFn = Apollo.MutationFunction<VerifyChildCodeMutation, VerifyChildCodeMutationVariables>;

/**
 * __useVerifyChildCodeMutation__
 *
 * To run a mutation, you first call `useVerifyChildCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyChildCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyChildCodeMutation, { data, loading, error }] = useVerifyChildCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      guardianId: // value for 'guardianId'
 *   },
 * });
 */
export function useVerifyChildCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyChildCodeMutation, VerifyChildCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyChildCodeMutation, VerifyChildCodeMutationVariables>(VerifyChildCodeDocument, options);
      }
export type VerifyChildCodeMutationHookResult = ReturnType<typeof useVerifyChildCodeMutation>;
export type VerifyChildCodeMutationResult = Apollo.MutationResult<VerifyChildCodeMutation>;
export type VerifyChildCodeMutationOptions = Apollo.BaseMutationOptions<VerifyChildCodeMutation, VerifyChildCodeMutationVariables>;
export const LinkChildPageContentDocument = gql`
    query LinkChildPageContent($limit: Int) {
  listGuardianRelationships(type: CHILD, limit: $limit) {
    childAccount {
      id
      avatarImage
      email
      mobile {
        countryCode
        number
      }
      name
      schoolInfo {
        grade
        school {
          admissionAge
          id
          name
          regularCourseYear
          zipcode
        }
      }
      signInMethods
    }
    id
  }
}
    `;

/**
 * __useLinkChildPageContentQuery__
 *
 * To run a query within a React component, call `useLinkChildPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkChildPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkChildPageContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLinkChildPageContentQuery(baseOptions?: Apollo.QueryHookOptions<LinkChildPageContentQuery, LinkChildPageContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkChildPageContentQuery, LinkChildPageContentQueryVariables>(LinkChildPageContentDocument, options);
      }
export function useLinkChildPageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkChildPageContentQuery, LinkChildPageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkChildPageContentQuery, LinkChildPageContentQueryVariables>(LinkChildPageContentDocument, options);
        }
export type LinkChildPageContentQueryHookResult = ReturnType<typeof useLinkChildPageContentQuery>;
export type LinkChildPageContentLazyQueryHookResult = ReturnType<typeof useLinkChildPageContentLazyQuery>;
export type LinkChildPageContentQueryResult = Apollo.QueryResult<LinkChildPageContentQuery, LinkChildPageContentQueryVariables>;
export const UnlinkChildDialogContentDocument = gql`
    mutation UnlinkChildDialogContent($relationshipId: String!) {
  unlinkChild(id: $relationshipId)
}
    `;
export type UnlinkChildDialogContentMutationFn = Apollo.MutationFunction<UnlinkChildDialogContentMutation, UnlinkChildDialogContentMutationVariables>;

/**
 * __useUnlinkChildDialogContentMutation__
 *
 * To run a mutation, you first call `useUnlinkChildDialogContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkChildDialogContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkChildDialogContentMutation, { data, loading, error }] = useUnlinkChildDialogContentMutation({
 *   variables: {
 *      relationshipId: // value for 'relationshipId'
 *   },
 * });
 */
export function useUnlinkChildDialogContentMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkChildDialogContentMutation, UnlinkChildDialogContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkChildDialogContentMutation, UnlinkChildDialogContentMutationVariables>(UnlinkChildDialogContentDocument, options);
      }
export type UnlinkChildDialogContentMutationHookResult = ReturnType<typeof useUnlinkChildDialogContentMutation>;
export type UnlinkChildDialogContentMutationResult = Apollo.MutationResult<UnlinkChildDialogContentMutation>;
export type UnlinkChildDialogContentMutationOptions = Apollo.BaseMutationOptions<UnlinkChildDialogContentMutation, UnlinkChildDialogContentMutationVariables>;
export const EnterpriseMarketingAgreementPageDocument = gql`
    query EnterpriseMarketingAgreementPage($enterpriseId: String!) {
  me {
    id
    role
  }
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    id
    branding {
      appIconUrl
    }
    ...EnterpriseAppBarEnterpriseDtoFields
    ...EnterpriseSideMenuPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc}
${EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __useEnterpriseMarketingAgreementPageQuery__
 *
 * To run a query within a React component, call `useEnterpriseMarketingAgreementPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseMarketingAgreementPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseMarketingAgreementPageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function useEnterpriseMarketingAgreementPageQuery(baseOptions: Apollo.QueryHookOptions<EnterpriseMarketingAgreementPageQuery, EnterpriseMarketingAgreementPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnterpriseMarketingAgreementPageQuery, EnterpriseMarketingAgreementPageQueryVariables>(EnterpriseMarketingAgreementPageDocument, options);
      }
export function useEnterpriseMarketingAgreementPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnterpriseMarketingAgreementPageQuery, EnterpriseMarketingAgreementPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnterpriseMarketingAgreementPageQuery, EnterpriseMarketingAgreementPageQueryVariables>(EnterpriseMarketingAgreementPageDocument, options);
        }
export type EnterpriseMarketingAgreementPageQueryHookResult = ReturnType<typeof useEnterpriseMarketingAgreementPageQuery>;
export type EnterpriseMarketingAgreementPageLazyQueryHookResult = ReturnType<typeof useEnterpriseMarketingAgreementPageLazyQuery>;
export type EnterpriseMarketingAgreementPageQueryResult = Apollo.QueryResult<EnterpriseMarketingAgreementPageQuery, EnterpriseMarketingAgreementPageQueryVariables>;
export const UpdateChildSchoolInfoDocument = gql`
    mutation UpdateChildSchoolInfo($id: String!, $updateChildSchoolInfoDtoInput: UpdateChildSchoolInfoDtoInput!) {
  updateChildSchoolInfo(
    id: $id
    updateChildSchoolInfoDtoInput: $updateChildSchoolInfoDtoInput
  ) {
    schoolInfoDtoForChildren {
      grade
      school {
        id
        admissionAge
        name
        regularCourseYear
        zipcode
      }
    }
  }
}
    `;
export type UpdateChildSchoolInfoMutationFn = Apollo.MutationFunction<UpdateChildSchoolInfoMutation, UpdateChildSchoolInfoMutationVariables>;

/**
 * __useUpdateChildSchoolInfoMutation__
 *
 * To run a mutation, you first call `useUpdateChildSchoolInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChildSchoolInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChildSchoolInfoMutation, { data, loading, error }] = useUpdateChildSchoolInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateChildSchoolInfoDtoInput: // value for 'updateChildSchoolInfoDtoInput'
 *   },
 * });
 */
export function useUpdateChildSchoolInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChildSchoolInfoMutation, UpdateChildSchoolInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChildSchoolInfoMutation, UpdateChildSchoolInfoMutationVariables>(UpdateChildSchoolInfoDocument, options);
      }
export type UpdateChildSchoolInfoMutationHookResult = ReturnType<typeof useUpdateChildSchoolInfoMutation>;
export type UpdateChildSchoolInfoMutationResult = Apollo.MutationResult<UpdateChildSchoolInfoMutation>;
export type UpdateChildSchoolInfoMutationOptions = Apollo.BaseMutationOptions<UpdateChildSchoolInfoMutation, UpdateChildSchoolInfoMutationVariables>;
export const MarkOrgAsPrimaryDocument = gql`
    mutation MarkOrgAsPrimary($orgId: String!) {
  organizationMarkOrgAsPrimary(orgId: $orgId) {
    orgId
  }
}
    `;
export type MarkOrgAsPrimaryMutationFn = Apollo.MutationFunction<MarkOrgAsPrimaryMutation, MarkOrgAsPrimaryMutationVariables>;

/**
 * __useMarkOrgAsPrimaryMutation__
 *
 * To run a mutation, you first call `useMarkOrgAsPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOrgAsPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOrgAsPrimaryMutation, { data, loading, error }] = useMarkOrgAsPrimaryMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useMarkOrgAsPrimaryMutation(baseOptions?: Apollo.MutationHookOptions<MarkOrgAsPrimaryMutation, MarkOrgAsPrimaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkOrgAsPrimaryMutation, MarkOrgAsPrimaryMutationVariables>(MarkOrgAsPrimaryDocument, options);
      }
export type MarkOrgAsPrimaryMutationHookResult = ReturnType<typeof useMarkOrgAsPrimaryMutation>;
export type MarkOrgAsPrimaryMutationResult = Apollo.MutationResult<MarkOrgAsPrimaryMutation>;
export type MarkOrgAsPrimaryMutationOptions = Apollo.BaseMutationOptions<MarkOrgAsPrimaryMutation, MarkOrgAsPrimaryMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($id: String!, $updateAccountDtoInput: UpdateAccountDtoInput!) {
  updateAccount(id: $id, updateAccountDtoInput: $updateAccountDtoInput) {
    id
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateAccountDtoInput: // value for 'updateAccountDtoInput'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const PersonalInfoPageDocument = gql`
    query PersonalInfoPage($enterpriseId: String!) {
  enterprise: enterprise(enterpriseId: $enterpriseId) {
    id
    branding {
      appIconUrl
    }
    ...EnterpriseAppBarEnterpriseDtoFields
    ...EnterpriseSideMenuPageTemplateEnterpriseDtoFields
  }
}
    ${EnterpriseAppBarEnterpriseDtoFieldsFragmentDoc}
${EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragmentDoc}`;

/**
 * __usePersonalInfoPageQuery__
 *
 * To run a query within a React component, call `usePersonalInfoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalInfoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalInfoPageQuery({
 *   variables: {
 *      enterpriseId: // value for 'enterpriseId'
 *   },
 * });
 */
export function usePersonalInfoPageQuery(baseOptions: Apollo.QueryHookOptions<PersonalInfoPageQuery, PersonalInfoPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalInfoPageQuery, PersonalInfoPageQueryVariables>(PersonalInfoPageDocument, options);
      }
export function usePersonalInfoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalInfoPageQuery, PersonalInfoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalInfoPageQuery, PersonalInfoPageQueryVariables>(PersonalInfoPageDocument, options);
        }
export type PersonalInfoPageQueryHookResult = ReturnType<typeof usePersonalInfoPageQuery>;
export type PersonalInfoPageLazyQueryHookResult = ReturnType<typeof usePersonalInfoPageLazyQuery>;
export type PersonalInfoPageQueryResult = Apollo.QueryResult<PersonalInfoPageQuery, PersonalInfoPageQueryVariables>;
export const OrganizationInputDocument = gql`
    query OrganizationInput {
  organizations: organizationOrgs {
    ...OrganizationInputOrganizationFields
  }
}
    ${OrganizationInputOrganizationFieldsFragmentDoc}`;

/**
 * __useOrganizationInputQuery__
 *
 * To run a query within a React component, call `useOrganizationInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationInputQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationInputQuery, OrganizationInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationInputQuery, OrganizationInputQueryVariables>(OrganizationInputDocument, options);
      }
export function useOrganizationInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInputQuery, OrganizationInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationInputQuery, OrganizationInputQueryVariables>(OrganizationInputDocument, options);
        }
export type OrganizationInputQueryHookResult = ReturnType<typeof useOrganizationInputQuery>;
export type OrganizationInputLazyQueryHookResult = ReturnType<typeof useOrganizationInputLazyQuery>;
export type OrganizationInputQueryResult = Apollo.QueryResult<OrganizationInputQuery, OrganizationInputQueryVariables>;
export const SearchSchoolsDocument = gql`
    query SearchSchools($q: String, $offset: Float, $limit: Float) {
  searchSchools(q: $q, offset: $offset, limit: $limit) {
    schools {
      id
      name
      monthAcademicYearStartsFrom
      profileImage
      countryCode
      country
      city
      isCertified
      isActivated
      zipcode
      latitude
      longitude
      firstGrade
      regularCourseYear
      admissionAge
    }
  }
}
    `;

/**
 * __useSearchSchoolsQuery__
 *
 * To run a query within a React component, call `useSearchSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSchoolsQuery({
 *   variables: {
 *      q: // value for 'q'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SearchSchoolsQuery, SearchSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSchoolsQuery, SearchSchoolsQueryVariables>(SearchSchoolsDocument, options);
      }
export function useSearchSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSchoolsQuery, SearchSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSchoolsQuery, SearchSchoolsQueryVariables>(SearchSchoolsDocument, options);
        }
export type SearchSchoolsQueryHookResult = ReturnType<typeof useSearchSchoolsQuery>;
export type SearchSchoolsLazyQueryHookResult = ReturnType<typeof useSearchSchoolsLazyQuery>;
export type SearchSchoolsQueryResult = Apollo.QueryResult<SearchSchoolsQuery, SearchSchoolsQueryVariables>;
export const LinkChildPageDocument = gql`
    query LinkChildPage {
  me {
    id
    role
  }
}
    `;

/**
 * __useLinkChildPageQuery__
 *
 * To run a query within a React component, call `useLinkChildPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkChildPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkChildPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkChildPageQuery(baseOptions?: Apollo.QueryHookOptions<LinkChildPageQuery, LinkChildPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkChildPageQuery, LinkChildPageQueryVariables>(LinkChildPageDocument, options);
      }
export function useLinkChildPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkChildPageQuery, LinkChildPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkChildPageQuery, LinkChildPageQueryVariables>(LinkChildPageDocument, options);
        }
export type LinkChildPageQueryHookResult = ReturnType<typeof useLinkChildPageQuery>;
export type LinkChildPageLazyQueryHookResult = ReturnType<typeof useLinkChildPageLazyQuery>;
export type LinkChildPageQueryResult = Apollo.QueryResult<LinkChildPageQuery, LinkChildPageQueryVariables>;
export const LinkGuardianPageContentDocument = gql`
    query LinkGuardianPageContent($guardianId: String!) {
  getAccount(id: $guardianId) {
    avatarImage
    description
    id
    name
    profileImage
    role
    username
  }
}
    `;

/**
 * __useLinkGuardianPageContentQuery__
 *
 * To run a query within a React component, call `useLinkGuardianPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkGuardianPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkGuardianPageContentQuery({
 *   variables: {
 *      guardianId: // value for 'guardianId'
 *   },
 * });
 */
export function useLinkGuardianPageContentQuery(baseOptions: Apollo.QueryHookOptions<LinkGuardianPageContentQuery, LinkGuardianPageContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkGuardianPageContentQuery, LinkGuardianPageContentQueryVariables>(LinkGuardianPageContentDocument, options);
      }
export function useLinkGuardianPageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkGuardianPageContentQuery, LinkGuardianPageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkGuardianPageContentQuery, LinkGuardianPageContentQueryVariables>(LinkGuardianPageContentDocument, options);
        }
export type LinkGuardianPageContentQueryHookResult = ReturnType<typeof useLinkGuardianPageContentQuery>;
export type LinkGuardianPageContentLazyQueryHookResult = ReturnType<typeof useLinkGuardianPageContentLazyQuery>;
export type LinkGuardianPageContentQueryResult = Apollo.QueryResult<LinkGuardianPageContentQuery, LinkGuardianPageContentQueryVariables>;
export const LinkGuardianPageSetGuardianRelationshipDocument = gql`
    mutation LinkGuardianPageSetGuardianRelationship($guardianId: String!, $relationship: RelationshipType!) {
  linkGuardian(
    linkGuardianRequestDtoInput: {guardianId: $guardianId, relationshipType: $relationship}
  ) {
    id
    childId
    createdAt
    guardianId
    relationshipType
  }
}
    `;
export type LinkGuardianPageSetGuardianRelationshipMutationFn = Apollo.MutationFunction<LinkGuardianPageSetGuardianRelationshipMutation, LinkGuardianPageSetGuardianRelationshipMutationVariables>;

/**
 * __useLinkGuardianPageSetGuardianRelationshipMutation__
 *
 * To run a mutation, you first call `useLinkGuardianPageSetGuardianRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkGuardianPageSetGuardianRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkGuardianPageSetGuardianRelationshipMutation, { data, loading, error }] = useLinkGuardianPageSetGuardianRelationshipMutation({
 *   variables: {
 *      guardianId: // value for 'guardianId'
 *      relationship: // value for 'relationship'
 *   },
 * });
 */
export function useLinkGuardianPageSetGuardianRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<LinkGuardianPageSetGuardianRelationshipMutation, LinkGuardianPageSetGuardianRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkGuardianPageSetGuardianRelationshipMutation, LinkGuardianPageSetGuardianRelationshipMutationVariables>(LinkGuardianPageSetGuardianRelationshipDocument, options);
      }
export type LinkGuardianPageSetGuardianRelationshipMutationHookResult = ReturnType<typeof useLinkGuardianPageSetGuardianRelationshipMutation>;
export type LinkGuardianPageSetGuardianRelationshipMutationResult = Apollo.MutationResult<LinkGuardianPageSetGuardianRelationshipMutation>;
export type LinkGuardianPageSetGuardianRelationshipMutationOptions = Apollo.BaseMutationOptions<LinkGuardianPageSetGuardianRelationshipMutation, LinkGuardianPageSetGuardianRelationshipMutationVariables>;