import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';

import useTranslatedTexts from '../../../../useTranslatedTexts';

import { Box } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

interface ChildInfoButtonProps {
  onOpenDialogRequest: () => void;
  event: TagManagerEventDefinition;
}

const ChildInfoButton = ({
  onOpenDialogRequest: handleOpenDialogRequest,
  event,
}: ChildInfoButtonProps): JSX.Element => {
  const texts = useTranslatedTexts();

  return (
    <Button
      data-testid="child-info-button"
      fullWidth
      appearance="outlined"
      className="border-gray-300"
      theme="white"
      onClick={handleOpenDialogRequest}
      {...createTrackEventProps(event)}
    >
      <Box>{texts.button.accountInfo}</Box>
    </Button>
  );
};

export default ChildInfoButton;
