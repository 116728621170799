/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

import { RelationshipType } from 'generated/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const title = {
    confirmLink: t('account.link_guardian.confirm_link.title'),
    setRelationship: (name: string) =>
      t('account.link_guardian.set_relationship.title', { name }),
    linkFailed: t('account.link_guardian.link_failed.title'),
  };

  const description = {
    confirmLink: t('account.link_guardian.confirm_link.description'),
  };

  const button = {
    link: t('account.link_guardian.set_relationship.button.link'),
    next: t('account.button.next'),
    cancel: t('account.button.cancel'),
    confirm: t('account.button.confirm'),
  };

  const message = {
    success: t('account.link_guardian.link_success.message'),
    invalidRole: t('account.link_guardian.link_failed.message.invalid_role'),
    connectionError: t(
      'account.link_guardian.link_failed.message.connection_error'
    ),
  };

  const option = {
    [RelationshipType.Father]: t('account.item.relationship.father'),
    [RelationshipType.GrandFather]: t('account.item.relationship.grand_father'),
    [RelationshipType.GrandMother]: t('account.item.relationship.grand_mother'),
    [RelationshipType.Guardian]: t('account.item.relationship.guardian'),
    [RelationshipType.Mother]: t('account.item.relationship.mother'),
  };

  return {
    title,
    description,
    message,
    button,
    option,
  };
};

export default useTranslatedTexts;
