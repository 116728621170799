/* istanbul ignore file */

import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const changeEmail = {
    title: t('account.change_email.title'),
    description: t('account.change_email.description'),
  };

  const title = {
    failedToRecieveEmail: t('account.error.title.failed_to_recieve_email'),
  };

  const button = {
    change: t('account.change_email.button.change'),
    verifyEmail: t('account.button.verify_email'),
    failedToReceiveEmail: t(
      'account.email_sign_up.button.failed_to_recieve_email'
    ),
    close: t('account.button.close'),
    resendEmailCode: t('account.button.resend_email_code'),
  };

  const message = {
    sendEmailCode: t('account.message.send_email_code'),
    successChange: t('account.message.success_change'),
    invalidEmail: t('account.error.message.invalid_email'),
    alreadyUsingEmail: t('account.error.message.already_using_email'),
    alreadyOccupiedEmail: t('account.error.message.already_occupied_email'),
    temporaryError: t('account.error.message.temporary_error'),
    notMatchedEmailCode: t('account.error.message.not_matched_email_code'),
    expiredEmailCode: t('account.error.message.expired_email_code'),
    failedToReceiveEmail: t('account.error.message.failed_to_receive_email'),
  };

  const label = {
    newEmail: t('account.label.new_email'),
    emailCode: t('account.label.email_code'),
  };

  const placeholder = {
    email: t('account.placeholder.email'),
    emailCode: t('account.placeholder.email_code'),
  };

  return { changeEmail, title, button, label, placeholder, message };
};

export default useTranslatedTexts;
