import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import { useTranslation } from 'react-i18next';

import { LEGACY_CLASSTING_URL } from 'shared/constants';

const StudentCodePageContent = (): JSX.Element => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <IframeResizer
      className="w-full border-0 m-0 p-0"
      heightCalculationMethod="bodyScroll"
      src={`${LEGACY_CLASSTING_URL}/settings/student-codes?navbar=0&lng=${language}`}
    />
  );
};

export default StudentCodePageContent;
