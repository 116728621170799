import React from 'react';

import { Dialog, HoverDialogContent } from 'designSystem/molecules';

import { Box } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

interface DialogContentTemplateProps {
  title: string;
  description: string;
  cancelButton?: {
    text: string;
    onClick: () => void;
    event?: TagManagerEventDefinition;
  };
  confirmButton?: {
    active: boolean;
    text: string;
    onClick: () => void;
    event?: TagManagerEventDefinition;
  };
  onCloseClick?: () => void;
  testId?: string;
}

const DialogContentTemplate: React.FC<DialogContentTemplateProps> = ({
  title,
  description,
  cancelButton,
  confirmButton,
  onCloseClick,
  children,
  testId,
}): JSX.Element => {
  const handleConfirmClick = () => {
    if (!confirmButton?.active) {
      // istanbul ignore next
      return;
    }

    confirmButton.onClick();
  };

  const cancelButtonTrackEventProps =
    cancelButton?.event && createTrackEventProps(cancelButton.event);

  const confirmButtonTrackEventProps =
    confirmButton?.event && createTrackEventProps(confirmButton.event);

  return (
    <HoverDialogContent
      testId={testId}
      title={title}
      description={description}
      onCloseClick={onCloseClick}
    >
      {children}
      <Box className="flex flex-row gap-4 justify-end">
        {cancelButton && (
          <Button
            data-testid="dialog-content-template-cancel-button"
            theme="white"
            appearance="text"
            onClick={cancelButton.onClick}
            {...cancelButtonTrackEventProps}
          >
            {cancelButton.text}
          </Button>
        )}
        {confirmButton && (
          <Button
            data-testid={`dialog-content-template-confirm-button--${
              confirmButton.active ? 'active' : 'inactive'
            }`}
            appearance="contained"
            opacity={confirmButton.active ? 1 : 0.5}
            onClick={handleConfirmClick}
            {...confirmButtonTrackEventProps}
          >
            {confirmButton.text}
          </Button>
        )}
      </Box>
    </HoverDialogContent>
  );
};

// Event 처리에 대해 간단히 처리하기 위해 부득이 이런식으로 처리 함(frontend 개발자가 없음)
// TODO: template을 없애고 각각 Dialog를 만드는 것이 필요
const SelectLinkTemplate: React.FC<DialogContentTemplateProps> = ({
  title,
  description,
  cancelButton,
  onCloseClick,
  children,
  testId,
}): JSX.Element => {
  const cancelButtonTrackEventProps =
    cancelButton?.event && createTrackEventProps(cancelButton.event);

  return (
    <HoverDialogContent
      testId={testId}
      title={title}
      description={description}
      onCloseClick={onCloseClick}
    >
      {children}
      <Box className="flex flex-row gap-4 justify-end">
        {cancelButton && (
          <Button
            data-testid="dialog-content-template-cancel-button"
            theme="white"
            appearance="text"
            onClick={cancelButton.onClick}
            {...cancelButtonTrackEventProps}
          >
            {cancelButton.text}
          </Button>
        )}
      </Box>
    </HoverDialogContent>
  );
};

export { DialogContentTemplate, SelectLinkTemplate };
