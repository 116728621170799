import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const title = t('account.link_child.by_url.title');
  const shareButton = t('account.link_child.by_url.button.share_url');
  const copyButton = t('account.link_child.by_url.button.copy_url');

  const guide01 = t('account.link_child.by_url.guide_01');
  const guide02 = t('account.link_child.by_url.guide_02');

  const guide02Description = t(
    'account.link_child.by_url.guide_02_description'
  );

  const linkCopied = t('account.link_child.by_url.message.link_copied');

  const share = {
    message: (name: string, link: string) =>
      t('account.link_child.by_url.share_message', { name, link }),
  };

  return {
    title,
    shareButton,
    copyButton,
    guide01,
    guide02,
    guide02Description,
    linkCopied,
    share,
  };
};

export default useTranslatedTexts;
