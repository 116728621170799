/* istanbul ignore file */
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { getCredentialAccountId } from 'core/credential/credential.service';

function GoogleTagManager(): null {
  const userId = getCredentialAccountId();
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    try {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
      });

      setInitialized(true);
    } catch (e) {
      // do nothing
    }
  }, []);

  useEffect(() => {
    if (initialized && userId) {
      TagManager.dataLayer({
        dataLayer: {
          userId,
        },
      });
    }
  }, [initialized, userId]);

  return null;
}

export default GoogleTagManager;
