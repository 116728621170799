import React from 'react';

import { Box } from 'designSystem/ions';

import { WithChildrenProp } from 'types/utilities';

type LabelProps = WithChildrenProp<unknown>;

const Label = ({ children }: LabelProps): JSX.Element => {
  return (
    <Box is="label" className="text-gray-600 text-body-3 font-medium">
      {children}
    </Box>
  );
};

export default Label;
