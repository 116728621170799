import randomstring from 'randomstring';

import { BridgeMessageBuilder } from './BridgeMessageBuilder';

export interface AuthToken {
  token_type: string;
  access_token: string;
  id_token: string;
  expires_in: string;
}

export type WebViewTitleType = {
  header: {
    title: string;
  };
};

class WebViewBridgeCallbackFailedError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message ?? 'webview bridge callback execution failed';
  }
}

export class WebViewBridge {
  private static request = (message: string): void => {
    window.ReactNativeWebView?.postMessage(message);
  };

  private static requestWithCallback<T>(
    message: string,
    callbackName: string,
    timeout = 5000
  ): Promise<T> {
    function cleanUp() {
      delete window.bridgeCallbacks?.[callbackName];
      delete window.bridgeErrorCallbacks?.[callbackName];
    }

    const asyncRequest = new Promise<T>(function (resolve, reject) {
      window.bridgeCallbacks = window.bridgeCallbacks ?? {};
      window.bridgeErrorCallbacks = window.bridgeErrorCallbacks ?? {};

      const onSuccess = new Promise(function () {
        window.bridgeCallbacks[callbackName] = function (
          stringifiedParam?: string
        ) {
          try {
            const response = JSON.parse(stringifiedParam ?? '{}');
            resolve(response);
          } catch (e) {
            reject(new WebViewBridgeCallbackFailedError());
          } finally {
            cleanUp();
          }
        };
      });

      const onError = new Promise(function () {
        window.bridgeErrorCallbacks[callbackName] = function (
          stringifiedError?: string
        ) {
          try {
            const error = stringifiedError
              ? JSON.parse(stringifiedError)
              : new WebViewBridgeCallbackFailedError();
            reject(error);
          } catch (e) {
            reject(new WebViewBridgeCallbackFailedError());
          } finally {
            cleanUp();
          }
        };
      });

      const onTimeout = new Promise(function () {
        setTimeout(function () {
          if (
            !!window.bridgeCallbacks[callbackName] ||
            !!window.bridgeErrorCallbacks[callbackName]
          ) {
            reject(new WebViewBridgeCallbackFailedError());
          }
          cleanUp();
        }, timeout);
      });

      Promise.race([onSuccess, onError, onTimeout]);
      WebViewBridge.request(message);
    });

    return asyncRequest;
  }

  static getAuthorizationToken(): Promise<AuthToken> {
    const callbackName = randomstring.generate();
    const message = new BridgeMessageBuilder('auth', 'get_token')
      .setCallback(callbackName)
      .build();

    return WebViewBridge.requestWithCallback<AuthToken>(message, callbackName);
  }

  static requestNavigationClose(): Promise<void> {
    const callbackName = randomstring.generate();
    const message = new BridgeMessageBuilder('navigation', 'close')
      .setCallback(callbackName)
      .build();

    return WebViewBridge.requestWithCallback<void>(message, callbackName);
  }

  static requestShare(text: string): Promise<void> {
    const callbackName = randomstring.generate();
    const message = new BridgeMessageBuilder('interaction', 'share')
      .setPayload({ message: text })
      .build();

    return WebViewBridge.requestWithCallback<void>(message, callbackName);
  }
}
