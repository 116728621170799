/* istanbul ignore file */
/* eslint-disable */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function WhalespaceIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={20} height={20} rx={4} fill="#193A97" />
      <g clipPath="url(#clip0_12395_19081)">
        
        <path d="M9.99934 13.9436C12.762 13.9436 15.0003 11.7052 15.0003 8.94258C15.0003 7.99918 14.7342 7.11934 14.2794 6.36462L14.196 6.39044C13.0103 6.80951 11.9934 7.99918 11.336 9.73702C10.8712 10.9664 10.4681 11.7787 9.98742 12.452C9.76101 12.7698 9.51274 13.026 9.20093 13.2623C9.06587 13.3736 8.90103 13.4868 8.70043 13.6059L8.50977 13.7191C8.98047 13.8661 9.47898 13.9436 9.99735 13.9436H9.99934Z" fill="white"/>
        <path d="M10.861 6.87504C9.286 7.9237 8.80139 8.6943 7.73088 8.6943C6.87885 8.6943 5.50447 7.84425 5.98312 6.16997C5.99106 6.14217 5.9851 6.11635 5.96127 6.10443C5.93942 6.09251 5.90963 6.09649 5.89176 6.12231C5.32969 6.93065 5 7.91377 5 8.97236C5 10.446 5.63952 11.7668 6.65243 12.6824C6.23337 13.1034 5.73287 13.4391 5.17875 13.6695C5.12115 13.6933 5.12314 13.7767 5.18272 13.7966H5.18073C6.10626 14.0786 7.20655 14.019 8.06653 13.5841C8.06653 13.5841 8.07249 13.5801 8.07448 13.5801C8.32473 13.455 8.58491 13.2941 8.83515 13.0895C9.13505 12.8631 9.37934 12.6129 9.60179 12.3011C10.0407 11.6893 10.5055 10.744 11.0298 9.38546C11.7547 7.52052 12.8213 6.60691 13.8977 6.13422C13.9295 6.12032 13.9633 6.10642 13.997 6.0945C14.0348 6.0806 14.0248 6.06074 13.997 6.05478C13.9613 6.04882 13.9116 6.04286 13.85 6.0369C13.3734 5.9952 12.1718 5.99917 10.861 6.87305V6.87504ZM9.99901 9.43313C9.77656 9.43313 9.59583 9.2226 9.59583 8.96243C9.59583 8.70225 9.77656 8.49172 9.99901 8.49172C10.2214 8.49172 10.4022 8.70225 10.4022 8.96243C10.4022 9.2226 10.2214 9.43313 9.99901 9.43313Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_12395_19081">
          <rect width="10" height="7.94439" fill="white" transform="translate(5 6.02499)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(WhalespaceIcon),
};

/* eslint-enable */
export default Asset;
