/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

import React, { PropsWithChildren } from 'react';

import CTAWithRightAsset from './withRightAsset';
import CTAWithTopAsset from './withTopAsset';

import type { CTAWithAssetProps } from './CallToAction.types';

type WithAsset = 'topAsset' | 'rightAsset';
type Props = CTAWithAssetProps & {
  withAsset: WithAsset;
};

type CTAComponent = typeof CTAWithRightAsset | typeof CTAWithTopAsset;

function getComponent(withAsset: WithAsset): CTAComponent {
  if (withAsset === 'topAsset') {
    return CTAWithTopAsset;
  } else {
    return CTAWithRightAsset;
  }
}

const CallToAction = (props: PropsWithChildren<Props>) => {
  const { withAsset, ...rest } = props;
  const Component = getComponent(withAsset);
  return <Component {...rest} />;
};

export default React.memo(CallToAction);
