/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function GoogleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={1}
        y={1}
        width={16}
        height={16}
        rx={4}
        fill="#fff"
        stroke="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.84 9.09c0-.283-.025-.555-.073-.817H9V9.82h2.153a1.84 1.84 0 01-.799 1.207v1.004h1.293c.757-.697 1.193-1.722 1.193-2.94z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 13c1.08 0 1.985-.358 2.647-.97l-1.293-1.003c-.358.24-.816.382-1.355.382-1.041 0-1.923-.704-2.238-1.65H5.425v1.037A3.999 3.999 0 008.999 13z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.762 9.76A2.404 2.404 0 016.636 9c0-.264.046-.52.126-.76V7.204H5.425a3.999 3.999 0 000 3.593L6.762 9.76z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6.59a2.16 2.16 0 011.528.6l1.148-1.148A3.844 3.844 0 008.999 5a3.998 3.998 0 00-3.574 2.204L6.76 8.24C7.076 7.295 7.958 6.59 9 6.59z"
        fill="#EA4335"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(GoogleIcon),
};

export default Asset;
