import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ModalStatus = 'default' | 'success' | 'error';
export interface OpenModalParamsType {
  title?: string;
  message: string;
  status: ModalStatus;
  buttonGroup?: JSX.Element;
  onClickButton?: () => void;
}
export interface ModalContextType {
  isOpen: boolean;
  title?: string;
  message: string;
  status: ModalStatus;
  buttonGroup?: JSX.Element;
  onClickButton?: () => void;
  openModal: (params: OpenModalParamsType) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined
);

ModalContext.displayName = 'ModalContext';

export const useModalContext = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) throw new Error('useModalContext:: context is not initialized');
  return context;
};

export const ModalProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<ModalStatus>('default');
  const [onClickButton, setOnClickButton] = useState<() => void>();
  const [buttonGroup, setButtonGroup] = useState<JSX.Element>();

  const value = useMemo(
    () => ({
      isOpen,
      title,
      message,
      status,
      buttonGroup,
      onClickButton,
      openModal: ({
        title = '',
        message,
        status,
        buttonGroup,
        onClickButton,
      }: OpenModalParamsType) => {
        setIsOpen(true);
        setTitle(title);
        setMessage(message);
        setStatus(status);
        setButtonGroup(buttonGroup);
        setOnClickButton(() => onClickButton);
      },
      closeModal: () => {
        setIsOpen(false);
        setTitle('');
        setMessage('');
        setStatus('default');
        setButtonGroup(undefined);
        setOnClickButton(undefined);
      },
    }),
    [isOpen, title, message, status, buttonGroup, onClickButton]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
