import React, { useCallback, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js';
import { Helmet } from 'react-helmet-async';

import {
  Tooltip,
  Typography,
  Box as CDSBox,
  Button as CDSButton,
  BoxIndicator,
} from '@classtinginc/design-system';

import { RiQuestionFill } from 'react-icons/ri';

import { useCopyToClipboard } from 'react-use';

import toast from 'react-hot-toast';

import { CheckboxCircle } from '../../../designSystem/ions/Icons/fill';

import useTranslatedTexts from './useTranslatedTexts';

import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { maskingMobileNumber } from 'shared/utils/masking';

import { Box } from 'designSystem/ions';
import { Anchor, Button, Label } from 'designSystem/atoms';
import { IdentityProviderBadge } from 'designSystem/molecules';
import { EnterpriseSectionPanel } from 'designSystem/organisms';
import { EnterpriseSideMenuPageTemplate } from 'designSystem/templates';
import { CopyLinkIcon } from 'designSystem/ions/Icons/outlined';
import { createTrackEventProps } from 'shared/analytics/utils';
import EnterpriseAppBar from 'designSystem/organisms/EnterpriseAppBar';
import {
  EnterpriseAppBarEnterpriseDtoFieldsFragment,
  EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment,
  useAccountInfoPageQuery,
} from 'generated/graphql';
import ForbiddenErrorPage from 'pages/ErrorPages/ForbiddenErrorPage';

interface TooltipProps {
  open?: boolean;
  delayDuration?: number;
}

export const AccountInfoPage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const texts = useTranslatedTexts();
  const history = useHistory();
  const [copyState, copyToClipboard] = useCopyToClipboard();

  const { data: { enterprise } = {}, loading } = useAccountInfoPageQuery({
    variables: {
      enterpriseId: account?.enterprise?.id ?? '',
    },
    skip: !account?.enterprise,
  });

  const faviconUrl =
    enterprise?.isWhiteLabeled && enterprise?.branding?.appIconUrl
      ? enterprise?.branding?.appIconUrl
      : `${process.env.PUBLIC_URL}/favicon.ico`;

  const handleClickDeleteAccountButton = useCallback(() => {
    history.push('/enterprise/account/delete');
  }, [history]);

  const handleClickChangePasswordButton = useCallback(() => {
    history.push('/account/change-password');
  }, [history]);

  const handleClickChangeMobileButton = useCallback(() => {
    /* istanbul ignore next */
    history.push('/enterprise/account/change-mobile');
  }, [history]);

  const handleClickChangeEmail = () => {
    history.push('/enterprise/account/change-email');
  };

  const tooltipProps: TooltipProps = {
    open: undefined,
    delayDuration: 0,
  };

  const currentToastId = useRef('');
  const handleClickCopy = () => {
    copyToClipboard(account.id);

    if (currentToastId.current !== '') {
      toast.remove(currentToastId.current);
    }

    const toastId = toast(
      <CDSBox
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <CDSBox as="div">
          <CheckboxCircle.View {...CheckboxCircle.size} />
        </CDSBox>
        <Typography
          as="div"
          css={{
            marginLeft: '$03',
          }}
          size="sub-body"
          color="dark"
        >
          {texts.message.copyConfirm}
        </Typography>
      </CDSBox>,
      {
        position: 'top-center',
        style: {
          maxWidth: 'inherit',
          margin: '4.375rem',
          padding: '7px 0px',
        },
      }
    );

    currentToastId.current = toastId;
  };

  const EmptyText = (text: string) => {
    return (
      <Box
        is="p"
        className="text-body-3 sm:text-body-1 text-gray-500 font-regular"
      >
        {text}
      </Box>
    );
  };

  const renderMobileNumber = () => {
    if (account.mobile) {
      const parsedNumber = parsePhoneNumber(
        account.mobile.number,
        account.mobile.countryCode
      )?.formatNational() as string;
      return maskingMobileNumber(parsedNumber);
    } else {
      return EmptyText(texts.label.none);
    }
  };

  const emailEventName = account.email
    ? 'ChangeEmailStarted'
    : 'AddEmailStarted';
  const mobileEventName = account.mobile
    ? 'ChangeMobileStarted'
    : 'AddMobileStarted';

  /**
   * @TODO 컴포넌트 분리
   */
  const renderAccountInfoPanel = () => {
    return (
      <EnterpriseSectionPanel.Wrapper
        is="article"
        testId="account-info-panel"
        topSpacing="board"
        className="w-full pt-8"
      >
        <EnterpriseSectionPanel.Header
          title={texts.title.accountInfo}
          showTitleInMobile={false}
        />
        <EnterpriseSectionPanel.Body>
          {account.username ? (
            <EnterpriseSectionPanel.Item
              testId="account-username"
              label={<Label>{texts.label.username}</Label>}
              divider
            >
              {account.username}
            </EnterpriseSectionPanel.Item>
          ) : null}
          <EnterpriseSectionPanel.Item
            testId="account-email"
            label={<Label>{texts.label.email}</Label>}
            divider
          >
            {account.email ? account.email : EmptyText(texts.label.none)}
            <Button
              testId="email-update-button"
              theme="white"
              onClick={handleClickChangeEmail}
              {...createTrackEventProps({
                name: emailEventName,
                on: 'click',
                properties: {
                  userId: account.id,
                  role: account.role,
                },
              })}
            >
              {account.email ? texts.button.change : texts.button.add}
            </Button>
          </EnterpriseSectionPanel.Item>
          <EnterpriseSectionPanel.Item
            testId="account-mobile"
            label={<Label>{texts.label.mobile}</Label>}
            divider
          >
            {renderMobileNumber()}
            <Button
              testId="change-mobile-button"
              theme="white"
              onClick={handleClickChangeMobileButton}
              {...createTrackEventProps({
                name: mobileEventName,
                on: 'click',
                properties: {
                  userId: account.id,
                  role: account.role,
                },
              })}
            >
              {account.mobile ? texts.button.change : texts.button.add}
            </Button>
          </EnterpriseSectionPanel.Item>
          <EnterpriseSectionPanel.Item
            testId="account-provider"
            label={<Label>{texts.label.linked}</Label>}
            divider
          >
            <IdentityProviderBadge account={account} />
          </EnterpriseSectionPanel.Item>
          <EnterpriseSectionPanel.Item
            testId="account-password"
            label={<Label>{texts.label.password}</Label>}
            divider
          >
            {account.identities.length === 0 ? (
              <Button
                testId="account-change-password"
                theme="white"
                onClick={handleClickChangePasswordButton}
                {...createTrackEventProps({
                  name: 'ChangePasswordStarted',
                  on: 'click',
                  properties: {
                    userId: account.id,
                    role: account.role,
                  },
                })}
              >
                {texts.button.changePassword}
              </Button>
            ) : (
              EmptyText(texts.label.cannotUpdatePassword)
            )}
          </EnterpriseSectionPanel.Item>
          <EnterpriseSectionPanel.Item
            testId="account-id"
            label={
              <CDSBox className="flex flax-row">
                <Label>{texts.label.id}</Label>
                <CDSBox
                  css={{
                    paddingLeft: '$01',
                  }}
                >
                  <Tooltip.Provider>
                    <Tooltip.Root {...tooltipProps}>
                      <Tooltip.Trigger asChild>
                        <Typography
                          size="body"
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '$text-medium',
                          }}
                        >
                          <RiQuestionFill />
                        </Typography>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          side="bottom"
                          align="start"
                          animation
                          alignOffset={-5}
                          sideOffset={4}
                          css={{ zIndex: 10 }}
                        >
                          {texts.message.idTooltipDescription}
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </CDSBox>
              </CDSBox>
            }
          >
            {account.id}
            <CDSButton
              onClick={handleClickCopy}
              data-testid="copy-id-button"
              appearance="text"
              color="primary"
              size="sm"
              css={{
                display: 'flex flex-row',
                color: '$gray-700',
              }}
            >
              <CDSBox>
                <CopyLinkIcon.View {...{ width: '16px', height: '16px' }} />
              </CDSBox>
              <CDSBox
                css={{
                  paddingLeft: '$01',
                }}
              >
                {texts.button.copy}
              </CDSBox>
            </CDSButton>
          </EnterpriseSectionPanel.Item>
        </EnterpriseSectionPanel.Body>
      </EnterpriseSectionPanel.Wrapper>
    );
  };

  if (!account.enterprise) {
    return <ForbiddenErrorPage />;
  }

  if (loading) {
    return <BoxIndicator />;
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <EnterpriseSideMenuPageTemplate
        account={account}
        title={texts.pageTitle}
        enterprise={
          enterprise as EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment
        }
        headerChild={
          <EnterpriseAppBar
            account={account}
            enterprise={
              enterprise as EnterpriseAppBarEnterpriseDtoFieldsFragment
            }
          />
        }
      >
        {renderAccountInfoPanel()}
        {/* TODO: 계정 삭제 페이지에 화이트 라벨링이 적용될때까지 엔트리 제거 */}
        <Box className="flex flex-col items-start sm:items-end w-full mt-5 sm:mt-6 text-right">
          <Button
            testId="account-delete-button"
            theme="white"
            appearance="text"
            className="hidden sm:flex"
            onClick={handleClickDeleteAccountButton}
            {...createTrackEventProps({
              name: 'DeleteAccountStarted',
              on: 'click',
              properties: {
                userId: account.id,
                role: account.role,
              },
            })}
          >
            {texts.button.deleteAccount}
          </Button>
          <Anchor
            className="flex sm:hidden text-gray-600 text-14 font-regular underline cursor-pointer"
            onClick={handleClickDeleteAccountButton}
            {...createTrackEventProps({
              name: 'DeleteAccountStarted',
              on: 'click',
              properties: {
                userId: account.id,
                role: account.role,
              },
            })}
          >
            {texts.button.deleteAccount}
          </Anchor>
        </Box>
      </EnterpriseSideMenuPageTemplate>
    </>
  );
};

export default withAccount(AccountInfoPage);
