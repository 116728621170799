import React from 'react';

import { Box } from 'designSystem/ions';

type RadioProps = {
  id?: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const className = `form-radio w-4 h-4 border rounded-1/2 cursor-pointer border-gray-400 text-primary-normal`;

const Radio = ({ id, name, value, onChange }: RadioProps): JSX.Element => {
  return (
    <Box
      is="input"
      type="radio"
      id={id}
      className={className}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

export default Radio;
