import React, { Ref, forwardRef, ComponentPropsWithRef } from 'react';

import { Box } from 'designSystem/ions';
import { Dropdown } from 'designSystem/atoms';
import { TextField } from 'designSystem/molecules';

import { Without } from 'types/utilities';

type DropdownItem = {
  key: string;
  value: string;
  label: string;
};

type DropdownTextFieldProps = {
  dropdownLabel: string;
  dropdownItems: DropdownItem[];
  onDropdownChange: (id: string) => void;
} & Without<ComponentPropsWithRef<typeof TextField>, 'startAdornment'>;

/**
 * @TODO Input이 Dropdown/TextField 두 개인 상황이며, ref를 어떻게 전달할지 정한 후 구현필요.
 */
const DropdownTextField = forwardRef(
  (
    {
      type,
      label,
      isError = false,
      helperTexts = [],
      name,
      value,
      placeholder,
      disabled,
      testId,
      dropdownLabel,
      dropdownItems,
      onChange,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onBlur,
      onFocus,
      onDropdownChange,
    }: DropdownTextFieldProps,
    forwardedRef: Ref<HTMLInputElement>
  ): JSX.Element => {
    return (
      <Box is="div" ref={forwardedRef} className="relative w-full">
        <TextField
          type={type}
          label={label}
          helperTexts={helperTexts}
          name={name}
          value={value}
          placeholder={placeholder}
          startAdornment={
            <Dropdown
              testId="dropdown"
              disabled={disabled}
              onValueChange={onDropdownChange}
              defaultValue={dropdownItems[0].value}
            >
              <Dropdown.Trigger
                className="-ml-4 px-4 py-2"
                height="auto"
                hasBorder={false}
                boxSizing="content-box"
                minWidth="70px"
              >
                {dropdownLabel}
              </Dropdown.Trigger>
              <Dropdown.Menu
                className="-left-4"
                top="3.25rem"
                width="290px"
                height="336px"
                marginLeft={-1}
              >
                {dropdownItems.map(({ key, value, label }) => {
                  return (
                    <Dropdown.MenuItem value={value} key={key} showWithValue>
                      {label}
                    </Dropdown.MenuItem>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          }
          isError={isError}
          disabled={disabled}
          testId={testId}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Box>
    );
  }
);

DropdownTextField.displayName = 'DropdownTextField';

export default DropdownTextField;
