export const countryCode = [
  { countryCode: 'kr', name: 'South Korea (대한민국)', dialCode: '+82' },
  { countryCode: 'tw', name: 'Taiwan (台灣)', dialCode: '+886' },
  { countryCode: 'us', name: 'United States', dialCode: '+1' },
  { countryCode: 'jp', name: 'Japan (日本)', dialCode: '+81' },
  { countryCode: 'ar', name: 'Argentina', dialCode: '+54' },
  { countryCode: 'au', name: 'Australia', dialCode: '+61' },
  { countryCode: 'at', name: 'Austria (Österreich)', dialCode: '+43' },
  { countryCode: 'bh', name: 'Bahrain (;البحرين)', dialCode: '+973' },
  { countryCode: 'bd', name: 'Bangladesh (বাংলাদেশ)', dialCode: '+880' },
  { countryCode: 'br', name: 'Brazil (Brasil)', dialCode: '+55' },
  { countryCode: 'kh', name: 'Cambodia (កម្ពុជា)', dialCode: '+855' },
  { countryCode: 'ca', name: 'Canada', dialCode: '+1' },
  { countryCode: 'cl', name: 'Chile', dialCode: '+56' },
  { countryCode: 'cn', name: 'China (中国)', dialCode: '+86' },
  { countryCode: 'co', name: 'Colombia', dialCode: '+57' },
  {
    countryCode: 'cz',
    name: 'Czech Republic (Česká republika)',
    dialCode: '+420',
  },
  { countryCode: 'dk', name: 'Denmark (Danmark)', dialCode: '+45' },
  { countryCode: 'eg', name: 'Egypt (;مصر)', dialCode: '+20' },
  { countryCode: 'fr', name: 'France', dialCode: '+33' },
  { countryCode: 'de', name: 'Germany (Deutschland)', dialCode: '+49' },
  { countryCode: 'gh', name: 'Ghana (Gaana)', dialCode: '+233' },
  { countryCode: 'gr', name: 'Greece (Ελλάδα)', dialCode: '+30' },
  { countryCode: 'gu', name: 'Guam', dialCode: '+1671' },
  { countryCode: 'hk', name: 'Hong Kong (香港)', dialCode: '+852' },
  { countryCode: 'in', name: 'India (भारत)', dialCode: '+91' },
  { countryCode: 'id', name: 'Indonesia', dialCode: '+62' },
  { countryCode: 'ir', name: 'Iran (;ایران)', dialCode: '+98' },
  { countryCode: 'il', name: 'Israel (;ישראל)', dialCode: '+972' },
  { countryCode: 'it', name: 'Italy (Italia)', dialCode: '+39' },
  { countryCode: 'jm', name: 'Jamaica', dialCode: '+1876' },
  { countryCode: 'ke', name: 'Kenya', dialCode: '+254' },
  { countryCode: 'kw', name: 'Kuwait (;الكويت)', dialCode: '+965' },
  { countryCode: 'la', name: 'Laos (ລາວ)', dialCode: '+856' },
  { countryCode: 'lb', name: 'Lebanon (;لبنان)', dialCode: '+961' },
  { countryCode: 'mo', name: 'Macau (澳門)', dialCode: '+853' },
  { countryCode: 'my', name: 'Malaysia', dialCode: '+60' },
  { countryCode: 'mu', name: 'Mauritius (Moris)', dialCode: '+230' },
  { countryCode: 'mx', name: 'Mexico (México)', dialCode: '+52' },
  { countryCode: 'mn', name: 'Mongolia (Монгол)', dialCode: '+976' },
  { countryCode: 'ma', name: 'Morocco (;المغرب)', dialCode: '+212' },
  { countryCode: 'np', name: 'Nepal (नेपाल)', dialCode: '+977' },
  { countryCode: 'nl', name: 'Netherlands (Nederland)', dialCode: '+31' },
  { countryCode: 'nz', name: 'New Zealand', dialCode: '+64' },
  { countryCode: 'ng', name: 'Nigeria', dialCode: '+234' },
  {
    countryCode: 'kp',
    name: 'North Korea (조선 민주주의 인민 공화국)',
    dialCode: '+850',
  },
  { countryCode: 'pk', name: 'Pakistan (;پاکستان)', dialCode: '+92' },
  { countryCode: 'pa', name: 'Panama (Panamá)', dialCode: '+507' },
  { countryCode: 'py', name: 'Paraguay', dialCode: '+595' },
  { countryCode: 'pe', name: 'Peru (Perú)', dialCode: '+51' },
  { countryCode: 'ph', name: 'Philippines', dialCode: '+63' },
  { countryCode: 'pl', name: 'Poland (Polska)', dialCode: '+48' },
  { countryCode: 'pr', name: 'Puerto Rico', dialCode: '+1' },
  { countryCode: 'ro', name: 'Romania (România)', dialCode: '+40' },
  { countryCode: 'ru', name: 'Russia (Россия)', dialCode: '+7' },
  { countryCode: 'rw', name: 'Rwanda', dialCode: '+250' },
  {
    countryCode: 'sa',
    name: 'Saudi Arabia (;المملكة العربية السعودية)',
    dialCode: '+966',
  },
  { countryCode: 'sg', name: 'Singapore', dialCode: '+65' },
  { countryCode: 'sk', name: 'Slovakia (Slovensko)', dialCode: '+421' },
  { countryCode: 'za', name: 'South Africa', dialCode: '+27' },
  { countryCode: 'es', name: 'Spain (España)', dialCode: '+34' },
  { countryCode: 'ch', name: 'Switzerland (Schweiz)', dialCode: '+41' },
  { countryCode: 'th', name: 'Thailand (ไทย)', dialCode: '+66' },
  { countryCode: 'tr', name: 'Turkey (Türkiye)', dialCode: '+90' },
  { countryCode: 'ua', name: 'Ukraine (Україна)', dialCode: '+380' },
  {
    countryCode: 'ae',
    name: 'United Arab Emirates (;الإمارات العربية المتحدة)',
    dialCode: '+971',
  },
  { countryCode: 'gb', name: 'United Kingdom', dialCode: '+44' },
  { countryCode: 'uz', name: 'Uzbekistan (Oʻzbekiston)', dialCode: '+998' },
  { countryCode: 'vn', name: 'Vietnam (Việt Nam)', dialCode: '+84' },
];
