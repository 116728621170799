import React, { PropsWithChildren } from 'react';

import { GlobalSection } from 'designSystem/layouts';
import { WithChildrenProp } from 'types/utilities';
import { Account } from 'shared/data/account';

import SideMenuLayoutEnterprise from 'designSystem/layouts/EnterpriseSideMenuLayout';
import { EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment } from 'generated/graphql';
import { Box, EnterpriseHelmet } from 'designSystem/ions';

const Header = ({
  children,
  title: CTATitle,
  keepShadow = false,
  className = '',
  enterprise,
}: PropsWithChildren<{
  title: string;
  keepShadow?: boolean;
  className?: string;
  enterprise: EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment;
}>): JSX.Element => {
  const colorStyle = 'sm:bg-white';
  const headerStyle = `
  flex flex-initial
  w-full
  z-20
  ${keepShadow ? 'shadow-sm' : 'shadow-none sm:shadow-sm'}
  ${colorStyle}
  ${className}`;

  const title =
    enterprise?.isWhiteLabeled && enterprise?.branding?.name
      ? `${CTATitle} | ${enterprise?.branding?.name}`
      : CTATitle;

  return (
    <Box is="header" className={headerStyle}>
      <Box is="h1" className="sr-only">
        {CTATitle}
      </Box>
      <EnterpriseHelmet title={title} />
      <Box is="div" className="flex relative h-full w-full">
        {children}
      </Box>
    </Box>
  );
};

type EnterpriseSideMenuPageTemplateProps = {
  showHeader?: boolean;
  showFooter?: boolean;
  headerChild: React.ReactNode;
  account: Account;
  enterprise: EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment;
  title?: string;
};

const EnterpriseSideMenuPageTemplate = ({
  children,
  showHeader = true,
  headerChild,
  account,
  enterprise,
  title,
}: WithChildrenProp<EnterpriseSideMenuPageTemplateProps>): JSX.Element => {
  return (
    <GlobalSection.Wrapper>
      {showHeader && (
        <Header
          className="bg-white"
          title={title as string}
          keepShadow
          enterprise={enterprise}
        >
          {headerChild}
        </Header>
      )}
      <GlobalSection.Body className="bg-white sm:bg-gray-50">
        <SideMenuLayoutEnterprise account={account}>
          {children}
        </SideMenuLayoutEnterprise>
      </GlobalSection.Body>
    </GlobalSection.Wrapper>
  );
};

export default EnterpriseSideMenuPageTemplate;
