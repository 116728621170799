import React from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import toast from 'react-hot-toast';

import { useCopyToClipboard } from 'react-use';

import * as Sentry from '@sentry/react';

import useTranslatedTexts from './useTranslatedTexts';

import { Account } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { Box } from 'designSystem/ions';
import {
  CopyLinkIcon,
  ShareIcon,
  UserWithHeartIcon,
} from 'designSystem/ions/Icons/outlined';
import { CheckboxCircle } from 'designSystem/ions/Icons/fill';
import { LINK_GUARDIAN_WEB_TO_APP_PAGE_URL } from 'shared/constants';
import { CenterTitleNavigationHeader } from 'designSystem/organisms';
import { GlobalSection, TransparentPanel } from 'designSystem/layouts';
import SingleColumnLayout from 'designSystem/layouts/SingleColumnLayout';
import { withAccount } from 'shared/root';
import { WebViewBridge } from 'shared/webViewBridge';
import { createTrackEventProps } from 'shared/analytics/utils';

const titleStyle = `
text-headline-3
font-bold text-gray-900
break-all whitespace-pre-line`;

const iconConatinerStyle = `rounded-full w-14 h-14 flex items-center justify-center`;

export const LinkChildByUrlPage = ({
  account,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const isAllowed = account.role === 'parent' || account.role === 'teacher';
  const texts = useTranslatedTexts();
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const isClasstingMobileApp = useIsClasstingMobileApp();

  const guardianId = account.id;

  if (!isAllowed) {
    return <Redirect to="/not-found" />;
  }

  const webToAppPageURL = `${LINK_GUARDIAN_WEB_TO_APP_PAGE_URL}?id=${guardianId}`;

  const handleClickCopy = () => {
    copyToClipboard(webToAppPageURL);

    toast(
      <Box className="gap-3 flex flex-row items-center">
        <CheckboxCircle.View {...CheckboxCircle.size} />
        <Box className="break-all">{texts.linkCopied}</Box>
      </Box>
    );
  };

  const handleClickShare = async () => {
    try {
      const shareMessage = texts.share.message(account.name, webToAppPageURL);

      await WebViewBridge.requestShare(shareMessage);
    } catch (error) {
      /* istanbul ignore next */
      Sentry.captureException(error);
    }
  };

  return (
    <GlobalSection.Wrapper>
      <GlobalSection.Header className="bg-white" title="클래스팅" keepShadow>
        <CenterTitleNavigationHeader
          title=""
          showBackButtonInMobile={true}
          showTitleInMobile={false}
          backButtonBehavior={'back'}
        />
      </GlobalSection.Header>
      <GlobalSection.Body hasTopSpacing={false} className="bg-white">
        <SingleColumnLayout>
          <TransparentPanel.Wrapper>
            <TransparentPanel.Body headerLess>
              <Box
                data-testid="link-child-by-url-page-content"
                className={`flex flex-col items-center ${
                  isClasstingMobileApp ? 'mt-8' : 'mt-12'
                }`}
              >
                <Box className="flex flex-col items-center gap-4">
                  <UserWithHeartIcon.View {...UserWithHeartIcon.size} />
                  <Box data-testid="title" is="h3" className={titleStyle}>
                    {texts.title}
                  </Box>
                </Box>
                <Box className="flex flex-col gap-6 items-start mt-10 max-w-xs">
                  <Box className="flex flex-row gap-1">
                    <Box className="text-gray-500">❶</Box>
                    <Box
                      data-testid="guide01"
                      className="font-medium text-gray-800"
                    >
                      {texts.guide01}
                    </Box>
                  </Box>
                  <Box className="flex flex-row gap-1">
                    <Box className="text-gray-500">❷</Box>
                    <Box>
                      <Box
                        data-testid="guide02"
                        className="font-medium text-gray-800"
                      >
                        {texts.guide02}
                      </Box>
                      <Box className="text-14 text-gray-600 break-all">
                        {texts.guide02Description}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-row mt-22 gap-3">
                  <Box className="flex flex-col items-center gap-3">
                    <Box
                      is="button"
                      onClick={handleClickCopy}
                      data-testid="copy-url-button"
                      className={`${iconConatinerStyle} bg-gray-100 text-gray-800`}
                      {...createTrackEventProps({
                        name: 'GuardianLinkCopied',
                        on: 'click',
                        properties: {
                          userId: account.id,
                        },
                      })}
                    >
                      <CopyLinkIcon.View {...CopyLinkIcon.size} />
                    </Box>
                    <Box className="text-gray-800 text-14">
                      {texts.copyButton}
                    </Box>
                  </Box>
                  {isClasstingMobileApp && (
                    <Box className="flex flex-col items-center gap-3">
                      <Box
                        is="button"
                        onClick={handleClickShare}
                        data-testid="share-url-button"
                        className={`${iconConatinerStyle} bg-ctGreen-500`}
                        {...createTrackEventProps({
                          name: 'GuardianLinkShared',
                          on: 'click',
                          properties: {
                            userId: account.id,
                          },
                        })}
                      >
                        <ShareIcon.View {...ShareIcon.size} />
                      </Box>
                      <Box className="text-gray-800 text-14">
                        {texts.shareButton}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </TransparentPanel.Body>
          </TransparentPanel.Wrapper>
        </SingleColumnLayout>
      </GlobalSection.Body>
    </GlobalSection.Wrapper>
  );
};

export default withAccount(LinkChildByUrlPage);
