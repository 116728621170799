import React, { ReactElement } from 'react';

import { Box, BoxProps } from 'designSystem/ions';

import type { Without } from 'types/utilities';

type Props = Without<BoxProps<'a'>, 'is'>;

const Anchor = (
  props: Props,
  ref: React.Ref<HTMLAnchorElement>
): ReactElement => {
  return <Box is="a" ref={ref} {...props} />;
};

export default React.memo(React.forwardRef(Anchor));
