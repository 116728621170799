import React, { useMemo } from 'react';
import type { Ref } from 'react';
import classnames from 'classnames';

import {
  Box,
  BoxProps,
  getThemeObject,
  Theme,
  ThemeObject,
} from 'designSystem/ions';

import { WithChildrenProp, Without } from 'types/utilities';

type ButtonSize = 'md' | 'lg';
type ButtonAppearance = 'contained' | 'outlined' | 'text' | 'icon';
type IconPosition = 'left' | 'right' | 'up' | 'down' | 'none';

export type ButtonLikeComponentProps = {
  theme?: Theme;
  disabled?: boolean;
  size?: ButtonSize;
  appearance?: ButtonAppearance;
  iconPosition?: IconPosition;
  fullWidth?: boolean;
  rounded?: boolean;
  testId?: string;
  className?: string;
  type?: 'button' | 'reset' | 'submit';
};

type BoxButtonProps = Without<BoxProps<'button'>, 'is'>;

type ButtonProps = WithChildrenProp<BoxButtonProps & ButtonLikeComponentProps>;

interface StyleParams {
  size: ButtonSize;
  appearance: ButtonAppearance;
  themeObject: ThemeObject;
  fullWidth: boolean;
  rounded: boolean;
  className: string;
  theme?: string;
}

function getButtonAppearanceStyle({
  appearance,
  themeObject,
}: {
  appearance: ButtonAppearance;
  themeObject: ThemeObject;
}): string {
  if (appearance === 'contained') {
    return `${themeObject.bg.normal} border ${themeObject.border.normal} hover:shadow active:${themeObject.bg.dark} active:${themeObject.border.dark} disabled:bg-gray-400 disabled:border-gray-400`;
  }
  if (appearance === 'text') {
    return 'bg-transparent';
  }
  // outlined style
  return `bg-white border ${themeObject.border.normal} hover:shadow hover:${themeObject.border.dark} active:bg-gray-100 active:${themeObject.border.dark} disabled:bg-gray-400 disabled:border-gray-400`;
}

function getButtonSizeStyle({ size }: { size: ButtonSize }) {
  if (size === 'lg') {
    return 'h-14 px-6';
  }
  // md style
  return 'h-11 px-4';
}

function getButtonStyle({
  size,
  appearance,
  themeObject,
  fullWidth,
  rounded,
  className,
}: StyleParams) {
  const appearanceStyle = getButtonAppearanceStyle({ appearance, themeObject });
  const sizeStyle = getButtonSizeStyle({ size });
  return classnames(
    `
    flex justify-center items-center
    transition transition-colors ease-in duration-200
    focus:outline-none focus-visible:ring
    disabled:cursor-not-allowed disabled:shadow-none`,
    {
      'rounded-lg': rounded,
      'w-full': fullWidth,
    },
    sizeStyle,
    appearanceStyle,
    className
  );
}

function getTextAppearanceStyle({
  appearance,
  themeObject,
  theme,
}: {
  appearance: ButtonAppearance;
  themeObject: ThemeObject;
  theme?: string;
}): string {
  if (appearance === 'contained' && theme !== 'gray') {
    return 'text-white';
  }
  return `${themeObject.color.normal} disabled:text-gray-400`;
}

function getTextSizeStyle({ size }: { size: ButtonSize }) {
  if (size === 'lg') {
    return 'text-body-1 font-regular';
  }
  // md style
  return 'text-body-3 font-medium';
}

function getTextStyle({
  size,
  appearance,
  themeObject,
  theme,
}: Omit<StyleParams, 'fullWidth' | 'rounded' | 'className'>) {
  const appearanceStyle = getTextAppearanceStyle({
    appearance,
    themeObject,
    theme,
  });
  const sizeStyle = getTextSizeStyle({ size });
  const defaultStyle = 'text-center';
  return `${defaultStyle} ${sizeStyle} ${appearanceStyle}`;
}

export const useMakeButtonStyle = (
  props: ButtonLikeComponentProps
): {
  buttonStyle: string;
  textStyle: string;
} => {
  const {
    theme = 'primary',
    appearance = 'outlined',
    size = 'md',
    fullWidth = false,
    rounded = true,
    className = '',
  } = props;
  const themeObject = useMemo(() => getThemeObject(theme), [theme]);
  const buttonStyle = useMemo(
    () =>
      getButtonStyle({
        size,
        appearance,
        themeObject,
        fullWidth,
        rounded,
        className,
      }),
    [appearance, size, themeObject, fullWidth, rounded, className]
  );
  const textStyle = useMemo(
    () =>
      getTextStyle({
        size,
        appearance,
        themeObject,
        theme,
      }),
    [appearance, size, themeObject, theme]
  );

  return {
    buttonStyle,
    textStyle,
  };
};

const Button = (
  props: ButtonProps,
  ref: Ref<HTMLButtonElement>
): JSX.Element => {
  const {
    theme = 'primary',
    appearance = 'outlined',
    size = 'md',
    disabled = false,
    type = 'button',
    rounded = true,
    fullWidth = false,
    children,
    onClick,
    testId,
    className = '',
    iconPosition,
    ...restProps
  } = props;
  const { buttonStyle, textStyle } = useMakeButtonStyle(props);
  return (
    <Box
      is="button"
      type={type}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyle}
      ref={ref}
      {...restProps}
    >
      <Box is="span" className={textStyle}>
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(React.forwardRef(Button));
