import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { useSearchParam } from 'react-use';

import LinkGuardianPageContent from './LinkGuardianPageContent';

import NotAllowedRole from './LinkGuardianPageContent/exceptions/NotAllowedRole';

import { SingleColumnPageTemplate } from 'designSystem/templates';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { TransparentPanel } from 'designSystem/layouts';
import { CenterTitleNavigationHeader } from 'designSystem/organisms';

export const LinkGuardianPage = ({
  account,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const guardianId = useSearchParam('guardian_id') || '';

  const isAllowedRole = account.role === 'student';

  const isClasstingMobileApp = useIsClasstingMobileApp();

  return (
    <SingleColumnPageTemplate
      headerChild={
        <CenterTitleNavigationHeader
          showBackButtonInMobile={true}
          showTitleInMobile={false}
          backButtonBehavior={isClasstingMobileApp ? 'close' : 'back'}
        />
      }
      showFooter={false}
    >
      <TransparentPanel.Wrapper>
        <TransparentPanel.Body headerLess>
          {isAllowedRole ? (
            <LinkGuardianPageContent
              guardianId={guardianId}
              accountId={account.id}
            />
          ) : (
            <NotAllowedRole />
          )}
        </TransparentPanel.Body>
      </TransparentPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default withAccount(LinkGuardianPage);
