import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const title = t('account.guardian_connect.link.title');
  const description = t('account.guardian_connect.link.description');

  return {
    title,
    description,
  };
};

export default useTranslatedTexts;
