/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const title = {
    studentCode: t('account.student_code.title'),
  };

  return {
    title,
  };
};

export default useTranslatedTexts;
