import React from 'react';

function ClasstingWhiteCircleLogo({
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="61"
      height="66"
      viewBox="0 0 61 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4473 28.1482C35.8838 28.1482 35.3422 28.2431 34.8624 28.4002C34.1241 28.6421 31.7095 29.6654 31.6214 32.695C31.6194 32.7675 31.6185 32.8366 31.6185 32.9062C31.6185 32.9758 31.6194 33.0454 31.6214 33.1145C31.7095 36.147 34.1241 37.1708 34.8619 37.4127C36.4352 37.9275 38.662 37.7752 40.1968 35.804C40.2027 35.7967 40.2085 35.7894 40.2139 35.7821L42.4884 32.9062L40.2139 30.0308C40.2085 30.0236 40.2027 30.0158 40.1963 30.009C39.1306 28.6396 37.7306 28.1482 36.4473 28.1482ZM32.909 7.34573C32.7874 7.34573 32.6657 7.34622 32.5445 7.34768C19.3188 7.53064 8.77496 17.3781 7.47954 30.2299C6.00748 44.8305 17.2399 57.8881 31.9051 58.4487C40.1204 58.7621 47.8326 55.2089 52.9237 48.8114C53.5568 48.0153 53.5529 46.8952 52.9145 46.0879L47.5683 39.3289C47.3659 39.0725 46.9771 39.0725 46.7742 39.3284L45.9843 40.327C42.7916 44.4188 37.5311 46.0144 32.5771 44.393C27.684 42.7916 24.427 38.4491 24.2791 33.3305C24.2747 33.1918 24.2727 33.0493 24.2727 32.9062C24.2727 32.7631 24.2747 32.6211 24.2791 32.479C24.4275 27.3633 27.6845 23.0208 32.5771 21.4194C37.5306 19.798 42.7906 21.3941 45.9839 25.4845L46.7742 26.484C46.9766 26.7399 47.3654 26.7399 47.5683 26.484L52.914 19.7245C53.5539 18.9153 53.5461 17.78 52.894 16.964C48 10.8376 40.7331 7.34573 32.909 7.34573ZM32.908 65.8125C24.1151 65.8125 15.8511 62.3907 9.6363 56.1772C3.42249 49.9627 0.000486633 41.6987 0 32.9067C0 24.1814 3.35825 15.9699 9.45625 9.78412C15.5552 3.5974 23.719 0.124002 32.4428 0.00332347C37.623 -0.0686943 42.5954 1.03006 47.2223 3.26699C51.6254 5.3959 55.572 8.54716 58.6334 12.3797C61.431 15.8818 61.4481 20.7761 58.6757 24.2811L51.8536 32.9062L58.6757 41.5313C61.4427 45.0295 61.4413 49.9044 58.6723 53.3846C52.387 61.2832 42.9964 65.8125 32.908 65.8125Z"
        fill="#00C896"
      />
    </svg>
  );
}

const Asset = {
  View: React.memo(ClasstingWhiteCircleLogo),
};
export default Asset;
