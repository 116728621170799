import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const Plus = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.6665 9.16669V4.16669H11.3332V9.16669H16.3332V10.8334H11.3332V15.8334H9.6665V10.8334H4.6665V9.16669H9.6665Z" />
    </svg>
  );
};

const Asset = {
  size,
  View: Plus,
};
export default Asset;
