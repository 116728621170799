import React from 'react';

import { ProfileListItem } from 'designSystem/molecules';

interface LinkedGuardianListItemProps {
  profileImage: string;
  name: string;
  relation: string;
}

const LinkedGuardianListItem = ({
  name,
  relation,
  profileImage,
}: LinkedGuardianListItemProps): JSX.Element => {
  return (
    <ProfileListItem
      profileImage={profileImage}
      username={name}
      description={relation}
      testid="linked-guardian-list-item"
      bordered
    />
  );
};

export default LinkedGuardianListItem;
