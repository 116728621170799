import React, { useState, useEffect, useCallback } from 'react';
import { useCopyToClipboard } from 'react-use';

import dayjs from 'dayjs';

import { RenewChildCodeButton } from './components';

import { ContentCopyIcon } from 'designSystem/ions/Icons/outlined';
import { CheckboxCircle } from 'designSystem/ions/Icons/fill';
import { Box } from 'designSystem/ions';
import { Toast } from 'designSystem/atoms';
import { HoverDialogContent } from 'designSystem/molecules';
import { useTimer } from 'shared/hooks';
import { useCreateChildCodeMutation } from 'generated/graphql';

import useTranslatedTexts from 'pages/InfoPages/ChildCodePage/useTranslatedTexts';
import { getCredentialAccountId } from 'core/credential/credential.service';

const DEFAULT_EXPIRES_TIME = 7 * 60 * 60 * 24;
const COPY_SUCCESS_TOAST_VISIBLE_TIME = 3000;

const codeTextStyle = {
  fontSize: '24px',
  lineHeight: '36px',
};

const copyIconStyle = {
  top: '7px',
};

interface ChildCodeDialogContentProps {
  childId: string;
  onClose: () => void;
}

const ChildCodeDialogContent = ({
  childId,
  onClose,
}: ChildCodeDialogContentProps): JSX.Element => {
  const accountId = getCredentialAccountId();
  const texts = useTranslatedTexts();
  const [copyState, copyToClipboard] = useCopyToClipboard();
  const [toastVisibility, setToastVisibility] = useState(false);
  const [expireAt, setExpireAt] = useState<string>(
    dayjs().add(7, 'day').toISOString()
  );

  const { time, set: setTime } = useTimer(DEFAULT_EXPIRES_TIME);

  const [createChildCode, { data }] = useCreateChildCodeMutation({
    variables: {
      id: childId,
    },
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const childCode = response?.createChildCode;
      if (childCode) {
        const expiresTime = dayjs(childCode.expireAt).diff(dayjs(), 'second');
        setExpireAt(childCode.expireAt);
        setTime(expiresTime);
      } else {
        setTime(0);
      }
    },
  });
  const childCode = data?.createChildCode?.code ?? '';
  const showToast = toastVisibility && !copyState.error;

  useEffect(() => {
    createChildCode();
  }, [createChildCode]);

  const hideToast = useCallback(() => {
    setToastVisibility(false);
  }, []);

  const handleClickCopyIcon = () => {
    copyToClipboard(childCode);
    setToastVisibility(true);
  };

  const handlePressRenewCodeButton = () => {
    createChildCode();
  };

  return (
    <HoverDialogContent
      testId="child-code-dialog-content"
      title={texts.title.childCode}
      description={texts.message.parentGuide}
      onCloseClick={onClose}
    >
      <Box className="flex flex-col gap-3 py-2">
        <Box className="flex flex-row justify-center">
          <Box className="relative">
            <Box
              data-testid="child-code"
              className="font-bold"
              style={codeTextStyle}
            >
              {childCode}
            </Box>
            {childCode ? (
              <Box
                data-testid="copy-child-code"
                is="span"
                className="absolute -right-3 transform translate-x-full cursor-pointer"
                style={copyIconStyle}
                onClick={handleClickCopyIcon}
              >
                <ContentCopyIcon.View {...ContentCopyIcon.size} />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box is="p" className="text-center text-red-600">
          {texts.message.timeLimit(expireAt)}
        </Box>
        <Box className="flex flex-row justify-center">
          <RenewChildCodeButton
            testId="child-code-dialog-content-renew-button"
            onClick={handlePressRenewCodeButton}
            disabled={time > 0}
            event={{
              name: 'ChildCodeRenewed',
              on: 'click',
              properties: {
                userId: accountId,
              },
            }}
          >
            {texts.button.renewChildCode}
          </RenewChildCodeButton>
        </Box>
      </Box>
      {showToast && (
        <Toast
          message={texts.message.codeCopied}
          duration={COPY_SUCCESS_TOAST_VISIBLE_TIME}
          startAdornment={<CheckboxCircle.View {...CheckboxCircle.size} />}
          onDismiss={hideToast}
        />
      )}
    </HoverDialogContent>
  );
};

export default ChildCodeDialogContent;
