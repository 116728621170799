import React from 'react';

import { Box, BoxProps } from 'designSystem/ions';
import { Without } from 'types/utilities';

type DividerBoxProps = Without<BoxProps<'hr'>, 'is'>;
export type DividerProps = DividerBoxProps;

const Divider = ({ className, ...props }: DividerProps) => {
  const baseStyle = `border-t-1px border-gray-300 w-full ${className}`;
  return <Box is="hr" className={baseStyle} {...props} />;
};

export default React.memo(Divider);
