/* istanbul ignore file */

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import koKR from './ko-KR/translations.json';
import en from './en/translations.json';
import es from './es/translations.json';
import idID from './id-ID/translations.json';
import jaJP from './ja-JP/translations.json';
import ruRU from './ru-RU/translations.json';
import thTH from './th-TH/translations.json';
import viVN from './vi-VN/translations.json';
import zhCN from './zh-CN/translations.json';
import zhTW from './zh-TW/translations.json';

import { KEY_I18NEXT_LNG } from 'shared/constants';

const translationsJson = {
  ko: {
    translation: koKR,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  id: {
    translation: idID,
  },
  ja: {
    translation: jaJP,
  },
  ru: {
    translation: ruRU,
  },
  th: {
    translation: thTH,
  },
  vi: {
    translation: viVN,
  },
  'zh-CN': {
    translation: zhCN,
  },
  'zh-TW': {
    translation: zhTW,
  },
};

const koRegex = /^ko[-_]?\w/;
const enRegex = /^en[-_]?\w/;
const esRegex = /^es[-_]?\w/;
const zhRegex = /^zh[-_]?\w/;

const generalRegex = /^(\w{2,3})[-_]?/;

const fallbackLng = (code: string) => {
  if (!code) {
    return ['ko'];
  }
  const fallbacks = [code];
  if (koRegex.test(code)) {
    return ['ko'];
  }
  if (enRegex.test(code) || code === 'uk') {
    return ['en'];
  }
  if (esRegex.test(code)) {
    return ['es'];
  }
  if (zhRegex.test(code) && !['zh-TW'].includes(code)) {
    fallbacks.push('zh-CN');
    fallbacks.push('ko');
    return fallbacks;
  }

  const matchResult = code.match(generalRegex);
  if (matchResult) {
    const [, langPart] = matchResult;
    if (langPart !== code) {
      fallbacks.push(langPart);
    }
    if (langPart !== 'en') {
      fallbacks.push('en');
    }
  }
  return fallbacks;
};

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      react: {
        wait: true,
        useSuspense: false,
      },
      pluralSeparator: '|',
      resources: translationsJson,
      fallbackLng: fallbackLng,
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        formatSeparator: ',',
      },

      detection: {
        order: ['querystring', 'sessionStorage', 'navigator'],

        // cache user language on
        caches: ['sessionStorage'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupSessionStorage: KEY_I18NEXT_LNG,
      },
    },
    (err) => {
      if (err) {
        return console.log('something went wrong loading', err);
      }

      const cachedLng = window.sessionStorage.getItem(KEY_I18NEXT_LNG);

      if (cachedLng) {
        document.documentElement.setAttribute('lang', cachedLng);
      }
    }
  );

export default i18next;
