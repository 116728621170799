/* istanbul ignore file */

import React from 'react';

import { gray } from '../../colors.cjs';

const size = {
  width: '24px',
  height: '24px',
};

function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      role="img"
    >
      <path
        d="M3 12H21M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
        stroke={gray[600]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(ArrowLeftIcon),
};

export default Asset;
