import { UserProfile } from 'oidc-client-ts';

import {
  CLASSTING_OIDC_CLIENT_ID,
  CLASSTING_OIDC_URL,
} from '../../shared/constants';

export const KEY_CREDENTIAL = `oidc.user:${CLASSTING_OIDC_URL}/oidc:${CLASSTING_OIDC_CLIENT_ID}`;
export interface CredentialParamObject {
  token_type: string;
  access_token: string;
  expires_in: string;
  session_state: string;
  scope: string;
  id_token: string;
  [key: string]: string;
}

export interface CredentialObject
  extends Omit<CredentialParamObject, 'expires_in'> {
  expires_at: string;
}

export interface CredentialUser {
  id_token?: string;
  session_state?: string | null;
  access_token: string;
  refresh_token?: string;
  token_type: string;
  scope?: string;
  profile: UserProfile;
  expires_at?: number;
  userState?: unknown;
}
