import { useTranslation } from 'react-i18next';

type useTranslatedTextsReturn = {
  checkList: { text: string }[];
  checkLabel: string;
  CTATitle: string;
  CTADesc: string;
  cancelButton: string;
  deleteButton: string;
};

const useTranslatedTexts = (): useTranslatedTextsReturn => {
  const { t } = useTranslation();
  const checkList = [
    { text: t('account.delete_account.agree.message.1') },
    { text: t('account.delete_account.agree.message.2') },
    { text: t('account.delete_account.agree.message.3') },
    { text: t('account.delete_account.agree.message.4') },
  ];

  const checkLabel = t('account.delete_account.agree.check.all');
  const CTATitle = t('account.delete_account.agree.title');
  const CTADesc = t('account.delete_account.agree.description');
  const cancelButton = t('account.button.cancel');
  const deleteButton = t('account.delete_account.agree.button.delete_account');

  return {
    checkList,
    checkLabel,
    CTATitle,
    CTADesc,
    cancelButton,
    deleteButton,
  };
};

export default useTranslatedTexts;
