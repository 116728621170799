/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const message = {
    notSaved: t('account.error.message.not_saved'),
  };

  const button = {
    leave: t('account.button.leave'),
    cancel: t('account.button.cancel'),
  };

  return { message, button };
};

export default useTranslatedTexts;
