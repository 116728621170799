import React, { ReactElement, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import OrganizationInput from '../components/OrganizationInput';

import GradeInput from '../components/GradeInput';

import useTranslatedTexts from '../useTranslatedTexts';

import {
  OrganizationInputAccountFieldsFragment,
  OrganizationInputOrganizationFieldsFragment,
  SchoolGrade,
  useMarkOrgAsPrimaryMutation,
  useUpdateAccountMutation,
} from 'generated/graphql';
import { Box, useIsInSmScreen } from 'designSystem/ions';
import { SectionPanel, useModalContext } from 'designSystem/organisms';
import { Button } from 'designSystem/atoms';

type OrganizationInfoPanelProps = {
  account: OrganizationInputAccountFieldsFragment;
};

type FormValues = {
  orgId: string;
  grade: SchoolGrade;
};

const schema = yup.object().shape({
  orgId: yup.string().optional(),
  grade: yup.string().optional(),
});

const OrganizationInfoPanel = ({
  account,
}: OrganizationInfoPanelProps): ReactElement => {
  const texts = useTranslatedTexts();
  const { openModal } = useModalContext();
  const accountSchool = account.schoolInfo
    ?.school as OrganizationInputOrganizationFieldsFragment;
  const primaryOrg = accountSchool ?? null;
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrganizationInputOrganizationFieldsFragment | null | undefined
  >(primaryOrg);
  const isSmScreen = useIsInSmScreen();
  const [markOrgAsPrimary] = useMarkOrgAsPrimaryMutation();
  const [updateAccount] = useUpdateAccountMutation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { isDirty, dirtyFields, isSubmitting, errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      orgId: account.schoolInfo?.school?.id,
      grade: (account.grade?.toUpperCase() as SchoolGrade) ?? SchoolGrade.None,
    },
  });

  const handleChangeOrganization = (
    organization: OrganizationInputOrganizationFieldsFragment
  ) => {
    setSelectedOrganization(organization);
  };

  const handleSubmitInfo = async ({ orgId, grade }: FormValues) => {
    try {
      if (dirtyFields['orgId']) {
        await markOrgAsPrimary({
          variables: {
            orgId,
          },
        });
      }

      if (dirtyFields['grade']) {
        await updateAccount({
          variables: {
            id: account.id,
            updateAccountDtoInput: {
              grade,
            },
          },
        });
      }

      openModal({
        status: 'success',
        message: texts.message.successChange,
      });

      reset({ orgId: orgId, grade });

      return Promise.resolve();
    } catch (error) {
      Sentry.captureException(error);
      return Promise.reject();
    }
  };

  return (
    <SectionPanel.Wrapper className="w-full pt-8">
      <SectionPanel.Body>
        <Box className="w-full">
          <Box className="text-body-2 sm:text-body-1 text-gray-900 font-medium">
            {texts.subtitle.myOrganizations}
          </Box>
          <form onSubmit={handleSubmit(handleSubmitInfo)}>
            <Controller
              control={control}
              name="orgId"
              render={({ field: { onChange } }) => {
                return (
                  <OrganizationInput
                    account={account}
                    primaryOrganization={selectedOrganization}
                    onChangeOrganization={(
                      organization: OrganizationInputOrganizationFieldsFragment
                    ) => {
                      onChange(organization.id);
                      handleChangeOrganization(organization);
                    }}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="grade"
              render={({ field: { onChange, value } }) => {
                return (
                  <GradeInput
                    grade={value}
                    onChangeGrade={(grade: SchoolGrade) => {
                      onChange(grade);
                    }}
                    hasError={Boolean(errors.grade)}
                  />
                );
              }}
            />

            <Button
              type="submit"
              className="bottom-0 mt-10"
              appearance="contained"
              disabled={!isDirty || isSubmitting}
              size="lg"
              fullWidth
              rounded
            >
              {texts.button.saveChange}
            </Button>
          </form>
        </Box>
      </SectionPanel.Body>
    </SectionPanel.Wrapper>
  );
};

export default OrganizationInfoPanel;
