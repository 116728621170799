import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from 'designSystem/ions';
import { Anchor, Divider } from 'designSystem/atoms';

interface FooterLinkTextProps {
  linkText: string;
  linkUrl: string;
  fontWeight?: string;
}

const FooterLinkText = ({
  linkText,
  linkUrl,
  fontWeight = 'font-medium',
}: FooterLinkTextProps) => {
  const footerStyle = `flex items-center justify-center h-full text-body-3 text-gray-600 ${fontWeight}`;

  return (
    <Anchor
      className={footerStyle}
      href={linkUrl}
      target="_blank"
      rel="noopener noreferer nofollow"
    >
      {linkText}
    </Anchor>
  );
};

const GlobalFooter = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const allowLanguage: { [key: string]: string } = {
    ko: 'ko',
    en: 'en',
    'ja-JP': 'ja',
    'zh-TW': 'zh-tw',
    'vi-VN': 'vi',
  };
  const language = allowLanguage[i18n.language] ?? 'en';

  const serviceText = t('account.footer.terms.service');
  const privacyText = t('account.footer.terms.privacy');
  const helpText = t('account.footer.help');
  const copyrightText = t('account.footer.copyright');

  return (
    <Box
      is="div"
      className="relative w-full h-full"
      data-testid="global-footer"
    >
      <Divider />
      <Box is="div" className="flex flex-col px-4 pt-4 pb-6 gap-y-2">
        <Box is="div" className="flex flex-wrap flex-row gap-y-2 xs:flex-col">
          <Box is="p" className="text-body-3 text-gray-600 font-regular mr-4">
            {copyrightText}
          </Box>
          <Box
            is="div"
            className="flex flex-wrap gap-x-2 items-center break-all h-full"
          >
            <FooterLinkText
              linkText={serviceText}
              linkUrl={`https://policies.classting.com/${language}/terms`}
            />
            <Box is="i" className="h-3 border-r border-gray-400" />
            <FooterLinkText
              fontWeight="font-bold"
              linkText={privacyText}
              linkUrl={`https://policies.classting.com/${language}/privacy`}
            />
            <Box is="i" className="h-3 border-r border-gray-400" />
            <FooterLinkText
              linkText={helpText}
              linkUrl="https://support.classting.com/hc/ko"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(GlobalFooter);
