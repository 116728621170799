/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';

import {
  RequestMobileChangeParam,
  RequestMobileChangeResponse,
  ChangeMobileParam,
  UpdateAccountParam,
  ChangeRoleParam,
} from '../user/user.model';

import { Account } from './account.model';

import { create } from 'core/axios';
import { API_URL } from 'shared/constants';

const axiosInstance = create({
  baseURL: `${API_URL}/account-service/accounts`,
});

export async function fetchMyAccount() {
  const response = await axiosInstance.get<Account>('/me');
  return response.data;
}

export async function deleteAccount(userId: string) {
  const response = await axiosInstance.delete<Account>(`/${userId}`);
  return response.data;
}

export async function updateAccount({ userId, data }: UpdateAccountParam) {
  const response = await axiosInstance.patch<Account>(`/${userId}`, data);
  return response.data;
}

export async function updatePassword({
  userId,
  password,
  newPassword,
  newPasswordConfirm,
}: {
  userId: string;
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
}) {
  return axiosInstance.put<void>(`/${userId}/password`, {
    password,
    newPassword,
    newPasswordConfirm,
  });
}

export function requestEmailUpdate({
  userId,
  email,
  isEnterprise,
}: {
  userId: string;
  email: string;
  isEnterprise?: boolean;
}) {
  return axiosInstance.post<void>(`/${userId}/request-email-change`, {
    email,
    isEnterprise,
  });
}

export function updateEmail({
  userId,
  code,
}: {
  userId: string;
  code: string;
}) {
  return axiosInstance.post<void>(`/${userId}/change-email`, {
    code,
  });
}

export async function requestMobileChange({
  userId,
  data,
  isEnterprise,
}: RequestMobileChangeParam) {
  const response = await axiosInstance.post<RequestMobileChangeResponse>(
    `/${userId}/request-mobile-change`,
    {
      ...data,
      isEnterprise,
    }
  );
  return response.data;
}

export async function changeMobile({ userId, data }: ChangeMobileParam) {
  await axiosInstance.post<void>(`/${userId}/change-mobile`, data);
}

export async function changeRole(data: ChangeRoleParam) {
  const formFields = {
    user_id: 'entry.1545128597',
    user_email: 'entry.658083278',
    user_role: 'entry.1837266912',
    report_role: 'entry.984622172',
    report_reason: 'entry.219181179',
  };
  const params = (Object.entries(formFields) as [
    keyof typeof formFields,
    string
  ][])
    .map(([field, entry]) => {
      return entry + '=' + data[field];
    })
    .join('&');

  return axios.post(
    'https://docs.google.com/forms/d/e/1FAIpQLSdCMJEP4NiRz4ZhVIJRnmpoW3H5tJYjttSKAjhNCB4XRS6sug/formResponse',
    encodeURI(params),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
}
