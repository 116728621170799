import React from 'react';

import { GlobalFooter } from 'designSystem/organisms';
import { GlobalSection, SingleColumnLayout } from 'designSystem/layouts';
import { useIsClasstingMobileApp } from 'shared/hooks/platform';
import { WithChildrenProp } from 'types/utilities';

type SingleColumnPageTemplateProps = {
  headerChild?: React.ReactNode;
  showFooter?: boolean;
  hasHeaderShadow?: boolean;
  hasBodyTopSpacing?: boolean;
};

const SingleColumnPageTemplate = ({
  children,
  showFooter = true,
  headerChild,
  hasHeaderShadow = true,
  hasBodyTopSpacing = true,
}: WithChildrenProp<SingleColumnPageTemplateProps>): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();

  return (
    <GlobalSection.Wrapper>
      {headerChild && (
        <GlobalSection.Header
          className="bg-white"
          title="클래스팅"
          keepShadow={hasHeaderShadow}
        >
          {headerChild}
        </GlobalSection.Header>
      )}
      <GlobalSection.Body
        className="bg-white sm:bg-gray-50"
        hasTopSpacing={hasBodyTopSpacing}
      >
        <SingleColumnLayout>{children}</SingleColumnLayout>
      </GlobalSection.Body>
      {!isClasstingMobileApp && showFooter && (
        <GlobalSection.Footer>
          <GlobalFooter />
        </GlobalSection.Footer>
      )}
    </GlobalSection.Wrapper>
  );
};

export default SingleColumnPageTemplate;
