/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

import React, { ComponentPropsWithRef } from 'react';

import { Anchor } from 'designSystem/atoms';

import {
  useMakeButtonStyle,
  ButtonLikeComponentProps,
} from 'designSystem/atoms/Button/Button';

import { WithChildrenProp } from 'types/utilities';
import { Box } from 'designSystem/ions';

type AnchorProps = ComponentPropsWithRef<typeof Anchor>;
type AnchorButtonProps = WithChildrenProp<
  AnchorProps & ButtonLikeComponentProps
>;

const handleSpaceKeyUp = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
  if (event.key === ' ') {
    return event.currentTarget.click();
  }
  /** for ie 11 compatibility  **/
  if (event.charCode == 32) {
    return event.currentTarget.click();
  }
  if (event.keyCode == 32) {
    return event.currentTarget.click();
  }
  /** for ie 11 compatibility  **/
};

const AnchorButton = (
  props: AnchorButtonProps,
  ref: React.Ref<HTMLAnchorElement>
): JSX.Element => {
  const {
    theme = 'primary',
    appearance = 'outlined',
    size = 'md',
    disabled = false,
    type = 'button',
    children,
    onClick,
    testId,
    className = '',
    iconPosition,
    ...restProps
  } = props;

  const { buttonStyle, textStyle } = useMakeButtonStyle(props);
  return (
    <Anchor
      className={buttonStyle}
      role="button"
      onKeyUp={handleSpaceKeyUp}
      data-testid={testId}
      ref={ref}
      onClick={onClick}
      {...restProps}
    >
      <Box is="span" className={textStyle}>
        {children}
      </Box>
    </Anchor>
  );
};

export default React.memo(React.forwardRef(AnchorButton));
