import React from 'react';
import { RouteProps } from 'react-router-dom';

import { sessionStorageService } from 'core/storage';
import { getCredentialAccountId } from 'core/credential/credential.service';
import { CLASSTING_OIDC_URL } from 'shared/constants';
import { FullScreenLoader } from 'designSystem/templates';

interface LocationState {
  from: string;
}

const ChallengeRedirectPage = ({ location }: RouteProps): JSX.Element => {
  const locationState: LocationState = location?.state as LocationState;
  const redirectUrl = locationState?.from ?? '/';
  const id = getCredentialAccountId();

  sessionStorageService.setItem('redirect', redirectUrl);

  const challengeCallbackUrl = `${window.location.origin}/account/challenge-callback`;

  window.location.replace(
    `${CLASSTING_OIDC_URL}/login-provider/challenge?id=${id}&redirect_uri=${challengeCallbackUrl}`
  );
  return <FullScreenLoader />;
};

export default ChallengeRedirectPage;
