import React, { useState, useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { State, Location, Action } from 'history';
import { Prompt, useHistory } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import { useModalContext, SimpleModal } from 'designSystem/organisms';
import { Button } from 'designSystem/atoms';
import { useIsInMobile } from 'designSystem/ions';
import { useHistoryBackHandler } from 'shared/hooks/history';

type RouteLeavingGuardProps = {
  when: boolean;
};

const RouteLeavingGuard = ({ when }: RouteLeavingGuardProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const isMobile = useIsInMobile();
  const history = useHistory();
  const historyHandler = useHistoryBackHandler();
  const [lastLocation, setLastLocation] = useState<Location<State>>();
  const [lastAction, setLastAction] = useState<Action>();
  const [isConfirmedNavigate, setIsConfirmedNavigate] = useState(false);
  const { openModal, closeModal } = useModalContext();

  const showModal = (location: Location<State>, action: Action) => {
    const handleClickClose = () => {
      hideModal();
    };
    const handleClickLeave = () => {
      hideModal(() => {
        setIsConfirmedNavigate(true);
      });
    };

    openModal({
      status: 'default',
      message: texts.message.notSaved,
      buttonGroup: (
        <SimpleModal.ButtonGroup>
          <Button
            testId="route-leaving-guard__close-button"
            theme="white"
            appearance={isMobile ? 'text' : 'outlined'}
            onClick={handleClickClose}
          >
            {texts.button.cancel}
          </Button>
          <Button
            testId="route-leaving-guard__leave-button"
            theme="danger"
            appearance={isMobile ? 'text' : 'outlined'}
            onClick={handleClickLeave}
          >
            {texts.button.leave}
          </Button>
        </SimpleModal.ButtonGroup>
      ),
    });
    setLastLocation(location);
    setLastAction(action);
  };

  const handleBlockedNavigation = (
    location: Location<State>,
    action: Action
  ): boolean => {
    if (!isConfirmedNavigate) {
      showModal(location, action);
      return false;
    }
    return true;
  };

  const hideModal = (callback?: () => void) => {
    closeModal();
    callback?.();
  };

  /* istanbul ignore next */
  useBeforeunload((event) => {
    if (when) {
      event.preventDefault();
    }
  });

  /* istanbul ignore next */
  useEffect(() => {
    if (isConfirmedNavigate) {
      if (lastAction === 'POP') {
        historyHandler.go(-1);
      } else if (lastAction === 'PUSH') {
        history.push(lastLocation?.pathname ?? '');
      } else if (lastAction === 'REPLACE') {
        history.replace(lastLocation?.pathname ?? '');
      }
      setIsConfirmedNavigate(false);
    }
  }, [history, historyHandler, isConfirmedNavigate, lastAction, lastLocation]);

  return <Prompt when={when} message={handleBlockedNavigation} />;
};

export default RouteLeavingGuard;
