import React from 'react';

import useTranslatedTexts from './useTranslatedTexts';

import { EnterpriseChangeEmailForm } from './EnterpriseChangeEmailForm';

import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { Box } from 'designSystem/ions';
import { EnterpriseSingleColumnPageTemplate } from 'designSystem/templates';
import {
  EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment,
  useEnterpriseChangeEmailPageQuery,
} from 'generated/graphql';
import ForbiddenErrorPage from 'pages/ErrorPages/ForbiddenErrorPage';

type ChangeEmailProps = {
  account: Account;
};
const EnterpriseChangeEmail = ({ account }: ChangeEmailProps): JSX.Element => {
  const texts = useTranslatedTexts();

  const { data: { enterprise } = {} } = useEnterpriseChangeEmailPageQuery({
    variables: {
      enterpriseId: account?.enterprise?.id ?? '',
    },
    skip: !account?.enterprise,
  });

  if (!account.enterprise) {
    return <ForbiddenErrorPage />;
  }

  return (
    <EnterpriseSingleColumnPageTemplate
      title={texts.changeEmail.title}
      desc={texts.changeEmail.description}
      enterprise={
        enterprise as EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment
      }
    >
      <Box is="div" className="flex flex-col items-center w-full">
        <Box className="w-full" data-testid="change-email-form">
          <EnterpriseChangeEmailForm account={account} />
        </Box>
      </Box>
    </EnterpriseSingleColumnPageTemplate>
  );
};

export default withAccount(EnterpriseChangeEmail);
