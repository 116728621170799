import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const CTATitle = t('account.delete_account.done.title');
  const CTADesc = t('account.delete_account.done.description');
  const confirmButton = t('account.button.confirm');
  return {
    CTATitle,
    CTADesc,
    confirmButton,
  };
};

export default useTranslatedTexts;
