import React, { useMemo, useRef } from 'react';

import classNames from 'classnames';

import { useFieldArray } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import useTranslatedTexts from '../useTranslatedTexts';
import { EMPTY_SCHOOL_INFO } from '../constants';

import {
  ChildInfoForm,
  ChildInfoFormSchema,
  childInfoFormSchema,
} from './ChildInfoForm';

import { useModalContext, SectionPanel } from 'designSystem/organisms';

import { Button } from 'designSystem/atoms';
import { Plus } from 'designSystem/ions/Icons/solid';
import { Box, useIsInSmScreen } from 'designSystem/ions';

import { Account, useMyAccount } from 'shared/data/account';
import { useFormWithResolver } from 'shared/hooks';
import { isEmptyObject } from 'shared/utils';
import { createTrackEventProps } from 'shared/analytics/utils';

import { RouteLeavingGuard } from 'router/RouteLeavingGuard';

import {
  SchoolGrade,
  useUpdateChildSchoolInfoMutation,
} from 'generated/graphql';

const checkIfHasChanges = (
  defaultValues: ChildInfoFormSchema,
  fieldValues: ChildInfoFormSchema
): boolean => {
  const refinedFieldValues: ChildInfoFormSchema = {
    schoolInfoForChildren: fieldValues.schoolInfoForChildren.filter(
      (item) => item.school.id !== ''
    ),
  };

  return !isEqual(defaultValues, refinedFieldValues);
};

const getChildInfoFormDefaultValue = (
  account: Account
): ChildInfoFormSchema => {
  if (account.schoolInfoDtoForChildren.length === 0) {
    return {
      schoolInfoForChildren: [EMPTY_SCHOOL_INFO],
    };
  }

  return {
    schoolInfoForChildren: account.schoolInfoDtoForChildren.map(
      (schoolInfo) => {
        return {
          ...schoolInfo,
          grade: schoolInfo.grade.toUpperCase() as SchoolGrade,
        };
      }
    ),
  };
};

const ChildInfoPanel = ({ account }: { account: Account }): JSX.Element => {
  const { isLoading: isRefetching, refetch } = useMyAccount({ enabled: false });
  const texts = useTranslatedTexts();
  const { openModal } = useModalContext();
  const isActiveRouteLeaveGuard = useRef(false);
  const isSmScreen = useIsInSmScreen();

  const [
    updateChildSchoolInfo,
    updateChildSchoolInfoMutation,
  ] = useUpdateChildSchoolInfoMutation();

  const childInfoFormDefaultValues = useMemo(
    () => getChildInfoFormDefaultValue(account),
    [account]
  );

  const childInfoForm = useFormWithResolver<ChildInfoFormSchema>({
    schema: childInfoFormSchema,
    defaultValues: childInfoFormDefaultValues,
  });

  const {
    formState: childInfoFormState,
    handleSubmit: childInfoFormHandleSubmit,
    reset: childInfoFormReset,
    watch: childInfoFormWatch,
    control: childInfoFormControl,
    getValues: childInfoFormGetValues,
  } = childInfoForm;
  const { errors: childInfoFormErrors } = childInfoFormState;

  const schoolInfoForChildren = childInfoFormWatch('schoolInfoForChildren');

  const childInfoFormHasErrors = !isEmptyObject(childInfoFormErrors);
  const childInfoFormHasChanges = checkIfHasChanges(
    childInfoFormDefaultValues,
    childInfoFormGetValues()
  );

  const {
    append: appendSchoolInfoForChildren,
    update: updateSchoolInfoForChildren,
  } = useFieldArray({
    control: childInfoFormControl,
    name: 'schoolInfoForChildren',
  });

  const addChildButtonDisabled = schoolInfoForChildren.length >= 5;

  const saveButtonDisabled =
    updateChildSchoolInfoMutation.loading ||
    isRefetching ||
    !childInfoFormHasChanges ||
    childInfoFormHasErrors;

  const handleClickAddChildButton = () => {
    appendSchoolInfoForChildren(EMPTY_SCHOOL_INFO);
  };

  const toggleVisibleError = (index: number) => {
    updateSchoolInfoForChildren(index, {
      ...schoolInfoForChildren[index],
      isErrorVisible: true,
    });
  };

  const validChildSubmit = () => {
    const isExistNoneGrade = schoolInfoForChildren.some(
      (schoolInfo) =>
        schoolInfo.grade === 'NONE' &&
        schoolInfo.school.regularCourseYear !== undefined &&
        schoolInfo.school.regularCourseYear > 0
    );

    if (isExistNoneGrade) {
      schoolInfoForChildren.forEach((schoolInfo, index) => {
        toggleVisibleError(index);
      });
      return;
    }

    return handleSubmitPersonalInfoForm();
  };

  const handleSubmitPersonalInfoForm = childInfoFormHandleSubmit(
    ({ schoolInfoForChildren }: ChildInfoFormSchema) => {
      updateChildSchoolInfo({
        variables: {
          id: account.id,
          updateChildSchoolInfoDtoInput: {
            schoolInfoForChildren: schoolInfoForChildren
              .filter((schoolInfo) => schoolInfo.school.id !== '')
              .map((schoolInfo) => ({
                grade: schoolInfo.grade,
                schoolId: schoolInfo.school.id,
              })),
          },
        },
        onCompleted: () => {
          refetch().then(({ data }) => {
            if (data) {
              childInfoFormReset(getChildInfoFormDefaultValue(data));
            }
          });

          if (!isActiveRouteLeaveGuard.current) {
            openModal({
              status: 'success',
              message: texts.message.successChange,
            });
          }
        },
      });
    }
  );

  return (
    <SectionPanel.Wrapper
      testId="child-info-panel"
      className="w-full sm:mt-4 sm:mb-20"
    >
      <SectionPanel.Body>
        <Box className="w-full flex flex-row justify-between items-center">
          <Box className="text-body-2 sm:text-body-1 text-gray-900 font-medium">
            {texts.subtitle.childInfo}
          </Box>
          <Button
            data-testid="add-child-button"
            onClick={handleClickAddChildButton}
            disabled={addChildButtonDisabled}
            appearance="text"
            theme="white"
            {...createTrackEventProps({
              name: 'AddChildInfoClicked',
              on: 'click',
              properties: {
                userId: account.id,
                role: account.role,
              },
            })}
          >
            <Box
              className={classNames('flex flex-row gap-2', {
                'text-gray-400': addChildButtonDisabled,
              })}
            >
              <Plus.View width={Plus.size.width} height={Plus.size.height} />
              <Box>{texts.button.addChild}</Box>
            </Box>
          </Button>
        </Box>
        <ChildInfoForm account={account} formProps={childInfoForm} />
        <Button
          testId="childinfo-submit-form-button"
          className="bottom-0 mt-10"
          appearance="contained"
          disabled={saveButtonDisabled}
          size={/* istanbul ignore next */ isSmScreen ? 'md' : 'lg'}
          fullWidth
          rounded
          onClick={validChildSubmit}
          {...createTrackEventProps({
            name: 'ChildInfoSaveClicked',
            on: 'click',
            properties: {
              userId: account.id,
              role: account.role,
            },
          })}
        >
          {texts.button.saveChange}
        </Button>
      </SectionPanel.Body>
      <RouteLeavingGuard when={childInfoFormHasChanges} />
    </SectionPanel.Wrapper>
  );
};

export default ChildInfoPanel;
