/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

class MemoryStorage implements Storage {
  private storage: {
    [key: string]: string;
  } = {};

  private static instance: MemoryStorage;
  public static getInstance(): MemoryStorage {
    if (!MemoryStorage.instance) {
      MemoryStorage.instance = new MemoryStorage();
    }
    return MemoryStorage.instance;
  }

  getItem = (key: string) => {
    return this.storage[key] !== undefined ? this.storage[key] : null;
  };

  setItem = (key: string, val = '') => {
    this.storage[key] = val + '';
  };

  removeItem = (key: string) => {
    delete this.storage[key];
  };

  clear = () => {
    this.storage = {};
  };

  key = (idx: number) => Object.keys(this.storage)[idx] || null;

  get length(): number {
    return Object.keys(this.storage).length;
  }

  get __STORE__(): {
    [key: string]: string;
  } {
    return this.storage;
  }
}

const memoryStorage = MemoryStorage.getInstance();

export default memoryStorage;
