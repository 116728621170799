/* istanbul ignore file */

export const API_URL = process.env.REACT_APP_API_URL;
export const CLASSTING_HOME_URL =
  process.env.REACT_APP_CLASSTING_HOME_URL || 'www.classting.com';
export const LEGACY_CLASSTING_URL = process.env.REACT_APP_LEGACY_CLASSTING_URL;

export const CLASSTING_OIDC_CLIENT_ID =
  process.env.REACT_APP_CLASSTING_OIDC_CLIENT_ID;
export const CLASSTING_OIDC_REDIRECT_URL =
  process.env.REACT_APP_CLASSTING_OIDC_REDIRECT_URL;
export const CLASSTING_OIDC_RESPONSE_TYPE =
  process.env.REACT_APP_CLASSTING_OIDC_RESPONSE_TYPE;
export const CLASSTING_OIDC_URL = process.env.REACT_APP_CLASSTING_OIDC_URL;
export const LOGOUT_REDIRECT_URL = process.env.REACT_APP_LOGOUT_REDIRECT_URL;

export const CLASSTING_FEDERATED_LOGOUT_URL =
  process.env.REACT_APP_CLASSTING_FEDERATED_LOGOUT_URL;

export const LINK_GUARDIAN_WEB_TO_APP_PAGE_URL =
  process.env.REACT_APP_LINK_GUARDIAN_WEB_TO_APP_PAGE_URL;

export const BASE_URL = process.env.REACT_APP_BASE_URL;
