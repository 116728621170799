import { useState } from 'react';

import { Field, FieldErrors } from './useFormRegister';

import { HelperTextStatus, HelperTextIcon } from 'designSystem/atoms';

type HelperTextStatusMap = {
  [key: string]: {
    status: HelperTextStatus;
    visible: boolean;
    icon: HelperTextIcon;
  };
};

export type HelperTextStatusByFieldStatus = {
  helperTextStatus: HelperTextStatusMap;
  hasFocus: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const useHelperTextStatusByFieldStatus = (
  field: Field,
  errors: FieldErrors
): HelperTextStatusByFieldStatus => {
  const [hasFocus, setHasFocus] = useState(false);
  const [inChange, setInChange] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    setInChange(true);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    setHasFocus(false);
    setInChange(false);
  };

  const helperTextStatus: HelperTextStatusMap = {};

  for (const [type, isError] of Object.entries(errors)) {
    let status: HelperTextStatus = 'default';
    let visible = false;
    let icon: HelperTextIcon = '';
    const isRestError = Object.keys(errors)
      .filter((key) => key !== type)
      .every((key) => errors[key]);

    if (hasFocus) {
      if ((inChange || field.value) && !isError && !isRestError) {
        visible = false;
      } else {
        visible = true;
        if (field.value) {
          icon = isError ? 'x' : 'check';
          status = isError ? 'default' : 'success';
        } else {
          icon = '';
        }
        if (inChange) {
          status = isError ? 'default' : 'success';
          if (field.value) {
            icon = isError ? 'x' : 'check';
          } else {
            icon = '';
          }
        }
      }
    } else {
      if (isError) {
        visible = true;
        status = 'error';
        icon = 'x';
      } else {
        if (isRestError && field.value) {
          visible = true;
          status = 'success';
          icon = 'check';
        }
      }
    }
    helperTextStatus[type] = { status, visible, icon };
  }

  return {
    hasFocus,
    helperTextStatus,
    handleChange,
    handleFocus,
    handleBlur,
  };
};

export default useHelperTextStatusByFieldStatus;
