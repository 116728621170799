import React from 'react';

import { useHistory } from 'react-router-dom';

import toast from 'react-hot-toast';

import classnames from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';

import {
  RelationshipType,
  SimpleAccountDto,
  useLinkGuardianPageSetGuardianRelationshipMutation,
} from 'generated/graphql';
import { Box, useIsInMobile } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import { UserWithHeartIcon } from 'designSystem/ions/Icons/outlined';
import useTranslatedTexts from 'pages/LinkGuardianPage/useTranslatedTexts';
import { HoverDialogContent } from 'designSystem/molecules';
import { CheckboxCircle } from 'designSystem/ions/Icons/fill';
import { createTrackEventProps } from 'shared/analytics/utils';

const RELATIONSHIP_OPTIONS = [
  RelationshipType.Mother,
  RelationshipType.Father,
  RelationshipType.GrandFather,
  RelationshipType.GrandMother,
  RelationshipType.Guardian,
];

type SetGuardianRelationshipProps = {
  guardianAccount: SimpleAccountDto;
  accountId: string;
};

const SetRelationship = ({
  guardianAccount,
  accountId,
}: SetGuardianRelationshipProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const isInMobile = useIsInMobile();
  const history = useHistory();
  const [
    guardianRelationship,
    setGuardianRelationship,
  ] = React.useState<RelationshipType>();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [linkGuardian] = useLinkGuardianPageSetGuardianRelationshipMutation();

  const handleClickLink = () => {
    if (guardianRelationship === undefined) {
      /* istanbul ignore next */
      return;
    }

    linkGuardian({
      variables: {
        guardianId: guardianAccount.id,
        relationship: guardianRelationship,
      },
      onError: handleLinkFailure,
      onCompleted: handleLinkSuccess,
    });
  };

  const handleLinkFailure = () => {
    setIsDialogOpen(true);
  };

  const handleLinkSuccess = () => {
    history.replace('/account/child-code');

    toast(
      <Box
        data-testid="link-success-toast"
        className="flex flex-row items-center gap-2.5"
      >
        <CheckboxCircle.View
          className="text-ctGreen-500"
          {...CheckboxCircle.size}
        />
        {texts.message.success}
      </Box>
    );
  };

  const handleSelectGuardianRelationship = (relationship: RelationshipType) => {
    setGuardianRelationship(relationship);
  };

  const handleClickCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box
      data-testid="set-guardian-relationship-content"
      className="flex flex-col items-center mt-8 xs:mt-12"
    >
      <UserWithHeartIcon.View
        className="text-ctGreen-500"
        {...UserWithHeartIcon.size}
      />
      <Box data-testid="title" className="font-bold text-20 mt-4">
        {texts.title.setRelationship(
          guardianAccount.name || guardianAccount.username || ''
        )}
      </Box>
      <Box
        data-testid="guardian-relationship-options-container"
        className="flex flex-col gap-3 mt-10 w-full"
      >
        {RELATIONSHIP_OPTIONS.map((option) => (
          <GuardianRelationshipOption
            key={option}
            value={option}
            onClick={handleSelectGuardianRelationship}
            selected={guardianRelationship === option}
          />
        ))}
      </Box>
      <Box
        className={classnames('flex gap-3', {
          'fixed z-50 bottom-4 w-full px-4 flex-col': isInMobile,
          'flex-row-reverse mt-12 self-end flex-wrap break-all': !isInMobile,
        })}
      >
        <Button
          data-testid="link-button"
          disabled={guardianRelationship === undefined}
          appearance="contained"
          theme="primary"
          className="w-full"
          onClick={handleClickLink}
          {...createTrackEventProps({
            name: 'LinkGuardianTried',
            on: 'click',
            properties: {
              userId: accountId,
              parentId: guardianAccount.id,
            },
          })}
        >
          {texts.button.link}
        </Button>
      </Box>
      <Dialog.Root open={isDialogOpen}>
        <HoverDialogContent
          testId="hover-dialog"
          description={texts.message.connectionError}
        >
          <Button
            data-testid="close-dialog"
            theme="primary"
            className="self-end mt-6"
            appearance="contained"
            onClick={handleClickCloseDialog}
            {...createTrackEventProps({
              name: 'LinkGuardianFailed',
              on: 'click',
              properties: {
                userId: accountId,
                parentId: guardianAccount.id,
              },
            })}
          >
            {texts.button.confirm}
          </Button>
        </HoverDialogContent>
      </Dialog.Root>
    </Box>
  );
};

type GuardianRelationshipOptionProps = {
  value: RelationshipType;
  selected: boolean;
  onClick: (relationship: RelationshipType) => void;
};

const GuardianRelationshipOption = ({
  value,
  selected,
  onClick,
}: GuardianRelationshipOptionProps) => {
  const texts = useTranslatedTexts();

  const handleClickRelationshipOption = () => onClick(value);

  return (
    <Button
      theme={selected ? 'primary' : 'white'}
      appearance="outlined"
      className="w-full"
      onClick={handleClickRelationshipOption}
      testId={value}
    >
      {texts.option[value]}
    </Button>
  );
};

export default SetRelationship;
