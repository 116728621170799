import React, { ReactElement } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import AuthRoute from './AuthRoute';

import pages from 'pages';

function EnterpriseAccountRouter({ match }: RouteComponentProps): ReactElement {
  const { EnterpriseInfoPages } = pages;
  return (
    <Switch>
      <AuthRoute
        exact
        path={`${match.path}/personal-info`}
        component={EnterpriseInfoPages.PersonalInfoPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/info`}
        component={EnterpriseInfoPages.AccountInfoPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/link-child`}
        component={EnterpriseInfoPages.LinkChildPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/child-code`}
        component={EnterpriseInfoPages.ChildCodePage}
      />
      <AuthRoute
        exact
        path={`${match.path}/change-email`}
        component={pages.EnterpriseChangeEmailPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/change-mobile`}
        component={pages.EnterpriseChangeMobilePage}
      />
      <AuthRoute
        exact
        path={`${match.path}/marketing-agreement`}
        component={EnterpriseInfoPages.MarketingAgreementPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/delete`}
        component={pages.EnterpriseDeleteConfirmPage}
      />
      <Route
        exact
        path={`${match.path}/delete/success`}
        component={pages.EnterpriseDeleteSuccessPage}
      />
      <Route
        exact
        path={`${match.path}/delete/error`}
        component={pages.TemporaryErrorPage}
      />
      <Route
        path={`${match.path}/post-logout`}
        component={pages.EnterpriseSignOutCallbackPage}
      />
      <Route component={pages.NotFoundErrorPage} />
    </Switch>
  );
}

export default EnterpriseAccountRouter;
