import dayjs from 'dayjs';

// NOTE:: YYYY-MM-DD format string
export const getWesternAge = (birthDate: string, baseDate?: string): number => {
  const today = dayjs(baseDate);
  const birthday = dayjs(birthDate);
  let age = today.year() - birthday.year();
  const month = today.month() - birthday.month();
  if (month < 0 || (month === 0 && today.date() < birthday.date())) {
    age--;
  }

  return age;
};

export const isValidFormat = (date: string, format: string): boolean => {
  return dayjs(date, format).format(format) === date;
};
