/* istanbul ignore file */

import React from 'react';

import { gray } from '../../colors.cjs';

const size = {
  width: '20px',
  height: '20px',
};

function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" {...props} role="img">
      <path
        fillRule="evenodd"
        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
        fill={gray[600]}
        clipRule="evenodd"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(ArrowLeftIcon),
};

export default Asset;
