import React from 'react';

import useTranslatedTexts from '../../../../useTranslatedTexts';
import { DialogContentTemplate } from '../../components';

interface ChildCodeUnverifiedProps {
  onRetryClick: () => void;
}

const ChildCodeUnverified = ({
  onRetryClick: handleRetryClick,
}: ChildCodeUnverifiedProps): JSX.Element => {
  const texts = useTranslatedTexts();

  return (
    <DialogContentTemplate
      testId="child-code-unverified-dialog-content"
      title={texts.title.failedToLinkChild}
      description={texts.error.invalidChildCode}
      confirmButton={{
        active: true,
        onClick: handleRetryClick,
        text: texts.button.retry,
      }}
    />
  );
};

export default ChildCodeUnverified;
