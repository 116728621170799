import React, { PropsWithChildren } from 'react';

import { AuthProvider as OdicAuthProvider } from 'react-oidc-context';

import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import memoryStorage from '../../core/storage/memoryStorage';
import {
  CLASSTING_OIDC_CLIENT_ID,
  CLASSTING_OIDC_REDIRECT_URL,
  CLASSTING_OIDC_RESPONSE_TYPE,
  CLASSTING_OIDC_URL,
} from '../../shared/constants';

const oidcConfig: UserManagerSettings = {
  authority: `${CLASSTING_OIDC_URL}/oidc`,
  client_id: CLASSTING_OIDC_CLIENT_ID,
  redirect_uri: CLASSTING_OIDC_REDIRECT_URL ?? '',
  silent_redirect_uri: CLASSTING_OIDC_REDIRECT_URL ?? '',
  response_type: CLASSTING_OIDC_RESPONSE_TYPE,
  scope: 'openid email profile',
  userStore: new WebStorageStateStore({
    store: memoryStorage,
  }),
};

const AuthProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  return <OdicAuthProvider {...oidcConfig} {...props} />;
};

export default AuthProvider;
