import React from 'react';

import useTranslatedTexts from '../../../../useTranslatedTexts';

import { SelectLinkTemplate } from '../../components';

import { Button } from 'designSystem/atoms';
import { CodeIcon, LinkIcon } from 'designSystem/ions/Icons/outlined';
import { Box } from 'designSystem/ions';
import { createTrackEventProps } from 'shared/analytics/utils';

interface SelectLinkMethodProps {
  accountId: string | null;
  closeDialog: () => void;
  onClickMethod: (method: 'url' | 'code') => void;
}

const SelectLinkMethod = ({
  accountId,
  closeDialog,
  onClickMethod,
}: SelectLinkMethodProps): JSX.Element => {
  const texts = useTranslatedTexts();

  const handleClickLinkChildByUrl = () => {
    onClickMethod('url');
  };

  const handleClickLinkChildByCode = () => {
    onClickMethod('code');
  };

  return (
    <SelectLinkTemplate
      testId="select-llink-method-dialog-content"
      title={texts.title.linkChild}
      description={texts.description.linkChildSelectMethod}
      onCloseClick={closeDialog}
    >
      <Button
        testId="link-by-url"
        theme="white"
        onClick={handleClickLinkChildByUrl}
        {...createTrackEventProps({
          name: 'GuardianLinkSelected',
          on: 'click',
          properties: {
            userId: accountId,
          },
        })}
      >
        <Box className="flex flex-row items-center gap-1">
          <LinkIcon.View
            width={LinkIcon.size.width}
            height={LinkIcon.size.height}
            className="text-gray-800"
          />
          <Box>{texts.button.linkChildByUrl}</Box>
        </Box>
      </Button>
      <Button
        testId="link-by-code"
        theme="white"
        onClick={handleClickLinkChildByCode}
        {...createTrackEventProps({
          name: 'ChildCodeSelected',
          on: 'click',
          properties: {
            userId: accountId,
          },
        })}
      >
        <Box className="flex flex-row items-center gap-1">
          <CodeIcon.View
            width={CodeIcon.size.width}
            height={CodeIcon.size.height}
          />
          <Box>{texts.button.linkChildByChildCode}</Box>
        </Box>
      </Button>
    </SelectLinkTemplate>
  );
};

export default SelectLinkMethod;
