import React from 'react';

import useTranslatedTexts from '../../../../useTranslatedTexts';

import { Box } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import { HoverDialogContent } from 'designSystem/molecules/HoverDialogContent';
import {
  LinkChildPageContentDocument,
  useUnlinkChildDialogContentMutation,
} from 'generated/graphql';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';
import { getCredentialAccountId } from 'core/credential/credential.service';

interface UnlinkChildDialogContentProps {
  relationshipId: string;
  onCloseDialogRequest: () => void;
  onUnlinkSuccess: () => void;
}

interface CancelButtonProps {
  onClick: () => void;
  event: TagManagerEventDefinition;
}

const CancelButton = ({ onClick: handleClick, event }: CancelButtonProps) => {
  const texts = useTranslatedTexts();
  return (
    <Button
      data-testid="unlink-child-dialog-cancel-button"
      onClick={handleClick}
      minWidth="4.5rem"
      theme="white"
      appearance="text"
      {...createTrackEventProps(event)}
    >
      {texts.button.cancel}
    </Button>
  );
};

interface ConfirmButtonProps {
  onClick: () => void;
}

const ConfirmButton = ({ onClick: handleClick }: ConfirmButtonProps) => {
  const texts = useTranslatedTexts();
  return (
    <Button
      data-testid="unlink-child-dialog-confirm-button"
      minWidth="4.5rem"
      appearance="contained"
      theme="danger"
      onClick={handleClick}
    >
      {texts.button.confirm}
    </Button>
  );
};

const UnlinkChildDialogContent: React.FC<UnlinkChildDialogContentProps> = ({
  relationshipId,
  onCloseDialogRequest: handleCloseDialogRequest,
  onUnlinkSuccess: handleUnlinkSuccess,
}): JSX.Element => {
  const accountId = getCredentialAccountId();
  const texts = useTranslatedTexts();
  const [unlink] = useUnlinkChildDialogContentMutation({
    variables: { relationshipId: relationshipId },
    refetchQueries: [LinkChildPageContentDocument],
  });

  const handleUnlinkClick = async () => {
    const response = await unlink();

    if (!response.errors) {
      return handleUnlinkSuccess();
    }
  };

  return (
    <HoverDialogContent
      description={texts.message.unlinkChild}
      testId="unlink-child-dialog-content"
    >
      <Box className="flex flex-row gap-4 justify-end">
        <CancelButton
          onClick={handleCloseDialogRequest}
          event={{
            name: 'UnlinkChildCancelled',
            on: 'click',
            properties: {
              userId: accountId,
            },
          }}
        />
        <ConfirmButton onClick={handleUnlinkClick} />
      </Box>
    </HoverDialogContent>
  );
};

export default UnlinkChildDialogContent;
