import React, { PropsWithChildren, ComponentPropsWithoutRef } from 'react';

import { Box, useIsInMobile } from 'designSystem/ions';

type TopSpacing = 'narrow' | 'board';
type PanelOwnProps = PropsWithChildren<{
  testId?: string;
  topSpacing?: TopSpacing;
}>;
export type PanelProps = PanelOwnProps & ComponentPropsWithoutRef<typeof Box>;

const topSpacingRecord: Record<TopSpacing, string> = {
  narrow: 'pt-6',
  board: 'pt-8',
};

const Panel = ({
  testId,
  children,
  className: classNameProp,
  is = 'div',
  topSpacing = 'narrow',
  ...restProps
}: PanelProps): JSX.Element => {
  const isMobile = useIsInMobile();
  const topSpacingStyle = topSpacingRecord[topSpacing];
  const baseStyle = `flex flex-col w-full bg-white px-0 py-5 sm:px-8 sm:pb-8 sm:${topSpacingStyle}`;
  /* istanbul ignore next */
  const panelStyle = isMobile ? '' : `border border-gray-300 rounded-lg`;
  const className = `${baseStyle} ${panelStyle} ${classNameProp}`;
  return (
    <Box is={is} data-testid={testId} className={className} {...restProps}>
      {children}
    </Box>
  );
};

export default Panel;
