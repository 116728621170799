import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IdentityProviderType } from 'shared/data/account';

import { Box } from 'designSystem/ions';
import {
  AppleIcon,
  AzureIcon,
  GoogleIcon,
  KakaoIcon,
  NaverIcon,
  WhalespaceIcon,
} from 'designSystem/ions/Icons/outlined';
import { Account } from 'shared/data/account/account.model';

const IdentityProviderBadge = ({
  account,
}: {
  account: Account;
}): JSX.Element => {
  const { t } = useTranslation();
  const notLinked = t('account.item.linked_account.null');
  const linkedWith = {
    apple: t('account.item.linked_account.apple'),
    google: t('account.item.linked_account.google'),
    kakao: t('account.item.linked_account.kakao'),
    naver: t('account.item.linked_account.naver'),
    whalespace: t('account.item.linked_account.whalespace'),
    azure: t('account.item.linked_account.azure'),
  };

  const renderProviderIcon = useCallback((provider: IdentityProviderType) => {
    switch (provider) {
      case IdentityProviderType.APPLE:
        return <AppleIcon.View data-testid="icon-apple" {...AppleIcon.size} />;

      case IdentityProviderType.GOOGLE:
        return (
          <GoogleIcon.View data-testid="icon-google" {...GoogleIcon.size} />
        );

      case IdentityProviderType.KAKAO:
        return <KakaoIcon.View data-testid="icon-kakao" {...KakaoIcon.size} />;

      case IdentityProviderType.NAVER:
        return <NaverIcon.View data-testid="icon-naver" {...NaverIcon.size} />;

      case IdentityProviderType.WHALESPACE:
        return (
          <WhalespaceIcon.View
            data-testid="icon-whalespace"
            {...WhalespaceIcon.size}
          />
        );

      case IdentityProviderType.AZURE:
        return <AzureIcon.View data-testid="icon-azure" {...AzureIcon.size} />;
    }
  }, []);

  if (account.identities.length === 0) {
    return (
      <Box
        is="p"
        data-testid="provider-badge"
        className="text-body-3 sm:text-body-1 text-gray-500 font-regular"
      >
        {notLinked}
      </Box>
    );
  } else {
    const { provider } = account.identities[0];
    return (
      <Box
        is="div"
        data-testid="provider-badge"
        className="flex flex-row items-center w-full"
      >
        <Box is="span">{renderProviderIcon(provider)}</Box>
        <Box
          is="p"
          className="ml-2 text-body-3 sm:text-body-1 text-gray-900 font-regular"
        >
          {linkedWith[provider]}
        </Box>
      </Box>
    );
  }
};

export default React.memo(IdentityProviderBadge);
