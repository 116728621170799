import React from 'react';

const size = {
  width: '16px',
  height: '16px',
};

const MobileIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="4" fill="#757575" />
      <rect
        x="4.7002"
        y="3.05005"
        width="6.6"
        height="9.9"
        rx="1"
        fill="white"
      />
      <rect x="5.7998" y="4.15002" width="4.4" height="5.5" fill="#757575" />
      <ellipse cx="8.0002" cy="11.3" rx="0.55" ry="0.550001" fill="#757575" />
    </svg>
  );
};

const Asset = {
  size,
  View: MobileIcon,
};
export default Asset;
