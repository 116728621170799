import { useHistory } from 'react-router-dom';

import { useIsClasstingMobileApp } from 'shared/hooks/platform';
import { WebViewBridge } from 'shared/webViewBridge';

const URL_CHECK_DELAY = 1000;

type HistoryBackHandlerResult = {
  go: (delta: number) => void;
  back: () => void;
};

/**
 * RN 웹뷰 대응을위한 히스토리 처리 훅
 *
 * - history.go(-1)
 * - history.back()
 *
 * 이전 히스토리가 없을 때 웹뷰가 그대로 떠있는 상태로 반응이 없는 문제가 있음.
 * 히스토리 이동 후 url 이 변경되지 않은 경우 브릿지를 통해 웹뷰를 닫도록 처리하는 훅
 */
export const useHistoryBackHandler = (): HistoryBackHandlerResult => {
  const history = useHistory();
  const isClasstingMobileApp = useIsClasstingMobileApp();

  const handleCloseWebView = (previousUrl: string) => {
    if (!isClasstingMobileApp) return;
    setTimeout(() => {
      const currentUrl = window.location.href;
      if (previousUrl === currentUrl) {
        WebViewBridge.requestNavigationClose();
      }
    }, URL_CHECK_DELAY);
  };

  const handleHistoryGo = (delta: number) => {
    const previousUrl = window.location.href;
    history.go(delta);
    // handle backward only
    if (delta < 0) {
      handleCloseWebView(previousUrl);
    }
  };

  const handleHistoryBack = () => {
    const previousUrl = window.location.href;
    history.back();
    handleCloseWebView(previousUrl);
  };

  return {
    go: handleHistoryGo,
    back: handleHistoryBack,
  };
};
