import React from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { Box as CDSBox, styled } from '@classtinginc/design-system';

import { useTranslation } from 'react-i18next';

import { Account } from 'shared/data/account';

type EnterpriseSideMenuProps = {
  account?: Account;
};

const StyledMenuItem = styled(CDSBox, {
  display: 'block',
  padding: '$03 $06',
  pointer: 'cursor',
  fontWeight: '$regular',
  color: '$gray-700',
  backgroundColor: '$white',

  '&.active': {
    fontWeight: '$medium',
    color: '$gray-900',
    backgroundColor: '$gray-100',
  },
});

const EnterpriseSideMenu = ({
  account,
}: EnterpriseSideMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPage = history.location.pathname;

  const myInfoText = t('account.my_info.title');
  const accountInfoText = t('account.account_info.title');
  const linkGuardianText = t('account.link_guardian.title');
  const linkChildText = t('account.link_child.title');
  const marketingAgreementText = t('account.marketing_agreement.title');

  return (
    <CDSBox
      as="aside"
      className="bg-white border border-gray-300 rounded-lg"
      css={{
        height: 'fit-content',
      }}
    >
      <CDSBox
        as="ul"
        className="flex flex-col py-2 text-body-3 font-regular text-gray-700"
        css={{
          width: '214px',
        }}
      >
        <li>
          <StyledMenuItem as={NavLink} to="/enterprise/account/personal-info">
            {myInfoText}
          </StyledMenuItem>
        </li>
        <li>
          <StyledMenuItem as={NavLink} to="/enterprise/account/info">
            {accountInfoText}
          </StyledMenuItem>
        </li>
        {account?.role === 'student' && (
          <li>
            <StyledMenuItem as={NavLink} to="/enterprise/account/child-code">
              {linkGuardianText}
            </StyledMenuItem>
          </li>
        )}
        {(account?.role === 'parent' || account?.role === 'teacher') && (
          <li>
            <StyledMenuItem as={NavLink} to="/enterprise/account/link-child">
              {linkChildText}
            </StyledMenuItem>
          </li>
        )}
        <li>
          <StyledMenuItem
            as={NavLink}
            to="/enterprise/account/marketing-agreement"
          >
            {marketingAgreementText}
          </StyledMenuItem>
        </li>
      </CDSBox>
    </CDSBox>
  );
};

export default EnterpriseSideMenu;
