import * as yup from 'yup';
import {
  useForm,
  FieldValues,
  UseFormReturn,
  DefaultValues,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useFormWithResolver = <TFieldValues extends FieldValues>({
  schema,
  defaultValues,
}: {
  schema: yup.SchemaOf<TFieldValues>;
  defaultValues?: DefaultValues<TFieldValues>;
}): UseFormReturn<TFieldValues> => {
  return useForm<TFieldValues>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });
};

export default useFormWithResolver;
