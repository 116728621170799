import PersonalInfoPage from './PersonalInfoPage';
import AccountInfoPage from './AccountInfoPage';
import LinkChildPage from './LinkChildPage';
import ChildCodePage from './ChildCodePage';
import MarketingAgreementPage from './MarketingAgreementPage';

const EnterpriseInfoPages = {
  PersonalInfoPage,
  AccountInfoPage,
  LinkChildPage,
  ChildCodePage,
  MarketingAgreementPage,
};

export default EnterpriseInfoPages;
