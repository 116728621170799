import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PersonalInfoPanel } from './PersonalInfoPanel';

import { ChildInfoPanel } from './ChildInfoPanel';

import useTranslatedTexts from './useTranslatedTexts';

import OrganizationInfoPanel from './OrganizationInfoPanel';

import { SideMenuPageTemplate } from 'designSystem/templates';
import {
  GlobalAppBar,
  CenterTitleNavigationHeader,
} from 'designSystem/organisms';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { Box } from 'designSystem/ions';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { OrganizationInputAccountFieldsFragment } from 'generated/graphql';

export const PersonalInfoPage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const isChildInfoPanelVisible = account.role === 'parent';
  const texts = useTranslatedTexts();

  const isClasstingMobileApp = useIsClasstingMobileApp();

  return (
    <SideMenuPageTemplate
      account={account}
      headerChild={
        isClasstingMobileApp ? (
          <CenterTitleNavigationHeader title={texts.title.userInfo} />
        ) : (
          <GlobalAppBar account={account} />
        )
      }
    >
      <PersonalInfoPanel account={account} />
      {account.role !== 'parent' && (
        <>
          <Box className="block sm:mt-5 mt-6 h-3 sm:h-0 -mx-4 bg-gray-200" />
          <OrganizationInfoPanel
            account={account as OrganizationInputAccountFieldsFragment}
          />
        </>
      )}
      {isChildInfoPanelVisible && (
        <>
          <Box className="block sm:hidden h-3 mt-5 bg-gray-200 -mx-4" />
          <ChildInfoPanel account={account} />
        </>
      )}
    </SideMenuPageTemplate>
  );
};

export default withAccount(PersonalInfoPage);
