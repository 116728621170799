import React, { useState } from 'react';
import classNames from 'classnames';
import * as Dialog from '@radix-ui/react-dialog';

import useTranslatedTexts from '../useTranslatedTexts';

import LinkedGuardianList from './LinkedGuardianList';
import ChildCodeDialogContent from './ChildCodeDialogContent';

import { Box, useIsInMobile } from 'designSystem/ions';
import { Button } from 'designSystem/atoms';
import {
  RelationshipType,
  GuardianAccountDto,
  useChildCodePageContentQuery,
} from 'generated/graphql';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { createTrackEventProps } from 'shared/analytics/utils';
import { getCredentialAccountId } from 'core/credential/credential.service';

const LINKED_PARENT_LIST_FETCH_UNIT = 10;

interface ChildCodePageContentProps {
  childId: string;
}

const ChildCodePageContent: React.FC<ChildCodePageContentProps> = ({
  childId,
}) => {
  const accountId = getCredentialAccountId();
  const [childCodeDialogVisibility, setChildCodeDialogVisibility] = useState(
    false
  );
  const texts = useTranslatedTexts();
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const isMobile = useIsInMobile();

  const { data } = useChildCodePageContentQuery({
    variables: {
      limit: LINKED_PARENT_LIST_FETCH_UNIT,
    },
    fetchPolicy: 'network-only',
  });

  const guardianRelationships = data?.listGuardianRelationships || [];
  const guardianAccounts = guardianRelationships
    .filter((relationship) => relationship?.guardianAccount)
    .map((relationship) => ({
      relationType: relationship?.relationshipType as RelationshipType,
      guardianAccount: relationship?.guardianAccount as GuardianAccountDto,
    }));
  const hasLinkedGuardian = !!guardianAccounts.length;

  const handlePressViewChildCodeButton = () => {
    setChildCodeDialogVisibility(true);
  };

  const handleCloseDialog = () => {
    setChildCodeDialogVisibility(false);
  };

  return (
    <Box
      data-testid="child-code-page-content"
      className={classNames('flex flex-col w-full mt-30', {
        'gap-2': isClasstingMobileApp,
        'gap-4': !isClasstingMobileApp,
        'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2':
          isMobile && !hasLinkedGuardian,
      })}
    >
      <LinkedGuardianList guardianAccounts={guardianAccounts} />
      <Box
        className={classNames('flex flex-row justify-center', {
          'fixed inset-x-0 bottom-0': isClasstingMobileApp && hasLinkedGuardian,
          'mt-6': hasLinkedGuardian,
          'mb-6': !hasLinkedGuardian,
        })}
      >
        <Button
          theme="primary"
          appearance="contained"
          size="md"
          fullWidth={hasLinkedGuardian}
          rounded={!(isClasstingMobileApp && hasLinkedGuardian)}
          onClick={handlePressViewChildCodeButton}
          testId="view-child-code-button"
          {...createTrackEventProps({
            name: 'ChildCodeViewed',
            on: 'click',
            properties: {
              userId: accountId,
            },
          })}
        >
          {texts.button.viewChildCode}
        </Button>
      </Box>
      {childCodeDialogVisibility && (
        <Dialog.Root defaultOpen={true}>
          <ChildCodeDialogContent
            childId={childId}
            onClose={handleCloseDialog}
          />
        </Dialog.Root>
      )}
    </Box>
  );
};

export default ChildCodePageContent;
