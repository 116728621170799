/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { AxiosResponse } from 'axios';

import {
  RequestMobileChangeResponse,
  RequestMobileChangeParam,
  ChangeMobileParam,
  UpdateAccountParam,
  ChangeRoleParam,
} from '../user/user.model';

import {
  deleteAccount,
  updateAccount,
  fetchMyAccount,
  updatePassword,
  requestEmailUpdate,
  updateEmail,
  requestMobileChange,
  changeMobile,
  changeRole,
} from './account.apis';

import { Account } from './account.model';

import { CustomAxiosError } from 'core/axios';

export function useMyAccount(
  options:
    | UseQueryOptions<Account, CustomAxiosError<unknown>, Account>
    | undefined = {}
) {
  return useQuery<Account, CustomAxiosError>('/me', () => fetchMyAccount(), {
    retry: 2,
    ...options,
  });
}

export function useDeleteAccount(
  options: UseMutationOptions<Account, Error, string, unknown> | undefined = {}
) {
  return useMutation<Account, Error, string, unknown>(
    '/deleteAccount',
    deleteAccount,
    { retry: false, ...options }
  );
}

export function useUpdateAccountPassword(
  options: UseMutationOptions<
    AxiosResponse<void>,
    CustomAxiosError<unknown>,
    {
      userId: string;
      password: string;
      newPassword: string;
      newPasswordConfirm: string;
    }
  >
) {
  return useMutation<
    AxiosResponse<void>,
    CustomAxiosError,
    {
      userId: string;
      password: string;
      newPassword: string;
      newPasswordConfirm: string;
    }
  >('/putPassword', updatePassword, { retry: false, ...options });
}

export function useRequestAccountEmailUpdate(
  options: UseMutationOptions<
    AxiosResponse<void>,
    CustomAxiosError<unknown>,
    { userId: string; email: string; isEnterprise?: boolean }
  >
) {
  return useMutation<
    AxiosResponse<void>,
    CustomAxiosError,
    { userId: string; email: string; isEnterprise?: boolean }
  >('/requestEmailUpdate', requestEmailUpdate, {
    retry: false,
    ...options,
  });
}

export function useUpdateAccountEmail(
  options: UseMutationOptions<
    AxiosResponse<void>,
    CustomAxiosError<unknown>,
    { userId: string; code: string }
  >
) {
  return useMutation<
    AxiosResponse<void>,
    CustomAxiosError,
    { userId: string; code: string }
  >('/updateEmail', updateEmail, {
    retry: false,
    ...options,
  });
}

export function useRequestMobileChange(
  options:
    | UseMutationOptions<
        RequestMobileChangeResponse,
        CustomAxiosError,
        RequestMobileChangeParam,
        unknown
      >
    | undefined = {}
) {
  return useMutation<
    RequestMobileChangeResponse,
    CustomAxiosError,
    RequestMobileChangeParam,
    unknown
  >('/requestMobileChange', requestMobileChange, { retry: false, ...options });
}

export function useChangeMobile(
  options:
    | UseMutationOptions<void, CustomAxiosError, ChangeMobileParam, unknown>
    | undefined = {}
) {
  return useMutation<void, CustomAxiosError, ChangeMobileParam, unknown>(
    '/changeMobile',
    changeMobile,
    {
      retry: false,
      ...options,
    }
  );
}

/**
 * @TODO 사용자 정보 수정
 */
/* istanbul ignore next */
export function useUpdateAccount(
  options:
    | UseMutationOptions<Account, CustomAxiosError, UpdateAccountParam, unknown>
    | undefined = {}
) {
  return useMutation<Account, CustomAxiosError, UpdateAccountParam, unknown>(
    '/updateAccount',
    updateAccount,
    { retry: false, ...options }
  );
}

export function useChangeRole(
  options: UseMutationOptions<AxiosResponse<void>, Error, ChangeRoleParam>
) {
  return useMutation<AxiosResponse<void>, CustomAxiosError, ChangeRoleParam>(
    '/changeRole',
    changeRole,
    {
      retry: false,
      ...options,
    }
  );
}
