import React, { useState, useCallback } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';

import { BoxIndicator } from '@classtinginc/design-system';

import { EnterpriseSingleColumnPageTemplate } from '../../designSystem/templates';

import {
  EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment,
  useEnterpriseDeleteConfirmPageQuery,
} from '../../generated/graphql';

import { useDeleteButtonHandler } from './hooks';

import useTranslatedTexts from './useTranslatedTexts';

import { CheckBoxForm, CheckList, TitleBox } from 'designSystem/molecules';
import { useModalContext } from 'designSystem/organisms';
import { Button } from 'designSystem/atoms';
import { MyAccountCard } from 'shared/components/organisms';
import { getCredential } from 'core/credential/credential.service';
import { useAccountCheck } from 'shared/root';
import { useHistoryBackHandler } from 'shared/hooks/history';
import { Box } from 'designSystem/ions';
import { createTrackEventProps } from 'shared/analytics/utils';
import ForbiddenErrorPage from 'pages/ErrorPages/ForbiddenErrorPage';

const EnterpriseDeleteConfirmPage = (
  props: RouteComponentProps
): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const handlePressCheckBox = useCallback(
    () => setChecked((checked) => !checked),
    [setChecked]
  );
  const { data: account } = useAccountCheck();
  const {
    data: { enterprise } = {},
    loading,
  } = useEnterpriseDeleteConfirmPageQuery({
    variables: {
      enterpriseId: account?.enterprise?.id ?? '',
    },
    skip: !account?.enterprise,
  });
  const credential = getCredential();
  const history = useHistory();
  const historyHandler = useHistoryBackHandler();
  const { openModal } = useModalContext();

  const { handler } = useDeleteButtonHandler({
    account,
    credential,
    history,
    homeUrl: enterprise?.homeUrl,
  });

  const handleClickDeleteButton = () => {
    handler(openModal);
  };

  const handleClickCancelButton = () => {
    historyHandler.go(-2);
  };

  const buttonDisabled = !checked || !account?.id;
  const texts = useTranslatedTexts();

  if (account && !account.enterprise) {
    return <ForbiddenErrorPage />;
  }

  if (loading) {
    return <BoxIndicator />;
  }

  return (
    <EnterpriseSingleColumnPageTemplate
      title={texts.CTATitle}
      desc={texts.CTADesc}
      enterprise={
        enterprise as EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment
      }
    >
      <Box
        is="section"
        className="
              flex flex-col items-center
              w-full pb-6
              "
      >
        <Box is="h3" className="sr-only">
          유저 정보
        </Box>
        <MyAccountCard />
      </Box>
      <Box is="section" className="flex flex-col w-full">
        <Box is="h3" className="sr-only">
          주의사항 안내
        </Box>
        <CheckList data={texts.checkList} theme="danger" />
      </Box>
      <Box is="div" className="flex flex-col w-full mt-6 mb-5">
        <Box is="div" className="flex h-px w-full bg-gray-300"></Box>
      </Box>
      <Box is="section" className="flex flex-col w-full">
        <Box is="h3" className="sr-only">
          주의사항 확인 동의
        </Box>
        <Box is="div" className="flex flex-row w-full mb-10">
          <CheckBoxForm
            id="confirm-delete-checkbox"
            checked={checked}
            theme="danger"
            labelText={texts.checkLabel}
            onPressCheckBox={handlePressCheckBox}
          />
        </Box>
        <Box is="div" className="flex flex-row justify-end w-full h-11">
          <Box is="div" className="flex flex-1 sm:flex-initial flex-col pr-4">
            <Button
              size="md"
              theme="white"
              appearance="outlined"
              className="w-full sm:w-auto"
              testId="confirm-cancel-button"
              onClick={handleClickCancelButton}
              {...createTrackEventProps({
                name: 'DeleteAccountCancelled',
                on: 'click',
                properties: {
                  userId: account?.id,
                  role: account?.role,
                },
              })}
            >
              {texts.cancelButton}
            </Button>
          </Box>
          <Box is="div" className="flex flex-1 sm:flex-initial flex-col">
            <Button
              theme="danger"
              size="md"
              appearance="contained"
              testId="confirm-delete-button"
              disabled={buttonDisabled}
              onClick={handleClickDeleteButton}
              className="w-full sm:w-auto"
              {...createTrackEventProps({
                name: 'DeleteAccountOperated',
                on: 'click',
                properties: {
                  userId: account?.id,
                  role: account?.role,
                },
              })}
            >
              {texts.deleteButton}
            </Button>
          </Box>
        </Box>
      </Box>
    </EnterpriseSingleColumnPageTemplate>
  );
};

export default EnterpriseDeleteConfirmPage;
