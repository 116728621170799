import React, { useState, useEffect } from 'react';

import useTranslatedTexts from '../../../../useTranslatedTexts';

import { DialogContentTemplate } from '../../components';

import { Box } from 'designSystem/ions';
import { Input } from 'designSystem/atoms';
import { textToNode } from 'shared/utils/string';
import {
  ChildCodeResponseDto,
  useVerifyChildCodeMutation,
} from 'generated/graphql';
import { getCredentialAccountId } from 'core/credential/credential.service';

interface VerifyChildCodeProps {
  guardianId: string;
  onVerified: (childInfo: ChildCodeResponseDto) => void;
  onCancelClick: () => void;
}

const VerifyChildCode = ({
  guardianId,
  onVerified: handleVerified,
  onCancelClick,
}: VerifyChildCodeProps): JSX.Element => {
  const accountId = getCredentialAccountId();
  const texts = useTranslatedTexts();

  const [inputValue, setInputValue] = useState('');
  const [shouldAlertEmpty, setShouldAlertEmpty] = useState(false);
  const [shouldAlertInvalid, setShouldAlertInvalid] = useState(false);
  const shouldAlertInputError = shouldAlertInvalid || shouldAlertInvalid;

  const [verifyChildCode] = useVerifyChildCodeMutation({
    variables: { code: inputValue, guardianId: guardianId },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    if (text.length > 6) {
      return;
    }

    setInputValue(text.toUpperCase());

    const isInputEmpty = text.length === 0;
    if (isInputEmpty && !shouldAlertEmpty) {
      setShouldAlertEmpty(true);
    } else if (!isInputEmpty && shouldAlertEmpty) {
      setShouldAlertEmpty(false);
    }
  };

  const handleConfirmClick = async () => {
    const result = await verifyChildCode();
    if (result.errors) {
      setShouldAlertInvalid(true);
    } else if (result.data?.verifyChildCode) {
      const childInfo = result.data?.verifyChildCode;
      handleVerified(childInfo);
    }
  };

  const handleCancelClick = () => {
    const reset = () => {
      setInputValue('');
      setShouldAlertEmpty(false);
      setShouldAlertInvalid(false);
    };

    reset();
    onCancelClick();
  };

  const alertMessageType = shouldAlertEmpty
    ? 'empty'
    : shouldAlertInvalid
    ? 'invalid'
    : 'none';

  return (
    <DialogContentTemplate
      testId="verify-child-code-dialog-content"
      title={texts.title.linkChild}
      description={texts.description.linkChild}
      cancelButton={{
        text: texts.button.cancel,
        onClick: handleCancelClick,
        event: {
          name: 'LinkChildCancelled',
          on: 'click',
          properties: {
            userId: accountId,
          },
        },
      }}
      confirmButton={{
        active: inputValue.length > 0,
        onClick: handleConfirmClick,
        text: texts.button.confirm,
      }}
    >
      <Box className="flex flex-col gap-1">
        <Input
          testId="verify-child-code-input"
          value={inputValue}
          placeholder={texts.placeholder.child_code}
          onChange={handleInputChange}
          status={shouldAlertInputError ? 'error' : 'default'}
        />
        <Box
          data-testid={`alert-message--${alertMessageType}`}
          className="text-14 leading-5 text-red-500"
        >
          {shouldAlertEmpty
            ? texts.error.emptyChildCode
            : shouldAlertInvalid
            ? texts.error.invalidChildCode
            : ''}
        </Box>
      </Box>

      <Box lineHeight="1.125rem" className="text-12 text-gray-600">
        {textToNode(texts.message.linkChild).map((node, i) => {
          if (node.nodeName.match(/strong/i)) {
            // istanbul ignore next
            return <b key={i}>{node.textContent}</b>;
          } else {
            return node.textContent;
          }
        })}
      </Box>
    </DialogContentTemplate>
  );
};

export default VerifyChildCode;
