import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const CTATitle = t('account.error.title.forbidden');
  const CTADesc = t('account.error.message.forbidden');
  const homeButton = t('account.button.home');
  const backButton = t('account.button.back');
  return {
    CTATitle,
    CTADesc,
    homeButton,
    backButton,
  };
};

export default useTranslatedTexts;
