import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const title = t('account.redirect_to_app.title');
  const description = t('account.redirect_to_app.description');
  const openButton = t('account.redirect_to_app.button.open');
  const installButton = t('account.redirect_to_app.button.install');
  const leatestVersionTitle = t(
    'account.redirect_to_app.leatest_version_title'
  );
  const leatestVersionDescription = t(
    'account.redirect_to_app.leatest_version_description'
  );

  return {
    title,
    description,
    openButton,
    installButton,
    leatestVersionTitle,
    leatestVersionDescription,
  };
};

export default useTranslatedTexts;
