import React from 'react';

const size = {
  width: '240px',
  height: '240px',
};
function BlockedBino(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M163.03 176.283L126.286 173.625C123.388 173.415 121.21 170.898 121.42 168C121.63 165.102 124.147 162.924 127.045 163.134L163.789 165.792C165.82 165.939 167.659 164.346 167.806 162.315C168.373 154.464 175.222 148.539 183.073 149.106L188.164 149.475C191.062 149.685 193.24 152.202 193.03 155.1C192.82 157.998 190.303 160.176 187.405 159.966L182.317 159.6C180.253 159.45 178.45 161.01 178.3 163.077C177.73 170.925 170.881 176.85 163.03 176.283Z"
          fill="white"
        />
        <path
          d="M164.081 177.219C163.712 177.219 163.34 177.207 162.968 177.18L126.224 174.522C122.837 174.276 120.281 171.321 120.524 167.934C120.644 166.293 121.394 164.796 122.636 163.719C123.881 162.642 125.468 162.111 127.109 162.234L163.853 164.892C164.597 164.943 165.329 164.694 165.914 164.187C166.502 163.68 166.853 162.99 166.907 162.249C167.51 153.903 174.791 147.606 183.137 148.209L188.228 148.578C191.615 148.824 194.171 151.779 193.928 155.166C193.808 156.807 193.058 158.304 191.816 159.381C190.574 160.458 188.984 160.983 187.343 160.866L182.252 160.497C180.683 160.386 179.312 161.568 179.198 163.14C178.907 167.178 177.056 170.862 173.987 173.517C171.2 175.926 167.72 177.219 164.081 177.219ZM126.659 164.019C125.612 164.019 124.613 164.391 123.815 165.081C122.933 165.843 122.402 166.902 122.318 168.063C122.144 170.46 123.953 172.551 126.35 172.725L163.094 175.383C166.652 175.641 170.099 174.492 172.805 172.152C175.511 169.812 177.143 166.563 177.398 163.008C177.584 160.446 179.819 158.514 182.378 158.7L187.469 159.069C188.63 159.156 189.755 158.781 190.637 158.019C191.519 157.257 192.05 156.198 192.134 155.037C192.308 152.64 190.499 150.549 188.102 150.375L183.011 150.006C175.658 149.469 169.238 155.025 168.707 162.381C168.62 163.602 168.047 164.73 167.096 165.552C166.145 166.374 164.948 166.776 163.727 166.689L126.983 164.031C126.875 164.022 126.767 164.019 126.659 164.019Z"
          fill="#9E9E9E"
        />
        <path
          d="M124.563 188.835L130.83 188.247L142.515 186.3L159.708 183.267L165.438 170.454L157.224 163.713C157.224 163.713 157.152 157.857 156.861 154.725C156.297 148.725 155.502 142.998 154.53 137.709C151.038 124.347 156.336 117.693 119.25 117.693C104.199 117.693 93.9813 119.331 91.1403 130.125C86.9823 145.926 94.8003 172.839 94.8003 172.839C100.065 192.696 107.496 209.001 113.682 215.532C116.406 218.958 121.002 220.659 125.484 219.468C131.517 217.869 135.141 211.626 133.542 205.593L130.812 195.3L128.775 188.787"
          fill="white"
        />
        <path
          d="M122.599 220.743C118.93 220.743 115.381 219.084 113.008 216.12C106.54 209.268 99.055 192.378 93.934 173.067C93.919 173.019 91.954 166.209 90.526 157.551C88.6 145.875 88.516 136.569 90.274 129.894C93.457 117.792 105.799 116.79 119.254 116.79C134.635 116.79 143.218 117.906 147.958 120.525C152.707 123.15 153.4 127.071 154.279 132.039C154.582 133.755 154.897 135.531 155.407 137.481C155.413 137.502 155.419 137.523 155.422 137.547C156.424 143.004 157.213 148.758 157.765 154.644C158.017 157.326 158.104 161.877 158.128 163.287L166.018 169.761C166.333 170.019 166.435 170.454 166.267 170.823L160.537 183.636C160.414 183.909 160.165 184.104 159.871 184.155L142.678 187.188L130.984 189.138C130.963 189.141 130.942 189.144 130.921 189.147L129.871 189.246L131.683 195.036C131.686 195.048 131.692 195.06 131.695 195.075L134.425 205.368C136.156 211.893 132.253 218.613 125.728 220.344C124.684 220.611 123.637 220.743 122.599 220.743ZM119.254 118.59C103.594 118.59 94.618 120.465 92.014 130.35C87.967 145.725 95.59 172.317 95.668 172.584C100.729 191.673 108.058 208.278 114.34 214.911C114.358 214.929 114.376 214.95 114.391 214.971C116.989 218.235 121.252 219.66 125.257 218.598C130.825 217.122 134.152 211.392 132.676 205.824L129.952 195.552L128.032 189.417L124.648 189.735C124.153 189.78 123.715 189.417 123.667 188.922C123.622 188.427 123.985 187.989 124.48 187.941L130.714 187.356L142.369 185.415L159.085 182.466L164.341 170.718L156.661 164.415C156.457 164.247 156.334 163.995 156.331 163.731C156.331 163.674 156.256 157.875 155.971 154.815C155.425 148.992 154.645 143.304 153.655 137.91C153.133 135.9 152.8 134.019 152.506 132.36C151.651 127.518 151.084 124.317 147.088 122.109C142.693 119.673 134.107 118.59 119.254 118.59Z"
          fill="#9E9E9E"
        />
        <path
          d="M92.9946 126.897C74.1366 129.042 70.7106 119.775 66.0156 112.752C60.7806 104.919 56.1336 95.019 45.9486 99.375C32.6856 105.045 41.4516 121.605 48.7866 132.375C60.5976 149.721 76.5426 155.463 93.5436 153.381"
          fill="white"
        />
        <path
          d="M87.028 154.686C80.533 154.686 74.446 153.447 68.857 150.987C60.814 147.444 53.812 141.351 48.043 132.882C40.024 121.107 36.874 111.999 38.683 105.816C39.64 102.543 41.965 100.098 45.595 98.547C55.306 94.398 60.442 102.42 64.975 109.497C65.56 110.412 66.166 111.357 66.766 112.254C67.27 113.01 67.768 113.802 68.251 114.567C72.355 121.101 76.6 127.857 92.896 126.003C93.391 125.943 93.835 126.303 93.892 126.795C93.949 127.29 93.592 127.734 93.1 127.791C75.679 129.771 70.921 122.202 66.727 115.524C66.229 114.732 65.761 113.988 65.269 113.253C64.66 112.341 64.048 111.39 63.46 110.466C58.909 103.362 54.613 96.651 46.303 100.2C43.15 101.547 41.221 103.551 40.411 106.32C38.761 111.963 41.83 120.558 49.531 131.868C55.105 140.055 61.852 145.932 69.583 149.34C76.735 152.49 84.76 153.549 93.436 152.487C93.931 152.424 94.378 152.778 94.438 153.27C94.498 153.762 94.147 154.212 93.655 154.272C91.402 154.548 89.194 154.686 87.028 154.686Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.2"
          d="M54.4217 119.442C58.2107 117.384 60.8567 112.971 58.1657 108.096C55.4747 103.221 45.7487 102.597 44.3447 111.342C42.9407 120.087 49.7747 121.968 54.4217 119.442Z"
          fill="#9E9E9E"
        />
        <path
          d="M146.518 119.709C164.62 123.3 168.682 114.591 173.794 108.162C179.494 100.992 184.789 91.773 194.311 96.807C206.71 103.362 196.888 118.707 188.917 128.556C178.666 141.225 166.456 146.508 153.646 146.403"
          fill="white"
        />
        <path
          d="M154.032 147.306C153.9 147.306 153.771 147.306 153.639 147.306C153.141 147.303 152.742 146.895 152.745 146.397C152.748 145.899 153.153 145.503 153.651 145.506C153.777 145.506 153.9 145.506 154.026 145.506C167.31 145.506 178.809 139.617 188.214 127.992C196.578 117.657 200.238 109.581 199.092 103.989C198.531 101.247 196.827 99.159 193.887 97.605C186.138 93.507 181.443 99.66 176.472 106.17C175.827 107.016 175.158 107.889 174.495 108.726C173.961 109.398 173.448 110.082 172.902 110.811C168.297 116.955 163.077 123.915 146.34 120.597C145.851 120.501 145.536 120.027 145.632 119.538C145.728 119.052 146.205 118.734 146.691 118.83C162.318 121.929 166.968 115.728 171.462 109.731C171.99 109.026 172.536 108.297 173.085 107.607C173.739 106.785 174.399 105.918 175.041 105.081C180.006 98.577 185.631 91.206 194.727 96.015C198.126 97.812 200.187 100.374 200.856 103.629C202.116 109.773 198.333 118.353 189.615 129.126C179.853 141.189 167.883 147.306 154.032 147.306Z"
          fill="#9E9E9E"
        />
        <path
          d="M72.7343 71.412C84.1003 71.412 93.3143 62.198 93.3143 50.832C93.3143 39.466 84.1003 30.252 72.7343 30.252C61.3683 30.252 52.1543 39.466 52.1543 50.832C52.1543 62.198 61.3683 71.412 72.7343 71.412Z"
          fill="white"
        />
        <path
          d="M72.7037 72.303C68.4887 72.303 64.2317 71.067 60.5087 68.493C55.7897 65.229 52.6277 60.321 51.5987 54.675C50.5727 49.029 51.8027 43.323 55.0697 38.604C61.8107 28.866 75.2177 26.424 84.9557 33.165C94.6937 39.906 97.1357 53.313 90.3947 63.051C86.2307 69.072 79.5197 72.303 72.7037 72.303ZM72.7607 31.158C66.5147 31.158 60.3647 34.119 56.5487 39.633C53.5577 43.956 52.4267 49.185 53.3687 54.357C54.3107 59.529 57.2087 64.026 61.5317 67.017C64.9397 69.375 68.8427 70.509 72.7037 70.509C78.9497 70.509 85.0997 67.548 88.9157 62.034C95.0927 53.112 92.8577 40.827 83.9327 34.65C80.5247 32.289 76.6247 31.158 72.7607 31.158ZM55.8107 39.12H55.8137H55.8107Z"
          fill="#9E9E9E"
        />
        <path
          d="M72.7334 61.122C78.4164 61.122 83.0234 56.515 83.0234 50.832C83.0234 45.149 78.4164 40.542 72.7334 40.542C67.0504 40.542 62.4434 45.149 62.4434 50.832C62.4434 56.515 67.0504 61.122 72.7334 61.122Z"
          fill="#FF9B96"
        />
        <path
          d="M72.7179 62.019C70.5219 62.019 68.3048 61.377 66.3668 60.033C61.2938 56.52 60.0218 49.536 63.5348 44.463C67.0448 39.39 74.0319 38.118 79.1049 41.631C84.1779 45.144 85.4499 52.128 81.9369 57.201C79.7649 60.333 76.2669 62.019 72.7179 62.019ZM72.7449 41.445C69.7628 41.445 66.8318 42.858 65.0108 45.489C62.0648 49.746 63.1298 55.608 67.3868 58.554C71.6439 61.5 77.5059 60.435 80.4519 56.178C83.3979 51.921 82.3329 46.059 78.0759 43.113C76.4499 41.985 74.5899 41.445 72.7449 41.445ZM64.2698 44.976H64.2728H64.2698Z"
          fill="#C86E6E"
        />
        <path
          d="M146.828 59.331C158.194 59.331 167.408 50.117 167.408 38.751C167.408 27.385 158.194 18.171 146.828 18.171C135.462 18.171 126.248 27.385 126.248 38.751C126.248 50.117 135.462 59.331 146.828 59.331Z"
          fill="white"
        />
        <path
          d="M146.783 60.237C142.457 60.237 138.236 58.929 134.603 56.412C124.865 49.671 122.423 36.264 129.164 26.526C135.905 16.788 149.312 14.346 159.05 21.087C163.769 24.351 166.931 29.259 167.96 34.905C168.986 40.551 167.756 46.257 164.489 50.976C161.225 55.695 156.317 58.857 150.671 59.886C149.375 60.12 148.073 60.237 146.783 60.237ZM130.646 27.549C124.469 36.471 126.704 48.756 135.629 54.933C139.952 57.924 145.181 59.055 150.353 58.113C155.525 57.171 160.022 54.273 163.013 49.95C166.004 45.627 167.135 40.398 166.193 35.226C165.251 30.054 162.353 25.557 158.03 22.566C149.105 16.392 136.82 18.627 130.646 27.549Z"
          fill="#9E9E9E"
        />
        <path
          d="M146.829 49.041C152.512 49.041 157.119 44.434 157.119 38.751C157.119 33.068 152.512 28.461 146.829 28.461C141.146 28.461 136.539 33.068 136.539 38.751C136.539 44.434 141.146 49.041 146.829 49.041Z"
          fill="#FF9B96"
        />
        <path
          d="M146.814 49.938C144.618 49.938 142.401 49.296 140.463 47.952C135.39 44.439 134.118 37.455 137.631 32.382C139.332 29.925 141.888 28.275 144.828 27.741C147.768 27.207 150.741 27.849 153.201 29.55C155.658 31.251 157.308 33.807 157.842 36.747C158.376 39.687 157.734 42.66 156.033 45.12C153.861 48.255 150.366 49.938 146.814 49.938ZM146.85 29.358C146.286 29.358 145.716 29.409 145.149 29.511C142.68 29.961 140.535 31.344 139.11 33.405C136.164 37.662 137.229 43.524 141.486 46.47C145.743 49.416 151.605 48.351 154.551 44.094C155.979 42.03 156.516 39.537 156.069 37.068C155.619 34.599 154.236 32.454 152.175 31.029C150.585 29.931 148.74 29.358 146.85 29.358Z"
          fill="#C86E6E"
        />
        <path
          d="M116.069 137.247C145.92 137.247 170.12 113.048 170.12 83.196C170.12 53.3445 145.92 29.145 116.069 29.145C86.217 29.145 62.0176 53.3445 62.0176 83.196C62.0176 113.048 86.217 137.247 116.069 137.247Z"
          fill="white"
        />
        <path
          d="M115.952 138.159C104.885 138.159 94.0908 134.811 84.7968 128.379C59.8818 111.135 53.6418 76.836 70.8858 51.924C88.1298 27.009 122.429 20.769 147.344 38.013C172.259 55.257 178.499 89.556 161.255 114.468C152.903 126.537 140.348 134.631 125.909 137.259C122.585 137.862 119.255 138.159 115.952 138.159ZM72.3648 52.947C55.6848 77.046 61.7238 110.22 85.8198 126.9C94.8078 133.122 105.251 136.362 115.955 136.362C119.153 136.362 122.369 136.074 125.582 135.489C139.55 132.948 151.691 125.118 159.77 113.445C176.45 89.346 170.411 56.172 146.315 39.492C122.216 22.812 89.0418 28.848 72.3648 52.947Z"
          fill="#9E9E9E"
        />
        <path
          d="M121.886 86.01L111.617 87.879C109.553 88.254 107.558 86.874 107.183 84.81C106.808 82.746 108.188 80.751 110.252 80.376L120.521 78.507C122.585 78.132 124.58 79.512 124.955 81.576C125.327 83.64 123.947 85.635 121.886 86.01Z"
          fill="#FF9B96"
        />
        <path
          d="M110.93 88.842C108.698 88.842 106.709 87.246 106.295 84.972C105.83 82.416 107.531 79.959 110.087 79.491L120.356 77.622C122.912 77.154 125.369 78.858 125.837 81.414C126.062 82.647 125.789 83.898 125.072 84.936C124.355 85.974 123.278 86.667 122.045 86.892L111.776 88.761C111.494 88.818 111.209 88.842 110.93 88.842ZM121.202 79.347C121.028 79.347 120.854 79.362 120.68 79.395L110.411 81.264C108.83 81.552 107.78 83.07 108.068 84.651C108.356 86.229 109.874 87.282 111.455 86.994L121.724 85.125C122.486 84.987 123.149 84.558 123.593 83.913C124.037 83.271 124.205 82.497 124.067 81.738C123.929 80.979 123.5 80.313 122.858 79.869C122.363 79.527 121.787 79.347 121.202 79.347Z"
          fill="#C86E6E"
        />
        <path
          d="M115.97 112.908C115.169 112.908 114.386 112.773 113.687 112.494C112.172 111.894 111.176 110.691 110.879 109.104C110.426 106.695 111.437 104.949 112.781 103.788C111.773 103.521 110.894 103.029 110.201 102.327C109.001 101.112 108.548 99.438 108.92 97.611C109.703 93.789 112.946 92.973 114.659 93.072C115.238 92.988 117.236 92.883 120.056 94.695C120.473 94.965 120.596 95.52 120.326 95.94C120.056 96.357 119.501 96.48 119.081 96.21C116.513 94.557 114.911 94.851 114.896 94.857C114.806 94.878 114.716 94.881 114.623 94.875C114.293 94.851 111.347 94.722 110.678 97.974C110.429 99.192 110.711 100.29 111.476 101.064C112.46 102.06 114.149 102.447 115.991 102.093C116.465 102 116.93 102.303 117.038 102.774C117.146 103.245 116.861 103.719 116.396 103.845C116.216 103.893 111.956 105.108 112.643 108.774C112.829 109.758 113.402 110.448 114.347 110.823C115.775 111.39 117.773 111.102 119.093 110.136C119.495 109.842 120.056 109.932 120.35 110.334C120.644 110.736 120.554 111.3 120.152 111.591C118.979 112.449 117.446 112.908 115.97 112.908Z"
          fill="#757575"
        />
        <path
          d="M101.897 88.068L91.1209 90.477C90.1489 90.696 89.1739 90.078 88.9579 89.103L88.7179 88.035C88.4989 87.063 89.1169 86.088 90.0919 85.872L100.868 83.463C101.84 83.244 102.815 83.862 103.031 84.837L103.271 85.905C103.487 86.877 102.869 87.852 101.897 88.068Z"
          fill="#616161"
        />
        <path
          d="M142.301 79.8L131.525 82.209C130.553 82.428 129.578 81.81 129.362 80.835L129.122 79.767C128.903 78.795 129.521 77.82 130.496 77.604L141.272 75.195C142.244 74.976 143.219 75.594 143.435 76.569L143.675 77.637C143.891 78.609 143.273 79.581 142.301 79.8Z"
          fill="#616161"
        />
        <path
          d="M133.404 117.399C121.737 111.642 119.454 98.472 124.56 89.571C128.748 82.269 139.278 78.819 146.007 79.335"
          fill="white"
        />
        <path
          d="M133.403 118.299C133.268 118.299 133.133 118.269 133.004 118.206C127.586 115.533 123.677 111.024 121.997 105.513C120.317 99.996 120.965 94.023 123.779 89.124C127.988 81.789 138.632 77.868 146.075 78.438C146.57 78.477 146.942 78.909 146.903 79.404C146.864 79.899 146.432 80.268 145.937 80.232C139.037 79.701 129.197 83.292 125.339 90.018C122.774 94.488 122.183 99.945 123.719 104.988C125.252 110.019 128.831 114.141 133.799 116.592C134.246 116.811 134.429 117.351 134.207 117.798C134.054 118.116 133.736 118.299 133.403 118.299Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.3"
          d="M146.551 84.048C138.556 82.512 130.558 85.959 128.893 96.987C127.228 108.015 138.784 120.39 150.997 108.447C163.21 96.504 156.355 85.932 146.551 84.048Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.3"
          d="M107.567 115.974C109.715 108.144 106.94 98.856 96.3082 96.285C85.6762 93.714 71.6692 106.23 82.3402 119.373C93.0112 132.516 104.933 125.577 107.567 115.974Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.3"
          d="M88.6998 52.773C87.9588 53.085 87.3408 53.658 86.9418 54.648C84.8148 59.91 91.7928 66.222 94.7208 68.385C98.7858 71.388 101.66 71.721 106.307 73.218C109.889 74.37 116.12 73.815 119.681 72.312C124.313 70.359 126.743 65.286 128.522 60.123C131.051 52.791 127.877 43.686 120.392 44.286C117.395 44.526 115.661 47.385 113.294 49.092C110.159 51.351 106.202 51.816 102.623 52.308C100.37 52.617 97.8648 53.112 95.4948 53.067C93.6108 53.031 90.7068 51.93 88.6998 52.773Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.2"
          d="M189.001 116.274C193.024 115.092 196.444 111.441 194.881 106.257C193.318 101.073 184.204 98.49 181.078 106.512C177.955 114.534 184.066 117.723 189.001 116.274Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.2"
          d="M105.586 148.143C104.821 148.389 104.155 148.908 103.672 149.859C101.098 154.917 107.503 161.808 110.236 164.214C114.028 167.559 116.863 168.138 121.363 170.031C124.831 171.489 131.086 171.471 134.764 170.283C139.549 168.738 142.408 163.893 144.625 158.904C147.778 151.818 145.402 142.473 137.893 142.425C134.887 142.407 132.913 145.104 130.405 146.598C127.087 148.578 123.106 148.698 119.497 148.881C117.226 148.995 114.688 149.271 112.33 149.022C110.455 148.824 107.659 147.477 105.586 148.143Z"
          fill="#9E9E9E"
        />
        <path
          d="M142.564 181.002L143.557 191.604C144.139 197.817 149.701 202.422 155.911 201.84C160.528 201.408 164.251 198.225 165.625 194.067C167.248 190.287 168.409 184.5 169.009 177.378C169.009 177.378 169.318 160.503 152.968 163.707"
          fill="white"
        />
        <path
          d="M154.844 202.791C152.006 202.791 149.276 201.807 147.05 199.965C144.524 197.874 142.967 194.934 142.661 191.691L141.668 181.089C141.623 180.594 141.986 180.156 142.481 180.108C142.976 180.06 143.414 180.426 143.462 180.921L144.455 191.523C144.713 194.289 146.045 196.794 148.199 198.582C150.353 200.367 153.065 201.21 155.831 200.949C159.956 200.562 163.466 197.754 164.774 193.791C164.783 193.767 164.792 193.743 164.801 193.719C166.37 190.062 167.516 184.401 168.11 177.345C168.11 176.877 168.044 170.061 163.748 166.467C161.213 164.343 157.643 163.716 153.14 164.598C152.654 164.694 152.18 164.376 152.084 163.887C151.988 163.398 152.306 162.927 152.795 162.831C157.862 161.838 161.939 162.6 164.912 165.093C169.997 169.359 169.916 177.075 169.91 177.402C169.91 177.423 169.91 177.441 169.907 177.462C169.298 184.704 168.11 190.557 166.469 194.394C164.927 199.014 160.82 202.29 155.996 202.743C155.612 202.773 155.225 202.791 154.844 202.791Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.2"
          d="M160.376 182.286C164 180.18 166.451 175.815 163.697 171.153C160.943 166.491 151.475 166.155 150.353 174.693C149.234 183.231 155.93 184.872 160.376 182.286Z"
          fill="#9E9E9E"
        />
        <path
          d="M87.6875 79.383C87.6875 79.383 96.4115 81.786 99.6155 82.629C102.82 83.472 104.335 82.293 103.492 79.596"
          fill="white"
        />
        <path
          d="M101.428 83.7929C100.858 83.7929 100.183 83.7059 99.3846 83.496C96.2136 82.662 87.5346 80.271 87.4476 80.247C86.9676 80.115 86.6857 79.62 86.8177 79.14C86.9497 78.66 87.4446 78.381 87.9246 78.51C88.0116 78.534 96.6786 80.919 99.8406 81.753C101.17 82.104 102.199 82.065 102.589 81.657C102.88 81.351 102.895 80.712 102.628 79.86C102.481 79.386 102.745 78.882 103.219 78.732C103.693 78.585 104.197 78.849 104.347 79.323C104.95 81.255 104.431 82.335 103.891 82.902C103.465 83.3519 102.715 83.7929 101.428 83.7929Z"
          fill="#A8A8A8"
        />
        <path
          d="M129.113 66.54C129.113 66.54 136.652 61.14 137.942 60.321C139.232 59.502 141.482 60.078 141.923 62.316"
          fill="white"
        />
        <path
          d="M129.114 67.44C128.832 67.44 128.556 67.308 128.382 67.065C128.094 66.66 128.187 66.099 128.589 65.808C128.898 65.586 136.164 60.384 137.46 59.562C138.369 58.986 139.587 58.908 140.64 59.361C141.765 59.844 142.554 60.858 142.809 62.142C142.905 62.628 142.587 63.102 142.101 63.198C141.615 63.294 141.141 62.976 141.045 62.49C140.907 61.785 140.511 61.263 139.935 61.014C139.428 60.795 138.837 60.822 138.429 61.08C137.175 61.878 129.717 67.218 129.642 67.272C129.48 67.386 129.297 67.44 129.114 67.44Z"
          fill="#A8A8A8"
        />
        <path
          opacity="0.06"
          d="M85.1152 240L181.04 0H226.571L130.646 240H85.1152Z"
          fill="#38636D"
        />
        <path
          opacity="0.06"
          d="M240 46.56L162.684 240H147.9L240 9.576V46.56Z"
          fill="#38636D"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="240" height="240" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(BlockedBino),
};
export default Asset;
