import dayjs from 'dayjs';

/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

import { RelationshipType } from 'generated/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const title = {
    linkGuardian: t('account.link_guardian.title'),
    childCode: t('account.child_code.title'),
  };

  const message = {
    emptyGuardian: t('account.message.empty_guardian'),
    parentGuide: t('account.child_code.description'),
    timeLimit(expireAt: string) {
      const date = dayjs(expireAt);

      const hour = String(date.get('hour')).padStart(2, '0');
      const min = String(date.get('minute')).padStart(2, '0');

      return t('account.child_code.message.time_limit', {
        year: date.get('year'),
        month: date.get('month') + 1,
        day: date.get('date'),
        time: `${hour}:${min}`,
      });
    },
    codeCopied: t('account.message.code_copied'),
  };

  const button = {
    viewChildCode: t('account.button.view_child_code'),
    renewChildCode: t('account.button.renew_child_code'),
  };

  const relation: Record<RelationshipType, string> = {
    FATHER: t('account.item.relationship.father'),
    MOTHER: t('account.item.relationship.mother'),
    GRAND_FATHER: t('account.item.relationship.grand_father'),
    GRAND_MOTHER: t('account.item.relationship.grand_mother'),
    GUARDIAN: t('account.item.relationship.guardian'),
  };

  return {
    title,
    message,
    button,
    relation,
  };
};

export default useTranslatedTexts;
