/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function AppleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0H4a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4V4a4 4 0 00-4-4z"
        fill="#000"
      />
      <path
        d="M8.1 5.113c.362 0 .818-.253 1.089-.59.245-.306.424-.733.424-1.16a.804.804 0 00-.015-.163c-.404.016-.89.28-1.182.632-.23.27-.44.69-.44 1.123 0 .063.01.127.016.148a.57.57 0 00.107.01zM6.82 11.491c.496 0 .716-.343 1.335-.343.629 0 .767.332 1.319.332.542 0 .905-.516 1.248-1.022.383-.58.542-1.15.552-1.176-.036-.01-1.074-.448-1.074-1.676 0-1.064.818-1.544.864-1.58-.542-.802-1.365-.823-1.59-.823-.609 0-1.105.38-1.417.38-.337 0-.782-.36-1.309-.36-1.002 0-2.02.855-2.02 2.468 0 1.001.379 2.06.844 2.746.4.58.747 1.054 1.248 1.054z"
        fill="#fff"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(AppleIcon),
};

export default Asset;
