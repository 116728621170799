/* istanbul ignore file */
/* eslint-disable */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function AzureIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2673_164722)">
        <path d="M2 2H11.489V11.5H2V2Z" fill="#F25022" />
        <path d="M12.4878 2H21.9769V11.5H12.4878V2Z" fill="#7FBA00" />
        <path d="M2 12.5H11.489V22H2V12.5Z" fill="#00A4EF" />
        <path d="M12.4878 12.5H21.9769V22H12.4878V12.5Z" fill="#FFB900" />
      </g>
      <defs>
        <clipPath id="clip0_2673_164722">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(AzureIcon),
};

/* eslint-enable */
export default Asset;
