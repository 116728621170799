import React from 'react';

const size = {
  width: '416px',
  height: '120px',
};
function ByeFriends(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 416 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Bye Friends</title>
      <path
        d="M40.8389 65.1182V97.6232C40.8389 103.367 43.7384 108.465 48.1454 111.53H57.5969V105.287C57.5969 103.85 58.7729 102.675 60.2084 102.675C61.6454 102.675 62.8199 103.85 62.8199 105.287V111.53H72.0119C76.5614 108.536 79.5764 103.478 79.5764 97.6232V65.1182"
        fill="#415F6E"
      />
      <path
        d="M72.0121 111.978H62.8201C62.5711 111.978 62.3701 111.777 62.3701 111.528V105.285C62.3701 104.093 61.4011 103.124 60.2086 103.124C59.0161 103.124 58.0471 104.093 58.0471 105.285V111.528C58.0471 111.777 57.8461 111.978 57.5971 111.978H48.1456C48.0541 111.978 47.9641 111.95 47.8891 111.897C43.1941 108.632 40.3906 103.296 40.3906 97.6215V65.1165C40.3906 64.8675 40.5916 64.6665 40.8406 64.6665C41.0896 64.6665 41.2906 64.8675 41.2906 65.1165V97.6215C41.2906 102.954 43.9036 107.973 48.2881 111.078H57.1471V105.285C57.1471 103.598 58.5211 102.224 60.2086 102.224C61.8961 102.224 63.2701 103.598 63.2701 105.285V111.078H71.8771C76.4191 108.036 79.1266 103.016 79.1266 97.6215V65.1165C79.1266 64.8675 79.3276 64.6665 79.5766 64.6665C79.8256 64.6665 80.0266 64.8675 80.0266 65.1165V97.6215C80.0266 103.364 77.1226 108.702 72.2596 111.903C72.1861 111.953 72.1006 111.978 72.0121 111.978Z"
        fill="#324650"
      />
      <path
        d="M71.4273 60.9316V86.7316C71.4273 92.9026 66.3783 97.9516 60.2073 97.9516C54.0363 97.9516 48.9873 92.9026 48.9873 86.7316V60.9316"
        fill="#F5D7D2"
      />
      <path
        d="M57.9269 83.7734C61.0859 84.2219 63.5414 87.5385 63.5414 91.5675C63.5414 95.9025 59.8889 100.203 54.5024 98.6655C49.4474 96.642 43.5569 93.8745 40.0754 89.9655C36.7724 86.259 33.4844 81.7005 33.4844 76.6065C33.4844 69.3045 43.0319 65.877 43.0319 65.877C44.5739 73.4265 49.1294 81.306 55.2314 83.2215C55.2329 83.223 57.8249 83.7629 57.9269 83.7734Z"
        fill="#415F6E"
      />
      <path
        d="M56.6701 99.4305C55.9246 99.4305 55.1566 99.321 54.3796 99.099C54.3646 99.0945 54.3496 99.09 54.3361 99.084C44.9461 95.325 41.5081 92.2515 39.7411 90.2655C36.6196 86.7615 33.0361 82.041 33.0361 76.6065C33.0361 73.473 34.7491 70.545 37.9921 68.142C40.4041 66.354 42.7816 65.49 42.8821 65.454C43.0051 65.4105 43.1416 65.421 43.2541 65.4855C43.3681 65.55 43.4476 65.6595 43.4746 65.787C44.2576 69.621 45.7936 73.3935 47.7976 76.413C49.9906 79.713 52.5991 81.9165 55.3471 82.785C56.3071 82.9845 57.8551 83.3055 57.9886 83.3265C57.9901 83.3265 57.9901 83.3265 57.9916 83.3265C61.4131 83.8125 63.9931 87.354 63.9931 91.566C63.9931 94.1085 62.7961 96.5895 60.8701 98.043C59.6536 98.9625 58.2106 99.4305 56.6701 99.4305ZM54.6496 98.241C57.3346 98.9985 59.2186 98.1645 60.3271 97.3275C62.0326 96.0405 63.0916 93.834 63.0916 91.569C63.0916 87.8025 60.8446 84.642 57.8641 84.2205C57.7081 84.1965 56.7916 84.009 55.1401 83.664C55.1251 83.661 55.1116 83.658 55.0966 83.6535C52.1431 82.7265 49.3591 80.3955 47.0461 76.914C45.0706 73.9395 43.5376 70.2585 42.7021 66.4965C41.8951 66.837 40.1911 67.6305 38.5081 68.8815C35.4736 71.1375 33.9346 73.7385 33.9346 76.6095C33.9346 81.741 37.3966 86.2845 40.4116 89.67C43.9726 93.6645 50.2366 96.4725 54.6496 98.241Z"
        fill="#324650"
      />
      <path
        d="M62.2063 83.7735C59.0473 84.222 56.5918 87.5385 56.5918 91.5675C56.5918 95.9025 60.7258 99.8685 65.6308 98.6655C70.6858 96.642 76.5763 93.8745 80.0578 89.9655C83.3608 86.259 86.6488 81.7005 86.6488 76.6065C86.6488 69.3045 77.1013 65.877 77.1013 65.877C75.5593 73.4265 71.0038 81.306 64.9018 83.2215C64.9018 83.223 62.3098 83.763 62.2063 83.7735Z"
        fill="#415F6E"
      />
      <path
        d="M63.8445 99.3359C62.1675 99.3359 60.5596 98.7809 59.1901 97.7054C57.2821 96.2084 56.1436 93.9149 56.1436 91.5674C56.1436 87.3554 58.7236 83.8124 62.1451 83.3279C62.1466 83.3279 62.1495 83.3279 62.151 83.3279C62.301 83.3039 63.8355 82.9859 64.7895 82.7879C67.536 81.9194 70.146 79.7159 72.339 76.4159C74.3445 73.3979 75.879 69.6239 76.662 65.7899C76.6875 65.6624 76.7685 65.5514 76.8825 65.4884C76.9965 65.4239 77.133 65.4134 77.2545 65.4569C77.355 65.4929 79.731 66.3569 82.1445 68.1449C85.386 70.5479 87.1005 73.4759 87.1005 76.6094C87.1005 82.0439 83.517 86.7644 80.3955 90.2684C78.627 92.2529 75.1905 95.3279 65.8005 99.0869C65.781 99.0944 65.7615 99.1004 65.7405 99.1064C65.103 99.2594 64.4685 99.3359 63.8445 99.3359ZM62.2695 84.2189C59.2891 84.6419 57.0421 87.8009 57.0421 91.5689C57.0421 93.6419 58.0516 95.6714 59.7436 96.9989C61.3936 98.2934 63.435 98.7329 65.493 98.2379C74.6835 94.5554 78.015 91.5839 79.7205 89.6684C82.7355 86.2829 86.1975 81.7394 86.1975 76.6079C86.1975 73.7354 84.6585 71.1359 81.624 68.8799C79.941 67.6289 78.237 66.8354 77.43 66.4949C76.5945 70.2569 75.0615 73.9379 73.086 76.9124C70.773 80.3954 67.989 82.7249 65.0355 83.6519C65.022 83.6564 65.007 83.6594 64.992 83.6624C63.342 84.0074 62.427 84.1949 62.2695 84.2189Z"
        fill="#324650"
      />
      <path
        d="M44.8965 93.9403C47.9025 95.8663 51.3645 97.4098 54.504 98.6668C59.889 100.204 63.543 95.9038 63.543 91.5688C63.543 87.5383 61.0875 84.2218 57.9285 83.7748C57.825 83.7643 55.233 83.2243 55.233 83.2243C54.2175 82.9063 53.2455 82.4203 52.3215 81.8008"
        fill="#F5D7D2"
      />
      <path
        d="M56.6703 99.4304C55.9248 99.4304 55.1568 99.3209 54.3798 99.0989C54.3648 99.0944 54.3498 99.0899 54.3363 99.0839C51.7683 98.0564 47.9718 96.4439 44.6538 94.3184C44.4438 94.1849 44.3838 93.9059 44.5173 93.6974C44.6508 93.4889 44.9298 93.4274 45.1383 93.5609C48.3828 95.6384 52.1148 97.2254 54.6483 98.2394C57.3333 98.9984 59.2173 98.1629 60.3258 97.3259C62.0313 96.0389 63.0903 93.8324 63.0903 91.5674C63.0903 87.8009 60.8433 84.6404 57.8628 84.2189C57.7083 84.1949 56.7933 84.0074 55.1388 83.6624C55.1238 83.6594 55.1103 83.6564 55.0953 83.6519C54.0678 83.3294 53.0493 82.8314 52.0683 82.1729C51.8613 82.0349 51.8073 81.7544 51.9453 81.5489C52.0833 81.3419 52.3638 81.2879 52.5693 81.4259C53.4723 82.0319 54.4053 82.4894 55.3428 82.7864C56.3028 82.9859 57.8508 83.3069 57.9843 83.3279C61.4088 83.8139 63.9888 87.3554 63.9888 91.5689C63.9888 94.1114 62.7918 96.5924 60.8658 98.0459C59.6538 98.9624 58.2108 99.4304 56.6703 99.4304Z"
        fill="#646464"
      />
      <path
        d="M69.0913 83.9536C68.9653 83.0161 68.8393 82.0771 68.7133 81.1396C67.5298 82.0801 66.2533 82.7986 64.9018 83.2231C64.9018 83.2231 62.3098 83.7631 62.2063 83.7736C59.0473 84.2221 56.5918 87.5386 56.5918 91.5676C56.5918 95.9026 60.7258 99.8686 65.6308 98.6656C68.2363 97.6231 71.0623 96.3826 73.6663 94.8901"
        fill="#F5D7D2"
      />
      <path
        d="M63.8446 99.3361C62.1676 99.3361 60.5596 98.7811 59.1901 97.7056C57.2821 96.2086 56.1436 93.9151 56.1436 91.5676C56.1436 87.3556 58.7236 83.8126 62.1451 83.3281C62.1511 83.3281 62.1571 83.3266 62.1631 83.3266C62.2816 83.3071 63.8296 82.9876 64.7896 82.7866C66.0361 82.3921 67.2616 81.7201 68.4346 80.7871C68.5621 80.6866 68.7331 80.6611 68.8831 80.7226C69.0331 80.7826 69.1396 80.9191 69.1606 81.0796L69.5386 83.8936C69.5716 84.1396 69.3991 84.3661 69.1531 84.3991C68.9086 84.4321 68.6806 84.2596 68.6476 84.0136L68.3716 81.9616C67.2946 82.7281 66.1756 83.2951 65.0386 83.6521C65.0251 83.6566 65.0101 83.6596 64.9951 83.6626C63.3421 84.0076 62.4256 84.1936 62.2711 84.2191C59.2906 84.6421 57.0436 87.8011 57.0436 91.5676C57.0436 93.6406 58.0531 95.6701 59.7451 96.9976C61.3966 98.2921 63.4366 98.7316 65.4946 98.2366C68.7331 96.9391 71.2591 95.7511 73.4431 94.5001C73.6591 94.3771 73.9336 94.4506 74.0566 94.6666C74.1796 94.8826 74.1061 95.1571 73.8901 95.2801C71.6611 96.5566 69.0901 97.7656 65.7976 99.0826C65.7781 99.0901 65.7586 99.0961 65.7376 99.1021C65.1031 99.2596 64.4686 99.3361 63.8446 99.3361Z"
        fill="#646464"
      />
      <path
        d="M54.9843 114.672H43.3293C41.8923 114.672 40.7178 113.498 40.7178 112.061C40.7178 110.624 41.8923 109.449 43.3293 109.449H54.9843C56.4213 109.449 57.5958 110.624 57.5958 112.061C57.5973 113.496 56.4213 114.672 54.9843 114.672Z"
        fill="#F5D7D2"
      />
      <path
        d="M54.9841 115.122H43.3291C41.6416 115.122 40.2676 113.748 40.2676 112.061C40.2676 110.373 41.6416 108.999 43.3291 108.999H54.9841C56.6716 108.999 58.0456 110.373 58.0456 112.061C58.0471 113.748 56.6731 115.122 54.9841 115.122ZM43.3291 109.899C42.1366 109.899 41.1676 110.868 41.1676 112.061C41.1676 113.253 42.1366 114.222 43.3291 114.222H54.9841C56.1766 114.222 57.1456 113.253 57.1456 112.061C57.1456 110.868 56.1766 109.899 54.9841 109.899H43.3291Z"
        fill="#646464"
      />
      <path
        d="M54.9722 114.672H46.1672C44.7302 114.672 43.5557 113.498 43.5557 112.061C43.5557 110.624 44.7302 109.449 46.1672 109.449H54.3872"
        fill="#F5D7D2"
      />
      <path
        d="M54.972 115.122H46.167C44.4795 115.122 43.1055 113.748 43.1055 112.061C43.1055 110.373 44.4795 108.999 46.167 108.999H54.387C54.636 108.999 54.837 109.2 54.837 109.449C54.837 109.698 54.636 109.899 54.387 109.899H46.167C44.9745 109.899 44.0055 110.868 44.0055 112.061C44.0055 113.253 44.9745 114.222 46.167 114.222H54.972C55.221 114.222 55.422 114.423 55.422 114.672C55.422 114.921 55.221 115.122 54.972 115.122Z"
        fill="#646464"
      />
      <path
        d="M65.4318 114.672H77.0868C78.5238 114.672 79.6983 113.498 79.6983 112.061C79.6983 110.624 78.5238 109.449 77.0868 109.449H65.4318C63.9948 109.449 62.8203 110.624 62.8203 112.061C62.8203 113.496 63.9948 114.672 65.4318 114.672Z"
        fill="#F5D7D2"
      />
      <path
        d="M77.0866 115.122H65.4316C63.7441 115.122 62.3701 113.748 62.3701 112.061C62.3701 110.373 63.7441 108.999 65.4316 108.999H77.0866C78.7741 108.999 80.1481 110.373 80.1481 112.061C80.1481 113.748 78.7741 115.122 77.0866 115.122ZM65.4316 109.899C64.2391 109.899 63.2701 110.868 63.2701 112.061C63.2701 113.253 64.2391 114.222 65.4316 114.222H77.0866C78.2791 114.222 79.2481 113.253 79.2481 112.061C79.2481 110.868 78.2791 109.899 77.0866 109.899H65.4316Z"
        fill="#646464"
      />
      <path
        d="M65.4434 114.672H74.2484C75.6854 114.672 76.8598 113.498 76.8598 112.061C76.8598 110.624 75.6854 109.449 74.2484 109.449H72.7634H66.2234"
        fill="#F5D7D2"
      />
      <path
        d="M74.2486 115.122H65.4451C65.1961 115.122 64.9951 114.921 64.9951 114.672C64.9951 114.423 65.1961 114.222 65.4451 114.222H74.2486C75.4411 114.222 76.4101 113.253 76.4101 112.061C76.4101 110.868 75.4411 109.899 74.2486 109.899H66.2251C65.9761 109.899 65.7751 109.698 65.7751 109.449C65.7751 109.2 65.9761 108.999 66.2251 108.999H74.2486C75.9361 108.999 77.3101 110.373 77.3101 112.061C77.3101 113.748 75.9361 115.122 74.2486 115.122Z"
        fill="#646464"
      />
      <path
        d="M88.5518 74.2015C94.5332 73.878 99.2378 70.9483 99.0598 67.6577C98.8819 64.3672 93.8888 61.9619 87.9074 62.2854C81.926 62.6089 77.2214 65.5386 77.3994 68.8291C77.5773 72.1197 82.5704 74.525 88.5518 74.2015Z"
        fill="#F5D7D2"
      />
      <path
        d="M87.475 74.6864C84.9145 74.6864 82.5085 74.1974 80.605 73.2809C78.3445 72.1919 77.047 70.6214 76.951 68.8589C76.855 67.0964 77.977 65.3954 80.107 64.0679C82.1545 62.7929 84.9175 62.0024 87.8845 61.8419C90.8515 61.6814 93.6835 62.1704 95.857 63.2174C98.1175 64.3064 99.415 65.8769 99.511 67.6394C99.607 69.4019 98.485 71.1029 96.355 72.4304C94.3075 73.7054 91.5445 74.4959 88.5775 74.6564C88.2085 74.6759 87.841 74.6864 87.475 74.6864ZM88.993 62.7119C88.6435 62.7119 88.2895 62.7209 87.9355 62.7404C85.117 62.8934 82.507 63.6359 80.5855 64.8314C78.7465 65.9759 77.776 67.3889 77.8525 68.8094C77.929 70.2299 79.0465 71.5289 80.998 72.4694C83.0365 73.4519 85.7125 73.9094 88.531 73.7564C91.3495 73.6034 93.9595 72.8609 95.881 71.6654C97.72 70.5209 98.6905 69.1079 98.614 67.6874C98.5375 66.2669 97.42 64.9679 95.4685 64.0274C93.685 63.1694 91.417 62.7119 88.993 62.7119Z"
        fill="#646464"
      />
      <path
        d="M88.3782 71.4359C91.3692 71.3036 93.7412 70.0068 93.6763 68.5394C93.6113 67.0721 91.1341 65.9899 88.1431 66.1222C85.1522 66.2545 82.7802 67.5513 82.8451 69.0187C82.91 70.486 85.3873 71.5682 88.3782 71.4359Z"
        fill="#F5D7D2"
      />
      <path
        d="M87.8817 71.901C84.8607 71.901 82.4967 70.7085 82.3977 69.072C82.3662 68.5455 82.5717 68.019 82.9917 67.5495C83.9307 66.501 85.8807 65.7855 88.0797 65.679C91.3647 65.5185 94.0212 66.7575 94.1262 68.4945C94.1577 69.021 93.9522 69.5475 93.5322 70.017C92.5932 71.0655 90.6432 71.781 88.4442 71.8875C88.2537 71.8965 88.0662 71.901 87.8817 71.901ZM88.5777 66.567C88.4247 66.567 88.2732 66.57 88.1217 66.5775C86.1852 66.6705 84.4332 67.2885 83.6607 68.1495C83.4012 68.439 83.2782 68.7315 83.2947 69.0165C83.3742 70.32 86.0472 71.1 88.4007 70.9875C90.3372 70.8945 92.0892 70.2765 92.8617 69.4155C93.1212 69.126 93.2442 68.8335 93.2277 68.5485C93.1527 67.3305 90.8052 66.567 88.5777 66.567Z"
        fill="#646464"
      />
      <path
        d="M43.1266 68.6787C43.2581 65.3859 38.5124 62.5227 32.5269 62.2837C26.5413 62.0447 21.5825 64.5203 21.451 67.8132C21.3195 71.106 26.0651 73.9692 32.0507 74.2082C38.0363 74.4472 42.9951 71.9716 43.1266 68.6787Z"
        fill="#F5D7D2"
      />
      <path
        d="M32.8531 74.676C32.5816 74.676 32.3086 74.67 32.0341 74.6595C29.0641 74.541 26.2921 73.7895 24.2266 72.543C22.0771 71.247 20.9326 69.561 21.0031 67.797C21.0736 66.033 22.3486 64.4445 24.5941 63.324C26.7526 62.247 29.5771 61.7175 32.5456 61.8375C35.5141 61.956 38.2876 62.7075 40.3531 63.954C42.5026 65.25 43.6471 66.936 43.5766 68.7C43.5061 70.464 42.2311 72.0525 39.9856 73.173C38.0281 74.151 35.5201 74.676 32.8531 74.676ZM31.7251 62.721C29.1976 62.721 26.8336 63.213 24.9976 64.1295C23.0596 65.097 21.9601 66.4125 21.9031 67.833C21.8461 69.2535 22.8361 70.653 24.6916 71.772C26.6296 72.9405 29.2501 73.647 32.0701 73.7595C34.8901 73.872 37.5586 73.377 39.5836 72.3675C41.5216 71.4 42.6211 70.0845 42.6781 68.664C42.7351 67.2435 41.7451 65.844 39.8896 64.725C37.9516 63.5565 35.3311 62.85 32.5111 62.7375C32.2471 62.727 31.9846 62.721 31.7251 62.721ZM43.1281 68.682H43.1296H43.1281Z"
        fill="#646464"
      />
      <path
        d="M37.6922 68.9601C37.7402 67.4921 35.3526 66.2226 32.3593 66.1247C29.3661 66.0267 26.9006 67.1374 26.8526 68.6055C26.8046 70.0736 29.1921 71.3431 32.1854 71.441C35.1787 71.5389 37.6441 70.4282 37.6922 68.9601Z"
        fill="#F5D7D2"
      />
      <path
        d="M32.5527 71.8967C32.4147 71.8967 32.2752 71.8937 32.1342 71.8892C29.9337 71.8112 27.9732 71.1182 27.0177 70.0832C26.5902 69.6197 26.3772 69.0962 26.4012 68.5697C26.4792 66.8297 29.1162 65.5607 32.4042 65.6762C34.6047 65.7542 36.5652 66.4472 37.5207 67.4822C37.9482 67.9457 38.1612 68.4692 38.1372 68.9957C38.0622 70.6622 35.6427 71.8967 32.5527 71.8967ZM32.0382 66.5702C29.7747 66.5702 27.3567 67.3682 27.2997 68.6102C27.2862 68.8952 27.4152 69.1862 27.6792 69.4727C28.4652 70.3247 30.2262 70.9202 32.1657 70.9907C34.5177 71.0762 37.1802 70.2602 37.2387 68.9567C37.2522 68.6717 37.1232 68.3807 36.8592 68.0942C36.0732 67.2422 34.3122 66.6467 32.3727 66.5762C32.2617 66.5732 32.1492 66.5702 32.0382 66.5702Z"
        fill="#646464"
      />
      <path
        d="M31.2725 61.2437C31.2725 77.1572 44.2925 90.1757 60.2045 90.1757C76.118 90.1757 89.1365 77.1557 89.1365 61.2437L89.1245 47.5502C89.1245 31.5632 76.1645 23.3477 60.176 23.3477C44.1875 23.3477 31.2275 31.5632 31.2275 47.5502L31.2725 61.2437Z"
        fill="#415F6E"
      />
      <path
        d="M60.2043 90.6254C52.3878 90.6254 45.0183 87.561 39.4518 81.9945C33.8868 76.4295 30.8208 69.06 30.8208 61.2435L30.7773 47.5515C30.7773 39.858 33.7698 33.477 39.4308 29.097C44.6748 25.041 51.8478 22.8975 60.1758 22.8975C68.5038 22.8975 75.6768 25.041 80.9208 29.097C86.5818 33.477 89.5743 39.858 89.5743 47.55L89.5878 61.242C89.5878 69.0585 86.5233 76.4295 80.9568 81.9945C75.3903 87.561 68.0208 90.6254 60.2043 90.6254ZM60.1758 23.799C52.0488 23.799 45.0663 25.878 39.9813 29.811C34.5483 34.0125 31.6773 40.1475 31.6773 47.5515L31.7208 61.242V61.2435C31.7208 68.82 34.6923 75.963 40.0878 81.3585C45.4833 86.754 52.6278 89.7254 60.2028 89.7254C67.7778 89.7254 74.9223 86.754 80.3178 81.3585C85.7133 75.963 88.6848 68.8185 88.6848 61.2435L88.6743 47.5515C88.6743 40.1475 85.8033 34.0125 80.3703 29.811C75.2853 25.8765 68.3028 23.799 60.1758 23.799Z"
        fill="#324650"
      />
      <path
        d="M78.2057 69.7275H42.2177C39.5402 70.7295 37.8047 72.1725 37.8047 73.9515C37.8047 76.755 42.0947 80.529 47.6297 80.862C47.6297 83.697 53.1992 86.9055 60.2042 86.9055C67.2092 86.9055 72.7787 83.2815 72.7787 80.862C78.3137 80.529 82.6037 76.755 82.6037 73.9515C82.6052 72.174 80.8757 70.731 78.2057 69.7275Z"
        fill="#F5D7D2"
      />
      <path
        d="M34.4102 66.5117C34.4102 67.7642 35.4887 71.1032 38.2082 72.4787C42.9587 74.8787 51.9632 75.8252 59.9537 75.8252C68.3657 75.8252 77.5577 75.0347 82.5902 72.4787C85.0757 71.2157 85.9577 67.6502 85.9577 66.5117"
        fill="#415F6E"
      />
      <path
        d="M59.9534 76.275C50.418 76.275 42.213 75.006 38.0055 72.8805C35.181 71.454 33.96 67.974 33.96 66.513C33.96 66.264 34.161 66.063 34.41 66.063C34.659 66.063 34.86 66.264 34.86 66.513C34.86 67.623 35.8815 70.8 38.412 72.078C42.501 74.1435 50.5545 75.3765 59.9549 75.3765C65.9084 75.3765 76.7384 74.9475 82.3874 72.078C84.7619 70.872 85.5089 67.3905 85.5089 66.513C85.5089 66.264 85.7099 66.063 85.9589 66.063C86.2079 66.063 86.4089 66.264 86.4089 66.513C86.4089 67.674 85.5194 71.496 82.7954 72.8805C79.7444 74.4285 73.3064 76.275 59.9534 76.275Z"
        fill="#324650"
      />
      <path
        d="M57.7413 78.9359C58.3643 78.9359 58.8693 78.7177 58.8693 78.4484C58.8693 78.1792 58.3643 77.9609 57.7413 77.9609C57.1183 77.9609 56.6133 78.1792 56.6133 78.4484C56.6133 78.7177 57.1183 78.9359 57.7413 78.9359Z"
        fill="black"
      />
      <path
        d="M62.6671 78.996C63.2901 78.996 63.7951 78.7777 63.7951 78.5085C63.7951 78.2393 63.2901 78.021 62.6671 78.021C62.0441 78.021 61.5391 78.2393 61.5391 78.5085C61.5391 78.7777 62.0441 78.996 62.6671 78.996Z"
        fill="black"
      />
      <path
        d="M68.3439 78.6868C68.1084 78.6868 67.9104 78.5038 67.8954 78.2653C67.8804 78.0178 68.0694 77.8033 68.3169 77.7883C71.5644 77.5873 74.5014 77.0218 74.5299 77.0158C74.7744 76.9678 75.0099 77.1283 75.0579 77.3713C75.1059 77.6158 74.9454 77.8513 74.7024 77.8993C74.6724 77.9053 71.6814 78.4828 68.3724 78.6868C68.3619 78.6868 68.3529 78.6868 68.3439 78.6868Z"
        fill="black"
      />
      <path
        d="M52.0009 78.6869C51.9919 78.6869 51.9829 78.6869 51.9724 78.6854C48.6634 78.4814 45.6724 77.9039 45.6424 77.8979C45.3979 77.8499 45.2389 77.6144 45.2869 77.3699C45.3349 77.1254 45.5704 76.9664 45.8149 77.0144C45.8449 77.0204 48.7804 77.5859 52.0279 77.7869C52.2754 77.8019 52.4644 78.0164 52.4494 78.2639C52.4344 78.5039 52.2364 78.6869 52.0009 78.6869Z"
        fill="black"
      />
      <path
        d="M52.291 51.2491C48.994 51.2491 47.5225 45.5551 47.5225 42.1021C47.5225 35.0971 53.2015 30.0571 60.2065 30.0571C67.2115 30.0571 72.8905 35.0971 72.8905 42.1021C72.8905 46.3081 71.632 51.6196 67.7095 51.6196"
        fill="#415F6E"
      />
      <path
        d="M67.7073 52.0694C67.4583 52.0694 67.2573 51.8684 67.2573 51.6194C67.2573 51.3704 67.4583 51.1694 67.7073 51.1694C71.1993 51.1694 72.4383 46.2854 72.4383 42.1019C72.4383 38.8799 71.1693 35.9249 68.8668 33.7814C66.5973 31.6694 63.5208 30.5069 60.2043 30.5069C56.8878 30.5069 53.8113 31.6694 51.5418 33.7814C49.2393 35.9249 47.9703 38.8784 47.9703 42.1019C47.9703 45.6569 49.5078 50.7989 52.2888 50.7989C52.5378 50.7989 52.7388 50.9999 52.7388 51.2489C52.7388 51.4979 52.5378 51.6989 52.2888 51.6989C48.7293 51.6989 47.0703 45.9719 47.0703 42.1019C47.0703 38.6264 48.4413 35.4374 50.9283 33.1229C53.3658 30.8549 56.6598 29.6069 60.2043 29.6069C63.7488 29.6069 67.0428 30.8564 69.4803 33.1229C71.9688 35.4374 73.3383 38.6264 73.3383 42.1019C73.3383 46.6994 71.8638 52.0694 67.7073 52.0694Z"
        fill="#324650"
      />
      <path
        d="M122.29 81.9814V112.235C122.29 113.447 123.282 114.438 124.494 114.438H134.485C135.697 114.438 136.689 113.447 136.689 112.235V107.162C136.689 105.923 137.703 104.909 138.942 104.909C140.181 104.909 141.195 105.923 141.195 107.162V112.235C141.195 113.447 142.186 114.438 143.398 114.438H153.39C154.602 114.438 155.593 113.447 155.593 112.235V81.9814"
        fill="#5AA5EB"
      />
      <path
        d="M153.387 114.888H143.396C141.933 114.888 140.742 113.699 140.742 112.235V107.162C140.742 106.167 139.934 105.359 138.939 105.359C137.945 105.359 137.136 106.167 137.136 107.162V112.235C137.136 113.697 135.945 114.888 134.483 114.888H124.491C123.029 114.888 121.838 113.699 121.838 112.235V81.9813C121.838 81.7323 122.039 81.5312 122.288 81.5312C122.537 81.5312 122.738 81.7323 122.738 81.9813V112.235C122.738 113.202 123.524 113.988 124.491 113.988H134.483C135.449 113.988 136.236 113.202 136.236 112.235V107.162C136.236 105.671 137.448 104.459 138.939 104.459C140.43 104.459 141.642 105.671 141.642 107.162V112.235C141.642 113.202 142.428 113.988 143.396 113.988H153.387C154.353 113.988 155.141 113.202 155.141 112.235V81.9813C155.141 81.7323 155.342 81.5312 155.591 81.5312C155.84 81.5312 156.041 81.7323 156.041 81.9813V112.235C156.041 113.697 154.85 114.888 153.387 114.888Z"
        fill="#326E9B"
      />
      <path
        d="M138.939 103.016C145.655 103.016 151.099 97.5714 151.099 90.8553C151.099 84.1393 145.655 78.6948 138.939 78.6948C132.223 78.6948 126.778 84.1393 126.778 90.8553C126.778 97.5714 132.223 103.016 138.939 103.016Z"
        fill="white"
      />
      <path
        d="M127.158 114.888C125.931 114.888 124.934 113.891 124.934 112.664V110.135C124.934 109.886 125.135 109.685 125.384 109.685C125.633 109.685 125.834 109.886 125.834 110.135V112.664C125.834 113.394 126.428 113.988 127.158 113.988C127.407 113.988 127.608 114.189 127.608 114.438C127.608 114.687 127.406 114.888 127.158 114.888Z"
        fill="#326E9B"
      />
      <path
        d="M150.164 114.888C149.915 114.888 149.714 114.687 149.714 114.438C149.714 114.189 149.915 113.988 150.164 113.988C150.894 113.988 151.488 113.394 151.488 112.664V110.135C151.488 109.886 151.689 109.685 151.938 109.685C152.187 109.685 152.388 109.886 152.388 110.135V112.664C152.388 113.889 151.391 114.888 150.164 114.888Z"
        fill="#326E9B"
      />
      <path
        d="M154.966 81.5835C153.891 85.9995 150.114 89.586 145.726 90.762C145.726 90.762 144.783 91.299 144.277 91.299C141.658 91.299 139.534 93.423 139.534 96.042C139.534 98.661 141.658 100.785 144.277 100.785C147.421 100.785 152.892 98.07 155.583 95.802C159.559 92.4525 163.038 86.6745 163.861 81.582H154.966V81.5835Z"
        fill="#5AA5EB"
      />
      <path
        d="M144.279 101.237C141.415 101.237 139.086 98.9072 139.086 96.0437C139.086 93.1802 141.415 90.8507 144.279 90.8507C144.541 90.8507 145.165 90.5657 145.504 90.3722C145.537 90.3527 145.573 90.3392 145.611 90.3287C149.938 89.1692 153.523 85.6127 154.53 81.4788C154.579 81.2778 154.759 81.1353 154.968 81.1353H163.864C163.996 81.1353 164.121 81.1938 164.206 81.2943C164.292 81.3948 164.329 81.5283 164.308 81.6573C163.492 86.6957 160.024 92.6552 155.875 96.1487C153.022 98.5502 147.457 101.237 144.279 101.237ZM145.899 91.1822C145.642 91.3247 144.829 91.7492 144.279 91.7492C141.912 91.7492 139.986 93.6752 139.986 96.0422C139.986 98.4092 141.912 100.335 144.279 100.335C147.222 100.335 152.617 97.7132 155.295 95.4587C159.151 92.2112 162.396 86.7617 163.326 82.0338H155.314C154.128 86.3132 150.391 89.9582 145.899 91.1822Z"
        fill="#326E9B"
      />
      <path
        d="M134.17 91.334C134.094 91.328 134.016 91.3235 133.939 91.316C133.836 91.3085 132.156 91.001 132.156 91.001C127.915 89.0225 124.186 86.1455 123.034 81.584H114.015C114.727 85.9955 117.642 90.5015 120.883 94.046C123.757 97.187 130.512 100.785 133.626 100.787C136.245 100.787 138.369 98.663 138.369 96.044C138.369 93.608 136.533 91.604 134.17 91.334Z"
        fill="#5AA5EB"
      />
      <path
        d="M133.628 101.237H133.626C130.397 101.235 123.554 97.6322 120.552 94.3502C118.316 91.9067 114.437 87.0077 113.571 81.6573C113.55 81.5268 113.588 81.3948 113.673 81.2943C113.759 81.1938 113.883 81.1353 114.015 81.1353H123.035C123.24 81.1353 123.42 81.2747 123.471 81.4742C124.428 85.2587 127.314 88.2332 132.296 90.5687C133.049 90.7067 133.883 90.8567 133.983 90.8687C134.027 90.8732 134.069 90.8762 134.111 90.8792L134.208 90.8867C134.213 90.8867 134.217 90.8882 134.223 90.8882C136.844 91.1882 138.819 93.4052 138.819 96.0452C138.819 97.4327 138.279 98.7362 137.298 99.7172C136.317 100.697 135.014 101.237 133.628 101.237ZM114.554 82.0338C115.541 86.9522 119.121 91.4522 121.215 93.7412C124.034 96.8222 130.646 100.335 133.626 100.335H133.628C134.774 100.335 135.851 99.8882 136.662 99.0782C137.474 98.2667 137.919 97.1897 137.919 96.0422C137.919 93.8642 136.29 92.0327 134.129 91.7807L134.049 91.7747C133.998 91.7717 133.946 91.7672 133.896 91.7627C133.824 91.7582 133.208 91.6502 132.075 91.4417C132.038 91.4342 132 91.4237 131.966 91.4072C126.84 89.0162 123.801 85.9472 122.688 82.0338H114.554Z"
        fill="#326E9B"
      />
      <path
        d="M136.205 96.291C136.822 96.507 137.557 96.666 138.275 96.5865Z"
        fill="#5AA5EB"
      />
      <path
        d="M137.888 97.0577C137.321 97.0577 136.707 96.9437 136.056 96.7157C135.822 96.6332 135.698 96.3767 135.78 96.1427C135.863 95.9087 136.119 95.7857 136.353 95.8667C137.046 96.1097 137.675 96.2012 138.225 96.1397C138.471 96.1142 138.695 96.2897 138.722 96.5372C138.749 96.7847 138.572 97.0067 138.324 97.0337C138.182 97.0502 138.036 97.0577 137.888 97.0577Z"
        fill="#326E9B"
      />
      <path
        d="M134.841 99.21C135.516 99.4139 136.257 99.5489 136.857 99.4424Z"
        fill="#5AA5EB"
      />
      <path
        d="M136.401 99.93C135.915 99.93 135.349 99.834 134.712 99.6421C134.473 99.5701 134.34 99.3195 134.41 99.0811C134.482 98.8426 134.733 98.7091 134.971 98.7796C135.711 99.0031 136.336 99.0781 136.777 98.9986C137.022 98.9551 137.256 99.117 137.299 99.3615C137.343 99.606 137.181 99.84 136.936 99.8835C136.77 99.915 136.591 99.93 136.401 99.93Z"
        fill="#326E9B"
      />
      <path
        d="M141.562 96.4321C141.027 96.5866 140.182 96.6526 139.596 96.5866Z"
        fill="#5AA5EB"
      />
      <path
        d="M140.097 97.0622C139.904 97.0622 139.716 97.0532 139.547 97.0337C139.299 97.0067 139.122 96.7832 139.149 96.5372C139.176 96.2897 139.398 96.1127 139.646 96.1397C140.174 96.1982 140.961 96.1367 141.438 95.9987C141.677 95.9297 141.927 96.0677 141.995 96.3062C142.062 96.5447 141.926 96.7937 141.687 96.8627C141.251 96.9902 140.645 97.0622 140.097 97.0622Z"
        fill="#326E9B"
      />
      <path
        d="M143.045 99.2061C142.365 99.413 141.618 99.551 141.014 99.443Z"
        fill="#5AA5EB"
      />
      <path
        d="M141.469 99.9304C141.279 99.9304 141.1 99.9154 140.934 99.8854C140.689 99.8419 140.526 99.6079 140.571 99.3634C140.614 99.1189 140.848 98.9554 141.093 99.0004C141.538 99.0799 142.167 99.0019 142.914 98.7754C143.151 98.7034 143.403 98.8369 143.475 99.0754C143.547 99.3124 143.413 99.5644 143.175 99.6364C142.531 99.8314 141.96 99.9304 141.469 99.9304Z"
        fill="#326E9B"
      />
      <path
        d="M141.085 31.8604C148.881 32.3899 156.492 35.7859 162.183 42.0049C173.929 54.8419 173.044 74.7709 160.207 86.5159C147.37 98.2624 127.441 97.3774 115.696 84.5404C103.951 71.7034 104.835 51.7744 117.672 40.0294C122.308 35.7874 127.872 33.2014 133.639 32.2219C133.638 32.2219 136.558 31.6444 141.085 31.8604Z"
        fill="#5AA5EB"
      />
      <path
        d="M138.973 95.2277C138.49 95.2277 138.007 95.2172 137.523 95.1962C128.995 94.8182 121.126 91.1417 115.365 84.8447C103.47 71.8457 104.37 51.5927 117.369 39.6977C121.908 35.5457 127.507 32.8067 133.564 31.7792C133.674 31.7567 136.578 31.1972 141.108 31.4117C141.111 31.4117 141.114 31.4117 141.117 31.4117C149.356 31.9712 156.957 35.6252 162.516 41.7017C168.278 47.9987 171.243 56.1632 170.864 64.6907C170.484 73.2182 166.809 81.0872 160.512 86.8487C154.572 92.2817 146.971 95.2277 138.973 95.2277ZM139.092 32.2622C135.808 32.2622 133.749 32.6582 133.725 32.6627C127.828 33.6632 122.385 36.3242 117.975 40.3607C105.342 51.9197 104.467 71.6027 116.028 84.2357C121.627 90.3557 129.276 93.9287 137.562 94.2962C145.848 94.6637 153.783 91.7822 159.903 86.1827C166.023 80.5832 169.596 72.9347 169.964 64.6487C170.331 56.3612 167.45 48.4277 161.85 42.3077C156.449 36.4037 149.064 32.8532 141.058 32.3087C140.364 32.2757 139.707 32.2622 139.092 32.2622Z"
        fill="#326E9B"
      />
      <path
        d="M128.324 89.6658C128.285 89.6658 128.245 89.6598 128.204 89.6493C123.947 88.4733 121.459 87.1743 121.354 87.1188C121.133 87.0033 121.049 86.7303 121.166 86.5113C121.282 86.2908 121.553 86.2068 121.774 86.3223C121.798 86.3358 124.289 87.6333 128.444 88.7808C128.684 88.8468 128.824 89.0943 128.758 89.3343C128.702 89.5353 128.522 89.6658 128.324 89.6658Z"
        fill="black"
      />
      <path
        d="M149.671 89.6654C149.473 89.6654 149.29 89.5334 149.236 89.3324C149.172 89.0924 149.314 88.8449 149.554 88.7819C153.079 87.8384 156.204 86.3325 156.234 86.3175C156.457 86.2095 156.727 86.3025 156.835 86.526C156.943 86.7495 156.85 87.0194 156.627 87.1274C156.595 87.1424 153.397 88.6844 149.787 89.6519C149.749 89.6594 149.71 89.6654 149.671 89.6654Z"
        fill="black"
      />
      <path
        d="M226.816 100.238C226.816 100.238 230.148 100.839 231.304 104.91C232.461 108.981 232.183 109.953 236.023 109.953C239.355 109.953 239.077 112.266 239.077 112.497C239.077 112.775 239.031 114.672 235.839 114.672C228.021 114.672 228.436 114.117 227.743 112.868C226.647 110.894 228.298 106.992 224.32 106.02"
        fill="white"
      />
      <path
        d="M235.837 115.122C228.721 115.122 228.168 114.699 227.493 113.364C227.448 113.276 227.401 113.184 227.347 113.087C226.936 112.347 226.872 111.441 226.803 110.484C226.675 108.708 226.555 107.031 224.211 106.458C223.969 106.4 223.821 106.155 223.881 105.914C223.939 105.672 224.184 105.524 224.425 105.584C227.406 106.311 227.569 108.59 227.701 110.42C227.763 111.275 227.821 112.083 228.135 112.65C228.195 112.758 228.247 112.86 228.297 112.959C228.723 113.801 228.936 114.224 235.839 114.224C236.493 114.224 238.627 114.099 238.627 112.499C238.627 112.482 238.629 112.458 238.629 112.428C238.638 112.215 238.665 111.567 238.191 111.072C237.766 110.628 237.036 110.405 236.023 110.405C232.308 110.405 232.039 109.392 231.24 106.388C231.133 105.986 231.012 105.53 230.871 105.035C229.81 101.301 226.86 100.706 226.735 100.682C226.492 100.635 226.33 100.401 226.375 100.158C226.42 99.9151 226.653 99.7531 226.897 99.7966C227.044 99.8236 230.518 100.497 231.738 104.789C231.88 105.291 232.003 105.75 232.11 106.157C232.881 109.059 232.999 109.505 236.023 109.505C237.291 109.505 238.239 109.823 238.84 110.45C239.331 110.961 239.562 111.641 239.527 112.467L239.526 112.5C239.526 112.937 239.347 115.122 235.837 115.122Z"
        fill="#828282"
      />
      <path
        d="M195.787 78.2686C195.541 81.0511 195.406 84.0511 195.406 87.1801C195.406 97.8481 196.965 107.022 199.201 111.125C200.112 113.207 202.192 114.672 204.601 114.672C207.841 114.672 210.493 112.02 210.493 108.78V103.851H213.762V108.78C213.762 112.02 216.414 114.672 219.654 114.672C222.061 114.672 224.142 113.207 225.054 111.125C227.29 107.022 228.849 97.8481 228.849 87.1801C228.849 84.0511 228.714 81.0511 228.468 78.2686"
        fill="white"
      />
      <path
        d="M219.654 115.122C216.157 115.122 213.312 112.277 213.312 108.78V104.3H210.943V108.78C210.943 112.277 208.098 115.122 204.601 115.122C202.092 115.122 199.816 113.631 198.798 111.324C196.464 107.025 194.956 97.5542 194.956 87.1817C194.956 84.1127 195.085 81.1007 195.339 78.2297C195.361 77.9822 195.579 77.7992 195.826 77.8217C196.074 77.8442 196.257 78.0617 196.234 78.3092C195.982 81.1532 195.856 84.1382 195.856 87.1817C195.856 97.4282 197.325 106.743 199.597 110.91C199.603 110.922 199.609 110.933 199.614 110.945C200.485 112.935 202.443 114.222 204.601 114.222C207.601 114.222 210.043 111.782 210.043 108.78V103.85C210.043 103.601 210.244 103.4 210.493 103.4H213.762C214.011 103.4 214.212 103.601 214.212 103.85V108.78C214.212 111.78 216.652 114.222 219.654 114.222C221.812 114.222 223.77 112.935 224.641 110.945C224.646 110.933 224.652 110.921 224.658 110.91C226.93 106.743 228.399 97.4282 228.399 87.1817C228.399 84.1382 228.271 81.1532 228.021 78.3092C227.998 78.0617 228.181 77.8427 228.429 77.8217C228.676 77.7992 228.895 77.9822 228.916 78.2297C229.17 81.0992 229.299 84.1112 229.299 87.1817C229.299 97.5542 227.791 107.027 225.457 111.324C224.439 113.631 222.163 115.122 219.654 115.122Z"
        fill="#828282"
      />
      <path
        d="M220.135 89.3208C224.985 88.0218 229.159 84.0573 230.347 79.1763H238.522C237.612 84.8028 234.876 90.1758 230.344 94.3218C227.475 96.9468 224.176 98.8038 220.702 99.9243C220.041 100.226 219.309 100.397 218.536 100.397C215.641 100.397 213.294 98.0493 213.294 95.1543C213.292 89.9718 220.135 89.3208 220.135 89.3208Z"
        fill="white"
      />
      <path
        d="M218.534 100.848C215.396 100.848 212.842 98.2951 212.842 95.1556C212.842 92.8501 214.114 91.0276 216.52 89.8876C218.158 89.1121 219.773 88.9066 220.051 88.8766C224.836 87.5806 228.794 83.6446 229.907 79.0696C229.957 78.8686 230.137 78.7261 230.345 78.7261H238.52C238.652 78.7261 238.777 78.7846 238.862 78.8851C238.948 78.9856 238.985 79.1191 238.964 79.2481C237.995 85.2346 235.118 90.5611 230.645 94.6546C227.81 97.2481 224.519 99.1636 220.862 100.346C220.121 100.679 219.338 100.848 218.534 100.848ZM220.178 89.7691C220.162 89.7706 218.507 89.9386 216.886 90.7111C214.799 91.7071 213.742 93.2026 213.742 95.1571C213.742 97.8001 215.891 99.9496 218.534 99.9496C219.218 99.9496 219.884 99.8041 220.514 99.5176C220.529 99.5101 220.546 99.5041 220.562 99.4996C224.105 98.3566 227.294 96.5041 230.039 93.9931C234.232 90.1576 236.972 85.2001 237.988 79.6291H230.695C229.393 84.3766 225.239 88.4206 220.252 89.7571C220.228 89.7616 220.202 89.7661 220.178 89.7691Z"
        fill="#828282"
      />
      <path
        d="M207.362 89.9508C209.974 90.2508 212.002 92.4648 212.002 95.1558C212.002 98.0508 209.654 100.398 206.759 100.398C205.961 100.398 205.208 100.215 204.532 99.8958C200.354 98.5443 196.454 96.1473 193.279 92.6763C189.695 88.7598 187.531 84.0513 186.743 79.1763H195.053C196.328 84.2163 200.092 88.3038 205.135 89.5833C205.136 89.5833 207.278 89.9448 207.362 89.9508Z"
        fill="white"
      />
      <path
        d="M206.761 100.848C205.933 100.848 205.129 100.67 204.368 100.316C199.987 98.8936 196.039 96.3571 192.949 92.9806C189.43 89.1346 187.13 84.3856 186.301 79.2481C186.28 79.1176 186.317 78.9856 186.403 78.8851C186.488 78.7846 186.613 78.7261 186.745 78.7261H195.055C195.26 78.7261 195.44 78.8656 195.491 79.0651C196.757 84.0721 200.488 87.9316 205.229 89.1421C206.023 89.2756 207.302 89.4901 207.412 89.5036C207.413 89.5036 207.413 89.5036 207.415 89.5036C210.287 89.8336 212.453 92.2621 212.453 95.1556C212.452 98.2951 209.899 100.848 206.761 100.848ZM187.279 79.6261C188.149 84.3901 190.331 88.7866 193.613 92.3731C196.604 95.6416 200.428 98.0941 204.673 99.4681C204.691 99.4741 204.709 99.4816 204.725 99.4891C205.372 99.7936 206.057 99.9481 206.761 99.9481C209.404 99.9481 211.553 97.7986 211.553 95.1556C211.553 92.7211 209.731 90.6751 207.313 90.3976C207.185 90.3811 206.429 90.2566 205.063 90.0271C205.051 90.0256 205.039 90.0226 205.027 90.0196C200.065 88.7611 196.139 84.7951 194.71 79.6261H187.279V79.6261Z"
        fill="#828282"
      />
      <path
        d="M212.517 94.4837C229.474 94.4837 243.22 80.7372 243.22 63.7802C243.22 46.8231 229.474 33.0767 212.517 33.0767C195.56 33.0767 181.813 46.8231 181.813 63.7802C181.813 80.7372 195.56 94.4837 212.517 94.4837Z"
        fill="white"
      />
      <path
        d="M212.518 94.9184C204.47 94.9184 196.43 91.8299 190.343 85.6649C184.498 79.7429 181.309 71.898 181.363 63.5775C181.417 55.257 184.708 47.454 190.631 41.6085C196.504 35.8125 204.268 32.6265 212.51 32.6265C212.581 32.6265 212.65 32.6265 212.719 32.6265C221.039 32.6805 228.842 35.9715 234.688 41.895C246.755 54.12 246.626 73.884 234.4 85.9515C228.341 91.9334 220.427 94.9184 212.518 94.9184ZM212.51 33.5265C204.505 33.5265 196.967 36.6195 191.263 42.249C185.512 47.9265 182.315 55.503 182.263 63.5835C182.21 71.664 185.308 79.2825 190.984 85.0335C202.702 96.9059 221.894 97.0304 233.767 85.3124C245.639 73.5945 245.764 54.4005 234.046 42.528C228.37 36.777 220.793 33.5805 212.711 33.528C212.647 33.528 212.578 33.5265 212.51 33.5265Z"
        fill="#828282"
      />
      <path
        d="M216.554 90.1693L208.633 90.1183C207.989 90.1138 207.467 89.5843 207.472 88.9408C207.476 88.2973 208.006 87.7753 208.649 87.7798L216.571 87.8308C217.214 87.8353 217.736 88.3648 217.732 89.0083C217.727 89.6503 217.198 90.1738 216.554 90.1693Z"
        fill="#FF9B96"
      />
      <path
        d="M216.562 90.6191C216.559 90.6191 216.55 90.6206 216.551 90.6191L208.63 90.5681C207.737 90.5621 207.016 89.8316 207.022 88.9376C207.025 88.5056 207.196 88.1006 207.505 87.7961C207.814 87.4916 208.22 87.3311 208.652 87.3281L216.574 87.3791C217.466 87.3851 218.188 88.1156 218.182 89.0096C218.176 89.9006 217.45 90.6191 216.562 90.6191ZM216.557 89.7191C216.559 89.7191 216.56 89.7191 216.562 89.7191C216.956 89.7191 217.279 89.3996 217.282 89.0051C217.285 88.6091 216.964 88.2836 216.568 88.2806L208.646 88.2296C208.645 88.2296 208.643 88.2296 208.642 88.2296C208.453 88.2296 208.274 88.3031 208.138 88.4381C208 88.5731 207.925 88.7531 207.923 88.9451C207.92 89.3411 208.241 89.6666 208.637 89.6696L216.557 89.7191Z"
        fill="#C86E6E"
      />
      <path
        d="M220.171 89.3847C219.957 89.3847 219.768 89.2317 219.729 89.0142C219.685 88.7697 219.847 88.5357 220.093 88.4922C220.14 88.4832 224.794 87.6507 227.488 86.9232C227.728 86.8587 227.976 87.0012 228.04 87.2412C228.105 87.4812 227.962 87.7287 227.722 87.7932C224.991 88.5297 220.299 89.3697 220.251 89.3787C220.225 89.3832 220.198 89.3847 220.171 89.3847Z"
        fill="black"
      />
      <path
        d="M205.031 89.2453C205.009 89.2453 204.986 89.2438 204.964 89.2408C204.772 89.2123 200.219 88.5298 197.48 87.7918C197.24 87.7273 197.097 87.4798 197.162 87.2398C197.226 86.9998 197.474 86.8573 197.714 86.9218C200.402 87.6463 205.049 88.3423 205.096 88.3498C205.342 88.3858 205.511 88.6153 205.475 88.8613C205.442 89.0863 205.25 89.2453 205.031 89.2453Z"
        fill="black"
      />
      <path
        d="M243.06 63.0104C239.15 59.3774 234.675 56.7239 229.637 55.0574C227.858 54.1064 226.037 53.3144 224.174 52.6784C224.288 52.1819 224.45 51.6944 224.673 51.2219C226.745 46.8329 233.207 45.1139 238.7 47.7944C244.193 50.4734 246.635 56.5364 244.563 60.9254C244.185 61.7219 243.675 62.4179 243.06 63.0104Z"
        fill="white"
      />
      <path
        d="M243.061 63.4605C242.951 63.4605 242.84 63.42 242.755 63.3405C238.904 59.7645 234.443 57.1215 229.496 55.485C229.472 55.4775 229.448 55.467 229.426 55.455C227.68 54.522 225.865 53.7315 224.029 53.1045C223.811 53.0295 223.684 52.803 223.735 52.578C223.859 52.035 224.038 51.5145 224.266 51.03C225.193 49.0665 226.987 47.5335 229.321 46.713C232.345 45.6495 235.835 45.897 238.897 47.3895C244.427 50.088 247.208 56.3745 244.97 61.1175C244.58 61.944 244.043 62.6895 243.374 63.3345C243.286 63.4185 243.173 63.4605 243.061 63.4605ZM229.814 54.642C234.734 56.2755 239.182 58.878 243.043 62.3835C243.497 61.8885 243.871 61.335 244.154 60.7335C246.187 56.4255 243.598 50.685 238.501 48.1995C235.654 46.8105 232.417 46.5795 229.618 47.562C227.513 48.3015 225.902 49.6695 225.079 51.414C224.933 51.723 224.81 52.05 224.713 52.389C226.447 53.0055 228.161 53.7615 229.814 54.642Z"
        fill="#828282"
      />
      <path
        d="M238.792 59.5874C235.981 57.6584 232.93 56.1464 229.637 55.0574C229.531 55.0004 229.423 54.9449 229.316 54.8894C229.352 54.4619 229.459 54.0449 229.645 53.6489C230.68 51.4544 233.746 50.7614 236.492 52.1009C239.239 53.4404 240.625 56.3054 239.59 58.5014C239.392 58.9184 239.12 59.2829 238.792 59.5874Z"
        fill="#FF9B96"
      />
      <path
        d="M238.79 60.0375C238.702 60.0375 238.613 60.012 238.535 59.958C235.765 58.0575 232.723 56.5515 229.495 55.4835C229.471 55.476 229.447 55.4655 229.424 55.4535C229.342 55.41 229.259 55.3665 229.177 55.323L229.108 55.287C228.947 55.203 228.853 55.032 228.868 54.8505C228.908 54.3585 229.033 53.889 229.238 53.4555C229.712 52.452 230.599 51.6795 231.737 51.279C233.251 50.7465 235.057 50.898 236.69 51.696C239.653 53.1405 241.136 56.28 239.998 58.692C239.78 59.1525 239.477 59.565 239.099 59.9175C239.012 59.997 238.901 60.0375 238.79 60.0375ZM229.814 54.642C232.988 55.695 235.985 57.162 238.73 59.0025C238.912 58.791 239.063 58.5585 239.182 58.308C240.112 56.3385 238.816 53.7345 236.294 52.5045C234.875 51.813 233.323 51.675 232.034 52.128C231.112 52.452 230.426 53.0445 230.05 53.8395C229.931 54.09 229.847 54.357 229.799 54.6345C229.805 54.6375 229.81 54.6405 229.814 54.642Z"
        fill="#C86E6E"
      />
      <path
        d="M181.684 63.381C185.595 59.748 190.069 57.0945 195.108 55.428C196.887 54.477 198.708 53.685 200.571 53.049C200.457 52.5525 200.295 52.065 200.071 51.5925C198 47.2035 191.538 45.4845 186.045 48.165C180.552 50.844 178.11 56.907 180.181 61.296C180.559 62.0925 181.071 62.7885 181.684 63.381Z"
        fill="white"
      />
      <path
        d="M181.684 63.8311C181.571 63.8311 181.459 63.7891 181.372 63.7051C180.703 63.0601 180.166 62.3146 179.776 61.4881C177.538 56.7451 180.319 50.4586 185.849 47.7601C188.911 46.2661 192.401 46.0201 195.425 47.0836C197.758 47.9041 199.553 49.4371 200.48 51.4006C200.708 51.8851 200.887 52.4056 201.011 52.9486C201.062 53.1736 200.936 53.4001 200.717 53.4751C198.883 54.1006 197.066 54.8926 195.32 55.8256C195.298 55.8376 195.274 55.8481 195.25 55.8556C190.301 57.4921 185.84 60.1351 181.991 63.7111C181.904 63.7906 181.795 63.8311 181.684 63.8311ZM191.533 47.3311C189.748 47.3311 187.928 47.7481 186.244 48.5686C181.147 51.0556 178.558 56.7961 180.59 61.1026C180.874 61.7041 181.247 62.2576 181.702 62.7526C185.563 59.2471 190.01 56.6446 194.93 55.0111C196.583 54.1291 198.298 53.3731 200.033 52.7581C199.936 52.4191 199.813 52.0921 199.667 51.7831C198.844 50.0386 197.233 48.6706 195.128 47.9311C193.984 47.5306 192.766 47.3311 191.533 47.3311Z"
        fill="#828282"
      />
      <path
        d="M185.954 59.958C188.765 58.029 191.816 56.517 195.108 55.428C195.215 55.371 195.323 55.3155 195.429 55.26C195.393 54.8325 195.287 54.4155 195.101 54.0195C194.066 51.825 191 51.132 188.253 52.4715C185.507 53.811 184.121 56.676 185.156 58.872C185.352 59.289 185.624 59.652 185.954 59.958Z"
        fill="#FF9B96"
      />
      <path
        d="M185.954 60.408C185.843 60.408 185.734 60.3675 185.648 60.288C185.269 59.9355 184.967 59.523 184.75 59.0625C183.611 56.6505 185.095 53.511 188.057 52.0665C189.691 51.27 191.495 51.1185 193.009 51.6495C194.147 52.05 195.035 52.8225 195.508 53.826C195.713 54.261 195.838 54.7305 195.878 55.221C195.893 55.401 195.799 55.5735 195.638 55.6575L195.551 55.7025C195.475 55.743 195.398 55.782 195.32 55.824C195.298 55.836 195.274 55.8465 195.25 55.854C192.022 56.922 188.98 58.4265 186.208 60.3285C186.131 60.3825 186.043 60.408 185.954 60.408ZM191.113 52.236C190.235 52.236 189.319 52.452 188.45 52.875C185.929 54.105 184.633 56.709 185.563 58.6785C185.681 58.929 185.833 59.163 186.014 59.373C188.759 57.531 191.758 56.0655 194.93 55.0125C194.935 55.0095 194.939 55.008 194.944 55.005C194.896 54.7275 194.812 54.4605 194.693 54.21C194.318 53.415 193.631 52.8225 192.709 52.4985C192.209 52.323 191.669 52.236 191.113 52.236Z"
        fill="#C86E6E"
      />
      <path
        d="M302.128 94.3455C302.128 94.3455 304.219 95.0325 306.482 93.6165C308.453 92.3835 309.466 93.3375 309.691 94.086C309.895 94.7655 309.962 96.684 306.775 98.763C303.589 100.842 300.818 101.527 300.818 101.527"
        fill="#FF8700"
      />
      <path
        d="M300.818 101.976C300.616 101.976 300.433 101.84 300.382 101.634C300.322 101.393 300.469 101.15 300.71 101.09C300.737 101.084 303.451 100.394 306.529 98.3852C309.698 96.3167 309.359 94.5482 309.259 94.2152C309.188 93.9797 308.986 93.6812 308.632 93.5477C308.146 93.3647 307.484 93.5207 306.721 93.9992C304.306 95.5112 302.081 94.8047 301.987 94.7747C301.751 94.6967 301.622 94.4432 301.7 94.2062C301.778 93.9707 302.03 93.8417 302.267 93.9182C302.348 93.9437 304.201 94.5137 306.244 93.2357C307.52 92.4362 308.42 92.5052 308.95 92.7047C309.512 92.9162 309.95 93.3842 310.123 93.9557C310.309 94.5752 310.636 96.7802 307.022 99.1382C303.809 101.235 301.045 101.934 300.928 101.963C300.89 101.972 300.854 101.976 300.818 101.976Z"
        fill="#96501E"
      />
      <path
        d="M299.614 110.127H297.454C302.255 106.146 305.336 99.7277 305.336 91.5872C305.336 77.4542 296.053 63.4922 284.599 63.4922C273.146 63.4922 263.861 77.4542 263.861 91.5872C263.861 99.7262 266.941 106.145 271.739 110.127H269.563C268.313 110.127 267.29 111.15 267.29 112.4C267.29 113.649 268.313 114.672 269.563 114.672H279.703C280.183 114.672 280.628 114.519 280.996 114.263C281.656 113.823 282.095 113.073 282.095 112.226V106.839C282.095 105.462 283.222 104.787 284.597 104.787C285.974 104.787 287.099 105.464 287.099 106.839V112.226C287.099 112.907 287.383 113.525 287.836 113.969C288.25 114.401 288.829 114.674 289.471 114.674H299.611C300.86 114.674 301.883 113.651 301.883 112.401C301.886 111.15 300.863 110.127 299.614 110.127Z"
        fill="#FF8700"
      />
      <path
        d="M299.614 115.122H289.474C288.732 115.122 288.036 114.822 287.514 114.279C286.962 113.737 286.651 113.004 286.651 112.224V106.837C286.651 105.351 285.081 105.235 284.599 105.235C284.118 105.235 282.547 105.351 282.547 106.837V112.224C282.547 113.193 282.061 114.094 281.247 114.636C280.795 114.951 280.258 115.122 279.705 115.122H269.565C268.063 115.122 266.842 113.901 266.842 112.4C266.842 110.898 268.063 109.677 269.565 109.677H270.55C265.938 105.366 263.413 98.994 263.413 91.587C263.413 84.6075 265.657 77.46 269.571 71.979C273.684 66.216 279.022 63.042 284.601 63.042C290.179 63.042 295.516 66.216 299.631 71.979C303.544 77.4615 305.788 84.6075 305.788 91.587C305.788 98.994 303.262 105.366 298.648 109.677H299.616C301.117 109.677 302.338 110.898 302.338 112.4C302.338 113.901 301.116 115.122 299.614 115.122ZM284.599 104.335C286.393 104.335 287.551 105.318 287.551 106.837V112.224C287.551 112.759 287.766 113.265 288.153 113.646C288.513 114.021 288.978 114.222 289.473 114.222H299.613C300.618 114.222 301.435 113.405 301.435 112.4C301.435 111.395 300.618 110.577 299.613 110.577H297.453C297.264 110.577 297.094 110.459 297.03 110.28C296.965 110.102 297.019 109.902 297.166 109.78C302.145 105.652 304.885 99.192 304.885 91.587C304.885 84.7905 302.701 77.8335 298.896 72.501C294.955 66.981 289.878 63.942 284.599 63.942C279.321 63.942 274.242 66.981 270.303 72.501C266.496 77.8335 264.313 84.7905 264.313 91.587C264.313 99.1905 267.054 105.652 272.029 109.78C272.175 109.902 272.23 110.102 272.166 110.28C272.101 110.459 271.932 110.577 271.743 110.577H269.566C268.561 110.577 267.744 111.395 267.744 112.4C267.744 113.405 268.561 114.222 269.566 114.222H279.706C280.075 114.222 280.434 114.108 280.741 113.893C281.313 113.514 281.647 112.891 281.647 112.225V106.839C281.647 105.318 282.805 104.335 284.599 104.335Z"
        fill="#96501E"
      />
      <path
        d="M272.034 110.127C270.785 110.127 269.762 111.15 269.762 112.399C269.762 113.649 270.785 114.672 272.034 114.672H279.695"
        fill="#FF8700"
      />
      <path
        d="M279.695 115.122H272.034C270.533 115.122 269.312 113.901 269.312 112.399C269.312 110.898 270.533 109.677 272.034 109.677C272.283 109.677 272.484 109.878 272.484 110.127C272.484 110.376 272.283 110.577 272.034 110.577C271.029 110.577 270.212 111.394 270.212 112.399C270.212 113.404 271.029 114.222 272.034 114.222H279.695C279.944 114.222 280.145 114.423 280.145 114.672C280.145 114.921 279.942 115.122 279.695 115.122Z"
        fill="#96501E"
      />
      <path
        d="M297.146 110.127C298.395 110.127 299.418 111.15 299.418 112.399C299.418 113.649 298.395 114.672 297.146 114.672H289.485"
        fill="#FF8700"
      />
      <path
        d="M297.146 115.122H289.485C289.236 115.122 289.035 114.921 289.035 114.672C289.035 114.423 289.236 114.222 289.485 114.222H297.146C298.151 114.222 298.968 113.404 298.968 112.399C298.968 111.394 298.151 110.577 297.146 110.577C296.897 110.577 296.696 110.376 296.696 110.127C296.696 109.878 296.897 109.677 297.146 109.677C298.647 109.677 299.868 110.898 299.868 112.399C299.868 113.901 298.646 115.122 297.146 115.122Z"
        fill="#96501E"
      />
      <path
        d="M294.028 96.6511C292.389 101.685 287.046 102.432 281.947 101.298C276.78 100.148 274.162 95.7496 275.8 90.7171C278.046 83.8216 290.41 79.9966 290.41 79.9966C290.41 79.9966 296.428 89.2801 294.028 96.6511Z"
        fill="white"
      />
      <path
        d="M274.992 84.9438C275.781 85.4238 276.549 85.9488 277.29 86.5204C280.197 88.7614 282.395 91.4809 283.887 94.4524C284.258 95.0059 284.508 95.6404 284.595 96.3334C284.919 98.9254 282.87 101.319 280.02 101.681C274.916 102.329 273.507 96.2854 273.507 96.2854C272.169 93.2194 269.46 90.6574 266.16 89.3314"
        fill="#FF8700"
      />
      <path
        d="M279.267 102.179C274.701 102.179 273.222 96.9707 273.081 96.4307C271.777 93.4697 269.196 91.0352 265.993 89.7482C265.762 89.6552 265.651 89.3927 265.743 89.1632C265.836 88.9322 266.098 88.8212 266.328 88.9127C269.761 90.2927 272.529 92.9132 273.919 96.1037C273.93 96.1292 273.939 96.1547 273.945 96.1817C273.948 96.1952 274.296 97.6457 275.233 98.9897C276.442 100.724 278.034 101.48 279.963 101.234C282.564 100.904 284.442 98.7302 284.148 96.3887C284.073 95.7887 283.858 95.2217 283.512 94.7027C283.501 94.6877 283.492 94.6712 283.483 94.6547C281.965 91.6337 279.789 89.0162 277.014 86.8772C276.295 86.3237 275.536 85.8017 274.756 85.3277C274.543 85.1987 274.476 84.9212 274.605 84.7097C274.734 84.4967 275.011 84.4292 275.223 84.5597C276.031 85.0502 276.817 85.5902 277.563 86.1647C280.438 88.3817 282.696 91.0922 284.275 94.2257C284.691 94.8557 284.949 95.5457 285.04 96.2762C285.394 99.1097 283.167 101.733 280.075 102.126C279.798 102.162 279.528 102.179 279.267 102.179Z"
        fill="#96501E"
      />
      <path
        d="M301.948 90.2266C298.898 91.4221 296.401 93.5761 295.061 96.4531C295.061 96.4531 294.394 98.2996 294.374 98.3746C293.698 100.649 291.232 102.092 288.596 101.651C285.761 101.175 283.808 98.7016 284.234 96.1246C284.351 95.4151 284.642 94.7746 285.053 94.2241C286.99 90.7276 289.91 87.6496 293.774 85.3936"
        fill="#FF8700"
      />
      <path
        d="M289.544 102.178C289.208 102.178 288.866 102.15 288.523 102.093C285.448 101.578 283.325 98.8679 283.79 96.0509C283.913 95.3039 284.21 94.6064 284.674 93.9794C286.715 90.3059 289.783 87.2023 293.548 85.0048C293.762 84.8803 294.038 84.9524 294.163 85.1669C294.289 85.3814 294.215 85.6573 294.001 85.7818C290.368 87.9043 287.408 90.8984 285.446 94.4429C285.436 94.4609 285.425 94.4774 285.413 94.4939C285.026 95.0099 284.78 95.5844 284.678 96.1979C284.294 98.5259 286.085 100.773 288.671 101.206C291.056 101.607 293.323 100.333 293.944 98.2469C293.962 98.1899 294.052 97.9259 294.64 96.2999C294.644 96.2879 294.649 96.2744 294.655 96.2624C295.999 93.3764 298.531 91.0843 301.784 89.8078C302.015 89.7163 302.276 89.8303 302.368 90.0628C302.458 90.2938 302.345 90.5549 302.113 90.6464C299.083 91.8359 296.726 93.9584 295.478 96.6254C295.232 97.3064 294.838 98.4014 294.806 98.5019C294.146 100.717 291.98 102.178 289.544 102.178Z"
        fill="#96501E"
      />
      <path
        d="M268.545 93.6138L268.321 96.7953L275.019 102.098L277.753 103.269L285.009 102.377L295.614 103.046L299.577 98.3028L301.195 94.0053L297.567 95.5668L293.103 97.0743L284.34 97.8558L275.632 96.9633L270.387 95.4558L268.545 93.6138Z"
        fill="#FF8700"
      />
      <path
        d="M285.232 97.8828C282.535 97.8753 277.126 97.2678 274.627 96.6003C275.579 99.1353 277.988 101.981 281.477 102.684C287.11 103.82 293.164 102.411 294.974 97.3773C295.045 97.1823 295.192 96.6033 295.249 96.4053C291.958 97.6608 286.697 97.9323 285.232 97.8828Z"
        fill="white"
      />
      <path
        d="M277.713 91.1885C265.497 88.661 256.45 79.3685 256.45 65.885C256.45 49.9685 269.053 35.981 284.599 35.981C300.145 35.981 312.748 49.9685 312.748 65.885C312.748 78.77 304.488 87.8285 293.094 90.812C293.094 90.8135 287.115 92.9075 277.713 91.1885Z"
        fill="#FF8700"
      />
      <path
        d="M285.022 92.3207C282.9 92.3207 280.419 92.1392 277.632 91.6307C277.629 91.6307 277.624 91.6292 277.621 91.6292C271.35 90.3317 266.004 87.3287 262.162 82.9427C258.132 78.3422 256 72.4427 256 65.8862C256 58.1222 259.05 50.3852 264.366 44.6597C269.83 38.7737 277.017 35.5322 284.601 35.5322C292.185 35.5322 299.37 38.7737 304.836 44.6597C310.152 50.3852 313.201 58.1222 313.201 65.8862C313.201 72.1502 311.245 77.8427 307.545 82.3487C304.024 86.6342 299.074 89.7107 293.227 91.2452C292.92 91.3487 289.942 92.3207 285.022 92.3207ZM277.798 90.7472C282.582 91.6202 286.444 91.4762 288.843 91.2032C291.444 90.9047 292.932 90.3947 292.947 90.3887C292.957 90.3842 292.969 90.3812 292.981 90.3782C304.897 87.2567 312.3 77.8727 312.3 65.8862C312.3 49.9202 299.616 36.4322 284.601 36.4322C269.586 36.4322 256.902 49.9202 256.902 65.8862C256.902 72.2222 258.955 77.9162 262.84 82.3502C266.55 86.5847 271.722 89.4887 277.798 90.7472Z"
        fill="#96501E"
      />
      <path
        d="M295.911 39.2208C296.058 38.4813 296.14 37.7178 296.14 36.9333C296.14 30.4038 290.847 25.1118 284.319 25.1118C277.791 25.1118 272.497 30.4053 272.497 36.9333C272.497 37.7403 272.583 38.5263 272.737 39.2853C272.917 40.5108 273.699 41.1798 272.247 42.7983C271.08 44.1003 269.95 46.4808 275.224 44.9343C275.224 44.9343 282.975 43.6728 291.999 44.5143C292.981 44.7483 293.524 44.9343 293.524 44.9343C298.798 46.4808 298.377 44.0343 297.141 42.7983C295.521 41.1798 295.744 40.1748 295.911 39.2208Z"
        fill="#8C46AF"
      />
      <path
        d="M272.519 45.8896C271.748 45.8896 271.249 45.6841 270.988 45.2716C270.406 44.3476 271.439 43.0246 271.912 42.4996C272.857 41.4451 272.713 40.9501 272.494 40.1986C272.422 39.9541 272.341 39.6766 272.294 39.3646C272.131 38.5606 272.047 37.7416 272.047 36.9346C272.047 30.1681 277.552 24.6631 284.318 24.6631C291.085 24.6631 296.59 30.1681 296.59 36.9346C296.59 37.7161 296.51 38.5141 296.353 39.3046L296.342 39.3691C296.189 40.2421 296.044 41.0686 297.458 42.4816C298.171 43.1941 298.676 44.2771 298.195 45.0886C297.629 46.0411 296.015 46.1356 293.398 45.3676C293.392 45.3661 293.384 45.3631 293.378 45.3616C293.372 45.3601 292.852 45.1831 291.925 44.9611C283.342 44.1661 275.828 45.2971 275.323 45.3766C274.15 45.7171 273.221 45.8896 272.519 45.8896ZM284.318 25.5601C278.048 25.5601 272.947 30.6616 272.947 36.9316C272.947 37.6831 273.025 38.4436 273.178 39.1936C273.179 39.2026 273.181 39.2101 273.182 39.2191C273.22 39.4741 273.289 39.7126 273.358 39.9436C273.605 40.7926 273.862 41.6701 272.582 43.0981C271.894 43.8661 271.615 44.5741 271.75 44.7901C271.787 44.8501 272.203 45.3511 275.098 44.5021C275.116 44.4961 275.134 44.4931 275.152 44.4901C275.23 44.4781 283.064 43.2286 292.04 44.0656C292.061 44.0671 292.082 44.0716 292.103 44.0761C293.035 44.2981 293.578 44.4766 293.66 44.5051C296.77 45.4141 297.338 44.7676 297.421 44.6281C297.608 44.3116 297.34 43.6336 296.822 43.1161C295.078 41.3716 295.288 40.1731 295.456 39.2116L295.468 39.1426C295.468 39.1396 295.469 39.1351 295.469 39.1321C295.616 38.3971 295.69 37.6576 295.69 36.9316C295.69 30.6616 290.588 25.5601 284.318 25.5601Z"
        fill="#502D7D"
      />
      <path
        d="M275.483 43.5853C275.483 43.5853 277.891 45.3403 279.839 45.2923C281.788 45.2443 282.62 43.8658 281.314 42.8098C280.007 41.7538 277.906 40.5898 277.906 40.5898"
        fill="#8C46AF"
      />
      <path
        d="M279.762 45.7426C277.704 45.7426 275.32 44.0221 275.218 43.9486C275.017 43.8016 274.974 43.5211 275.119 43.3201C275.266 43.1191 275.547 43.0756 275.748 43.2211C275.77 43.2376 278.061 44.8876 279.828 44.8426C280.788 44.8186 281.343 44.4421 281.466 44.0806C281.598 43.6936 281.25 43.3381 281.029 43.1611C279.769 42.1426 277.707 40.9966 277.686 40.9861C277.468 40.8661 277.39 40.5916 277.51 40.3741C277.63 40.1566 277.905 40.0771 278.122 40.1986C278.209 40.2466 280.273 41.3926 281.595 42.4621C282.279 43.0141 282.541 43.7101 282.318 44.3716C282.04 45.1876 281.095 45.7141 279.85 45.7441C279.82 45.7426 279.792 45.7426 279.762 45.7426Z"
        fill="#502D7D"
      />
      <path
        d="M292.933 43.5858C292.933 43.5858 290.525 45.3408 288.577 45.2928C286.628 45.2448 285.796 43.8663 287.102 42.8103C288.409 41.7543 290.51 40.5903 290.51 40.5903"
        fill="#8C46AF"
      />
      <path
        d="M288.655 45.7426C288.625 45.7426 288.595 45.7426 288.565 45.7411C287.32 45.7111 286.375 45.1846 286.097 44.3686C285.872 43.7071 286.136 43.0126 286.82 42.4591C288.142 41.3911 290.206 40.2436 290.293 40.1956C290.51 40.0741 290.783 40.1536 290.905 40.3711C291.025 40.5886 290.947 40.8616 290.729 40.9831C290.708 40.9951 288.646 42.1411 287.386 43.1581C287.167 43.3351 286.819 43.6906 286.949 44.0776C287.072 44.4406 287.627 44.8171 288.587 44.8396C290.362 44.8831 292.645 43.2346 292.667 43.2181C292.868 43.0726 293.149 43.1161 293.296 43.3171C293.443 43.5181 293.398 43.7986 293.197 43.9456C293.096 44.0221 290.713 45.7426 288.655 45.7426Z"
        fill="#502D7D"
      />
      <path
        d="M284.428 40.1596C285.388 40.1596 286.166 39.9178 286.166 39.6196C286.166 39.3214 285.388 39.0796 284.428 39.0796C283.468 39.0796 282.689 39.3214 282.689 39.6196C282.689 39.9178 283.468 40.1596 284.428 40.1596Z"
        fill="black"
      />
      <path
        d="M288.331 39.8071C288.11 39.8071 287.917 39.6451 287.885 39.4186C287.851 39.1726 288.023 38.9446 288.269 38.9101C289.625 38.7211 290.836 38.4466 290.848 38.4436C291.091 38.3881 291.331 38.5396 291.386 38.7826C291.442 39.0256 291.29 39.2656 291.047 39.3211C290.996 39.3331 289.79 39.6061 288.394 39.8011C288.373 39.8056 288.352 39.8071 288.331 39.8071Z"
        fill="black"
      />
      <path
        d="M280.525 39.8075C280.504 39.8075 280.483 39.806 280.462 39.803C279.065 39.608 277.859 39.335 277.808 39.323C277.565 39.2675 277.414 39.026 277.469 38.7845C277.525 38.5415 277.765 38.39 278.008 38.4455C278.02 38.4485 279.229 38.7215 280.586 38.912C280.832 38.9465 281.005 39.1745 280.97 39.4205C280.939 39.6455 280.745 39.8075 280.525 39.8075Z"
        fill="black"
      />
      <path
        d="M275.398 55.7988C276.631 55.7988 277.833 55.9593 278.986 56.2623V54.2598C278.986 49.8843 275.875 47.0703 272.073 47.0703C268.27 47.0703 265.159 49.8843 265.159 54.2598V60.0423C267.937 57.3918 271.506 55.7988 275.398 55.7988Z"
        fill="#FF8700"
      />
      <path
        d="M265.156 60.4926C265.096 60.4926 265.036 60.4806 264.979 60.4566C264.814 60.3861 264.706 60.2226 264.706 60.0426V54.2601C264.706 49.8336 267.804 46.6206 272.07 46.6206C276.337 46.6206 279.433 49.8336 279.433 54.2601V56.2626C279.433 56.4021 279.369 56.5341 279.258 56.6196C279.147 56.7051 279.004 56.7336 278.869 56.6991C277.735 56.4021 276.567 56.2506 275.397 56.2506C271.779 56.2506 268.252 57.7131 265.467 60.3696C265.383 60.4506 265.27 60.4926 265.156 60.4926ZM272.071 47.5206C268.326 47.5206 265.608 50.3556 265.608 54.2601V59.0346C268.432 56.6511 271.872 55.3491 275.398 55.3491C276.453 55.3491 277.506 55.4646 278.536 55.6911V54.2601C278.535 50.3556 275.817 47.5206 272.071 47.5206Z"
        fill="#96501E"
      />
      <path
        d="M275.302 55.8003V53.9208C275.302 51.8764 273.847 50.7769 272.069 50.7769C270.292 50.7769 268.837 51.8764 268.837 53.9208V57.4053C270.814 56.3868 273.001 55.8153 275.302 55.8003Z"
        fill="#644100"
      />
      <path
        d="M268.839 57.8552C268.758 57.8552 268.677 57.8327 268.605 57.7892C268.471 57.7082 268.389 57.5612 268.389 57.4052V53.9207C268.389 51.7712 269.868 50.3267 272.071 50.3267C274.275 50.3267 275.754 51.7712 275.754 53.9207V55.8002C275.754 56.0477 275.554 56.2487 275.307 56.2502C273.127 56.2652 271.02 56.7872 269.046 57.8057C268.981 57.8387 268.909 57.8552 268.839 57.8552ZM272.071 51.2267C270.787 51.2267 269.289 51.9317 269.289 53.9207V56.6867C271.062 55.8767 272.928 55.4312 274.852 55.3607V53.9207C274.852 51.9317 273.354 51.2267 272.071 51.2267Z"
        fill="#4B321E"
      />
      <path
        d="M293.802 55.7988C297.695 55.7988 301.263 57.3918 304.043 60.0423V54.2598C304.043 49.8843 300.932 47.0703 297.129 47.0703C293.327 47.0703 290.216 49.8843 290.216 54.2598V56.2623C291.368 55.9593 292.568 55.7988 293.802 55.7988Z"
        fill="#FF8700"
      />
      <path
        d="M304.043 60.4922C303.929 60.4922 303.818 60.4487 303.732 60.3677C300.947 57.7112 297.42 56.2487 293.802 56.2487C292.631 56.2487 291.462 56.4002 290.33 56.6972C290.195 56.7332 290.051 56.7032 289.941 56.6177C289.83 56.5322 289.766 56.4002 289.766 56.2607V54.2582C289.766 49.8317 292.863 46.6187 297.129 46.6187C301.397 46.6187 304.493 49.8317 304.493 54.2582V60.0407C304.493 60.2207 304.385 60.3842 304.22 60.4547C304.163 60.4802 304.103 60.4922 304.043 60.4922ZM293.802 55.3487C297.329 55.3487 300.77 56.6507 303.593 59.0342V54.2597C303.593 50.3552 300.875 47.5201 297.129 47.5201C293.384 47.5201 290.666 50.3552 290.666 54.2597V55.6907C291.695 55.4642 292.746 55.3487 293.802 55.3487Z"
        fill="#96501E"
      />
      <path
        d="M300.36 57.4054V53.9209C300.36 51.8764 298.905 50.7769 297.127 50.7769C295.35 50.7769 293.895 51.8764 293.895 53.9209V55.8004C296.197 55.8154 298.384 56.3869 300.36 57.4054Z"
        fill="#644100"
      />
      <path
        d="M300.36 57.8552C300.289 57.8552 300.219 57.8387 300.154 57.8057C298.18 56.7887 296.074 56.2652 293.893 56.2502C293.646 56.2487 293.446 56.0477 293.446 55.8002V53.9207C293.446 51.7712 294.925 50.3267 297.129 50.3267C299.331 50.3267 300.811 51.7712 300.811 53.9207V57.4052C300.811 57.5627 300.73 57.7082 300.595 57.7892C300.522 57.8327 300.441 57.8552 300.36 57.8552ZM294.346 55.3592C296.271 55.4297 298.137 55.8752 299.91 56.6852V53.9192C299.91 51.9302 298.411 51.2252 297.127 51.2252C295.843 51.2252 294.345 51.9302 294.345 53.9192V55.3592H294.346Z"
        fill="#4B321E"
      />
      <path
        d="M275.339 86.9282C276.216 87.9407 278.61 88.6682 281.433 88.6682C282.434 88.6682 283.376 88.5737 284.222 88.4117C285.066 88.5737 286.01 88.6682 287.01 88.6682C289.833 88.6682 292.229 87.9407 293.105 86.9282"
        fill="#FF8700"
      />
      <path
        d="M287.01 89.1183C286.046 89.1183 285.108 89.0343 284.222 88.8693C283.335 89.0343 282.398 89.1183 281.433 89.1183C278.522 89.1183 275.996 88.3743 274.998 87.2223C274.836 87.0348 274.856 86.7498 275.043 86.5878C275.231 86.4258 275.516 86.4453 275.678 86.6328C276.486 87.5658 278.853 88.2183 281.432 88.2183C282.369 88.2183 283.278 88.1343 284.135 87.9693C284.192 87.9588 284.249 87.9588 284.304 87.9693C285.159 88.1343 286.07 88.2183 287.007 88.2183C289.587 88.2183 291.954 87.5658 292.761 86.6328C292.923 86.4453 293.208 86.4243 293.396 86.5878C293.583 86.7498 293.604 87.0348 293.441 87.2223C292.448 88.3743 289.922 89.1183 287.01 89.1183Z"
        fill="#4B321E"
      />
      <path
        d="M288.258 85.8558C288.258 87.7173 284.223 88.4118 284.223 88.4118C284.223 88.4118 280.188 87.7278 280.188 85.8558C280.188 84.9618 281.993 84.2373 284.223 84.2373C286.452 84.2373 288.258 84.9618 288.258 85.8558Z"
        fill="#644100"
      />
      <path
        d="M284.222 88.8616C284.197 88.8616 284.171 88.8602 284.147 88.8557C284.104 88.8482 283.079 88.6711 282.037 88.2526C280.511 87.6391 279.737 86.8336 279.737 85.8541C279.737 84.8266 281.278 83.7856 284.222 83.7856C287.167 83.7856 288.707 84.8266 288.707 85.8541C288.707 86.8276 287.933 87.6332 286.408 88.2482C285.367 88.6682 284.342 88.8467 284.299 88.8542C284.273 88.8602 284.248 88.8616 284.222 88.8616ZM284.222 84.6871C282.067 84.6871 280.637 85.3906 280.637 85.8556C280.637 87.0406 283.211 87.7681 284.222 87.9557C285.233 87.7666 287.807 87.0331 287.807 85.8556C287.807 85.3906 286.378 84.6871 284.222 84.6871Z"
        fill="#4B321E"
      />
      <path
        d="M272.77 84.4233C272.77 84.4233 274.972 85.4358 277.88 85.3038Z"
        fill="#FF8700"
      />
      <path
        d="M277.348 85.7673C274.645 85.7673 272.669 84.8733 272.581 84.8328C272.356 84.7293 272.257 84.4623 272.36 84.2358C272.464 84.0108 272.731 83.9103 272.957 84.0153C272.978 84.0243 275.104 84.9828 277.859 84.8553C278.107 84.8448 278.318 85.0368 278.329 85.2843C278.341 85.5333 278.147 85.7433 277.9 85.7538C277.714 85.7628 277.529 85.7673 277.348 85.7673Z"
        fill="black"
      />
      <path
        d="M295.677 84.4233C295.677 84.4233 293.475 85.4358 290.566 85.3038Z"
        fill="#FF8700"
      />
      <path
        d="M291.097 85.7671C290.917 85.7671 290.733 85.7626 290.547 85.7551C290.298 85.7431 290.106 85.5331 290.118 85.2856C290.13 85.0381 290.341 84.8476 290.587 84.8566C293.343 84.9826 295.468 84.0256 295.489 84.0166C295.716 83.9131 295.983 84.0121 296.086 84.2386C296.19 84.4651 296.091 84.7306 295.866 84.8356C295.777 84.8731 293.8 85.7671 291.097 85.7671Z"
        fill="black"
      />
      <path
        d="M285.029 98.2771C274.741 98.2771 269.456 95.2156 269.236 95.0851C269.021 94.9591 268.951 94.6831 269.078 94.4686C269.206 94.2541 269.48 94.1836 269.695 94.3111C269.725 94.3291 271.04 95.0911 273.56 95.8441C275.902 96.5431 279.793 97.3771 285.028 97.3771C290.234 97.3771 293.707 96.5521 295.705 95.8621C297.863 95.1151 298.807 94.3591 298.816 94.3501C299.008 94.1941 299.291 94.2211 299.449 94.4131C299.606 94.6051 299.579 94.8871 299.387 95.0446C299.228 95.1781 295.355 98.2771 285.029 98.2771Z"
        fill="#96501E"
      />
      <path
        d="M373.88 68.9236C373.089 68.9236 367.701 68.9236 367.701 68.9236C364.58 68.9236 362.025 66.3691 362.025 63.2476C362.025 60.1261 364.58 57.5716 367.701 57.5716H371.124C372.266 57.5716 373.19 56.6461 373.19 55.5061V53.2531C373.19 50.1316 375.744 47.5771 378.866 47.5771C381.987 47.5771 384.542 50.1316 384.542 53.2531C384.542 53.2531 384.542 57.8806 384.542 58.2616C384.543 64.1491 379.769 68.9236 373.88 68.9236Z"
        fill="#9BCD14"
      />
      <path
        d="M373.88 69.3734H367.701C364.323 69.3734 361.575 66.6254 361.575 63.2474C361.575 59.8694 364.323 57.1214 367.701 57.1214H371.124C372.015 57.1214 372.74 56.397 372.74 55.506V53.2529C372.74 49.875 375.488 47.127 378.866 47.127C382.244 47.127 384.992 49.875 384.992 53.2529V58.2614C384.993 64.3874 380.007 69.3734 373.88 69.3734ZM367.701 58.0199C364.82 58.0199 362.475 60.3644 362.475 63.2459C362.475 66.1274 364.82 68.4719 367.701 68.4719H373.88C379.512 68.4719 384.093 63.8894 384.093 58.2585V53.25C384.093 50.3685 381.749 48.024 378.867 48.024C375.986 48.024 373.641 50.3685 373.641 53.25V55.5029C373.641 56.8904 372.513 58.0184 371.126 58.0184H367.701V58.0199Z"
        fill="#5A6E2D"
      />
      <path
        d="M338.114 57.5703C338.905 57.5703 344.293 57.5703 344.293 57.5703C347.414 57.5703 349.969 60.1248 349.969 63.2463C349.969 66.3678 347.414 68.9223 344.293 68.9223H340.87C339.728 68.9223 338.804 69.8478 338.804 70.9878V73.2408C338.804 76.3623 336.25 78.9168 333.128 78.9168C330.007 78.9168 327.452 76.3623 327.452 73.2408C327.452 73.2408 327.452 68.6133 327.452 68.2323C327.451 62.3448 332.225 57.5703 338.114 57.5703Z"
        fill="#9BCD14"
      />
      <path
        d="M333.126 79.3681C329.748 79.3681 327 76.6201 327 73.2421V68.2336C327 62.1061 331.986 57.1201 338.113 57.1201H344.292C347.67 57.1201 350.418 59.8681 350.418 63.2461C350.418 66.6241 347.67 69.3721 344.292 69.3721H340.869C339.978 69.3721 339.253 70.0966 339.253 70.9876V73.2406C339.252 76.6201 336.504 79.3681 333.126 79.3681ZM338.113 58.0201C332.481 58.0201 327.9 62.6026 327.9 68.2336V73.2421C327.9 76.1236 330.244 78.4681 333.126 78.4681C336.007 78.4681 338.352 76.1236 338.352 73.2421V70.9891C338.352 69.6016 339.48 68.4736 340.867 68.4736H344.29C347.172 68.4736 349.516 66.1291 349.516 63.2476C349.516 60.3661 347.172 58.0216 344.29 58.0216H338.113V58.0201Z"
        fill="#5A6E2D"
      />
      <path
        d="M355.999 94.3594C363.429 94.3594 369.508 88.2799 369.508 80.8504V35.3674C369.508 27.9379 363.429 21.8584 355.999 21.8584C348.57 21.8584 342.49 27.9379 342.49 35.3674V80.8504C342.49 88.2814 348.57 94.3594 355.999 94.3594Z"
        fill="#9BCD14"
      />
      <path
        d="M355.999 94.8092C348.303 94.8092 342.04 88.5467 342.04 80.8502V35.3672C342.04 27.6707 348.303 21.4082 355.999 21.4082C363.696 21.4082 369.958 27.6707 369.958 35.3672V80.8502C369.958 88.5482 363.696 94.8092 355.999 94.8092ZM355.999 22.3097C348.799 22.3097 342.94 28.1672 342.94 35.3687V80.8517C342.94 88.0517 348.798 93.9107 355.999 93.9107C363.199 93.9107 369.058 88.0532 369.058 80.8517V35.3672C369.058 28.1672 363.199 22.3097 355.999 22.3097Z"
        fill="#5A6E2D"
      />
      <path
        d="M365.841 35.5666C365.841 26.8891 360.562 22.6576 353.421 22.2136C354.088 22.1116 354.771 22.0576 355.465 22.0576C362.895 22.0576 368.974 28.1371 368.974 35.5666C368.974 35.5666 369.466 37.1011 367.42 37.1011C365.374 37.1011 365.841 35.5666 365.841 35.5666Z"
        fill="#80B000"
      />
      <path
        d="M350.194 56.336C352.093 56.336 353.647 54.782 353.647 52.883V46.952C353.647 45.053 352.093 43.499 350.194 43.499C348.295 43.499 346.741 45.053 346.741 46.952V52.883C346.741 54.782 348.295 56.336 350.194 56.336Z"
        fill="#EB4173"
      />
      <path
        d="M350.194 56.7858C348.042 56.7858 346.291 55.0353 346.291 52.8828V46.9518C346.291 44.7993 348.042 43.0488 350.194 43.0488C352.347 43.0488 354.097 44.7993 354.097 46.9518V52.8828C354.099 55.0353 352.347 56.7858 350.194 56.7858ZM350.194 43.9488C348.538 43.9488 347.191 45.2958 347.191 46.9518V52.8828C347.191 54.5388 348.538 55.8858 350.194 55.8858C351.85 55.8858 353.197 54.5388 353.197 52.8828V46.9518C353.199 45.2958 351.85 43.9488 350.194 43.9488Z"
        fill="#A0375A"
      />
      <path
        d="M355.884 43.4987C356.446 43.4987 356.908 43.0382 356.908 42.4742V37.1207C356.908 36.5582 356.448 36.0962 355.884 36.0962C355.321 36.0962 354.859 36.5567 354.859 37.1207V42.4742C354.859 43.0382 355.321 43.4987 355.884 43.4987Z"
        fill="black"
      />
      <path
        d="M345.932 43.4987C346.356 43.4987 346.704 43.1507 346.704 42.7262V36.8702C346.704 36.4457 346.356 36.0977 345.932 36.0977C345.507 36.0977 345.159 36.4457 345.159 36.8702V42.7262C345.159 43.1507 345.506 43.4987 345.932 43.4987Z"
        fill="black"
      />
      <path
        d="M349.717 53.6178C350.248 53.6178 350.682 53.1843 350.682 52.6533V47.1813C350.682 46.6503 350.248 46.2168 349.717 46.2168C349.186 46.2168 348.753 46.6503 348.753 47.1813V52.6533C348.751 53.1843 349.186 53.6178 349.717 53.6178Z"
        fill="#AD2E5B"
      />
      <path
        d="M357.609 32.7168C356.657 31.7643 355.112 31.7643 354.159 32.7168Z"
        fill="#9BCD14"
      />
      <path
        d="M357.609 33.1666C357.494 33.1666 357.378 33.1231 357.291 33.0346C356.516 32.2591 355.254 32.2591 354.479 33.0346C354.303 33.2101 354.018 33.2101 353.843 33.0346C353.667 32.8591 353.667 32.5741 353.843 32.3986C354.969 31.2721 356.802 31.2721 357.929 32.3986C358.104 32.5741 358.104 32.8591 357.929 33.0346C357.839 33.1231 357.725 33.1666 357.609 33.1666Z"
        fill="#5A6E2D"
      />
      <path
        d="M344.479 32.7168C345.431 33.6693 346.453 33.6693 347.405 32.7168Z"
        fill="#9BCD14"
      />
      <path
        d="M345.942 33.8807C345.321 33.8807 344.722 33.5957 344.16 33.0347C343.984 32.8592 343.984 32.5742 344.16 32.3987C344.335 32.2232 344.62 32.2232 344.796 32.3987C345.568 33.1712 346.312 33.1727 347.086 32.3987C347.262 32.2232 347.547 32.2232 347.722 32.3987C347.898 32.5742 347.898 32.8592 347.722 33.0347C347.161 33.5972 346.561 33.8807 345.942 33.8807Z"
        fill="#5A6E2D"
      />
      <path
        d="M369.509 81.2251C369.509 95.7391 366.454 111.554 358.304 112.257C358.103 112.278 357.899 112.292 357.692 112.292C354.538 112.292 351.98 109.734 351.98 106.58C351.98 105.101 352.649 103.764 353.464 101.993C353.464 101.993 354.47 100.479 354.47 92.2531"
        fill="#9BCD14"
      />
      <path
        d="M357.69 112.74C354.293 112.74 351.528 109.975 351.528 106.578C351.528 105.105 352.125 103.81 352.883 102.172L353.052 101.803C353.061 101.784 353.072 101.766 353.084 101.746C353.118 101.688 354.018 100.069 354.018 92.2514C354.018 92.0024 354.219 91.8014 354.468 91.8014C354.717 91.8014 354.918 92.0024 354.918 92.2514C354.918 99.9539 354.039 101.887 353.861 102.199L353.7 102.549C352.991 104.086 352.43 105.303 352.43 106.578C352.43 109.479 354.791 111.84 357.692 111.84C357.875 111.84 358.059 111.829 358.256 111.808C366.233 111.12 369.059 94.9034 369.059 81.2249C369.059 80.9759 369.26 80.7749 369.509 80.7749C369.758 80.7749 369.959 80.9759 369.959 81.2249C369.959 89.2199 369.047 96.6149 367.392 102.051C365.345 108.778 362.298 112.363 358.341 112.705C358.125 112.728 357.902 112.74 357.69 112.74Z"
        fill="#5A6E2D"
      />
      <path
        d="M354.467 94.2724C356.718 94.2724 357.629 92.7934 358.545 90.1938Z"
        fill="#9BCD14"
      />
      <path
        d="M354.467 94.7223C354.218 94.7223 354.017 94.5213 354.017 94.2723C354.017 94.0233 354.218 93.8223 354.467 93.8223C356.429 93.8223 357.237 92.5503 358.121 90.0453C358.203 89.8113 358.46 89.6883 358.695 89.7708C358.929 89.8533 359.052 90.1098 358.97 90.3453C358.155 92.6523 357.2 94.7223 354.467 94.7223Z"
        fill="#5A6E2D"
      />
      <path
        d="M348.045 103.643C351.577 103.643 354.466 100.754 354.466 97.2211V84.0841C354.466 80.5516 351.577 77.6626 348.045 77.6626C344.512 77.6626 341.623 80.5516 341.623 84.0841V97.2211C341.623 100.752 344.512 103.643 348.045 103.643Z"
        fill="#9BCD14"
      />
      <path
        d="M348.044 104.092C344.255 104.092 341.173 101.01 341.173 97.2209V84.0839C341.173 80.2949 344.255 77.2124 348.044 77.2124C351.833 77.2124 354.916 80.2949 354.916 84.0839V97.2209C354.916 101.01 351.833 104.092 348.044 104.092ZM348.044 78.1124C344.752 78.1124 342.073 80.7914 342.073 84.0839V97.2209C342.073 100.513 344.752 103.192 348.044 103.192C351.337 103.192 354.016 100.513 354.016 97.2209V84.0839C354.016 80.7914 351.337 78.1124 348.044 78.1124Z"
        fill="#5A6E2D"
      />
      <path
        d="M366.006 20.9389C364.662 22.1434 363.138 22.9714 361.682 23.5504C360.122 22.6564 358.389 22.0759 356.61 21.9049C357.393 19.7539 358.788 16.8664 361.146 14.7544C363.21 12.9049 366.179 12.6169 367.515 14.3179C368.852 16.0174 368.07 19.0894 366.006 20.9389Z"
        fill="#EB4173"
      />
      <path
        d="M361.681 24.0003C361.603 24.0003 361.527 23.9808 361.458 23.9403C359.923 23.0613 358.233 22.5123 356.566 22.3518C356.428 22.3383 356.304 22.2618 356.229 22.1433C356.154 22.0263 356.139 21.8808 356.187 21.7488C356.964 19.6113 358.404 16.6053 360.846 14.4168C362.154 13.2438 363.843 12.6243 365.359 12.7563C366.414 12.8478 367.282 13.2918 367.869 14.0373C369.327 15.8943 368.611 19.2078 366.306 21.2733C365.076 22.3743 363.576 23.2818 361.848 23.9673C361.794 23.9898 361.738 24.0003 361.681 24.0003ZM357.232 21.5268C358.764 21.7503 360.298 22.2723 361.713 23.0523C363.259 22.4163 364.602 21.5928 365.704 20.6043C367.635 18.8733 368.316 16.0668 367.159 14.5953C366.73 14.0493 366.081 13.7238 365.28 13.6548C364.005 13.5438 362.571 14.0793 361.444 15.0903C359.337 16.9788 358.011 19.5408 357.232 21.5268Z"
        fill="#A0375A"
      />
      <path
        d="M361.432 21.0855C361.848 21.0855 362.247 20.931 362.569 20.6415C362.77 20.4615 362.796 20.142 362.628 19.929C362.46 19.7145 362.16 19.6875 361.96 19.866C361.792 20.0175 361.576 20.085 361.359 20.0685C361.14 20.0475 360.94 19.938 360.799 19.758C360.507 19.386 360.553 18.8295 360.901 18.5175C361.102 18.3375 361.128 18.018 360.96 17.805C360.792 17.5905 360.492 17.562 360.292 17.742C359.544 18.4125 359.446 19.6095 360.073 20.409C360.378 20.796 360.805 21.0345 361.276 21.078C361.327 21.0825 361.38 21.0855 361.432 21.0855Z"
        fill="white"
      />
      <path
        d="M362.07 17.3146C362.178 17.3146 362.286 17.2756 362.374 17.1961L363.394 16.2811C363.595 16.1011 363.621 15.7816 363.453 15.5686C363.283 15.3541 362.985 15.3256 362.785 15.5056L361.765 16.4206C361.564 16.6006 361.539 16.9201 361.707 17.1331C361.8 17.2531 361.935 17.3146 362.07 17.3146Z"
        fill="white"
      />
      <path
        d="M363.74 19.4385C363.848 19.4385 363.956 19.3995 364.044 19.32L365.064 18.405C365.265 18.225 365.291 17.9055 365.123 17.6925C364.955 17.478 364.655 17.451 364.455 17.6295L363.435 18.5445C363.234 18.7245 363.209 19.044 363.377 19.257C363.47 19.377 363.605 19.4385 363.74 19.4385Z"
        fill="white"
      />
      <path
        d="M355.51 21.8475C353.952 21.8475 352.483 22.128 351.136 22.6395C351.009 22.6755 350.878 22.7055 350.754 22.746C349.941 22.263 349.107 21.6735 348.286 20.9385C346.222 19.089 344.494 16.017 345.831 14.316C347.167 12.615 349.696 12.903 351.76 14.7525C354.109 16.857 355.503 19.728 356.287 21.8775C356.028 21.8595 355.77 21.8475 355.51 21.8475Z"
        fill="#EB4173"
      />
      <path
        d="M350.754 23.1959C350.675 23.1959 350.595 23.1749 350.525 23.1329C349.613 22.5914 348.759 21.9659 347.987 21.2744C346.853 20.2574 345.899 18.9734 345.371 17.7509C344.718 16.2404 344.756 14.9579 345.477 14.0384C346.071 13.2824 346.896 12.8384 347.864 12.7544C349.218 12.6359 350.748 13.2419 352.061 14.4179C354.495 16.5989 355.932 19.5944 356.709 21.7229C356.762 21.8654 356.738 22.0259 356.646 22.1474C356.555 22.2689 356.408 22.3379 356.256 22.3259C355.971 22.3064 355.734 22.2974 355.511 22.2974C354.05 22.2974 352.631 22.5539 351.296 23.0594C351.284 23.0639 351.272 23.0684 351.26 23.0714C351.209 23.0864 351.158 23.0999 351.107 23.1134C351.035 23.1329 350.963 23.1509 350.892 23.1734C350.847 23.1884 350.801 23.1959 350.754 23.1959ZM348.207 13.6394C348.119 13.6394 348.03 13.6424 347.943 13.6514C347.229 13.7144 346.622 14.0399 346.185 14.5949C345.087 15.9929 346.577 18.8024 348.587 20.6039C349.278 21.2234 350.009 21.7664 350.817 22.2599C350.837 22.2539 350.856 22.2494 350.876 22.2449C350.916 22.2344 350.955 22.2239 350.996 22.2134C352.428 21.6734 353.946 21.4004 355.511 21.4004C355.548 21.4004 355.586 21.4004 355.623 21.4004C354.84 19.4384 353.526 16.9409 351.461 15.0899C350.421 14.1569 349.251 13.6394 348.207 13.6394Z"
        fill="#A0375A"
      />
      <path
        d="M351.158 21.328C351.205 21.3295 351.253 21.328 351.301 21.325C351.73 21.295 352.124 21.076 352.412 20.7085C353.006 19.951 352.954 18.7885 352.297 18.118C352.12 17.938 351.848 17.9575 351.689 18.16C351.53 18.3625 351.544 18.673 351.721 18.853C352.027 19.165 352.051 19.705 351.775 20.0575C351.641 20.2285 351.457 20.329 351.259 20.344C351.059 20.356 350.869 20.2825 350.72 20.131C350.543 19.9525 350.272 19.9705 350.113 20.173C349.954 20.377 349.967 20.686 350.144 20.866C350.426 21.1555 350.782 21.316 351.158 21.328Z"
        fill="white"
      />
      <path
        d="M349.118 19.6693C349.241 19.6723 349.364 19.6168 349.452 19.5043C349.611 19.3018 349.598 18.9913 349.421 18.8113L348.524 17.8978C348.347 17.7178 348.075 17.7373 347.916 17.9398C347.757 18.1423 347.771 18.4528 347.948 18.6328L348.845 19.5463C348.923 19.6258 349.02 19.6663 349.118 19.6693Z"
        fill="white"
      />
      <path
        d="M350.696 17.6547C350.819 17.6577 350.942 17.6022 351.031 17.4897C351.19 17.2872 351.176 16.9767 350.999 16.7967L350.102 15.8832C349.925 15.7032 349.654 15.7212 349.495 15.9252C349.336 16.1277 349.349 16.4382 349.526 16.6182L350.423 17.5317C350.501 17.6112 350.599 17.6517 350.696 17.6547Z"
        fill="white"
      />
      <path
        d="M355.511 21.8477C354.993 21.8477 354.488 21.8823 353.991 21.9423C352.839 19.6938 351.548 16.3683 351.548 12.7353C351.548 8.71375 353.438 6.15625 355.748 6.15625C359.102 6.15625 360.618 9.53875 360.618 12.7353C360.618 16.4328 359.066 19.8167 357.714 22.0637C356.99 21.9242 356.252 21.8477 355.511 21.8477Z"
        fill="#EB4173"
      />
      <path
        d="M357.714 22.5136C357.685 22.5136 357.657 22.5106 357.628 22.5061C356.91 22.3681 356.197 22.2976 355.509 22.2976C355.03 22.2976 354.55 22.3276 354.043 22.3891C353.856 22.4116 353.674 22.3156 353.589 22.1476C352.452 19.9276 351.096 16.4911 351.096 12.7351C351.096 8.66256 353.052 5.70605 355.746 5.70605C359.401 5.70605 361.065 9.34956 361.065 12.7351C361.065 16.5571 359.451 20.0431 358.098 22.2961C358.017 22.4326 357.87 22.5136 357.714 22.5136ZM355.51 21.3976C356.154 21.3976 356.818 21.4546 357.486 21.5656C358.746 19.4086 360.168 16.2046 360.168 12.7351C360.168 9.78306 358.785 6.60605 355.747 6.60605C353.574 6.60605 351.997 9.18456 351.997 12.7351C351.997 16.1611 353.191 19.3261 354.252 21.4606C354.679 21.4186 355.102 21.3976 355.51 21.3976Z"
        fill="#A0375A"
      />
      <path
        d="M355.278 17.7181C356.4 17.7181 357.313 16.7431 357.313 15.5446C357.313 15.1951 357.048 14.9116 356.721 14.9116C356.394 14.9116 356.128 15.1951 356.128 15.5446C356.128 16.0456 355.747 16.4521 355.278 16.4521C354.808 16.4521 354.427 16.0441 354.427 15.5446C354.427 15.1951 354.162 14.9116 353.835 14.9116C353.508 14.9116 353.242 15.1951 353.242 15.5446C353.242 16.7431 354.156 17.7181 355.278 17.7181Z"
        fill="white"
      />
      <path
        d="M353.835 13.8914C354.162 13.8914 354.427 13.6079 354.427 13.2584V11.6774C354.427 11.3279 354.162 11.0444 353.835 11.0444C353.508 11.0444 353.242 11.3279 353.242 11.6774V13.2584C353.242 13.6079 353.508 13.8914 353.835 13.8914Z"
        fill="white"
      />
      <path
        d="M356.719 13.8914C357.046 13.8914 357.312 13.6079 357.312 13.2584V11.6774C357.312 11.3279 357.046 11.0444 356.719 11.0444C356.392 11.0444 356.127 11.3279 356.127 11.6774V13.2584C356.127 13.6079 356.392 13.8914 356.719 13.8914Z"
        fill="white"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(ByeFriends),
};
export default Asset;
