import React, { useState } from 'react';

import useTranslatedTexts from '../../../../useTranslatedTexts';

import { DialogContentTemplate } from '../../components';

import {
  RelationshipType,
  useSetGuardianRelationshipMutation,
} from 'generated/graphql';
import { RadioGroup } from 'designSystem/molecules';
import { getCredentialAccountId } from 'core/credential/credential.service';

interface SetRelationshipStepProps {
  childId: string;
  childName: string;
  onSuccess: () => void;
  onUnverifiedError: () => void;
  onExpiredError: () => void;
  onCancelClick: () => void;
}

const SetRelationshipStep = ({
  childId,
  childName,
  onSuccess: handleSuccess,
  onCancelClick: handleCancelClick,
  onUnverifiedError,
  onExpiredError,
}: SetRelationshipStepProps): JSX.Element => {
  const accountId = getCredentialAccountId();
  const texts = useTranslatedTexts();
  const [relationship, setRelationship] = useState<RelationshipType>();

  const [linkChild] = useSetGuardianRelationshipMutation({
    refetchQueries: ['LinkChildPageContent'],
  });

  const handleConfirmClick = async () => {
    if (!relationship) {
      // istanbul ignore next
      return;
    }

    const response = await linkChild({
      variables: {
        childId: childId,
        relationship: relationship,
      },
    });

    if (!response.errors) {
      return handleSuccess();
    }

    const unverifiedError = response.errors.find((error) => {
      const message = error.message as string;
      return message.match(/forbidden/i);
    });

    const expiredError = response.errors.find((error) => {
      const message = error.message as string;
      return message.match(/not\sfound/i);
    });

    if (unverifiedError) {
      return onUnverifiedError();
    }

    if (expiredError) {
      return onExpiredError();
    }
  };

  const handleRelationshipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRelationship(e.target.value as RelationshipType);
  };

  return (
    <DialogContentTemplate
      testId="set-guardian-relationship-dialog-content"
      title={texts.title.selectRelationship}
      description={texts.description.selectRelationship(childName)}
      cancelButton={{
        text: texts.button.cancel,
        onClick: handleCancelClick,
        event: {
          name: 'LinkChildCancelled',
          on: 'click',
          properties: {
            userId: accountId,
          },
        },
      }}
      confirmButton={{
        active: !!relationship,
        onClick: handleConfirmClick,
        text: texts.button.link,
      }}
    >
      <RadioGroup
        name="relationship_type"
        items={[
          { label: texts.item.mother, value: RelationshipType.Mother },
          { label: texts.item.father, value: RelationshipType.Father },
          {
            label: texts.item.grandMother,
            value: RelationshipType.GrandMother,
          },
          {
            label: texts.item.grandFather,
            value: RelationshipType.GrandFather,
          },
          { label: texts.item.guardian, value: RelationshipType.Guardian },
        ]}
        onChange={handleRelationshipChange}
      />
    </DialogContentTemplate>
  );
};

export default SetRelationshipStep;
