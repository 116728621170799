/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const ChevronUp = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
      />
    </svg>
  );
};

const Asset = {
  size,
  View: ChevronUp,
};

export default Asset;
