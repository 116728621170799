import React, { useCallback, useRef } from 'react';

import { Camera } from 'designSystem/ions/Icons/solid';
import { Box } from 'designSystem/ions';
import { ProfileImage } from 'designSystem/atoms';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

const EditableProfileImage = ({
  src,
  deleteText,
  onChange,
  onDelete,
  editEvent,
  deleteEvent,
}: {
  src?: string;
  deleteText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  editEvent?: TagManagerEventDefinition;
  deleteEvent?: TagManagerEventDefinition;
}): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const editButtonTrackEventProps =
    editEvent && createTrackEventProps(editEvent);

  const deleteButtonTrackEventProps =
    deleteEvent && createTrackEventProps(deleteEvent);

  const handleClickChangeButton = useCallback(() => {
    /* istanbul ignore else */
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  }, []);

  return (
    <Box className="flex flex-row">
      <Box
        ref={fileInputRef}
        data-testid="image-input-field"
        is="input"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={onChange}
        {...editButtonTrackEventProps}
      />
      <Box
        is="button"
        data-testid="change-profile-image"
        className="relative focus:outline-none focus-visible:ring"
        aria-label="프로필 변경"
        onClick={handleClickChangeButton}
      >
        <ProfileImage src={src} size="md" />
        <Box className="absolute right-0 bottom-0 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200">
          <Camera.View className="text-gray-600" width={16} height={16} />
        </Box>
      </Box>
      {!!src && (
        <Box className="flex flex-row items-end ml-3">
          <Box
            is="button"
            aria-label={deleteText}
            className="bg-transparent text-caption text-gray-700 sm:text-gray-400 font-medium focus:outline-none"
            onClick={onDelete}
            {...deleteButtonTrackEventProps}
          >
            {deleteText}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditableProfileImage;
