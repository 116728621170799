import React from 'react';

import { Radio } from 'designSystem/atoms';
import { Box } from 'designSystem/ions';

type RadioGroupProps = {
  items: { value: string; label: string }[];
  name: string;
  labelledby?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const labelStyle = 'text-body-3 text-gray-900 ml-2 cursor-pointer';
const itemStyle = 'mb-3 last:mb-0';

const RadioGroup = ({
  items,
  name,
  labelledby,
  onChange,
}: RadioGroupProps): JSX.Element => {
  return (
    <Box role="radiogroup" aria-labelledby={labelledby}>
      {items.map(({ value, label }) => (
        <Box is="div" key={value} className={itemStyle}>
          <Radio id={value} name={name} value={value} onChange={onChange} />
          <Box is="label" className={labelStyle} htmlFor={value}>
            {label}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RadioGroup;
