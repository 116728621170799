import React from 'react';

import { Box } from 'designSystem/ions';

interface SingleColumnLayoutProps {
  children: React.ReactNode;
}

const SingleColumnLayout = ({
  children,
}: SingleColumnLayoutProps): JSX.Element => {
  const className = `
  flex flex-col
  col-12 md:col-8
  mb-22
  `;

  return (
    <Box is="div" className="container">
      <Box is="div" className="row justify-center">
        <Box is="main" role="main" className={className}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default SingleColumnLayout;
