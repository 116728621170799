import React from 'react';

import { Redirect } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import {
  CLASSTING_FEDERATED_LOGOUT_URL,
  CLASSTING_OIDC_URL,
} from 'shared/constants';

import { useClearStoredDataAsync } from 'shared/root';
import ByeFriends from 'assets/classtingFriends/ByeFriends';
import {
  GlobalSection,
  SingleColumnLayout,
  TransparentPanel,
} from 'designSystem/layouts';
import { AnchorButton, CallToAction } from 'designSystem/molecules';
import {
  CenterTitleNavigationHeader,
  GlobalFooter,
} from 'designSystem/organisms';
import { sessionStorageService } from 'core/storage';
import { useIsClasstingMobileApp } from 'shared/hooks';

/**
 * 현재는 RN에서만 동작하는 url입니다.
 * RN 해당 url을 캐치하면 RN에서 로그아웃 로직을 수행하고 앱 랜딩 페이지로 네비게이션합니다.
 */
const END_SESSION_SUCCESS_URL = `${CLASSTING_OIDC_URL}/session/end/success`;

const ByeFriendsAsset = () => (
  <ByeFriends.View className="w-2/3 md:w-full mx-auto" />
);

const DeleteSuccess = (): JSX.Element => {
  useClearStoredDataAsync(100);
  const texts = useTranslatedTexts();
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const redirectUrl = isClasstingMobileApp
    ? END_SESSION_SUCCESS_URL
    : CLASSTING_FEDERATED_LOGOUT_URL;

  return (
    <GlobalSection.Wrapper>
      <GlobalSection.Header title="클래스팅">
        <CenterTitleNavigationHeader
          title="계정 삭제 완료"
          showBackButtonInMobile={false}
          showTitleInMobile={false}
        />
      </GlobalSection.Header>
      <GlobalSection.Body>
        <SingleColumnLayout>
          <TransparentPanel.Wrapper>
            <TransparentPanel.Header />
            <TransparentPanel.Body headerLess>
              <CallToAction
                withAsset="topAsset"
                title={texts.CTATitle}
                desc={texts.CTADesc}
                asset={ByeFriendsAsset}
                width={ByeFriends.size.width}
              >
                <AnchorButton
                  testId="delete-success-page__confirm-button"
                  size="md"
                  appearance="outlined"
                  href={redirectUrl}
                >
                  {texts.confirmButton}
                </AnchorButton>
              </CallToAction>
            </TransparentPanel.Body>
          </TransparentPanel.Wrapper>
        </SingleColumnLayout>
      </GlobalSection.Body>
      {!isClasstingMobileApp && (
        <GlobalSection.Footer>
          <GlobalFooter />
        </GlobalSection.Footer>
      )}
    </GlobalSection.Wrapper>
  );
};

const DeleteSuccessPage = (): JSX.Element => {
  const isDeleteRequestSuccess = sessionStorageService.getItem(
    'isDeleteRequestSuccess'
  );
  if (isDeleteRequestSuccess) {
    return <DeleteSuccess />;
  }
  return (
    <Redirect
      to={{
        pathname: '/account/delete/error',
        state: {
          reason: '비정상적인 접근',
        },
      }}
    />
  );
};

export default DeleteSuccessPage;
