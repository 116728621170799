/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function LinkIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7141 12.3566L13.5357 11.1783L14.7141 9.99992C15.0236 9.69039 15.2691 9.32291 15.4367 8.91848C15.6042 8.51405 15.6904 8.08059 15.6904 7.64284C15.6904 7.20509 15.6042 6.77162 15.4367 6.36719C15.2691 5.96276 15.0236 5.59529 14.7141 5.28576C14.4045 4.97622 14.037 4.73068 13.6326 4.56316C13.2282 4.39564 12.7947 4.30942 12.357 4.30942C11.9192 4.30942 11.4858 4.39564 11.0813 4.56316C10.6769 4.73068 10.3094 4.97622 9.99989 5.28576L8.82156 6.46409L7.64322 5.28576L8.82156 4.10742C9.76182 3.18235 11.0295 2.6663 12.3485 2.67167C13.6676 2.67704 14.931 3.2034 15.8637 4.1361C16.7964 5.06879 17.3228 6.33225 17.3281 7.65127C17.3335 8.97029 16.8175 10.238 15.8924 11.1783L14.7141 12.3566ZM12.3566 14.7141L11.1782 15.8924C10.7153 16.3629 10.1638 16.7371 9.55561 16.9934C8.94737 17.2497 8.2944 17.3831 7.63437 17.3858C6.97434 17.3884 6.32031 17.2604 5.71001 17.0091C5.0997 16.7577 4.5452 16.388 4.07848 15.9213C3.61176 15.4546 3.24207 14.9001 2.99073 14.2898C2.73939 13.6795 2.61137 13.0255 2.61406 12.3654C2.61674 11.7054 2.75008 11.0524 3.00639 10.4442C3.26269 9.83596 3.63689 9.28449 4.10739 8.82159L5.28572 7.64326L6.46406 8.82159L5.28572 9.99992C4.97619 10.3095 4.73065 10.6769 4.56313 11.0814C4.39561 11.4858 4.30939 11.9193 4.30939 12.357C4.30939 12.7948 4.39561 13.2282 4.56313 13.6327C4.73065 14.0371 4.97619 14.4046 5.28572 14.7141C5.59526 15.0236 5.96273 15.2692 6.36716 15.4367C6.77159 15.6042 7.20506 15.6904 7.64281 15.6904C8.08056 15.6904 8.51402 15.6042 8.91845 15.4367C9.32288 15.2692 9.69035 15.0236 9.99989 14.7141L11.1782 13.5358L12.3566 14.7141ZM12.3566 6.46409L13.5357 7.64326L7.64322 13.5349L6.46406 12.3566L12.3566 6.46492V6.46409Z"
        fill="currentColor"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(LinkIcon),
};

export default Asset;
