import React, { useState, forwardRef, Ref, FocusEvent } from 'react';
import classnames from 'classnames';

import { Box } from 'designSystem/ions';

type InputAttributes = React.InputHTMLAttributes<HTMLInputElement>;
type InputStatus = 'default' | 'success' | 'error';

export type InputProps = Pick<InputAttributes, 'inputMode'> & {
  type?: 'text' | 'password';
  disabled?: boolean;
  status?: InputStatus;
  placeholder?: string;
  value?: string;
  name?: string;
  testId?: string;
  endAdornment?: JSX.Element;
  startAdornment?: JSX.Element;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  readOnly?: boolean;
};

const rootStyle =
  'flex items-center w-full h-11 md:h-14 border rounded-lg px-4';
const rootFocusStyle = 'border-primary-normal';
const rootEnableStyle = 'bg-white';
const rootDisabledStyle = 'bg-gray-50 text-gray-500';
const inputStyle =
  'border-0 w-full h-full disabled:bg-gray-50 text-body-3 md:text-body-1 focus:outline-none  placeholder-gray-500';
const getStyleByStatus = (status: InputStatus): string => {
  switch (status) {
    case 'success':
      return 'border-status-success';
    case 'error':
      return 'border-status-error';
    default:
      return 'border-gray-300';
  }
};

const Input = (
  {
    type = 'text',
    disabled = false,
    status = 'default',
    placeholder,
    value,
    name,
    inputMode,
    testId,
    startAdornment,
    endAdornment,
    onChange,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onBlur,
    onFocus,
    onClick,
    readOnly: readonly,
  }: InputProps,
  forwardedRef: Ref<HTMLInputElement>
): JSX.Element => {
  const [isFocus, setIsFocus] = useState(false);
  const styleByStatus = getStyleByStatus(status);

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    setIsFocus(true);
  };

  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    setIsFocus(false);
  };

  return (
    <Box
      className={classnames(rootStyle, {
        [styleByStatus]: !isFocus || status == 'error',
        [rootFocusStyle]: isFocus && !disabled && status !== 'error',
        [rootDisabledStyle]: disabled,
        [rootEnableStyle]: !disabled,
      })}
      data-testid={testId}
    >
      {startAdornment}
      <Box
        is="input"
        ref={forwardedRef}
        type={type}
        name={name}
        disabled={disabled}
        className={inputStyle}
        placeholder={placeholder}
        value={value}
        aria-label={name}
        inputMode={inputMode}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onClick={onClick}
        readOnly={readonly}
      />
      {endAdornment}
    </Box>
  );
};

export default forwardRef(Input);
