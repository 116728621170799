import { useMedia } from 'react-use';

type Display = 'flex' | 'inline-flex' | 'block' | 'inline-block';

export const hideInMobile = (
  flag: boolean,
  { display }: { display: Display } = { display: 'flex' }
): string => {
  if (flag) {
    return `hidden sm:${display}`;
  }
  return '';
};

export const showInMobile = (
  flag: boolean,
  { display }: { display: Display } = { display: 'flex' }
): string => {
  if (flag) {
    return `${display} sm:hidden`;
  }
  return '';
};

export const useIsInMobile = (): boolean => useMedia('(max-width: 539px)');
export const useIsInSmScreen = (): boolean => useMedia('(max-width: 959px)');
