import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import { useHistory } from 'react-router-dom';

import { Box, BoxProps, useIsInMobile } from 'designSystem/ions';
import { ArrowLeftIcon } from 'designSystem/ions/Icons/outlined';
import { Divider, Label, Panel, PanelProps } from 'designSystem/atoms';
import { TitleBox } from 'designSystem/molecules';
import { Without } from 'types/utilities';
import { ChevronLeft } from 'designSystem/ions/Icons/solid';
import { useHistoryBackHandler } from 'shared/hooks/history';

export type PanelWrapperProps = PropsWithChildren<{
  testId?: string;
}> &
  PanelProps;

export type PanelHeaderProps = {
  testId?: string;
  title?: string;
  description?: string;
  showTitleInMobile?: boolean;
  showBackButton?: boolean;
  backTo?: number | string;
  showBackButtonOnMobile?: boolean;
};

type PanelInAppHeaderProps = {
  testId?: string;
  title?: string;
  description?: string;
  showTitleInMobile?: boolean;
  showBackButton?: boolean;
  backTo?: number | string;
};

type PanelItemBoxProps = Without<BoxProps<'div'>, 'is'> & {
  testId?: string;
  label?: React.ReactNode;
  divider?: boolean;
};
type PanelItemProps = PropsWithChildren<PanelItemBoxProps>;

const Wrapper = ({
  testId,
  children,
  ...restProps
}: PanelWrapperProps): JSX.Element => {
  return (
    <Panel testId={testId} {...restProps}>
      {children}
    </Panel>
  );
};

const Header = ({
  testId,
  title,
  description,
  showTitleInMobile = true,
  showBackButton = false,
  showBackButtonOnMobile = false,
  backTo = -1,
}: PanelHeaderProps): JSX.Element => {
  const history = useHistory();
  const historyHandler = useHistoryBackHandler();
  const isMobile = useIsInMobile();
  const showInMobileStyle = showTitleInMobile ? 'flex' : 'hidden';
  const headerAlignStyle = `${showInMobileStyle} relative sm:flex items-center w-full mb-8 ${
    showBackButton ? 'flex-col' : 'flex-row'
  }`;
  const titleAlign = showBackButton ? 'center' : 'left';
  const handleClickBackButton = () => {
    if (typeof backTo === 'number') {
      historyHandler.go(backTo);
    } else {
      history.push(backTo);
    }
  };

  const renderBackButton = () => {
    if (!showBackButton) {
      return null;
    }

    if (isMobile && showBackButtonOnMobile) {
      return (
        <HeaderBackButton
          insetLeft={0}
          iconType="chevron"
          onClick={handleClickBackButton}
        />
      );
    }

    if (!isMobile) {
      return (
        <HeaderBackButton
          insetLeft={2}
          iconType="arrow"
          onClick={handleClickBackButton}
        />
      );
    }
  };

  return (
    <Box is="header" data-testid={testId} className={headerAlignStyle}>
      <TitleBox
        size="sm"
        title={title}
        desc={description}
        align={titleAlign}
        hideTitleInMobile={!showTitleInMobile}
      />
      {renderBackButton()}
    </Box>
  );
};

interface HeaderBackButtonProps {
  onClick: () => void;
  iconType: 'chevron' | 'arrow';
  insetLeft: number;
}

const HEADER_BACK_BUTTON_DEFAULT_INSET = 2;

const HeaderBackButton = ({
  onClick: handleClick,
  iconType,
  insetLeft = HEADER_BACK_BUTTON_DEFAULT_INSET,
}: HeaderBackButtonProps): JSX.Element => {
  const Icon = iconType === 'arrow' ? ArrowLeftIcon : ChevronLeft;
  return (
    <Box is="div" left={-insetLeft} className={`flex absolute`}>
      <Box
        is="button"
        data-testid="panel__back-button"
        className="focus:outline-none focus-visible:ring"
        aria-label="Go back"
        onClick={handleClick}
      >
        <Icon.View {...Icon.size} />
      </Box>
    </Box>
  );
};

const Body = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Box
      is="div"
      className="
      flex flex-col items-center
      w-full"
    >
      {children}
    </Box>
  );
};

const Footer = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Box is="footer" className="flex flex-col items-center w-full mt-6">
      {children}
    </Box>
  );
};

const Item = ({
  testId,
  children,
  label,
  divider,
  className,
  ...props
}: PanelItemProps): JSX.Element => {
  const wrapperStyle = classnames(
    'flex flex-col items-center w-full mt-5 sm:mt-4 first:mt-0',
    className
  );
  const innerStyle = 'flex flex-row items-center justify-between w-full';

  const [firstChild, ...otherChildren] = React.Children.toArray(children);

  return (
    <Box is="div" data-testid={testId} className={wrapperStyle} {...props}>
      <Box is="div" className={innerStyle}>
        <Box is="div">
          {label && (
            <Box className="mb-2" data-testid="panel__item_label">
              {label}
            </Box>
          )}
          <Box
            is="div"
            className="text-body-3 sm:text-body-1 text-gray-900 font-regular"
          >
            {firstChild}
          </Box>
        </Box>
        {otherChildren}
      </Box>
      {divider ? <Divider className="hidden sm:block mt-4" /> : null}
    </Box>
  );
};

Wrapper.displayName = 'SectionPanel.Wrapper';
Header.displayName = 'SectionPanel.Header';
Body.displayName = 'SectionPanel.Body';
Footer.displayName = 'SectionPanel.Footer';
Item.displayName = 'SectionPanel.Item';

export default { Wrapper, Header, Body, Footer, Item };
