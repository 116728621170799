import React from 'react';

import { status } from '../../colors.cjs.js';

const size = {
  width: '20px',
  height: '20px',
};

const ErrorIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={status.error}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M19.6 10A9.6 9.6 0 11.4 10a9.6 9.6 0 0119.2 0zm-8.4 4.8a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0zM10 4a1.2 1.2 0 00-1.2 1.2V10a1.2 1.2 0 002.4 0V5.2A1.2 1.2 0 0010 4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const Asset = {
  size,
  View: ErrorIcon,
};
export default Asset;
