import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import type { Ref } from 'react';

import { CheckBox } from 'designSystem/atoms';
import { Box, getThemeObject, Theme } from 'designSystem/ions';

type CheckBoxProps = ComponentPropsWithoutRef<typeof CheckBox>;

export type CheckBoxFormProps = Omit<CheckBoxProps, 'onClick' | 'color'> & {
  onPressCheckBox: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  labelText: string;
  theme?: Theme;
};

export const CheckBoxForm = (props: CheckBoxFormProps): JSX.Element => {
  const { id, theme = 'primary', checked, onPressCheckBox, labelText } = props;
  const checkBoxColor = useMemo(() => getThemeObject(theme).color.normal, [
    theme,
  ]);

  return (
    <Box is="label" htmlFor={id} className="flex cursor-pointer">
      <Box
        is="div"
        className="
        flex flex-col flex-initial
        items-center justify-center
        py-2px mr-3"
      >
        <CheckBox
          id={id}
          color={checkBoxColor}
          checked={checked}
          onClick={onPressCheckBox}
        />
      </Box>
      <Box is="div" className="flex flex-col items-start">
        <Box is="span" className="text-body-1 font-regular text-gray-900">
          {labelText}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CheckBoxForm);
