/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQueryClient, UseQueryOptions } from 'react-query';

import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { clearStoredData } from './root.helper';

import { useMyAccount, Account } from 'shared/data/account';

import { CustomAxiosError } from 'core/axios';
import { sessionStorageService } from 'core/storage';
import { KEY_I18NEXT_LNG } from 'shared/constants';

export function useClearStoredDataAsync(milliseconds = 100) {
  const queryClient = useQueryClient();
  return useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      clearStoredData({ queryClient });
    }, milliseconds);
    return () => {
      clearTimeout(timer);
    };
  }, [milliseconds, queryClient]);
}

export function useClearStoredData() {
  const queryClient = useQueryClient();
  clearStoredData({ queryClient });
}

export function useAccountCheck(
  options:
    | UseQueryOptions<Account, CustomAxiosError<unknown>, Account>
    | undefined = {}
) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { i18n } = useTranslation();
  return useMyAccount({
    retry: false,
    ...options,
    onError: (error) => {
      if (error.response?.status) {
        if (error.response?.status === 401) {
          setTimeout(() => {
            clearStoredData({ queryClient });
            window.location.reload();
          }, 200);
          return;
        }
      }
      return history.replace('/account/error');
    },
    onSuccess: (data) => {
      const { language } = data;
      if (language) {
        const cachedLanguage = sessionStorageService.getItem(KEY_I18NEXT_LNG);
        if (!cachedLanguage) {
          i18n.changeLanguage(language);
        }
      }
    },
  });
}
