/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AuthRoute from './AuthRoute';

import EnterpriseAccountRouter from './EnterpriseAccountRouter';

import pages from 'pages';

function DeleteAccount({ match }: RouteComponentProps) {
  return (
    <Switch>
      <AuthRoute exact path={match.path} component={pages.DeleteConfirmPage} />
      <Route
        exact
        path={`${match.path}/success`}
        component={pages.DeleteSuccessPage}
      />
      <Route
        exact
        path={`${match.path}/error`}
        component={pages.TemporaryErrorPage}
      />
      <Route component={pages.NotFoundErrorPage} />
    </Switch>
  );
}

function Account({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route exact path={match.path}>
        <Redirect to={`${match.path}/delete`} />
      </Route>
      <AuthRoute
        exact
        path={`${match.path}/personal-info`}
        component={pages.PersonalInfoPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/info`}
        component={pages.AccountInfoPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/link-child`}
        component={pages.LinkChildPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/link-child-by-url`}
        component={pages.LinkChildByUrlPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/link-guardian`}
        component={pages.LinkGuardianPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/child-code`}
        component={pages.ChildCodePage}
      />
      <AuthRoute
        exact
        path={`${match.path}/student-code`}
        component={pages.StudentCodePage}
      />
      <Route path={`${match.path}/delete`} component={DeleteAccount} />
      <AuthRoute
        exact
        path={`${match.path}/change-password`}
        component={pages.ChangePasswordPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/change-email`}
        component={pages.ChangeEmailPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/change-mobile`}
        component={pages.ChangeMobilePage}
      />
      <AuthRoute
        exact
        path={`${match.path}/marketing-agreement-notice`}
        component={pages.MarketingAgreementNoticePage}
      />
      <Route path={`${match.path}/delete`} component={DeleteAccount} />
      <Route
        exact
        path={`${match.path}/callback`}
        component={pages.AuthCallbackPage}
      />
      <Route
        exact
        path={`${match.path}/challenge-redirect`}
        component={pages.ChallengeRedirectPage}
      />
      <Route
        exact
        path={`${match.path}/challenge-callback`}
        component={pages.ChallengeCallbackPage}
      />
      <Route
        exact
        path={`${match.path}/error`}
        component={pages.TemporaryErrorPage}
      />
      <Route
        exact
        path={`${match.path}/guardian-connect`}
        component={pages.GuardianConnectPage}
      />
      <AuthRoute
        exact
        path={`${match.path}/marketing-agreement`}
        component={pages.MarketingAgreementPage}
      />
      <Route component={pages.NotFoundErrorPage} />
    </Switch>
  );
}

const RootRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/account" />
      </Route>
      <Route path="/account" component={Account} />
      <Route path="/enterprise/account" component={EnterpriseAccountRouter} />
      <Route component={pages.NotFoundErrorPage} />
    </Switch>
  );
};

export default RootRouter;
