const palette = require('./palette.cjs')
module.exports = {
  transparent: 'transparent',
  current: 'currentColor',
  primary: {
    normal: palette.classtingGreen[500],
    dark: palette.classtingGreen[700],
  },
  danger: {
    normal: palette.classtingRed[500],
    dark: palette.classtingRed[700],
  },
  status: {
    success: palette.classtingGreen[500],
    info: palette.classtingBlue[500],
    warn: palette.classtingOrange[500],
    error: palette.classtingRed[500],
  },
  ctGreen: palette.classtingGreen,
  ctRed: palette.classtingRed,
  gray: palette.gray,
  black: palette.black,
  white: palette.white,
  red: palette.red,
  yellow: palette.amber,
  green: palette.emerald,
  blue: palette.blue,
  indigo: palette.indigo,
  purple: palette.violet,
  pink: palette.pink,
}
