import React, { useState } from 'react';

import Box from 'ui-box/dist/src/box';

import { useHistory } from 'react-router-dom';

import VerifyChildCode from './contents/VerifyChildCode';
import SetGuardianRelationship from './contents/SetGuardianRelationship';
import ChildCodeUnverified from './contents/ChildCodeUnverified';
import ChildCodeExpired from './contents/ChildCodeExpired';

import SelectLinkMethod from './contents/SelectLinkMethod';

import { ChildCodeResponseDto } from 'generated/graphql';

interface LinkChildDialogContentProps {
  guardianId: string;
  accountId: string | null;
  onLinkCancel: () => void;
  onLinkSuccess: () => void;
}

enum LinkChildDialogContentType {
  SelectLinkMethod = 'select-link-method',
  VerifyChildCode = 'verify-child-code',
  SetRelationship = 'set-relationship',
  UnverifiedCode = 'unverified-code',
  Expired = 'expired',
}

const LinkChildDialogContent = ({
  guardianId,
  accountId,
  onLinkCancel: handleLinkCancel,
  onLinkSuccess: handleLinkSuccess,
}: LinkChildDialogContentProps): JSX.Element => {
  const history = useHistory();

  const [childInfo, setChildInfo] = useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  const [content, setContent] = useState(
    LinkChildDialogContentType.SelectLinkMethod
  );

  const resetDialog = () => {
    setChildInfo({ id: '', name: '' });
    setContent(LinkChildDialogContentType.SelectLinkMethod);
  };

  const handleChildCodeVerified = (childInfo: ChildCodeResponseDto) => {
    if (childInfo.childName && childInfo.childId) {
      setChildInfo({ name: childInfo.childName, id: childInfo.childId });
    }
    setContent(LinkChildDialogContentType.SetRelationship);
  };

  const handleSuccess = () => {
    resetDialog();
    handleLinkSuccess();
  };

  const handleExpiredError = () => {
    setContent(LinkChildDialogContentType.Expired);
  };

  const handleUnverifiedError = () => {
    setContent(LinkChildDialogContentType.UnverifiedCode);
  };

  const handleRetryClick = () => {
    resetDialog();
  };

  const handleCancelClick = () => {
    resetDialog();
    handleLinkCancel();
  };

  const closeDialog = () => {
    resetDialog();
    handleLinkCancel();
  };

  const handleClickMethod = (method: 'url' | 'code') => {
    if (method === 'url') {
      history.push('/account/link-child-by-url');
    } else if (method === 'code') {
      setContent(LinkChildDialogContentType.VerifyChildCode);
    }
  };

  return (
    <Box>
      {content === LinkChildDialogContentType.SelectLinkMethod ? (
        <SelectLinkMethod
          accountId={accountId}
          onClickMethod={handleClickMethod}
          closeDialog={closeDialog}
        />
      ) : content === LinkChildDialogContentType.VerifyChildCode ? (
        <VerifyChildCode
          guardianId={guardianId}
          onVerified={handleChildCodeVerified}
          onCancelClick={handleCancelClick}
        />
      ) : content === LinkChildDialogContentType.SetRelationship ? (
        <SetGuardianRelationship
          onSuccess={handleSuccess}
          onExpiredError={handleExpiredError}
          onUnverifiedError={handleUnverifiedError}
          onCancelClick={handleCancelClick}
          childId={childInfo.id}
          childName={childInfo.name}
        />
      ) : content === LinkChildDialogContentType.UnverifiedCode ? (
        <ChildCodeUnverified onRetryClick={handleRetryClick} />
      ) : content === LinkChildDialogContentType.Expired ? (
        <ChildCodeExpired onRetryClick={handleRetryClick} />
      ) : (
        // istanbul ignore next
        <></>
      )}
    </Box>
  );
};

export default LinkChildDialogContent;
