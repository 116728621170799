/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function NaverIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={16} height={16} rx={4} fill="#1EC800" />
      <path
        d="M9.27 4.335v3.698L6.74 4.335H4v7.33h2.727V7.966l2.534 3.699H12v-7.33H9.27z"
        fill="#fff"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(NaverIcon),
};

export default Asset;
