/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  CreateImageRecordParam,
  CreateImageRecordResponse,
  UploadImageParam,
} from './media-service.model';

import { create } from 'core/axios';
import { API_URL } from 'shared/constants';

const axiosInstance = create({
  baseURL: `${API_URL}/media-service`,
});

const axiosWithoutAuthInstance = create({}, { auth: false });

export async function createImageRecord(payload: CreateImageRecordParam) {
  const response = await axiosInstance.post<CreateImageRecordResponse>(
    '/images',
    payload
  );
  return response.data;
}

export async function uploadImage({ presignedUrl, data }: UploadImageParam) {
  await axiosWithoutAuthInstance.post<void>(presignedUrl, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
