/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  keywords?: string;
  desc?: string;
  title: string;
  thumbnail?: string;
}

const EnterpriseHelmet = ({
  keywords,
  desc,
  title,
  thumbnail,
}: Props): ReactElement => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      {desc && <meta name="description" content={desc} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {thumbnail && <meta property="og:image" content={thumbnail} />}
      <meta property="og:site_name" content={title} />
      <meta property="og:type" content="website" />
      {desc && <meta property="og:description" content={desc} />}
      <meta name="twitter:title" content={title} />
      {desc && <meta name="twitter:description" content={desc} />}
      {thumbnail && <meta name="twitter:image" content={thumbnail} />}
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

export default React.memo(EnterpriseHelmet);
