import React from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import { BoxIndicator } from '@classtinginc/design-system';

import { Helmet } from 'react-helmet-async';

import useTranslatedTexts from './useTranslatedTexts';
import ChildCodePageContent from './ChildCodePageContent';

import { SectionPanel } from 'designSystem/organisms';
import { EnterpriseSideMenuPageTemplate } from 'designSystem/templates';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import EnterpriseAppBar from 'designSystem/organisms/EnterpriseAppBar';
import {
  EnterpriseAppBarEnterpriseDtoFieldsFragment,
  EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment,
  useChildCodePageQuery,
} from 'generated/graphql';
import ForbiddenErrorPage from 'pages/ErrorPages/ForbiddenErrorPage';

export const ChildCodePage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const texts = useTranslatedTexts();
  const isAllowed = account.role === 'student';

  const { data: { enterprise } = {}, loading } = useChildCodePageQuery({
    variables: {
      enterpriseId: account?.enterprise?.id ?? '',
    },
    skip: !account?.enterprise,
  });

  const faviconUrl =
    enterprise?.isWhiteLabeled && enterprise?.branding?.appIconUrl
      ? enterprise?.branding?.appIconUrl
      : `${process.env.PUBLIC_URL}/favicon.ico`;

  const renderContent = () => {
    if (!isAllowed) {
      return <Redirect to="/not-found" />;
    }

    return <ChildCodePageContent childId={account.id} />;
  };

  if (!account.enterprise) {
    return <ForbiddenErrorPage />;
  }

  if (loading) {
    return <BoxIndicator />;
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <EnterpriseSideMenuPageTemplate
        account={account}
        title={texts.pageTitle}
        enterprise={
          enterprise as EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment
        }
        headerChild={
          <EnterpriseAppBar
            account={account}
            enterprise={
              enterprise as EnterpriseAppBarEnterpriseDtoFieldsFragment
            }
          />
        }
      >
        <SectionPanel.Wrapper
          testId="link-guardian-panel"
          className="w-full pt-8"
        >
          <SectionPanel.Body>{renderContent()}</SectionPanel.Body>
        </SectionPanel.Wrapper>
      </EnterpriseSideMenuPageTemplate>
    </>
  );
};

export default withAccount(ChildCodePage);
