import React from 'react';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';

import { useMyAccount, Account } from 'shared/data/account';

import { Box } from 'designSystem/ions';
import { ProfileImage } from 'designSystem/atoms';
import { SimpleCard } from 'designSystem/organisms';

const SkeletonAccountCard = (): JSX.Element => (
  <SimpleCard.Wrapper
    testId="my-account-card--skeleton"
    className="animate-pulse"
  >
    <SimpleCard.Header title="account_name_skeleton" />
    <SimpleCard.Body>
      <SimpleCard.Left>
        <Box is="div" className="flex flex-col w-full items-start">
          <Box is="div" className="w-2/4 h-6 bg-gray-300 mb-2" />
          <Box is="div" className="w-11/12 h-4 bg-gray-300 mb-3" />
          <Box is="div" className="w-11/12 h-4 bg-gray-300" />
        </Box>
      </SimpleCard.Left>
      <SimpleCard.Right>
        <Box
          is="div"
          data-testid="my-account-card__profile--skeleton"
          className="min-w-12 w-12 h-12 rounded-full bg-gray-300"
        />
      </SimpleCard.Right>
    </SimpleCard.Body>
  </SimpleCard.Wrapper>
);

export const MyAccountCardPresenter = ({
  account,
}: {
  account: Account;
}): JSX.Element => {
  const { t } = useTranslation();
  const joined = t('account.account_info.joined_at', {
    year: dayjs(account.signedUpAt).year(),
    month: dayjs(account.signedUpAt).month() + 1,
    day: dayjs(account.signedUpAt).date(),
  });
  const headerTitle = account.name ?? '계정 정보';
  return (
    <SimpleCard.Wrapper testId={'my-account-card'} className="ld ld-fade-in">
      <SimpleCard.Header title={headerTitle} />
      <SimpleCard.Body>
        <SimpleCard.Left>
          <Box is="div" className="flex flex-col w-full items-start">
            <Box
              is="p"
              data-testid="my-account-card__name"
              className="w-full text-body-1 font-bold text-gray-900 break-all whitespace-pre-line"
            >
              {account.name ?? ''}
            </Box>
            <Box
              is="p"
              data-testid="my-account-card__email"
              className="w-full text-body-3 font-regular text-gray-900 break-all whitespace-pre-line"
            >
              {account.email ?? ''}
            </Box>
          </Box>
          <Box is="div" className="flex flex-col w-full items-start pt-3">
            <Box is="p" className="text-caption font-regular text-gray-700">
              {joined}
            </Box>
          </Box>
        </SimpleCard.Left>
        <SimpleCard.Right>
          <ProfileImage src={account.profileImage || ''} />
        </SimpleCard.Right>
      </SimpleCard.Body>
    </SimpleCard.Wrapper>
  );
};

export const MyAccountCardContainer = (): JSX.Element => {
  const { data: account, isSuccess } = useMyAccount();
  if (isSuccess && account) {
    return <MyAccountCardPresenter account={account} />;
  }

  return <SkeletonAccountCard />;
};

export default React.memo(MyAccountCardContainer);
