/* istanbul ignore file */
import React from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { Box } from 'designSystem/ions';
import { XIcon } from 'designSystem/ions/Icons/solid';

const overlayStyle = `
fixed inset-0
bg-black opacity-40
`;

const contentStyle = `
fixed inset-0
flex flex-col items-center justify-center
p-4
`;

const contentBodyStyle = `
w-full
flex flex-col gap-4
p-6
bg-white rounded-lg
`;

interface HoverDialogContentProps {
  title?: string;
  description?: string;
  testId?: string;
  onCloseClick?: () => void;
}

const HoverDialogContent: React.FC<HoverDialogContentProps> = ({
  title,
  description,
  testId,
  onCloseClick: handleCloseClick,
  children,
}): JSX.Element => {
  return (
    <>
      <Dialog.Overlay className={overlayStyle} />
      <Dialog.Content className={contentStyle}>
        <Box
          data-testid={testId}
          maxWidth="25.375rem"
          className={contentBodyStyle}
        >
          <Header title={title} onCloseClick={handleCloseClick} />
          <Box className="flex flex-col gap-4">
            {description && (
              <Box
                className="text-gray-800 whitespace-pre-line"
                data-testid="hover-dialog-content-description"
              >
                {description}
              </Box>
            )}
            {children}
          </Box>
        </Box>
      </Dialog.Content>
    </>
  );
};

interface HeaderProps {
  title?: string;
  onCloseClick?: () => void;
}

const Header = ({ title, onCloseClick }: HeaderProps): JSX.Element => {
  if (!title && !onCloseClick) {
    return <></>;
  }

  return (
    <Box
      data-testid="hover-dialog-content-header"
      className="flex flex-row justify-between"
    >
      <Box
        data-testid="hover-dialog-content-title"
        className="text-gray-900 leading-6 text-20 font-bold"
      >
        {title}
      </Box>
      {onCloseClick && (
        <Box
          data-testid="child-info-dialog-close-button"
          is="button"
          className="focus:outline-none focus-visible:ring"
          onClick={onCloseClick}
        >
          <XIcon.View
            className="text-gray-700"
            width={XIcon.size.width}
            height={XIcon.size.height}
          />
        </Box>
      )}
    </Box>
  );
};

export default HoverDialogContent;
