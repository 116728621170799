import React, { ReactElement } from 'react';

import {
  Box as CDSBox,
  Tag,
  Text,
  RadioGroup,
  BoxIndicator,
} from '@classtinginc/design-system';

import useTranslatedTexts from '../../useTranslatedTexts';

import {
  OrganizationInputAccountFieldsFragment,
  OrganizationInputOrganizationFieldsFragment,
  useOrganizationInputQuery,
} from 'generated/graphql';
import { Box } from 'designSystem/ions';
import { Label } from 'designSystem/atoms';

type OrganizationInputProps = {
  account: OrganizationInputAccountFieldsFragment;
  primaryOrganization:
    | OrganizationInputOrganizationFieldsFragment
    | undefined
    | null;
  onChangeOrganization: (
    organization: OrganizationInputOrganizationFieldsFragment
  ) => void;
};

const OrganizationInput = ({
  account,
  primaryOrganization,
  onChangeOrganization,
}: OrganizationInputProps): ReactElement => {
  const texts = useTranslatedTexts();
  const { data: { organizations } = {}, loading } = useOrganizationInputQuery({
    fetchPolicy: 'cache-and-network',
  });

  const accountSchool = account.schoolInfo
    ?.school as OrganizationInputOrganizationFieldsFragment;
  const list = (accountSchool ? [accountSchool] : []).concat(
    (organizations ?? []) as OrganizationInputOrganizationFieldsFragment[]
  );
  const orgList = list.filter(
    (item, index) => item && list.findIndex((i) => i.id === item.id) === index
  );

  const handleChange = (id: string) => {
    const selectedOrg = orgList.find((org) => org.id === id);
    onChangeOrganization(
      selectedOrg as OrganizationInputOrganizationFieldsFragment
    );
  };

  if (loading) {
    return <BoxIndicator />;
  }

  if (orgList.length === 0) {
    return (
      <Box className="mt-5 sm:mt-6">
        <Label>{texts.label.organizations}</Label>
        <Box className="mt-1">
          <Text size="80" weight="medium" color="disabled">
            {texts.message.emptyOrganizations}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="mt-5 sm:mt-6">
      <Label>{texts.label.organizations}</Label>
      <Box className="mt-1">
        {!primaryOrganization && (
          <Text size="80" weight="medium" color="disabled">
            {texts.message.selectPrimaryOrganization}
          </Text>
        )}
        <RadioGroup
          defaultValue={primaryOrganization?.id}
          onValueChange={handleChange}
        >
          {orgList.map((org) => {
            return (
              <CDSBox
                key={org.id}
                css={{
                  position: 'relative',
                  display: 'flex',
                  padding: '$04 $04 $04 $00',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <RadioGroup.Radio
                  size="lg"
                  value={org.id}
                  id={org.id}
                  css={{
                    marginTop: '0.1875rem',
                    cursor: 'pointer',
                  }}
                  data-testid={`${org.name}-role-radio`}
                />
                <Text
                  as="label"
                  htmlFor={org.id}
                  size="100"
                  color="dark"
                  weight="regular"
                  css={{
                    marginLeft: '$04',
                    cursor: 'pointer',
                    overflow: 'hidden',
                  }}
                >
                  {org.name}
                </Text>
                {primaryOrganization?.id === org.id && (
                  <Tag size="xs" tint="ctGreen" css={{ marginLeft: '$02' }}>
                    {texts.tag.primaryOrganization}
                  </Tag>
                )}
              </CDSBox>
            );
          })}
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default OrganizationInput;
