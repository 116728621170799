import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { useModalContext, ModalStatus } from './ModalContext';

import { ErrorIcon } from 'designSystem/ions/Icons/solid';
import { Button } from 'designSystem/atoms';
import { Box, useIsInMobile } from 'designSystem/ions';

type Appearance = 'text' | 'outlined' | 'contained';

const getButtonAppearance = (isInMobile: boolean, status: ModalStatus) => {
  let appearance: Appearance = isInMobile ? 'text' : 'outlined';
  if (status === 'success') {
    appearance = 'contained';
  }
  return appearance;
};

const getButtonTheme = (status: ModalStatus) => {
  let theme: 'primary' | 'white';
  if (status === 'success') {
    theme = 'primary';
  } else {
    theme = 'white';
  }

  return theme;
};

const titleIcons = {
  error: ErrorIcon,
  success: ErrorIcon,
};

const ButtonGroup = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Box className="flex ml-auto">
      {React.Children.map(children, (component, index) => {
        return (
          <Box className="mr-3 last:mr-0" key={index}>
            {component}
          </Box>
        );
      })}
    </Box>
  );
};

const SimpleModal = (): JSX.Element | null => {
  const {
    isOpen,
    title,
    status,
    message,
    closeModal,
    buttonGroup,
    onClickButton,
  } = useModalContext();
  const { t } = useTranslation();
  const isInMobile = useIsInMobile();

  const handleClickClose = () => {
    onClickButton?.();
    closeModal();
  };

  const buttonAppearance: Appearance = getButtonAppearance(isInMobile, status);
  const buttonTheme = getButtonTheme(status);
  const TitleIcon = status === 'default' ? null : titleIcons[status];
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={!(process.env.NODE_ENV === 'test')}
      className="
         absolute inset-auto
         p-6 sm:pb-6 pb-4 sm:w-xs-screen w-312px border-none rounded-lg
         shadow-modal bg-white
         overflow-auto outline-none
        "
      overlayClassName="fixed bg-black/50 inset-0 flex justify-center items-center z-50"
      appElement={document.getElementById('root') as HTMLElement}
    >
      <Box data-testid="modal" className="flex flex-col">
        {title && (
          <Box className="mb-3 flex items-center">
            {TitleIcon && (
              <Box className="mr-2.5">
                <TitleIcon.View {...TitleIcon.size} />
              </Box>
            )}
            <Box
              className="text-headline-4 text-gray-800"
              data-testid="modal-title"
            >
              {title}
            </Box>
          </Box>
        )}
        {message && (
          <Box
            className="text-body-3 text-gray-700 sm:mb-8 mb-6 keep-all"
            data-testid="modal-message"
          >
            {message}
          </Box>
        )}
        {buttonGroup ? (
          buttonGroup
        ) : (
          <Box className="ml-auto">
            <Button
              theme={buttonTheme}
              testId="modal-confirm"
              appearance={buttonAppearance}
              onClick={handleClickClose}
            >
              {t('account.button.confirm')}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

SimpleModal.ButtonGroup = ButtonGroup;

export default SimpleModal;
