import React from 'react';

import { Box } from '@classtinginc/design-system';

import { Helmet } from 'react-helmet-async';

import { EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment } from '../../../generated/graphql';

import { WithChildrenProp } from 'types/utilities';

type EnterpriseSingleColumnPageTemplateProps = {
  title: string;
  desc: string;
  enterprise?: EnterpriseSingleColumnPageTemplateEnterpriseDtoFieldsFragment;
};

const EnterpriseSingleColumnPageTemplate = ({
  children,
  title: CTATitle,
  desc,
  enterprise,
}: WithChildrenProp<EnterpriseSingleColumnPageTemplateProps>): JSX.Element => {
  const isWhiteLabeled = enterprise?.isWhiteLabeled;
  const faviconUrl =
    isWhiteLabeled && enterprise?.branding?.appIconUrl
      ? enterprise?.branding?.appIconUrl
      : `${process.env.PUBLIC_URL}/favicon.ico`;

  const title =
    enterprise?.isWhiteLabeled && enterprise?.branding?.name
      ? `${CTATitle} | ${enterprise?.branding?.name}`
      : CTATitle;

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Box as="div" className="h-screen bg-gray-50">
        <Box
          as="div"
          className="flex flex-col items-center justify-center m-auto max-w-full min-h-screen min-w-480px py-12 w-120"
        >
          {!!enterprise && !isWhiteLabeled && (
            <Box
              as="img"
              className="mb-6"
              src={`${process.env.PUBLIC_URL}/classting_wordmark.png`}
            />
          )}
          <Box className="p-10 w-full border border-gray-200 rounded-lg bg-white">
            <Box>
              {enterprise?.branding?.logoUrl && (
                <Box
                  as="div"
                  className="mb-8 flex justify-center max-w-full h-8"
                >
                  <Box
                    as="img"
                    src={enterprise.branding.logoUrl}
                    alt={enterprise?.branding?.name as string}
                  />
                </Box>
              )}
              <Box
                as="h1"
                className="text-headline-2 font-medium text-center text-gray-900"
              >
                {CTATitle}
              </Box>
              <Helmet title={title} />
              <Box
                as="p"
                className="text-body-3 font-regular text-center text-gray-800 mt-4 mb-8"
              >
                {desc}
              </Box>
            </Box>
            <Box as="div">{children}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EnterpriseSingleColumnPageTemplate;
