import React from 'react';

const size = {
  width: '16px',
  height: '16px',
};

const EmailIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="4" fill="#757575" />
      <path
        d="M3.60156 5.73615L7.99991 7.93505L12.3983 5.73615C12.382 5.4559 12.2592 5.19247 12.055 4.99983C11.8508 4.80718 11.5806 4.6999 11.2999 4.69995H4.69991C4.41918 4.6999 4.14906 4.80718 3.94486 4.99983C3.74066 5.19247 3.61784 5.4559 3.60156 5.73615Z"
        fill="white"
      />
      <path
        d="M12.4001 6.96484L8.0001 9.16484L3.6001 6.96484V10.1999C3.6001 10.4917 3.71599 10.7715 3.92228 10.9778C4.12857 11.1841 4.40836 11.2999 4.7001 11.2999H11.3001C11.5918 11.2999 11.8716 11.1841 12.0779 10.9778C12.2842 10.7715 12.4001 10.4917 12.4001 10.1999V6.96484Z"
        fill="white"
      />
    </svg>
  );
};

const Asset = {
  size,
  View: EmailIcon,
};
export default Asset;
