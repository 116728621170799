import React from 'react';
import classNames from 'classnames';

import { Redirect } from 'react-router-dom';

import AgreeToLink from './contents/AgreeToLink';

import SetGuardianRelationship from './contents/SetGuardianRelationship';

import { Box } from 'designSystem/ions';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { WebViewBridge } from 'shared/webViewBridge';
import { useLinkGuardianPageContentQuery } from 'generated/graphql';
import { useHistoryBackHandler } from 'shared/hooks/history';

enum LinkGuardianPageContentType {
  AgreeToLink,
  SetGuardianRelationship,
}

interface LinkGuardianPageContentProps {
  guardianId: string;
  accountId: string;
}

const LinkGuardianPageContent = ({
  guardianId,
  accountId,
}: LinkGuardianPageContentProps): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const history = useHistoryBackHandler();

  const [contentType, setContentType] = React.useState(
    LinkGuardianPageContentType.AgreeToLink
  );

  const { data, error, loading } = useLinkGuardianPageContentQuery({
    variables: {
      guardianId: guardianId,
    },
  });

  const guardianAccount = data?.getAccount;

  const isNonExistingGuardian =
    error?.graphQLErrors[0]?.extensions?.statusCode === 404 ||
    guardianAccount === undefined ||
    guardianAccount === null;

  const handleClickNext = () => {
    setContentType(LinkGuardianPageContentType.SetGuardianRelationship);
  };

  const goBack = () => {
    if (isClasstingMobileApp) {
      WebViewBridge.requestNavigationClose();
    } else {
      history.go(-1);
    }
  };

  const handleClickCancel = async () => {
    goBack();
  };

  if (loading) {
    return <></>;
  }

  if (isNonExistingGuardian) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Box
      data-testid="link-child-page-content"
      className={classNames('flex flex-col w-full mt-30', {
        'gap-2': isClasstingMobileApp,
        'gap-4': !isClasstingMobileApp,
      })}
    >
      {contentType === LinkGuardianPageContentType.AgreeToLink ? (
        <AgreeToLink
          guardianAccount={guardianAccount}
          accountId={accountId}
          onClickNext={handleClickNext}
          onClickCancel={handleClickCancel}
        />
      ) : (
        <SetGuardianRelationship
          guardianAccount={guardianAccount}
          accountId={accountId}
        />
      )}
    </Box>
  );
};

export default LinkGuardianPageContent;
