import React, { useState } from 'react';

import classNames from 'classnames';

import { ProfileImage } from 'designSystem/atoms';
import { Box } from 'designSystem/ions';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { ShowMore } from 'designSystem/ions/Icons/solid';
import { TagManagerEventDefinition } from 'shared/analytics/utils';
import { track } from 'shared/analytics';

const menuStyle = `
  absolute right-0 top-0
  flex flex-col justify-center
  bg-white shadow-md rounded-lg
`;

interface ProfileListItemProps {
  profileImage: string;
  username: string;
  description: string;
  bordered?: boolean;
  testid?: string;
  children?: React.ReactNode;
  menuItems?: MenuItemProps[];
}

const ProfileListItem = ({
  profileImage,
  username,
  description,
  bordered = false,
  testid,
  children,
  menuItems,
}: ProfileListItemProps): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();

  return (
    <Box
      data-testid={testid}
      className={classNames('flex flex-col p-4 gap-y-4 w-full', {
        'border border-gray-200 rounded-lg': isClasstingMobileApp || bordered,
      })}
    >
      <Box className="flex flex-row justify-between">
        <Box className="flex flex-row gap-x-2">
          <Box data-testid="profile-list-item-image">
            <ProfileImage src={profileImage} />
          </Box>
          <Box>
            <Box data-testid="profile-list-item-name" className="font-medium">
              {username}
            </Box>
            <Box
              data-testid="profile-list-item-description"
              className="text-14 leading-normal text-gray-600"
            >
              {description}
            </Box>
          </Box>
        </Box>
        {menuItems && <Menu items={menuItems} />}
      </Box>
      {children}
    </Box>
  );
};

interface MenuProps {
  items: MenuItemProps[];
}

const Menu = ({ items }: MenuProps) => {
  const [itemsVisible, setItemsVisible] = useState(false);
  const handleRootClick = () => {
    setItemsVisible(!itemsVisible);
  };

  const handleRootBlur = () => {
    setItemsVisible(false);
  };

  const createMenuItemClickHandler = (
    onMenuItemClick?: () => void,
    event?: TagManagerEventDefinition
  ) => () => {
    if (itemsVisible) {
      setItemsVisible(false);
    }

    if (onMenuItemClick) {
      onMenuItemClick();
    }

    if (event) {
      track(event);
    }
  };

  return (
    <Box
      className="relative"
      width={ShowMore.size.width}
      height={ShowMore.size.height}
    >
      <Box
        data-testid="profile-list-item-menu-root"
        is="button"
        onClick={handleRootClick}
        onBlur={handleRootBlur}
      >
        <ShowMore.View width="100%" height="100%" />
      </Box>
      {itemsVisible && (
        <Box className="relative absolute">
          <Box className={menuStyle}>
            {items.map((menuItem) => (
              <MenuItem
                key={menuItem.name}
                name={menuItem.name}
                onClick={createMenuItemClickHandler(
                  menuItem.onClick,
                  menuItem.event
                )}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface MenuItemProps {
  name: string;
  onClick?: () => void;
  event?: TagManagerEventDefinition;
}

const MenuItem = ({
  name,
  onClick: handleClick,
}: MenuItemProps): JSX.Element => {
  return (
    <Box
      data-testid="profile-list-item-menu-item"
      className="w-32 h-14 px-4"
      is="button"
      onMouseDown={handleClick}
    >
      <Box className="text-14 leading-normal text-gray-900">{name}</Box>
    </Box>
  );
};

export default ProfileListItem;
