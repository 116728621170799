import TemporaryErrorPage from './TemporaryErrorPage';
import NotFoundErrorPage from './NotFoundErrorPage';
import ForbiddenErrorPage from './ForbiddenErrorPage';

const ErrorPages = {
  TemporaryErrorPage,
  NotFoundErrorPage,
  ForbiddenErrorPage,
};

export default ErrorPages;
