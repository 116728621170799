import React, { useState } from 'react';
import classNames from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';

import LinkedChildList from './LinkedChildList';
import LinkChildButton from './LinkChildButton';

import LinkChildDialogContent from './LinkChildDialogContent';

import { Box, useIsInMobile } from 'designSystem/ions';
import {
  GuardianRelationshipDto,
  useLinkChildPageContentQuery,
} from 'generated/graphql';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { getCredentialAccountId } from 'core/credential/credential.service';

const LINKED_CHILD_LIST_FETCH_UNIT = 10;

interface LinkChildPageContentProps {
  guardianId: string;
}

const LinkChildPageContent = ({
  guardianId,
}: LinkChildPageContentProps): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const isMobile = useIsInMobile();
  const accountId = getCredentialAccountId();

  const [isLinkChildDialogVisible, setIsLinkChildDialogVisible] = useState(
    false
  );

  const { data } = useLinkChildPageContentQuery({
    variables: {
      limit: LINKED_CHILD_LIST_FETCH_UNIT,
    },
  });

  const guardianRelationships = (data?.listGuardianRelationships ||
    []) as GuardianRelationshipDto[];

  const hasLinkedChild = guardianRelationships.length > 0;

  const handleLinkChildClick = () => {
    setIsLinkChildDialogVisible(true);
  };

  const handleLinkChildDialogCancelClick = () => {
    setIsLinkChildDialogVisible(false);
  };

  const handleLinkSuccess = () => {
    setIsLinkChildDialogVisible(false);
  };

  return (
    <Box
      data-testid="link-child-page-content"
      className={classNames('flex flex-col w-full mt-30', {
        'gap-2': isClasstingMobileApp,
        'gap-4': !isClasstingMobileApp,
        'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2':
          isMobile && !hasLinkedChild,
      })}
    >
      <LinkedChildList relationships={guardianRelationships} />
      <Box
        className={classNames('w-full flex flex-row', {
          'justify-end': hasLinkedChild,
          'justify-center': !hasLinkedChild || isMobile,
        })}
      >
        <LinkChildButton
          appearance={hasLinkedChild ? 'text' : 'contained'}
          onClick={handleLinkChildClick}
          event={{
            name: 'LinkChildStarted',
            on: 'click',
            properties: {
              userId: accountId,
            },
          }}
        />
      </Box>
      <Dialog.Root open={isLinkChildDialogVisible}>
        <LinkChildDialogContent
          guardianId={guardianId}
          accountId={accountId}
          onLinkSuccess={handleLinkSuccess}
          onLinkCancel={handleLinkChildDialogCancelClick}
        />
      </Dialog.Root>
    </Box>
  );
};

export default LinkChildPageContent;
