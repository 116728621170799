import React from 'react';

import SignInMethodIcon from '../SignInMethodIcon';

import { Box } from 'designSystem/ions';
import { Divider } from 'designSystem/atoms';
import { SignInMethod } from 'generated/graphql';
import useTranslatedTexts from 'pages/InfoPages/LinkChildPage/useTranslatedTexts';
import { HoverDialogContent } from 'designSystem/molecules/HoverDialogContent';

interface ChildInfoDialogContentProps {
  childId: string;
  email: string;
  mobile: string;
  name: string;
  signInMethods: SignInMethod[];
  onCloseDialogRequest: () => void;
}

const infoLabelStyle = 'text-12 leading-4 text-gray-600';
const infoTextStyle = 'text-14 leading-5 text-gray-800 break-all';

const ChildInfoDialogContent = ({
  childId: id,
  email,
  mobile,
  name,
  signInMethods,
  onCloseDialogRequest: handleCloseDialogRequest,
}: ChildInfoDialogContentProps): JSX.Element => {
  const texts = useTranslatedTexts();

  return (
    <HoverDialogContent
      onCloseClick={handleCloseDialogRequest}
      testId="child-info-dialog-content"
      title={name}
    >
      <Box
        className="grid gap-x-3 gap-y-1 items-center"
        gridTemplateColumns="max-content auto"
      >
        <Box className={infoLabelStyle}>{texts.label.email}</Box>
        <Box
          data-testid="child-info-dialog-child-email"
          className={infoTextStyle}
        >
          {email || texts.message.none}
        </Box>
        <Box className={infoLabelStyle}>{texts.label.mobile}</Box>
        <Box
          data-testid="child-info-dialog-child-mobile"
          className={infoTextStyle}
        >
          {mobile || texts.message.none}
        </Box>
        <Box className={infoLabelStyle}>{texts.label.username}</Box>
        <Box data-testid="child-info-dialog-child-id" className={infoTextStyle}>
          {id}
        </Box>
      </Box>

      <Divider />

      <Box className="flex flex-col gap-2">
        <Box className={`${infoTextStyle} font-medium`}>
          {texts.label.signInMethod}
        </Box>
        <Box
          data-testid="child-info-dialog-child-signin-methods"
          className="flex flex-col gap-1"
        >
          {signInMethods.map((method) => (
            <Box className="flex flex-row items-center gap-2" key={method}>
              <SignInMethodIcon
                testId={`child-info-dialog-signin-methods-${method}-icon`}
                method={method}
              />
              <Box
                data-testid={`child-info-dialog-signin-methods-${method}-description`}
                className="text-14 text-gray-800"
              >
                {texts.signInMethod[method]}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        data-testid="child-info-dialog-guide-text"
        className="text-12 text-gray-600"
      >
        {texts.message.signInMethod}
      </Box>
    </HoverDialogContent>
  );
};

export default ChildInfoDialogContent;
