import * as React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const CheckboxCircleIcon = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666Zm-.83-5 5.891-5.892-1.178-1.178-4.714 4.714-2.357-2.358-1.178 1.179 3.535 3.535Z"
      fill="#00C896"
    />
  </svg>
);

const Asset = {
  size,
  View: CheckboxCircleIcon,
};
export default Asset;
