/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

import { SignInMethod } from 'generated/graphql';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const title = {
    linkChild: t('account.link_child.title'),
    failedToLinkChild: t('account.error.title.failed_to_link_child'),
    selectRelationship: t('account.select_relationship.title'),
  };

  const description = {
    linkChild: t('account.link_child.description'),
    selectRelationship(childName: string) {
      return t('account.select_relationship.description', {
        name: childName,
      });
    },
    linkChildSelectMethod: t('account.link_child.select_method.description'),
  };

  const button = {
    cancel: t('account.button.cancel'),
    confirm: t('account.button.confirm'),
    linkChild: t('account.button.link_child'),
    unlinkChild: t('account.button.unlink_child'),
    retry: t('account.button.retry'),
    link: t('account.link_child.button.link'),
    accountInfo: t('account.my_child.button.account_info'),
    linkChildByUrl: t('account.link_child.select_method.button.by_url'),
    linkChildByChildCode: t(
      'account.link_child.select_method.button.by_child_code'
    ),
  };

  const error = {
    invalidChildCode: t('account.error.message.invalid_child_code'),
    emptyChildCode: t('account.error.message.empty_child_code'),
    invalidRequestLinkChild: t(
      'account.error.message.invalid_request_link_child'
    ),
  };

  const message = {
    emptyChild: t('account.message.empty_child'),
    linkChild: t('account.link_child.message'),
    signInMethod: t('account.message.sign_in_method'),
    none: t('account.message.none'),
    unlinkChild: t('account.message.unlink_child'),
  };

  const item = {
    father: t('account.item.relationship.father'),
    grandFather: t('account.item.relationship.grand_father'),
    grandMother: t('account.item.relationship.grand_mother'),
    guardian: t('account.item.relationship.guardian'),
    mother: t('account.item.relationship.mother'),
    unlinkChild: t('account.message.unlink_child'),
  };

  const label = {
    email: t('account.label.email'),
    mobile: t('account.label.mobile'),
    username: t('account.label.username'),
    signInMethod: t('account.label.sign_in_method'),
  };

  const placeholder = {
    child_code: t('account.placeholder.child_code'),
  };

  const signInMethod: Record<SignInMethod, string> = {
    [SignInMethod.Apple]: t('account.item.sign_in_method.apple'),
    [SignInMethod.Email]: t('account.item.sign_in_method.email'),
    [SignInMethod.Google]: t('account.item.sign_in_method.google'),
    [SignInMethod.Id]: t('account.item.sign_in_method.id'),
    [SignInMethod.Kakao]: t('account.item.sign_in_method.kakao'),
    [SignInMethod.Mobile]: t('account.item.sign_in_method.mobile'),
    [SignInMethod.Naver]: t('account.item.sign_in_method.naver'),
    [SignInMethod.Whalespace]: t('account.item.sign_in_method.whalespace'),
    [SignInMethod.Azure]: t('account.item.sign_in_method.azure'),
    [SignInMethod.Username]: t('account.item.sign_in_method.id'),
  };

  return {
    title,
    description,
    message,
    button,
    error,
    item,
    label,
    signInMethod,
    placeholder,
  };
};

export default useTranslatedTexts;
