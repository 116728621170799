import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  ForwardRefExoticComponent,
} from 'react';
import classnames from 'classnames';

import { ChevronUp, ChevronDown } from 'designSystem/ions/Icons/solid';
import { Box, BoxProps, colors } from 'designSystem/ions';

import { WithChildrenProp, Without } from 'types/utilities';

type DropdownTextAlign = 'left' | 'center' | 'right';

type DropdownContextValue = {
  value: null | string | number;
  open: boolean;
  disabled: boolean;
  onSelect(value: string | number): void;
  onOpenChange(open: boolean): void;
  onOpenToggle(): void;
};

type DropdownProps = {
  testId?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: string | number;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
  onValueChange?(value: string | number): void;
};

const DropdownContext = createContext<DropdownContextValue>(
  {} as DropdownContextValue
);

const defaultIconColor = colors.gray[500];
const disabledIconColor = colors.gray[400];

// Dropdown
const Dropdown: DropdownComponent = ({
  testId,
  className,
  children,
  disabled = false,
  defaultValue,
  defaultOpen = false,
  onOpenChange,
  onValueChange,
}: WithChildrenProp<DropdownProps>) => {
  const [value, setValue] = useState<string | number | null>(
    defaultValue || null
  );
  const [isOpen, setOpenState] = useState(defaultOpen);
  const contextValue = {
    value,
    disabled,
    open: isOpen,
    onSelect: useCallback(
      (value: string) => {
        onValueChange?.(value);
        setValue(value);
      },
      [onValueChange]
    ),
    onOpenChange: useCallback(
      (open: boolean) => {
        onOpenChange?.(open);
        setOpenState(open);
      },
      [onOpenChange]
    ),
    onOpenToggle: useCallback(() => {
      setOpenState((prevState) => {
        onOpenChange?.(!prevState);
        return !prevState;
      });
    }, [onOpenChange]),
  };

  useEffect(() => {
    setOpenState((isOpen) => isOpen && !disabled);
  }, [disabled]);

  return (
    <Box data-testid={testId} className={classnames('relative', className)}>
      <DropdownContext.Provider value={contextValue}>
        {children}
      </DropdownContext.Provider>
    </Box>
  );
};

// Dropdown.Trigger
type DropdownTriggerProps = Without<BoxProps<'button'>, 'is'> & {
  placeholder?: string;
  align?: DropdownTextAlign;
  hasBorder?: boolean;
  className?: string;
  hasError?: boolean;
};

const triggerStyle = {
  rootStyle: `
  flex flex-row items-center
  box-content
  h-11 md:h-14
  px-4 rounded-lg
  bg-white disabled:bg-gray-50 disabled:text-gray-500
  focus:outline-none focus-visible:ring`,
  disabeldStyle: 'bg-gray-50 cursor-not-allowed',
  borderStyle: {
    default: 'border border-gray-300 focus:border-ctGreen-500',
    error: 'border border-status-error',
  },
};

const Trigger = (
  {
    children,
    placeholder,
    align = 'center',
    hasBorder = true,
    hasError = false,
    className,
    ...rest
  }: WithChildrenProp<DropdownTriggerProps>,
  ref: React.Ref<HTMLButtonElement>
) => {
  const { open, value, disabled, onOpenToggle } = useContext(DropdownContext);
  const handleClickTrigger = useCallback(() => {
    onOpenToggle();
  }, [onOpenToggle]);

  const iconColor = disabled ? disabledIconColor : defaultIconColor;
  const dropdownTriggerStyle = classnames(
    triggerStyle.rootStyle,
    {
      [hasError
        ? triggerStyle.borderStyle.error
        : triggerStyle.borderStyle.default]: hasBorder,
      [triggerStyle.disabeldStyle]: disabled,
    },
    className
  );

  const labelStyle = classnames(
    'w-full text-body-3 md:text-body-1 font-regular',
    {
      'text-left': align === 'left',
      'text-center': align === 'center',
      'text-right': align === 'right',
    }
  );

  return (
    <Box
      is="button"
      type="button"
      ref={ref}
      data-testid="dropdown-trigger"
      className={dropdownTriggerStyle}
      onClick={handleClickTrigger}
      aria-haspopup="listbox"
      aria-expanded={open}
      disabled={disabled}
      {...rest}
    >
      <Box is="div" className="flex flex-1 mr-2">
        {value === null || children === undefined ? (
          <Box
            is="p"
            data-testid="dropdown-label--placeholder"
            className={classnames(labelStyle, 'text-gray-500')}
          >
            {placeholder}
          </Box>
        ) : (
          <Box
            is="p"
            data-testid="dropdown-label"
            className={classnames(labelStyle, 'text-gray-900')}
          >
            {children}
          </Box>
        )}
      </Box>
      {open ? (
        <ChevronUp.View {...ChevronUp.size} color={iconColor} />
      ) : (
        <ChevronDown.View {...ChevronDown.size} color={iconColor} />
      )}
    </Box>
  );
};

// Dropdown.Menu
type DropdownMenuProps = Without<BoxProps<'div'>, 'is'> & {
  className?: string;
};

const dropdownMenuRootStyle = `
flex absolute flex-col
mt-1 px-0 py-2
bg-white
rounded-lg
shadow-dropdown
z-10
overflow-y-auto`;

const Menu = ({
  children,
  className,
  ...rest
}: WithChildrenProp<DropdownMenuProps>) => {
  const { open, onOpenChange } = useContext(DropdownContext);
  const handleClickOutside = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  const dropdownMenuStyle = classnames(dropdownMenuRootStyle, className);

  return open ? (
    <Box>
      <Box
        data-testid="dropdown-menu-outside"
        className="fixed inset-0 bg-transparent"
        zIndex={1}
        onMouseDown={handleClickOutside}
        onTouchStart={handleClickOutside}
      />
      <Box
        is="div"
        data-testid="dropdown-menu"
        className={dropdownMenuStyle}
        tabIndex={-1}
        zIndex={2}
        role="listbox"
        {...rest}
      >
        {children}
      </Box>
    </Box>
  ) : null;
};

// Dropdown.MenuItem
type DropdownMenuItemProps = {
  value: string | number;
  align?: DropdownTextAlign;
  disabled?: boolean;
  showWithValue?: boolean;
};

const dropdownMenuItemStyle = {
  defaultStyle: 'text-gray-900 hover:bg-gray-100 cursor-pointer',
  selectedStyle: 'text-gray-900 bg-gray-100 cursor-default',
  disabledStyle: 'text-gray-400 cursor-not-allowed',
};

const MenuItem = ({
  children,
  value,
  align = 'center',
  disabled = false,
  showWithValue = false,
}: WithChildrenProp<DropdownMenuItemProps>) => {
  const { value: currentValue, onSelect, onOpenChange } = useContext(
    DropdownContext
  );
  const isSelected = value === currentValue;
  const itemStyle = classnames(
    'flex flex-row items-start py-2.5 px-5 text-body-3 sm:text-body-1 font-regular',
    {
      [dropdownMenuItemStyle.defaultStyle]: !(disabled || isSelected),
      [dropdownMenuItemStyle.selectedStyle]: isSelected,
      [dropdownMenuItemStyle.disabledStyle]: disabled,
      'text-left': align === 'left',
      'text-center': align === 'center',
      'text-right': align === 'right',
    }
  );

  const handleClickDropdownItem = useCallback(() => {
    if (disabled) return;
    onSelect(value);
    onOpenChange(false);
  }, [onSelect, onOpenChange, value, disabled]);

  return (
    <Box className={itemStyle} onClick={handleClickDropdownItem} role="option">
      <Box is="p" className="truncate">
        {children}
      </Box>
      {showWithValue && (
        <Box is="p" className="text-gray-600 font-medium ml-3">
          {value}
        </Box>
      )}
    </Box>
  );
};

type DropdownComponent = {
  (props: WithChildrenProp<DropdownProps>): JSX.Element;
  Trigger: ForwardRefExoticComponent<WithChildrenProp<DropdownTriggerProps>>;
  Menu: typeof Menu;
  MenuItem: typeof MenuItem;
};

Dropdown.Trigger = React.forwardRef(Trigger);
Dropdown.Menu = Menu;
Dropdown.MenuItem = MenuItem;

export default Dropdown;
