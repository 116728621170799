import React, { useEffect } from 'react';

import classNames from 'classnames';

import { useHistory } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';

import { Button } from 'designSystem/atoms';

import ClasstingCircleLogo from 'assets/logo/ClasstingCircleLogo';
import { textToNode } from 'shared/utils';
import { Box, useIsInMobile, useIsInSmScreen } from 'designSystem/ions';
import { daumtools } from 'shared/utils/web2app';
import { BASE_URL } from 'shared/constants';

interface RedirectAppPageProps {
  redirectWebUrl: string;
}

interface UserAgent {
  os: {
    name: string;
  };
  platform: string;
}

const ANDROID_STORE_LINK = 'market://details?id=com.Classting';
const IOS_STORE_LINK = 'http://goo.gl/88kSr';
const DEEP_LINK_URL = 'classting://webview?url=';

const getStoreUrl = () => {
  const userAgent = daumtools.userAgent();

  if (userAgent.os.name === 'android') {
    return ANDROID_STORE_LINK;
  }

  if (userAgent.os.name === 'ios') {
    return IOS_STORE_LINK;
  }

  return window.location.href;
};

const webToApp = (redirectWebUrl: string) => {
  const encodedUrl = encodeURIComponent(redirectWebUrl);

  const deeplinkUrl = `${DEEP_LINK_URL}${encodedUrl}`;

  daumtools.web2app({
    appName: 'classting',
    storeURL: getStoreUrl(),
    urlScheme: deeplinkUrl,
    intentURI: deeplinkUrl,
  });
};

const RedirectAppPage = (props: RedirectAppPageProps): JSX.Element => {
  const { redirectWebUrl } = props;
  const texts = useTranslatedTexts();
  const isSmScreen = useIsInSmScreen();
  const isInMobile = useIsInMobile();
  const history = useHistory();

  const { platform } = daumtools.userAgent() as UserAgent;

  useEffect(() => {
    if (!platform || platform !== 'pc') return;

    history.replace(redirectWebUrl);
  }, [platform, history, redirectWebUrl]);

  const handleInstallApp = () => {
    if (!window.top) return;

    window.location.replace(getStoreUrl());
  };

  const handleOepnApp = () => {
    webToApp(`${BASE_URL}${redirectWebUrl}`);
  };

  return (
    <>
      <Box is="div" className="flex flex-col mt-40">
        <ClasstingCircleLogo.View className="w-full mx-auto" />
      </Box>
      <Box
        is="div"
        data-testid="redirect-app-page-content"
        className="flex flex-col mt-9 mb-40"
      >
        <Box
          data-testid="title"
          is="h2"
          className="mb-2 md:mb-3 text-headline-4 md:text-headline-3 font-medium text-center text-gray-900 keep-all whitespace-pre-line"
        >
          {texts.title}
        </Box>
        <Box
          data-testid="description"
          is="span"
          className="text-caption md:text-body-3 font-regular text-center text-gray-700 keep-all whitespace-pre-line"
        >
          {texts.description}
        </Box>
      </Box>
      <Box
        data-testid="bottom-button-box"
        className={classNames('flex flex-col w-full', {
          'fixed bottom-4 px-3': isInMobile,
        })}
      >
        <Box is="div" className="flex flex-col mb-4">
          <Box
            data-testid="description"
            is="span"
            className="text-12 text-gray-700 mt-6 whitespace-pre-line text-center"
          >
            {textToNode(texts.leatestVersionDescription).map((node, i) => {
              if (node.nodeName.match(/strong/i)) {
                // istanbul ignore next
                return (
                  <span className="text-ctGreen-500" key={i}>
                    {node.textContent}
                  </span>
                );
              } else {
                return node.textContent;
              }
            })}
          </Box>
        </Box>
        <Button
          data-testid="open-button"
          className="bottom-0"
          appearance="contained"
          size={/* istanbul ignore next */ isSmScreen ? 'md' : 'lg'}
          fullWidth
          rounded
          onClick={handleOepnApp}
        >
          {texts.openButton}
        </Button>
        <Button
          data-testid="install-button"
          className="bottom-0 mt-4"
          appearance="contained"
          theme="gray"
          size={/* istanbul ignore next */ isSmScreen ? 'md' : 'lg'}
          fullWidth
          rounded
          onClick={handleInstallApp}
        >
          {texts.installButton}
        </Button>
      </Box>
    </>
  );
};

export default RedirectAppPage;
