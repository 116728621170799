import React, { ChangeEvent } from 'react';
import range from 'lodash/range';

import { Select } from '@classtinginc/design-system';

import useTranslatedTexts from '../../useTranslatedTexts';

import { GRADES } from 'shared/constants/grades';

import { HelperText, Label } from 'designSystem/atoms';
import { SchoolGrade } from 'generated/graphql';
import { Box } from 'designSystem/ions';

import { useGradeTranslations } from 'shared/hooks/translation/useGradeTranslation';

type GradeInputProps = {
  grade: SchoolGrade | undefined;
  onChangeGrade: (selectedGrade: SchoolGrade) => void;
  hasError?: boolean;
};

const GradeInput = ({
  grade,
  onChangeGrade,
  hasError,
}: GradeInputProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const { translateGrade } = useGradeTranslations();

  const currentGrade = grade ? grade.toUpperCase() : SchoolGrade.None;

  const gradeItems = GRADES.map((value) => {
    const translate = translateGrade(value);

    return {
      key: translate,
      value,
      label: translate,
    };
  });

  const isGradeErrorVisible =
    grade === undefined && hasError !== undefined && hasError;

  const handleChangeGrade = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectGrade = e.target.value as SchoolGrade;

    onChangeGrade(selectGrade);
  };

  return (
    <Box className="mt-5 sm:mt-6">
      <Label>{texts.label.grade}</Label>
      <Box className="flex flex-col w-full mt-1">
        <Select
          id="grade"
          value={currentGrade}
          onChange={handleChangeGrade}
          appearance="outlined"
          size="lg"
        >
          {gradeItems.map(({ key, label, value }) => {
            return (
              <option key={key} value={value}>
                {label}
              </option>
            );
          })}
        </Select>
        {isGradeErrorVisible && (
          <Box className="mt-1">
            <HelperText message={texts.message.empty_grade} status="error" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GradeInput;
