import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { BoxIndicator } from '@classtinginc/design-system';

import { PersonalInfoPanel } from './PersonalInfoPanel';

import { ChildInfoPanel } from './ChildInfoPanel';

import OrganizationInfoPanel from './OrganizationInfoPanel';

import useTranslatedTexts from './useTranslatedTexts';

import { EnterpriseSideMenuPageTemplate } from 'designSystem/templates';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { Box } from 'designSystem/ions';
import EnterpriseAppBar from 'designSystem/organisms/EnterpriseAppBar';
import {
  EnterpriseAppBarEnterpriseDtoFieldsFragment,
  EnterpriseDto,
  EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment,
  OrganizationInputAccountFieldsFragment,
  usePersonalInfoPageQuery,
} from 'generated/graphql';
import ForbiddenErrorPage from 'pages/ErrorPages/ForbiddenErrorPage';

export const PersonalInfoPage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const texts = useTranslatedTexts();
  const isChildInfoPanelVisible = account.role === 'parent';

  const { data: { enterprise } = {}, loading } = usePersonalInfoPageQuery({
    variables: {
      enterpriseId: account?.enterprise?.id ?? '',
    },
    skip: !account?.enterprise,
  });

  const faviconUrl =
    enterprise?.isWhiteLabeled && enterprise?.branding?.appIconUrl
      ? enterprise?.branding?.appIconUrl
      : `${process.env.PUBLIC_URL}/favicon.ico`;

  if (!account.enterprise) {
    return <ForbiddenErrorPage />;
  }

  if (loading) {
    return <BoxIndicator />;
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <EnterpriseSideMenuPageTemplate
        account={account}
        title={texts.pageTitle}
        enterprise={
          enterprise as EnterpriseSideMenuPageTemplateEnterpriseDtoFieldsFragment
        }
        headerChild={
          <EnterpriseAppBar
            account={account}
            enterprise={
              enterprise as EnterpriseAppBarEnterpriseDtoFieldsFragment
            }
          />
        }
      >
        <PersonalInfoPanel
          account={account}
          enterprise={enterprise as EnterpriseDto}
        />
        {account.role !== 'parent' && (
          <>
            <Box className="block sm:mt-5 mt-6 h-3 sm:h-0 -mx-4 bg-gray-200" />
            <OrganizationInfoPanel
              account={account as OrganizationInputAccountFieldsFragment}
            />
          </>
        )}
        {isChildInfoPanelVisible && (
          <>
            <Box className="block sm:hidden h-3 mt-5 bg-gray-200 -mx-4" />
            <ChildInfoPanel account={account} />
          </>
        )}
      </EnterpriseSideMenuPageTemplate>
    </>
  );
};

export default withAccount(PersonalInfoPage);
