import React from 'react';
import classnames from 'classnames';

import { XIcon, CheckIcon } from 'designSystem/ions/Icons/solid';
import { ExclamationCircle } from 'designSystem/ions/Icons/outlined';
import { Box } from 'designSystem/ions';

export type HelperTextIcon = 'x' | 'check' | 'exclamation' | '';
export type HelperTextStatus = 'default' | 'success' | 'error';
export type HelperTextType = {
  status: HelperTextStatus;
  message: string;
  icon?: HelperTextIcon;
};

type HelperTextProps = HelperTextType;

const defaultHelperTextStyle = 'flex items-center text-caption';
const helperTextIcon = 'mr-1';

const getHelperTextStyleByStatus = (status: HelperTextStatus) => {
  switch (status) {
    case 'success':
      return 'text-status-success';
    case 'error':
      return 'text-status-error';
    case 'default':
      return 'text-gray-600';
  }
};

const icons = {
  x: XIcon,
  check: CheckIcon,
  exclamation: ExclamationCircle,
};

const HelperText = ({
  status,
  message,
  icon = '',
}: HelperTextProps): JSX.Element => {
  const styleByStatus = getHelperTextStyleByStatus(status);
  const Icon = icon ? icons[icon] : null;

  return (
    <Box
      data-testid="helper-text"
      className={classnames(defaultHelperTextStyle, styleByStatus)}
    >
      {Icon && (
        <Box data-testid="icon" className={helperTextIcon}>
          <Icon.View width="16px" height="16px" />
        </Box>
      )}
      {message}
    </Box>
  );
};

export default HelperText;
