/* istanbul ignore file */

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import * as Sentry from '@sentry/react';

import modifyAxiosError, { CustomAxiosError } from './modifyAxiosError';

import { API_URL } from 'shared/constants';
import { getCredential } from 'core/credential/credential.service';

type AxiosFactoryOptions = {
  auth?: boolean;
};

/**
 * Basic Axios config
 */
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

function makeAuthorizationHeader(): string {
  const credential = getCredential();
  return credential
    ? `${credential.token_type} ${credential.access_token}`
    : '';
}

function requestInterceptor(config: AxiosRequestConfig) {
  config.headers['Authorization'] = makeAuthorizationHeader();
  return config;
}

function responseInterceptor(response: AxiosResponse) {
  return response;
}

function responseErrorInterceptor(error: AxiosError) {
  const modifiedAxiosError = modifyAxiosError(error);

  Sentry.setUser(modifiedAxiosError.user ?? null);
  Sentry.setContext('error', modifiedAxiosError.contexts?.error ?? null);
  Sentry.captureException(modifiedAxiosError);

  return Promise.reject<CustomAxiosError>(modifiedAxiosError);
}

export function create(
  config?: AxiosRequestConfig,
  options?: AxiosFactoryOptions
): AxiosInstance {
  const instance = axios.create(
    config ?? {
      baseURL: API_URL,
    }
  );
  const auth = options?.auth ?? true;

  if (auth) {
    instance.interceptors.request.use(requestInterceptor);
  }

  instance.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );
  return instance;
}
