/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

import React from 'react';

import { hideInMobile, Box } from 'designSystem/ions';

type TitleBoxAlign = 'left' | 'center' | 'right';
type TitleBoxSize = 'sm' | 'md';
export interface EnterpriseTitleBoxProps {
  title?: string;
  desc?: string;
  align?: TitleBoxAlign;
  size?: TitleBoxSize;
  className?: string;
  hideTitleInMobile?: boolean;
}

function getTitleBoxStyle(
  size: TitleBoxSize
): { titleStyle: string; descStyle: string } {
  switch (size) {
    case 'sm':
      return {
        titleStyle: `
        text-headline-5 sm:text-headline-3
        font-medium text-gray-900
        break-all whitespace-pre-line`,
        descStyle: `
        sm:mt-6
        text-caption md:text-body-3
        font-regular text-gray-700
        break-all whitespace-pre-line`,
      };

    case 'md':
      return {
        titleStyle: `
        text-headline-2 md:text-headline-1
        font-medium text-gray-900
        break-all whitespace-pre-line`,
        descStyle: `
        mt-2 sm:mt-3 md:mt-4
        text-caption md:text-body-3
        font-regular text-gray-700
        break-all whitespace-pre-line`,
      };
  }
}

const EnterpriseTitleBox = ({
  title = '',
  desc = '',
  align = 'center',
  size = 'md',
  className = '',
  hideTitleInMobile = true,
}: EnterpriseTitleBoxProps): JSX.Element => {
  const {
    titleStyle: baseTitleStyle,
    descStyle: baseDescStyle,
  } = getTitleBoxStyle(size);
  const textAlignStyle = `text-${align}`;
  const hideInMobileStyle = hideInMobile(hideTitleInMobile);
  const wrapperStyle = `flex flex-col  items-center ${className}`;
  const titleStyle = `${baseTitleStyle} ${textAlignStyle} ${hideInMobileStyle}`;
  const descStyle = `${baseDescStyle} ${textAlignStyle}`;

  return (
    <Box is="div" data-testid="title-box" className={wrapperStyle}>
      <Box is="h2" className={titleStyle}>
        {title}
      </Box>
      {desc ? (
        <Box is="p" className={descStyle}>
          {desc}
        </Box>
      ) : null}
    </Box>
  );
};

export default EnterpriseTitleBox;
