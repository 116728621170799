/* istanbul ignore file */

import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

function CodeIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.16667 4.16667V15.8333H15.8333V4.16667H4.16667ZM3.33333 2.5H16.6667C16.8877 2.5 17.0996 2.5878 17.2559 2.74408C17.4122 2.90036 17.5 3.11232 17.5 3.33333V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5V2.5ZM8.22417 12.5L7.54083 14.1667H5.69417L9.16667 5.83333H10.8333L14.3058 14.1667H12.4583L11.775 12.5H8.225H8.22417ZM8.9075 10.8333H11.0925L10 8.16667L8.9075 10.8333Z"
        fill="#424242"
      />
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(CodeIcon),
};

export default Asset;
