/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { History } from 'history';
import qs from 'qs';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { User } from 'oidc-client-ts';

import { Account, useDeleteAccount } from 'shared/data/account';
import { CLASSTING_OIDC_URL, LOGOUT_REDIRECT_URL } from 'shared/constants';
import { sessionStorageService } from 'core/storage';
import { useCanDeleteUser } from 'shared/data/user';
import { OpenModalParamsType } from 'designSystem/organisms';

export function useDeleteButtonHandler({
  account,
  history,
  credential,
}: {
  account: Account | undefined;
  history: History;
  credential: User | null;
}) {
  const mutation = useDeleteAccount({
    onSuccess: () => {
      const paramObject = {
        id_token_hint: credential?.id_token,
        post_logout_redirect_uri: LOGOUT_REDIRECT_URL,
      };
      const params = qs.stringify(paramObject);
      sessionStorageService.setItem('isDeleteRequestSuccess', true);
      window.location.assign(
        `${CLASSTING_OIDC_URL}/oidc/session/end?${params}`
      );
    },
    onError: () => {
      history.push(`/account/delete/error`, {
        reason: '계정 삭제 오류',
      });
    },
  });
  const { t } = useTranslation();
  const tempErrorMessage = t('account.error.message.temporary_error');
  const deleteAdminErrorTitle = t('account.error.title.delete_admin_account');
  const deleteAdminErrorMessage = t(
    'account.error.message.delete_admin_account'
  );
  const canDeleteResponse = useCanDeleteUser({
    enabled: !!account?.id,
  });
  const canDelete = canDeleteResponse?.data?.data?.can_delete;
  const handler = (openModal: (params: OpenModalParamsType) => void) => {
    /* istanbul ignore else */
    if (!account?.id) {
      history.push(`/account/delete/error`, {
        reason: '유저 정보 오류',
      });
    } else if (canDeleteResponse.isError) {
      toast.error(tempErrorMessage);
    } else if (canDelete === false) {
      openModal({
        status: 'error',
        title: deleteAdminErrorTitle,
        message: deleteAdminErrorMessage,
      });
    } else {
      mutation.mutate(account.id);
    }
  };
  return {
    handler,
    mutation,
  };
}
