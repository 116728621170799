import * as React from 'react';

const size = {
  width: '25px',
  height: '24px',
};

const ContentCopyIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.5 1h-12c-1.1 0-2 .9-2 2v14h2V3h12V1Zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm0 16h-11V7h11v14Z"
      fill="#616161"
    />
  </svg>
);

const Asset = {
  size,
  View: ContentCopyIcon,
};
export default Asset;
