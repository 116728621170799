/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useMemo, useCallback } from 'react';
import { History } from 'history';
import parsePhoneNumber from 'libphonenumber-js';
import dayjs from 'dayjs';

import useTranslatedTexts from './useTranlatedTexts';

import {
  Account,
  useChangeMobile,
  useRequestMobileChange,
} from 'shared/data/account';
import { FieldHelperText } from 'designSystem/molecules';
import { OpenModalParamsType } from 'designSystem/organisms';

export function useRequestMobileChangeCommand({
  account,
  history,
}: {
  account: Account | undefined;
  history: History;
}) {
  const texts = useTranslatedTexts();
  const [errorCode, setErrorCode] = useState(0);
  const [inFocus, setInputFocusState] = useState(false);
  const [countryDialCode, setCountryDialCode] = useState<string>('+82');
  const [mobileNumber, setMobileNumber] = useState('');
  const mutation = useRequestMobileChange({
    onError: (error) => {
      /* istanbul ignore next */
      setErrorCode(error.response?.status || 0);
    },
  });

  const handleChangeMobileNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const onlyNumberRegexp = /[^0-9]/g;
      setErrorCode(0);
      setMobileNumber(event.target.value.replace(onlyNumberRegexp, ''));
    },
    []
  );

  const currentMobileNumber = useMemo(() => {
    return parsePhoneNumber(countryDialCode + mobileNumber);
  }, [mobileNumber, countryDialCode]);

  const originMobileNumber = useMemo(() => {
    if (account?.mobile) {
      const { countryCode, number } = account?.mobile;
      return parsePhoneNumber(number, countryCode);
    }
  }, [account]);

  const expireTimeout = useMemo(() => {
    if (mutation.data) {
      return Math.floor(
        (dayjs(mutation.data?.expireAt).toDate().getTime() -
          dayjs().toDate().getTime()) /
          1000
      );
    } else {
      return 0;
    }
  }, [mutation]);

  const handler = () => {
    const countryCode = currentMobileNumber?.country;

    if (!account?.id || !countryCode) {
      history.push(`/account/error`, {
        reason: '유저 정보 오류',
      });
    } else {
      mutation.mutate({
        userId: account.id,
        data: { countryCode, number: mobileNumber },
      });
    }
  };

  const resendHandler = () => {
    mutation.reset();
    handler();
  };

  const errors = {
    invalidMobileNumber:
      !inFocus && mobileNumber !== '' && !currentMobileNumber?.isValid(),
    alreadyOccupiedMobile: errorCode === 409,
    alreadyUsingMobile:
      mobileNumber !== '' &&
      /* istanbul ignore next */
      (originMobileNumber && currentMobileNumber
        ? originMobileNumber.isEqual(currentMobileNumber)
        : false),
  };

  const helperTexts: FieldHelperText[] = [
    {
      visible: mutation.isSuccess,
      status: 'default',
      message: texts.message.sendMobileCode,
      icon: 'check',
    },
    {
      visible: errors.invalidMobileNumber,
      status: 'error',
      message: texts.message.invalidMobileNumber,
    },
    {
      visible: errors.alreadyOccupiedMobile,
      status: 'error',
      message: texts.message.alreadyOccupiedMobile,
    },
    {
      visible: errors.alreadyUsingMobile,
      status: 'error',
      message: texts.message.alreadyUsingMobile,
    },
  ];
  const hasError = Object.values(errors).some((v) => v);

  const inputProps = {
    value: mobileNumber,
    dropdownLabel: countryDialCode,
    onChange: handleChangeMobileNumber,
    onFocus: () => setInputFocusState(true),
    onBlur: () => setInputFocusState(false),
    onDropdownChange: setCountryDialCode,
    isError: hasError,
    helperTexts,
  };

  const buttonProps = {
    disabled: !currentMobileNumber?.isValid() || hasError,
  };

  return {
    inputProps,
    buttonProps,
    expireTimeout,
    mutation,
    errors,
    handler,
    resendHandler,
  };
}

export function useChangeMobileCommand({
  account,
  history,
}: {
  account: Account | undefined;
  history: History;
}) {
  const texts = useTranslatedTexts();
  const [errorCode, setErrorCode] = useState(0);
  const [mobileCode, setMobileCode] = useState('');
  const mutation = useChangeMobile({
    onError: (error) => {
      /* istanbul ignore next */
      setErrorCode(error.response?.status || 0);
    },
  });

  const handleChangeMobileCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorCode(0);
      setMobileCode(event.target.value);
    },
    []
  );

  const handler = (
    requestId: string,
    openModal: (params: OpenModalParamsType) => void
  ) => {
    /* istanbul ignore else */
    if (!account?.id) {
      history.push(`/account/error`, {
        reason: '유저 정보 오류',
      });
    } else {
      mutation.mutate(
        {
          userId: account.id,
          data: { requestId, code: mobileCode },
        },
        {
          onSuccess() {
            openModal({
              status: 'success',
              message: texts.message.successChange,
              onClickButton() {
                history.push('/account/info');
              },
            });
          },
        }
      );
    }
  };

  const errors = {
    notMatchedMobileCode: errorCode === 406,
    expiredMobileCode: errorCode === 408,
    alreadyOccupiedMobile: errorCode === 409,
  };

  const helperTexts: FieldHelperText[] = [
    {
      visible: errors.notMatchedMobileCode,
      status: 'error',
      message: texts.message.notMatchedMobileCode,
    },
    {
      visible: errors.expiredMobileCode,
      status: 'error',
      message: texts.message.expiredMobileCode,
    },
    {
      visible: errors.alreadyOccupiedMobile,
      status: 'error',
      message: texts.message.alreadyOccupiedMobile,
    },
  ];
  const hasError = Object.values(errors).some((v) => v);

  const inputProps = {
    value: mobileCode,
    onChange: handleChangeMobileCode,
    isError: hasError,
    helperTexts,
  };

  const buttonProps = {
    disabled: mobileCode.length < 6 || hasError,
  };

  return {
    inputProps,
    buttonProps,
    mutation,
    errors,
    handler,
  };
}
