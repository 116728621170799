/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

import React, { PropsWithChildren } from 'react';

import type { CTAWithAssetProps } from '../CallToAction.types';

import { Box, Helmet } from 'designSystem/ions';

const CTAWithTopAsset = ({
  title = '',
  desc = '',
  asset: Asset,
  width = 'auto',
  children,
}: PropsWithChildren<CTAWithAssetProps>): JSX.Element => {
  const titleStyle = `
  mb-2 md:mb-3
  text-headline-4 md:text-headline-3
  font-medium
  text-center text-gray-900
  keep-all whitespace-pre-line
  `;

  const descStyle = `
  text-caption md:text-body-3
  font-regular
  text-center text-gray-700
  keep-all whitespace-pre-line
  `;
  const wrapperStyle = { width };
  return (
    <>
      <Box
        is="div"
        className="flex flex-col mt-20 sm:mt-8"
        style={wrapperStyle}
      >
        <Asset />
      </Box>
      <Box
        is="div"
        data-test-id="landscape-asset-title-box"
        className="flex flex-col mt-9"
      >
        <Helmet title={title} desc={desc} />
        <Box is="h2" className={titleStyle}>
          {title}
        </Box>
        <Box is="span" className={descStyle}>
          {desc}
        </Box>
        <Box is="div" className="flex flex-col items-start w-full mt-8">
          <Box
            is="div"
            className="flex flex-row items-start justify-center w-full h-11"
          >
            <Box is="div" className="flex flex-col">
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(CTAWithTopAsset);
