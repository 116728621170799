import React, { useMemo, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import countries from 'i18n-iso-countries';

import { Input, Select, Textarea } from '@classtinginc/design-system';

import useTranslatedTexts from '../../useTranslatedTexts';

import { errorTypes, PersonalInfoFormSchema } from './schema';

import { createTrackEventProps } from 'shared/analytics/utils';
import { Account } from 'shared/data/account';
import { XClose } from 'designSystem/ions/Icons/solid';
import { Box, BoxProps } from 'designSystem/ions';
import { Button, HelperText, Label } from 'designSystem/atoms';
import { FieldHelperText } from 'designSystem/molecules';

import { WithChildrenProp } from 'types/utilities';

const LANGUAGE_ITEMS: OptionItem[] = [
  { key: 'ko', value: 'ko', label: '한국어' },
  { key: 'en', value: 'en', label: 'English' },
  { key: 'zh-TW', value: 'zh-TW', label: '繁體中文' },
  { key: 'ja-JP', value: 'ja-JP', label: '日本語' },
  { key: 'es', value: 'es', label: 'Español' },
  { key: 'vi-VN', value: 'vi-VN', label: 'Tiếng Việt' },
  { key: 'zh-CN', value: 'zh-CN', label: '简体中文' },
  { key: 'tl-PH', value: 'tl-PH', label: 'Tagalog' },
  { key: 'km-KH', value: 'km-KH', label: 'ភាសាខ្មែរ' },
  { key: 'id-ID', value: 'id-ID', label: 'bahasa Indonesia' },
  { key: 'ru-RU', value: 'ru-RU', label: 'Русский' },
  { key: 'th-TH', value: 'th-TH', label: 'ไทย' },
  { key: 'mn-MN', value: 'mn-MN', label: 'Монгол' },
  { key: 'ne-NP', value: 'ne-NP', label: 'नेपाली' },
  { key: 'ar', value: 'ar', label: 'عربي' },
  { key: 'uz', value: 'uz', label: 'Oʻzbekcha' },
];

type PersonalInfoFormProps = {
  account: Account;
  formProps: UseFormReturn<PersonalInfoFormSchema>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onClickChangeRole: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type OptionItem = { key: React.Key; label: string; value: string | number };

const LabelBox = ({
  label,
  children,
  className,
  testId,
  ...rest
}: WithChildrenProp<BoxProps<'div'> & { label: string; testId?: string }>) => {
  return (
    <Box is="div" data-testid={testId} {...rest}>
      <Label>{label}</Label>
      {children}
    </Box>
  );
};

const PersonalInfoForm = ({
  account,
  formProps,
  onSubmit,
  onClickChangeRole,
}: PersonalInfoFormProps): JSX.Element => {
  const { i18n } = useTranslation();
  const texts = useTranslatedTexts();
  const { formState, register, unregister, watch, clearErrors } = formProps;
  const { errors } = formState;

  const genderItems = useMemo(
    () => [
      { key: '0', value: 'female', label: texts.item.female },
      { key: '1', value: 'male', label: texts.item.male },
    ],
    [texts]
  );

  const birthdayMonthItems = useMemo(
    () =>
      _.range(12).map((month) => {
        return {
          key: month,
          value: month,
          label: i18n.language === 'ko' ? `${month + 1}월` : `${month + 1}`,
        };
      }),
    [i18n.language]
  );

  const birthdayDateItems = useMemo(
    () =>
      _.range(31).map((date) => {
        return {
          key: date,
          value: date + 1,
          label: i18n.language === 'ko' ? `${date + 1}일` : `${date + 1}`,
        };
      }),
    [i18n.language]
  );

  const language = watch('language');

  const countryItems = useMemo(() => {
    let targetLanguage = language.replace(/-.+/, '');

    if (!countries.langs().find((lang) => lang === targetLanguage)) {
      targetLanguage = 'en';
    }

    const countryCodes = countries.getNames(targetLanguage, {
      select: 'official',
    });

    return Object.entries(countryCodes)
      .map(([countryCode, translatedCountryName]) => {
        return {
          key: countryCode,
          value: countryCode,
          label: translatedCountryName,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [language]);

  const [, birthDateMonth, birthDateDay] =
    !errors.birthdayMonth && !errors.birthdayDate && account.birthDate
      ? //delete first letter if zero
        account.birthDate?.split('-').map((date) => {
          if (date[0] === '0') {
            return date[1];
          }
          return date;
        })
      : [];

  useEffect(() => {
    if (account.gender) {
      unregister('gender');
    }
    if (account.birthDate) {
      unregister(['birthdayMonth', 'birthdayDate']);
    }
  }, [account.birthDate, account.gender, unregister]);

  const clearBirthdayError = () => {
    clearErrors(['birthdayMonth', 'birthdayDate']);
  };

  const birthdayMonthRegister = register('birthdayMonth');
  const birthdayDateRegister = register('birthdayDate');

  return (
    <Box
      is="form"
      data-testid="personal-info-form"
      className="w-full"
      onSubmit={onSubmit}
    >
      <Box className="mt-5 sm:mt-6">
        <Box className="mb-1">
          <Label>{texts.label.name}</Label>
        </Box>
        <Input
          {...register('name')}
          appearance="outlined"
          size="lg"
          data-testid="user-name-input"
          placeholder={texts.placeholder.name}
        />
        {errors.name && (
          <Box className="mt-1">
            {([
              {
                message: texts.error.invalidNameBrand,
                visible: errors.name?.type === errorTypes.containsBrandName,
                status: 'error',
              },
              {
                message: texts.error.invalidName,
                visible: errors.name?.type === errorTypes.specialCharacter,
                status: 'error',
              },
            ] as FieldHelperText[])
              .filter(({ visible }) => visible)
              .map(({ message, status }) => (
                <HelperText key={message} message={message} status={status} />
              ))}
          </Box>
        )}
      </Box>
      <Box className="mt-5 sm:mt-6">
        <Box className="mb-1">
          <Label>{`${texts.label.description} ${texts.item.option}`}</Label>
        </Box>
        <Textarea
          {...register('description')}
          data-testid="user-description-input"
          placeholder={texts.placeholder.description}
          minLength={2}
          maxLength={200}
          spellCheck={false}
        />
      </Box>
      <Box className="mt-12 sm:mt-10 text-gray-900 text-body-2 sm:text-body-1 font-medium">
        {texts.label.personalInfo}
      </Box>
      <Box className="mt-5 sm:mt-6">
        <LabelBox
          testId="user-birthdate"
          label={`${texts.label.birthday} ${texts.item.option}`}
        >
          {account.birthDate ? (
            <Box
              className="mt-2 text-gray-900 text-body-3 md:text-body-1 font-regular"
              data-testid="user-birthdate-text"
            >
              {`${birthDateMonth}${texts.placeholder.month} ${birthDateDay}${texts.placeholder.day}`}
            </Box>
          ) : (
            <Box
              className="flex flex-row w-full gap-3 mt-1"
              data-testid="birthdate-selector"
            >
              <Select
                {...birthdayMonthRegister}
                appearance="outlined"
                size="lg"
                data-testid="user-birthday-month-select"
                onChange={(e) => {
                  clearBirthdayError();
                  birthdayDateRegister.onChange(e);
                }}
              >
                <option disabled value={-1} hidden>
                  {texts.placeholder.month}
                </option>
                {birthdayMonthItems.map(({ key, label, value }) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <Select
                {...birthdayDateRegister}
                appearance="outlined"
                size="lg"
                data-testid="user-birthday-date-select"
                onChange={(e) => {
                  clearBirthdayError();
                  birthdayDateRegister.onChange(e);
                }}
              >
                <option disabled value={-1} hidden>
                  {texts.placeholder.day}
                </option>
                {birthdayDateItems.map(({ key, label, value }) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Box>
          )}
          <Box className="mt-1">
            {!account.birthDate &&
              !(errors.birthdayDate || errors.birthdayMonth) && (
                <HelperText
                  status="default"
                  icon="exclamation"
                  message={texts.message.addBirthday}
                />
              )}
            {(errors.birthdayDate || errors.birthdayMonth) && (
              <HelperText
                status="error"
                message={texts.error.invalidBirthday}
              />
            )}
          </Box>
        </LabelBox>
      </Box>
      <Box className="mt-5 sm:mt-6">
        <LabelBox
          testId="user-gender"
          label={`${texts.label.gender} ${texts.item.option}`}
        >
          {account.gender ? (
            <Box className="mt-2 text-gray-900 text-body-3 md:text-body-1 font-regular">
              {texts.item[account.gender]}
            </Box>
          ) : (
            <Box className="flex flex-col w-full mt-1">
              <Box width="140px">
                <Select
                  {...register('gender')}
                  appearance="outlined"
                  size="lg"
                  data-testid="user-gender-select"
                >
                  <option disabled value="" hidden>
                    {texts.placeholder.gender}
                  </option>
                  {genderItems.map(({ key, label, value }) => (
                    <option key={key} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box className="mt-1">
                <HelperText
                  status="default"
                  icon="exclamation"
                  message={texts.message.addGender}
                />
              </Box>
            </Box>
          )}
        </LabelBox>
      </Box>
      <Box className="flex flex-col items-start mt-5 sm:mt-6">
        <Box className="flex flex-row justify-between w-full items-center">
          <Box className="flex flex-col">
            <LabelBox label={`${texts.label.role}`}>
              <Box className="text-gray-900 text-body-3  mt-2 md:text-body-1 font-regular">
                {texts.role[account.role]}
              </Box>
            </LabelBox>
          </Box>
          <Button
            testId="change-role-button"
            appearance="outlined"
            theme="white"
            onClick={onClickChangeRole}
            {...createTrackEventProps({
              name: 'ChangeRoleStrarted',
              on: 'click',
              properties: {
                userId: account?.id,
                role: account?.role,
              },
            })}
          >
            {texts.button.changeRole}
          </Button>
        </Box>
      </Box>
      <Box className="mt-5 sm:mt-6">
        <LabelBox testId="user-language" label={`${texts.label.language}`}>
          <Box className="flex flex-col w-full mt-1">
            <Select
              {...register('language')}
              appearance="outlined"
              size="lg"
              data-testid="user-language-select"
            >
              <option disabled value="" hidden>
                {texts.label.language}
              </option>
              {LANGUAGE_ITEMS.map(({ key, label, value }) => (
                <option key={key} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Box>
        </LabelBox>
      </Box>
      <Box className="mt-5 sm:mt-6">
        <LabelBox testId="user-country" label={`${texts.label.country}`}>
          <Box className="flex flex-col w-full mt-1">
            <Select
              {...register('countryCode')}
              appearance="outlined"
              size="lg"
              data-testid="user-country-select"
            >
              <option disabled value="" hidden>
                {texts.label.country}
              </option>
              {countryItems.map(({ key, label, value }) => (
                <option key={key} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Box>
        </LabelBox>
      </Box>
    </Box>
  );
};

export default PersonalInfoForm;
