import React from 'react';

import { RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';

import { Box, Divider, Typography } from '@classtinginc/design-system';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import ClasstingWhiteCircleLogo from 'assets/logo/ClasstingWhiteCircleLogo';

import { CenterTitleNavigationHeader } from 'designSystem/organisms';
import { SingleColumnPageTemplate } from 'designSystem/templates';
import { TransparentPanel } from 'designSystem/layouts';
import { Account } from 'shared/data/account';
import { withAccount } from 'shared/root';

dayjs.extend(utc);
dayjs.extend(timezone);

export const MarketingAgreementNoticePage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const lastAgreedAtFromParams = params.get('lastAgreedAt') ?? '';

  const lastAgreedAt = dayjs(lastAgreedAtFromParams)
    .tz('Asia/Seoul')
    .format('YYYY년 MM월 DD일');
  const isEmailAgreed = account.marketingAgreement?.isEmailAgreed ?? false;
  const isSMSAgreed = account.marketingAgreement?.isSMSAgreed ?? false;
  const isPushAgreed = account.marketingAgreement?.isPushAgreed ?? false;

  return (
    <SingleColumnPageTemplate
      headerChild={
        <CenterTitleNavigationHeader
          showTitleInMobile={false}
          showBackButtonInMobile={true}
          backButtonBehavior="close"
        />
      }
      hasHeaderShadow={false}
      showFooter={false}
    >
      <TransparentPanel.Wrapper>
        <TransparentPanel.Body headerLess>
          <Box
            css={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              css={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '$06',
                marginTop: '$02',
              }}
            >
              <ClasstingWhiteCircleLogo.View />
            </Box>
            <Box
              css={{
                marginBottom: '2.5rem',
              }}
            >
              <Typography as="div" size="body" color="dark">
                안녕하세요, 클래스팅입니다.
              </Typography>
              <Typography
                as="div"
                css={{ marginBottom: '$06' }}
                size="body"
                color="dark"
              >
                클래스팅을 믿고 이용해주시는 고객님께 진심으로 감사드립니다.
              </Typography>
              <Typography as="div" size="body" color="dark">
                정보통신망 이용촉진 및 정보보호 등에 관한 법률 제50조 8항 및
                동법 시행령 제62조에 따라 고객님의 마케팅 정보 수신 동의 내역을
                안내해 드립니다.
              </Typography>
            </Box>
            <Box css={{ marginBottom: '$03' }}>
              <Typography as="div" size="heading" color="dark">
                마케팅 정보 수신 동의 내역
              </Typography>
            </Box>
            <Box
              as="div"
              css={{
                borderTopWidth: '2px',
                borderBlockColor: '#212121',
              }}
            ></Box>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '$05',
              }}
            >
              <Typography
                as="div"
                css={{ flexBasis: '40%', marginLeft: '$05' }}
                size="body"
                color="dark"
              >
                수신 동의일
              </Typography>
              <Typography
                as="div"
                css={{ flexBasis: '60%' }}
                size="body"
                color="dark"
                data-testid="last-agreed-at"
              >
                {lastAgreedAt}
              </Typography>
            </Box>
            <Divider type={'full-bleed'} css={{ marginTop: '$05' }} />
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '$05',
              }}
            >
              <Typography
                as="div"
                css={{ flexBasis: '40%', marginLeft: '$05' }}
                size="body"
                color="dark"
              >
                이메일 알림
              </Typography>
              <Typography
                as="div"
                css={{ flexBasis: '60%' }}
                size="body"
                color="dark"
                data-testid="is-email-agreed"
              >
                {isEmailAgreed ? '동의' : '미동의'}
              </Typography>
            </Box>
            <Divider type={'full-bleed'} css={{ marginTop: '$05' }} />
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '$05',
              }}
            >
              <Typography
                as="div"
                css={{ flexBasis: '40%', marginLeft: '$05' }}
                size="body"
                color="dark"
              >
                문자 알림
              </Typography>
              <Typography
                as="div"
                css={{ flexBasis: '60%' }}
                size="body"
                color="dark"
                data-testid="is-sms-agreed"
              >
                {isSMSAgreed ? '동의' : '미동의'}
              </Typography>
            </Box>
            <Divider type={'full-bleed'} css={{ marginTop: '$05' }} />
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '$05',
              }}
            >
              <Typography
                as="div"
                css={{ flexBasis: '40%', marginLeft: '$05' }}
                size="body"
                color="dark"
              >
                앱 알림
              </Typography>
              <Typography
                as="div"
                css={{ flexBasis: '60%' }}
                size="body"
                color="dark"
                data-testid="is-push-agreed"
              >
                {isPushAgreed ? '동의' : '미동의'}
              </Typography>
            </Box>
            <Box
              as="div"
              css={{
                borderTopWidth: '2px',
                borderBlockColor: '#212121',
                marginTop: '$05',
              }}
            ></Box>
            <Typography
              as="div"
              size="tiny"
              color="medium"
              css={{ marginTop: '$02' }}
            >
              {`* 본 안내는 ${dayjs()
                .tz('Asia/Seoul')
                .format('YYYY년 MM월 DD일')} 기준으로 작성되었습니다.`}
            </Typography>
            <Box
              css={{
                marginTop: '2.5rem',
              }}
            >
              <Typography
                as="div"
                css={{ marginBottom: '$06' }}
                size="body"
                color="dark"
              >
                수신 거부를 원하시는 경우 아래 경로에서 동의 내용을 철회할 수
                있습니다.
              </Typography>
              <Typography
                as="div"
                css={{
                  marginBottom: '$06',
                  fontSize: '1rem',
                }}
                size="title"
                color="dark"
              >
                {
                  '클래스팅 홈(https://www.classting.com/home) > 로그인 > 내 정보 > 혜택·마케팅 알림'
                }
              </Typography>
              <Typography as="div" size="body" color="dark">
                클래스팅은 앞으로도 더 나은 서비스를 제공하기 위해 최선을
                다하겠습니다.
              </Typography>
            </Box>
            <Box
              css={{
                marginTop: '2.5rem',
              }}
            >
              <Typography
                as="div"
                size="sub-body"
                color="medium"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                관련하여 궁금하신 사항은
              </Typography>
              <Typography
                as="div"
                size="sub-body"
                color="medium"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                고객센터(1544-0570)로 문의주시기 바랍니다.
              </Typography>
            </Box>
          </Box>
        </TransparentPanel.Body>
      </TransparentPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default withAccount(MarketingAgreementNoticePage);
