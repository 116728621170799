import React, { PropsWithChildren } from 'react';

import { Box, Helmet } from 'designSystem/ions';

const Wrapper = ({
  children,
  className = '',
}: PropsWithChildren<{ className?: string }>): JSX.Element => {
  const classNameProp = `
  flex flex-col ${className}`;
  return (
    <Box is="article" className={classNameProp}>
      {children}
    </Box>
  );
};

const Header = ({
  children,
  title,
}: PropsWithChildren<{
  title?: string;
}>): JSX.Element => {
  const className = `
  flex flex-col items-center
  w-full
  mt-0 sm:mt-4 md:mt-0
  `;

  return (
    <Box is="header" className={className}>
      {title ? (
        <>
          <Box is="h2" className="sr-only">
            {title}
          </Box>
          <Helmet title={title} />
        </>
      ) : null}

      {children}
    </Box>
  );
};

const Body = ({
  children,
  headerLess = false,
}: PropsWithChildren<{ headerLess?: boolean }>): JSX.Element => {
  const marginTop = headerLess ? '' : 'mt-8 md:mt-10';
  const className = `
  flex flex-col items-center
  w-full
  ${marginTop}
  `;
  return (
    <Box is="div" className={className}>
      {children}
    </Box>
  );
};

Wrapper.displayName = 'transparentPanel.Wrapper';
Header.displayName = 'transparentPanel.Header';
Body.displayName = 'transparentPanel.Body';

export default { Wrapper, Header, Body };
