import PersonalInfoPage from './PersonalInfoPage';
import AccountInfoPage from './AccountInfoPage';
import LinkChildPage from './LinkChildPage';
import ChildCodePage from './ChildCodePage';
import StudentCodePage from './StudentCodePage';
import MarketingAgreementPage from './MarketingAgreementPage';

const InfoPages = {
  PersonalInfoPage,
  AccountInfoPage,
  LinkChildPage,
  ChildCodePage,
  StudentCodePage,
  MarketingAgreementPage,
};

export default InfoPages;
