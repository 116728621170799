/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */
import urlParser from 'url-parse';
import jwt from 'jsonwebtoken';

import type { AxiosError, AxiosResponse } from 'axios';
import { Contexts, User } from '@sentry/types';

const SENSITIVE_DATA = ['password', 'client_secret', 'mobile', 'pw', 'passwd'];
const AuthRegexp = /(bearer|Bearer)\s(.)*/g;

export class NetworkError extends Error {
  response?: AxiosResponse;
  contexts?: Contexts;
  user?: User;

  constructor(
    message: string,
    error: AxiosError,
    contexts?: Contexts,
    user?: User
  ) {
    super(message);
    this.stack = error.stack;
    this.response = error.response;
    this.contexts = contexts;
    this.user = user;
  }
}

export type CustomAxiosError<E = unknown> = AxiosError<E> & NetworkError;

function filterSensitiveData(data?: Record<string, unknown>) {
  if (data) {
    return Object.fromEntries(
      Object.entries(data).filter(([key]) => {
        return !SENSITIVE_DATA.includes(key);
      })
    );
  }
  return data;
}

function parseData(data?: string) {
  try {
    if (typeof data === 'object') {
      return data;
    }
    if (data) {
      return JSON.parse(data);
    }
    return;
  } catch (e) {
    // ignore
    return;
  }
}

function parseErrorMessage(error: AxiosError) {
  let message = 'server error message is empty';
  if (error.response) {
    const data = error.response.data;
    // pay server
    if (data.message) {
      message = data.code ? `${data.message} (${data.code})` : data.message;
    }
    if (data.error) {
      const typeofError = typeof data.error;
      if (typeofError === 'string') {
        // auth server
        if (data.error_description) {
          message = data.error_code
            ? `${data.error_description} (${data.error_code})`
            : data.error_description;
        }
      }
      // api server
      if (typeofError === 'object') {
        message = `${data.error.message} (${data.error.code})`;
      }
    }
  }
  return message;
}

function fullUrl(url: string, baseURL?: string) {
  return baseURL ? baseURL + url : url;
}

function decryptUserId(authorization: string): string {
  return jwt.decode(authorization.replace(/^(bearer|Bearer)\s+/, ''))?.sub;
}

function modifyAxiosError(error: AxiosError): NetworkError {
  const config = error.config;
  let data = undefined;
  let status = 0;
  let headers = undefined;
  let user;
  const event = undefined;
  if (error.response) {
    data = error.response.data;
    status = error.response.status;
    headers = error.response.headers;
  }
  if (config && config.url) {
    const url = fullUrl(config.url, config.baseURL);
    const { host, pathname, query, protocol } = urlParser(url);
    const method = config.method?.toUpperCase();
    const requestBody = filterSensitiveData(parseData(config.data));
    const contexts: Contexts = {
      error: {
        method,
        host,
        pathname,
        query,
        data,
        status,
        event,
        url,
        responseHeaders: headers,
        requestBody,
      },
    };
    const message = parseErrorMessage(error);
    const {
      Authorization: originalAuthorHeader,
      ...restHeaders
    } = config.headers;
    if (originalAuthorHeader) {
      const authorization = config.headers.Authorization;
      restHeaders.Authorization = authorization.replace(
        AuthRegexp,
        '$1 filtered'
      );
      user = { id: decryptUserId(authorization) };
    }
    return new NetworkError(
      `${method} ${protocol}//${host}${pathname} [${status}]\n${message}`,
      error,
      contexts,
      user
    );
  }
  return error;
}

export default modifyAxiosError;
