import React, {
  Ref,
  forwardRef,
  useEffect,
  ComponentPropsWithRef,
} from 'react';
import classnames from 'classnames';

import { useTimer } from 'shared/hooks';

import { Box } from 'designSystem/ions';
import { TextField } from 'designSystem/molecules';
import { Without } from 'types/utilities';

type TimerTextFieldProps = {
  initialTime: number;
  onFinish?: () => void;
} & Without<ComponentPropsWithRef<typeof TextField>, 'endAdornment'>;

/**
 * @param props.onFinish useEffect 의 dependency로 잡혀있기 때문에 memoize 필요
 */
const TimerTextField = forwardRef(
  (
    {
      type,
      label,
      isError = false,
      helperTexts = [],
      name,
      placeholder,
      testId,
      initialTime,
      onChange,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onBlur,
      onFocus,
      onFinish,
    }: TimerTextFieldProps,
    forwardedRef: Ref<HTMLInputElement>
  ): JSX.Element => {
    const { time, timeString } = useTimer(initialTime);
    const timeout = time === 0;
    const timeFontStyle = classnames(
      'text-body-3 md:text-body-1 font-regular',
      {
        'text-ctGreen-500': !(isError || timeout),
        'text-status-error': isError || timeout,
      }
    );

    useEffect(() => {
      timeout && onFinish?.();
    }, [timeout, onFinish]);

    return (
      <Box is="div" className="relative w-full">
        <TextField
          ref={forwardedRef}
          type={type}
          label={label}
          helperTexts={helperTexts}
          name={name}
          placeholder={placeholder}
          endAdornment={
            <Box is="p" className={timeFontStyle}>
              {timeString}
            </Box>
          }
          isError={isError || timeout}
          testId={testId}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Box>
    );
  }
);

TimerTextField.displayName = 'TimerTextField';

export default TimerTextField;
