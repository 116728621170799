/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */
interface storageOptions {
  raw: boolean; //determine to do not serialize or deserialize. default is false;
}

export class StorageService {
  private storage: Storage;

  public constructor(storage: Storage) {
    this.storage = storage;
  }

  setItem = <T = unknown>(
    key: string,
    value: T | string,
    options: storageOptions = {
      raw: false,
    }
  ): void => {
    if (options.raw) {
      this.storage.setItem(key, value as string);
    } else if (typeof value === 'string') {
      this.storage.setItem(key, value);
    } else {
      try {
        this.storage.setItem(key, JSON.stringify(value));
      } catch (e) {
        console.error('storage.service:: json stringify error', e);
      }
    }
  };

  getItem = <T = unknown>(
    key: string,
    options: storageOptions = {
      raw: false,
    }
  ): T | string | null => {
    if (options.raw) {
      return this.storage.getItem(key);
    } else {
      const item = this.storage.getItem(key);
      try {
        if (item === null || item === 'null' || item === '') {
          return null;
        }
        return JSON.parse(item) as T;
      } catch (e) {
        return item;
      }
    }
  };

  removeItem = (key: string): void => {
    this.storage.removeItem(key);
  };

  clear = (): void => {
    this.storage.clear();
  };
}
