import React, { PropsWithChildren } from 'react';

import { Box } from 'designSystem/ions';

const Wrapper = ({
  children,
  testId,
  className: classNameProp,
}: PropsWithChildren<{ testId?: string; className?: string }>): JSX.Element => {
  const className = `
  flex flex-col justify-center items-center
  w-full
  py-5 px-6
  bg-white rounded-lg shadow-card
  ${classNameProp}
  `;
  return (
    <Box is="article" data-testid={testId} className={className}>
      {children}
    </Box>
  );
};

const Header = ({
  children,
  title,
}: PropsWithChildren<{ title: string }>): JSX.Element => {
  return (
    <Box
      is="header"
      className="
  flex flex-col items-center
  w-full"
    >
      <Box is="h3" className="sr-only">
        {title}
      </Box>
      {children}
    </Box>
  );
};

const Body = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Box
      is="div"
      className="
      flex flex-row items-center
      w-full"
    >
      {children}
    </Box>
  );
};

const Left = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Box is="div" className="flex flex-col w-full flex-1 items-start pr-3">
      {children}
    </Box>
  );
};

const Right = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Box is="div" className="flex flex-initial flex-col items-start">
      {children}
    </Box>
  );
};

Wrapper.displayName = 'SimpleCard.Wrapper';
Header.displayName = 'SimpleCard.Header';
Body.displayName = 'SimpleCard.Body';
Left.displayName = 'SimpleCard.Left';
Right.displayName = 'SimpleCard.Right';

export default {
  Wrapper: React.memo(Wrapper),
  Header: React.memo(Header),
  Body: React.memo(Body),
  Left: React.memo(Left),
  Right: React.memo(Right),
};
