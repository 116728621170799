import React from 'react';

import useTranslatedTexts from './useTranslatedTexts';
import { ChangeEmailForm } from './ChangeEmailForm';

import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { Box } from 'designSystem/ions';
import {
  CenterTitleNavigationHeader,
  SectionPanel,
} from 'designSystem/organisms';
import { SingleColumnPageTemplate } from 'designSystem/templates';

type ChangeEmailProps = {
  account: Account;
};
const ChangeEmail = ({ account }: ChangeEmailProps): JSX.Element => {
  const texts = useTranslatedTexts();

  return (
    <SingleColumnPageTemplate
      headerChild={
        <CenterTitleNavigationHeader title={texts.changeEmail.title} />
      }
      showFooter={false}
      hasHeaderShadow={false}
    >
      <SectionPanel.Wrapper testId="account-info-panel" className="w-full">
        <SectionPanel.Header
          showBackButton
          title={texts.changeEmail.title}
          description={texts.changeEmail.description}
          backTo="/account/info"
        />
        <SectionPanel.Body>
          <Box className="w-full" data-testid="change-email-form">
            <ChangeEmailForm account={account} />
          </Box>
        </SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default withAccount(ChangeEmail);
