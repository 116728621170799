import { StorageService } from '../storage/storage.service';

import { sessionStorageService } from 'core/storage';

const challengingAccountId = 'challenging_account_id';

export class ChallengeService {
  private storageService: StorageService;
  private challengeNeedPaths = [
    '/account/delete',
    '/account/change-email',
    '/account/change-mobile',
  ];

  public constructor(storageService: StorageService) {
    this.storageService = storageService;
  }

  setChallengeAccountId = (accountId: string | null): void => {
    this.storageService.setItem(challengingAccountId, accountId);
  };

  checkChallenging = (accountId: string | null): boolean => {
    const challengeAccountId = this.getChallengeAccountId();
    this.removeChallengeAccountId();
    return !!challengeAccountId && accountId === challengeAccountId;
  };

  checkChallengingPath = (path: string): boolean => {
    return this.challengeNeedPaths.includes(path);
  };

  private getChallengeAccountId = (): unknown => {
    return this.storageService.getItem(challengingAccountId, { raw: true });
  };

  private removeChallengeAccountId = (): void => {
    this.storageService.removeItem(challengingAccountId);
  };
}

const challengeService = new ChallengeService(sessionStorageService);

export const {
  setChallengeAccountId,
  checkChallengingPath,
  checkChallenging,
} = challengeService;
