/* istanbul ignore file */
/**
 * @TODO test 구현 필요
 */

import React, { PropsWithChildren } from 'react';

import type { CTAWithAssetProps } from '../CallToAction.types';

import { Box, Helmet } from 'designSystem/ions';

const CTAWithRightAsset = ({
  title = '',
  desc = '',
  asset: Asset,
  width = 'auto',
  children,
}: PropsWithChildren<CTAWithAssetProps>): JSX.Element => {
  const titleStyle = `
  mb-3 md:mb-5
  text-headline-3 md:text-headline-2
  text-center md:text-left
  font-medium
  text-gray-900
  break-all whitespace-pre-line
  `;

  const descStyle = `
  text-caption md:text-body-3
  text-center md:text-left
  font-regular
  text-gray-700
  break-all whitespace-pre-line
  `;
  const wrapperStyle = { width };

  return (
    <Box
      is="div"
      className="flex flex-col sm:mt-2 md:mt-8 md:flex-row-reverse md:justify-between md:w-full"
    >
      <Box
        is="div"
        className="relative flex flex-col md:flex-initial self-center"
        style={wrapperStyle}
      >
        <Asset />
      </Box>
      <Box
        is="div"
        data-test-id="portrait-asset-title-box"
        className="
        flex
        flex-col md:flex-grow
        items-center md:items-start
        mt-8 md:mt-10
        mr-0 md:mr-3
        "
      >
        <Helmet title={title} desc={desc} />
        <Box is="h2" className={titleStyle}>
          {title}
        </Box>
        <Box is="span" className={descStyle}>
          {desc}
        </Box>
        <Box is="div" className="flex flex-col items-start w-full mt-8">
          <Box
            is="div"
            className="flex flex-row items-start justify-center md:justify-start w-full h-11"
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CTAWithRightAsset);
