import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { Account } from '../data/account/account.model';

import { FullScreenLoader } from 'designSystem/templates';
import { useAccountCheck } from 'shared/root';

export const withAccount = (
  Component: ({
    account,
  }: {
    account: Account;
    routeProps: RouteComponentProps;
  }) => JSX.Element
) => (props: RouteComponentProps): JSX.Element => {
  const { data: account } = useAccountCheck();
  if (account) {
    return <Component account={account} routeProps={props} />;
  } else {
    return <FullScreenLoader />;
  }
};
