import React from 'react';
import Cookies from 'universal-cookie';
import { RouteProps, useHistory } from 'react-router-dom';

import { sessionStorageService } from 'core/storage';
import { FullScreenLoader } from 'designSystem/templates';
import { setChallengeAccountId } from 'core/challenge/challenge.service';

const ChallengeCallbackPage = ({ location }: RouteProps): JSX.Element => {
  const cookies = new Cookies();
  const challengeCookie = cookies.get('challenging_account_id');
  const history = useHistory();

  setChallengeAccountId(challengeCookie);
  const redirectUrl: string = sessionStorageService.getItem('redirect') ?? '/';
  sessionStorageService.removeItem('redirect');

  history.replace(redirectUrl);
  return <FullScreenLoader />;
};

export default ChallengeCallbackPage;
