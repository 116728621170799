import React, { memo } from 'react';

import { GlobalSection } from 'designSystem/layouts';
import { Spinner } from 'designSystem/atoms';
import { Box } from 'designSystem/ions';

const FullScreenLoader = (): JSX.Element => {
  return (
    <GlobalSection.Wrapper>
      <Box
        is="div"
        className="
      flex flex-1-0
      z-10 bg-gray-50
      justify-center items-center
      "
        data-testid="full-screen-loader"
      >
        <Box
          is="div"
          className="flex w-full h-full justify-center items-center"
        >
          <Spinner />
        </Box>
      </Box>
      <GlobalSection.Footer></GlobalSection.Footer>
    </GlobalSection.Wrapper>
  );
};

export default memo(FullScreenLoader);
