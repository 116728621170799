// 010-12**-56**
export function maskingMobileNumber(mobileNumber: string): string {
  mobileNumber = mobileNumber.replace(/-/g, '');
  const isLagacyNumber = mobileNumber.length === 10; // 011-123-4567
  const pattern = /(\d{3})(\d{2})\d{1,2}(\d{2})\d{2}/;
  let result = '';

  if (pattern.test(mobileNumber)) {
    result = mobileNumber.replace(
      pattern,
      `$1-$2${isLagacyNumber ? '*' : '**'}-$3**`
    );
  }

  return result;
}
