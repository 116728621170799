/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { CanDeleteResponse } from './user.model';

import { create } from 'core/axios';

import { LEGACY_CLASSTING_URL } from 'shared/constants';

const axiosInstance = create({
  baseURL: `${LEGACY_CLASSTING_URL}/api/users`,
});

export async function fetchCanDelete() {
  const response = await axiosInstance.get<CanDeleteResponse>('/me/can_delete');
  return response.data;
}
