/* istanbul ignore files */

export interface TagManagerEventDefinition {
  name: string;
  on: 'click';
  properties?: Record<string, unknown>;
}

interface TagManagerTrackEventProps {
  'data-event-on': string;
  'data-event-name': string;
  'data-event-properties'?: string;
}

export function createTrackEventProps({
  name,
  on,
  properties,
}: TagManagerEventDefinition): TagManagerTrackEventProps {
  const props: TagManagerTrackEventProps = {
    'data-event-on': on,
    'data-event-name': name,
  };

  if (properties) {
    props['data-event-properties'] = JSON.stringify(properties);
  }

  return props;
}
