import React from 'react';

const size = {
  width: '24px',
  height: '24px',
};

const ExclamationCircle = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M12 8v4-4zm0 8h.01H12zm9-4a9 9 0 11-18 0 9 9 0 0118 0z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Asset = {
  size,
  View: ExclamationCircle,
};

export default Asset;
