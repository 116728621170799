/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, UseMutationOptions } from 'react-query';

import { createImageRecord, uploadImage } from './media-service.apis';
import {
  CreateImageRecordResponse,
  CreateImageRecordParam,
  UploadImageParam,
} from './media-service.model';

import { CustomAxiosError } from 'core/axios';

export function useCreateImageRecord(
  options:
    | UseMutationOptions<
        CreateImageRecordResponse,
        CustomAxiosError<unknown>,
        CreateImageRecordParam,
        unknown
      >
    | undefined = {}
) {
  return useMutation<
    CreateImageRecordResponse,
    CustomAxiosError<unknown>,
    CreateImageRecordParam,
    unknown
  >('/createImageRecord', createImageRecord, {
    retry: false,
    ...options,
  });
}

export function useUploadImage(
  options:
    | UseMutationOptions<
        void,
        CustomAxiosError<unknown>,
        UploadImageParam,
        unknown
      >
    | undefined = {}
) {
  return useMutation<
    void,
    CustomAxiosError<unknown>,
    UploadImageParam,
    unknown
  >('/uploadImage', uploadImage, {
    retry: false,
    ...options,
  });
}
