import React from 'react';

const size = {
  width: '16px',
  height: '16px',
};

const UserIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="4" fill="#757575" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9999 7.45002C8.43751 7.45002 8.85719 7.27619 9.16663 6.96675C9.47606 6.65732 9.6499 6.23763 9.6499 5.80002C9.6499 5.36242 9.47606 4.94273 9.16663 4.6333C8.85719 4.32386 8.43751 4.15002 7.9999 4.15002C7.5623 4.15002 7.14261 4.32386 6.83318 4.6333C6.52374 4.94273 6.3499 5.36242 6.3499 5.80002C6.3499 6.23763 6.52374 6.65732 6.83318 6.96675C7.14261 7.27619 7.5623 7.45002 7.9999 7.45002ZM4.1499 12.4C4.1499 11.8944 4.24949 11.3938 4.44297 10.9267C4.63645 10.4596 4.92004 10.0352 5.27754 9.67766C5.63505 9.32016 6.05947 9.03657 6.52657 8.84309C6.99367 8.64961 7.49431 8.55002 7.9999 8.55002C8.50549 8.55002 9.00613 8.64961 9.47323 8.84309C9.94034 9.03657 10.3648 9.32016 10.7223 9.67766C11.0798 10.0352 11.3634 10.4596 11.5568 10.9267C11.7503 11.3938 11.8499 11.8944 11.8499 12.4H4.1499Z"
        fill="white"
      />
    </svg>
  );
};

const Asset = {
  size,
  View: UserIcon,
};
export default Asset;
