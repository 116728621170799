import { useState, useEffect, useCallback } from 'react';

const SECOND = 1000;

const toMinuteString = (second: number) => {
  const min = String(Math.floor(second / 60)).padStart(2, '0');
  const sec = String(Math.floor(second % 60)).padStart(2, '0');
  return `${min}:${sec}`;
};

export const useTimer = (
  initialSeconds: number
): {
  time: number;
  timeString: string;
  set: (time?: number) => void;
} => {
  const [seconds, setTime] = useState(initialSeconds);
  const [triggerFlag, trigger] = useState(false);

  useEffect(() => {
    let timer = window.setTimeout(function tick() {
      clearTimeout(timer);
      setTime((seconds) => {
        if (seconds > 0) {
          timer = window.setTimeout(tick, SECOND);
        }
        return Math.max(seconds - 1, 0);
      });
    }, SECOND);

    return () => clearTimeout(timer);
  }, [initialSeconds, triggerFlag]);

  const set = useCallback((time = 0) => {
    setTime(time);
    trigger((value) => !value);
  }, []);

  return {
    set,
    time: seconds,
    timeString: toMinuteString(seconds),
  };
};
