export type Theme = 'primary' | 'danger' | 'white' | 'gray';

export interface ColorObject {
  normal: string;
  dark: string;
}

export type ThemeDictionary = {
  [key in Theme]: ColorObject;
};

export interface ThemeObject {
  color: ColorObject;
  bg: ColorObject;
  border: ColorObject;
}

export const colorThemeDictionary: ThemeDictionary = {
  primary: {
    normal: 'text-primary-normal',
    dark: 'text-primary-dark',
  },
  white: {
    normal: 'text-gray-700',
    dark: 'text-gray-700',
  },
  danger: {
    normal: 'text-danger-normal',
    dark: 'text-danger-dark',
  },
  gray: {
    normal: 'text-gray-800',
    dark: 'text-gray-800',
  },
};

export const bgThemeDictionary: ThemeDictionary = {
  primary: {
    normal: 'bg-primary-normal',
    dark: 'bg-primary-dark',
  },
  white: {
    normal: 'bg-white',
    dark: 'bg-gray-100',
  },
  danger: {
    normal: 'bg-danger-normal',
    dark: 'bg-danger-dark',
  },
  gray: {
    normal: 'bg-gray-200',
    dark: 'bg-gray-200',
  },
};

export const borderThemeDictionary: ThemeDictionary = {
  primary: {
    normal: 'border-primary-normal',
    dark: 'border-primary-dark',
  },
  white: {
    normal: 'border-gray-400',
    dark: 'border-gray-500',
  },
  danger: {
    normal: 'border-danger-normal',
    dark: 'border-danger-dark',
  },
  gray: {
    normal: 'border-gray-200',
    dark: 'border-gray-200',
  },
};

export const getThemeObject = (theme: Theme): ThemeObject => {
  const color = colorThemeDictionary[theme] ?? colorThemeDictionary.primary;
  const bg = bgThemeDictionary[theme] ?? bgThemeDictionary.primary;
  const border = borderThemeDictionary[theme] ?? borderThemeDictionary.primary;
  return {
    color,
    bg,
    border,
  };
};
