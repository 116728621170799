import React from 'react';

const size = {
  width: '150px',
  height: '300px',
};
function BinoSorry(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 150 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.125 291C104.948 291 129.125 280.255 129.125 267C129.125 253.745 104.948 243 75.125 243C45.3016 243 21.125 253.745 21.125 267C21.125 280.255 45.3016 291 75.125 291Z"
        fill="black"
        fillOpacity="0.15"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M120.255 269.898L140.092 269.812C143.385 269.865 146.096 267.24 146.149 263.947C146.201 260.655 143.576 257.943 140.284 257.891L120.446 257.977C118.14 257.94 116.22 255.96 116.257 253.65C116.404 244.728 109.264 237.352 100.342 237.206L94.556 237.112C91.2635 237.06 88.5523 239.685 88.4998 242.977C88.4473 246.27 91.0723 248.981 94.3648 249.033L100.151 249.127C102.499 249.165 104.377 251.107 104.34 253.455C104.194 262.376 111.334 269.752 120.255 269.898Z"
          fill="white"
        />
        <path
          d="M120.254 271.023C120.246 271.023 120.243 271.023 120.235 271.023C115.619 270.948 111.306 269.077 108.085 265.751C104.868 262.425 103.135 258.052 103.21 253.44C103.225 252.603 102.91 251.812 102.325 251.212C101.744 250.608 100.96 250.271 100.128 250.256L94.3414 250.162C90.4339 250.098 87.3063 246.87 87.3701 242.962C87.4338 239.055 90.6626 235.935 94.5701 235.991L100.356 236.085C104.973 236.16 109.285 238.031 112.506 241.357C115.724 244.683 117.456 249.056 117.381 253.668C117.355 255.367 118.765 256.826 120.464 256.852L140.279 256.766C142.195 256.796 143.961 257.561 145.278 258.922C146.594 260.283 147.303 262.072 147.273 263.966C147.243 265.86 146.478 267.626 145.116 268.942C143.755 270.258 141.959 270.956 140.073 270.937L120.258 271.023C120.258 271.023 120.258 271.023 120.254 271.023ZM94.4576 238.237C91.8251 238.237 89.6651 240.356 89.6239 242.996C89.5789 245.662 91.7163 247.867 94.3826 247.912L100.169 248.006C101.605 248.028 102.948 248.613 103.949 249.645C104.95 250.676 105.49 252.041 105.464 253.473C105.4 257.49 106.904 261.292 109.705 264.183C112.503 267.075 116.253 268.702 120.261 268.77L140.088 268.683C141.396 268.702 142.626 268.222 143.553 267.322C144.479 266.422 145.004 265.218 145.023 263.925C145.045 262.635 144.561 261.408 143.661 260.482C142.761 259.552 141.558 259.031 140.264 259.012L120.449 259.098C117.509 259.05 115.083 256.545 115.131 253.627C115.195 249.611 113.691 245.808 110.89 242.917C108.089 240.026 104.335 238.395 100.323 238.331L94.5364 238.237C94.5101 238.237 94.4839 238.237 94.4576 238.237Z"
          fill="#9E9E9E"
        />
        <path
          d="M126.091 119.966C99.489 106.429 91.5202 126.184 75.2903 146.063C59.0603 126.184 51.0915 106.429 24.489 119.966C11.064 126.799 1.76776 149.303 7.96651 170.145C14.169 190.988 34.5315 200.67 43.749 200.67C44.5628 200.67 50.1165 200.67 64.4603 200.67C64.8503 200.67 65.244 200.67 65.6453 200.67C74.3603 200.67 82.0703 200.67 84.9352 200.67C85.7002 200.67 86.1202 200.67 86.1202 200.67C100.46 200.67 106.018 200.67 106.831 200.67C116.049 200.67 136.411 190.988 142.614 170.145C148.813 149.303 139.516 126.799 126.091 119.966Z"
          fill="white"
        />
        <path
          d="M106.832 201.795H43.7492C38.293 201.795 30.568 198.791 24.073 194.137C18.193 189.93 10.4417 182.404 6.89048 170.467C3.92048 160.481 4.24673 149.46 7.80923 139.425C11.1805 129.934 17.0755 122.475 23.9792 118.961C34.6517 113.527 43.1567 112.942 50.7392 117.112C57.553 120.862 62.8705 127.935 69.028 136.125C71.0267 138.784 73.0817 141.521 75.2942 144.274C77.503 141.521 79.5617 138.784 81.5605 136.125C87.718 127.935 93.0317 120.862 99.8492 117.112C107.432 112.942 115.933 113.531 126.609 118.961C133.517 122.475 139.408 129.934 142.779 139.425C146.342 149.456 146.668 160.481 143.698 170.467C140.147 182.404 132.392 189.93 126.515 194.137C120.013 198.787 112.288 201.795 106.832 201.795ZM40.1567 116.644C35.8517 116.644 30.9092 117.96 24.9992 120.967C12.4742 127.342 2.84423 148.972 9.04673 169.822C14.9342 189.611 34.468 199.541 43.7492 199.541H106.832C116.113 199.541 135.647 189.611 141.534 169.822C147.737 148.972 138.107 127.339 125.582 120.967C104.015 109.991 95.3492 121.519 83.353 137.475C81.0805 140.497 78.733 143.621 76.1605 146.771C75.9467 147.034 75.628 147.184 75.2905 147.184C74.953 147.184 74.6342 147.03 74.4205 146.771C71.848 143.621 69.5005 140.497 67.228 137.475C58.5205 125.895 51.5642 116.644 40.1567 116.644Z"
          fill="#9E9E9E"
        />
        <path
          d="M39.6207 192.397C39.0845 198.472 38.792 205.02 38.792 211.849C38.792 235.136 42.1932 255.161 47.0757 264.116C49.0632 268.661 53.6045 271.86 58.862 271.86C65.9345 271.86 71.7245 266.074 71.7245 258.997V246.926H78.857V258.997C78.857 266.07 84.6432 271.86 91.7195 271.86C96.977 271.86 101.518 268.661 103.506 264.116C108.388 255.161 111.789 235.136 111.789 211.849C111.789 205.02 111.497 198.469 110.961 192.397"
          fill="white"
        />
        <path
          d="M91.7193 272.985C84.0093 272.985 77.7318 266.711 77.7318 258.997V248.051H72.8493V258.997C72.8493 266.711 66.5756 272.985 58.8618 272.985C53.3306 272.985 48.3131 269.7 46.0631 264.615C40.9593 255.21 37.6631 234.51 37.6631 211.853C37.6631 205.148 37.9443 198.57 38.4993 192.3C38.5556 191.681 39.0993 191.228 39.7181 191.28C40.3368 191.336 40.7943 191.88 40.7381 192.499C40.1906 198.705 39.9131 205.215 39.9131 211.853C39.9131 234.199 43.1118 254.501 48.0618 263.58C48.0768 263.61 48.0918 263.636 48.1031 263.666C49.9818 267.96 54.2043 270.735 58.8581 270.735C65.3306 270.735 70.5956 265.47 70.5956 258.997V246.926C70.5956 246.304 71.0981 245.801 71.7206 245.801H78.8531C79.4756 245.801 79.9781 246.304 79.9781 246.926V258.997C79.9781 265.47 85.2431 270.735 91.7156 270.735C96.3693 270.735 100.592 267.96 102.471 263.666C102.482 263.636 102.497 263.606 102.512 263.58C107.462 254.501 110.661 234.195 110.661 211.853C110.661 205.215 110.383 198.705 109.836 192.499C109.779 191.88 110.237 191.333 110.856 191.28C111.474 191.231 112.022 191.681 112.074 192.3C112.629 198.57 112.911 205.148 112.911 211.853C112.911 234.51 109.614 255.21 104.511 264.615C102.268 269.7 97.2506 272.985 91.7193 272.985Z"
          fill="#9E9E9E"
        />
        <path
          d="M75.2898 187.417C109.129 187.417 136.561 159.985 136.561 126.146C136.561 92.3071 109.129 64.875 75.2898 64.875C41.4506 64.875 14.0186 92.3071 14.0186 126.146C14.0186 159.985 41.4506 187.417 75.2898 187.417Z"
          fill="white"
        />
        <path
          d="M75.29 188.539C58.625 188.539 42.9537 182.048 31.1712 170.265C19.385 158.479 12.8975 142.811 12.8975 126.146C12.8975 109.481 19.385 93.81 31.1712 82.0238L31.9662 82.8187L31.1712 82.0238C42.9537 70.2413 58.625 63.75 75.29 63.75C91.955 63.75 107.626 70.2413 119.409 82.0238C131.195 93.81 137.682 109.478 137.682 126.143C137.682 142.808 131.191 158.479 119.409 170.261C107.626 182.051 91.955 188.539 75.29 188.539ZM75.29 66C59.225 66 44.12 72.255 32.7612 83.6175C21.4025 94.9762 15.1437 110.081 15.1437 126.146C15.1437 142.211 21.4025 157.312 32.7612 168.675C44.12 180.037 59.225 186.292 75.29 186.292C91.355 186.292 106.46 180.037 117.819 168.675C129.177 157.316 135.436 142.211 135.436 126.146C135.436 110.081 129.181 94.9762 117.819 83.6175C106.46 72.255 91.355 66 75.29 66Z"
          fill="#9E9E9E"
        />
        <path
          d="M81.2079 156.154H69.3766C66.9991 156.154 65.0566 154.207 65.0566 151.834C65.0566 149.456 67.0029 147.514 69.3766 147.514H81.2079C83.5854 147.514 85.5279 149.46 85.5279 151.834C85.5279 154.207 83.5817 156.154 81.2079 156.154Z"
          fill="#FF9B96"
        />
        <path
          d="M81.2079 157.279H69.3766C66.3729 157.279 63.9316 154.834 63.9316 151.834C63.9316 148.834 66.3766 146.389 69.3766 146.389H81.2079C84.2116 146.389 86.6529 148.834 86.6529 151.834C86.6529 154.834 84.2079 157.279 81.2079 157.279ZM69.3729 148.635C67.6104 148.635 66.1779 150.067 66.1779 151.83C66.1779 153.592 67.6104 155.025 69.3729 155.025H81.2041C82.9666 155.025 84.3991 153.592 84.3991 151.83C84.3991 150.067 82.9666 148.635 81.2041 148.635H69.3729Z"
          fill="#C86E6E"
        />
        <path
          d="M65.2559 164.235C66.2009 168.904 70.3521 169.106 75.2909 169.106C80.2296 169.106 84.3809 168.904 85.3259 164.235H65.2559Z"
          fill="#DA5036"
        />
        <path
          d="M75.2909 170.231C70.8509 170.231 65.3234 170.231 64.1534 164.456C64.0859 164.126 64.1721 163.781 64.3859 163.518C64.5996 163.256 64.9184 163.106 65.2559 163.106H85.3259C85.6634 163.106 85.9821 163.256 86.1959 163.518C86.4096 163.781 86.4959 164.122 86.4284 164.456C85.2584 170.231 79.7309 170.231 75.2909 170.231ZM66.8046 165.36C68.0084 167.782 70.9334 167.981 75.2909 167.981C79.6484 167.981 82.5696 167.782 83.7771 165.36H66.8046Z"
          fill="#8E4149"
        />
        <path
          d="M92.3021 161.876C91.4808 163.286 89.9546 164.235 88.2146 164.235H62.3658C60.6258 164.235 59.0996 163.283 58.2783 161.876"
          fill="white"
        />
        <path
          d="M88.2181 165.36H62.3656C60.2956 165.36 58.3568 164.243 57.3068 162.443C56.9955 161.906 57.1756 161.216 57.7118 160.905C58.2481 160.594 58.9381 160.774 59.2493 161.31C59.8981 162.42 61.0905 163.11 62.3618 163.11H88.2143C89.4893 163.11 90.6818 162.42 91.3268 161.31C91.6381 160.774 92.3281 160.59 92.8643 160.905C93.4006 161.216 93.5843 161.906 93.2693 162.443C92.2268 164.243 90.2881 165.36 88.2181 165.36Z"
          fill="#616161"
        />
        <path
          d="M57.0246 153.521H44.1621C43.2284 153.521 42.4746 152.767 42.4746 151.833C42.4746 150.9 43.2284 150.146 44.1621 150.146H57.0246C57.9583 150.146 58.7121 150.9 58.7121 151.833C58.7121 152.767 57.9546 153.521 57.0246 153.521Z"
          fill="#616161"
        />
        <path
          d="M45.3428 148.635C44.7203 148.635 44.2178 148.132 44.2178 147.51C44.2178 146.887 44.7203 146.385 45.3428 146.385C52.4828 146.385 54.7215 141.585 54.8115 141.379C55.0665 140.816 55.7265 140.557 56.2928 140.809C56.859 141.06 57.1178 141.716 56.8703 142.282C56.7615 142.545 54.009 148.635 45.3428 148.635Z"
          fill="#9E9E9E"
        />
        <path
          d="M68.1315 135.277C67.8052 135.277 67.479 135.135 67.2578 134.861C66.8678 134.377 66.9427 133.669 67.4227 133.279C69.0277 131.977 69.174 128.936 69.0915 127.871C69.0465 127.252 69.5077 126.712 70.1265 126.664C70.7452 126.615 71.2852 127.076 71.334 127.695C71.349 127.897 71.694 132.712 68.8365 135.026C68.6302 135.195 68.379 135.277 68.1315 135.277Z"
          fill="#9E9E9E"
        />
        <path
          d="M75.7306 137.246C75.4081 137.246 75.0894 137.108 74.8644 136.841C73.5818 135.308 73.7056 131.629 73.7431 130.909C73.7768 130.29 74.3018 129.81 74.9243 129.844C75.5431 129.878 76.0231 130.406 75.9894 131.025C75.9031 132.686 76.1244 134.843 76.5894 135.401C76.9869 135.878 76.9231 136.586 76.4469 136.988C76.2406 137.16 75.9856 137.246 75.7306 137.246Z"
          fill="#9E9E9E"
        />
        <path
          d="M82.6066 137.246C82.4903 137.246 82.3703 137.228 82.2503 137.186C78.3578 135.889 78.5753 130.504 78.5866 130.275C78.6166 129.656 79.1453 129.173 79.7641 129.206C80.3828 129.236 80.8628 129.761 80.8328 130.384C80.8291 130.44 80.6941 134.299 82.9591 135.053C83.5478 135.248 83.8665 135.885 83.6716 136.474C83.5178 136.95 83.0791 137.246 82.6066 137.246Z"
          fill="#9E9E9E"
        />
        <path
          d="M75.6143 177.484C72.9068 177.484 70.8143 176.704 70.6831 176.655C70.1018 176.434 69.8131 175.785 70.0343 175.204C70.2556 174.622 70.9043 174.334 71.4856 174.551C71.5381 174.57 75.5956 176.062 79.2856 174.562C79.8593 174.326 80.5156 174.604 80.7519 175.181C80.9844 175.755 80.7106 176.415 80.1331 176.647C78.5881 177.274 77.0168 177.484 75.6143 177.484Z"
          fill="#9E9E9E"
        />
        <path
          d="M106.397 153.521H93.5342C92.6004 153.521 91.8467 152.767 91.8467 151.833C91.8467 150.9 92.6004 150.146 93.5342 150.146H106.397C107.33 150.146 108.084 150.9 108.084 151.833C108.084 152.767 107.33 153.521 106.397 153.521Z"
          fill="#616161"
        />
        <path
          d="M105.216 148.635C96.5496 148.635 93.7971 142.541 93.6846 142.283C93.4371 141.713 93.6959 141.049 94.2659 140.801C94.8321 140.558 95.4959 140.813 95.7434 141.379C95.8409 141.596 98.0834 146.385 105.212 146.385C105.835 146.385 106.337 146.888 106.337 147.51C106.337 148.133 105.838 148.635 105.216 148.635Z"
          fill="#9E9E9E"
        />
        <path
          d="M18.6105 108.315C21.0405 104.239 24.0705 100.511 27.7043 97.14C33.6856 90.7013 40.7656 86.1525 48.9518 83.49C50.8756 82.4813 52.8331 81.6075 54.8281 80.8613C53.6993 78.3338 52.1018 75.9638 50.0281 73.89C40.9156 64.7775 26.1443 64.7775 17.0355 73.89C7.92305 83.0025 7.92305 97.7738 17.0355 106.883C17.5418 107.389 18.0705 107.865 18.6105 108.315Z"
          fill="white"
        />
        <path
          d="M18.6101 109.44C18.3476 109.44 18.0926 109.35 17.8901 109.178C17.2863 108.671 16.7463 108.18 16.2401 107.678C6.70383 98.1414 6.70383 82.6276 16.2401 73.0951C25.7763 63.5626 41.2901 63.5589 50.8226 73.0951C52.9413 75.2139 54.6363 77.6739 55.8551 80.4039C55.9826 80.6889 55.9863 81.0114 55.8663 81.2964C55.7463 81.5814 55.5138 81.8064 55.2213 81.9151C53.2676 82.6426 51.3363 83.5051 49.4763 84.4839C49.4201 84.5139 49.3601 84.5364 49.3001 84.5589C41.3351 87.1501 34.3488 91.6389 28.5288 97.9051C28.5101 97.9239 28.4913 97.9464 28.4688 97.9651C24.9326 101.25 21.9401 104.925 19.5738 108.893C19.4051 109.174 19.1201 109.369 18.7976 109.425C18.7376 109.436 18.6738 109.44 18.6101 109.44ZM33.5313 68.1901C27.8426 68.1901 22.1613 70.3539 17.8301 74.6814C9.17133 83.3401 9.17133 97.4251 17.8301 106.084C17.9988 106.253 18.1713 106.421 18.3513 106.586C20.6988 102.885 23.5713 99.4426 26.9088 96.3376C32.9651 89.8276 40.2326 85.1551 48.5126 82.4439C50.0651 81.6301 51.6663 80.8951 53.2901 80.2464C52.2363 78.1914 50.8751 76.3239 49.2326 74.6814C44.9013 70.3576 39.2163 68.1901 33.5313 68.1901Z"
          fill="#9E9E9E"
        />
        <path
          d="M25.7389 99.0678C26.3764 98.4153 27.0252 97.7703 27.7039 97.1403C32.4589 92.0216 37.9114 88.0991 44.0614 85.3691C43.5064 84.2028 42.7452 83.1078 41.7777 82.1403C37.2214 77.5841 29.8377 77.5841 25.2814 82.1403C20.7252 86.6966 20.7252 94.0803 25.2814 98.6366C25.4314 98.7828 25.5852 98.9253 25.7389 99.0678Z"
          fill="#FF9B96"
        />
        <path
          d="M25.7388 100.193C25.4688 100.193 25.2026 100.099 24.9851 99.9039C24.8163 99.7501 24.6476 99.5926 24.4826 99.4314C22.0676 97.0164 20.7363 93.8064 20.7363 90.3901C20.7363 86.9739 22.0676 83.7639 24.4826 81.3451C29.4701 76.3576 37.5851 76.3576 42.5688 81.3451C43.6038 82.3801 44.4438 83.5689 45.0701 84.8851C45.2013 85.1589 45.2163 85.4739 45.1113 85.7589C45.0063 86.0439 44.7888 86.2726 44.5113 86.3964C38.5301 89.0551 33.1526 92.9289 28.5288 97.9051C28.5101 97.9239 28.4913 97.9464 28.4688 97.9651C27.8501 98.5426 27.2201 99.1576 26.5451 99.8514C26.3238 100.076 26.0313 100.193 25.7388 100.193ZM33.5313 79.8526C30.8313 79.8526 28.1351 80.8801 26.0801 82.9351C24.0888 84.9264 22.9938 87.5739 22.9938 90.3864C22.9938 93.0339 23.9613 95.5314 25.7351 97.4776C26.1363 97.0801 26.5263 96.7051 26.9126 96.3451C31.4501 91.4664 36.6926 87.6039 42.5013 84.8476C42.0738 84.1539 41.5676 83.5126 40.9863 82.9351C38.9276 80.8801 36.2276 79.8526 33.5313 79.8526Z"
          fill="#D88484"
        />
        <path
          d="M107.706 86.8165L121.067 97.1403C125.327 101.427 128.893 106.118 131.784 111.203C132.954 110.4 134.072 109.485 135.111 108.447C144.223 99.334 144.223 84.5628 135.111 75.454C125.998 66.3415 111.227 66.3415 102.118 75.454C100.164 77.4078 98.6343 79.6203 97.5205 81.9828C100.997 83.2878 104.394 84.8965 107.706 86.8165Z"
          fill="white"
        />
        <path
          d="M131.784 112.328C131.702 112.328 131.615 112.316 131.533 112.298C131.225 112.226 130.963 112.031 130.809 111.758C127.982 106.785 124.453 102.154 120.32 97.9839L107.075 87.7502C103.869 85.8939 100.52 84.3077 97.1228 83.0327C96.8266 82.9239 96.5941 82.6914 96.4741 82.4027C96.3578 82.1102 96.3653 81.7839 96.5003 81.4989C97.7003 78.9564 99.3241 76.6539 101.319 74.6552C110.855 65.1227 126.369 65.1189 135.902 74.6552C140.522 79.2752 143.064 85.4139 143.064 91.9464C143.064 98.4789 140.522 104.618 135.902 109.238C134.848 110.291 133.674 111.266 132.418 112.129C132.234 112.26 132.009 112.328 131.784 112.328ZM99.0841 81.3789C102.219 82.6239 105.302 84.1239 108.268 85.8414C108.309 85.8677 108.35 85.8939 108.392 85.9239L121.753 96.2477C121.79 96.2777 121.828 96.3114 121.862 96.3452C125.855 100.365 129.305 104.805 132.129 109.56C132.905 108.968 133.637 108.326 134.312 107.648C138.504 103.455 140.814 97.8789 140.814 91.9464C140.814 86.0139 138.504 80.4377 134.312 76.2452C125.653 67.5864 111.568 67.5902 102.909 76.2452C101.387 77.7752 100.104 79.4964 99.0841 81.3789Z"
          fill="#9E9E9E"
        />
        <path
          d="M121.067 97.1401C122.503 98.5876 123.861 100.08 125.139 101.621C125.747 101.209 126.324 100.74 126.864 100.2C131.421 95.6438 131.421 88.2601 126.864 83.7038C122.308 79.1476 114.924 79.1476 110.368 83.7038C109.367 84.7051 108.583 85.8451 108.024 87.0638L121.067 97.1401Z"
          fill="#FF9B96"
        />
        <path
          d="M125.14 102.743C124.817 102.743 124.495 102.604 124.273 102.338C123.01 100.823 121.678 99.3563 120.317 97.9838L107.331 87.9526C106.915 87.6338 106.776 87.0676 106.997 86.5913C107.627 85.2226 108.493 83.9813 109.57 82.9088C111.985 80.4938 115.198 79.1626 118.615 79.1626C122.031 79.1626 125.241 80.4938 127.66 82.9088C132.647 87.8963 132.647 96.0076 127.66 100.995C127.086 101.569 126.452 102.09 125.77 102.551C125.578 102.679 125.357 102.743 125.14 102.743ZM109.45 86.7413L121.753 96.2476C121.791 96.2776 121.828 96.3113 121.862 96.3451C123.043 97.5338 124.198 98.7863 125.312 100.084C125.575 99.8663 125.826 99.6413 126.066 99.4013C130.176 95.2913 130.176 88.6051 126.066 84.4951C124.075 82.5038 121.427 81.4088 118.615 81.4088C115.802 81.4088 113.151 82.5038 111.163 84.4951C110.488 85.1701 109.915 85.9238 109.45 86.7413Z"
          fill="#D88484"
        />
        <path
          opacity="0.2"
          d="M75.2916 184.541C100.331 184.541 120.629 168.85 120.629 149.494C120.629 130.138 100.331 114.446 75.2916 114.446C50.2524 114.446 29.9541 130.138 29.9541 149.494C29.9541 168.85 50.2524 184.541 75.2916 184.541Z"
          fill="#9E9E9E"
        />
        <path
          d="M30.2646 172.481C28.5284 167.014 32.9271 148.515 32.9271 148.515C39.2046 140.273 48.6771 121.819 36.8646 116.378C20.3496 108.769 10.3521 136.125 10.3521 136.125C10.3521 136.125 6.00586 145.819 6.00586 156.154"
          fill="white"
        />
        <path
          d="M30.2645 173.606C29.7882 173.606 29.3457 173.299 29.192 172.823C28.4157 170.385 28.6332 165.488 29.8332 158.265C30.7107 152.981 31.8207 148.301 31.832 148.256C31.8695 148.103 31.937 147.96 32.0307 147.836C34.712 144.315 37.3107 139.808 38.987 135.78C41.1395 130.594 41.852 126.139 41.042 122.891C40.4082 120.349 38.8857 118.553 36.392 117.401C33.0882 115.879 29.8145 115.774 26.6607 117.086C17.1545 121.043 11.462 136.361 11.4057 136.515C11.3982 136.541 11.387 136.564 11.3757 136.59C11.3345 136.684 7.12695 146.201 7.12695 156.161C7.12695 156.784 6.62445 157.286 6.00195 157.286C5.37945 157.286 4.87695 156.784 4.87695 156.161C4.87695 145.924 8.9682 136.474 9.3057 135.709C9.7032 134.636 15.5345 119.284 25.7945 115.013C29.552 113.449 33.4332 113.565 37.3295 115.361C40.442 116.794 42.422 119.145 43.2207 122.351C44.1582 126.12 43.4307 130.931 41.057 136.646C38.6307 142.489 35.387 147.116 33.9582 149.014C32.7282 154.26 29.9982 167.974 31.3257 172.151C31.5132 172.744 31.187 173.378 30.5945 173.565C30.4932 173.588 30.377 173.606 30.2645 173.606Z"
          fill="#9E9E9E"
        />
        <path
          d="M120.317 172.481C122.053 167.014 117.654 148.515 117.654 148.515C111.377 140.273 101.904 121.819 113.717 116.378C130.232 108.769 140.229 136.125 140.229 136.125C140.229 136.125 144.576 145.823 144.576 156.158"
          fill="white"
        />
        <path
          d="M120.317 173.606C120.204 173.606 120.088 173.588 119.976 173.554C119.383 173.366 119.057 172.733 119.244 172.14C120.572 167.963 117.846 154.249 116.612 149.003C115.183 147.105 111.939 142.478 109.513 136.635C107.139 130.92 106.412 126.113 107.349 122.34C108.148 119.138 110.128 116.786 113.241 115.35C117.137 113.554 121.022 113.438 124.776 115.001C129.178 116.835 133.299 120.896 137.023 127.073C139.648 131.426 141.099 135.251 141.264 135.698C141.602 136.459 145.693 145.913 145.693 156.154C145.693 156.776 145.191 157.279 144.568 157.279C143.946 157.279 143.443 156.776 143.443 156.154C143.443 146.194 139.236 136.676 139.194 136.583C139.183 136.56 139.172 136.534 139.164 136.508C139.108 136.354 133.404 121.028 123.906 117.079C120.756 115.77 117.482 115.875 114.178 117.398C111.684 118.549 110.162 120.341 109.528 122.888C108.718 126.135 109.431 130.59 111.583 135.776C113.256 139.804 115.858 144.311 118.539 147.833C118.633 147.956 118.704 148.103 118.738 148.253C118.749 148.298 119.856 152.978 120.737 158.261C121.937 165.484 122.154 170.381 121.378 172.819C121.236 173.299 120.793 173.606 120.317 173.606Z"
          fill="#9E9E9E"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M116.09 116.629C115.981 116.629 115.869 116.614 115.756 116.576C115.164 116.393 114.83 115.763 115.018 115.166C115.756 112.785 114.489 110.299 114.478 110.276C114.185 109.729 114.391 109.046 114.943 108.754C115.49 108.461 116.173 108.668 116.465 109.219C116.536 109.354 118.198 112.526 117.166 115.834C117.016 116.321 116.57 116.629 116.09 116.629Z"
            fill="#9E9E9E"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M111.107 119.205C111.051 119.205 110.998 119.201 110.942 119.194C110.327 119.104 109.903 118.534 109.993 117.919C110.376 115.324 105.992 113.314 105.947 113.295C105.381 113.044 105.122 112.38 105.369 111.814C105.617 111.247 106.281 110.985 106.851 111.236C107.094 111.345 112.854 113.914 112.22 118.249C112.134 118.8 111.654 119.205 111.107 119.205Z"
            fill="#9E9E9E"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M122.803 116.629C122.499 116.629 122.196 116.505 121.974 116.261C121.554 115.804 121.588 115.091 122.046 114.671C123.279 113.542 123.013 111.641 123.009 111.622C122.919 111.011 123.336 110.437 123.947 110.34C124.558 110.242 125.132 110.659 125.229 111.27C125.248 111.397 125.702 114.375 123.561 116.332C123.347 116.531 123.077 116.629 122.803 116.629Z"
            fill="#9E9E9E"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M24.7549 117.881C24.5224 117.881 24.2862 117.81 24.0837 117.66C22.0699 116.16 23.0674 113.077 23.1874 112.729C23.3899 112.14 24.0312 111.829 24.6199 112.035C25.2049 112.237 25.5162 112.879 25.3137 113.464C25.1187 114.041 24.8749 115.44 25.4262 115.852C25.9249 116.224 26.0262 116.929 25.6549 117.427C25.4374 117.727 25.0962 117.881 24.7549 117.881Z"
            fill="#9E9E9E"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M31.5021 116.051C31.2359 116.051 30.9734 115.957 30.7596 115.77C28.0821 113.411 29.8109 107.831 30.0171 107.201C30.2121 106.609 30.8459 106.29 31.4346 106.481C32.0234 106.676 32.3459 107.31 32.1546 107.899C31.6521 109.444 31.0146 112.991 32.2484 114.082C32.7134 114.495 32.7584 115.204 32.3496 115.669C32.1246 115.924 31.8171 116.051 31.5021 116.051Z"
            fill="#9E9E9E"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M37.6177 117.881C37.524 117.881 37.4302 117.87 37.3365 117.848C36.7365 117.694 36.3727 117.083 36.5265 116.479C36.669 115.92 38.0115 111.015 41.3302 110.348C41.9377 110.22 42.5339 110.618 42.6539 111.229C42.7739 111.84 42.3839 112.433 41.7727 112.553C40.1265 112.883 38.979 115.98 38.7052 117.038C38.574 117.544 38.1165 117.881 37.6177 117.881Z"
            fill="#9E9E9E"
          />
        </g>
        <path
          opacity="0.3"
          d="M127.753 107.49C132.737 104.306 136.048 98.719 136.048 92.3515C136.048 82.4402 128.034 74.4077 118.153 74.4077C111.23 74.4077 105.23 78.859 102.253 84.6265C102.253 84.6265 105.617 92.1602 113.465 99.3827C121.314 106.605 127.753 107.49 127.753 107.49Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.2"
          d="M129.144 159.818C129.144 154.542 127.066 142.444 121.201 135.12C115.336 127.797 109.599 131.58 111.44 139.219C112.73 144.574 116.375 148.68 116.375 148.68C116.375 148.68 117.785 154.673 119.334 163.984C119.413 164.453 119.619 165.784 119.649 166.268C118.314 168.293 104.225 186.353 74.6339 186.353C45.6089 186.353 32.6714 169.103 31.0514 166.669C31.8201 157.538 34.0176 148.662 34.0176 148.662C34.0176 148.662 36.4514 145.68 38.0414 140.415C40.5576 132.083 34.5801 128.419 28.7189 135.743C22.8539 143.067 20.1239 154.605 20.1201 159.882C20.1201 164.209 20.5926 173.427 31.5014 181.328C43.5389 190.047 58.3251 195.559 75.2864 195.559C92.2476 195.559 106.46 190.725 118.813 181.328C128.619 173.873 129.144 163.77 129.144 159.818Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.3"
          d="M22.5997 103.954C17.9834 100.256 15.2834 94.3498 15.9622 88.0198C17.0159 78.1648 25.8359 71.0286 35.6609 72.0786C42.5459 72.8136 48.0397 77.8761 50.3872 83.9286C50.3872 83.9286 46.2435 91.0648 37.6709 97.4098C29.0984 103.755 22.5997 103.954 22.5997 103.954Z"
          fill="#9E9E9E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="142.395"
            height="209.235"
            fill="white"
            transform="translate(4.87695 63.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const Asset = {
  size,
  View: React.memo(BinoSorry),
};
export default Asset;
