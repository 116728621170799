/* istanbul ignore file */
import TagManager from 'react-gtm-module';

import { TagManagerEventDefinition } from 'shared/analytics/utils';

export function track(data: TagManagerEventDefinition): void {
  TagManager.dataLayer({
    dataLayer: {
      event: 'MIXPANEL-CUSTOM-EVENT',
      event_name: data.name,
      event_properties: JSON.stringify(data.properties),
    },
  });
}
