import React from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import SchoolInfoInput from '../../components/SchoolInfoInput';
import { EMPTY_SCHOOL_INFO } from '../../constants';

import { ChildInfoFormSchema } from './schema';

import { Box } from 'designSystem/ions';
import { Account } from 'shared/data/account';

import { School, SchoolGrade } from 'generated/graphql';

type ChildInfoFormProps = {
  account: Account;
  formProps: UseFormReturn<ChildInfoFormSchema>;
};

const ChildInfoForm = ({
  account,
  formProps,
}: ChildInfoFormProps): JSX.Element => {
  const { watch, control, formState } = formProps;

  const { errors } = formState;

  const schoolInfoForChildren = watch('schoolInfoForChildren');

  const {
    remove: removeSchoolInfoForChildren,
    update: updateSchoolInfoForChildren,
    replace: replaceSchoolInfoForChildren,
  } = useFieldArray({
    control,
    name: 'schoolInfoForChildren',
  });

  const isRemoveButtonInvisible =
    schoolInfoForChildren.length === 1 &&
    schoolInfoForChildren[0].school.id === '';

  const handleChangeGrade = (selectedGrade: SchoolGrade, index: number) => {
    /* istanbul ignore next */

    updateSchoolInfoForChildren(index, {
      ...schoolInfoForChildren[index],
      grade: selectedGrade,
      isErrorVisible: false,
    });
  };

  const handleClickDelete = (index: number) => {
    const filteredSchoolInfoForChildren = schoolInfoForChildren.filter(
      (_, schoolInfoIndex) => index !== schoolInfoIndex
    );

    if (filteredSchoolInfoForChildren.length === 0) {
      replaceSchoolInfoForChildren([EMPTY_SCHOOL_INFO]);
    } else {
      removeSchoolInfoForChildren(index);
    }
  };

  const handleChangeSchool = (school: School, index: number) => {
    updateSchoolInfoForChildren(index, {
      ...schoolInfoForChildren[index],
      school: {
        id: school.id as string,
        name: school.name as string,
        admissionAge: school.admissionAge as number,
        regularCourseYear: school.regularCourseYear as number,
      },
      grade: SchoolGrade.None,
      isErrorVisible: false,
    });
  };

  return (
    <Box
      is="form"
      data-testid="child-info-form"
      className="w-full flex flex-col gap-5"
    >
      {schoolInfoForChildren.map((schoolInfo, index) => (
        <SchoolInfoInput
          key={`${index}_${schoolInfo.school.id}`}
          index={index}
          schoolInfo={schoolInfo}
          removeButtonVisible={!isRemoveButtonInvisible}
          onChangeGrade={handleChangeGrade}
          onClickDelete={handleClickDelete}
          onChangeSchool={handleChangeSchool}
          event={{
            name: 'AddSchoolClicked',
            on: 'click',
            properties: {
              userId: account.id,
            },
          }}
          closeEvent={{
            name: 'AddSchoolCancelled',
            on: 'click',
            properties: {
              userId: account.id,
              role: account.role,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default ChildInfoForm;
