import React, { useState } from 'react';

import useTranslatedTexts from '../../useTranslatedTexts';

import { Button } from 'designSystem/atoms';
import { Plus } from 'designSystem/ions/Icons/solid';
import { Box } from 'designSystem/ions';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

interface LinkChildButtonProps {
  appearance: 'contained' | 'text';
  onClick: () => void;
  event: TagManagerEventDefinition;
}

const LinkChildButton = ({
  appearance,
  onClick: handleClick,
  event,
}: LinkChildButtonProps): JSX.Element => {
  const texts = useTranslatedTexts();

  return (
    <Button
      appearance={appearance}
      onClick={handleClick}
      data-testid="link-child-button"
    >
      <Box className="flex flex-row gap-2">
        <Plus.View
          width={Plus.size.width}
          height={Plus.size.height}
          className={appearance === 'text' ? 'text-gray-800' : ''}
        />
        <Box
          className={appearance === 'text' ? 'text-gray-800' : ''}
          {...createTrackEventProps(event)}
        >
          {texts.button.linkChild}
        </Box>
      </Box>
    </Button>
  );
};

export default LinkChildButton;
