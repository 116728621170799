/* istanbul ignore file */
import * as yup from 'yup';

import { SchoolGrade } from 'generated/graphql';

export const errorTypes = {
  unknown: 'unknown',
  missingValue: 'missingValue',
} as const;

export type ChildSchoolInfo = {
  grade: SchoolGrade;
  school: {
    id: string;
    name: string;
    admissionAge?: number;
    regularCourseYear?: number;
  };
  isErrorVisible?: boolean;
};

export type ChildInfoFormSchema = {
  schoolInfoForChildren: ChildSchoolInfo[];
};

const schema: yup.SchemaOf<ChildInfoFormSchema> = yup.object({
  schoolInfoForChildren: yup
    .array()
    .of(
      yup.object({
        grade: yup.string().required(),
        school: yup.object({
          id: yup.string().optional(),
          name: yup.string().optional(),
          admissionAge: yup.number().optional(),
          regularCourseYear: yup.number().optional(),
        }),
        isErrorVisible: yup.boolean().optional(),
      })
    )
    .optional(),
});

export const childInfoFormSchema = schema;
