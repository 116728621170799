import React from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import useTranslatedTexts from './useTranslatedTexts';
import ChildCodePageContent from './ChildCodePageContent';

import {
  SectionPanel,
  GlobalAppBar,
  CenterTitleNavigationHeader,
} from 'designSystem/organisms';
import { SideMenuPageTemplate } from 'designSystem/templates';
import { withAccount } from 'shared/root';
import { Account } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks';

export const ChildCodePage = ({
  account,
  routeProps,
}: {
  account: Account;
  routeProps?: RouteComponentProps;
}): JSX.Element => {
  const isAllowed = account.role === 'student';
  const texts = useTranslatedTexts();
  const isClasstingMobileApp = useIsClasstingMobileApp();

  const renderContent = () => {
    if (!isAllowed) {
      return <Redirect to="/not-found" />;
    }

    return <ChildCodePageContent childId={account.id} />;
  };

  return (
    <SideMenuPageTemplate
      account={account}
      showFooter={!isClasstingMobileApp}
      headerChild={
        isClasstingMobileApp ? (
          <CenterTitleNavigationHeader title={texts.title.linkGuardian} />
        ) : (
          <GlobalAppBar account={account} />
        )
      }
    >
      <SectionPanel.Wrapper
        testId="link-guardian-panel"
        className="w-full pt-8"
      >
        <SectionPanel.Body>{renderContent()}</SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SideMenuPageTemplate>
  );
};

export default withAccount(ChildCodePage);
