import React from 'react';

import { Box } from 'designSystem/ions';
import { ExclamationCircle } from 'designSystem/ions/Icons/outlined';

const NotAllowedRole = (): JSX.Element => {
  return (
    <Box
      data-testid="not-allowed-role"
      className="flex flex-col items-center mt-40"
    >
      <ExclamationCircle.View className="w-8 h-8 text-ctRed-400" />
      <Box is="h3" className="text-20 text-gray-900 font-bold mt-4">
        계정 연결 불가
      </Box>
      <Box className="text-14 text-gray-700 mt-6 whitespace-pre-line text-center">
        {
          '자녀 연결은 학생만 가능합니다.\n자녀 계정으로 로그인되어 있는지 확인해주세요.'
        }
      </Box>
    </Box>
  );
};

export default NotAllowedRole;
