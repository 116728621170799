import * as React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const Menu = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z" fill="#757575" />
  </svg>
);

const Asset = {
  size,
  View: Menu,
};
export default Asset;
