import React, { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import ChildInfoButton from './ChildInfoButton';
import ChildInfoDialogContent from './ChildInfoDialogContent';

import UnlinkChildDialogContent from './UnlinkChildDialogContent';

import { useGradeTranslations } from 'shared/hooks/translation/useGradeTranslation';
import { SchoolGrade, SignInMethod } from 'generated/graphql';
import { ProfileListItem } from 'designSystem/molecules';
import useTranslatedTexts from 'pages/InfoPages/LinkChildPage/useTranslatedTexts';
import { getCredentialAccountId } from 'core/credential/credential.service';
import { K12_GRADE_TO_NUMBER } from 'shared/constants/grades';

interface LinkedChildListItemProps {
  childId: string;
  schoolName: string;
  grade: SchoolGrade;
  profileImage: string;
  email: string;
  mobile: string;
  name: string;
  signInMethods: SignInMethod[];
  relationshipId: string;
}

const LinkedChildListItem = ({
  childId,
  email,
  mobile,
  name,
  schoolName,
  grade,
  profileImage,
  signInMethods,
  relationshipId,
}: LinkedChildListItemProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const accountId = getCredentialAccountId();
  const childGradeNumber = K12_GRADE_TO_NUMBER[grade];

  const [isChildInfoDialogOpen, setIsChildInfoDialogOpen] = useState(false);
  const [isUnlinkChildDialogOpen, setIsUnlinkChildDialogOpen] = useState(false);

  const { translateGrade } = useGradeTranslations();
  const gradeText = translateGrade(grade);
  const schoolInfoText = [schoolName, gradeText].join(' ');

  const handleOpenChildInfoDialogRequest = () => {
    setIsChildInfoDialogOpen(true);
  };

  const handleCloseChildInfoDialogRequest = () => {
    setIsChildInfoDialogOpen(false);
  };

  const handleCloseUnlinkChildDialogRequest = () => {
    setIsUnlinkChildDialogOpen(false);
  };

  const handleUnlinkClick = () => {
    setIsUnlinkChildDialogOpen(true);
  };

  const handleUnlinkSuccess = () => {
    setIsUnlinkChildDialogOpen(false);
  };

  return (
    <ProfileListItem
      profileImage={profileImage}
      username={name}
      description={schoolInfoText}
      testid="linked-child-list-item"
      menuItems={[
        {
          name: texts.button.unlinkChild,
          onClick: handleUnlinkClick,
          event: {
            name: 'UnlinkChildStarted',
            on: 'click',
            properties: {
              userId: accountId,
              childId: childId,
            },
          },
        },
      ]}
    >
      <ChildInfoButton
        onOpenDialogRequest={handleOpenChildInfoDialogRequest}
        event={{
          name: 'ChildAccountInfoViewed',
          on: 'click',
          properties: {
            userId: accountId,
            childId: childId,
            childGrade: childGradeNumber === -1 ? 'none' : childGradeNumber,
            signInMethods: signInMethods,
          },
        }}
      />
      <Dialog.Root open={isChildInfoDialogOpen}>
        <ChildInfoDialogContent
          childId={childId}
          email={email}
          mobile={mobile}
          name={name}
          signInMethods={signInMethods}
          onCloseDialogRequest={handleCloseChildInfoDialogRequest}
        />
      </Dialog.Root>
      <Dialog.Root open={isUnlinkChildDialogOpen}>
        <UnlinkChildDialogContent
          relationshipId={relationshipId}
          onCloseDialogRequest={handleCloseUnlinkChildDialogRequest}
          onUnlinkSuccess={handleUnlinkSuccess}
        />
      </Dialog.Root>
    </ProfileListItem>
  );
};

export default LinkedChildListItem;
