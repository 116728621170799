/* istanbul ignore file */
import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ClasstingLogo,
  IconButton,
  Drawer,
  Divider,
  DropdownMenu,
  Avatar,
} from '@classtinginc/design-system';

import {
  CLASSTING_FEDERATED_LOGOUT_URL,
  CLASSTING_HOME_URL,
} from 'shared/constants';
import { Account } from 'shared/data/account';

import { useIsInMobile, useIsInSmScreen } from 'designSystem/ions/helpers';
import { Box } from 'designSystem/ions';
import { Anchor } from 'designSystem/atoms';
import { ArrowDropDown, Menu } from 'designSystem/ions/Icons/fill';
import { gray, green } from 'designSystem/ions/colors.cjs';
import { createTrackEventProps } from 'shared/analytics/utils';

type GlobalAppBarProps = {
  account?: Account;
};

const MENU_ITEM_CLASS_NAME = 'w-full block pt-4 pb-4 px-4';
const ACTIVE_MENU_ITEM_CLASS_NAME = 'bg-gray-100 font-bold rounded';

const GlobalAppBar = ({ account }: GlobalAppBarProps): JSX.Element => {
  const { t } = useTranslation();
  const isInMobile = useIsInMobile();
  const isSmScreen = useIsInSmScreen();

  const dropdownRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  const myInfoText = t('account.my_info.title');
  const accountInfoText = t('account.account_info.title');
  const linkGuardianText = t('account.link_guardian.title');
  const linkChildText = t('account.link_child.title');
  const studentCodeText = t('account.student_code.title');
  const helpText = t('account.help.title');
  const goHomeText = t('account.settings.home');
  const signOutText = t('account.settings.sign_out');
  const marketingAgreementText = t('account.marketing_agreement.title');

  const logoClassName = `
  flex flex-col items-start justify-center
  h-full
  focus:outline-none focus-visible:ring
   `;
  const logoTabIndex = isInMobile ? -1 : 0;

  return (
    <Box is="div" className="px-4 w-full">
      <Box
        is="div"
        className="relative row min-h-14 sm:min-h-15 md:py-1 flex justify-between items-center"
        data-testid="tab-menu-header"
      >
        <Box
          is="div"
          className="flex flex-row justify-between w-full h-15 md:h-auto"
        >
          <Box is="div" className="flex justify-start z-20">
            <Anchor
              data-testid="classting-logo__link"
              className={logoClassName}
              tabIndex={logoTabIndex}
              href={CLASSTING_HOME_URL}
              aria-label="Go home"
            >
              <ClasstingLogo type="wordmark" />
            </Anchor>
          </Box>
          <Box is="div" className="flex items-center" ref={dropdownRef}>
            <Drawer.Root
              open={isSmScreen && openMenu}
              onOpenChange={handleMenuToggle}
            >
              <Drawer.Trigger asChild>
                <IconButton
                  css={{
                    display: isSmScreen ? 'block' : 'none',
                  }}
                  size="sm"
                  onClick={handleMenuToggle}
                >
                  <Menu.View />
                </IconButton>
              </Drawer.Trigger>
              <Drawer.Content
                position={isInMobile ? 'full' : 'right'}
                css={{
                  width: isInMobile ? '100%' : '340px',
                }}
              >
                <Drawer.Close onClick={handleMenuToggle} />
                <Drawer.Main>
                  <Box
                    is="ul"
                    className="flex flex-col text-body-1 text-gray-900 font-regular"
                  >
                    <li>
                      <NavLink
                        to="/account/personal-info"
                        onClick={handleMenuToggle}
                        className={MENU_ITEM_CLASS_NAME}
                        activeClassName={ACTIVE_MENU_ITEM_CLASS_NAME}
                      >
                        {myInfoText}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/account/info"
                        onClick={handleMenuToggle}
                        className={MENU_ITEM_CLASS_NAME}
                        activeClassName={ACTIVE_MENU_ITEM_CLASS_NAME}
                      >
                        {accountInfoText}
                      </NavLink>
                    </li>
                    {account?.role === 'student' && (
                      <li>
                        <NavLink
                          to="/account/child-code"
                          onClick={handleMenuToggle}
                          className={MENU_ITEM_CLASS_NAME}
                          activeClassName={ACTIVE_MENU_ITEM_CLASS_NAME}
                        >
                          {linkGuardianText}
                        </NavLink>
                      </li>
                    )}
                    {(account?.role === 'parent' ||
                      account?.role === 'teacher') && (
                      <NavLink
                        to="/account/link-child"
                        onClick={handleMenuToggle}
                        className={MENU_ITEM_CLASS_NAME}
                        activeClassName={ACTIVE_MENU_ITEM_CLASS_NAME}
                      >
                        {linkChildText}
                      </NavLink>
                    )}
                    <li>
                      <Anchor
                        href="https://support.classting.com/hc/ko"
                        target="_blank"
                        rel="noopener noreferer nofollow"
                        className={MENU_ITEM_CLASS_NAME}
                      >
                        {helpText}
                      </Anchor>
                    </li>
                    <li>
                      <NavLink
                        to={'/account/marketing-agreement'}
                        onClick={handleMenuToggle}
                        className={MENU_ITEM_CLASS_NAME}
                        activeClassName={ACTIVE_MENU_ITEM_CLASS_NAME}
                      >
                        {marketingAgreementText}
                      </NavLink>
                    </li>
                    <li>
                      <Anchor
                        href={CLASSTING_HOME_URL}
                        aria-label="Go home"
                        className={MENU_ITEM_CLASS_NAME}
                      >
                        {goHomeText}
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        href={CLASSTING_FEDERATED_LOGOUT_URL}
                        className={MENU_ITEM_CLASS_NAME}
                        {...createTrackEventProps({
                          name: 'SignOutClicked',
                          on: 'click',
                          properties: {
                            userId: account?.id,
                            role: account?.role,
                            grade: account?.schoolInfo?.grade ?? -1,
                          },
                        })}
                      >
                        {signOutText}
                      </Anchor>
                    </li>
                  </Box>
                </Drawer.Main>
              </Drawer.Content>
            </Drawer.Root>
            <DropdownMenu.Trigger
              onClick={handleMenuToggle}
              css={{
                display: isSmScreen ? 'none' : 'block',
              }}
            >
              <Box is="div" className="flex items-center gap-x-2">
                <Box
                  is="div"
                  className={
                    openMenu
                      ? 'border rounded-full border-primary-normal'
                      : 'border rounded-full border-white'
                  }
                >
                  <Avatar
                    alt={account?.name}
                    appearance="circle"
                    size="md"
                    src={account?.avatarImage ?? ''}
                  />
                </Box>
                <Box is="div" className={openMenu ? 'rotate-180' : ''}>
                  <ArrowDropDown.View
                    fill={openMenu ? green[500] : gray[600]}
                  />
                </Box>
              </Box>
            </DropdownMenu.Trigger>
            <DropdownMenu.Menu
              triggerRef={dropdownRef}
              open={openMenu}
              onClose={handleMenuToggle}
            >
              <Box
                is="div"
                className="flex flex-col text-body-1 text-gray-900 font-regular cursor-pointer min-w-45 max-h-44"
              >
                <Anchor className="p-4 block" href={CLASSTING_HOME_URL}>
                  {goHomeText}
                </Anchor>
                <Divider />
                <Anchor
                  className="p-4 block"
                  href={CLASSTING_FEDERATED_LOGOUT_URL}
                  {...createTrackEventProps({
                    name: 'SignOutClicked',
                    on: 'click',
                    properties: {
                      userId: account?.id,
                      role: account?.role,
                      grade: account?.schoolInfo?.grade ?? -1,
                    },
                  })}
                >
                  {signOutText}
                </Anchor>
              </Box>
            </DropdownMenu.Menu>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GlobalAppBar;
