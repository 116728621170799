/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

import { EnterpriseDto } from 'generated/graphql';

type Title = {
  userInfo: string;
  accountInfo: string;
  requestChangeRole: string;
};

type Subtitle = {
  childInfo: string;
  myOrganizations: string;
};

type TranslatedTextsReturn = {
  pageTitle: string;
  title: Title;
  subtitle: Subtitle;
  //FIXME: 타입 추가해야함
  label: { [x: string]: string };
  placeholder: { [x: string]: string };
  button: { [x: string]: string };
  message: { [x: string]: string };
  item: { [x: string]: string };
  role: { [x: string]: string };
  error: { [x: string]: string };
  tag: { [x: string]: string };
};

const useTranslatedTexts = (): TranslatedTextsReturn => {
  const { t } = useTranslation('translation');

  const pageTitle = t('account.my_info.title');

  const title = {
    userInfo: t('account.my_info.title'),
    accountInfo: t('account.account_info.title'),
    requestChangeRole: t('account.request_change_role.title'),
  };

  const subtitle = {
    childInfo: t('account.my_info.subtitle.child_info'),
    myOrganizations: t('account.my_info.subtitle.my_organizations'),
  };

  const label = {
    profile: t('account.my_info.subtitle.profile'),
    name: t('account.label.name'),
    description: t('account.label.description'),
    personalInfo: t('account.my_info.subtitle.personal_info'),
    birthday: t('account.label.birthday'),
    gender: t('account.label.gender'),
    role: t('account.label.role'),
    roleToChange: t('account.label.role_to_change'),
    reasonForChangeRole: t('account.label.reason_for_change_role'),
    language: t('account.label.language'),
    country: t('account.label.country'),
    grade: t('account.label.grade'),
    institution: t('account.label.institution'),
    organizations: t('account.label.organizations'),
  };

  const placeholder = {
    name: t('account.placeholder.name'),
    description: t('account.placeholder.description'),
    gender: t('account.placeholder.gender'),
    reasonRorChangeRole: t('account.placeholder.reason_for_change_role'),
    month: t('account.placeholder.month'),
    day: t('account.placeholder.day'),
    institutionName: t('account.placeholder.institution_name'),
    select: t('account.placeholder.select'),
  };

  const button = {
    delete: t('account.button.delete_profile'),
    changeRole: t('account.button.change_role.before'),
    saveChange: t('account.button.save_change'),
    cancel: t('account.button.cancel'),
    addChild: t('account.button.add_child'),
  };

  const message = {
    changeRole: t('account.message.change_role'),
    addGender: t('account.message.add_gender'),
    emptyBirthDate: t('account.error.message.empty_birthdate'),
    submitChangeRole: t('account.message.submit_chage_role'),
    successChange: t('account.message.success_change'),
    successRequestChangeRole: t('account.message.success_request_change_role'),
    empty_grade: t('account.message.empty_grade'),
    addBirthday: t('account.message.add_birthdate'),
    selectPrimaryOrganization: t('account.message.select_primary_organization'),
    emptyOrganizations: t('account.message.empty_organizations'),
  };

  const item = {
    female: t('account.item.gender.female'),
    male: t('account.item.gender.male'),
    student: t('account.item.role.student'),
    parent: t('account.item.role.parent'),
    teacher: t('account.item.role.teacher'),
    option: t('account.item.terms.option'),
    required: t('account.item.terms.required'),
  };

  const role = {
    parent: t('account.item.role.parent'),
    student: t('account.item.role.student'),
    teacher: t('account.item.role.teacher'),
  };

  const error = {
    invalidName: t('account.error.message.invalid_name'),
    invalidNameBrand: t('account.error.message.invalid_name_brand'),
    invalidDescription: t('account.error.message.unavailable_word'),
    invalidBirthday: t('account.error.message.invalid_birthday'),
  };

  const tag = {
    primaryOrganization: t('account.tag.primary_organization'),
  };

  return {
    pageTitle,
    title,
    subtitle,
    label,
    placeholder,
    button,
    message,
    item,
    role,
    error,
    tag,
  };
};

export default useTranslatedTexts;
