import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { ClipLoader } from 'react-spinners';
import { Popup } from '@classtinginc/design-system';

import debounce from 'lodash/debounce';

import { School, useSearchSchoolsQuery } from 'generated/graphql';
import { Button, Input, Spinner } from 'designSystem/atoms';
import { Box } from 'designSystem/ions';
import { LEGACY_CLASSTING_URL } from 'shared/constants';
import {
  createTrackEventProps,
  TagManagerEventDefinition,
} from 'shared/analytics/utils';

const SEARCH_RESULT_FETCH_LIMIT = 20;

const DEBOUNCE_DURATION = 500;

type SearchSchoolDialogProps = {
  open: boolean;
  index: number;
  closeDialog: () => void;
  onSelectSchool: (school: School, index: number) => void;
  event?: TagManagerEventDefinition;
};

const SearchSchoolDialog = ({
  open,
  index,
  closeDialog,
  onSelectSchool,
  event,
}: SearchSchoolDialogProps): JSX.Element => {
  const [t] = useTranslation();

  const [queryInputText, setQueryInputText] = React.useState('');

  const [isTyping, setIsTyping] = React.useState(false);

  const queryOffset = React.useRef(0);
  const lastResultLength = React.useRef(-1);

  const {
    loading: loadingSearchSchoolResults,
    refetch,
    fetchMore,
    data,
  } = useSearchSchoolsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const schools = data?.searchSchools?.schools || [];

  const isLoadingVisible =
    isTyping || (loadingSearchSchoolResults && !data?.searchSchools?.schools);

  const isGuideVisible = queryInputText === '';

  const searchResultContent = isLoadingVisible
    ? 'loading'
    : isGuideVisible
    ? 'guide'
    : 'results';

  const isInfiniteLoadingVisible =
    loadingSearchSchoolResults && !!data?.searchSchools?.schools;

  const handleClickClose = () => {
    closeDialog();
  };

  const handleSelectSchool = (school: School) => {
    closeDialog();
    onSelectSchool(school, index);
  };

  const resetSearchResult = async (query: string) => {
    queryOffset.current = 0;
    lastResultLength.current = -1;

    refetch({
      q: query,
      offset: 0,
      limit: SEARCH_RESULT_FETCH_LIMIT,
    });
  };

  const debouncedResetSearchResult = React.useRef(
    debounce(resetSearchResult, DEBOUNCE_DURATION, { trailing: true })
  ).current;

  const debouncedSetIsTyping = React.useRef(
    debounce(setIsTyping, DEBOUNCE_DURATION, { trailing: true })
  ).current;

  const handleChangeQuery = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isTyping) {
      setIsTyping(true);
    }

    setQueryInputText(e.target.value);

    await debouncedSetIsTyping.cancel();
    debouncedSetIsTyping(false);

    await debouncedResetSearchResult.cancel();
    debouncedResetSearchResult(e.target.value);
  };

  const handleClickRegistration = () => {
    const schoolRegistrationPageURL = `${LEGACY_CLASSTING_URL}/schools/certify?name=${queryInputText}`;

    window.open(schoolRegistrationPageURL, '_blank');
  };

  const fetchMoreSearchResult = async () => {
    if (lastResultLength.current === 0) {
      /* istanbul ignore next */
      return;
    }

    queryOffset.current += SEARCH_RESULT_FETCH_LIMIT;

    fetchMore({
      variables: {
        q: queryInputText,
        offset: queryOffset.current,
        limit: SEARCH_RESULT_FETCH_LIMIT,
      },
      /* istanbul ignore next */
      updateQuery(previousQueryResult, options) {
        const newlyFetchedSchools =
          options.fetchMoreResult?.searchSchools?.schools || [];

        lastResultLength.current = newlyFetchedSchools.length;

        const schools = previousQueryResult.searchSchools?.schools?.concat(
          newlyFetchedSchools
        );

        /* istanbul ignore next */
        return {
          __typename: 'Query',
          searchSchools: {
            __typename: 'SchoolCollection',
            schools,
          },
        };
      },
    });
  };

  const handleLastNthVisible = async () => {
    fetchMoreSearchResult();
  };

  const closePopupTrackEventProps = event && createTrackEventProps(event);

  return (
    <Popup.Root open={open}>
      <Popup.Content size={'sm'} data-testId="search-school-dialog">
        <Popup.Header>
          <Popup.Title>{t('account.search_school.title')}</Popup.Title>
        </Popup.Header>
        <Popup.Body>
          <Box className="flex flex-col gap-2">
            <Input
              testId="school-name-input"
              onChange={handleChangeQuery}
              placeholder={t('account.search_school.placeholder.search')}
              value={queryInputText}
            />
            <SearchSchoolResult
              schools={schools as School[]}
              content={searchResultContent}
              infiniteLoadingVisible={isInfiniteLoadingVisible}
              onSelectSchool={handleSelectSchool}
              onClickRegistration={handleClickRegistration}
              onLastNthVisible={handleLastNthVisible}
            />
          </Box>
        </Popup.Body>
        <Popup.Footer>
          <Button
            testId="close-button"
            appearance="outlined"
            theme="white"
            onClick={handleClickClose}
            {...closePopupTrackEventProps}
          >
            {t('account.button.close')}
          </Button>
        </Popup.Footer>
      </Popup.Content>
    </Popup.Root>
  );
};

const searchSchoolResultContainerStyle = `
  w-full h-60
  rounded-lg border border-gray-300
  overflow-hidden
`;

const OFFSET_FROM_LAST = 0;

type SearchSchoolResultProps = {
  schools: School[];
  content: 'guide' | 'loading' | 'results';
  infiniteLoadingVisible: boolean;
  onSelectSchool: (school: School) => void;
  onClickRegistration: () => void;
  onLastNthVisible: () => void;
};

const SearchSchoolResult = ({
  schools,
  content,
  infiniteLoadingVisible,
  onSelectSchool,
  onClickRegistration,
  onLastNthVisible,
}: SearchSchoolResultProps) => {
  const { ref, inView } = useInView();
  const [t] = useTranslation();

  const handleClickSchool = (school: School) => {
    onSelectSchool(school);
  };

  React.useEffect(() => {
    if (inView) {
      onLastNthVisible();
    }
  }, [inView, onLastNthVisible]);

  if (content === 'loading') {
    return (
      <Box className={searchSchoolResultContainerStyle}>
        <Box
          data-testid="spinner"
          className="w-full h-full flex justify-center items-center p-4"
        >
          <Spinner />
        </Box>
      </Box>
    );
  } else if (content === 'guide') {
    return (
      <Box className={searchSchoolResultContainerStyle}>
        <Box className="w-full h-full flex justify-center items-center p-4">
          <Box
            data-testid="search-guide"
            className="text-gray-500 text-13 text-center whitespace-pre-line w-full"
          >
            {t('account.search_school.message.empty_query')}
          </Box>
        </Box>
      </Box>
    );
  } else if (schools.length === 0) {
    return (
      <Box className={searchSchoolResultContainerStyle}>
        <Box className="w-full h-full flex flex-col justify-center items-center p-4">
          <Box
            data-testid="school-not-found-message"
            className="text-gray-500 text-13 text-center whitespace-pre-line w-full py-5"
          >
            {t('account.search_school.message.not_found')}
          </Box>
          <Button
            data-testid="school-registration-button"
            theme="primary"
            appearance="contained"
            onClick={onClickRegistration}
          >
            {t('account.search_school.button.register_school')}
          </Button>
        </Box>
      </Box>
    );
  }

  const OBSERVED_TARGET_INDEX = Math.min(
    Math.max(schools.length - 1 - OFFSET_FROM_LAST, 0),
    schools.length - 1
  );

  return (
    <Box className={searchSchoolResultContainerStyle}>
      <Box className="flex flex-col w-full h-full overflow-auto scrollbar-hide">
        {schools.map((school, i) => (
          <SearchSchoolResultItem
            key={school.id}
            containerRef={i === OBSERVED_TARGET_INDEX ? ref : undefined}
            school={school}
            onClick={handleClickSchool}
          />
        ))}
        {infiniteLoadingVisible && (
          <Box
            data-testid="spinner"
            className="w-full h-full flex justify-center items-center p-4"
          >
            <ClipLoader />
          </Box>
        )}
      </Box>
    </Box>
  );
};

type SearchSchoolResultItemProps = {
  containerRef?: (node?: Element | null | undefined) => void;
  school: School;
  onClick: (school: School) => void;
};

const SearchSchoolResultItem = ({
  containerRef,
  onClick,
  school,
}: SearchSchoolResultItemProps) => {
  const handleClick = () => {
    onClick(school);
  };

  return (
    <Box
      ref={containerRef}
      data-testid="search-school-result-item"
      className="w-full gap-1 text-gray-900 cursor-pointer hover:bg-gray-100 px-4 py-2.5"
      onClick={handleClick}
    >
      <Box data-testid="school-name" className="text-15">
        {school.name}
      </Box>
      <Box data-testid="school-address" className="text-gray-500 text-12">
        {[school.city, school.country].filter((str) => !!str).join(', ')}
      </Box>
    </Box>
  );
};

export default SearchSchoolDialog;
