import React, { Ref, forwardRef } from 'react';

import {
  HelperText,
  HelperTextType,
  Input,
  Label,
  InputProps,
} from 'designSystem/atoms';

import { Box } from 'designSystem/ions';

export type FieldHelperText = HelperTextType & {
  visible: boolean;
};

type TextFieldProps = {
  label: string;
  isError?: boolean;
  helperTexts?: FieldHelperText[];
} & InputProps;

const TextField = forwardRef(
  (
    {
      type,
      label,
      isError = false,
      helperTexts = [],
      name,
      value,
      placeholder,
      startAdornment,
      endAdornment,
      disabled,
      inputMode,
      testId,
      onChange,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      onBlur,
      onFocus,
      onClick,
      readOnly,
    }: TextFieldProps,
    forwardedRef: Ref<HTMLInputElement>
  ): JSX.Element => {
    const visibleHelperText = helperTexts.filter(({ visible }) => visible);

    return (
      <Box className="w-full">
        <Box className="mb-1">
          <Label>{label}</Label>
        </Box>
        <Input
          ref={forwardedRef}
          type={type}
          status={isError ? 'error' : 'default'}
          name={name}
          value={value}
          placeholder={placeholder}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          disabled={disabled}
          inputMode={inputMode}
          testId={testId}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          readOnly={readOnly}
        />
        {!!visibleHelperText.length && (
          <Box className="mt-1">
            {visibleHelperText.map(({ message, status, icon }) => (
              <HelperText
                key={message}
                message={message}
                status={status}
                icon={icon}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
