/* istanbul ignore file */
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();
  const title = t('account.marketing_agreement.title');

  const label = {
    marketingAgreement: t(
      'account.marketing_agreement.label.marketing_agreement'
    ),
    emailAgreed: t('account.marketing_agreement.label.email_agreed'),
    pushAgreed: t('account.marketing_agreement.label.push_agreed'),
    smsAgreed: t('account.marketing_agreement.label.sms_agreed'),
    showTerms: t('account.marketing_agreement.label.show_terms'),
  };

  const message = {
    setAgreed: (year: string, month: string, day: string) =>
      t('account.marketing_agreement.message.agreed', { year, month, day }),
    setDisagreed: (year: string, month: string, day: string) =>
      t('account.marketing_agreement.message.disagreed', { year, month, day }),
    temporaryError: t('account.error.message.temporary_error'),
  };

  const button = {
    confirm: t('account.button.confirm'),
  };

  return {
    title,
    label,
    message,
    button,
  };
};

export default useTranslatedTexts;
