/* istanbul ignore file */

import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslatedTexts = () => {
  const { t } = useTranslation();

  const changePassword = {
    title: t('account.change_password.title'),
    description: t('account.change_password.description'),
  };

  const button = {
    change: t('account.change_password.button.change'),
    back: t('account.button.back'),
  };

  const message = {
    validatedPassword: t('account.message.validated_password'),
    alreadyUsingPassword: t('account.error.message.already_using_password'),
    passwordConditionLength: t('account.message.password_condition.length'),
    passwordConditionCharacter: t(
      'account.message.password_condition.character'
    ),
    matchedPassword: t('account.message.matched_password'),
    notMatchedPassword: t('account.error.message.not_matched_password'),
    successChange: t('account.message.success_change'),
    temporaryError: t('account.error.message.temporary_error'),
    noPassword: t('account.message.no_password'),
  };

  const label = {
    currentPassword: t('account.label.current_password'),
    newPassword: t('account.label.new_password'),
    newConfirmPassword: t('account.label.new_confirm_password'),
  };

  const placeholder = {
    currentPassword: t('account.placeholder.current_password'),
    newPassword: t('account.placeholder.new_password'),
    newConfirmPassword: t('account.placeholder.new_confirm_password'),
  };

  return { button, changePassword, message, label, placeholder };
};

export default useTranslatedTexts;
