import AuthCallbackPage from './AuthCallbackPage';
import ChallengeRedirectPage from './ChallengeRedirectPage';
import ChallengeCallbackPage from './ChallengeCallbackPage';

const AuthPages = {
  AuthCallbackPage,
  ChallengeRedirectPage,
  ChallengeCallbackPage,
};

export default AuthPages;
