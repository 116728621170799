import { headerCase } from 'change-case';

import { DEFAULT_PROFILE_IMAGE } from './constants';

import { Account, useMyAccount, useUpdateAccount } from 'shared/data/account';
import {
  useCreateImageRecord,
  useUploadImage,
} from 'shared/data/media-service';

export type useUpdateAccountProfileImageCommandHookRes = {
  isLoading: boolean;
  isSuccess: boolean;
  uploadHandler: (file: File) => void;
  deleteHandler: () => void;
};

export const useUpdateAccountProfileImageCommand = ({
  account,
}: {
  account: Account;
}): useUpdateAccountProfileImageCommandHookRes => {
  const { refetch } = useMyAccount({ enabled: false });
  const createRecordMutation = useCreateImageRecord();
  const uploadImageMutation = useUploadImage();
  const updateAccountMutation = useUpdateAccount();

  const isLoading =
    createRecordMutation.isLoading ||
    uploadImageMutation.isLoading ||
    updateAccountMutation.isLoading;

  const isSuccess =
    createRecordMutation.isSuccess &&
    uploadImageMutation.isSuccess &&
    updateAccountMutation.isSuccess;

  const uploadHandler = async (file: File) => {
    try {
      /* istanbul ignore next */
      if (isLoading) return;

      const {
        presignedPost,
        ...record
      } = await createRecordMutation.mutateAsync({
        categoryId: account.id,
        categoryType: 'Account',
        contentType: file.type,
        originalFileName: file.name,
        totalSize: file.size,
      });

      /* istanbul ignore next */
      if (!presignedPost) return;

      const formData = new FormData();
      formData.append('Content-Type', file.type);
      /* istanbul ignore next */
      Object.entries(presignedPost.fields).forEach(([k, v]) => {
        formData.append(headerCase(k), v);
      });
      formData.append('file', file);

      await uploadImageMutation.mutateAsync({
        presignedUrl: presignedPost.url,
        data: formData,
      });

      /* istanbul ignore else */
      if (record.urls.original) {
        await updateAccountMutation.mutateAsync({
          userId: account.id,
          data: {
            profileImage: record.urls.original,
          },
        });
        await refetch();
      }
    } catch (err) {
      /* istanbul ignore next */
      console.error(err);
    }
  };

  const deleteHandler = () => {
    updateAccountMutation.mutate(
      {
        userId: account.id,
        data: {
          profileImage: DEFAULT_PROFILE_IMAGE,
        },
      },
      {
        onSuccess() {
          refetch();
        },
      }
    );
  };

  return {
    isLoading,
    isSuccess,
    uploadHandler,
    deleteHandler,
  };
};
