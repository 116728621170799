import React, { useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import {
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_CONFIRM,
} from './constant';
import useTranslatedTexts from './useTranslatedTexts';

import { Button } from 'designSystem/atoms';
import { EyeIcon } from 'shared/components/atoms';
import { TextField } from 'designSystem/molecules';
import {
  useModalContext,
  SectionPanel,
  CenterTitleNavigationHeader,
} from 'designSystem/organisms';
import { isEmptyObject } from 'shared/utils';
import { useUpdateAccountPassword, Account } from 'shared/data/account';
import { withAccount } from 'shared/root';
import {
  useFormRegister,
  useHelperTextStatusByFieldStatus,
  Field,
} from 'shared/hooks';
import { useHistoryBackHandler } from 'shared/hooks/history';
import { Box, useIsInMobile } from 'designSystem/ions';
import { SingleColumnPageTemplate } from 'designSystem/templates';

const formSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup
    .string()
    .min(8)
    .test('weak', 'weak-message', (value) => {
      if (value) {
        let included = 0;

        if (/[A-Z]/.test(value)) {
          included++;
        }

        if (/[a-z]/.test(value)) {
          included++;
        }

        if (/[0-9]/.test(value)) {
          included++;
        }

        if (/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(value)) {
          included++;
        }

        return included >= 2;
      }
      return false;
    })
    .required(),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null])
    .required(),
});

const fieldNames = [CURRENT_PASSWORD, NEW_PASSWORD, NEW_PASSWORD_CONFIRM];

type ChangePasswordProps = {
  account: Account;
};

const ChangePassword = ({ account }: ChangePasswordProps): JSX.Element => {
  const history = useHistory();
  const historyHandler = useHistoryBackHandler();
  if (account.identities.length) history.replace('not-found');
  const { openModal } = useModalContext();
  const isMobile = useIsInMobile();
  const texts = useTranslatedTexts();
  const { fields, setValue, errors, setError, handleSubmit } = useFormRegister(
    fieldNames,
    formSchema
  );
  const currentPassword = fields.get(CURRENT_PASSWORD) as Field;
  const newPassword = fields.get(NEW_PASSWORD) as Field;
  const newPasswordConfirm = fields.get(NEW_PASSWORD_CONFIRM) as Field;
  const isDisableSubmitButton = !(
    currentPassword.value &&
    newPassword.value &&
    newPasswordConfirm.value &&
    isEmptyObject(errors)
  );

  const {
    helperTextStatus,
    hasFocus,
    handleChange: handleChangeNewPassword,
    handleFocus: handleFocusNewPassword,
    handleBlur: handleBlurNewPassword,
  } = useHelperTextStatusByFieldStatus(newPassword, {
    min: errors[NEW_PASSWORD]?.types?.min,
    weak: errors[NEW_PASSWORD]?.types?.weak,
  });

  const mutation = useUpdateAccountPassword({
    onSuccess: () => {
      openModal({
        status: 'success',
        message: texts.message.successChange,
        onClickButton: () => {
          historyHandler.go(-1);
        },
      });
    },
    onError: (error) => {
      if (error.response?.status === 422) {
        setError(NEW_PASSWORD, {
          type: 'sameAsBefore',
          message: texts.message.alreadyUsingPassword,
        });
      } else if (error.response?.status === 403) {
        setValue(NEW_PASSWORD, '');
        setValue(NEW_PASSWORD_CONFIRM, '');
        // 비밀번호가 일치하지 않는 경우
        setError(CURRENT_PASSWORD, {
          type: 'misMatch',
          message: 'currentPassword is misMatch',
        });
      } else {
        //  기타 네트워크 에러
        openModal({
          status: 'error',
          message: texts.message.temporaryError,
        });
      }
    },
  });

  const onSubmit = (data: {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }) => {
    if (account) {
      mutation.mutate({
        userId: account.id,
        password: data.currentPassword,
        newPassword: data.newPassword,
        newPasswordConfirm: data.newPasswordConfirm,
      });
    }
  };

  const [
    currentPasswordFieldVisible,
    setCurrentPasswordFieldVisible,
  ] = useState(false);
  const [newPasswordFieldVisible, setNewPasswordFieldVisible] = useState(false);
  const [
    newPasswordConfirmFieldVisible,
    setNewPasswordConfirmFieldVisible,
  ] = useState(false);

  return (
    <SingleColumnPageTemplate
      headerChild={
        <CenterTitleNavigationHeader title={texts.changePassword.title} />
      }
      showFooter={false}
      hasHeaderShadow={false}
    >
      <SectionPanel.Wrapper
        is="article"
        testId="account-info-panel"
        className="w-full"
      >
        <SectionPanel.Header
          showBackButton
          title={texts.changePassword.title}
          description={texts.changePassword.description}
        />
        <SectionPanel.Body>
          <form
            className="w-full"
            data-testid="password-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box className="mb-5 sm:mb-6">
              <TextField
                ref={currentPassword.ref}
                type={currentPasswordFieldVisible ? 'text' : 'password'}
                label={texts.label.currentPassword}
                isError={
                  (!!currentPassword.value && !!errors[CURRENT_PASSWORD]) ||
                  (!currentPassword.value && !!errors[CURRENT_PASSWORD])
                }
                name={currentPassword.name}
                placeholder={texts.placeholder.currentPassword}
                endAdornment={
                  <EyeIcon
                    isVisible={currentPasswordFieldVisible}
                    setIsVisible={setCurrentPasswordFieldVisible}
                  />
                }
                helperTexts={[
                  {
                    message: texts.message.notMatchedPassword,
                    visible:
                      errors[CURRENT_PASSWORD]?.type === 'misMatch' &&
                      !!currentPassword.value,
                    status: 'error',
                  },
                ]}
                onChange={currentPassword.onChange}
                onBlur={currentPassword.onBlur}
              />
            </Box>
            <Box className="mb-5 sm:mb-6">
              <TextField
                ref={newPassword.ref}
                type={newPasswordFieldVisible ? 'text' : 'password'}
                label={texts.label.newPassword}
                isError={
                  (!hasFocus && errors[NEW_PASSWORD]) ||
                  errors[NEW_PASSWORD]?.type === 'sameAsBefore'
                }
                name={newPassword.name}
                placeholder={texts.placeholder.newPassword}
                endAdornment={
                  <EyeIcon
                    isVisible={newPasswordFieldVisible}
                    setIsVisible={setNewPasswordFieldVisible}
                  />
                }
                helperTexts={[
                  {
                    ...helperTextStatus.min,
                    message: texts.message.passwordConditionLength,
                  },
                  {
                    ...helperTextStatus.weak,
                    message: texts.message.passwordConditionCharacter,
                  },
                  {
                    message: texts.message.alreadyUsingPassword,
                    visible: errors[NEW_PASSWORD]?.type === 'sameAsBefore',
                    status: 'error',
                  },
                  {
                    message: texts.message.validatedPassword,
                    visible: !errors[NEW_PASSWORD] && !!newPassword.value,
                    status: 'success',
                    icon: 'check',
                  },
                ]}
                onFocus={handleFocusNewPassword}
                onChange={handleChangeNewPassword}
                onBlur={handleBlurNewPassword}
              />
            </Box>
            <Box className="mb-5 sm:mb-6">
              <TextField
                ref={newPasswordConfirm.ref}
                type={newPasswordConfirmFieldVisible ? 'text' : 'password'}
                label={texts.label.newConfirmPassword}
                isError={
                  (!!newPasswordConfirm.value &&
                    !!errors[NEW_PASSWORD_CONFIRM]) ||
                  (!newPasswordConfirm.value && !!errors[NEW_PASSWORD_CONFIRM])
                }
                name={newPasswordConfirm.name}
                placeholder={texts.placeholder.newConfirmPassword}
                endAdornment={
                  <EyeIcon
                    isVisible={newPasswordConfirmFieldVisible}
                    setIsVisible={setNewPasswordConfirmFieldVisible}
                  />
                }
                helperTexts={[
                  {
                    message: texts.message.notMatchedPassword,
                    visible:
                      errors[NEW_PASSWORD_CONFIRM]?.type === 'oneOf' &&
                      !!newPasswordConfirm.value,
                    status: 'error',
                  },
                  {
                    message: texts.message.matchedPassword,
                    visible:
                      !errors[NEW_PASSWORD_CONFIRM] &&
                      !!newPasswordConfirm.value,
                    status: 'success',
                    icon: 'check',
                  },
                ]}
                onBlur={newPasswordConfirm.onBlur}
                onChange={newPasswordConfirm.onChange}
              />
            </Box>
            <Button
              className="w-full sm:h-14"
              appearance="contained"
              size={isMobile ? 'md' : 'lg'}
              testId="submit-button"
              type="submit"
              disabled={isDisableSubmitButton}
            >
              {texts.button.change}
            </Button>
          </form>
        </SectionPanel.Body>
      </SectionPanel.Wrapper>
    </SingleColumnPageTemplate>
  );
};

export default withAccount(ChangePassword);
