import * as React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const ArrowDropDown = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 4L0 0H8L4 4Z" />
  </svg>
);

const Asset = {
  size,
  View: ArrowDropDown,
};
export default Asset;
