import React from 'react';

import { EyeOnIcon, EyeOffIcon } from 'designSystem/ions/Icons/solid';

type VisibilityProps = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const EyeIcon = ({ isVisible, setIsVisible }: VisibilityProps): JSX.Element => {
  const handleClickVisibleButton = () => {
    setIsVisible(true);
  };

  const handleClickInvisibleButton = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <EyeOffIcon.View
      className="cursor-pointer"
      data-testid="eye-off"
      width={EyeOffIcon.size.width}
      height={EyeOffIcon.size.height}
      onClick={handleClickInvisibleButton}
    />
  ) : (
    <EyeOnIcon.View
      className="cursor-pointer"
      data-testid="eye-on"
      width={EyeOnIcon.size.width}
      height={EyeOnIcon.size.height}
      onClick={handleClickVisibleButton}
    />
  );
};

export default EyeIcon;
