import React, { useRef, ChangeEvent } from 'react';
import {
  Box as CDSBox,
  Label,
  Popup,
  RadioGroup,
  Text,
  Textarea,
  Typography,
} from '@classtinginc/design-system';

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

import useTranslatedTexts from '../../useTranslatedTexts';

import { Account, useChangeRole } from 'shared/data/account';
import { Button } from 'designSystem/atoms';
import { OpenModalParamsType } from 'designSystem/organisms';
import { createTrackEventProps } from 'shared/analytics/utils';
import { useIsInMobile, useIsInSmScreen } from 'designSystem/ions';

type ChangeRoleDialogProps = {
  account: Account;
  open: boolean;
  openModal: (params: OpenModalParamsType) => void;
  closeDialog: () => void;
};

const schema = yup.object().shape({
  report_role: yup.string().required(),
  report_reason: yup.string().max(200).required(),
});

const ChangeRoleDialog = ({
  account,
  open,
  openModal,
  closeDialog,
}: ChangeRoleDialogProps): JSX.Element => {
  const texts = useTranslatedTexts();
  const isMobile = useIsInMobile();
  const reasonTextAreaEl = useRef<HTMLTextAreaElement>(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<{ report_role: string; report_reason: string }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const disabledSubmitButton = !isValid;

  const roleItems = [
    { value: 'student', label: texts.item.student },
    { value: 'parent', label: texts.item.parent },
    { value: 'teacher', label: texts.item.teacher },
  ];

  const handleClickClose = () => {
    closeDialog();
    reset();
  };

  const mutation = useChangeRole({
    onError: () => {
      /**
       * NOTE: google sheet에 정상적으로 요청한 내용이 기록되지만 app script를 사용하지 않고 google sheet에 요청할 경우 항상 error response가 주어짐
       */
      closeDialog();
      openModal({
        status: 'success',
        message: texts.message.successRequestChangeRole,
      });
    },
  });

  const submit = ({
    report_role,
    report_reason,
  }: {
    report_role: string;
    report_reason: string;
  }) => {
    mutation.mutate({
      user_id: account.id,
      user_role: account.role,
      user_email: account.email,
      report_role,
      report_reason,
    });

    reset();
  };

  return (
    <Popup.Root open={open}>
      <Popup.Content size={'sm'} data-testid="change-role-dialog">
        <Popup.Header>
          <Popup.Title>{texts.title.requestChangeRole}</Popup.Title>
          <CDSBox
            css={{
              marginTop: '$03',
            }}
          >
            <Typography size="body" color="medium">
              {texts.message.submitChangeRole}
            </Typography>
          </CDSBox>
        </Popup.Header>
        <form onSubmit={handleSubmit(submit)}>
          <Popup.Body>
            <CDSBox>
              <CDSBox css={{ marginBottom: '$03' }}>
                <Text size="80" color="medium" weight="medium">
                  {texts.label.roleToChange}
                </Text>
              </CDSBox>
              <Controller
                control={control}
                name="report_role"
                render={({ field: { onChange } }) => (
                  <RadioGroup
                    onValueChange={(value: string) => onChange(value)}
                  >
                    {roleItems.map((item, index) => {
                      const isDisabled = account.role === item.value;
                      return (
                        <CDSBox
                          key={index}
                          css={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '$03',
                          }}
                        >
                          <RadioGroup.Radio
                            size="lg"
                            value={item.value}
                            id={item.value}
                            css={{
                              marginTop: '0.1875rem',
                              cursor: isDisabled ? 'default' : 'pointer',
                            }}
                            data-testid={`${item.value}-role-radio`}
                            disabled={isDisabled}
                          />
                          <Label
                            htmlFor={item.value}
                            css={{
                              cursor: isDisabled ? 'default' : 'pointer',
                              marginLeft: '$02',
                              overflow: 'hidden',
                            }}
                          >
                            <Text
                              size="100"
                              color={isDisabled ? 'disabled' : 'dark'}
                              weight="regular"
                            >
                              {item.label}
                            </Text>
                          </Label>
                        </CDSBox>
                      );
                    })}
                  </RadioGroup>
                )}
              />
              <CDSBox
                css={{
                  marginTop: '$06',
                  marginBottom: '$01',
                }}
              >
                <Label>
                  <Text size="80" color="medium" weight="medium">
                    {`${texts.label.reasonForChangeRole} ${texts.item.required}`}
                  </Text>
                </Label>
              </CDSBox>
              <Controller
                control={control}
                name="report_reason"
                render={({ field: { onChange } }) => (
                  <Textarea
                    ref={reasonTextAreaEl}
                    placeholder={texts.placeholder.reasonRorChangeRole}
                    name="report_reason"
                    data-testid="report_reason"
                    rows={isMobile ? 3 : 2}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      onChange(event.target.value)
                    }
                    css={{
                      borderColor: '$gray-300',
                    }}
                  />
                )}
              />
            </CDSBox>
          </Popup.Body>
          <Popup.Footer>
            <Button
              theme="white"
              data-testid="cancel-button"
              onClick={handleClickClose}
              className="!mr-3"
            >
              {texts.button.cancel}
            </Button>
            <Button
              type="submit"
              appearance="contained"
              data-testid="submit-button"
              disabled={disabledSubmitButton}
              {...createTrackEventProps({
                name: 'ChangeRoleRequestClicked',
                on: 'click',
                properties: {
                  userId: account?.id,
                  role: account?.role,
                },
              })}
            >
              {texts.button.changeRole}
            </Button>
          </Popup.Footer>
        </form>
      </Popup.Content>
    </Popup.Root>
  );
};

export default ChangeRoleDialog;
