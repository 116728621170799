import React from 'react';

const size = {
  width: '20px',
  height: '20px',
};

const X = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      data-testid="x-icon"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M3.864 3.864a.9.9 0 011.272 0L9 7.727l3.864-3.863a.9.9 0 111.272 1.272L10.273 9l3.863 3.864a.9.9 0 01-1.272 1.272L9 10.273l-3.864 3.863a.9.9 0 01-1.272-1.272L7.727 9 3.864 5.136a.9.9 0 010-1.272z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const Asset = {
  size,
  View: React.memo(X),
};

export default Asset;
