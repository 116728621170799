import React, { PropsWithChildren } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { Box, useIsInMobile } from 'designSystem/ions';
import { ArrowLeftIcon } from 'designSystem/ions/Icons/solid';

type DialogProps = PropsWithChildren<{
  open: boolean;
  title: string;
  description?: string;
  testId?: string;
  close: () => void;
}>;

const ButtonGroup = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Box className="flex sm:ml-auto mt-3 sm:mt-6">
      {React.Children.map(children, (component, index) => {
        /* istanbul ignore next */
        return component ? (
          <Box className="mr-3 flex-auto last:mr-0" key={index}>
            {component}
          </Box>
        ) : null;
      })}
    </Box>
  );
};

const overlayStyle =
  'flex justify-center items-center fixed inset-0 bg-black/50 z-50';

const contentStyle = `
  flex flex-col
  fixed top-1/2 left-1/2
  w-full h-full sm:w-100 sm:h-auto
  px-15px sm:p-6
  bg-white border-none rounded-none sm:rounded-lg
  transform -translate-x-1/2 -translate-y-1/2  shadow-modal
`;

const headerStyle = `
  flex sm:block items-center justify-center relative h-14 sm:h-auto
`;

const titleStyle = `text-headline-5 sm:text-headline-4 text-gray-800 mb-0 sm:mb-3`;

const Dialog = ({
  children,
  open,
  title,
  description,
  testId,
  close,
}: DialogProps): JSX.Element => {
  const isMobile = useIsInMobile();

  const handleClickBackButton = () => {
    /* istanbul ignore next */
    close();
  };

  return (
    <DialogPrimitive.Root open={open}>
      <DialogPrimitive.Overlay className={overlayStyle} />
      <DialogPrimitive.Content className={contentStyle} data-testid={testId}>
        <Box className={headerStyle}>
          {isMobile && (
            <Box is="div" className="flex absolute left-0" data-testid="back">
              <DialogPrimitive.Close
                className="focus:outline-none focus-visible:ring"
                aria-label="close dialog"
                onClick={handleClickBackButton}
              >
                <ArrowLeftIcon.View {...ArrowLeftIcon.size} />
              </DialogPrimitive.Close>
            </Box>
          )}
          <Box className={titleStyle}>{title}</Box>
        </Box>
        <Box className="flex flex-col">
          {description !== undefined && (
            <Box className="text-gray-700 text-center sm:text-left text-caption sm:text-body-3 mb-8 sm:mb-6">
              {description}
            </Box>
          )}
          {children}
        </Box>
      </DialogPrimitive.Content>
    </DialogPrimitive.Root>
  );
};

Dialog.ButtonGroup = ButtonGroup;

export default Dialog;
