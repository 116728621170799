import React from 'react';

import Box from 'ui-box/dist/src/box';

import useTranslatedTexts from '../../useTranslatedTexts';

import LinkedChildListItem from './LinkedChildListItem';

import {
  SignInMethod,
  Grade,
  ChildAccountDto,
  GuardianRelationshipDto,
  SchoolGrade,
} from 'generated/graphql';

interface LinkedChildListProps {
  relationships: GuardianRelationshipDto[];
}

const LinkedChildList = ({
  relationships,
}: LinkedChildListProps): JSX.Element => {
  const filteredRelationships = relationships.filter(
    (relationship) => !!relationship.childAccount
  ) as { childAccount: ChildAccountDto; id: string }[];

  const texts = useTranslatedTexts();

  if (filteredRelationships.length === 0) {
    return (
      <Box
        data-testid="link-child-page-content-empty-child"
        className="whitespace-pre-line text-center text-14 px-4 sm:px-0 leading-normal text-gray-700"
      >
        {texts.message.emptyChild}
      </Box>
    );
  }

  return (
    <Box data-testid="linked-child-list" className="w-full flex flex-col gap-2">
      {filteredRelationships.map((item) => {
        const signInMethods = item.childAccount.signInMethods.filter(
          (method) => !!method
        ) as SignInMethod[];

        return (
          <LinkedChildListItem
            key={item.childAccount.id}
            childId={item.childAccount.id}
            email={item.childAccount.email || ''}
            mobile={item.childAccount.mobile?.number || ''}
            signInMethods={signInMethods}
            name={item.childAccount.name}
            profileImage={item.childAccount.avatarImage || ''}
            schoolName={item.childAccount.schoolInfo?.school?.name || ''}
            grade={item.childAccount.schoolInfo?.grade || SchoolGrade.None}
            relationshipId={item.id}
          />
        );
      })}
    </Box>
  );
};

export default LinkedChildList;
