import { CountryCode } from 'libphonenumber-js';

import { Enterprise, SchoolGrade } from 'generated/graphql';

export enum Status {
  INITIALIZED = 'initialized',
  VERIFICATION_REQUESTED = 'verificationRequested',
  VERIFIED = 'verified',
  SOCIAL_IDENTIFIED = 'socialIdentified',
  ACTIVATED = 'activated',
  SUSPENDED = 'suspended',
  DELETED = 'deleted',
}

export type Gender = 'female' | 'male';
export type Role = 'student' | 'parent' | 'teacher';

export enum IdentityProviderType {
  APPLE = 'apple',
  GOOGLE = 'google',
  KAKAO = 'kakao',
  NAVER = 'naver',
  WHALESPACE = 'whalespace',
  AZURE = 'azure',
}

export interface IdentityProvider {
  uid: string;
  provider: IdentityProviderType;
  createdAt: number;
}

export interface MarketingAgreement {
  lastAgreedAt?: string;
  isEmailAgreed: boolean;
  isSMSAgreed: boolean;
  isPushAgreed: boolean;
}

export interface Mobile {
  countryCode: CountryCode;
  number: string;
}

export type SchoolDto = {
  id: string;
  name: string;
  zipcode: string;
  regularCourseYear?: number;
  admissionAge?: number;
};

export type SchoolInfo = {
  grade: string;
  school: SchoolDto;
};

export type MySchoolInfo = {
  grade: SchoolGrade;
  school: SchoolDto;
  isErrorVisible?: boolean;
};

// date fields are string
export interface Account {
  id: string;
  username: string | null;
  name: string;
  email: string;
  emailVerified: boolean;
  enterprise?: Enterprise;
  identities: IdentityProvider[];
  profileImage: string | null;
  birthDate: string | null;
  gender: Gender | null;
  language: string | null;
  countryCode: string | null;
  description: string | null;
  mobile: Mobile | null;
  studentRegistrationCode: string | null;
  role: Role;
  grade: SchoolGrade;
  marketingTermsAgreedAt: string | null;
  marketingAgreement: MarketingAgreement | null;
  privacyTermsAgreedAt: string;
  termsOfUseAgreedAt: string;
  signedUpAt: string;
  updatedAt: string;
  createdAt: string;
  status: Status;
  schoolInfo: MySchoolInfo;
  schoolInfoDtoForChildren: SchoolInfo[];
  avatarImage?: string;
}
