import { SchoolGrade } from '../../generated/graphql';

export const GRADES = [
  SchoolGrade.None,
  SchoolGrade.Kindergarten,
  SchoolGrade.First,
  SchoolGrade.Second,
  SchoolGrade.Third,
  SchoolGrade.Fourth,
  SchoolGrade.Fifth,
  SchoolGrade.Sixth,
  SchoolGrade.Seventh,
  SchoolGrade.Eighth,
  SchoolGrade.Ninth,
  SchoolGrade.Tenth,
  SchoolGrade.Eleventh,
  SchoolGrade.Twelfth,
  SchoolGrade.HigherEducation,
];

export const K12_GRADE_TO_NUMBER: Record<string, number> = {
  [SchoolGrade.Kindergarten]: 0,
  [SchoolGrade.First]: 1,
  [SchoolGrade.Second]: 2,
  [SchoolGrade.Third]: 3,
  [SchoolGrade.Fourth]: 4,
  [SchoolGrade.Fifth]: 5,
  [SchoolGrade.Sixth]: 6,
  [SchoolGrade.Seventh]: 7,
  [SchoolGrade.Eighth]: 8,
  [SchoolGrade.Ninth]: 9,
  [SchoolGrade.Tenth]: 10,
  [SchoolGrade.Eleventh]: 11,
  [SchoolGrade.Twelfth]: 12,
};

export const K12_NUMBER_TO_GRADE: Record<number, string> = {
  0: SchoolGrade.Kindergarten,
  1: SchoolGrade.First,
  2: SchoolGrade.Second,
  3: SchoolGrade.Third,
  4: SchoolGrade.Fourth,
  5: SchoolGrade.Fifth,
  6: SchoolGrade.Sixth,
  7: SchoolGrade.Seventh,
  8: SchoolGrade.Eighth,
  9: SchoolGrade.Ninth,
  10: SchoolGrade.Tenth,
  11: SchoolGrade.Eleventh,
  12: SchoolGrade.Twelfth,
};
