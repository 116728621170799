import React, { useState, useCallback } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';

import { useDeleteButtonHandler } from './hooks';

import useTranslatedTexts from './useTranslatedTexts';

import {
  TransparentPanel,
  GlobalSection,
  SingleColumnLayout,
} from 'designSystem/layouts';
import { CheckBoxForm, CheckList, TitleBox } from 'designSystem/molecules';
import {
  CenterTitleNavigationHeader,
  GlobalFooter,
  useModalContext,
} from 'designSystem/organisms';
import { Button } from 'designSystem/atoms';
import { MyAccountCard } from 'shared/components/organisms';
import { getCredential } from 'core/credential/credential.service';
import { useAccountCheck } from 'shared/root';
import { useHistoryBackHandler } from 'shared/hooks/history';
import { Box } from 'designSystem/ions';
import { useIsClasstingMobileApp } from 'shared/hooks';
import { createTrackEventProps } from 'shared/analytics/utils';

const DeleteConfirmPage = (props: RouteComponentProps): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();
  const [checked, setChecked] = useState(false);
  const handlePressCheckBox = useCallback(
    () => setChecked((checked) => !checked),
    [setChecked]
  );
  const { data: account } = useAccountCheck();
  const credential = getCredential();
  const history = useHistory();
  const historyHandler = useHistoryBackHandler();
  const { openModal } = useModalContext();

  const { handler } = useDeleteButtonHandler({
    account,
    credential,
    history,
  });

  const handleClickDeleteButton = () => {
    handler(openModal);
  };

  const handleClickCancelButton = () => {
    historyHandler.go(-2);
  };

  const buttonDisabled = !checked || !account?.id;
  const texts = useTranslatedTexts();

  return (
    <GlobalSection.Wrapper>
      <GlobalSection.Header title="클래스팅">
        <CenterTitleNavigationHeader title={texts.CTATitle} />
      </GlobalSection.Header>
      <GlobalSection.Body>
        <SingleColumnLayout>
          <TransparentPanel.Wrapper>
            <TransparentPanel.Header>
              <TitleBox
                title={texts.CTATitle}
                desc={texts.CTADesc}
                hideTitleInMobile
              />
            </TransparentPanel.Header>
            <TransparentPanel.Body>
              <Box
                is="section"
                className="
              flex flex-col items-center
              w-full pb-6
              "
              >
                <Box is="h3" className="sr-only">
                  {texts.accountInfoTitle}
                </Box>
                <MyAccountCard />
              </Box>
              <Box is="section" className="flex flex-col w-full">
                <Box is="h3" className="sr-only">
                  {texts.noticeTitle}
                </Box>
                <CheckList data={texts.checkList} theme="danger" />
              </Box>
              <Box is="div" className="flex flex-col w-full mt-6 mb-5">
                <Box is="div" className="flex h-px w-full bg-gray-300"></Box>
              </Box>
              <Box is="section" className="flex flex-col w-full">
                <Box is="h3" className="sr-only">
                  {texts.noticeAgreeTitle}
                </Box>
                <Box is="div" className="flex flex-row w-full mb-10">
                  <CheckBoxForm
                    id="confirm-delete-checkbox"
                    checked={checked}
                    theme="danger"
                    labelText={texts.checkLabel}
                    onPressCheckBox={handlePressCheckBox}
                  />
                </Box>
                <Box is="div" className="flex flex-row w-full h-11">
                  <Box
                    is="div"
                    className="flex flex-1 sm:flex-initial flex-col"
                  >
                    <Button
                      size="md"
                      theme="white"
                      appearance="outlined"
                      className="w-full sm:w-auto"
                      testId="confirm-cancel-button"
                      onClick={handleClickCancelButton}
                      {...createTrackEventProps({
                        name: 'DeleteAccountCancelled',
                        on: 'click',
                        properties: {
                          userId: account?.id,
                          role: account?.role,
                        },
                      })}
                    >
                      {texts.cancelButton}
                    </Button>
                  </Box>
                  <Box
                    is="div"
                    className="flex flex-initial sm:flex-1 flex-col w-3"
                  />
                  <Box
                    is="div"
                    className="flex flex-1 sm:flex-initial flex-col"
                  >
                    <Button
                      theme="danger"
                      size="md"
                      appearance="contained"
                      testId="confirm-delete-button"
                      disabled={buttonDisabled}
                      onClick={handleClickDeleteButton}
                      className="w-full sm:w-auto"
                      {...createTrackEventProps({
                        name: 'DeleteAccountOperated',
                        on: 'click',
                        properties: {
                          userId: account?.id,
                          role: account?.role,
                        },
                      })}
                    >
                      {texts.deleteButton}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </TransparentPanel.Body>
          </TransparentPanel.Wrapper>
        </SingleColumnLayout>
      </GlobalSection.Body>
      {!isClasstingMobileApp && (
        <GlobalSection.Footer>
          <GlobalFooter />
        </GlobalSection.Footer>
      )}
    </GlobalSection.Wrapper>
  );
};

export default DeleteConfirmPage;
