import React from 'react';

import { GlobalFooter } from 'designSystem/organisms';
import { GlobalSection } from 'designSystem/layouts';
import { WithChildrenProp } from 'types/utilities';
import { Account } from 'shared/data/account';
import { useIsClasstingMobileApp } from 'shared/hooks/platform';

import SideMenuLayout from 'designSystem/layouts/SideMenuLayout';

type SideMenuPageTemplateProps = {
  showHeader?: boolean;
  showFooter?: boolean;
  headerChild: React.ReactNode;
  account: Account;
};

const SideMenuPageTemplate = ({
  children,
  showHeader = true,
  showFooter = true,
  headerChild,
  account,
}: WithChildrenProp<SideMenuPageTemplateProps>): JSX.Element => {
  const isClasstingMobileApp = useIsClasstingMobileApp();

  return (
    <GlobalSection.Wrapper>
      {showHeader && (
        <GlobalSection.Header className="bg-white" title="클래스팅" keepShadow>
          {headerChild}
        </GlobalSection.Header>
      )}
      <GlobalSection.Body className="bg-white sm:bg-gray-50">
        <SideMenuLayout account={account}>{children}</SideMenuLayout>
      </GlobalSection.Body>
      {!isClasstingMobileApp && showFooter && (
        <GlobalSection.Footer>
          <GlobalFooter />
        </GlobalSection.Footer>
      )}
    </GlobalSection.Wrapper>
  );
};

export default SideMenuPageTemplate;
